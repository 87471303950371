import { Button, Modal } from 'antd'
import CreditsNotesForm from '../credits-notes-form/credits-notes-form'
import { CorporateCreditsRequest } from '../../../pages/corporates-container/share-type'
import React from 'react'
import translate from '../../../i18n'

interface DistributeCreditsModalProps {
  onFinish: (value: CorporateCreditsRequest) => void
  handleCancel: () => void
  open: boolean
  loading: boolean
  isTrialCorporate: boolean
}

const DistributeCreditsModal = (props: DistributeCreditsModalProps) => {
  return (
    <Modal
      open={props.open}
      footer={null}
      width={800}
      forceRender
      title={translate('corporate.credits.distribution')}
      onCancel={props.handleCancel}
      destroyOnClose
    >
      <CreditsNotesForm
        loading={props.loading}
        onFinish={props.onFinish}
        showBufferPercentageInput
        showExpiredAtInput
        footerButton={<Button onClick={props.handleCancel}>{translate('cancel')}</Button>}
        isTrialCorporate={props.isTrialCorporate}
      />
    </Modal>
  )
}

export default DistributeCreditsModal
