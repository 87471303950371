import { IStudioAreaUserDto } from '../../share-types'
import { SET_STUDIO_ADMIN_USER_INFO, CLEAR_STUDIO_ADMIN_USER_INFO } from './constants'

export interface SetStudioAdminUserInfoAction {
  type: SET_STUDIO_ADMIN_USER_INFO
  payload: IStudioAreaUserDto
}

export const setStudioAdminUserInfoAction = (
  payload: IStudioAreaUserDto,
): SetStudioAdminUserInfoAction => {
  return {
    type: SET_STUDIO_ADMIN_USER_INFO,
    payload,
  }
}

export interface ClearStudioAdminUserInfoAction {
  type: CLEAR_STUDIO_ADMIN_USER_INFO
}

export const clearStudioAdminUserInfoAction = (): ClearStudioAdminUserInfoAction => {
  return {
    type: CLEAR_STUDIO_ADMIN_USER_INFO,
  }
}

export type StudioAdminUserInfoAction =
  | SetStudioAdminUserInfoAction
  | ClearStudioAdminUserInfoAction
