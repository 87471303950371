import { ReduxStore } from '../../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './card-config.module.less'
import { ICardConfig } from '../../card-stacks-container/share-type'
import CardConfigDescroptions from '../../../components/card-configs/card-config-descroptions/card-config-descroptions'
import { getCardConfigDetail } from '../../../api/cardConfigApi'

const CardConfig = () => {
  const params = useParams()
  const [cardConfigId] = useState<string | undefined>(params?.id)
  const [cardConfig, setCardConfig] = useState<ICardConfig>()
  const navigate = useNavigate()
  useEffect(() => {
    if (cardConfigId) {
      getCardConfigDetail(Number(cardConfigId)).then(res => {
        setCardConfig(res)
      })
    }
  }, [cardConfigId])
  const goBack = () => {
    navigate(-1)
  }

  return (
    <Space className={styles['card-stack-desc-wrapper']} direction="vertical" wrap size={16}>
      {cardConfig && <CardConfigDescroptions cardConfig={cardConfig} />}

      <Button className={styles['button-group']} onClick={goBack}>
        返回
      </Button>
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CardConfig)
