import { Modal, Space } from 'antd'
import { getLowestDiscountOff } from '../../../helpers/utils'
import { Plan } from '../../../store/global/reducer'
import { CouponDiscountTypeEnums } from '../../../pages/coupon-templates-container/share-type'
import YesOrNoTag from '../../common/YesOrNoTag'

interface ICouponTemplateDiscountOffModaProps {
  visible: boolean
  handleCancel: () => void
  handleOk: () => void
  values: MISSING_TYPE
  plans: Plan[]
  appendUserIdsCount: number
}

const CouponTemplateDiscountOffModal = (props: ICouponTemplateDiscountOffModaProps) => {
  const { visible, handleOk, handleCancel, values, plans, appendUserIdsCount } = props
  const { percentageOffPlans, couponType, maxAmount, offAmount, buyOneGetMorePlans, stackable } =
    values
  const renderPercentageOffPlansElement = () => {
    const { lowestDiscountOff, percentageOffPlansWithDiscountOff } = getLowestDiscountOff(
      plans,
      percentageOffPlans,
    )
    return (
      <Space direction="vertical">
        {percentageOffPlansWithDiscountOff.map(item => {
          const filterPlan = plans.find(plan => plan.id === item.planId)
          if (filterPlan) {
            const name = filterPlan.translations.find(t => t.locale === 'zh-CN')?.name
            return (
              <div key={item.planId}>
                ID：{filterPlan.id}-{name} - 课包价格：¥{filterPlan.price / 100} - 课包点数：
                {filterPlan.credits}点 - 折后价: ¥{item.price} -折扣:{item.discountOff}折
              </div>
            )
          }

          return ''
        })}
        <div>最低折扣：{lowestDiscountOff}折</div>
      </Space>
    )
  }

  const renderCashOffElement = () => {
    return (
      <Space>
        满{maxAmount}减{offAmount}
      </Space>
    )
  }

  const renderBuyOnGetMorePlansElement = () => {
    return (
      <Space direction="vertical">
        {buyOneGetMorePlans.map((item: { planId: number; bonusCredits: number }) => {
          const filterPlan = plans.find(plan => plan.id === item.planId)
          if (filterPlan) {
            const name = filterPlan.translations.find(t => t.locale === 'zh-CN')?.name
            return (
              <div key={item.planId}>
                ID：{filterPlan.id}-{name} - 课包价格：¥{filterPlan.price / 100} - 课包点数：
                {filterPlan.credits}点 - 奖励点数：{item.bonusCredits}点
              </div>
            )
          }

          return ''
        })}
      </Space>
    )
  }

  const renderStackableCashOffElement = () => {
    return (
      <Space direction="vertical">
        <Space>优惠详情：减{offAmount}元</Space>
        <Space>
          是否可叠加：
          <YesOrNoTag value={stackable} />
        </Space>
      </Space>
    )
  }

  const renderAppendUserElement = () => {
    return (
      <div style={{ padding: '8px 0' }}>
        <Space>新增{appendUserIdsCount}</Space>
      </div>
    )
  }

  return (
    <Modal
      centered
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      title="确认优惠信息"
      width={450}
      bodyStyle={{ padding: '24px 80px' }}
      cancelText="重新编辑"
    >
      {couponType === CouponDiscountTypeEnums.PERCENTAGE_OFF &&
        percentageOffPlans &&
        renderPercentageOffPlansElement()}

      {couponType === CouponDiscountTypeEnums.CASH_OFF &&
        maxAmount &&
        offAmount &&
        renderCashOffElement()}

      {couponType === CouponDiscountTypeEnums.BUY_ONE_GET_MORE &&
        buyOneGetMorePlans &&
        renderBuyOnGetMorePlansElement()}

      {couponType === CouponDiscountTypeEnums.STACKABLE_CASH_OFF &&
        offAmount &&
        renderStackableCashOffElement()}

      {appendUserIdsCount > 0 && renderAppendUserElement()}
    </Modal>
  )
}

export default CouponTemplateDiscountOffModal
