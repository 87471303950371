export const SHOW_SPINNER = 'PRO/SHOW_SPINNER'
export type SHOW_SPINNER = typeof SHOW_SPINNER

export const HIDE_SPINNER = 'PRO/HIDE_SPINNER'
export type HIDE_SPINNER = typeof HIDE_SPINNER

export const ADD_REQUEST_COUNT = 'PRO/ADD_REQUEST_COUNT'
export type ADD_REQUEST_COUNT = typeof ADD_REQUEST_COUNT

export const REDUCE_REQUEST_COUNT = 'PRO/REDUCE_REQUEST_COUNT'
export type REDUCE_REQUEST_COUNT = typeof REDUCE_REQUEST_COUNT
