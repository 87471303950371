import { Button, Col, notification, Row, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum, UserDto } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import {
  IUserSegment,
  IPaginatedUserSegmentsParams,
  ISegment,
  SegmentTypeEnum,
} from '../share-type'
import styles from './user-segments.module.less'
import {
  deleteUserSegments,
  getPaginatedUserSegmentsBySegmentId,
  getSegmentDetail,
} from '../../../api/segmentApi'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const UserSegments = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [segmentId] = useState<string | undefined>(params?.id)
  const [segment, setSegment] = useState<ISegment>()
  const [userSegments, setUserSegments] = useState<IUserSegment[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)

  useEffect(() => {
    const data = {
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
    }

    getSegmentDetail(Number(segmentId)).then(res => {
      setSegment(res)
    })

    getPaginatedUserSegmentsAction(data)
  }, [])

  const getPaginatedUserSegmentsAction = (data: IPaginatedUserSegmentsParams) => {
    getPaginatedUserSegmentsBySegmentId(Number(segmentId), data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setUserSegments(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
    }
    getPaginatedUserSegmentsAction(data)
  }

  const deleteUserSegment = (data: IUserSegment) => {
    deleteUserSegments(data.id)
      .then(() => {
        notification.success({
          message: '删除成功',
        })
        const data = {
          size: pageInfo.pageSize,
          page: pageInfo.currentPage - 1,
        }
        getPaginatedUserSegmentsAction(data)
      })
      .catch(error => {
        notification.error({
          message: '删除失败',
          description: error.message ?? '',
        })
      })
  }

  const columns: ColumnsType<IUserSegment> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '用户信息',
      dataIndex: 'user',
      key: 'user',
      children: [
        {
          title: '用户ID',
          dataIndex: 'user',
          key: 'userId',
          render: (user: UserDto) => user.id,
        },
        {
          title: '用户昵称',
          dataIndex: 'user',
          key: 'firstName',
          render: (user: UserDto) => user.firstName,
        },
        {
          title: '电话号码',
          dataIndex: 'user',
          key: 'phoneNumber',
          render: (user: UserDto) => user.phoneNumber || 'N/A',
        },
        {
          title: '点数',
          dataIndex: 'user',
          key: 'credits',
          render: (user: UserDto) => user.credits,
        },
        {
          title: '过期点数',
          dataIndex: 'user',
          key: 'frozenCredits',
          render: (user: UserDto) => user.frozenCredits,
        },
      ],
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IUserSegment) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.USER_SEGMENTS_WRITE}>
            <Button
              danger
              disabled={segment?.type !== SegmentTypeEnum.CUSTOM}
              onClick={() => deleteUserSegment(record)}
            >
              删除
            </Button>
          </AuthPermission>
        </Space>
      ),
    },
  ]

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>分组用户</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.USER_SEGMENTS_WRITE}>
            <Button
              disabled={segment?.type !== SegmentTypeEnum.CUSTOM}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate(`/segments/${segmentId}/user-segments/new`)}
            >
              新增分组用户
            </Button>
          </AuthPermission>
        </Col>
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={userSegments}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />

      <Space className={styles['button-segment']}>
        <Button onClick={goBack}>返回</Button>
      </Space>
    </Space>
  )
}

export default UserSegments
