import config from '../config/index'
import {
  IStudioLeaderboardCategoriesFilterParams,
  IStudioLeaderboardCategory,
  StudioLeaderboardCategoryRequest,
} from '../pages/studio-leaderboard-categories-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedStudioLeaderboardCategories = (
  data: IStudioLeaderboardCategoriesFilterParams,
) =>
  authRequest.get<MISSING_TYPE, IStudioLeaderboardCategory[]>(
    `${mainApi}/studio-leaderboard-categories`,
    {
      params: data,
    },
  )

export const getStudioLeaderboardCategoryDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IStudioLeaderboardCategory>(
    `${mainApi}/studio-leaderboard-categories/${id}`,
  )

export const createStudioLeaderboardCategory = (data: StudioLeaderboardCategoryRequest) =>
  authRequest.post<MISSING_TYPE, IStudioLeaderboardCategory>(
    `${mainApi}/studio-leaderboard-categories`,
    data,
  )

export const editStudioLeaderboardCategory = (id: number, data: StudioLeaderboardCategoryRequest) =>
  authRequest.put<MISSING_TYPE, IStudioLeaderboardCategory>(
    `${mainApi}/studio-leaderboard-categories/${id}`,
    data,
  )
