import { IOrganization } from './pages/organization-container/share-type'
import { IStudio } from './pages/studios-container/share-type'

export enum LocaleType {
  zh_CN = 'zh-CN',
  en = 'en',
}

export type IPaginatedParams = {
  page: number
  size: number
}

export type PageInfo = {
  count: number
  currentPage: number
  pageSize: number
}

export type AdminUserRole = {
  name: string
  id: number
}

export type AdminUserPermission = {
  id: number
  scope: string
}

export type IAdminUser = {
  id: number
  email: string
  uid: string
  createdAt: string
  timeZone: string
  provider: string
  roles: AdminUserRole[]
  abilities: number
  permissions: AdminUserPermission[]
}

export type IStudioAreaUserDto = {
  id: number
  organization: IOrganization
  email: string
  tudios: Array<IStudio>
}

export type ISimpleAdminUser = {
  id: number
  email: string
  uid: string | null
}

export type Category = {
  id: number
  name: string
}

export interface OptionType {
  label: string
  value: number
}

export type UserDto = {
  id: number
  email: string
  firstName: string
  realName: string
  phoneNumber: string
  cityId: number
  latitude: number
  longitude: number
  credits: number
  frozenCredits: number
  uid: string
}

export interface TagTree {
  value: string
  label: string
  children?: TagTree[]
}

export type ISimpleOrganization = {
  id: number
  organizationTranslations: {
    id: number
    name: string
    locale: string
  }[]
}

export type INeightborhoodTranslation = {
  id: number
  locale: string
  name: string
}

export type INeightborhood = {
  id: number
  translations: INeightborhoodTranslation[]
}

export type ITransitStation = {
  id: number
  latitude: number
  longitude: number
  isActive: boolean
  translations: ITransitStationTranslation[]
}

export type ITransitStationTranslation = {
  id: number
  locale: LocaleType
  name: string
}

export type ITransitLineTranslation = {
  id: number
  locale: LocaleType
  name: string
}

export type ITransitLine = {
  id: number
  cityId: number
  isActive: boolean
  transitStations: ITransitStation[]
  translations: ITransitLineTranslation[]
  createdAt: string
  updatedAt: string
}

export type IBusinessDistrictTranslation = {
  id: number
  locale: LocaleType
  name: string
  createdAt: string
  updatedAt: string
}

export type IBusinessDistrict = {
  id: number
  cityId: number
  latitude: number
  longitude: number
  isActive: boolean
  translations: IBusinessDistrictTranslation[]
  createdAt: string
  updatedAt: string
}

export enum MenuModeEnum {
  MENU,
  GRID,
}

export enum UploadFileDirectoryEnum {
  HOMEPAGE_ADS = 'mini-program/assets/homepage/ads',
  HOMEPAGE_ACTIVITY_ICONS = 'mini-program/assets/homepage/activity-icons',
  HOMEPAGE_TITLE_SUBTILE_ICONS = 'mini-program/assets/homepage/title-subtitle-icons',
  REFERRAL_BANNERS = 'mini-program/assets/referral/banners',
  STUDIO_LEADERBOARD_CATEGORIES_ICONS = 'mini-program/assets/studio-leaderboard/category-icons',
  STUDIO_LESSON_LIST_BANNERS = 'mini-program/assets/new-lesson-studio-list/banners',
}

export enum SupportedPrelaunchUploadImageTypeEnums {
  STUDIO_PROFILE = 'STUDIO_PROFILE',
  STUDIO_BANNER = 'STUDIO_BANNER',
  STUDIO_PHOTOS = 'STUDIO_PHOTOS',
  LESSON_DESCRIPTION_PHOTOS = 'LESSON_DESCRIPTION_PHOTOS',
}

export enum DAY_OF_WEEK {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum PermissionsEnum {
  PERMISSIONS_READ = 'permissions:read',
  PERMISSIONS_WRITE = 'permissions:write',
  COUPON_TEMPLATES_READ = 'coupon-templates:read',
  COUPON_TEMPLATES_WRITE = 'coupon-templates:write',
  ADMIN_USERS_READ = 'admin-users:read',
  ADMIN_USERS_WRITE = 'admin-users:write',
  ADMIN_ROLE_READ = 'admin-role:read',
  ADMIN_ROLE_WRITE = 'admin-role:write',
  BOOKING_REVIEWS_READ = 'booking-reviews:read',
  BOOKING_REVIEWS_WRITE = 'booking-reviews:write',
  CARD_STACKS_READ = 'card-stacks:read',
  CARD_STACKS_WRITE = 'card-stacks:write',
  CARD_CONFIGS_READ = 'card-configs:read',
  CARD_CONFIGS_WRITE = 'card-configs:write',
  STUDIOS_READ = 'studios:read',
  STUDIOS_WRITE = 'studios:write',
  LESSON_DESCRIPTIONS_READ = 'lesson-descriptions:read',
  LESSON_DESCRIPTIONS_WRITE = 'lesson-descriptions:write',
  IMAGE_UPLOAD = 'images:upload',
  CORPORATES_READ = 'corporates:read',
  CORPORATES_WRITE = 'corporates:write',
  CORPORATE_MEMBERS_READ = 'corporate-members:read',
  CORPORATE_MEMBERS_WRITE = 'corporate-members:write',
  REFERRAL_CAMPAIGNS_READ = 'referral-campaigns:read',
  REFERRAL_CAMPAIGNS_WRITE = 'referral-campaigns:write',
  SEGMENTS_READ = 'segments:read',
  SEGMENTS_WRITE = 'segments:write',
  USER_SEGMENTS_READ = 'user-segments:read',
  USER_SEGMENTS_WRITE = 'user-segments:write',
  SEGMENT_RULES_READ = 'segment-rules:read',
  SEGMENT_RULES_WRITE = 'segment-rules:write',
  STUDIO_LEADERBOARDS_READ = 'studio-leaderboards:read',
  STUDIO_LEADERBOARDS_WRITE = 'studio-leaderboards:write',
  STUDIO_LEADERBOARD_CATEGORIES_READ = 'studio-leaderboard-categories:read',
  STUDIO_LEADERBOARD_CATEGORIES_WRITE = 'studio-leaderboard-categories:write',
  SEARCH_TRENDS_READ = 'search-trends:read',
  SEARCH_TRENDS_WRITE = 'search-trends:write',
  LESSON_CREDIT_PRICINGS_READ = 'lesson-credit-pricings:read',
  LESSON_CREDIT_PRICINGS_WRITE = 'lesson-credit-pricings:write',
  TASK_REWARDS_READ = 'task-rewards:read',
  TASK_REWARDS_WRITE = 'task-rewards:write',
  FEATURE_FLAGS_READ = 'feature-flags:read',
  FEATURE_FLAGS_WRITE = 'feature-flags:write',
  WECHAT_URL_LINKS_READ = 'wechat-url-links:read',
  WECHAT_URL_LINKS_WRITE = 'wechat-url-links:write',
  SHORT_URLS_READ = 'short-urls:read',
  SHORT_URLS_WRITE = 'short-urls:write',
  ONE_TIME_CODES_READ = 'one-time-codes:read',
  ONE_TIME_CODES_WRITE = 'one-time-codes:write',
  TAGS_READ = 'tags:read',
  TAGS_WRITE = 'tags:write',
  STUDIO_INTEGRATION_READ = 'studio-integration:read',
  STUDIO_INTEGRATION_WRITE = 'studio-integration:write',
  PRELAUNCH_ORGANIZATION_FORMS_WRITE = 'prelaunch-organization-forms:write',
  PRELAUNCH_ORGANIZATION_FORMS_READ = 'prelaunch-organization-forms:read',
  FIRST_CLASS_DISCOUNTS_WRITE = 'first-class-discounts:write',
  FIRST_CLASS_DISCOUNTS_READ = 'first-class-discounts:read',
  CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_WRITE = 'corporate-credits-distribution-schedules:write',
  CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_READ = 'corporate-credits-distribution-schedules:read',
  LESSON_CREDIT_ADJUSTMENTS_READ = 'lesson-credit-adjustments:read',
  LESSON_CREDIT_ADJUSTMENTS_WRITE = 'lesson-credit-adjustments:write',
  DUPLICATION_LESSON_DESCRIPTIONS_READ = 'duplication-lesson-descriptions:read',
  DUPLICATION_LESSON_DESCRIPTIONS_WRITE = 'duplication-lesson-descriptions:write',
  BILLING_INVOICES_READ = 'billing-invoices:read',
  BILLING_INVOICES_WRITE = 'billing-invoices:write',
  EXCHANGE_RATE_READ = 'exchange-rate:read',
  EXCHANGE_RATE_WRITE = 'exchange-rate:write',
}
