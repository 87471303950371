import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { Button, Descriptions } from 'antd'
import { ICardConfig } from '../../../pages/card-stacks-container/share-type'
import { Link, useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'

interface Iprops extends ConnectedProps<typeof withConnect> {
  cardConfig: ICardConfig
}

const CardConfigDescriptions = (props: Iprops) => {
  const { cardConfig } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/card-configs/${cardConfig?.id}/edit`)
  }

  return (
    <Descriptions
      style={{ width: 1200 }}
      title="卡片设置详情"
      labelStyle={{ alignItems: 'center', fontWeight: 600 }}
      column={2}
      bordered
      extra={
        <AuthPermission permission={PermissionsEnum.CARD_CONFIGS_WRITE}>
          <Button onClick={toEdit} type="primary">
            编辑
          </Button>
        </AuthPermission>
      }
    >
      <Descriptions.Item label="ID">{cardConfig?.id}</Descriptions.Item>
      <Descriptions.Item label="名称">{cardConfig?.name}</Descriptions.Item>
      <Descriptions.Item label="card stack">
        <Link to={`/card-stacks/${cardConfig?.cardStack.id}`}>{cardConfig.cardStack.name}</Link>
      </Descriptions.Item>
      {cardConfig?.rank && <Descriptions.Item label="排名">{cardConfig?.rank}</Descriptions.Item>}
      <Descriptions.Item label="活跃">
        <YesOrNoTag value={cardConfig?.isActive} />
      </Descriptions.Item>
      <Descriptions.Item label="创建时间">{cardConfig?.createdAt}</Descriptions.Item>
      <Descriptions.Item label="更改时间">{cardConfig?.updatedAt}</Descriptions.Item>
      <Descriptions.Item label="设置">
        <pre>{JSON.stringify(cardConfig?.config, undefined, 2)}</pre>
      </Descriptions.Item>
    </Descriptions>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CardConfigDescriptions)
