import translate from '../i18n'
import { LocaleType } from '../share-types'

export const ONE_SECOND = 1000
export const ONE_MINUTE = ONE_SECOND * 60
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm:ss'
export const TIME_FORMAT_SHORT = 'HH:mm'
export const DEFAULT_REFERRAL_PAGE_BACKGROUND_COLOR = '#0055FF'
export const DEFAULT_REFERRAL_SAVE_POSTER_BUTTON_COLOR = '#0055FF'
export const DEFAULT_REFERRAL_SHARING_BUTTON_COLOR = '#F42764'
export const DEFAULT_REFERRAL_GENERATING_POSTER_BUTTON_COLOR = '#35DBFF'
export const DEFAULT_REFERRAL_POSTER_HIGHLIGHT_COLOR = '#F42764'
export const MAX_QS_STRINGIFY_LENGTH = 100
export const WECHAT_URL_LINK_EXPIRED_DAYS = 30

export const LocaleOptions = [
  {
    value: LocaleType.zh_CN,
    label: '中文',
  },
  {
    value: LocaleType.en,
    label: '英文',
  },
]

export const difficultLevelOptions = [
  {
    value: 1,
    label: translate('difficultLevelOptions.easy'),
  },
  {
    value: 3,
    label: translate('difficultLevelOptions.medium'),
  },
  {
    value: 5,
    label: translate('difficultLevelOptions.hard'),
  },
]

export const BREADCRUMB_NAMES = () => {
  return [
    {
      regexp: /^\/admin-users/,
      title: translate('navBar.manger'),
    },
    {
      regexp: /^\/admin-users\/new$/,
      title: translate('navBar.createNewAdminUser'),
    },
    {
      regexp: /^\/admin-users\/[0-9]+\/edit$/,
      title: translate('navBar.editAdminUser'),
    },
    {
      regexp: /^\/admin-users\/[0-9]+$/,
      title: translate('navBar.viewAdminUser'),
    },
    {
      regexp: /^\/coupon-templates/,
      title: translate('navBar.couponTemplates'),
    },
    {
      regexp: /^\/coupon-templates\/new$/,
      title: translate('navBar.newCouponTemplate'),
    },
    {
      regexp: /^\/coupon-templates\/[0-9]+\/edit$/,
      title: translate('navBar.editCouponTemplate'),
    },
    {
      regexp: /^\/coupon-templates\/[0-9]+$/,
      title: translate('navBar.viewCouponTemplate'),
    },
    {
      regexp: '/change-language',
      title: translate('navBar.changeLanguage'),
    },
    {
      regexp: /^\/admin-roles/,
      title: translate('navBar.adminRoles'),
    },
    {
      regexp: /^\/admin-roles\/new$/,
      title: translate('navBar.newAdminRoles'),
    },
    {
      regexp: /^\/admin-roles\/[0-9]+\/edit$/,
      title: translate('navBar.editAdminRoles'),
    },
    {
      regexp: /^\/booking-reviews/,
      title: translate('navBar.bookingReviews'),
    },
    {
      regexp: /^\/booking-reviews\/[0-9]+$/,
      title: translate('navBar.viewBookingReviewsTitle'),
    },
    {
      regexp: /^\/card-stacks/,
      title: translate('menuBar.cardStacksTitle'),
    },
    {
      regexp: /^\/card-stacks\/new$/,
      title: translate('navBar.newCardStack'),
    },
    {
      regexp: /^\/card-stacks\/[0-9]+$/,
      title: translate('navBar.viewCardStackTitle'),
    },
    {
      regexp: /^\/card-stacks\/[0-9]+\/edit$/,
      title: translate('navBar.editCardStack'),
    },
    {
      regexp: /^\/card-configs/,
      title: translate('menuBar.cardConfigsTitle'),
    },
    {
      regexp: /^\/card-configs\/new$/,
      title: translate('navBar.newCardConfig'),
    },
    {
      regexp: /^\/card-configs\/[0-9]+$/,
      title: translate('navBar.viewCardConfigTitle'),
    },
    {
      regexp: /^\/card-configs\/[0-9]+\/edit$/,
      title: translate('navBar.editCardConfig'),
    },
    {
      regexp: /^\/corporates/,
      title: translate('menuBar.corporatesTitle'),
    },
    {
      regexp: /^\/corporates\/new$/,
      title: translate('navBar.newCorporate'),
    },
    {
      regexp: /^\/corporates\/[0-9]+/,
      title: translate('navBar.viewCorporate'),
    },
    {
      regexp: /^\/corporates\/[0-9]+\/edit$/,
      title: translate('navBar.editCorporate'),
    },
    {
      regexp: /^\/corporates\/[0-9]+\/credits\/histories$/,
      title: translate('navBar.corporateCreditsHistories'),
    },
    {
      regexp: /^\/corporates\/[0-9]+\/credits-distribution-schedules\/[0-9]+$/,
      title: translate('navbar.corporateCreditsDistributionSchedule.view'),
    },
    {
      regexp: /^\/corporates\/[0-9]+\/credits-distribution-schedules\/[0-9]+\/edit$/,
      title: translate('navbar.corporateCreditsDistributionSchedule.edit'),
    },
    {
      regexp: /^\/segments/,
      title: translate('menuBar.segmentsTitle'),
    },
    {
      regexp: /^\/segments\/new$/,
      title: translate('navBar.newSegment'),
    },
    {
      regexp: /^\/segments\/[0-9]+$/,
      title: translate('navBar.viewSegment'),
    },
    {
      regexp: /^\/segments\/[0-9]+\/edit$/,
      title: translate('navBar.editSegment'),
    },
    {
      regexp: /^\/segments\/[0-9]+\/user-segments\/new$/,
      title: translate('navBar.newUserSegments'),
    },
    {
      regexp: /^\/referral-campaigns/,
      title: translate('menuBar.referralCampaignsTitle'),
    },
    {
      regexp: /^\/referral-campaigns\/new$/,
      title: translate('navBar.newReferralCampaign'),
    },
    {
      regexp: /^\/referral-campaigns\/[0-9]+$/,
      title: translate('navBar.viewReferralCampaign'),
    },
    {
      regexp: /^\/referral-campaigns\/[0-9]+\/edit$/,
      title: translate('navBar.editReferralCampaign'),
    },
    {
      regexp: /^\/segment-rules/,
      title: translate('menuBar.segmentRulesTitle'),
    },
    {
      regexp: /^\/segment-rules\/new$/,
      title: translate('navBar.newSegmentRule'),
    },
    {
      regexp: /^\/segment-rules\/[0-9]+$/,
      title: translate('navBar.viewSegmentRule'),
    },
    {
      regexp: /^\/segment-rules\/[0-9]+\/edit$/,
      title: translate('navBar.editSegmentRule'),
    },
    {
      regexp: /^\/studio-leaderboard-categories/,
      title: translate('menuBar.studioLeaderboardCategoriesTitle'),
    },
    {
      regexp: /^\/studio-leaderboard-categories\/new$/,
      title: translate('navBar.newStudioLeaderboardCategories'),
    },
    {
      regexp: /^\/studio-leaderboard-categories\/[0-9]+$/,
      title: translate('navBar.viewStudioLeaderboardCategories'),
    },
    {
      regexp: /^\/studio-leaderboard-categories\/[0-9]+\/edit$/,
      title: translate('navBar.editStudioLeaderboardCategories'),
    },
    {
      regexp: /^\/search-trends/,
      title: translate('menuBar.searchTrendsTitle'),
    },
    {
      regexp: /^\/search-trends\/[0-9]+$/,
      title: translate('navBar.viewSearchTrends'),
    },
    {
      regexp: /^\/studio-leaderboards/,
      title: translate('menuBar.studioLeaderboardsTitle'),
    },
    {
      regexp: /^\/studio-leaderboards\/new$/,
      title: translate('navBar.newStudioLeaderboards'),
    },
    {
      regexp: /^\/studio-leaderboards\/[0-9]+$/,
      title: translate('navBar.viewStudioLeaderboards'),
    },
    {
      regexp: /^\/studio-leaderboards\/[0-9]+\/edit$/,
      title: translate('navBar.editStudioLeaderboards'),
    },
    {
      regexp: /^\/lesson-credit-pricings/,
      title: translate('menuBar.lessonCreditPricingsTitle'),
    },
    {
      regexp: /^\/lesson-credit-pricings\/new$/,
      title: translate('navBar.newLessonCreditPricing'),
    },
    {
      regexp: /^\/lesson-credit-pricings\/bulk-upload$/,
      title: translate('navBar.batchUploadLessonCreditPricing'),
    },
    {
      regexp: /^\/task-rewards/,
      title: translate('navBar.taskRewardsTitle'),
    },
    {
      regexp: /^\/task-rewards\/[0-9]+$/,
      title: translate('navBar.viewTaskRewards'),
    },
    {
      regexp: /^\/task-rewards\/[0-9]+\/edit$/,
      title: translate('navBar.editTaskRewards'),
    },
    {
      regexp: /^\/task-rewards\/new$/,
      title: translate('navBar.newTaskRewards'),
    },

    {
      regexp: /^\/feature-flags/,
      title: translate('navBar.featureFlagsTitle'),
    },
    {
      regexp: /^\/feature-flags\/[0-9]+$/,
      title: translate('navBar.viewFeatureFlag'),
    },
    {
      regexp: /^\/feature-flags\/[0-9]+\/edit$/,
      title: translate('navBar.editFeatureFlag'),
    },
    {
      regexp: /^\/wechat-url-links/,
      title: translate('navBar.weChatURLLinksTitle'),
    },
    {
      regexp: /^\/short-urls/,
      title: translate('navBar.shortURLsTitle'),
    },
    {
      regexp: /^\/one-time-code-batches/,
      title: translate('navBar.oneTimeCodeBatchesTitle'),
    },
    {
      regexp: /^\/one-time-code-batches\/new$/,
      title: translate('navBar.newOneTimeCodeBatch'),
    },
    {
      regexp: /^\/one-time-code-batches\/[0-9]+$/,
      title: translate('navBar.viewOneTimeCodeBatch'),
    },
    {
      regexp: /^\/one-time-code-batches\/[0-9]+\/edit$/,
      title: translate('navBar.editOneTimeCodeBatch'),
    },
    {
      regexp: /^\/one-time-codes/,
      title: translate('menuBar.oneTimeCodesTitle'),
    },
    {
      regexp: /^\/tags/,
      title: translate('navBar.tags'),
    },
    {
      regexp: /^\/tags\/new$/,
      title: translate('navBar.newTag'),
    },
    {
      regexp: /^\/tags\/[0-9]+\/edit$/,
      title: translate('navBar.editTag'),
    },
    {
      regexp: /^\/tags\/[0-9]+$/,
      title: translate('navBar.viewTag'),
    },
    {
      regexp: /^\/lesson-descriptions/,
      title: translate('menuBar.lessonDescriptionsTitle'),
    },
    {
      regexp: /^\/lesson-descriptions\/[0-9]+$/,
      title: translate('navBar.viewLessonDescription'),
    },
    {
      regexp: /^\/organizations/,
      title: translate('menuBar.organizationsTitle'),
    },
    {
      regexp: /^\/organizations\/[0-9]+$/,
      title: translate('navBar.viewOrganization'),
    },
    {
      regexp: /^\/studios/,
      title: translate('navBar.studiosTitle'),
    },
    {
      regexp: /^\/studios\/[0-9]+$/,
      title: translate('navBar.viewStudio'),
    },
    {
      regexp: /^\/studio-integration-config/,
      title: translate('navBar.studioIntegrationConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+/,
      title: translate('navBar.viewStudioIntegrationConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+\/lesson-config\/new$/,
      title: translate('navBar.newStudioIntegrationLessonConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+\/lesson-config\/[0-9]+$/,
      title: translate('navBar.viewStudioIntegrationLessonConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+\/lesson-config\/[0-9]+\/edit$/,
      title: translate('navBar.editStudioIntegrationLessonConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+\/lesson-description-config\/new$/,
      title: translate('navBar.newStudioIntegrationLessonDescriptionConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+\/lesson-description-config\/[0-9]+$/,
      title: translate('navBar.viewStudioIntegrationLessonDescriptionConfig'),
    },
    {
      regexp: /^\/studio-integration-config\/[0-9]+\/lesson-description-config\/[0-9]+\/edit$/,
      title: translate('navBar.editStudioIntegrationLessonDescriptionConfig'),
    },
    {
      regexp: /^\/prelaunch-organization-forms/,
      title: translate('navBar.prelaunchOrganizationFormsTitle'),
    },
    {
      regexp: /^\/prelaunch-organization-forms\/[0-9]+/,
      title: translate('navBar.viewPrelaunchOrganizationForms'),
    },
    {
      regexp: /^\/prelaunch-organization-forms\/[0-9]+\/studios\/[0-9]+\/edit$/,
      title: translate('navBar.editPrelaunchStudios'),
    },
    {
      regexp: /^\/prelaunch-organization-forms\/[0-9]+\/studios\/[0-9]+$/,
      title: translate('navBar.viewPrelaunchStudios'),
    },
    {
      regexp: /^\/prelaunch-organization-forms\/[0-9]+\/lesson-descriptions\/[0-9]+\/edit$/,
      title: translate('navBar.editPrelaunchLessonDescriptions'),
    },
    {
      regexp: /^\/prelaunch-organization-forms\/[0-9]+\/lesson-descriptions\/[0-9]+$/,
      title: translate('navBar.viewPrelaunchLessonDescriptions'),
    },
    {
      regexp: /^\/prelaunch-organization-forms\/[0-9]+\/company-payment-details\/[0-9]+\/edit$/,
      title: translate('navBar.editPrelaunchCompanyPaymentDetail'),
    },
    {
      regexp: /^\/first-class-discount-disabled-lessons/,
      title: translate('navBar.firstClassDiscountDisabledLessonsTitle'),
    },
    {
      regexp: /^\/first-class-discount-disabled-lessons\/new$/,
      title: translate('navBar.newFirstClassDiscountDisabledLesson'),
    },
    {
      regexp: /^\/first-class-discount-disabled-lessons\/[0-9]+\/edit$/,
      title: translate('navBar.editFirstClassDiscountDisabledLesson'),
    },
    {
      regexp: /^\/lesson-credit-adjustments/,
      title: translate('navBar.lessonCreditAdjustmentsTitle'),
    },
    {
      regexp: /^\/lesson-credit-adjustments\/[0-9]+$/,
      title: translate('navBar.viewLessonCreditAdjustmentsTitle'),
    },
    {
      regexp: /^\/lesson-credit-adjustments\/new$/,
      title: translate('navBar.newLessonCreditAdjustmentsTitle'),
    },
    {
      regexp: /^\/billing-invoices/,
      title: translate('navBar.billingInvoicesTitle'),
    },
    {
      regexp: /^\/exchange-rates\/organizations/,
      title: translate('navBar.exchangeRatesTitle'),
    },
    {
      regexp: /^\/exchange-rates\/organizations\/[0-9]+\/studios/,
      title: translate('navBar.exchangeRatesStudiosTitle'),
    },
    {
      regexp: /^\/exchange-rates\/organizations\/[0-9]+\/studios\/[0-9]+\/lesson-descriptions$/,
      title: translate('navBar.exchangeRatesLessonDescriptionsTitle'),
    },
  ]
}
