import { SET_USER_AUTH_INFO, CLEAR_USER_AUTH_INFO } from './constants'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AuthAction } from './action'
import update from 'immutability-helper'

const persistConfig = {
  key: 'auth',
  storage,
}

const initialState = {}

function userAuthReducer(state = initialState, action: AuthAction) {
  switch (action.type) {
    case SET_USER_AUTH_INFO:
      return update(state, { $merge: action.payload })
    case CLEAR_USER_AUTH_INFO:
      return Object.assign({}, initialState)
    default:
      return state
  }
}

export default persistReducer(persistConfig, userAuthReducer)
