import { Button, Form, Input, notification, Space, Switch, Typography } from 'antd'
import { useEffect, useState } from 'react'
import styles from './short-url-form.module.less'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'
import { IShortURL, IShortURLRequest } from '../../../pages/short-urls/share-type'
import { createShortURL, updateShortURL } from '../../../api/shortURLApi'
import { IWeChatURLLink } from '../../../pages/wechat-url-links-container/share-type'

const { TextArea } = Input

const { Paragraph } = Typography

interface IShortURLFormModallProps {
  handleCancel: () => void
  handleSubmit: () => void
  shortURL: IShortURL | undefined
  wechatURLLink?: IWeChatURLLink
}

const ShortURLForm = (props: IShortURLFormModallProps) => {
  const { handleCancel, handleSubmit, shortURL, wechatURLLink } = props
  const [form] = Form.useForm<IShortURLRequest>()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const isRedirectUrlRefreshableValue = Form.useWatch('isRedirectUrlRefreshable', form)

  useEffect(() => {
    if (shortURL) {
      form.setFieldsValue(shortURL)
    } else {
      form.resetFields()
    }
  }, [])

  useEffect(() => {
    if (wechatURLLink) {
      form.setFieldsValue({ redirectUrl: wechatURLLink.urlLink })
    }
  }, [wechatURLLink])

  const onFinish = (values: IShortURLRequest) => {
    setLoadingButton(true)
    if (shortURL?.id) {
      updateShortURL(shortURL.id, values)
        .then(res => {
          if (res) {
            notification.success({
              message: translate('shortURLs.updateSuccess'),
              description: <Paragraph copyable>{res.shortUrl}</Paragraph>,
            })
            handleSubmit()
          }
        })
        .catch(err => {
          notification.error({
            message: translate('shortURLs.updateError'),
            description: err?.message || translate('networkError'),
          })
        })
        .finally(() => {
          setLoadingButton(false)
        })
    } else {
      createShortURL(values)
        .then(res => {
          if (res) {
            notification.success({
              message: translate('shortURLs.createSuccess'),
              description: <Paragraph copyable>{res.shortUrl}</Paragraph>,
            })
            handleSubmit()
          }
        })
        .catch(err => {
          notification.error({
            message: translate('shortURLs.createError'),
            description: err?.message || translate('networkError'),
          })
        })
        .finally(() => {
          setLoadingButton(false)
        })
    }
  }

  return (
    <>
      <Form
        layout="horizontal"
        name="shortURLsForm"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        initialValues={{
          isRedirectUrlRefreshable: false,
          refreshIntervalDays: 0,
        }}
      >
        <Form.Item
          name="name"
          label={translate('shortURLs.name')}
          rules={[{ required: true, message: 'Please input name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label={translate('shortURLs.description')}>
          <TextArea placeholder="" showCount />
        </Form.Item>
        <Form.Item
          name="redirectUrl"
          label={translate('shortURLs.redirectURL')}
          rules={[{ required: true, message: 'Please input redirect url!' }]}
        >
          <TextArea placeholder="" showCount />
        </Form.Item>
        <Form.Item
          name="isRedirectUrlRefreshable"
          label={translate('shortURLs.isRedirectUrlRefreshable')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="refreshIntervalDays"
          label={translate('shortURLs.refreshIntervalDays')}
          rules={[
            {
              required: isRedirectUrlRefreshableValue,
              message: 'Please input refresh interval days!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('isRedirectUrlRefreshable') && value <= 0) {
                  return Promise.reject(
                    new Error('The refresh interval days must be greater than 0!'),
                  )
                }

                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Space className={styles['button-group']}>
            <Button loading={loadingButton} type="primary" htmlType="submit">
              <FormattedMessage id="submit" />
            </Button>
            <Button loading={loadingButton} onClick={handleCancel}>
              <FormattedMessage id="cancel" />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default ShortURLForm
