import * as _ from 'lodash'

const isArray = function (a: MISSING_TYPE) {
  return Array.isArray(a)
}

const isObject = function (o: MISSING_TYPE) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

export const keysToCamel = function (o: MISSING_TYPE) {
  if (isObject(o)) {
    const n: MISSING_TYPE = {}

    Object.keys(o).forEach(k => {
      n[_.camelCase(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: MISSING_TYPE) => {
      return keysToCamel(i)
    })
  }

  return o
}

export const keysToSnake = function (o: MISSING_TYPE) {
  if (isObject(o)) {
    const n: MISSING_TYPE = {}

    Object.keys(o).forEach(k => {
      n[_.snakeCase(k)] = keysToSnake(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: MISSING_TYPE) => {
      return keysToSnake(i)
    })
  }

  return o
}
