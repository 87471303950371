import { Button, Col, Row, Space, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSearchTrendDetail, updateSearchTrendRankers } from '../../../api/searchTrendsApi'
import { ISearchTrend, SearchTrendRankerRequest } from '../share-type'
import styles from './search-trend.module.less'
import SearchTrendDescriptions from '../../../components/search-trends/search-trend-descriptions/search-trend-descriptions'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import SearchTrendRankers from '../../../components/search-trends/search-trend-rankers/search-trend-rankers'
import SearchTrendRankersForm from '../../../components/search-trends/search-trend-rankers-form/search-trend-rankers-form'
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { SearchTrendableTypeOptions } from '../constant'

const SearchTrend = () => {
  const params = useParams()
  const [searchTrendId] = useState<string | undefined>(params?.id)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const [searchTrend, setSearchTrend] = useState<ISearchTrend>()

  useEffect(() => {
    if (searchTrendId) {
      getSearchTrendDetail(Number(searchTrendId)).then(res => {
        setSearchTrend(res)
      })
    }
  }, [searchTrendId])
  if (!searchTrend) {
    return null
  }

  const onConfirm = (values: SearchTrendRankerRequest[]) => {
    updateSearchTrendRankers(Number(searchTrendId), values)
      .then(res => {
        setSearchTrend(res)
        notification.success({
          message: translate('searchTrends.rankers.updateSuccess'),
        })
        setIsEdit(false)
      })
      .catch(error => {
        notification.error({
          message: translate('searchTrends.rankers.updateError'),
          description: error?.message || '网络请求失败!',
        })
      })
  }
  return (
    <Space className={styles['search-trend-desc-wrapper']} direction="vertical" wrap size={16}>
      <SearchTrendDescriptions searchTrend={searchTrend} />

      <Space className="table-space" direction="vertical" wrap size={16}>
        <Row align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
            {translate('searchTrends.rankers', {
              trendableType: SearchTrendableTypeOptions.find(
                item => item.value === searchTrend?.trendableType,
              )?.label,
            })}
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
            <AuthPermission permission={PermissionsEnum.SEARCH_TRENDS_WRITE}>
              {isEdit ? (
                <Button
                  type="primary"
                  danger
                  icon={<CloseCircleOutlined />}
                  onClick={() => setIsEdit(false)}
                >
                  {translate('cancel')}
                </Button>
              ) : (
                <Button type="primary" icon={<EditOutlined />} onClick={() => setIsEdit(true)}>
                  {translate('edit')}
                </Button>
              )}
            </AuthPermission>
          </Col>
        </Row>

        {isEdit ? (
          <SearchTrendRankersForm searchTrend={searchTrend} onConfirm={onConfirm} />
        ) : (
          <SearchTrendRankers searchTrend={searchTrend} />
        )}
      </Space>
    </Space>
  )
}

export default SearchTrend
