import config from '../config/index'
import { TaggingContextEnums } from '../pages/tags-container/constant'
import {
  IPaginatedTag,
  IPaginatedTagsFilterParams,
  ITag,
  ITagsWithCategory,
  TagRequest,
  TaggingRequest,
} from '../pages/tags-container/share-type'
import { authRequest, request } from '../request'

const { mainApi } = config

export const getPaginatedTags = (data: IPaginatedTagsFilterParams) =>
  authRequest.get<MISSING_TYPE, IPaginatedTag>(`${mainApi}/tags`, {
    params: data,
  })

export const getTagDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ITag>(`${mainApi}/tags/${id}`)

export const createTag = (data: TagRequest) =>
  authRequest.post<MISSING_TYPE, ITag>(`${mainApi}/tags`, data)

export const editTag = (id: number, data: TagRequest) =>
  authRequest.put<MISSING_TYPE, ITag>(`${mainApi}/tags/${id}`, data)

export const getTagsByContext = (context: TaggingContextEnums) =>
  request.get<MISSING_TYPE, ITagsWithCategory>(`${mainApi}/tags/by-context`, {
    params: { context },
  })

export const updateTaggings = (data: TaggingRequest) =>
  authRequest.post<MISSING_TYPE, ITag>(`${mainApi}/taggings`, data)
