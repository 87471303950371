import { Button, Cascader, Divider, Form, Input, notification, Select, Switch, Upload } from 'antd'
import styles from './studio-leaderboard-category-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createStudioLeaderboardCategory,
  editStudioLeaderboardCategory,
  getStudioLeaderboardCategoryDetail,
} from '../../../api/studioLeaderboardCategoriesApi'
import { StudioLeaderboardCategoryFormValues } from '../share-type'
import translate from '../../../i18n'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import FormattedMessage from '../../../components/intl/format-message'
import { LocaleOptions } from '../../../helpers/constant'
import { LocaleType, TagTree, UploadFileDirectoryEnum } from '../../../share-types'
import { uploadImage } from '../../../api/cardConfigApi'
import { request } from '../../../request'
import { ReduxStore } from '../../../store'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import {
  studioLeaderboardCategoryFormValues,
  studioLeaderboardCategoryFormValuesToRequest,
} from '../../../helpers/studio-leaderboard-cagetory-helpers'
import categorySmallDefaultTooltipImage from '../../../assets/images/category-small-default-tooltip-image.png'
import categorySmallActiveTooltipImage from '../../../assets/images/category-small-active-tooltip-image.png'
import categoryLargelDefaultTooltipImage from '../../../assets/images/category-large-default-tooltip-image.png'
import categoryLargeActiveTooltipImage from '../../../assets/images/category-large-active-tooltip-image.png'
import { ITag } from '../../tags-container/share-type'

const { Option } = Select
const { TextArea } = Input
const { SHOW_CHILD } = Cascader

interface Iprops {
  title?: string
}
const SectionTitle = (props: Iprops) => {
  return <Divider style={{ fontSize: '14px' }}>{props.title}</Divider>
}

const StudioLeaderboardCategoryForm = (props: ConnectedProps<typeof withConnect>) => {
  const { allCategories } = props
  const [form] = Form.useForm()

  const params = useParams()
  const navigation = useNavigate()
  const [studioLeaderboardCategoriesId] = useState<string | undefined>(params?.id)
  const [tagTreeData, setTagTreeData] = useState<TagTree[]>()

  useEffect(() => {
    const treeTags = Object.keys(allCategories).map(key => {
      const tags = allCategories[key]
      return {
        value: key,
        label: key,
        children: tags.map((item: ITag) => {
          return {
            value: item.id.toString(),
            label: `${item.id}-${item.name}`,
          }
        }),
      }
    })

    setTagTreeData(treeTags)
  }, [allCategories])

  useEffect(() => {
    if (studioLeaderboardCategoriesId) {
      getStudioLeaderboardCategoryDetail(Number(studioLeaderboardCategoriesId)).then(data => {
        const values = studioLeaderboardCategoryFormValues(data)
        form.setFieldsValue(values)
      })
    }
  }, [studioLeaderboardCategoriesId, allCategories])

  const uploadUrl = (options: any, directory: UploadFileDirectoryEnum) => {
    const { onSuccess, onError, file, filename } = options
    const fileExtension = file.name.split('.').pop()
    const imageFilename = `${filename}_${file.uid}.${fileExtension}`
    uploadImage(imageFilename, directory)
      .then(res => {
        onSuccess(res)
        request({
          url: res,
          method: 'PUT',
          data: file,
          headers: {
            'x-amz-acl': 'public-read',
          },
        })
      })
      .catch(err => {
        onError({ err })
      })
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e.fileList
  }

  const onFinish = (values: StudioLeaderboardCategoryFormValues) => {
    const data = studioLeaderboardCategoryFormValuesToRequest(values)
    if (studioLeaderboardCategoriesId) {
      editStudioLeaderboardCategory(Number(studioLeaderboardCategoriesId), data)
        .then(res => {
          notification.success({
            message: translate('studioLeaderboardCategories.editSuccess'),
          })
          navigation(`/studio-leaderboard-categories/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: translate('studioLeaderboardCategories.editError'),
            description: err?.message || translate('networkError'),
          })
        })
    } else {
      createStudioLeaderboardCategory(data)
        .then(res => {
          notification.success({
            message: translate('studioLeaderboardCategories.createSuccess'),
          })
          navigation(`/studio-leaderboard-categories/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: translate('studioLeaderboardCategories.createError'),
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  return (
    <Form
      name="studioLeaderboardCategories"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['studio-leaderboard-category-form']}
    >
      <Form.Item
        label={translate('studioLeaderboardCategories.name')}
        name="name"
        rules={[{ required: true, message: translate('studioLeaderboardCategories.name.message') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={translate('studioLeaderboardCategories.isActive')}
        name="isActive"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={translate('studioLeaderboardCategories.studioLeaderboardCategoryTags')}
        name="studioLeaderboardCategoryTags"
        rules={[
          {
            required: true,
            message: translate('studioLeaderboardCategories.studioLeaderboardCategoryTags.message'),
          },
        ]}
      >
        <Cascader
          style={{ width: '100%' }}
          options={tagTreeData}
          multiple
          maxTagCount="responsive"
          showCheckedStrategy={SHOW_CHILD}
          placeholder="请选择课程tag"
        />
      </Form.Item>

      <SectionTitle title={translate('studioLeaderboardCategories.translations')} />
      <Form.List name="translations" initialValue={[{ locale: 'zh-CN' }, { locale: 'en' }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    label={translate('locale')}
                    name={[name, 'locale']}
                    rules={[{ required: true, message: translate('locale.message') }]}
                  >
                    <Select>
                      {LocaleOptions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={translate('studioLeaderboardCategories.translations.title')}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'studioLeaderboardCategories.translations.title.message',
                        ),
                      },
                    ]}
                    {...restField}
                    name={[name, 'title']}
                  >
                    <TextArea
                      showCount
                      maxLength={100}
                      placeholder={translate('studioLeaderboardCategories.translations.title')}
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <Form.Item colon={false} label="  ">
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              )
            })}

            {fields.length < Object.keys(LocaleType).length && (
              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  <FormattedMessage id="addNewTranslation" />
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      <SectionTitle title={translate('studioLeaderboardCategories.iconImage')} />
      <Form.Item
        name="iconSmallDefaultFileUrl"
        label={translate('studioLeaderboardCategories.iconSmallDefaultFileName')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <div className={styles['tooltop-title']}>过滤栏中默认分类图标（21px*20px）</div>
            <img src={categorySmallDefaultTooltipImage} className={styles['tooltip-inner-image']} />
          </div>
        }
      >
        <Upload
          maxCount={1}
          name="iconSmallDefaultFileUrl"
          customRequest={config =>
            uploadUrl(config, UploadFileDirectoryEnum.STUDIO_LEADERBOARD_CATEGORIES_ICONS)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
        >
          <Button icon={<UploadOutlined />}>
            <FormattedMessage id="clickToUpload" />
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="iconSmallActiveFileUrl"
        label={translate('studioLeaderboardCategories.iconSmallActiveFileName')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <div className={styles['tooltop-title']}>过滤栏中选中分类图标（21px*20px）</div>
            <img src={categorySmallActiveTooltipImage} className={styles['tooltip-inner-image']} />
          </div>
        }
      >
        <Upload
          maxCount={1}
          name="iconSmallActiveFileUrl"
          customRequest={config =>
            uploadUrl(config, UploadFileDirectoryEnum.STUDIO_LEADERBOARD_CATEGORIES_ICONS)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
        >
          <Button icon={<UploadOutlined />}>
            <FormattedMessage id="clickToUpload" />
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="iconLargeDefaultFileUrl"
        label={translate('studioLeaderboardCategories.iconLargeDefaultFileName')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <div className={styles['tooltop-title']}>过滤下拉框默认分类图标（34px*34px）</div>
            <img
              src={categoryLargelDefaultTooltipImage}
              className={styles['tooltip-inner-image']}
            />
          </div>
        }
      >
        <Upload
          maxCount={1}
          name="iconLargeDefaultFileUrl"
          customRequest={config =>
            uploadUrl(config, UploadFileDirectoryEnum.STUDIO_LEADERBOARD_CATEGORIES_ICONS)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
        >
          <Button icon={<UploadOutlined />}>
            <FormattedMessage id="clickToUpload" />
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="iconLargeActiveFileUrl"
        label={translate('studioLeaderboardCategories.iconLargeActiveFileName')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <div className={styles['tooltop-title']}>过滤下拉栏选中分类图标（34px*34px）</div>
            <img src={categoryLargeActiveTooltipImage} className={styles['tooltip-inner-image']} />
          </div>
        }
      >
        <Upload
          maxCount={1}
          name="iconLargeActiveFileUrl"
          customRequest={config =>
            uploadUrl(config, UploadFileDirectoryEnum.STUDIO_LEADERBOARD_CATEGORIES_ICONS)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
        >
          <Button icon={<UploadOutlined />}>
            <FormattedMessage id="clickToUpload" />
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          {translate('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    allCategories: state.categories.allCategories,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioLeaderboardCategoryForm)
