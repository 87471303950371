import { notification } from 'antd'
import { ConfigType, IStudioIntegrationLessonConfig } from '../share-types'
import translate from '../../../i18n'

import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  createStudioIntegrationLessonConfig,
  editStudioIntegrationLessonConfig,
  getStudioIntegrationLessonConfigDetail,
} from '../../../api/studioIntegrationConfigsApi'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import StudioIntegrationConfigForm from '../../../components/studio-integration-configs/studio-integration-config-form/studio-integration-config-form'

dayjs.extend(customParseFormat)

const StudioIntegrationLessonConfigForm = () => {
  const navigation = useNavigate()
  const params = useParams()
  const [studioIntegrationConfigId] = useState<string | undefined>(params?.id)
  const [lessonConfigId] = useState<string | undefined>(params?.lessonConfigId)
  const [data, setData] = useState<IStudioIntegrationLessonConfig>()

  useEffect(() => {
    if (lessonConfigId) {
      getStudioIntegrationLessonConfigDetail(Number(lessonConfigId)).then(data => {
        setData(data)
      })
    }
  }, [lessonConfigId])

  const onSubmit = (data: any) => {
    if (lessonConfigId) {
      editStudioIntegrationLessonConfig(Number(lessonConfigId), data)
        .then(() => {
          notification.success({
            message: translate('studioIntegrationConfig.editSuccess'),
          })
          navigation(`/studio-integration-config/${studioIntegrationConfigId}`)
        })
        .catch(err => {
          notification.error({
            message: translate('studioIntegrationConfig.editError'),
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    } else {
      createStudioIntegrationLessonConfig(data)
        .then(() => {
          notification.success({
            message: translate('studioIntegrationConfig.createSuccess'),
          })
          navigation(`/studio-integration-config/${studioIntegrationConfigId}`)
        })
        .catch(err => {
          notification.error({
            message: translate('studioIntegrationConfig.createError'),
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  return (
    <>
      {studioIntegrationConfigId && (
        <StudioIntegrationConfigForm
          type={ConfigType.LESSON}
          onSubmit={onSubmit}
          data={data}
          studioIntegrationConfigId={studioIntegrationConfigId}
        />
      )}
    </>
  )
}

export default StudioIntegrationLessonConfigForm
