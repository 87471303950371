import { Button, DatePicker, Form, Select } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { ISearchFilterParams } from '../../../pages/search-trends-container/share-type'
import { City, CityTranslation } from '../../../store/city/reducer'
import { LocaleType } from '../../../share-types'
import {
  SearchTrendableTypeOptions,
  SearchTrendsStatusOptions,
} from '../../../pages/search-trends-container/constant'
import dayjs from 'dayjs'
import moment from 'moment'

const { Option } = Select

const SearchTrendsFilter = ({
  onSearch,
  formData,
  activeCities,
  locale,
}: {
  onSearch: (value: ISearchFilterParams) => void
  formData: ISearchFilterParams | undefined
  activeCities: City[]
  locale: LocaleType
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        startDate: formData.startDate ? moment(formData.startDate) : undefined,
        endDate: formData.endDate ? moment(formData.endDate) : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: ISearchFilterParams) => {
    onSearch({
      ...values,
      startDate: values.startDate ? dayjs(values.startDate).format('YYYY-MM-DD') : undefined,
      endDate: values.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" className="filter-inline-form" onFinish={onFinish}>
      <Form.Item label={translate('searchTrends.city')} name="cityId">
        <Select style={{ minWidth: 130 }} allowClear>
          {activeCities.map(city => {
            const translation = city?.translations.find(
              (translation: CityTranslation) => translation.locale === locale,
            )
            return (
              <Option key={city.id} value={city.id}>
                {translation?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label={translate('searchTrends.trendableType')} name="trendableType">
        <Select style={{ minWidth: 130 }} allowClear>
          {SearchTrendableTypeOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={translate('searchTrends.status')} name="status">
        <Select style={{ minWidth: 130 }} allowClear>
          {SearchTrendsStatusOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={translate('searchTrends.startDate')} name="startDate">
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item label={translate('searchTrends.endDate')} name="endDate">
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {translate('search')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SearchTrendsFilter
