import { Table, Space, Row, Col, Button, notification } from 'antd'

import translate from '../../../i18n'
import {
  ConfigType,
  IStudioIntegrationLessonConfig,
  IStudioIntegrationLessonConfigFilterParams,
  IStudioIntegrationLessonDescriptionConfig,
  StudioIntegrationConfigFieldsAutoSetRule,
} from '../../../pages/studio-integration-config-container/share-types'
import { ColumnsType } from 'antd/lib/table'
import YesOrNoTag from '../../common/YesOrNoTag'
import { getDateTime } from '../../../helpers/utils'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import { PlusOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import StudioIntegrationLessonConfigFilter from '../studio-integration-lesson-configs-filter/studio-integration-lesson-configs-filter'
import LinkButton from '../../link-button/link-button'
import { useEffect, useState } from 'react'
import {
  editStudioIntegrationLessonConfig,
  editStudioIntegrationLessonDescriptionConfig,
} from '../../../api/studioIntegrationConfigsApi'
import { isStudioIntegrationLessonConfig } from '../../../helpers/studio-integration-config-helper'

interface StudioIntegrationLessonConfigProps {
  studioIntegrationConfigId: string
  data: IStudioIntegrationLessonConfig[] | IStudioIntegrationLessonDescriptionConfig[]
  onSearch: (values: IStudioIntegrationLessonConfigFilterParams) => void
  searchValues?: IStudioIntegrationLessonConfigFilterParams
  type: ConfigType
  onReloadConfigs: () => void
}

const StudioIntegrationLessonConfigTable = (props: StudioIntegrationLessonConfigProps) => {
  const { data, type, onReloadConfigs } = props
  const navigate = useNavigate()
  const [path, setPath] = useState<string>('lesson-config')
  const [autoSetRules, setAutoSetRules] = useState<string>(
    'studioIntegrationLessonFieldsAutoSetRules',
  )

  useEffect(() => {
    setPath(type === ConfigType.LESSON ? 'lesson-config' : 'lesson-description-config')
    setAutoSetRules(
      type === ConfigType.LESSON
        ? 'studioIntegrationLessonFieldsAutoSetRules'
        : 'studioIntegrationLessonDescriptionFieldsAutoSetRules',
    )
  }, [type])

  const onChangeStatus = (
    record: IStudioIntegrationLessonConfig | IStudioIntegrationLessonDescriptionConfig,
    isActive: boolean,
  ) => {
    const rules = isStudioIntegrationLessonConfig(record)
      ? record.studioIntegrationLessonFieldsAutoSetRules
      : record.studioIntegrationLessonDescriptionFieldsAutoSetRules

    const data = {
      studioIntegrationConfigId: props.studioIntegrationConfigId,
      isActive: isActive,
      fieldValue: record.fieldValue,
      fieldType: record.fieldType,
      rules: rules.map(item => item.rule),
    }

    const request = isStudioIntegrationLessonConfig(record)
      ? editStudioIntegrationLessonConfig(record.id, data)
      : editStudioIntegrationLessonDescriptionConfig(record.id, data)

    request
      .then(() => {
        if (onReloadConfigs) {
          onReloadConfigs()
        }
        notification.success({
          message: translate('studioIntegrationConfig.editSuccess'),
        })
      })
      .catch(err => {
        notification.error({
          message: translate('studioIntegrationConfig.editError'),
          description: err?.message || translate('networkError'),
        })
      })
  }

  const columns: ColumnsType<
    IStudioIntegrationLessonConfig | IStudioIntegrationLessonDescriptionConfig
  > = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: translate('studioIntegrationConfig.fieldType'),
      dataIndex: 'fieldType',
      key: 'fieldType',
    },
    {
      title: translate('studioIntegrationConfig.fieldValue'),
      dataIndex: 'fieldValue',
      key: 'fieldValue',
      width: 120,
    },
    {
      title: translate('isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: number) => <YesOrNoTag value={isActive} />,
      width: 80,
    },
    {
      title: translate('studioIntegrationConfig.rules'),
      dataIndex: autoSetRules,
      key: autoSetRules,
      render: (rules: StudioIntegrationConfigFieldsAutoSetRule[]) => {
        if (rules) {
          const ruleNames = rules.map(item => item.rule.type)
          return Array.from(ruleNames).join(', ')
        }
      },
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (
        record: IStudioIntegrationLessonConfig | IStudioIntegrationLessonDescriptionConfig,
      ) => {
        return (
          <Space>
            <AuthPermission permission={PermissionsEnum.STUDIO_INTEGRATION_WRITE}>
              {record.isActive ? (
                <Button
                  key="inactivate"
                  type="text"
                  className="status-button-red"
                  icon={<StopOutlined />}
                  onClick={() => onChangeStatus(record, false)}
                >
                  停用
                </Button>
              ) : (
                <Button
                  key="activate"
                  type="text"
                  className="status-button-green"
                  icon={<CheckCircleOutlined />}
                  onClick={() => onChangeStatus(record, true)}
                >
                  激活
                </Button>
              )}
            </AuthPermission>

            <AuthPermission permission={PermissionsEnum.STUDIO_INTEGRATION_WRITE}>
              <LinkButton
                to={`/studio-integration-config/${props.studioIntegrationConfigId}/${path}/${record.id}/edit`}
              >
                编辑
              </LinkButton>
            </AuthPermission>

            <LinkButton
              to={`/studio-integration-config/${props.studioIntegrationConfigId}/${path}/${record.id}`}
            >
              查看
            </LinkButton>
          </Space>
        )
      },
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>
            {translate(
              props.type === ConfigType.LESSON
                ? 'studioIntegrationLessonConfig.title'
                : 'studioIntegrationLessonDescConfig.title',
            )}
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.STUDIO_INTEGRATION_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                navigate(
                  `/studio-integration-config/${props.studioIntegrationConfigId}/${path}/new`,
                )
              }
            >
              {translate('studioIntegrationConfig.createNew')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <StudioIntegrationLessonConfigFilter
          studioIntegrationConfigId={props.studioIntegrationConfigId}
          onSearch={props.onSearch}
          formData={props.searchValues}
        />
      </Row>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1140 }}
        pagination={false}
      />
    </Space>
  )
}

export default StudioIntegrationLessonConfigTable
