import { Button, Descriptions, Tag, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import { ISegmentRule } from '../../../pages/segment-rules-container/share-type'
import YesOrNoTag from '../../common/YesOrNoTag'

interface Iprops {
  segmentRule: ISegmentRule
}

const SeISegmentRuleDescriptions = (props: Iprops) => {
  const { segmentRule } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/segment-rules/${segmentRule?.id}/edit`)
  }

  return (
    <Descriptions
      style={{ width: 1200 }}
      title="分组规则详情"
      labelStyle={{ alignItems: 'center', fontWeight: 600 }}
      column={2}
      bordered
      extra={
        <Space>
          <AuthPermission permission="segment-rules:write">
            <Button onClick={toEdit} type="primary">
              编辑
            </Button>
          </AuthPermission>
        </Space>
      }
    >
      <Descriptions.Item label="ID">{segmentRule?.id}</Descriptions.Item>
      <Descriptions.Item label="名称">{segmentRule?.name}</Descriptions.Item>
      <Descriptions.Item label="描述">{segmentRule.description}</Descriptions.Item>
      <Descriptions.Item label="用户分组">
        <Tag key={segmentRule.segment.id}>{segmentRule.segment.name}</Tag>
      </Descriptions.Item>
      <Descriptions.Item label="活跃">
        <YesOrNoTag value={segmentRule.isActive} />
      </Descriptions.Item>
      <Descriptions.Item label="创建时间">{segmentRule?.createdAt}</Descriptions.Item>
      <Descriptions.Item label="更改时间">{segmentRule?.updatedAt}</Descriptions.Item>
      <Descriptions.Item label="rule">
        {JSON.stringify(segmentRule.rule, undefined, 2)}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default SeISegmentRuleDescriptions
