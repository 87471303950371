import { Outlet, useLocation } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import translate from '../../i18n'
import { useEffect } from 'react'
import { setActiveCitiesAction } from '../../store/city/action'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import { getCities } from '../../api/authApi'

const ExchangeRatesContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/exchange-rates\/organizations\/[0-9]+\/studios$/)) {
      return translate('navBar.exchangeRatesStudiosTitle')
    }
    if (
      pathName.match(
        /^\/exchange-rates\/organizations\/[0-9]+\/studios\/[0-9]+\/lesson-descriptions$/,
      )
    ) {
      return translate('navBar.exchangeRatesLessonDescriptionsTitle')
    }
    return translate('navBar.exchangeRatesTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}
const mapDispatchToProps = {
  setActiveCitiesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(ExchangeRatesContainer)
