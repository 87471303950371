export const SET_STUDIO_LEADERBOARD_RANKING_TYPES = 'PRO/SET_STUDIO_LEADERBOARD_RANKING_TYPES'
export type SET_STUDIO_LEADERBOARD_RANKING_TYPES = typeof SET_STUDIO_LEADERBOARD_RANKING_TYPES

export const SET_PENDING_STUDIO_LEADERBOARDS_TYPES = 'PRO/SET_PENDING_STUDIO_LEADERBOARDS_TYPES'
export type SET_PENDING_STUDIO_LEADERBOARDS_TYPES = typeof SET_PENDING_STUDIO_LEADERBOARDS_TYPES

export const SET_APPROVED_STUDIO_LEADERBOARDS_TYPES = 'PRO/SET_APPROVED_STUDIO_LEADERBOARDS_TYPES'
export type SET_APPROVED_STUDIO_LEADERBOARDS_TYPES = typeof SET_APPROVED_STUDIO_LEADERBOARDS_TYPES

export const SET_REJECTED_STUDIO_LEADERBOARDS_TYPES = 'PRO/SET_REJECTED_STUDIO_LEADERBOARDS_TYPES'
export type SET_REJECTED_STUDIO_LEADERBOARDS_TYPES = typeof SET_REJECTED_STUDIO_LEADERBOARDS_TYPES

export const SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES = 'PRO/SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES'
export type SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES = typeof SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES
