import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { IWeChatURLLinksFilterParams } from '../../../pages/wechat-url-links-container/share-type'
import translate from '../../../i18n'
import { ApplicationTypeOptions } from '../../../pages/wechat-url-links-container/constant'

const { Option } = Select
const WeChatURLLinksFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IWeChatURLLinksFilterParams) => void
  formData: IWeChatURLLinksFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: IWeChatURLLinksFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="path" label={translate('weChatURLLinks.path')}>
        <Input />
      </Form.Item>
      <Form.Item name="applicationType" label={translate('weChatURLLinks.applicationType')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {ApplicationTypeOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default WeChatURLLinksFilter
