import { Button, Cascader, Divider, Form, Input, notification, Radio, Switch } from 'antd'
import styles from './segment-rule-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createSegmentRule,
  editSegmentRule,
  getSegmentRuleDetail,
} from '../../../api/segmentRuleApi'
import {
  SegmentFormRule,
  SegmentRuleFormValue,
  SegmentRuleRequest,
  SegmentRuleClass,
  SegmentRuleMatchMethod,
  SegmentRuleType,
} from '../share-type'
import { getSegmentSuggestion } from '../../../api/segmentApi'
import { getOptions } from '../../../helpers/utils'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getStudiosSuggestion, getStudiosSuggestionsByIds } from '../../../api/studiosApi'
import { AssertRule, FCFRule } from '../../../helpers/segment-rule'
import * as _ from 'lodash'
import { ISimpleOrganization, TagTree } from '../../../share-types'
import {
  getOrganizationsSuggestion,
  getOrganizationsSuggestionsByIds,
} from '../../../api/organizationsApi'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'
import { ITag, ITagsWithCategory } from '../../tags-container/share-type'

interface Iprops {
  title?: string
}

const SectionTitle = (props: Iprops) => {
  return <Divider style={{ fontSize: '14px' }}>{props.title}</Divider>
}

const { SHOW_CHILD } = Cascader

const SegmentRuleForm = (props: ConnectedProps<typeof withConnect>) => {
  const [form] = Form.useForm()
  const { firstPageSegments, allCategories } = props

  const params = useParams()
  const navigation = useNavigate()
  const [segmentRuleId] = useState<string | undefined>(params?.id)
  const [defaultSegmentOptions, setDefaultSegmentOptions] = useState<
    { label: string; value: number }[]
  >([])
  const [defaultOrganizationOption, setDefaultOrganizationOption] = useState<
    { label: string; value: number }[][]
  >([])
  const [defaultStudioSuggestionsOption, setDefaultStudioSuggestionsOption] = useState<
    { label: string; value: number }[][]
  >([])

  const ruleTypeValue = Form.useWatch('ruleType', form)
  const [tagTreeData, setTagTreeData] = useState<TagTree[]>()

  const fcfRule = new FCFRule()
  const assertRule = new AssertRule()

  useEffect(() => {
    setDefaultSegmentOptions(getOptions(firstPageSegments))
  }, [firstPageSegments])

  useEffect(() => {
    const treeTags = Object.keys(allCategories).map(key => {
      const tags = allCategories[key]
      return {
        value: key,
        label: key,
        children: tags.map((item: ITag) => {
          return {
            value: item.id.toString(),
            label: `${item.id}-${item.name}`,
          }
        }),
      }
    })

    setTagTreeData(treeTags)
  }, [allCategories])

  useEffect(() => {
    if (segmentRuleId) {
      getSegmentRuleDetail(Number(segmentRuleId)).then(res => {
        setDefaultSegmentOptions([
          {
            label: `${res.segment.id}-${res.segment.name}`,
            value: res.segment.id,
          },
        ])
        let data: SegmentRuleFormValue = {
          ...res,
          segmentId: res.segment.id,
        }
        if (res.rule['@class'] === fcfRule.className) {
          data = {
            ...data,
            ruleType: SegmentRuleType.FCFRule,
          }
        } else {
          const enabledRules: SegmentFormRule | undefined = getRules(res.rule, 'enabledRules')
          const disabledRules: SegmentFormRule | undefined = getRules(res.rule, 'disabledRules')

          const rules = []
          if (enabledRules) {
            rules.push(enabledRules)
          }

          if (disabledRules) {
            rules.push(disabledRules)
          }

          data = {
            ...data,
            ruleType: SegmentRuleType.AssertRule,
            rules,
          }
        }
        form.setFieldsValue(data)
        setStudioSuggestionsOptions(data)
        setOrganizationsSuggestionsOptions(data)
      })
    }
  }, [segmentRuleId, allCategories])

  const asyncGetOrganizationsSuggestionsByIds = async (ids: number[]) => {
    const res = await getOrganizationsSuggestionsByIds(ids)
    return res
  }

  const asyncGetStudiosSuggestionsByIds = async (ids: number[]) => {
    const res = await getStudiosSuggestionsByIds(ids)
    return res
  }

  const setOrganizationsSuggestionsOptions = (data: SegmentRuleFormValue) => {
    const organizationPromises = data.rules?.map(async (rule: any) => {
      if (rule.organizationIds?.length) {
        return asyncGetOrganizationsSuggestionsByIds(rule.organizationIds)
      } else {
        return new Promise(resolve => resolve(null))
      }
    })

    if (organizationPromises?.length) {
      Promise.all(organizationPromises).then(res => {
        if (res) {
          const options = res
            .filter(result => result)
            .map(result => {
              return getOrganizationsSuggestionOptions(result as ISimpleOrganization[])
            })
          setDefaultOrganizationOption(options)
        }
      })
    }
  }

  const setStudioSuggestionsOptions = (data: SegmentRuleFormValue) => {
    const studioPromises = data.rules?.map(async (rule: any) => {
      if (rule.studioIds?.length && rule.studioIds?.length <= MAX_QS_STRINGIFY_LENGTH) {
        return asyncGetStudiosSuggestionsByIds(rule.studioIds)
      } else {
        return new Promise(resolve => resolve(null))
      }
    })

    if (studioPromises?.length) {
      Promise.all(studioPromises).then(res => {
        if (res) {
          const options = res
            .filter(result => result)
            .map(result => {
              return getOptions(result as { id: number; name: string }[])
            })
          setDefaultStudioSuggestionsOption(options)
        }
      })
    }
  }

  const getRules = (rule: SegmentRuleClass, key: 'disabledRules' | 'enabledRules') => {
    const object: SegmentFormRule = {
      matchMethod:
        key === 'disabledRules' ? SegmentRuleMatchMethod.DISABLE : SegmentRuleMatchMethod.ENABLE,
      organizationIds: [],
      tagIds: [],
      studioIds: [],
    }
    const value = rule[key]
    if (value) {
      value.forEach(item => {
        if (_.has(item, 'organizationIds')) {
          _.assignIn(object, { organizationIds: item.organizationIds })
        }

        if (_.has(item, 'tagIds')) {
          const tagIds = item.tagIds?.map(item => findArrayByID(item)).filter(item => item)
          _.assignIn(object, { tagIds })
        }

        if (_.has(item, 'studioIds')) {
          _.assignIn(object, { studioIds: item.studioIds })
        }
      })
    }

    return object
  }

  function findArrayByID(id: number) {
    const categories = _.cloneDeep(allCategories) as ITagsWithCategory

    const tag = Object.values(categories)
      .flat()
      .find(item => item.id === id)

    return tag ? [tag.category, tag.id.toString()] : null
  }

  const handleTags = (tagIds: number[][]): number[] => {
    const tags = tagIds?.map(item => item[1]).filter(item => item)
    return tags
  }

  const onFinish = (values: SegmentRuleFormValue) => {
    const { rules, ruleType, ...other } = values
    let rule
    if (ruleType === SegmentRuleType.FCFRule) {
      rule = fcfRule.getParams()
    } else {
      rules?.forEach(rule => {
        const { organizationIds, tagIds, studioIds } = rule
        if (rule.matchMethod === SegmentRuleMatchMethod.ENABLE) {
          assertRule.enabledRules = { organizationIds, tagIds: handleTags(tagIds), studioIds }
        }
        if (rule.matchMethod === SegmentRuleMatchMethod.DISABLE) {
          assertRule.disabledRules = { organizationIds, tagIds: handleTags(tagIds), studioIds }
        }
      })
      rule = assertRule.getParams()
    }

    const data: SegmentRuleRequest = {
      ...other,
      rule: rule,
    }
    if (segmentRuleId) {
      editSegmentRule(Number(segmentRuleId), data)
        .then(res => {
          notification.success({
            message: '编辑规则成功',
          })
          navigation(`/segment-rules/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建规则失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    } else {
      createSegmentRule(data)
        .then(res => {
          notification.success({
            message: '创建规则成功',
          })
          navigation(`/segment-rules/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建规则失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  async function fetchSegmentSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getSegmentSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  async function fetchStudiosSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getStudiosSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  async function fetchOrganizationsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getOrganizationsSuggestion(keyword).then(res => {
      return getOrganizationsSuggestionOptions(res)
    })
  }

  const getOrganizationsSuggestionOptions = (organizationsSuggestions: ISimpleOrganization[]) => {
    return organizationsSuggestions.map(item => {
      const name = item.organizationTranslations[0].name
      return {
        label: `${item.id}-${name}`,
        value: item.id,
      }
    })
  }

  const getDefaultOptions = (options: any[], index: number) => {
    if (options.length - 1 >= index) {
      return options[index]
    }
    return options[options.length - 1] ?? []
  }

  return (
    <Form
      name="segment-rule"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['segment-rule-form']}
    >
      <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称！' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="描述" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="segmentId"
        label="用户分组"
        rules={[{ required: true, message: '请选择用户分组！' }]}
      >
        <DebounceSelect
          showSearch={true}
          placeholder="搜索用户分组"
          fetchOptions={fetchSegmentSuggestionOptions}
          defaultOptions={defaultSegmentOptions}
        />
      </Form.Item>

      <Form.Item label="是否激活" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>

      <SectionTitle title="规则" />

      <Form.Item
        label="选择规则类型"
        name="ruleType"
        tooltip={
          <div>
            FCF: 对于选择用户只能预定FCF的课程
            <div>自定义：可以选择场馆,课程tag, organization 进行限制</div>
          </div>
        }
      >
        <Radio.Group>
          <Radio value={SegmentRuleType.FCFRule}>FCF</Radio>
          <Radio value={SegmentRuleType.AssertRule}>自定义</Radio>
        </Radio.Group>
      </Form.Item>
      {ruleTypeValue === SegmentRuleType.AssertRule && (
        <Form.List
          name="rules"
          initialValue={[
            { matchMethod: SegmentRuleMatchMethod.ENABLE },
            { matchMethod: SegmentRuleMatchMethod.DISABLE },
          ]}
        >
          {fields => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <div key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, 'matchMethod']}
                      label="匹配方式"
                      tooltip={
                        <div>
                          enabled: 仅匹配到以下规则的课程允许预定
                          <div>disabled: 匹配到以下规则的课程无法被预定</div>
                        </div>
                      }
                    >
                      <Radio.Group disabled>
                        <Radio value={SegmentRuleMatchMethod.ENABLE}>enabled</Radio>
                        <Radio value={SegmentRuleMatchMethod.DISABLE}>disabled</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'organizationIds']}
                      label="选择organization"
                    >
                      <DebounceSelect
                        allowClear
                        showSearch={true}
                        mode="multiple"
                        placeholder="可以通过id/name搜索organization"
                        fetchOptions={fetchOrganizationsSuggestionOptions}
                        defaultOptions={getDefaultOptions(defaultOrganizationOption, index)}
                      />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'studioIds']} label="选择场馆">
                      <DebounceSelect
                        allowClear
                        showSearch={true}
                        mode="multiple"
                        placeholder="可以通过id/name搜索场馆"
                        fetchOptions={fetchStudiosSuggestionOptions}
                        defaultOptions={getDefaultOptions(defaultStudioSuggestionsOption, index)}
                      />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'tagIds']} label="选择课程tag">
                      <Cascader
                        style={{ width: '100%' }}
                        options={tagTreeData}
                        multiple
                        maxTagCount="responsive"
                        showCheckedStrategy={SHOW_CHILD}
                        placeholder="请选择课程tag"
                      />
                    </Form.Item>
                  </div>
                )
              })}
            </>
          )}
        </Form.List>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    firstPageSegments: state.segments.firstPageSegments,
    allCategories: state.categories.allCategories,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(SegmentRuleForm)
