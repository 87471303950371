import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './segment.module.less'
import { ISegment } from '../share-type'
import { Outlet } from 'react-router-dom'
import { getSegmentDetail } from '../../../api/segmentApi'
import SegmentDescriptions from '../../../components/segments/segment-descriptions/segment-descriptions'

const Segment = () => {
  const params = useParams()
  const [segmentId] = useState<string | undefined>(params?.id)
  const [segment, setSegment] = useState<ISegment>()
  useEffect(() => {
    if (segmentId) {
      getSegmentDetail(Number(segmentId)).then(res => {
        setSegment(res)
      })
    }
  }, [segmentId])

  return (
    <Space className={styles['segment-desc-wrapper']} direction="vertical" wrap size={16}>
      {segment && <SegmentDescriptions segment={segment} />}

      <Outlet />
    </Space>
  )
}

export default Segment
