import translate from '../../i18n'

export enum CreditsAdjustmentType {
  ORGANIZATION = 'ORGANIZATION',
  STUDIO = 'STUDIO',
  LESSON_DESCRIPTION = 'LESSON_DESCRIPTION',
}

export enum ExchangeMetricOrderByEnums {
  TOTAL_PAYOUT_IN_CENTS = 'TOTAL_PAYOUT_IN_CENTS',
  EXCHANGE_RATE = 'EXCHANGE_RATE',
  COST_PER_CREDIT = 'COST_PER_CREDIT',
  EARLY_VISIT_RATE = 'EARLY_VISIT_RATE',
  BLENDED_FCF_PERCENTAGE = 'BLENDED_FCF_PERCENTAGE',
  UPDATED_AT = 'UPDATED_AT',
}

export const sortableFields = [
  {
    key: ExchangeMetricOrderByEnums.TOTAL_PAYOUT_IN_CENTS,
    label: translate('totalPayout'),
  },
  {
    key: ExchangeMetricOrderByEnums.EXCHANGE_RATE,
    label: translate('exchangeRate'),
  },
  {
    key: ExchangeMetricOrderByEnums.COST_PER_CREDIT,
    label: translate('costPerCredit'),
  },
  {
    key: ExchangeMetricOrderByEnums.EARLY_VISIT_RATE,
    label: translate('earlyVisitRate'),
  },
  {
    key: ExchangeMetricOrderByEnums.BLENDED_FCF_PERCENTAGE,
    label: translate('fcfPercentage'),
  },
  {
    key: ExchangeMetricOrderByEnums.UPDATED_AT,
    label: translate('updatedAt'),
  },
]

export enum SortOrderEnums {
  ASC = 'ASC',
  DESC = 'DESC',
}
