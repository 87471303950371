import qs from 'qs'

import config from '../config/index'
import {
  CorporateCreditsDistributionScheduleResponse,
  CorporateCreditsRequest,
  IBatchUploadByCorporateIdResponse,
  ICorporate,
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleHistory,
  ICorporateCreditsDistributionScheduleMember,
  ICorporateDetailResponse,
  IPaginatedCorporateCreditsDistributionScheduleFilterParams,
  IPaginatedCorporateCreditsHistoriesParams,
  IPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdParams,
  IPaginatedCorporateMembersParams,
  IPaginatedCorporatesParams,
  ISchedule,
  IUpdateCorporateMembers,
  PaginatedCorporateCreditsDistributionSchedulesResponse,
  PaginatedCorporateCreditsHistoriesResponse,
  PaginatedCorporateMemberCreditsHistoriesByCorproateMemberIdResponse,
  PaginatedCorporateMembersResponse,
  PaginatedCorporatesResponse,
  ReclaimCorporateCreditsRequest,
} from '../pages/corporates-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedCorporates = (data: IPaginatedCorporatesParams) =>
  authRequest.get<MISSING_TYPE, PaginatedCorporatesResponse>(`${mainApi}/corporates`, {
    params: data,
  })

export const getCorporateDetail = (corporateId: number) =>
  authRequest.get<MISSING_TYPE, ICorporateDetailResponse>(`${mainApi}/corporates/${corporateId}`)

export const createCorporate = (data: FormData) =>
  authRequest.post<MISSING_TYPE, ICorporate>(`${mainApi}/corporates`, data)

export const editCorporate = (corporateId: number, data: FormData) =>
  authRequest.put<MISSING_TYPE, ICorporate>(`${mainApi}/corporates/${corporateId}`, data)

export const distributeCorporateCredits = (corporateId: number, data: CorporateCreditsRequest) =>
  authRequest.put(`${mainApi}/corporate/${corporateId}/distribute-credits`, data)

export const getPaginatedCorporateMembersByCorporateId = (
  corporateId: number,
  data: IPaginatedCorporateMembersParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedCorporateMembersResponse>(
    `${mainApi}/corporate/${corporateId}/corporate-members`,
    { params: data },
  )

export const corporateMembersBulkUpload = (corporateId: number, data: FormData) =>
  authRequest.post<MISSING_TYPE, IBatchUploadByCorporateIdResponse>(
    `${mainApi}/corporate/${corporateId}/members-bulk-upload`,
    data,
  )

export const editCorporateMember = (
  corporateId: number,
  corporateMembersId: number,
  data: IUpdateCorporateMembers,
) =>
  authRequest.put(
    `${mainApi}/corporate/${corporateId}/corporate-members/${corporateMembersId}?isActive=${data.isActive}`,
  )

export const distributeCorporateCreditsByCorporateMember = (
  corporateId: number,
  corporateMembersId: number,
  data: CorporateCreditsRequest,
) =>
  authRequest.put(
    `${mainApi}/corporate/${corporateId}/distribute-credits-by-corporate-member/${corporateMembersId}`,
    data,
  )

export const reclaimCorporateCreditsByCorporateMember = (
  corporateId: number,
  corporateMembersId: number,
  data: ReclaimCorporateCreditsRequest,
) =>
  authRequest.put(
    `${mainApi}/corporate/${corporateId}/corporate-members/${corporateMembersId}/reclaim-credits`,
    null,
    { params: data },
  )

export const getPaginatedCorporateCreditsHistoriesByCorporateId = (
  corporateId: number,
  data: IPaginatedCorporateCreditsHistoriesParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedCorporateCreditsHistoriesResponse>(
    `${mainApi}/corporates/${corporateId}/credits/histories`,
    { params: data },
  )

export const reclaimCreditsBulkUploadByCorporateId = (corporateId: number, data: FormData) =>
  authRequest.post<MISSING_TYPE, IBatchUploadByCorporateIdResponse>(
    `${mainApi}/corporate/${corporateId}/reclaim-credits/bulk-upload`,
    data,
  )

export const distributeCreditsBulkUploadByCorporateId = (corporateId: number, data: FormData) =>
  authRequest.post<MISSING_TYPE, IBatchUploadByCorporateIdResponse>(
    `${mainApi}/corporate/${corporateId}/distribute-credits/bulk-upload`,
    data,
  )

export const getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberId = (
  corporateMemberId: number,
  data: IPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdParams,
) =>
  authRequest.get<
    MISSING_TYPE,
    PaginatedCorporateMemberCreditsHistoriesByCorproateMemberIdResponse
  >(`${mainApi}/corporate-members/${corporateMemberId}/credits/histories`, { params: data })

export const deactivateMembersBulkUploadByCorporateId = (corporateId: number, data: FormData) => {
  return authRequest.post<MISSING_TYPE, IBatchUploadByCorporateIdResponse>(
    `${mainApi}/corporate/${corporateId}/deactivate-members/bulk-upload`,
    data,
  )
}

export const getCorporatesSuggestions = (keyword: string, isActive?: boolean) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/corporates/suggestions`,
    {
      params: {
        keyword,
        isActive,
      },
    },
  )

export const getCorporatesSuggestionsByIds = (ids: number[]) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/corporates/suggestions-by-ids`,
    {
      params: {
        ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const createCorporateCreditsDistributionSchedule = (corporateId: number, data: FormData) =>
  authRequest.post<MISSING_TYPE, CorporateCreditsDistributionScheduleResponse>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules`,
    data,
  )

export const editCorporateCreditsDistributionSchedule = (
  corporateId: number,
  creditsDistributionScheduleId: number,
  data: FormData,
) =>
  authRequest.put<MISSING_TYPE, ICorporateCreditsDistributionSchedule>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules/${creditsDistributionScheduleId}`,
    data,
  )

export const getCorporateCreditsDistributionSchedules = (
  corporateId: number,
  data?: IPaginatedCorporateCreditsDistributionScheduleFilterParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedCorporateCreditsDistributionSchedulesResponse>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules`,
    {
      params: data,
    },
  )

export const getCorporateCreditsDistributionScheduleById = (
  corporateId: number,
  creditsDistributionScheduleId: number,
) =>
  authRequest.get<MISSING_TYPE, ICorporateCreditsDistributionSchedule>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules/${creditsDistributionScheduleId}`,
  )

export const getCorporateCreditsDistributionScheduleMembers = (
  corporateId: number,
  scheduleId: number,
  data?: IPaginatedCorporateCreditsDistributionScheduleFilterParams,
) =>
  authRequest.get<MISSING_TYPE, ICorporateCreditsDistributionScheduleMember[]>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules/${scheduleId}/members`,
    {
      params: data,
    },
  )

export const getCorporateCreditsDistributionScheduleHistories = (
  corporateId: number,
  scheduleId: number,
  data?: IPaginatedCorporateCreditsDistributionScheduleFilterParams,
) =>
  authRequest.get<MISSING_TYPE, ICorporateCreditsDistributionScheduleHistory[]>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules/${scheduleId}/histories`,
    {
      params: data,
    },
  )

export const editCorporateCreditsDistributionScheduleMember = (
  corporateId: number,
  scheduleId: number,
  memberId: number,
  data?: { isActive: boolean },
) =>
  authRequest.put<MISSING_TYPE, ICorporateCreditsDistributionScheduleMember>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules/${scheduleId}/members/${memberId}`,
    data,
  )

export const getCorporateCreditsDistributionScheduleExecutePlans = (
  corporateId: number,
  data: ISchedule,
) =>
  authRequest.post<MISSING_TYPE, string[]>(
    `${mainApi}/corporate/${corporateId}/credits-distribution-schedules/execute-plans`,
    data,
  )
