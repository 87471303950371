import { Button, Modal, Descriptions, Result } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { FormatCouponTemplate } from '../../../pages/coupon-templates-container/share-type'
import { useParams } from 'react-router-dom'
import * as _ from 'lodash'
import { getDateTime } from '../../../helpers/utils'

interface ICreateCouponSuccessModalProps {
  visible: boolean
  handleCancel: () => void
  handleOk: () => void
  goBackCouponList: () => void
  couponTemplate: FormatCouponTemplate
}

const CreateCouponSuccessModal = (props: ICreateCouponSuccessModalProps) => {
  const { visible, handleOk, handleCancel, goBackCouponList, couponTemplate } = props
  const params = useParams()
  const couponNameList = couponTemplate.translations.map(translation => translation.name)
  const couponName = _.join(couponNameList, '/')
  return (
    <Modal
      centered
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      title={null}
      footer={null}
      width={500}
    >
      <Result
        status="success"
        icon={<CheckCircleFilled style={{ color: '#52C41A', fontSize: '40px' }} />}
        title={params.id ? '优惠券模板编辑成功' : '优惠券模板创建成功'}
        subTitle={
          <Descriptions title={couponName} column={1}>
            <Descriptions.Item label="优惠券模板代码">{couponTemplate.code}</Descriptions.Item>
            <Descriptions.Item label="可用数量">{couponTemplate.totalAmount}</Descriptions.Item>
            <Descriptions.Item label="优惠券模板开始时间">
              {getDateTime(couponTemplate.startDate)}
            </Descriptions.Item>
            <Descriptions.Item label="优惠券模板结束时间">
              {getDateTime(couponTemplate.endDate)}
            </Descriptions.Item>
          </Descriptions>
        }
        extra={[
          <Button type="primary" key="back" onClick={goBackCouponList}>
            返回优惠券模板列表页
          </Button>,
          <Button key="submit" onClick={handleOk}>
            {params.id ? '继续编辑' : '继续创建'}
          </Button>,
        ]}
      />
    </Modal>
  )
}

export default CreateCouponSuccessModal
