import { Navigate } from 'react-router-dom'
import { Permission } from '../pages/admin-roles-container/share-type'
import NoMatch from '../pages/not-match/no-match'

function AuthRoute(props: any) {
  const {
    user,
    backUrl,
    permission: routePermission,
    path,
    element,
    auth,
    accessToken,
    children,
    permissions,
    clientType,
    ...otherporps
  } = props
  if (auth && !accessToken) {
    if (clientType === 'studios-area') {
      return {
        path: '/studios-area/*',
        element: <Navigate to={backUrl} />,
      }
    } else if (clientType === 'admin') {
      return {
        path: '/*',
        element: <Navigate to={backUrl} />,
      }
    }
  }
  const getRoute = () => {
    if (children) {
      const authChildren = children.map((item: any) => {
        return AuthRoute({ ...item, user, accessToken, permissions })
      })
      return {
        path: path,
        element: element,
        children: authChildren,
        ...otherporps,
      }
    }
    return {
      path: path,
      element: element,
      ...otherporps,
    }
  }

  if (routePermission && permissions) {
    const userPermissions = permissions.map((item: Permission) => item.scope)
    if (userPermissions.indexOf(routePermission) > -1) {
      return getRoute()
    } else {
      return {
        path: '*',
        element: <NoMatch />,
      }
    }
  } else {
    return getRoute()
  }
}

export default AuthRoute
