import { UploadFile } from 'antd'
import * as _ from 'lodash'

export const getImageFileName = (filename: string, files?: UploadFile[]) => {
  if (!files || files.length === 0) {
    return ''
  }

  const file = files[0]
  if (_.hasIn(file, 'url')) {
    return file.name ?? ''
  } else {
    const file = files[0]
    const fileExtension = file?.name.split('.').pop()
    return `${filename}_${file.uid}.${fileExtension}`
  }
}

export const getImageUrlToUploadFileInput = (urls?: string[], uid?: string): UploadFile[] => {
  if (!urls) {
    return []
  }

  return urls.map((url, index) => ({
    status: 'done',
    url: url,
    thumbUrl: url,
    name: getImageFileNameFromUrl(url),
    uid: uid ?? index.toString(),
  }))
}

export const getImageFileNameFromUrl = (url: string) => {
  const lastSlashIndex = url.lastIndexOf('/')
  const result = url.substring(lastSlashIndex + 1)
  return result
}
