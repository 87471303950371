import { Button, Col, Row, Space } from 'antd'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PageInfo } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ICorporate, ICorporateCreditsHistoryFilterParams } from '../share-type'
import {
  getCorporateDetail,
  getPaginatedCorporateCreditsHistoriesByCorporateId,
} from '../../../api/corporateApi'
import Table, { ColumnsType } from 'antd/lib/table'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import { ICorporateCreditsHistory, IPaginatedCorporateCreditsHistoriesParams } from '../share-type'
import translate from '../../../i18n'
import CorporateCreditsHistoriesFilter from '../../../components/corporate-credits-histories/corporate-credits-histories-filter'
import {
  CorporateCreditsHistoryTypeEnum,
  CorporateCreditsHistoryTypeEnumOptions,
} from '../constant'
import styles from './corporate-credits-histories.module.less'
import CorporateCreditsModal from '../../../components/corporates/corporate-credits-modal/corporate-credits-modal'
import { FormattedMessage } from '../../../components/intl'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const CorporateCreditsHistories = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [corporateId] = useState<number | undefined>(Number(params?.id))
  const [searchParams, setSearchParams] = useSearchParams()
  const [corporateCreditsHistories, setCorporateCreditsHistories] = useState<
    ICorporateCreditsHistory[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ICorporateCreditsHistoryFilterParams>()
  const [visiable, setVisiable] = useState<boolean>(false)
  const [corporate, setCorporate] = useState<ICorporate>()

  useEffect(() => {
    if (!corporateId) {
      return
    }
    getCorporateDetail(corporateId).then(res => {
      setCorporate(res.corporate)
    })

    const newSearchValues = getSearchValues(
      [
        { key: 'type', isBoolean: false },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedCorporateCreditsHistoriesAction(data)
  }, [corporateId])

  const getPaginatedCorporateCreditsHistoriesAction = (
    data: IPaginatedCorporateCreditsHistoriesParams,
  ) => {
    if (!corporateId) {
      return
    }

    getPaginatedCorporateCreditsHistoriesByCorporateId(corporateId, data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setCorporateCreditsHistories(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedCorporateCreditsHistoriesAction(data)
  }
  const onSearch = (value: ICorporateCreditsHistoryFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(data))
    getPaginatedCorporateCreditsHistoriesAction(data)
  }

  const onSubmitCorporateCreditsAdjustment = () => {
    if (!corporateId) {
      return
    }
    getCorporateDetail(corporateId).then(res => {
      setCorporate(res.corporate)
    })
    getPaginatedCorporateCreditsHistoriesAction({
      size: pageInfo.pageSize,
      page: pageInfo.currentPage - 1,
      ...searchValues,
    })
  }

  const columns: ColumnsType<ICorporateCreditsHistory> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('corporateCreditsHistories.credits'),
      dataIndex: 'credits',
      key: 'credits',
      render: (credits: number) => {
        return credits > 0 ? <span>+{credits}</span> : credits
      },
    },
    {
      title: translate('corporateCreditsHistories.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: CorporateCreditsHistoryTypeEnum) => {
        return CorporateCreditsHistoryTypeEnumOptions.find(item => item.value === type)?.label
      },
    },
    {
      title: translate('corporateCreditsHistories.createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: translate('corporateCreditsHistories.notes'),
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return getDateTime(createdAt)
        }
        return ''
      },
      width: 180,
    },
  ]

  const goBack = () => {
    navigate(-1)
  }

  const onCorporateCreditsAdjustment = () => {
    setVisiable(true)
  }

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <FormattedMessage
            id="corporateCreditsHistories.viewDetail"
            values={{
              corporateId: corporate?.id,
              corporateName: corporate?.name,
            }}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Button type="primary" onClick={() => onCorporateCreditsAdjustment()}>
            {translate('corporate.credits.adjustment')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <FormattedMessage
            id="corporate.totalCredits"
            values={{
              credits: corporate?.credits,
            }}
          />
        </Col>
      </Row>
      <Row className="filter-bar">
        <CorporateCreditsHistoriesFilter onSearch={onSearch} formData={searchValues} />
      </Row>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={corporateCreditsHistories}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
        scroll={{ x: 1200 }}
      />

      <Space className={styles['button-group']}>
        <Button onClick={goBack}>返回</Button>
      </Space>

      {corporate && (
        <CorporateCreditsModal
          adjustmentCorporate={corporate}
          visiable={visiable}
          onCancel={() => setVisiable(false)}
          onSubmit={onSubmitCorporateCreditsAdjustment}
        />
      )}
    </Space>
  )
}

export default CorporateCreditsHistories
