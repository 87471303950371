import { Button, Col, Row, Space, Table } from 'antd'
import translate from '../../../i18n'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import {
  IIPrelaunchOrganizationFormsFilterParams,
  IPaginatedIPrelaunchOrganizationFormsFilterParams,
  IPrelaunchOrganizationForm,
  ISimplePrelaunchOrganizationForm,
} from '../share-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import { getPaginatedPrelaunchOrganizationForms } from '../../../api/prelaunchOrganizationFormsApi'
import { ColumnsType } from 'antd/lib/table'
import PrelaunchOrganizationFormsFilter from '../../../components/prelaunch-organization-forms/prelaunch-organization-forms-filter/prelaunch-organization-forms-filter'
import LinkButton from '../../../components/link-button/link-button'
import PrelaunchOrganizationFormsFormModal from '../../../components/prelaunch-organization-forms/prelaunch-organization-forms-form-modal/prelaunch-organization-forms-form-modal'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const PrelaunchOrganizationForms = () => {
  const [prelaunchOrganizationForms, setPrelaunchOrganizationForms] = useState<
    ISimplePrelaunchOrganizationForm[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IIPrelaunchOrganizationFormsFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'organizationId', isBoolean: false },
        { key: 'uniqueId', isBoolean: false },
        { key: 'status', isBoolean: false },
        { key: 'expiredAtAfter', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedPrelaunchOrganizationFormsAction(data)
  }, [])

  const getPaginatedPrelaunchOrganizationFormsAction = (
    data: IPaginatedIPrelaunchOrganizationFormsFilterParams,
  ) => {
    getPaginatedPrelaunchOrganizationForms(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setPrelaunchOrganizationForms(res.data)
      }
    })
  }

  const columns: ColumnsType<ISimplePrelaunchOrganizationForm> = [
    {
      title: translate('prelaunchOrganizationForms.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: translate('prelaunchOrganizationForms.uniqueId'),
      dataIndex: 'uniqueId',
      key: 'uniqueId',
    },
    {
      title: translate('prelaunchOrganizationForms.organizationId'),
      dataIndex: 'organizationId',
      key: 'organizationId',
    },
    {
      title: translate('prelaunchOrganizationForms.organizationName'),
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: translate('prelaunchOrganizationForms.expiredAt'),
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: (expiredAt: string) => {
        if (expiredAt) {
          return <span>{getDateTime(expiredAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'actions',
      render: (record: ISimplePrelaunchOrganizationForm) => {
        return (
          <Space>
            <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ}>
              <LinkButton to={`/prelaunch-organization-forms/${record.id}`}>
                {translate('view')}
              </LinkButton>
            </AuthPermission>
          </Space>
        )
      },
    },
  ]

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    getPaginatedPrelaunchOrganizationFormsAction(data)
  }

  const onSearch = (value: IIPrelaunchOrganizationFormsFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedPrelaunchOrganizationFormsAction(data)
  }

  const onCreatePrelaunchOrganizationForm = () => {
    setOpen(true)
  }

  const handlePrelaunchOrganizationFormSubmit = (res: IPrelaunchOrganizationForm) => {
    setOpen(false)
    navigate(`/prelaunch-organization-forms/${res.id}`)
  }

  return (
    <>
      <Space className="table-space" direction="vertical" wrap size={16}>
        <Row align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
            <div>{translate('navBar.prelaunchOrganizationFormsTitle')}</div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
            <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onCreatePrelaunchOrganizationForm}
              >
                {translate('prelaunchOrganizationsForm.create')}
              </Button>
            </AuthPermission>
          </Col>
        </Row>
        <Row className="filter-bar">
          <PrelaunchOrganizationFormsFilter onSearch={onSearch} formData={searchValues} />
        </Row>

        <Table
          rowKey={record => record.uniqueId}
          columns={columns}
          dataSource={prelaunchOrganizationForms}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
        />
      </Space>

      <PrelaunchOrganizationFormsFormModal
        open={open}
        handleCancel={() => setOpen(false)}
        handleSubmit={handlePrelaunchOrganizationFormSubmit}
      />
    </>
  )
}

export default PrelaunchOrganizationForms
