import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './studio.module.less'
import { IStudio } from '../share-type'
import StudioDescriptions from '../../../components/studios/studio-descriptions/studio-descriptions'
import { getStudioDetail } from '../../../api/studiosApi'

const Studio = () => {
  const params = useParams()
  const [studioId] = useState<string | undefined>(params?.id)
  const [studio, setStudio] = useState<IStudio>()
  useEffect(() => {
    loadStudioDetail()
  }, [studioId])

  const loadStudioDetail = () => {
    if (studioId) {
      getStudioDetail(Number(studioId)).then(res => {
        setStudio(res)
      })
    }
  }

  return (
    <Space className={styles['studio-desc-wrapper']} direction="vertical" wrap size={16}>
      {studio && <StudioDescriptions studio={studio} onLoadStudioDetail={loadStudioDetail} />}
    </Space>
  )
}

export default Studio
