import { ADD_REQUEST_COUNT, REDUCE_REQUEST_COUNT, SHOW_SPINNER, HIDE_SPINNER } from './constants'

export interface ShowSpinnerAction {
  type: SHOW_SPINNER
  payload: {
    show: boolean
  }
}

export const showSpinner = (): ShowSpinnerAction => {
  return {
    type: SHOW_SPINNER,
    payload: {
      show: true,
    },
  }
}

export interface HideSpinnerAction {
  type: HIDE_SPINNER
  payload: {
    show: boolean
  }
}

export const hideSpinner = (): HideSpinnerAction => {
  return {
    type: HIDE_SPINNER,
    payload: {
      show: false,
    },
  }
}

export interface AddRequestCountAction {
  type: ADD_REQUEST_COUNT
}

export const addRequestCount = () => {
  return {
    type: ADD_REQUEST_COUNT,
  }
}

export interface ReduceRequestCountAction {
  type: REDUCE_REQUEST_COUNT
}

export const reduceRequestCount = () => {
  return {
    type: REDUCE_REQUEST_COUNT,
  }
}

export type SpinnerAction =
  | ShowSpinnerAction
  | HideSpinnerAction
  | AddRequestCountAction
  | ReduceRequestCountAction
