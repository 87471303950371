import { OneTimeCodeStatus } from './share-type'

export const OneTimeCodeStatusOptions = [
  {
    text: '激活',
    label: '激活',
    value: OneTimeCodeStatus.ACTIVE,
    color: 'green',
  },
  {
    label: '停用',
    text: '停用',
    value: OneTimeCodeStatus.INACTIVE,
    color: 'red',
  },
  {
    label: '仅限内部',
    text: '仅限内部',
    value: OneTimeCodeStatus.INTERNAL_ONLY,
    color: 'blue',
  },
]

export const CHAR_CODE_0 = 0x0030
export const CHAR_CODE_Z = 0x007a
export const EXPORT_PASSORD_LENGTH = 12
