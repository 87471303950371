import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'

const StudioIntegrationConfigContainer = () => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/studio-integration-config\/[0-9]+\/lesson-description-config\/new/)) {
      return translate('navBar.newStudioIntegrationLessonDescriptionConfig')
    }

    if (
      pathName.match(
        /^\/studio-integration-config\/[0-9]+\/lesson-description-config\/[0-9]+\/edit/,
      )
    ) {
      return translate('navBar.editStudioIntegrationLessonDescriptionConfig')
    }

    if (pathName.match(/^\/studio-integration-config\/[0-9]+\/lesson-description-config\/[0-9]+/)) {
      return translate('navBar.viewStudioIntegrationLessonDescriptionConfig')
    }

    if (pathName.match(/^\/studio-integration-config\/[0-9]+\/lesson-config\/new/)) {
      return translate('navBar.newStudioIntegrationLessonConfig')
    }

    if (pathName.match(/^\/studio-integration-config\/[0-9]+\/lesson-config\/[0-9]+\/edit/)) {
      return translate('navBar.editStudioIntegrationLessonConfig')
    }

    if (pathName.match(/^\/studio-integration-config\/[0-9]+\/lesson-config\/[0-9]+/)) {
      return translate('navBar.viewStudioIntegrationLessonConfig')
    }

    if (pathName.match(/^\/studio-integration-config\/[0-9]+$/)) {
      return translate('navBar.viewStudioIntegrationConfig')
    }

    return translate('navBar.studioIntegrationConfig')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default StudioIntegrationConfigContainer
