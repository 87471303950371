import { Button, Col, Row, Space, Table, Tag } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getSearchParams, getSearchValues } from '../../../helpers/utils'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { IPaginatedTagsFilterParams, ITag, ITagsFilterParams } from '../share-type'
import { getPaginatedTags } from '../../../api/tagsApi'
import TagsFilter from '../../../components/tags/tags-filter/tags-filter'
import { TagCategoryEnums, TagSubCategoryEnums } from '../constant'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const Tags = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [tags, setTags] = useState<ITag[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ITagsFilterParams>()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
        { key: 'name', isBoolean: false },
        { key: 'isActive', isBoolean: true },
        { key: 'category', isBoolean: false },
        { key: 'subCategory', isBoolean: false },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedTagsAction(data)
  }, [])

  const getPaginatedTagsAction = (data: IPaginatedTagsFilterParams) => {
    getPaginatedTags(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setTags(res.data)
      }
    })
  }

  const onSearch = (value: ITagsFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(data))
    getPaginatedTagsAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    setSearchParams(getSearchParams(data))

    getPaginatedTagsAction(data)
  }

  const columns: ColumnsType<ITag> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('tags.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('tags.isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => {
        return <YesOrNoTag value={isActive} />
      },
    },
    {
      title: translate('tags.taggingsCount'),
      dataIndex: 'taggingsCount',
      key: 'taggingsCount',
    },
    {
      title: translate('tags.cachedCategoryList'),
      dataIndex: 'cachedCategoryList',
      key: 'cachedCategoryList',
    },
    {
      title: translate('tags.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category: TagCategoryEnums) => {
        return <Tag>{category ?? 'N/A'}</Tag>
      },
    },
    {
      title: translate('tags.subCategory'),
      dataIndex: 'subCategory',
      key: 'subCategory',
      render: (subCategory: TagSubCategoryEnums) => {
        return <Tag>{subCategory ?? 'N/A'}</Tag>
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: ITag) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.TAGS_WRITE}>
            <LinkButton to={`/tags/${record.id}/edit`}>{translate('edit')}</LinkButton>
          </AuthPermission>

          <LinkButton to={`/tags/${record.id}`}>{translate('view')}</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.tagsTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.TAGS_WRITE}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('/tags/new')}>
              {translate('navBar.newTag')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>

      <Row className="filter-bar">
        <TagsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={tags}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default Tags
