import { Button, Col, Dropdown, Row, Space, Table, Tag, notification } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { DownOutlined, PlusOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import {
  IOneTimeCodeBatch,
  IOneTimeCodeBatchesFilterParams,
  IPaginatedOneTimeCodeBatchesParams,
  OneTimeCodeStatus,
} from '../share-type'
import {
  getPaginatedOneTimeCodeBatches,
  oneTimeCodeBatchChangeStatus,
} from '../../../api/oneTimeCodeApi'
import OneTimeCodeBatchesFilter from '../../../components/one-time-code-batches/one-time-code-batches-filter/one-time-code-batches-filter'
import { OneTimeCodeStatusOptions } from '../constant'
import styles from './one-time-code-batches.module.less'
import FormattedMessage from '../../../components/intl/format-message'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const OneTimeCodeBatches = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [oneTimeCodeBatches, setOneTimeCodeBatches] = useState<IOneTimeCodeBatch[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IOneTimeCodeBatchesFilterParams>()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
        { key: 'id', isBoolean: false },
        { key: 'name', isBoolean: false },
        { key: 'isNewUserOnly', isBoolean: false },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedOneTimeCodeBatchesAction(data)
  }, [])

  const getPaginatedOneTimeCodeBatchesAction = (data: IPaginatedOneTimeCodeBatchesParams) => {
    getPaginatedOneTimeCodeBatches(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setOneTimeCodeBatches(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedOneTimeCodeBatchesAction(data)
  }
  const onSearch = (value: IOneTimeCodeBatchesFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedOneTimeCodeBatchesAction(data)
  }

  const onChangeStatus = (e: any, record: IOneTimeCodeBatch) => {
    oneTimeCodeBatchChangeStatus(record.id, e.key)
      .then(() => {
        notification.success({
          message: '修改状态成功',
        })
        getPaginatedOneTimeCodeBatchesAction({
          size: pageInfo.pageSize,
          page: 0,
          ...searchValues,
        })
      })
      .catch(err => {
        notification.error({
          message: '修改状态失败',
          description: err?.message || translate('networkError'),
        })
      })
  }

  const getActionItems = (record: IOneTimeCodeBatch) => {
    const actionList = OneTimeCodeStatusOptions.filter(item => item.value !== record.status)

    return actionList.map(item => {
      const icon =
        item.value === OneTimeCodeStatus.INACTIVE ? <StopOutlined /> : <CheckCircleOutlined />

      return {
        key: item.value,
        label: (
          <>
            <Button type="link" className={`status-button-${item.color}`} icon={icon}>
              {item.label}
            </Button>
          </>
        ),
      }
    })
  }

  const columns: ColumnsType<IOneTimeCodeBatch> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('oneTimeCodeBatches.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('oneTimeCodeBatches.credits'),
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: translate('oneTimeCodeBatches.isNewUserOnly'),
      dataIndex: 'isNewUserOnly',
      key: 'isNewUserOnly',
      render: (isActive: number) => (
        <YesOrNoTag value={isActive} yesColor="geekblue" noColor="cyan" />
      ),
    },
    {
      title: translate('oneTimeCodeBatches.creditsValidityPeriodInDays'),
      dataIndex: 'creditsValidityPeriodInDays',
      key: 'creditsValidityPeriodInDays',
    },
    {
      title: translate('oneTimeCodeBatches.timeRange'),
      dataIndex: 'usableWindowTimeRange',
      key: 'usableWindowStartTime',
      render: (usableWindowStartTime: string | null, record: IOneTimeCodeBatch) => {
        return (
          <span>
            {record.usableWindowStartTime && record.usableWindowEndTime
              ? `${getDateTime(record.usableWindowStartTime)} ~ ${getDateTime(
                  record.usableWindowEndTime,
                )}`
              : ''}
          </span>
        )
      },
    },
    {
      title: translate('oneTimeCodeBatches.ikeaSegment'),
      dataIndex: 'ikeaSegmentId',
      key: 'ikeaSegmentId',
    },
    {
      title: translate('oneTimeCodeBatches.status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: OneTimeCodeStatus) => {
        const tag = OneTimeCodeStatusOptions.find(item => item.value === status)
        return <Tag color={tag?.color}>{tag?.label}</Tag>
      },
      filters: OneTimeCodeStatusOptions,
      filterIcon: <DownOutlined />,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IOneTimeCodeBatch) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.ONE_TIME_CODES_WRITE}>
            <Dropdown
              menu={{
                items: getActionItems(record),
                onClick: e => onChangeStatus(e, record),
              }}
            >
              <a className={styles['action-item']}>
                <FormattedMessage id="oneTimeCodeBatches.changeStatus" />
              </a>
            </Dropdown>
          </AuthPermission>

          <AuthPermission permission={PermissionsEnum.ONE_TIME_CODES_WRITE}>
            <LinkButton to={`/one-time-code-batches/${record.id}/edit`}>
              {translate('edit')}
            </LinkButton>
          </AuthPermission>

          <LinkButton to={`/one-time-code-batches/${record.id}`}>{translate('view')}</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>One Time Code Batch</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.ONE_TIME_CODES_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/one-time-code-batches/new')}
            >
              {translate('navBar.newOneTimeCodeBatch')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <OneTimeCodeBatchesFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={oneTimeCodeBatches}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default OneTimeCodeBatches
