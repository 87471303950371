import qs from 'qs'

import config from '../config/index'
import {
  ILessonDescription,
  IPaginatedLessonDescription,
  IPaginatedLessonDescriptionFilterParams,
} from '../pages/lesson-description-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getLessonDescriptionsSuggestion = (keyword: string) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/lesson-descriptions/suggestions?keyword=${keyword}`,
  )

export const getLessonDescriptionsSuggestionsByIds = (ids: number[]) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/lesson-descriptions/suggestions-by-ids`,
    {
      params: {
        ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const getLessonDescriptionsSuggestionsByIdAndName = (keyword: string) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/lesson-descriptions/suggestions-by-id-and-name?keyword=${keyword}`,
  )

export const getLessonDescriptions = (params: IPaginatedLessonDescriptionFilterParams) =>
  authRequest.get<MISSING_TYPE, IPaginatedLessonDescription>(`${mainApi}/lesson-descriptions`, {
    params,
  })

export const getLessonDescriptionDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ILessonDescription>(`${mainApi}/lesson-descriptions/${id}`)
