import { Form, Input, Button, Tree, notification } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { ReduxStore } from '../../../store'
import styles from './admin-role-form.module.less'
import * as _ from 'lodash'
import type { DataNode } from 'antd/lib/tree'
import { createAdminRole, editAdminRole, getAdminRoleById } from '../../../api/roleApi'
import { IAdminRoleParams, Permission } from '../share-type'
import { getFormatAdminRole } from '../../../helpers/role-helper'

interface IRoleForm {
  name: string
  permissions: string[]
}

const AdminUserRoleForm = (props: ConnectedProps<typeof withConnect>) => {
  const params = useParams()
  const navigation = useNavigate()

  const roleId = params?.id
  const [form] = Form.useForm<IRoleForm>()

  useEffect(() => {
    if (roleId) {
      getAdminRoleById(Number(roleId)).then(res => {
        const formatRole = getFormatAdminRole(res)
        const initForm = {
          ...formatRole,
          permissions: formatRole.permissions.map(item => item.scope),
        }
        form.setFieldsValue(initForm)
      })
    }
  }, [roleId])

  const groupFilter = (value: Permission) => {
    return value.scope.replace(/:.*/, '')
  }

  const getTreeData = () => {
    const { permissions } = props
    const groupObj = _.groupBy(permissions, groupFilter)
    const result: DataNode[] = []
    _.forIn(groupObj, (value, key) => {
      result.push({
        title: key,
        key,
        children: value.map(item => {
          return {
            title: item.scope,
            key: item.scope,
          }
        }),
      })
    })
    return result
  }

  const onFinish = (values: IRoleForm) => {
    const permissions = values.permissions.filter(item => {
      return item.indexOf(':') !== -1
    })
    const permissionsIds = permissions.map(permission => {
      const filter = props.permissions.find((item: Permission) => item.scope === permission)
      return filter.id
    })

    const data: IAdminRoleParams = {
      ...values,
      permissions: permissionsIds,
    }
    if (roleId) {
      editAdminRole(roleId, data).then(() => {
        notification.success({
          message: '修改角色成功',
        })
        navigation('/admin-roles')
      })
    } else {
      createAdminRole(data).then(() => {
        notification.success({
          message: '创建新角色成功',
        })
        navigation('/admin-roles')
      })
    }
  }

  const treeData = getTreeData()

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="normal_login"
      className={styles['user-form']}
      onFinish={onFinish}
    >
      <Form.Item
        label="角色名称"
        name="name"
        rules={[{ required: true, message: '请输入角色名!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="permissions"
        label="角色权限"
        rules={[{ required: true, message: '请选择角色权限' }]}
        valuePropName="checkedKeys"
        trigger="onCheck"
      >
        <Tree checkable defaultExpandAll treeData={treeData} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          确定
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    permissions: state.global.permissions,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(AdminUserRoleForm)
