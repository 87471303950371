import { PageInfo } from '../../share-types'
import { CouponTemplateDistributionFrequencyModeEnums } from './constant'

export enum CouponDiscountTypeEnums {
  PERCENTAGE_OFF = 'PERCENTAGE_OFF',
  GIFT_CARD = 'GIFT_CARD',
  CASH_OFF = 'CASH_OFF',
  BUY_ONE_GET_MORE = 'BUY_ONE_GET_MORE',
  STACKABLE_CASH_OFF = 'STACKABLE_CASH_OFF',
}

export enum ForPeopleTypeEnums {
  ALL_USER = 'ALL_USER',
  NEW_USER_ONLY = 'NEW_USER_ONLY',
  OLD_USER_ONLY = 'OLD_USER_ONLY',
  SPECIAL_USER_ONLY = 'SPECIAL_USER_ONLY',
  CORPORATE_USERS = 'CORPORATE_USERS',
}

export enum CouponUsageRulesEnums {
  SINGLE_USE,
  REUSE,
}

export enum CouponTypeRulesEnums {
  NONE,
  REPURCHASE_RULE,
  TRIAL_USER_REPURCHASE_RULE,
}

export enum CouponFormMode {
  CREATE,
  EDIT,
  DUPLICATE,
}

export const couponRuleTypeOptions = [
  {
    label: '一般优惠券',
    text: '一般优惠券',
    value: CouponTypeRulesEnums.NONE,
  },
  {
    label: '老用户复购优惠券',
    text: '老用户复购优惠券',
    value: CouponTypeRulesEnums.REPURCHASE_RULE,
  },
  {
    label: 'IKEA用户复购优惠券',
    text: 'IKEA用户复购优惠券',
    value: CouponTypeRulesEnums.TRIAL_USER_REPURCHASE_RULE,
  },
]

export enum CouponTemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INTERNAL_ONLY = 'INTERNAL_ONLY',
}

export enum ValidityTypeEnums {
  NATURAL = 'NATURAL',
  FIXED = 'FIXED',
}

export enum ApplicableItemType {
  PLAN = 'PLAN',
}

export type CouponTemplateTranslation = {
  id?: number
  name: string
  discountTitle: string
  locale: string
  ruleTitle: string
  ruleDescription: string
  discountOff: number
}

export type CouponTemplateRule = {
  updatedAt: string
  createdAt: string
  rule: MISSING_TYPE
  id: number
}

export type CouponTemplate = {
  id: number
  code: string
  applicableItemType: ApplicableItemType
  status: string
  isAuto: boolean
  validityType: string
  validityDays: number
  showInPopup: boolean
  countDownBeforeMinutes: null | number
  startDate: string
  endDate: string
  translations: CouponTemplateTranslation[]
  rules: CouponTemplateRule[]
  discount: any
  usedCount: number
  issuedCount: number
}

export type FormatCouponTemplate = CouponTemplate & {
  cities?: number[]
  couponUsageRule?: CouponUsageRulesEnums
  forPeople?: ForPeopleTypeEnums
  userIds?: number[]
  totalAmount?: number
  refreshInterval?: number
  distributionFrequencyMode?: CouponTemplateDistributionFrequencyModeEnums
  usageLimit?: number
  couponRuleType?: CouponTypeRulesEnums
  plans: any[]
  usedCount: number
  issuedCount: number
  segmentId?: number
  corporateIds?: number[]
}

export type PaginatedCouponTemplate = {
  pageInfo: PageInfo
  data: CouponTemplate[]
}

export interface CreateCouponTemplateParams {
  code?: string
  isAuto: boolean
  showInPopup: boolean
  translations: CouponTemplateTranslation[]
  discount: any
  rules: any[]
  startDate: any
  endDate: any
  countDownBeforeMinutes: null | number
}

export interface ICouponTemplateFilterParams {
  name?: string
  code?: string
  status?: CouponTemplateStatus
  startDate?: string
  endDate?: string
  discountType?: CouponDiscountTypeEnums
}

export type IPaginatedCouponTemplateFilterParams = {
  page?: number
  size?: number
} & ICouponTemplateFilterParams
