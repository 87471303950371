import { Button, Card, Table, Tag } from 'antd'
import { IStudioAreaUserDto, PageInfo } from '../../../../share-types'
import BillingSummaryMonthSelector from '../billing-summary-month-selector/billing-summary-month-selector'
import styles from './billing-summary-details.module.less'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  exportBillingDetails,
  getBillingDetails,
} from '../../../../studios-area-api/billingSummariesApi'
import {
  BillingDetailDto,
  BillingDetailQueryParams,
} from '../../../pages/billing-summaries/share-type'
import { getDate, getTime } from '../../../../helpers/utils'
import {
  BillingDetailType,
  BillingDetailTypeOptions,
} from '../../../pages/billing-summaries/constants'

interface IProps {
  studioIds?: number[] | null
  studioAdminUserInfo: IStudioAreaUserDto
}

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const BillingSummaryDetails = (props: IProps) => {
  const { studioIds, studioAdminUserInfo } = props
  const [selectedMonth, setSelectedMonth] = useState<string | null>(
    moment().startOf('month').toISOString(),
  )

  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [billingDetails, setBillingDetails] = useState<BillingDetailDto[]>([])

  const onChangeSelectedMonth = (value: string) => {
    setSelectedMonth(value)
  }

  useEffect(() => {
    const startDate = moment(selectedMonth).startOf('month')
    const endDate = moment(selectedMonth).isSame(moment(), 'month')
      ? moment()
      : moment(selectedMonth).endOf('month')
    const data = {
      studioIds: studioIds,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
    }
    getBillingSummaryDetailsAction(data)
  }, [selectedMonth, studioIds])

  const columns = [
    {
      title: '预约ID',
      dataIndex: 'bookingId',
      key: 'bookingId',
    },
    {
      title: '课程名称',
      dataIndex: 'lessonName',
      key: 'lessonName',
    },
    {
      title: '课程日期',
      dataIndex: 'lessonStartTime',
      key: 'lessonStartTime',
      render: (lessonStartTime: string) => {
        return getDate(lessonStartTime)
      },
    },
    {
      title: '课程时间',
      dataIndex: 'lessonStartTime',
      key: 'lessonTime',
      render: (lessonStartTime: string) => {
        return getTime(lessonStartTime)
      },
    },
    {
      title: '预约用户',
      dataIndex: 'bookingUser',
      key: 'bookingUser',
    },
    {
      title: '用户电话',
      dataIndex: 'userPhoneNumber',
      key: 'userPhoneNumber',
    },
    {
      title: '工作室名称',
      dataIndex: 'studioName',
      key: 'studioName',
    },
    {
      title: '预约码',
      dataIndex: 'bookingRef',
      key: 'bookingRef',
    },
    {
      title: '状态',
      dataIndex: 'type',
      key: 'type',
      render: (type: BillingDetailType) => {
        const lable = BillingDetailTypeOptions.find(item => item.value === type)?.label
        return <Tag>{lable}</Tag>
      },
    },
    {
      title: '结算金额',
      dataIndex: 'gpRate',
      key: 'gpRate',
    },
  ]

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      studioIds: props.studioIds,
      startDate: moment(selectedMonth).toISOString(),
      endDate: moment(selectedMonth).endOf('month').toISOString(),
    }
    getBillingSummaryDetailsAction(data)
  }

  const getBillingSummaryDetailsAction = (data: BillingDetailQueryParams) => {
    getBillingDetails(data).then(res => {
      if (res) {
        setBillingDetails(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const onDownloadBillingSummary = () => {
    const startDate = moment(selectedMonth)
    const endDate = moment(selectedMonth).endOf('month')
    const data = {
      studioIds: props.studioIds,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }

    exportBillingDetails(data).then(res => {
      if (res) {
        // Add this `\ufeff` to the beginning of the file to make this csv support Chinese characters
        const url = window.URL.createObjectURL(new Blob([`\ufeff${res}`]))
        const link = document.createElement('a')
        link.href = url
        const name = `billing-summary-${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}.csv`
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      }
    })
  }

  return (
    <div>
      <Card className="studios-area-card" bordered={false}>
        <div className="display-flex gap-12">
          <div className={`studios-area-card-title`}>账单报告</div>
          <div className="display-flex flex-1 justify-between">
            <div className={styles['billing-summary-tips']}>
              这里展示账单主要信息，其他具体内容可下载完整账单查看
            </div>
            <Button type="primary" shape="round" onClick={onDownloadBillingSummary}>
              下载完整账单
            </Button>
          </div>
        </div>

        <div className={styles['billing-summary-header']}>
          <BillingSummaryMonthSelector
            value={selectedMonth}
            onChangeSelectedMonth={onChangeSelectedMonth}
            studioAdminUserInfo={studioAdminUserInfo}
          />
        </div>

        <Table
          rowKey={record => record.bookingId}
          columns={columns}
          dataSource={billingDetails}
          pagination={{
            current: pageInfo?.currentPage,
            pageSize: pageInfo?.pageSize,
            total: pageInfo?.count,
            onChange: paginationOnChange,
          }}
        />
      </Card>
    </div>
  )
}

export default BillingSummaryDetails
