import { Button, Form, Input, InputNumber, Modal } from 'antd'
import { request } from '../../../../request'
import { PlusCircleOutlined } from '@ant-design/icons'
import { getBillingInvoiceUploadPreSignedUrl } from '../../../../studios-area-api/billingInvoicesApi'
import { BillingInvoiceCreateRequest } from '../../../../pages/billing-invoices-container/share-type'
import Dragger from 'antd/lib/upload/Dragger'
import { useEffect } from 'react'

interface IProps {
  open: boolean
  handleSubmit: (values: BillingInvoiceCreateRequest) => void
  handleCancel: () => void
}
const BillingInvoicesUploadModal = (props: IProps) => {
  const { open, handleCancel, handleSubmit } = props
  const [form] = Form.useForm()

  useEffect(() => {
    if (open) {
      form.resetFields()
    }
  }, [open])
  const onFinish = (values: any) => {
    const data: BillingInvoiceCreateRequest = {
      fileKey: values.billingInvoiceUrl[0].response.fileKey,
      amountCents: values.amountCents * 100,
      notes: values.notes,
    }

    handleSubmit(data)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e.fileList
  }

  const uploadUrl = (options: any) => {
    const { onSuccess, onError, file, filename } = options
    const fileExtension = file.name.split('.').pop()
    const imageFilename = `${filename}_${file.uid}.${fileExtension}`
    getBillingInvoiceUploadPreSignedUrl(imageFilename)
      .then(res => {
        onSuccess(res)
        request({
          url: res.preSignedUrl,
          method: 'PUT',
          data: file,
          headers: {
            'x-amz-acl': 'private',
          },
        })
      })
      .catch(err => {
        onError({ err })
      })
  }

  return (
    <Modal open={open} onCancel={handleCancel} footer={null} width={800} title="上传电子发票">
      <Form
        name="studioLeaderboardCategories"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ isActive: false }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item name="amountCents" label="发票金额" rules={[{ required: true }]}>
          <InputNumber addonAfter="元" />
        </Form.Item>
        <Form.Item name="notes" label="备注">
          <Input />
        </Form.Item>
        <Form.Item
          name="billingInvoiceUrl"
          label="电子发票"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true }]}
        >
          <Dragger
            maxCount={1}
            name="billingInvoice"
            customRequest={config => uploadUrl(config)}
            listType="picture"
            accept=".pdf,.jpeg,.jpg,.png"
          >
            <p className="ant-upload-drag-icon">
              <PlusCircleOutlined color="#0055FF" />
            </p>
            <p className="ant-upload-text">点击“＋”从电脑上传，或将发票拖放到这里</p>
          </Dragger>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button type="primary" htmlType="submit">
            上传
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BillingInvoicesUploadModal
