import { SegmentTypeEnum } from './share-type'

export const SegmentTypeOptions = [
  {
    label: '系统内置',
    value: SegmentTypeEnum.SYSTEM,
  },
  {
    label: '自定义',
    value: SegmentTypeEnum.CUSTOM,
  },
  {
    label: 'IKEA',
    value: SegmentTypeEnum.IKEA,
  },
]
