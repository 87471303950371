const zh = {
  welcome: '欢迎使用ClassPass',
  noMatchPageDesc: '抱歉，您访问的页面不存在。',
  goBackButton: '返回首页',
  errorPageSubTitle: '抱歉，出了一些问题。',
  'menuBar.changeLanguageTitle': '语言 / Language',
  'menuBar.couponTemplatesTitle': '优惠券模板',
  'menuBar.adminRolesTiltle': '角色',
  'menuBar.adminUsersTiltle': '管理员',
  'menuBar.bookingReviewsTitle': '预订评论',
  'menuBar.marketingTitle': '营销',
  'menuBar.cardStacksTitle': '卡片堆',
  'menuBar.cardConfigsTitle': '卡片设置',
  'menuBar.corporatesTitle': '合作企业',
  'menuBar.referralCampaignsTitle': 'RAF活动',
  'menuBar.accountManageTitle': '账户管理',
  'menuBar.searchTrendsTitle': '热门搜索',
  'menuBar.featureFlagTitle': '功能开关',
  'menuBar.StudioIntegrationConfigs': '第三方场馆配置',

  saveButton: '保存修改',
  loginButton: '登录',
  logout: '退出',
  loginUsernameTips: '请输入账号!',
  loginPasswordTips: '请输入密码!',
  loginUsernamePlaceholder: '账号',
  loginPasswordPlaceholder: '密码',
  'navBar.home': '首页',
  'navBar.couponTemplates': '优惠券模板',
  'navBar.newCouponTemplate': '创建优惠券模板',
  'navBar.editCouponTemplate': '编辑优惠券模板',
  'navBar.viewCouponTemplate': '查看优惠券模板',
  'navBar.couponTemplatesTitle': '已创建优惠券模板列表',
  'navBar.changeLanguage': '切换语言',
  'navBar.manger': '管理员',
  'navBar.createNewAdminUser': '创建新用户',
  'navBar.editAdminUser': '编辑用户',
  'navBar.viewAdminUser': '查看用户',
  'navBar.adminRoles': '角色',
  'navBar.newAdminRoles': '增加角色',
  'navBar.editAdminRoles': '编辑角色',
  'navBar.viewBookingReview': '查看评论',
  'navBar.bookingReviews': '预订评论',
  'navBar.bookingReviewsTitle': '用户预订评论列表',
  'navBar.viewBookingReviewsTitle': '查看预订评论',
  'navBar.viewCardStackTitle': '查看卡片堆详情',
  'navBar.cardStacksTitle': '已创建卡片堆列表',
  'navBar.newCardStack': '创建卡片堆',
  'navBar.editCardStack': '编辑卡片堆',
  'navBar.cardConfigsTitle': '卡片设置',
  'navBar.viewCardConfigTitle': '查看卡片设置详情',
  'navBar.newCardConfig': '新增卡片设置',
  'navBar.editCardConfig': '编辑卡片设置',
  'navBar.corporatesTitle': '合作企业列表',
  'navBar.viewCorporate': '查看合作企业',
  'navBar.newCorporate': '新增合作企业',
  'navBar.editCorporate': '编辑合作企业',
  'menuBar.userManagementTitle': '用户管理',
  'menuBar.segmentsTitle': '用户分组',
  'menuBar.segmentRulesTitle': '用户分组规则',
  'navBar.editSegment': '编辑分组',
  'navBar.newSegment': '创建分组',
  'navBar.viewSegment': '查看分组',
  'navBar.segmentsTitle': '分组列表',
  'navBar.newUserSegments': '新增分组用户',
  'navBar.editSegmentRule': '编辑分组规则',
  'navBar.newSegmentRule': '创建分组规则',
  'navBar.viewSegmentRule': '分组规则详情',
  'navBar.segmentRulesTitle': '分组规则列表',
  'navBar.newUserGroups': '新增分组用户',
  'navBar.referralCampaignTitle': 'RAF活动',
  'navBar.viewReferralCampaign': '查看活动',
  'navBar.newReferralCampaign': '新建活动',
  'navBar.editReferralCampaign': '编辑活动',
  'navBar.referralCampaignDetail': 'RAF活动详情',
  'navBar.studioLeaderboardCategoryDetail': '排行榜分类详情',
  'navBar.editStudioLeaderboardCategories': '编辑排行榜分类',
  'navBar.newStudioLeaderboardCategories': '新增排行榜分类',
  'navBar.viewStudioLeaderboardCategories': '排行榜分类详情',
  'navBar.studioLeaderboardCategoriesTitle': '排行榜分类',
  'navBar.taskRewardsTitle': '任务奖励',
  'navBar.viewTaskRewards': '查看任务奖励',
  'navBar.newTaskRewards': '新增任务奖励',
  'navBar.editTaskRewards': '编辑任务奖励',
  'navBar.editFeatureFlag': '编辑功能开关',
  'navBar.newFeatureFlag': '创建功能开关',
  'navBar.viewFeatureFlag': '查看功能开关',
  'navBar.featureFlagsTitle': '功能开关列表',
  'menuBar.taskRewardsTitle': '任务奖励',
  'menuBar.couponTitle': '优惠券',
  'navBar.taskRewardsDetail': '任务奖励详情',

  'menuBar.studioLeaderboardCategoriesTitle': '排行榜分类',
  'menuBar.studioLeaderboardsTitle': '排行榜',

  'referralCampaigns.createNewCampaign': '新建活动',
  'referralCampaigns.name': '名称',
  'referralCampaigns.name.message': '请输入名称！',

  'referralCampaigns.isDefault': '默认活动',
  'referralCampaigns.isDefault.desc':
    '是否设置为默认活动。默认活动用于配置常规模式下的优惠劵以及邀请者的点数配置。该选项在活动创建后将不可编辑',

  'referralCampaigns.isActive': '活跃',
  'referralCampaigns.isActive.desc': '是否马上激活该活动。活动激活后将立刻对用户可见',
  'referralCampaigns.startDatetime': '开始时间',
  'referralCampaigns.endDatetime': '结束时间',
  'referralCampaigns.pageBackgroundColor': '背景色',
  'referralCampaigns.savePosterButtonColor': '保存海报按钮颜色',
  'referralCampaigns.sharingButtonColor': '分享按钮颜色',
  'referralCampaigns.generatingPosterButtonColor': '生成海报按钮颜色',
  'referralCampaigns.countDownBeforeMinutes': '倒数时间（分钟）',
  'referralCampaigns.countDownBeforeMinutes.desc': '距离活动结束时间多少分钟以内开始显示倒计时',
  'referralCampaigns.senderRewardScheme': '邀请者奖励设置',
  'referralCampaigns.receiverRewardScheme': '被邀请者奖励设置',
  'referralCampaigns.couponTemplateId': '优惠劵模板',
  'referralCampaigns.couponTemplateId.message': '请选择优惠劵模板!',
  'referralCampaigns.receiverCount': '邀请人数',
  'referralCampaigns.receiverCount.message': '请输入邀请人数!',
  'referralCampaigns.credits': '奖励点数',
  'referralCampaigns.credits.message': '请输入奖励点数!',
  'referralCampaigns.credits.desc': '邀请者在被邀请用户第一次购买成功后获得的奖励点数',
  'referralCampaigns.translations': '语言·翻译',
  'referralCampaigns.translations.title': '活动标题',
  'referralCampaigns.translations.title.message': '请输入活动标题!',
  'referralCampaigns.translations.rewardTitle': '奖励标语',
  'referralCampaigns.translations.rewardTitle.message': '请输入奖励标语!',
  'referralCampaigns.translations.receiverPageTitle': '被邀请者页面标题',
  'referralCampaigns.translations.receiverPageTitle.message': '请输入被邀请者页面标题!',
  'referralCampaigns.translations.referralPosterModalDescription': '生成海报顶部标语',
  'referralCampaigns.translations.referralPosterModalDescription.message':
    '请输入生成海报顶部标语！',
  'referralCampaigns.translations.sharingDescription': '微信分享信息内容',
  'referralCampaigns.translations.highlightColor': '生成海报顶部标语-高光文字颜色',
  'referralCampaigns.translations.sharingDescription.message': '请输入微信分享信息内容！',
  'referralCampaigns.translations.bannerUrl': '邀请者页面头图',
  'referralCampaigns.translations.shareImageUrl': '分享图片',
  'referralCampaigns.translations.posterImageUrl': '海报图片',
  'referralCampaigns.translations.referralEntranceImageUrl': 'RAF入口图片',
  'referralCampaigns.translations.receiverPageBannerUrl': '被邀请者页面头图',
  'referralCampaigns.translations.receiverPageIntroImageUrl': '被邀请者页面介绍图片',
  'referralCampaigns.translations.receiverPagePriceImageUrl': '被邀请者页面价格图片',
  'referralCampaigns.editSuccess': '编辑活动成功',
  'referralCampaigns.editError': '创建活动失败',
  'referralCampaigns.createSuccess': '创建活动成功',
  'referralCampaigns.createError': '创建活动失败',
  'referralCampaigns.campaignConfig': '活动配置',
  'referralCampaigns.rangeTime': '活动时间',
  'referralCampaigns.rangeTime.message': '请选择活动开始时间和结束时间',
  'referralCampaigns.senderRewardScheme.type.desc':
    '邀请者奖励形式。活动创建后将不可编辑。\n\nRECEIVER_COUNT_BASED: 满N人发一张优惠券。\nRECEIVER_COUNT_BASED_TIERS: 分段式奖励。\nREFERRAL_CREDITS:首次购买后基础点数奖励。 ',
  'referralCampaigns.senderRewardScheme.type.message': '请选择邀请者奖励形式',
  'referralCampaigns.receiverCount.desc': '邀请人数满指定人数以后，将给邀请者发一张优惠劵',
  'referralCampaigns.receiverRewardScheme.type.desc': '被邀请者奖励形式。 活动创建后将不可编辑。',
  'referralCampaigns.receiverRewardScheme.type.message': '请选择被邀请者奖励形式',
  'referralCampaigns.couponTemplateId.placeholder': '输入ID, 优惠劵名称，优惠码搜索优惠劵模版',
  'locale.message': '请选择语言',

  'navBar.editStudioLeaderboards': '编辑场馆排行榜',
  'navBar.newStudioLeaderboards': '新增场馆排行榜',
  'navBar.viewStudioLeaderboards': '场馆排行榜详情',
  'navBar.studioLeaderboardsTitle': '场馆排行榜',
  'navBar.studioLeaderboardsDetail': '排行榜详情',
  'studioLeaderboards.createNewStudioLeaderboard': '创建新的排行榜',
  'studioLeaderboards.batchApprove': '一键上线',
  'studioLeaderboards.batchApprove.desc': '一键通过所有待审核排行榜',
  'studioLeaderboards.title': '标题',
  'studioLeaderboards.title.message': '请输入标题！',
  'studioLeaderboards.translation.name': '名称',
  'studioLeaderboards.translation.name.message': '请输入名字',
  'studioLeaderboards.translations': '语言·翻译',
  'studioLeaderboards.rules': '规则·限制',
  'studioLeaderboards.city': '榜单所属城市',
  'studioLeaderboards.area': '榜单所属区域',
  'studioLeaderboards.area.message': '请选择城市以及区域',
  'studioLeaderboards.neighborhoods': '榜单所属区域',
  'studioLeaderboards.category': '榜单分类',
  'studioLeaderboards.category.message': '请选择榜单分类',
  'studioLeaderboards.priority': '榜单优先级',
  'studioLeaderboards.priority.message': '请输入榜单优先级',
  'studioLeaderboards.type': '榜单类型',
  'studioLeaderboards.type.message': '请选择榜单类型',
  'studioLeaderboards.status': '状态',
  'studioLeaderboards.createSuccess': '创建排行榜成功',
  'studioLeaderboards.createError': '创建排行榜失败',
  'studioLeaderboards.editSuccess': '编辑排行榜成功',
  'studioLeaderboards.editError': '编辑排行榜失败',
  'studioLeaderboards.studioRankers': '场馆排名',
  'studioLeaderboards.preview': '排行榜预览',
  'studioLeaderboards.category.placeholder': '输入分类名称搜索排行榜分类',
  'studioLeaderboards.numberOfRankers': '场馆数量',
  'studioLeaderboards.allNeighborhoods': '全部区域',
  'studioLeaderboards.allCategories': '全部分类',
  'studioLeaderboards.isActive.desc':
    '每次排行榜更新时，旧版的排行榜会被系统自动标记为isActive=false，此类排行榜不建议编辑',

  'studioLeaderboards.batchMode': '批量生成',
  'studioLeaderboards.batchMode.tooltip.message':
    '打开批量生成开关，城市，区域，排行榜类型如果不填写的话，会自动组合。不选择榜单分类，则生成对应的全部分类榜单',
  'studioLeaderboards.translations.tooltip.message':
    '批量生成排行榜不需要设置语言·翻译，我们会根据榜单城市，区域，榜单类型自动选择生成语言·翻译',
  'studioLeaderboards.title.tooltip.message':
    '批量生成排行榜不需要设置标题，我们会根据榜单城市，区域，榜单类型自动选择生成标题',
  'studioLeaderboards.updateStudioLeaderboardStatusSuccess': '更新排行榜状态成功',
  'studioLeaderboards.updateStudioLeaderboardStatusError': '更新排行榜状态失败',
  'studioLeaderboards.batchApproveStudioLeaderboardError': '一键上线待审核排行榜失败',

  'studioLeaderboardCategories.name': '排行榜分类名称',
  'studioLeaderboardCategories.name.message': '请输入分类名称',
  'studioLeaderboardCategories.isActive': '活跃',
  'studioLeaderboardCategories.iconImage': '排行榜分类图片',
  'studioLeaderboardCategories.iconSmallDefaultFileName': '排行榜分类默认小图标',
  'studioLeaderboardCategories.iconSmallActiveFileName': '排行榜分类选中小图标',
  'studioLeaderboardCategories.iconLargeDefaultFileName': '排行榜分类默认大图标',
  'studioLeaderboardCategories.iconLargeActiveFileName': '排行榜分类选中大图标',
  'studioLeaderboardCategories.translations': '语言·翻译',
  'studioLeaderboardCategories.translations.title': '分类标题',
  'studioLeaderboardCategories.translations.title.message': '请输入分类标题',
  'studioLeaderboardCategories.studioLeaderboardCategoryTags': 'Tags',
  'studioLeaderboardCategories.studioLeaderboardCategoryTags.message': '请选择标题',
  'studioLeaderboardCategories.editSuccess': '编辑排行榜分类成功',
  'studioLeaderboardCategories.editError': '编辑排行榜分类失败',
  'studioLeaderboardCategories.createSuccess': '创建排行榜分类成功',
  'studioLeaderboardCategories.createError': '创建排行榜分类失败',
  'searchTrendRankersForm.studioTips': '搜索的场馆只能选择当前城市下的场馆，最多能选择20个场馆',
  'searchTrendRankersForm.lessonDescriptionTips': '最多能选择20个课程类型',

  'navBar.searchTrendsTitle': '热门搜索',
  'navBar.viewSearchTrends': '热门搜索详情',
  'searchTrends.generatedDate': '生成时间',
  'searchTrends.startDate': '开始时间',
  'searchTrends.endDate': '结束时间',
  'searchTrends.city': '城市',
  'searchTrends.status': '状态',
  'searchTrends.updateStatusSuccess': '修改状态成功',
  'searchTrends.updateStatusError': '修改状态失败',
  'searchTrends.rankers': '热门{trendableType}搜索排名',
  'searchTrends.addRankders': '新增热门搜索排名',
  'searchTrends.trendableType': '类别',
  'searchTrends.rankers.clicksCount': '点击次数',
  'searchTrends.rankers.rank': '排名',
  'searchTrends.rankers.studioId': '场馆ID',
  'searchTrends.rankers.studioName': '场馆名称',
  'searchTrends.rankers.lessonDescriptionId': '课程ID',
  'searchTrends.rankers.lessonDescriptionName': '课程名称',
  'searchTrends.rankers.updateSuccess': '更新热门搜索排名成功',
  'searchTrends.rankers.updateError': '更新热门搜索排名失败',
  'searchTrends.lessonDescription.message': '请选择课程类型',
  'searchTrends.studio.message': '请选择场馆',

  'couponTemplates.name': '优惠券模板名称',
  'couponTemplates.name.message': '请输入优惠券模板名称!',
  'couponTemplates.discountTitle': '优惠券模板标题',
  'couponTemplates.discountTitle.message': '请输入优惠券模板标题!',
  'couponTemplates.discountType': '折扣类型',
  'couponTemplates.discountType.message': '请选择折扣类型!',
  'couponTemplates.couponRuleType': '优惠券类型',
  'couponTemplates.couponRuleType.desc':
    '老用户复购优惠券：当老用户点数小于7点 或者 点数3天内快过期时发送的优惠券\nIKEA用户复购优惠券：购买过IKEA套餐的用户完成第一节预约时自动发送的优惠券',
  'couponTemplates.discountOff': '优惠详情',
  'couponTemplates.plans': '课包',
  'couponTemplates.plans.message': '请选择课包!',
  'couponTemplates.plans.price': '课包价格',
  'couponTemplates.plans.price.message': '请输入课包价格!',
  'couponTemplates.cities': '适用城市',
  'couponTemplates.startDate': '开始时间',
  'couponTemplates.endDate': '结束时间',
  'couponTemplates.usedCount': '消耗量',
  'couponTemplates.status': '状态',
  'couponTemplates.code': '优惠码',
  'couponTemplates.rulesTitle': '优惠券模板规则标题',
  'couponTemplates.rulesTitle.message': '请输入优惠券模板规则标题!',
  'couponTemplates.rulesDescription': '优惠券模板规则详情',
  'couponTemplates.rulesDescription.message': '请输入优惠券模板规则详情!',
  'couponTemplates.couponRule': '优惠券模板使用规则',
  'couponTemplates.couponRule.message': '请选择优惠券模板使用规则!',
  'couponTemplates.frequency': '发放时间间隔',
  'couponTemplates.frequency.message': '请填写发放时间间隔!',

  'couponTemplates.expirationType': '有效性类型',
  'couponTemplates.expirationType.tips': 'fixed表示，截止日期固定, natural表示，从领劵日期开始算',
  'couponTemplates.expirationDate': '单张优惠券模板有效日期',

  'couponTemplates.validityDays': '有效期天数!',
  'couponTemplates.usageLimit': '发放张数',
  'couponTemplates.usageLimit.message': '请输入发放张数!',
  'couponTemplates.corporateIds': '企业IDs',
  'couponTemplates.corporateIds.desc': '企业ID(可多选）, 可选ID必须为状态为ACTIVE的企业',

  'menuBar.lessonCreditPricingsTitle': '课程点数',
  'navBar.lessonCreditPricingTitle': '课程点数',
  'navBar.newLessonCreditPricing': '新增课程点数调整',
  'navBar.batchUploadLessonCreditPricing': '批量上传',
  'navBar.exchangeRatesTitle': '点数汇率',
  'lessonCreditPricing.lessonDescriptionId': '课程模版',
  'lessonCreditPricing.studioId': '场馆',
  'lessonCreditPricing.lessonId': '课程',
  'lessonCreditPricing.startDateRange': '课程开始时间范围',
  'lessonCreditPricing.startTimeRange': '课程开始时间段',
  'lessonCreditPricing.editLessonCreditPricing': '编辑课程点数调整',
  'lessonCreditPricing.newLessonCreditPricing': '新增课程点数调整',
  'lessonCreditPricing.batchUploadLessonCreditPricing': '批量上传',
  'lessonCreditPricing.adjustedCredits': '调整后的点数',
  'lessonCreditPricing.originalCredits': '原始点数',
  'lessonCreditPricing.lessonStartTime': '课程开始日期',
  'lessonCreditPricing.lessonEndTime': '课程结束日期',
  'lessonCreditPricing.studio': '场馆',
  'lessonCreditPricing.lesson': '课程',
  'lessonCreditPricing.lessonDescription': '课程模版',
  'lessons.credits': '点数',
  'lessonCreditPricing.adjustedCredits.message': '请输入调整后的点数',
  'lessonCreditPricing.adjustments.view': '查看课程点数调整详情',

  'navBar.corporateCreditsHistories': '企业点数历史',
  'corporateCreditsHistories.type': '类型',
  'corporateCreditsHistories.notes': '备注',
  'corporateCreditsHistories.credits': '点数',
  'corporateCreditsHistories.createdBy': '创建人',
  'corporate.credits': '点数',
  'corporate.totalCredits': '企业总点数：{credits}点',
  'corporate.originCredits': '点数（当前企业点数为：{credits}点）',
  'corporate.notes': '备注',
  'corporate.notes.placeholder': '请输入备注',
  'corporate.credits.payment': '点数充值',
  'corporate.credits.paymentSuccess': '点数充值成功',
  'corporate.credits.paymentFailed': '点数充值失败',
  'corporate.credits.adjustment': '点数调整',
  'corporate.credits.adjustedSuccess': '点数调整成功',
  'corporate.credits.adjustedFailed': '点数调整失败',
  'corporate.credits.distribution': '分发点数',
  'corporate.credits.distributionSuccess': '分发点数成功',
  'corporate.credits.distributionFailed': '分发点数失败',
  'corporate.credits.viewDetails': '查看详情',
  'corporate.phoneNumber.bulkCheckFailed': '批量检查失败',
  'corporate.credits.reclaim': '回收点数',
  'corporate.credits.reclaimSuccess': '回收点数成功',
  'corporate.credits.reclaimFailed': '回收点数失败',
  'corporate.credits.reclaim.confirm': '确定要批量回收企业用户的点数吗？',
  'corporate.batchReclaimCredits': '批量回收点数',
  'corporate.batchReclaimCredits.action': '回收类型',
  'corporate.batchReclaimCredits.action.onlyCredits': '仅回收点数',
  'corporate.batchReclaimCredits.action.reclaimAndDisable': '回收并停用',
  'corporate.batchReclaimCredits.success': '批量回收用户点数成功',
  'corporate.batchReclaimCredits.failed': '批量回收用户点数失败',
  'corporate.batchReclaimCredits.modal.title':
    '列表内包{number}个含未激活用户，是否跳过这些用户继续回收?',
  'corporate.batchReclaimCredits.modal.okText': '跳过',
  'corporate.phoneNumber.bulkUpload': '批量上传电话号码列表',
  'corporate.phoneNumber.specify': '指定用户电话号码',
  'corporate.member.activate': '激活',
  'corporate.member.deactivate': '停用',
  'corporate.credits.distributeToAll': '分发给所有企业用户',
  'corporate.credits.distributeToUploaded': '分发给上传的用户',
  'corporate.credits.distributeToAll.confirm':
    '有{activatedMemberCount}个活跃用户,是否给所有活跃用户发放点数？',
  'corporate.credits.distributeToAll.confirmNoMember':
    '当前企业没有活跃用户，无法分发点数, 请先激活用户',
  'corporate.batchDistributeCredits': '批量分发点数',
  'corporate.batchDistributeCredits.success': '批量分发用户点数成功',
  'corporate.batchDistributeCredits.failed': '批量分发用户点数失败',
  'corporate.batchDistributeCredits.modal.title':
    '您所上传的列表内包含{number}位未激活用户，是否激活并分发点数',
  'corporate.batchDistributeCredits.modal.okText': '激活并分发',
  'corporate.batchDistributeCredits.modal.cancelText': '不激活，仅分发',
  'corporate.batchDistributeCredits.modal.cancel': '取消',
  'corporate.corporateCreditsDistributionSchedule.modal.isApplyForAllMembers':
    '是否分发给所有企业用户',
  'corporate.corporateCreditsDistributionSchedule.modal.confirm': '请确认以下信息无误：',
  'corporate.corporateCreditsDistributionSchedule.modal.isReclaimBeforeDistribute':
    '分发前回收所有点数',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.type': '定时任务类型',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.type.desc':
    'SPECIFIC_TIME: 可以提前指定多个固定的时间\nREPEATABLE: 指定首次执行时间，执行周期，重复执行',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.type.message':
    '请选择定时任务类型',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.startDate': '初次运行时间',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.endDate': '终止运行时间',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.interval': '运行间隔时间',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.interval.placeholder':
    '请输入时长',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.intervalUnit.placeholder':
    '时间单位',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.specificTimes': '运行时间',

  'corporate.corporateCreditsDistributionSchedule.modal.schedule.specificTimes.message':
    '请选择运行时间',
  'corporate.corporateCreditsDistributionSchedule.modal.creditsValidityPeriod': '点数有效时长',
  'corporate.corporateCreditsDistributionSchedule.modal.duration.placeholder': '时长',
  'corporate.corporateCreditsDistributionSchedule.modal.durationUnit.placeholder': '时间单位',
  'corporate.corporateCreditsDistributionSchedule.modal.extendDays.placeholder': '延长天数',
  'navbar.corporateCreditsDistributionSchedule.create': '创建定时分发点数任务',
  'navbar.corporateCreditsDistributionSchedule': '定时分发点数任务',
  'navbar.corporateCreditsDistributionSchedule.edit': '编辑定时分发点数任务',
  'navbar.corporateCreditsDistributionSchedule.view': '查看定时分发点数任务',
  'navbar.corporateCreditsDistributionSchedule.members': '定时分发点数成员',
  'navbar.corporateCreditsDistributionSchedule.histories': '定时分发点数执行记录',
  'corporate.corporateCreditsDistributionSchedule.create.success': '创建定时分发点数任务成功',
  'corporate.corporateCreditsDistributionSchedule.create.failed': '创建定时分发点数任务失败',
  'corporate.corporateCreditsDistributionSchedule.edit.success': '编辑定时分发点数任务成功',
  'corporate.corporateCreditsDistributionSchedule.edit.failed': '编辑定时分发点数任务失败',
  'corporate.corporateCreditsDistributionSchedule.modal.ok': '创建定时任务',
  'corporate.corporateCreditsDistributionSchedule.modal.executePlans':
    '预计执行日期: {executePlans}',
  'corporateMember.editSuccess': '修改用户成功',
  'corporateMember.editFailed': '修改用户失败',
  'corporate.corporateCreditsDistributionSchedule.modal.phoneNumberCheck.title':
    '列表内包含{validCount}个有效号码和{invalidCount}个无效电话号码，是否跳过这些用户继续创建定时任务?',
  'corporate.corporateCreditsDistributionSchedule.modal.phoneNumberCheck.okText':
    '跳过无效号码并继续创建任务',
  'corporate.corporateCreditsDistributionSchedule.create.phoneNumberCheck.success':
    '成功添加{number}个企业成员至该任务',

  'corporateCreditsHistories.viewDetail': '企业 {corporateId}-{corporateName} 点数详情',
  'corporateMemberCreditsHistories.drwerTitle': '企业用户点数详情',
  'corporateMemberCreditsHistories.corporateId': '企业ID',
  'corporateMemberCreditsHistories.corporateMemberId': '企业用户ID',
  'corporateMemberCreditsHistories.credits': '点数',
  'corporateMemberCreditsHistories.type': '类型',
  'corporateMemberCreditsHistories.referenceId': '关联ID',
  'corporateMemberCreditsHistories.referenceType': '关联类型',
  'corporateMemberCreditsHistories.createdBy': '创建人',
  'corporatemember.credits.viewCreditsHistories': '查看点数详情',
  'corporateMemberCreditsHistories.phonNumber': '电话号码',
  'corporateMemberCreditsHistories.notes': '备注',

  'corporate.batchDeactivateMembers': '批量停用企业用户',
  'corporate.batchDeactivateMembers.success': '批量停用企业用户成功',
  'corporate.batchDeactivateMembers.failed': '批量停用企业用户失败',
  'corporate.batchDeactivateMembers.confirm': '确定要批量停用企业用户吗？',
  'corporate.changeStatus.confirm': '确定要{status} [{phoneNumber}]吗？',

  'corporate.studioIds.bulkUpload': '批量上传允许预约场馆ID列表(.csv)',
  'corporateCreditsHistories.creatTimeRange': '创建时间范围',

  'corporate.batchUploadPhoneNumber.modal.okText': '跳过',
  'corporate.unusedDistributedCreditsThreshold': '未使用分发点数报警阈值',
  'corporate.unusedDistributedCredits': '实际未使用分发点数',
  'corporate.unusedDistributedCreditsThreshold.tips':
    '当未使用的积分少于此阈值时，公司管理员将收到通知。默认值为 0，表示不会触发任何通知。',
  'corporate.unusedDistributedCreditsThreshold.required': '请输入未使用分发点数报警阈值',
  'corporateMember.expiredTimeRange': '过期时间范围',
  'corporateMember.export': '导出',
  'corporateMember.creditsExpiredAt': '点数过期时间',
  'corporate.bufferPercentage': '分发点数buffer百分比',
  'corporate.bufferPercentage.required': '请输入分发点数buferr百分比',
  'corporate.expiredAt': '过期时间',
  'corporate.expiredAt.required': '请输入过期时间',

  'taskRewards.title': '标题',
  'taskRewards.taskRewardableId': '任务ID',
  'taskRewards.taskRewardableType': '任务类型',
  'taskRewards.beans': '活力豆',
  'taskRewards.isActive': '活跃',

  'menuBar.weChatURLLinksTitle': '微信URL链接',
  'navBar.weChatURLLinksTitle': '微信URL链接',
  'weChatURLLinks.path': '路径',
  'weChatURLLinks.parameters': '参数',
  'weChatURLLinks.applicationType': '应用类型',
  'weChatURLLinks.urlLink': 'URL链接',
  'navBar.newWeChatURLLinks': '新建微信URL链接',
  'weChatURLLinks.newWeChatURLLinks': '新建微信URL链接',
  'weChatURLLinks.createSuccess': '创建微信URL链接成功',
  'weChatURLLinks.createError': '创建微信URL链接失败',

  'navBar.shortURLsTitle': '短链接',
  'navBar.newShortURLs': '新建短链接',
  'menuBar.shortURLsTitle': '短链接',
  'shortURLs.updateShortURLs': '编辑短链接',
  'shortURLs.redirectURL': '重定向URL',
  'shortURLs.hashKey': '哈希键',
  'shortURLs.name': '名称',
  'shortURLs.description': '描述',
  'shortURLs.shortURL': '短链接',
  'shortURLs.createSuccess': '创建短链接成功',
  'shortURLs.createError': '创建短链接失败',
  'shortURLs.updateSuccess': '更新短链接成功',
  'shortURLs.updateError': '更新短链接失败',
  'shortURLs.newShortURLs': '新建短链接',
  'shortURLs.isRedirectUrlRefreshable': '是否可刷新重定向URL',
  'shortURLs.refreshIntervalDays': '刷新间隔天数',

  'navBar.oneTimeCodeBatches': 'One Time Code Batch',
  'navBar.newOneTimeCodeBatch': '新建 One Time Code Batch',
  'navBar.editOneTimeCodeBatch': '编辑 One Time Code Batch',
  'navBar.viewOneTimeCodeBatch': '查看 One Time Code Batch',
  'navBar.oneTimeCodeBatchesTitle': 'One Time Code Batches',
  'menuBar.oneTimeCodeBatchesTitle': 'One Time Code Batch',
  'oneTimeCodeBatches.detail': '详情',
  'oneTimeCodeBatches.name': '名称',
  'oneTimeCodeBatches.credits': '点数',
  'oneTimeCodeBatches.isNewUserOnly': '仅限新用户',
  'oneTimeCodeBatches.creditsValidityPeriodInDays': '点数有效期(天)',
  'oneTimeCodeBatches.numberOfCodes': 'code 数量',
  'oneTimeCodeBatches.prefix': '前缀',
  'oneTimeCodeBatches.timeRange': '可使用时间范围',
  'oneTimeCodeBatches.ikeaSegment': 'IKEA Segment',
  'oneTimeCodeBatches.status': '状态',
  'oneTimeCodeBatches.createSuccess': '创建成功',
  'oneTimeCodeBatches.createError': '创建失败',
  'oneTimeCodeBatches.updateSuccess': '更新成功',
  'oneTimeCodeBatches.updateError': '更新失败',
  'oneTimeCodeBatches.changeStatus': '修改状态',
  'oneTimeCodeBatches.export': '导出',
  'oneTimeCodeBatches.exportConfirm': '确定要导出该批次的code吗？',
  'oneTimeCodeBatches.exportSuccess': '导出成功',
  'oneTimeCodeBatches.exportError': '导出失败',
  'oneTimeCodeBatches.exportPassword': '导出密码:',
  'oneTimeCodeBatches.numberOfCodesClaimed': '已使用数量',
  'oneTimeCodeBatches.numberOfCodesCreated': '已生成数量',

  'menuBar.oneTimeCodesTitle': 'One Time Code',
  'oneTimeCode.isRedeemed': '是否被使用',
  'oneTimeCode.redeemedBy': '使用人ID',
  'oneTimeCode.redeemedByUser': '使用人昵称',
  'oneTimeCode.timeRange': '创建时间范围',
  'oneTimeCode.redeemedAt': '使用时间',
  'oneTimeCode.creditsValidityPeriodInDays': '点数有效期(天)',
  'oneTimeCode.status': '状态',

  'shortURLs.createFromWeChatURLLInk': '从微信URL链接创建',
  'shortURLs.createFromWeblink': '已有重定向URL',
  'shortURLs.wechatURLLink': '微信URL链接',

  'menuBar.miscTitle': 'Misc',
  'navBar.tags': 'Tags',
  'navBar.newTag': 'New Tag',
  'navBar.editTag': 'Edit Tag',
  'navBar.viewTag': 'View Tag',
  'navBar.tagsTitle': 'Tag',
  'menuBar.tagsTitle': 'Tag',
  'tags.name': '名称',
  'tags.isActive': '活跃',
  'tags.taggingsCount': 'Tag计数',
  'tags.cachedCategoryList': 'Context',
  'tags.categoryAndSubCategory': 'Category & Sub Category',
  'tags.category': 'Category',
  'tags.subCategory': 'Sub Category',
  'tags.translation.name': 'Translation Name',
  'tags.translations': '语言·翻译',

  'menuBar.lessonDescriptionsTitle': '课程模版',
  'navBar.lessonDescriptionsTitle': '课程模版',
  'navBar.viewLessonDescription': '查看课程模版',
  'menuBar.LessonsTitle': '课程',
  'lessonDescription.name': '课程模版名称',
  'lessonDescription.duration': '时长(min)',
  'lessonDescription.organizationId': '品牌ID',
  'lessonDescription.externalProviderId': '外部提供商ID',
  'lessonDescription.isRedirected': '是否重定向',
  'lessonDescription.isActive': '是否活跃',
  'lessonDescription.credits': '点数',
  'lessonDescription.isAllDay': '是否为全天通票',
  'lessonDescription.hasExternalProviderId': '是否有外部提供商ID',
  'lessonDescription.bookBefore': '提前预约时间',
  'lessonDescription.bookBefore.tips': '距离课程开始前停止接受预约时间（分钟）',
  'lessonDescription.maxCapacity': '开放席位数',
  'lessonDescription.cachedActivityList': '缓存场馆课程类型列表',
  'lessonDescription.cachedLabelList': '缓存标签列表',
  'lessonDescription.lateBefore': '提前迟到时间',
  'lessonDescription.lateBefore.tips': '课程开始前可免费取消时间 (小时)',
  'lessonDescription.startBookable': '开始预约时间',
  'lessonDescription.startBookable.tips': '距离课程开始开放预约时间 (天)',
  'lessonDescription.isHidden': '是否隐藏',
  'lessonDescription.isCancelled': '是否取消',
  'lessonDescription.protip': '提示',
  'lessonDescription.maxCapacityCeiling': '开放席位数上限',
  'lessonDescription.allowSignInWindowMarking': '允许标记',
  'lessonDescription.ratingScore': '评分',
  'lessonDescription.ratingVotes': '投票',
  'lessonDescription.difficultyLevel': '难度等级',
  'lessonDescription.lessonDescriptionTranslations': '语言·翻译',

  'lessonDescriptionTranslations.name': '名称',
  'lessonDescriptionTranslations.blurb': '简介',
  'lessonDescriptionTranslations.description': '描述',
  'lessonDescriptionTranslations.specialNoteToUsers': '特别提示',
  'lessonDescriptionTranslations.highlights': '亮点',
  'lessonDescriptionTranslations.lessonPrerequisites': '课前提醒',
  'lessonDescriptionTranslations.equipmentPrerequisites': '设备前提',
  'lessonDescriptionTranslations.checkInNotes': '开课前多少分钟需到场',
  'lessonDescriptionTranslations.otherNotes': '其他提示',
  'lessonDescriptionTranslations.targetUserGroups': '目标用户群',

  'navBar.organizationsTitle': '品牌',
  'menuBar.organizationsTitle': '品牌',
  'navBar.viewOrganization': '查看品牌',
  'organization.phoneNumber': '电话号码',
  'organization.email': '邮箱',
  'organization.bookBefore': '提前预约时间(min)',
  'organization.cancelBefore': '提前取消时间(min)',
  'organization.bannerFileName': 'Banner文件名',
  'organization.bannerContentType': 'Banner内容类型',
  'organization.bannerFileSize': 'Banner文件大小',
  'organization.bannerUpdatedAt': 'Banner更新时间',
  'organization.profileFileName': 'Profile文件名',
  'organization.profileContentType': 'Profile内容类型',
  'organization.profileFileSize': 'Profile文件大小',
  'organization.profileUpdatedAt': 'Profile更新时间',
  'organization.lateBefore': '提前迟到时间(min)',
  'organization.maxCapacity': '开放席位数',
  'organization.startBookable': '开始预约时间',
  'organization.cityId': '城市ID',
  'organization.liteBookingLimit': 'Lite预约限制',
  'organization.attendanceMarkingRequired': '是否需要标记',
  'organization.maxCapacityCeiling': '开放席位数上限',
  'organization.allowSlotAvailabilityNotification': '是否允许通知',
  'organization.label': '标签',
  'organization.showClassRatings': '是否显示评分',
  'organization.integratorLessonDescriptionsSync': '是否同步',
  'organization.integratorQrCodeCheckIn': '是否二维码签到',
  'organization.isUserRealNameRequired': '是否需要实名',
  'organization.isInternalPreviewOnly': '是否内部预览',
  'organization.tier': '等级',
  'organization.organizationTranslation': '语言·翻译',
  'organization.picturesAndBanners': '图片和横幅',
  'organization.accountManager': '账户管理员',
  'organization.accountManager.email': 'organization账户管理员邮箱',

  'organizationTranslation.name': '名称',
  'organizationTranslation.romanizedName': '罗马名称',
  'organizationTranslation.blurb': '简介',
  'organizationTranslation.description': '描述',
  'organizationTranslation.specialNoteToUsers': '特别提示',

  'navBar.studiosTitle': '场馆',
  'navBar.viewStudio': '查看场馆',
  'studio.organizationId': '品牌ID',
  'studio.externalProviderId': '外部提供商ID',
  'studio.zip': '邮编',
  'studio.phoneNumber': '电话号码',
  'studio.email': '邮箱',
  'studio.neighborhoodSlug': '区域Slug',
  'studio.timeZone': '时区',
  'studio.bookBefore': '提前预约时间(min)',
  'studio.cancelBefore': '提前取消时间(min)',
  'studio.bannerFileName': 'Banner文件名',
  'studio.bannerContentType': 'Banner内容类型',
  'studio.bannerFileSize': 'Banner文件大小',
  'studio.bannerUpdatedAt': 'Banner更新时间',
  'studio.profileFileName': 'Profile文件名',
  'studio.profileContentType': 'Profile内容类型',
  'studio.profileFileSize': 'Profile文件大小',
  'studio.profileUpdatedAt': 'Profile更新时间',
  'studio.latitude': '纬度',
  'studio.longitude': '经度',
  'studio.cachedActivityList': '缓存场馆课程类型列表',
  'studio.cachedNeighborhoodList': '缓存区域列表',
  'studio.status': '状态',
  'studio.neighborhoodId': '区域ID',
  'studio.cityId': '城市ID',
  'studio.businessDistrictId': '商业区ID',
  'studio.isSurgePricingEnabled': '是否启用高峰价格',
  'studio.maxVisitTimesThreshold': '最大访问次数阈值',
  'studio.ratingScore': '评分',
  'studio.ratingVotes': '投票',
  'studio.isRequiredQrCodeCheckIn': '是否需要二维码签到',
  'studio.needUploadScheduleReminder': '是否需要上传日程提醒',
  'studio.checkInOpenInMinutes': '签到开放时间(min)',
  'studio.checkInOpenInMinutes.tips': '"指在课程开始前开放签到的时间(分钟）。',
  'studio.checkInDurationInMinutes': '签到持续时间(min)',
  'studio.checkInDurationInMinutes.tips': '"指在课程开始前停止签到的时间(分钟）。',
  'studio.integratorLessonSync': '是否同步',
  'studio.picturesAndBanners': '图片和横幅',
  'studio.studioTranslation': '语言·翻译',
  'studioTranslation.name': '名称',
  'studioTranslation.address1': '地址',
  'studioTranslation.address1.message': '请输入地址',
  'studioTranslation.address2': '地址2',
  'studioTranslation.howToFindStudio': '如何找到场馆',
  'studioTranslation.howToFindStudio.tips': '例: 喜茶斜对面',
  'studioTranslation.transitToStudioPath': '到场馆的路径',
  'studioTranslation.transitToStudioPath.tips': '例: 地铁2号线C出口直行200米',
  'studioTranslation.country': '国家',
  'studioTranslation.city': '城市',
  'studioTranslation.area': '区域',
  'studioTranslation.state': '州',
  'studioTranslation.blurb': '简介',
  'studioTranslation.highlights': '亮点',
  'studioTranslation.description': '描述',
  'studioTranslation.description.message': '请输入描述',
  'studioTranslation.specialNoteToUsers': '特别提示',
  'studioTranslation.announcement': '公告',

  'navBar.editStudioIntegrationConfig': '编辑第三方场馆配置',
  'navBar.newStudioIntegrationConfig': '创建第三方场馆配置',
  'navBar.newStudioIntegrationLessonConfig': '创建课程配置',
  'navBar.viewStudioIntegrationLessonConfig': '查看课程配置',
  'navBar.editStudioIntegrationLessonConfig': '编辑课程配置',
  'navBar.newStudioIntegrationLessonDescriptionConfig': '创建课程模版配置',
  'navBar.viewStudioIntegrationLessonDescriptionConfig': '查看课程模版配置',
  'navBar.editStudioIntegrationLessonDescriptionConfig': '编辑课程模版配置',
  'navBar.viewStudioIntegrationConfig': '查看第三方场馆配置',
  'navBar.studioIntegrationConfig': '第三方场馆配置',
  'studioIntegrationConfig.integratorType': 'Integrator种类',
  'studioIntegrationConfig.organizationIds': '品牌ID',
  'studioIntegrationConfig.createNew': '新建配置',
  'studioIntegrationConfig.detail': '第三方场馆配置详情',
  'studioIntegrationConfig.fieldType': '字段类型',
  'studioIntegrationConfig.fieldType.message': '请选择字段类型！',
  'studioIntegrationConfig.fieldValue': '字段值',
  'studioIntegrationConfig.fieldValue.message': '请输入字段值！',
  'studioIntegrationConfig.rules': '规则',
  'studioIntegrationLessonConfig.title': '课程配置',
  'studioIntegrationLessonDescConfig.title': '课程模版配置',
  'studioIntegrationLessonConfig.detail': '课程配置详情',
  'studioIntegrationLessonConfig.timeRanges': '时间范围',
  'studioIntegrationLessonConfig.timeRanges.message': '请选择至少一个时间范围！',
  'studioIntegrationConfig.createSuccess': '创建配置成功',
  'studioIntegrationConfig.createError': '创建配置失败',
  'studioIntegrationConfig.editSuccess': '编辑配置成功',
  'studioIntegrationConfig.editError': '编辑配置失败',

  'paymentDetail.companyName': '收款帐户名称',
  'paymentDetail.companyName.message': '请输入收款帐户名称',
  'paymentDetail.companyAddress': '公司地址',
  'paymentDetail.companyAddress.message': '请输入公司地址',
  'paymentDetail.bankName': '银行名称',
  'paymentDetail.bankName.message': '请输入银行名称',
  'paymentDetail.bankName.tips': ' 请填写支行信息，例如：中信银行上海静安寺支行',
  'paymentDetail.bankAccountNumber': '银行账号',
  'paymentDetail.bankAccountNumber.message': '请输入银行账号',
  'paymentDetail.taxIdentificationNumber': '税务登记号',
  'paymentDetail.taxIdentificationNumber.message': '请输入税务登记号',
  'paymentDetail.taxIdentificationNumber.tips': '您营业执照左上角的序号',
  'studio.area': '城市和区域',
  'studio.area.message': '请选择城市和区域',
  'studio.latitude.message': '请输入纬度',
  'studio.longitude.message': '请输入经度',
  'studio.latitude.tips': '经纬度查询网址：',
  'transitStations.message': '请选择地铁站',
  'businessDistricts.message': '请选择商圈',
  'studio.amenities': '场馆设施',
  'studio.amenities.message': '请选择场馆设施',
  'studio.activities': '场馆课程类型',
  'studio.activities.message': '请选择场馆课程类型',
  'studio.labels': '标签',
  'studio.labels.message': '请选择标签',
  'studio.primaryCategory': '主分类',
  'studio.primaryCategory.message': '请选择主分类',
  'studio.transitStations': '最靠近的地铁站',
  'studio.businessDistricts': '商圈',
  'studio.checkInInfomation': '签到信息',
  'studio.taggings': 'Taggings',
  'studio.locationInformation': '位置信息',
  'studio.baseInformation': '场馆基本信息',
  'studio.info': '场馆信息',
  'studio.fxiaokeAccountObjectId': 'fxiaoke客户ID',
  'studio.studioId': '场馆ID',
  'lessonDescription.startBookable.message': '请输入开始预约时间',
  'lessonDescription.maxCapacity.message': '请输入开放席位数',
  'lessonDescription.bookBefore.message': '请输入提前预约时间',
  'lessonDescription.lateBefore.message': '请输入提前迟到时间',
  'lessonDescription.difficultLevel': '难度等级',
  'lessonDescription.difficultLevel.message': '请选择难度等级',
  'lessonDescription.isAllDay.message': '请选择是否为全天通票',
  'lessonDescription.description.message': '请输入描述',
  'studio.phoneNumbers': '电话号码',
  'studio.phoneNumbers.tips': '电话号码应该由86或者0开头，例: 8618683593821, 028-87820183',
  'studio.checkInOpenInMinutes.message': '请输入签到开放时间',
  'studio.checkInDurationInMinutes.message': '请输入签到持续时间',
  city: '城市',
  'city.message': '请选择城市',
  neighborhood: '区域',
  'neighborhood.message': '请选择区域',
  'lessonDescription.duration.message': '请输入时长',
  'lessonDescription.credits.message': '请输入点数',

  'prelaunchOrganizationsForm.create': '创建待上线品牌',
  'navBar.viewPrelaunchOrganizationForms': '查看待上线品牌',
  'navBar.prelaunchOrganizationFormsTitle': '待上线品牌',
  'navBar.prelaunchStudios': '待上线场馆',
  'navBar.editPrelaunchStudios': '编辑待上线场馆',
  'navBar.viewPrelaunchStudios': '查看待上线场馆',
  'navBar.prelaunchLessonDescriptions': '待上线课程模版',
  'navBar.viewPrelaunchLessonDescriptions': '查看待上线课程模版',
  'navBar.editPrelaunchLessonDescriptions': '编辑待上线课程模版',
  'navBar.prelaunchCompanyPaymentDetail': '待上线支付详情',
  'navBar.editPrelaunchCompanyPaymentDetail': '编辑待上线支付详情',
  'prelaunchOrganizationForms.id': 'ID',
  'prelaunchOrganizationForms.organizationId': '品牌ID',
  'prelaunchOrganizationForms.uniqueId': 'UUID',
  'prelaunchOrganizationForms.uniqueLink': '分享链接',
  'prelaunchOrganizationForms.organizationName': '品牌名称',
  'prelaunchOrganizationForms.expiredAt': '过期时间',
  'prelaunchOrganizationForms.expiredAtAfter': '过期日期',
  'prelaunchOrganizationForms.confirm': '确认上线',
  'prelaunchOrganizationForms.confirmed': '已上线',
  'prelaunchOrganizationForms.confirm.message':
    '{formName}将正式上线, 点击确认前请确认提交信息正确无误！',
  'prelaunchCompanyPaymentDetail.companyName': '收款帐户名称',
  'prelaunchCompanyPaymentDetail.companyAddress': '公司地址',
  'prelaunchCompanyPaymentDetail.taxIdentificationNumber': '纳税人识别号',
  'prelaunchCompanyPaymentDetail.bankName': '开户银行',
  'prelaunchCompanyPaymentDetail.bankAccountNumber': '开户银行账号',

  addStudios: '添加场馆',
  addLessonDescriptions: '添加课程模版',
  addCompanyPaymentDetail: '添加对公收款帐户详情',
  studio: '场馆',
  lessonDescription: '课程模版',
  filledPaymentInfo: '您已经填写了支付信息',
  'name.message': '请输入名称',
  'lessonDescription.status': '状态',
  'addStudios.success': '添加场馆成功',
  'addStudios.error': '添加场馆失败',
  'addLessonDescriptions.success': '添加课程模版成功',
  'addLessonDescriptions.error': '添加课程模版失败',
  'addCompanyPaymentDetail.success': '添加对公收款帐户详情成功',
  'addCompanyPaymentDetail.error': '添加对公收款帐户详情失败',
  'studio.adminUser': '场馆管理员',
  'studio.adminUser.email': '邮箱',
  'studio.adminUser.email.message': '请输入邮箱',
  'studio.adminUser.email.tips': '您的邮箱将做为场馆后台登陆的用户名，请填写正确的邮箱',
  addNewPhoneNumber: '添加新的电话号码',
  'city.placeholder': '请选择城市',
  'neighborhood.placeholder': '请选择区域',
  'businessDistricts.placeholder': '请选择商圈',
  'transitStations.placeholder': '请选择地铁站',
  'amenities.placeholder': '请选择场馆设施',
  'activities.placeholder': '请选择场馆课程类型',
  'labels.placeholder': '请选择标签',
  'primaryCategory.placeholder': '请选择主分类',
  'paymentDetail.companyName.tips':
    '您提供的收款帐户名称应与您开票的公司名一致。如果您是个体户，填写经营者的个人帐户名即可。',
  'difficultLevelOptions.easy': '适合小白',
  'difficultLevelOptions.medium': '适合经常运动者',
  'difficultLevelOptions.hard': '适合专业训练者',

  firstClassDiscount: '首访特惠',
  'firstClassDiscount.discountPercentage': '首访特惠折扣',
  'firstClassDiscount.discountPercentage.message': '请输入首访特惠折扣',
  'firstClassDiscount.isDisabled': '是否禁用',
  'firstClassDiscount.isFixed': '是否固定',
  'firstClassDiscount.create': '创建首访特惠',
  'firstClassDiscount.create.success': '创建首访特惠成功',
  'firstClassDiscount.create.error': '创建首访特惠失败',
  'firstClassDiscount.update': '编辑首访特惠',
  'firstClassDiscount.update.success': '创建首访特惠成功',
  'firstClassDiscount.update.error': '创建首访特惠失败',
  'studio.isFirstClassDiscounted': '只看首访特惠',
  'navBar.editFirstClassDiscountDisabledLesson': '编辑首访特惠禁用课程',
  'navBar.newFirstClassDiscountDisabledLesson': '新建首访特惠禁用课程',
  'navBar.firstClassDiscountDisabledLessonsTitle': '首访特惠禁用课程',
  'menuBar.firstClassDiscountDisabledLessonsTitle': '首访特惠禁用课程',

  firstClassDiscountDisabledLessons: '首访特惠禁用课程列表',
  'firstClassDiscountDisabledLessons.createSuccess': '创建首访特惠禁用课程成功',
  'firstClassDiscountDisabledLessons.createError': '创建首访特惠禁用课程失败',
  'firstClassDiscountDisabledLessons.updateSuccess': '更新首访特惠禁用课程成功',
  'firstClassDiscountDisabledLessons.updateError': '更新首访特惠禁用课程失败',
  'firstClassDiscountDisabledLessons.rangeStartTime': '开始时间范围',
  'firstClassDiscountDisabledLessons.rangeStartDate': '开始日期范围',
  'firstClassDiscountDisabledLessons.deleteConfirm': '确定要删除此项吗？',
  'firstClassDiscountDisabledLessons.deleteSuccess': '删除成功',
  'firstClassDiscountDisabledLessons.deleteError': '删除失败',
  'studio.primaryCategory.tips': '请选择一个符合您场馆主营业务的标签',
  'studio.customerSupportPhoneNumber': '客户联系电话',
  'studio.customerSupportPhoneNumber.message': '请输入客户联系电话',
  'studio.notificationPhoneNumber': '接收预约通知的手机号',
  'studio.notificationPhoneNumber.tips':
    '上线后CP运营团队会协助您开通小程序后台权限也能收到预约推送通知，因此此处非必填',
  'studioTranslation.description.tips': '请填写您场馆的简介',
  'searchPlaceholder.tips': '展开后可对选项进行搜索',

  'navBar.lessonCreditAdjustmentsTitle': '课程点数调整',
  'navBar.newLessonCreditAdjustmentsTitle': '新建课程点数调整',
  'navBar.editLessonCreditAdjustmentsTitle': '编辑课程点数调整',
  'navBar.viewLessonCreditAdjustmentsTitle': '查看课程点数调整',
  'lessonCreditAdjustments.selectAdjustmentType': '选择点数调整类型',
  'lessonCreditAdjustments.selectAdjustableType': '选择对象',
  'lessonCreditAdjustments.AdjustmentType': '点数调整类型',
  'lessonCreditAdjustments.AdjustmentType.message': '请选择点数调整类型',
  'lessonCreditAdjustments.AdjustableType': '调整对象类型',
  'lessonCreditAdjustments.AdjustableType.message': '请选择对象类型',
  'lessonCreditAdjustments.AdjustableId': '调整对象ID',
  'lessonCreditAdjustments.AdjustableId.message': '请选择{adjustableType} ID',
  'lessonCreditAdjustments.setRule': '设置',
  'lessonCreditAdjustments.adjustCredits': '调整点数',
  'lessonCreditAdjustments.adjustCredits.message': '请输入调整点数',
  'lessonCreditAdjustments.lessonStartTimeRange': '课程开始时间区间',
  'lessonCreditAdjustments.dayOfWeek': '日期',
  'lessonCreditAdjustments.lessonDescriptionIds': '课程模版ID',
  'lessonCreditAdjustments.reviewAndSubmit': '确认提交',
  'lessonCreditAdjustments.lessonDescriptionIds.all': '该组织下所有课程模版ID',
  'lessonCreditAdjustments.dateRange': '课程日期',
  'lessonCreditAdjustments.daysOfWeek': '星期',
  'lessonCreditAdjustments.selectLessonDescriptions': '选择课程模版',
  'lessonCreditAdjustments.adjustCredits.edit': '编辑调整点数',
  'lessonCreditAdjustments.adjustCredits.minError': '点数调整类型=PEAK时，调整点数不能为负数',
  'lessonCreditAdjustments.adjustCredits.maxError':
    '点数调整类型=OFF_PEAK时，调整点数应该小于等于0',
  'lessonCreditAdjustments.billingItemPricing': 'payout价格调整',
  'lessonCreditAdjustments.billingItemPricingEnabled': '添加payout价格设置',
  'lessonCreditAdjustments.billingItemPricing.completedClassPriceCents': '已完成课程',
  'lessonCreditAdjustments.billingItemPricing.lateCancelledPriceCents': '迟到取消课程',
  'lessonCreditAdjustments.billingItemPricing.earlyVisitPriceCents': '提前开课课程',
  'lessonCreditAdjustments.billingItemPricing.noShowPriceCents': '未到场课程',

  'duplicateLessonDescription.search': '查找重复课程模版',
  'duplicateLessonDescription.mainLessonDescription.id': '主要课程模版ID',
  'duplicateLessonDescription.mainLessonDescription.name': '主要课程模版名称',
  'duplicateLessonDescription.duplicateLessonDescriptions': '重复课程模版',
  'duplicateLessonDescription.mergeSuccess': '修改状态成功',
  'duplicateLessonDescription.mergeFail': '修改状态失败',
  'duplicateLessonDescription.mergeSelected': '合并所有选中课程模版',
  'duplicateLessonDescription.duplicateLessonDescriptions.main': '主要课程模版',
  'duplicateLessonDescription.mergeDuplicates': '合并所有重复课程模版',
  'duplicateLessonDescription.addDuplicates': '添加重复课程模版',
  'duplicateLessonDescription.addDuplicates.prompt': '没有找到重复课模版？',
  'duplicateLessonDescription.addDuplicates.success': '添加重复课程模版成功',
  'duplicateLessonDescription.addDuplicates.failure': '添加重复课程模版失败',
  'duplicateLessonDescription.merged': '已合并',
  'duplicateLessonDescription.notMerged': '待合并',
  'duplicateLessonDescription.rejected': '已拒绝',
  'duplicateLessonDescription.recover': '恢复',

  'duplicateLessonDescription.cancelMerge': '取消合并',
  'duplicateLessonDescription.merge': '确认合并',
  'duplicateLessonDescription.merge.confirmText':
    '已选择的课程模版一旦合并，所有即将开始的课程将重新关联到主课程模版上。此操作无法撤销，请确认后再继续操作合并。',
  'duplicateLessonDescription.cancelMerge.confirmText':
    '取消课程模版合并操作仅会影响取消后生成课程的课程模版ID，此前已生成的课程数据不会发生改变。请确认后再继续操作取消。',

  'activate.success': '激活成功',
  'deactivate.success': '停用成功',
  'edit.success': '编辑成功',

  hasPeakOffPeak: '有高低峰课程',

  'navBar.billingInvoicesTitle': '发票相关',
  'menuBar.billingInvoicesTitle': '发票相关',

  'exchangeRates.exchangeRateChangeMode': '修改模式',
  'exchangeRates.exchangeRate': '汇率',
  'exchangeRates.exchangeRate.message': '请输入汇率',
  'exchangeRates.batchUpdateCredits': '批量修改点数',
  'exchangeRates.batchUpdateCredits.message': '在原来的点数基础上+/-',
  'navBar.exchangeRatesStudiosTitle': '品牌详情',
  'navBar.exchangeRatesLessonDescriptionsTitle': '场馆详情',
  earlyVisitRateTips: '过去30到7天的预约中，early visit占completed预约的百分比',
  costPerCreditTips: '过去30到7天的预约中，已完成的课程的用户总花费/课程总共消费的点数',
  exchangeRateTips: '课程模板的价格与点数的汇率，计算方式：completed_class_price_cents/100/credits',
  fcfPercentageTips:
    '课程模板的early visit价格占比，计算方式：100 - 100 * early_visit_price_cents / completed_class_price_cents',

  cityName: '城市',
  organizationName: '品牌名称',
  studioName: '场馆名称',
  lessonDescriptionName: '课程名称',
  exchangeRate: '当前汇率',
  fcfPercentage: 'FCF百分比',
  earlyVisitRate: '初访率',
  totalPayout: '总支出（元）',
  activities: '分类',
  costPerCredit: '每一点点数实际花费(元/点)',
  studioList: '场馆列表',
  viewStudioList: '查看场馆列表',
  viewLessonDescriptionList: '查看课程模版列表',
  'exchangeRates.update': '修改汇率',
  'exchangeRates.updateSuccess': '修改汇率成功',
  'exchangeRates.updateFailed': '修改汇率失败',
  'exchangeRates.exchangeRateRange': '汇率范围',
  'exchangeRates.fcfPercentageRange': 'FCF百分比范围',
  'exchangeRates.costPerCreditRange': '每一点点数实际花费范围',
  'exchangeRates.earlyVisitRateRange': '初访率范围',
  'exchangeRates.isIntegrator': '是否来自第三方',
  'exchangeRates.integratorUpdateTitle': '第三方课程汇率修改',
  'exchangeRates.updateContent': '请确认是否继续修改汇率?',
  'exchangeRates.adjuestment.tips1':
    '最终点数会round up，例如计算出来点数为3.33， 则最终点数会是4点',
  'exchangeRates.adjuestment.tips2':
    '此修改会影响到此刻到未来的所有课程，并且修改之后会自动更新peak/off-peak的价格以及用户看到的点数',

  'exchangeRates.adjuestment.studioTips1':
    '当前场馆来自第三方且课程模板也被其他场馆使用，修改汇率会影响到其他场馆的课程模板价格',
  'exchangeRates.adjuestment.studioTips2':
    '当前场馆中存在课程模板也被其他场馆使用，修改汇率会影响到其他场馆的课程模板价格',

  'exchangeRates.adjuestment.lessonTips1':
    '当前课程是第三方课程且该课程也被其他场馆使用，修改汇率会影响到其他场馆的课程价格',
  'exchangeRates.adjuestment.lessonTips2':
    '当前课程被其他场馆使用，修改汇率会影响到其他场馆的课程价格',
  'exchangeRates.fetchFailed': '获取汇率失败',

  createdAt: '创建时间',
  isExpired: '是否过期',
  createdBy: '创建人',
  updatedBy: '更新人',
  updatedAt: '更新时间',
  tableActionColumn: '操作',
  edit: '编辑',
  view: '查看',
  confirm: '确认',
  success: '成功',
  error: '失败',
  cancel: '取消',
  continue: '继续',
  yes: '是',
  no: '否',
  search: '搜索',
  batchDownload: '批量下载',
  download: '下载',
  reset: '重置',
  type: '类型',
  locale: '语言',
  clickToUpload: '点击上传',
  addNewTranslation: '添加语言配置',
  addNewTier: '添加分段式奖励配置',
  submit: '提交',
  apiError: '请求失败',
  networkError: '网络请求失败，请稍后重试',
  operationSuccess: '操作成功',
  goBack: '返回',
  userId: '用户ID',
  loginError: '登录失败',
  duplicate: '复制',
  taggings: 'Taggings',
  editTaggings: '编辑Taggings',
  isActive: '活跃',
  config: '配置',
  translations: '语言·翻译',
  bookingRules: '预约规则',
  lessonInformation: '课程信息',
  status: '状态',
  name: '名称',
  funFact: '趣味点',
  activityList: '课程类型',
  label: '标签',
  photos: '图片',
  companyPaymentDetail: '支付详情',
  delete: '删除',
  add: '添加',
  monday: '周一',
  tuesday: '周二',
  wednesday: '周三',
  thursday: '周四',
  friday: '周五',
  saturday: '周六',
  sunday: '周日',
  previous: '上一步',
  next: '下一步',
  rule: '规则',
  activate: '激活',
  deactivate: '停用',
  email: '邮箱',
  'email.message': '请输入邮箱',
  reject: '拒绝',
  exportSuccess: '导出成功',
  exportError: '导出失败',
  orderBy: '排序',
  'orderBy.message': '请选择排序',
  orderDirection: '排序方向',
  ascending: '升序',
  descending: '降序',
}

export default zh
