import { Button, Form, Modal, notification } from 'antd'
import translate from '../../../i18n'
import DebounceSelect from '../../debounce-select/debounce-select'
import { getLessonDescriptionsSuggestionsByIdAndName } from '../../../api/lessonDescriptionsApi'
import { getOptions } from '../../../helpers/utils'
import { CreateDuplicateLessonDescriptionRequest } from '../../../pages/lesson-description-container/share-type'
import { FormattedMessage } from 'react-intl'
import { createDuplicateLessonDescription } from '../../../api/duplicationLessonDescriptionsApi'

interface IProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
}

const AddDuplicateLessonDescriptionsModal = ({ open, handleCancel, handleSubmit }: IProps) => {
  const [form] = Form.useForm<CreateDuplicateLessonDescriptionRequest>()

  async function fetchLessonDescriptionsSuggestionList(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getLessonDescriptionsSuggestionsByIdAndName(keyword).then(res => {
      return getOptions(res)
    })
  }

  const onFinish = (values: CreateDuplicateLessonDescriptionRequest) => {
    createDuplicateLessonDescription(values)
      .then(res => {
        if (res) {
          notification.success({
            message: translate('duplicateLessonDescription.addDuplicates.success'),
          })
          handleSubmit()
        }
      })
      .catch(err => {
        notification.error({
          message: translate('duplicateLessonDescription.addDuplicates.failure'),
          description: err?.message || translate('networkError'),
        })
      })
  }

  return (
    <Modal
      open={open}
      footer={null}
      forceRender
      title={translate('duplicateLessonDescription.addDuplicates')}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="change_admin_user_password"
        onFinish={onFinish}
      >
        <Form.Item
          name="lessonDescriptionId"
          label={translate('duplicateLessonDescription.mainLessonDescription.id')}
          required={true}
        >
          <DebounceSelect
            allowClear
            showSearch={true}
            placeholder="可以通过id/name搜索lessonDescriptions"
            fetchOptions={fetchLessonDescriptionsSuggestionList}
          />
        </Form.Item>

        <Form.Item
          name="duplicateLessonDescriptionIds"
          label={translate('duplicateLessonDescription.duplicateLessonDescriptions')}
          required={true}
        >
          <DebounceSelect
            allowClear
            showSearch={true}
            placeholder="可以通过id/name搜索lessonDescriptions"
            fetchOptions={fetchLessonDescriptionsSuggestionList}
            mode="multiple"
          />
        </Form.Item>

        <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
          <Button htmlType="submit" type="primary">
            <FormattedMessage id="submit"></FormattedMessage>
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddDuplicateLessonDescriptionsModal
