import { Button, Form } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { IFirstClassDiscountDisabledLessonsFilterParams } from '../../../pages/first-class-discount-disabled-lessons-container/share-type'
import translate from '../../../i18n'
import DebounceSelect from '../../debounce-select/debounce-select'
import { getLessonDescriptionsSuggestion } from '../../../api/lessonDescriptionsApi'
import { getOptions } from '../../../helpers/utils'

const FirstClassDiscountDisabledLessonsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IFirstClassDiscountDisabledLessonsFilterParams) => void
  formData: IFirstClassDiscountDisabledLessonsFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  async function fetchLessonDescriptionsSuggestionList(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getLessonDescriptionsSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  const onFinish = (values: IFirstClassDiscountDisabledLessonsFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item
        label={translate('lessonCreditPricing.lessonDescriptionId')}
        name="lessonDescriptionId"
      >
        <DebounceSelect
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="选择课程"
          fetchOptions={fetchLessonDescriptionsSuggestionList}
        />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FirstClassDiscountDisabledLessonsFilter
