import { Form, Input, Button, Select, notification } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { createAdminUser, editAdminUser, getAdminUserInfo } from '../../../api/userApi'
import { ReduxStore } from '../../../store'
import { clearAuthAction } from '../../../store/userAuth/action'
import { clearUserInfoAction } from '../../../store/userInfo/action'
import { Role } from '../../admin-roles-container/share-type'
import styles from './admin-user-form.module.less'

const { Option } = Select
interface IUserForm {
  email: string
  password: string
  confirmPassword?: string
  roleIds: number[]
}
const AdminUserForm = (props: ConnectedProps<typeof withConnect>) => {
  const params = useParams()
  const navigation = useNavigate()

  const userId = params?.id
  const [form] = Form.useForm<IUserForm>()

  const onFinish = (values: IUserForm) => {
    const { email, roleIds, password } = values
    const params = {
      email,
      roleIds,
    }
    if (userId) {
      editAdminUser(Number(userId), params).then(res => {
        if (email === props.userInfo.email) {
          setTimeout(() => {
            notification.info({
              message: '修改用户成功，需要重新登录',
            })
            props.clearAuthAction()
            props.clearUserInfoAction()
          }, 500)
        } else {
          notification.success({
            message: '修改用户成功',
          })
          navigation(`/admin-users/${res.id}`)
        }
      })
    } else {
      createAdminUser({ ...params, password }).then(res => {
        notification.success({
          message: '创建用户成功',
        })
        navigation(`/admin-users/${res.id}`)
      })
    }
  }

  useEffect(() => {
    if (userId) {
      getAdminUserInfo(Number(userId)).then(res => {
        const { email, roles } = res
        const roleIds = roles.map(item => item.id)
        form.setFieldsValue({ email, roleIds: roleIds, password: '123456' })
      })
    }
  }, [userId])

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      form={form}
      name="normal_login"
      className={styles['user-form']}
      onFinish={onFinish}
    >
      <Form.Item
        label="电子邮件"
        name="email"
        rules={[
          { required: true, message: '请输入电子邮箱!' },
          { type: 'email', message: '输入的电子邮件无效！' },
        ]}
      >
        <Input readOnly={!!userId} disabled={!!userId} />
      </Form.Item>

      <Form.Item
        label="账号密码"
        name="password"
        hasFeedback
        rules={[{ required: true, message: '请输入账号密码!' }]}
      >
        <Input.Password readOnly={!!userId} disabled={!!userId} />
      </Form.Item>

      {!userId && (
        <Form.Item
          label="确认密码"
          name="confirmPassword"
          hasFeedback
          rules={[
            {
              required: true,
              message: '请确认密码',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('您输入的两个密码不匹配!'))
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      )}

      <Form.Item
        name="roleIds"
        label="用户角色"
        rules={[{ required: true, message: '请选择用户角色' }]}
      >
        <Select mode="multiple">
          {props.roles.map((role: Role) => {
            return (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          确定
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    roles: state.global.roles,
    userInfo: state.userInfo,
  }
}

const mapDispatchToProps = {
  clearAuthAction,
  clearUserInfoAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(AdminUserForm)
