import { Button, Input, Form, Space, Upload, Radio, notification, Modal } from 'antd'
import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import styles from './batch-reclaim-credits-modal.module.less'
import translate from '../../../i18n'
import * as _ from 'lodash'
import {
  IBatchReclaimCreditsFormValue,
  IBatchUploadByCorporateIdResponse,
} from '../../../pages/corporates-container/share-type'
import { reclaimCreditsBulkUploadByCorporateId } from '../../../api/corporateApi'
import { FormattedMessage } from '../../intl'
import PhoneNumbersCheckModal from '../phone-numbers-check-modal/phone-numbers-check-modal'

const { TextArea } = Input

interface IBatchReclaimCreditsModalProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
  corporateId: number
}

const BatchReclaimCreditsModal = (props: IBatchReclaimCreditsModalProps) => {
  const { open, corporateId, handleSubmit, handleCancel } = props
  const [form] = Form.useForm()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [phoneNumbersCheckModalVisible, setPhoneNumbersCheckModalVisible] = useState<boolean>(false)
  const [phoneNumbersCheckModalResult, setPhoneNumbersCheckModalResult] =
    useState<IBatchUploadByCorporateIdResponse>()
  const [formValue, setFormValue] = useState<FormData>()

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  const onFinish = (values: IBatchReclaimCreditsFormValue) => {
    if (corporateId) {
      const data = new FormData()
      if (values.file && values.file.length) {
        data.append('file', values.file[0].originFileObj)
      }
      if (values.phoneNumbers) {
        const phoneNumbers = _.split(values.phoneNumbers, ',')
        data.append('phoneNumbers', JSON.stringify(phoneNumbers))
      }

      data.append('notes', values.notes || '')
      data.append('deactivate', String(values.deactivate))

      setFormValue(data)
      setLoadingButton(true)
      reclaimCreditsBulkUploadByCorporateIdAction(data)
    }
  }

  const reclaimCreditsBulkUploadByCorporateIdAction = (data: FormData) => {
    if (!corporateId) {
      return
    }
    reclaimCreditsBulkUploadByCorporateId(corporateId, data)
      .then(res => {
        if (!res.isSuccess) {
          handleCancel()
          setPhoneNumbersCheckModalResult(res)
          setPhoneNumbersCheckModalVisible(true)
        } else {
          notification.success({
            message: translate('corporate.batchReclaimCredits.success'),
          })
          if (phoneNumbersCheckModalVisible) {
            setPhoneNumbersCheckModalVisible(false)
          }
          handleSubmit()
        }
      })
      .catch(err => {
        notification.error({
          message: translate('corporate.batchReclaimCredits.failed'),
          description: err.message ?? translate('networkError'),
        })
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const onSkip = () => {
    if (!formValue) {
      return
    }
    formValue.append('ignoreInactiveUsers', 'true')
    setFormValue(formValue)
    reclaimCreditsBulkUploadByCorporateIdAction(formValue)
  }
  return (
    <>
      <Modal
        title={translate('corporate.batchReclaimCredits')}
        open={open}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        width={800}
      >
        <Form
          layout="horizontal"
          name="batch_reclaim_credits"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ deactivate: false }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="file"
            label={translate('corporate.phoneNumber.bulkUpload')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item name="phoneNumbers" label={translate('corporate.phoneNumber.specify')}>
            <TextArea placeholder="多个用户phone number，使用,隔开" showCount />
          </Form.Item>

          <Form.Item name="deactivate" label={translate('corporate.batchReclaimCredits.action')}>
            <Radio.Group>
              <Radio value={false}>
                <FormattedMessage id="corporate.batchReclaimCredits.action.onlyCredits" />
              </Radio>
              <Radio value={true}>
                <FormattedMessage id="corporate.batchReclaimCredits.action.reclaimAndDisable" />
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="notes" label={translate('corporate.notes')}>
            <TextArea placeholder={translate('corporate.notes.placeholder')} showCount />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Space className={styles['button-group']}>
              <Button loading={loadingButton} type="primary" htmlType="submit">
                <FormattedMessage id="submit" />
              </Button>
              <Button loading={loadingButton} onClick={handleCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {phoneNumbersCheckModalResult && (
        <PhoneNumbersCheckModal
          visibale={phoneNumbersCheckModalVisible}
          result={phoneNumbersCheckModalResult}
          onConfirm={onSkip}
          onCancel={() => setPhoneNumbersCheckModalVisible(false)}
          title={translate('corporate.batchReclaimCredits.modal.title', {
            number: phoneNumbersCheckModalResult?.inactivePhoneNumbers?.length,
          })}
          okText={translate('corporate.batchReclaimCredits.modal.okText')}
        />
      )}
    </>
  )
}

export default BatchReclaimCreditsModal
