import config from '../config/index'
import {
  ICardConfig,
  IPaginatedCardConfigsParams,
  PaginatedCardConfigsResponse,
} from '../pages/card-stacks-container/share-type'
import { authRequest } from '../request'
import { UploadFileDirectoryEnum } from '../share-types'

const { mainApi } = config

export const getPaginatedCardConfigs = (data: IPaginatedCardConfigsParams) =>
  authRequest.get<MISSING_TYPE, PaginatedCardConfigsResponse>(`${mainApi}/card-configs`, {
    params: data,
  })

export const getCardConfigDetail = (cardConfigId: number) =>
  authRequest.get<MISSING_TYPE, ICardConfig>(`${mainApi}/card-configs/${cardConfigId}`)

export const createCardConfig = (data: MISSING_TYPE) =>
  authRequest.post<MISSING_TYPE, ICardConfig>(`${mainApi}/card-configs`, data)

export const editCardConfig = (cardConfigId: number, data: MISSING_TYPE) =>
  authRequest.put<MISSING_TYPE, ICardConfig>(`${mainApi}/card-configs/${cardConfigId}`, data)

export const uploadImage = (filename: string, directory: UploadFileDirectoryEnum) =>
  authRequest.post<MISSING_TYPE, string>(
    `${mainApi}/images/pre-signed-url?filename=${filename}&directory=${directory}`,
  )
