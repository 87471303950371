import {
  IStudioLeaderboardRankingType,
  PaginatedStudioLeaderboardsResponse,
} from '../../pages/studio-leaderboards-container/share-type'
import { StudioLeaderboardAction } from './action'
import {
  SET_STUDIO_LEADERBOARD_RANKING_TYPES,
  SET_PENDING_STUDIO_LEADERBOARDS_TYPES,
  SET_APPROVED_STUDIO_LEADERBOARDS_TYPES,
  SET_REJECTED_STUDIO_LEADERBOARDS_TYPES,
  SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES,
} from './constants'
import update from 'immutability-helper'

interface InitialState {
  studioLeaderboardRankingTypes: IStudioLeaderboardRankingType[]
  pendingStudioLeaderboards: PaginatedStudioLeaderboardsResponse
  approvedStudioLeaderboards: PaginatedStudioLeaderboardsResponse
  rejectedStudioLeaderboards: PaginatedStudioLeaderboardsResponse
  expiredStudioLeaderboards: PaginatedStudioLeaderboardsResponse
}

const defaultStudioLeaderboards = {
  pageInfo: {
    pageSize: 10,
    currentPage: 1,
    count: 0,
  },
  data: [],
}

const initialState: InitialState = {
  studioLeaderboardRankingTypes: [],
  pendingStudioLeaderboards: defaultStudioLeaderboards,
  approvedStudioLeaderboards: defaultStudioLeaderboards,
  rejectedStudioLeaderboards: defaultStudioLeaderboards,
  expiredStudioLeaderboards: defaultStudioLeaderboards,
}

function studioLeaderboardReducer(state = initialState, action: StudioLeaderboardAction) {
  switch (action.type) {
    case SET_STUDIO_LEADERBOARD_RANKING_TYPES:
      return update(state, { $merge: { studioLeaderboardRankingTypes: action.payload } })
    case SET_PENDING_STUDIO_LEADERBOARDS_TYPES:
      return update(state, {
        $merge: { pendingStudioLeaderboards: action.paginatedStudioLeaderboards },
      })
    case SET_APPROVED_STUDIO_LEADERBOARDS_TYPES:
      return update(state, {
        $merge: { approvedStudioLeaderboards: action.paginatedStudioLeaderboards },
      })
    case SET_REJECTED_STUDIO_LEADERBOARDS_TYPES:
      return update(state, {
        $merge: { rejectedStudioLeaderboards: action.paginatedStudioLeaderboards },
      })
    case SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES:
      return update(state, {
        $merge: { expiredStudioLeaderboards: action.paginatedStudioLeaderboards },
      })
    default:
      return state
  }
}

export default studioLeaderboardReducer
