import {
  Button,
  Form,
  Input,
  InputNumber,
  Switch,
  DatePicker,
  Divider,
  Select,
  Upload,
  notification,
} from 'antd'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InputColor from '../../../components/input-color/input-color'
import {
  createReferralCampaign,
  editReferralCampaign,
  getReferralCampaignDetail,
} from '../../../api/referralCampaignsApi'
import {
  SenderRewardSchemeType,
  ReceiverRewardSchemeType,
  referralCampaignSenderRewardOptions,
  defaultCampaignSenderRewardOptions,
} from '../share-type'
import {
  DATE_TIME_FORMAT,
  DEFAULT_REFERRAL_GENERATING_POSTER_BUTTON_COLOR,
  DEFAULT_REFERRAL_PAGE_BACKGROUND_COLOR,
  DEFAULT_REFERRAL_POSTER_HIGHLIGHT_COLOR,
  DEFAULT_REFERRAL_SAVE_POSTER_BUTTON_COLOR,
  DEFAULT_REFERRAL_SHARING_BUTTON_COLOR,
  LocaleOptions,
} from '../../../helpers/constant'
import { LocaleType, UploadFileDirectoryEnum } from '../../../share-types'
import { uploadImage } from '../../../api/cardConfigApi'
import { request } from '../../../request'
import {
  IReferralCampaignFormValues,
  referralCampaignFormValuesToRequest,
  referralCampaignToFormValues,
} from '../../../helpers/referral-campaign-helpers'
import { ReduxStore } from '../../../store'
import translate from '../../../i18n'
import { FormattedMessage } from '../../../components/intl'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getCouponTemplatesSuggestions } from '../../../api/couponApi'
import { getOptions } from '../../../helpers/utils'
import config from '../../../config'
import styles from './referral-campaign-form.module.less'

const { RangePicker } = DatePicker
const { Option } = Select
const { TextArea } = Input
const referralAssetUrl = `${config.assetUrl}/mini-program/assets/referral`

interface Iprops {
  title?: string
}

interface OptionType {
  label: string
  value: number
}

const SectionTitle = (props: Iprops) => {
  return <Divider style={{ fontSize: '14px' }}>{props.title}</Divider>
}

const ReferralCampaignForm = () => {
  const [form] = Form.useForm()
  const senderRewardSchemeTypeValue = Form.useWatch('senderRewardSchemeType', form)
  const receiverRewardSchemeTypeValue = Form.useWatch('receiverRewardSchemeType', form)
  const isDefaultValue = Form.useWatch('isDefault', form)

  const params = useParams()
  const navigation = useNavigate()
  const [referralCampaignId] = useState<string | undefined>(params?.id)
  const [defaultCouponTemplateOptions, setDefaultCouponTemplateOptions] = useState<OptionType[]>([])
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    fetchCouponTemplatesSuggestions('').then(options => setDefaultCouponTemplateOptions(options))
  }, [])

  useEffect(() => {
    if (referralCampaignId) {
      getReferralCampaignDetail(Number(referralCampaignId)).then(data => {
        form.setFieldsValue(referralCampaignToFormValues(data))
        setDisabled(true)
      })
    }
  }, [referralCampaignId])

  useEffect(() => {
    form.setFieldsValue({ senderRewardSchemeType: undefined })
  }, [isDefaultValue])

  const onFinish = (values: IReferralCampaignFormValues) => {
    const formData = referralCampaignFormValuesToRequest(values)
    if (referralCampaignId) {
      editReferralCampaign(Number(referralCampaignId), formData)
        .then(res => {
          notification.success({
            message: translate('referralCampaigns.editSuccess'),
          })
          navigation(`/referral-campaigns/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: translate('referralCampaigns.editError'),
            description: err?.message || translate('networkError'),
          })
        })
    } else {
      createReferralCampaign(formData)
        .then(res => {
          notification.success({
            message: translate('referralCampaigns.createSuccess'),
          })
          navigation(`/referral-campaigns/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: translate('referralCampaigns.createError'),
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  const rangeConfig = {
    rules: [
      {
        type: 'array' as const,
        required: true,
        message: translate('referralCampaigns.rangeTime.message'),
      },
    ],
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e.fileList
  }

  const uploadUrl = (options: any, directory: UploadFileDirectoryEnum) => {
    const { onSuccess, onError, file, filename } = options
    const fileExtension = file.name.split('.').pop()
    const imageFilename = `${filename}_${file.uid}.${fileExtension}`
    uploadImage(imageFilename, directory)
      .then(res => {
        onSuccess(res)
        request({
          url: res,
          method: 'PUT',
          data: file,
          headers: {
            'x-amz-acl': 'public-read',
          },
        })
      })
      .catch(err => {
        onError({ err })
      })
  }

  async function fetchCouponTemplatesSuggestions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getCouponTemplatesSuggestions({ keyword: keyword ?? '', isValid: true }).then(res => {
      return getOptions(res)
    })
  }

  return (
    <Form
      name="referral-campaigns"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{
        isActive: false,
        isDefault: false,
        countDownBeforeMinutes: 0,
        pageBackgroundColor: DEFAULT_REFERRAL_PAGE_BACKGROUND_COLOR,
        savePosterButtonColor: DEFAULT_REFERRAL_SAVE_POSTER_BUTTON_COLOR,
        sharingButtonColor: DEFAULT_REFERRAL_SHARING_BUTTON_COLOR,
        generatingPosterButtonColor: DEFAULT_REFERRAL_GENERATING_POSTER_BUTTON_COLOR,
        senderRewardSchemeReceiverCount: 3,
        senderRewardSchemeCredits: 5,
      }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className="resource-form"
    >
      <SectionTitle title={translate('referralCampaigns.campaignConfig')} />
      <Form.Item
        label={translate('referralCampaigns.name')}
        name="name"
        rules={[{ required: true, message: translate('referralCampaigns.name.message') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={translate('referralCampaigns.rangeTime')} name="rangeTime" {...rangeConfig}>
        <RangePicker showTime format={DATE_TIME_FORMAT} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label={translate('referralCampaigns.isDefault')}
        tooltip={translate('referralCampaigns.isDefault.desc')}
        name="isDefault"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={translate('referralCampaigns.isActive')}
        tooltip={translate('referralCampaigns.isActive.desc')}
        name="isActive"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={translate('referralCampaigns.pageBackgroundColor')}
        name="pageBackgroundColor"
        rules={[{ required: true }]}
      >
        <InputColor />
      </Form.Item>

      <Form.Item
        label={translate('referralCampaigns.savePosterButtonColor')}
        name="savePosterButtonColor"
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <img
              src={`${referralAssetUrl}/savePosterButtonColor.png`}
              className={styles['tooltip-inner-image']}
            />
          </div>
        }
      >
        <InputColor />
      </Form.Item>

      <Form.Item
        label={translate('referralCampaigns.sharingButtonColor')}
        name="sharingButtonColor"
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <img
              src={`${referralAssetUrl}/sharingButtonColor.png`}
              className={styles['tooltip-inner-image']}
            />
          </div>
        }
      >
        <InputColor />
      </Form.Item>

      <Form.Item
        label={translate('referralCampaigns.generatingPosterButtonColor')}
        name="generatingPosterButtonColor"
        rules={[{ required: true }]}
        tooltip={
          <div className={styles['tooltip-inner']}>
            <img
              src={`${referralAssetUrl}/generatingPosterButtonColor.png`}
              className={styles['tooltip-inner-image']}
            />
          </div>
        }
      >
        <InputColor />
      </Form.Item>

      <SectionTitle title={translate('referralCampaigns.senderRewardScheme')} />
      <Form.Item
        label={translate('type')}
        tooltip={translate('referralCampaigns.senderRewardScheme.type.desc')}
        name="senderRewardSchemeType"
      >
        <Select
          placeholder={translate('referralCampaigns.senderRewardScheme.type.message')}
          allowClear
          disabled={disabled}
        >
          {isDefaultValue
            ? defaultCampaignSenderRewardOptions.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            : referralCampaignSenderRewardOptions.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
        </Select>
      </Form.Item>
      {senderRewardSchemeTypeValue === SenderRewardSchemeType.REFERRAL_CREDITS && (
        <Form.Item
          label={translate('referralCampaigns.credits')}
          tooltip={translate('referralCampaigns.credits.desc')}
          rules={[{ required: true, message: translate('referralCampaigns.credits.message') }]}
          name="senderRewardSchemeCredits"
        >
          <InputNumber formatter={value => `${value}点`} disabled={disabled} />
        </Form.Item>
      )}

      {senderRewardSchemeTypeValue === SenderRewardSchemeType.RECEIVER_COUNT_BASED_TIERED && (
        <Form.List
          name="senderRewardSchemeRewardTiers"
          initialValue={[{ receiverCount: undefined }]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <div key={key}>
                    <Form.Item label={`TIER ${key + 1}`}></Form.Item>
                    <Form.Item
                      {...restField}
                      label={translate('referralCampaigns.receiverCount')}
                      name={[name, 'receiverCount']}
                      rules={[
                        {
                          required: true,
                          message: translate('referralCampaigns.receiverCount.message'),
                        },
                      ]}
                      tooltip={translate('referralCampaigns.receiverCount.desc')}
                    >
                      <InputNumber formatter={value => `${value}人`} disabled={disabled} />
                    </Form.Item>
                    <Form.Item
                      label={translate('referralCampaigns.couponTemplateId')}
                      name={[name, 'couponTemplateId']}
                      rules={[
                        {
                          required: true,
                          message: translate('referralCampaigns.couponTemplateId.message'),
                        },
                      ]}
                    >
                      <DebounceSelect
                        showSearch
                        placeholder={translate('referralCampaigns.couponTemplateId.placeholder')}
                        fetchOptions={fetchCouponTemplatesSuggestions}
                        defaultOptions={defaultCouponTemplateOptions}
                        disabled={disabled}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Form.Item colon={false} label="  ">
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          disabled={disabled}
                          onClick={() => remove(name)}
                        />
                      </Form.Item>
                    ) : null}
                  </div>
                )
              })}

              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  <FormattedMessage id="addNewTier" />
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
      {senderRewardSchemeTypeValue === SenderRewardSchemeType.RECEIVER_COUNT_BASED && (
        <Form.Item
          label={translate('referralCampaigns.receiverCount')}
          tooltip={translate('referralCampaigns.receiverCount.desc')}
          rules={[
            { required: true, message: translate('referralCampaigns.receiverCount.message') },
          ]}
          name="senderRewardSchemeReceiverCount"
        >
          <InputNumber formatter={value => `${value}人`} disabled={disabled} />
        </Form.Item>
      )}
      {senderRewardSchemeTypeValue === SenderRewardSchemeType.RECEIVER_COUNT_BASED && (
        <Form.Item
          label={translate('referralCampaigns.couponTemplateId')}
          name="senderRewardSchemeCouponTemplateId"
          rules={[
            {
              required: true,
              message: translate('referralCampaigns.couponTemplateId.message'),
            },
          ]}
        >
          <DebounceSelect
            showSearch
            placeholder={translate('referralCampaigns.couponTemplateId.placeholder')}
            fetchOptions={fetchCouponTemplatesSuggestions}
            defaultOptions={defaultCouponTemplateOptions}
            disabled={disabled}
          />
        </Form.Item>
      )}

      <SectionTitle title={translate('referralCampaigns.receiverRewardScheme')} />
      <Form.Item
        label={translate('type')}
        tooltip={translate('referralCampaigns.receiverRewardScheme.type.desc')}
        name="receiverRewardSchemeType"
      >
        <Select
          placeholder={translate('referralCampaigns.receiverRewardScheme.type.message')}
          allowClear
          disabled={disabled}
        >
          <Option
            key={ReceiverRewardSchemeType.REFERRAL_RECEIPT}
            value={ReceiverRewardSchemeType.REFERRAL_RECEIPT}
          >
            REFERRAL_RECEIPT
          </Option>
        </Select>
      </Form.Item>
      {receiverRewardSchemeTypeValue === ReceiverRewardSchemeType.REFERRAL_RECEIPT && (
        <Form.Item
          label={translate('referralCampaigns.couponTemplateId')}
          name="receiverRewardSchemeCouponTemplateId"
          rules={[
            { required: true, message: translate('referralCampaigns.couponTemplateId.message') },
          ]}
        >
          <DebounceSelect
            showSearch
            placeholder={translate('referralCampaigns.couponTemplateId.placeholder')}
            fetchOptions={fetchCouponTemplatesSuggestions}
            defaultOptions={defaultCouponTemplateOptions}
            disabled={disabled}
          />
        </Form.Item>
      )}
      <SectionTitle title={translate('referralCampaigns.translations')} />
      <Form.List
        name="translations"
        initialValue={[
          { locale: 'zh-CN', highlightColor: DEFAULT_REFERRAL_POSTER_HIGHLIGHT_COLOR },
          { locale: 'en', highlightColor: DEFAULT_REFERRAL_POSTER_HIGHLIGHT_COLOR },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    label={translate('locale')}
                    name={[name, 'locale']}
                    rules={[{ required: true, message: translate('locale.message') }]}
                  >
                    <Select>
                      {LocaleOptions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={translate(
                      'referralCampaigns.translations.referralPosterModalDescription',
                    )}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'referralCampaigns.translations.referralPosterModalDescription.message',
                        ),
                      },
                    ]}
                    {...restField}
                    name={[name, 'referralPosterModalDescription']}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/referralPosterModalDescription.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <TextArea
                      showCount
                      maxLength={100}
                      placeholder={translate(
                        'referralCampaigns.translations.referralPosterModalDescription',
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    label={translate('referralCampaigns.translations.highlightColor')}
                    name={[name, 'highlightColor']}
                  >
                    <InputColor />
                  </Form.Item>

                  <Form.Item
                    label={translate('referralCampaigns.translations.sharingDescription')}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'referralCampaigns.translations.sharingDescription.message',
                        ),
                      },
                    ]}
                    {...restField}
                    name={[name, 'sharingDescription']}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/sharingDescription.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <TextArea
                      showCount
                      maxLength={100}
                      placeholder={translate('referralCampaigns.translations.receiverPageTitle')}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'bannerUrl']}
                    label={translate('referralCampaigns.translations.bannerUrl')}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true }]}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/bannerUrl_2.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <Upload
                      maxCount={1}
                      name="bannerUrl"
                      customRequest={config =>
                        uploadUrl(config, UploadFileDirectoryEnum.REFERRAL_BANNERS)
                      }
                      listType="picture"
                    >
                      <Button icon={<UploadOutlined />}>
                        <FormattedMessage id="clickToUpload" />
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={translate('referralCampaigns.translations.receiverPageBannerUrl')}
                    name={[name, 'receiverPageBannerUrl']}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true }]}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/receiverPageBannerUrl_2.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <Upload
                      maxCount={1}
                      name="receiverPageBannerUrl"
                      customRequest={config =>
                        uploadUrl(config, UploadFileDirectoryEnum.REFERRAL_BANNERS)
                      }
                      listType="picture"
                    >
                      <Button icon={<UploadOutlined />}>
                        <FormattedMessage id="clickToUpload" />
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={translate('referralCampaigns.translations.shareImageUrl')}
                    name={[name, 'shareImageUrl']}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true }]}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/shareImageUrl_2.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <Upload
                      maxCount={1}
                      name="shareImageUrl"
                      customRequest={config =>
                        uploadUrl(config, UploadFileDirectoryEnum.REFERRAL_BANNERS)
                      }
                      listType="picture"
                    >
                      <Button icon={<UploadOutlined />}>
                        <FormattedMessage id="clickToUpload" />
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={translate('referralCampaigns.translations.posterImageUrl')}
                    name={[name, 'posterImageUrl']}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true }]}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/posterImageUrl_2.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <Upload
                      maxCount={1}
                      name="posterImageUrl"
                      customRequest={config =>
                        uploadUrl(config, UploadFileDirectoryEnum.REFERRAL_BANNERS)
                      }
                      listType="picture"
                    >
                      <Button icon={<UploadOutlined />}>
                        <FormattedMessage id="clickToUpload" />
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={translate('referralCampaigns.translations.referralEntranceImageUrl')}
                    name={[name, 'referralEntranceImageUrl']}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true }]}
                    tooltip={
                      <div className={styles['tooltip-inner']}>
                        <img
                          src={`${referralAssetUrl}/referralEntranceImageUrl.png`}
                          className={styles['tooltip-inner-image']}
                        />
                      </div>
                    }
                  >
                    <Upload
                      maxCount={1}
                      name="referralEntranceImageUrl"
                      customRequest={config =>
                        uploadUrl(config, UploadFileDirectoryEnum.REFERRAL_BANNERS)
                      }
                      listType="picture"
                    >
                      <Button icon={<UploadOutlined />}>
                        <FormattedMessage id="clickToUpload" />
                      </Button>
                    </Upload>
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Form.Item colon={false} label="  ">
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              )
            })}

            {fields.length < Object.keys(LocaleType).length && (
              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  <FormattedMessage id="addNewTranslation" />
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="submit" />
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ReferralCampaignForm)
