import { Button, Form, InputNumber, Select, Space, Input, DatePicker } from 'antd'
import translate from '../../../i18n'
import styles from './credits-notes-form.module.less'
import { CorporateCreditsRequest } from '../../../pages/corporates-container/share-type'
import React from 'react'

interface IProps {
  onFinish: (value: CorporateCreditsRequest) => void
  footerButton?: React.ReactNode
  loading: boolean
  originalCredits?: number
  showCreditsInput?: boolean
  showBufferPercentageInput?: boolean
  showExpiredAtInput?: boolean
  isTrialCorporate: boolean
}

const { Option } = Select
const { TextArea } = Input

const CreditsNotesForm = (props: IProps) => {
  const [form] = Form.useForm()
  const {
    onFinish,
    footerButton,
    loading,
    originalCredits,
    showBufferPercentageInput = false,
    showExpiredAtInput = false,
    isTrialCorporate,
  } = props
  const [addOrMinus, setAddOrMinus] = React.useState<'add' | 'minus'>('add')
  const newCredits = Form.useWatch('credits', form) || 0

  const onChangeAddOrMinus = (value: 'add' | 'minus') => {
    setAddOrMinus(value)
    form.setFieldsValue({
      credits: undefined,
    })
  }

  const selectBefore = (
    <Select value={addOrMinus} style={{ width: 115 }} onChange={onChangeAddOrMinus}>
      <Option value="add">{translate('corporate.credits.payment')} +</Option>
      <Option value="minus">{translate('corporate.credits.adjustment')} -</Option>
    </Select>
  )

  const creditsItemExtraRender = () => {
    if (originalCredits !== undefined && newCredits) {
      const credits = addOrMinus === 'add' ? newCredits : -newCredits
      return <div>调整后的点数为：{originalCredits + credits}点 </div>
    }
    return null
  }

  const onFormSubmit = (value: CorporateCreditsRequest) => {
    if (addOrMinus === 'minus') {
      value.credits = -value.credits
    }
    onFinish(value)
  }

  return (
    <Form
      layout="horizontal"
      name="credits-notes-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      onFinish={onFormSubmit}
      autoComplete="off"
      form={form}
      initialValues={showBufferPercentageInput ? { bufferPercentage: 0 } : {}}
    >
      <Form.Item
        label={
          originalCredits !== undefined
            ? translate('corporate.originCredits', { credits: originalCredits })
            : translate('corporate.credits')
        }
        name="credits"
        rules={[
          {
            required: true,
            message: '请输入点数！',
          },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve()
              } else if (value < 1) {
                return Promise.reject(new Error('最小值为1，请重新输入!'))
              } else if (originalCredits && addOrMinus === 'minus' && value > originalCredits) {
                return Promise.reject(new Error('选择点数调整时,最大值不能超过原企业点数!'))
              } else {
                return Promise.resolve()
              }
            },
          }),
        ]}
        extra={creditsItemExtraRender()}
      >
        <InputNumber
          className={styles['input-number']}
          addonBefore={originalCredits !== undefined ? selectBefore : null}
        />
      </Form.Item>

      {showBufferPercentageInput && (
        <Form.Item
          label={translate('corporate.bufferPercentage')}
          name="bufferPercentage"
          rules={[
            {
              required: true,
              message: translate('corporate.bufferPercentage.required'),
            },
          ]}
        >
          <InputNumber disabled={isTrialCorporate} addonAfter="%" min={0} />
        </Form.Item>
      )}

      {showExpiredAtInput && (
        <Form.Item
          label={translate('corporate.expiredAt')}
          name="expiredAt"
          rules={[
            {
              required: true,
              message: translate('corporate.expiredAt.required'),
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
      )}

      <Form.Item name="notes" label={translate('corporate.notes')}>
        <TextArea placeholder={translate('corporate.notes.placeholder')} showCount />
      </Form.Item>
      <Form.Item label=" " colon={false}>
        <Space className={styles['button-group']}>
          <Button loading={loading} type="primary" htmlType="submit">
            提交
          </Button>
          {footerButton}
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CreditsNotesForm
