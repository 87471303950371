import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { getCities, getPlans } from '../../api/authApi'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { setActiveCitiesAction } from '../../store/city/action'
import { setPlansAction } from '../../store/global/action'
import translate from '../../i18n'

const UserSegmentContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })

    getPlans({ page: 0, size: 999, type: 3 }).then(res => {
      const { setPlansAction } = props
      const miniProgramSelectablePlan = res.data.filter((item: any) => item.miniProgramSelectable)
      setPlansAction(miniProgramSelectablePlan)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/segments\/[0-9]+\/edit$/)) {
      return translate('navBar.editSegment')
    }

    if (pathName.match(/^\/segments\/new/)) {
      return translate('navBar.newSegment')
    }

    if (pathName.match(/^\/segments\/[0-9]+$/)) {
      return translate('navBar.viewSegment')
    }

    if (pathName.match(/^\/segments\/[0-9]+\/user-segments\/new$/)) {
      return translate('navBar.newUserSegments')
    }

    return translate('navBar.segmentsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
  setPlansAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(UserSegmentContainer)
