import { IPaginatedParams, PageInfo } from '../../share-types'

export enum BillingInvoiceStatusEnums {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
}

export interface IBillingInvoice {
  id: number
  organizationId: number
  organizationName: string
  fileUrl: string
  status: BillingInvoiceStatusEnums
  amountCents: number
  notes?: string
  rejectReason?: string
  createdAt: string
  updatedAt: string
}

export interface PaginatedBillingInvoicesResponse {
  data: IBillingInvoice[]
  pageInfo: PageInfo
}

export interface IBillingInvoiceFilterParams {
  status?: BillingInvoiceStatusEnums
  organizationId?: number
}

export type IPaginatedBillingInvoiceFilterParams = IPaginatedParams & IBillingInvoiceFilterParams

export interface IBillingInvoiceExportParams {
  status: BillingInvoiceStatusEnums
  organizationId?: number
  startTime: string
  endTime: string
}

export interface IBillingInvoiceStatusChangeParams {
  status: BillingInvoiceStatusEnums
  rejectReason?: string
}

export interface BillingInvoiceUploadPreSignedUrlResponse {
  preSignedUrl: string
  fileKey: string
}

export interface BillingInvoiceCreateRequest {
  fileKey: string
  amountCents: number
  notes?: string
}

export interface PresignedGetUrlResponse {
  preSignedUrl: string
  expiredAt: string
}
