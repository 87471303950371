import axios from 'axios'

import { store } from '../store'
import { addRequestCount, reduceRequestCount } from '../store/spinner/action'

const errorHandle = (error: MISSING_TYPE) => {
  const { response } = error
  store.dispatch(reduceRequestCount())
  let msg
  let statusCode
  if (response && response instanceof Object) {
    const { data } = response
    statusCode = response.status

    if (data instanceof Object) {
      return Promise.reject({
        ...data,
        success: false,
      })
    } else {
      msg = data
    }
  } else {
    statusCode = 600
    msg = error.message || 'Network Error'
  }

  return Promise.reject({ success: false, statusCode, message: msg, error })
}

const request = axios.create()
request.interceptors.response.use(response => {
  store.dispatch(reduceRequestCount())
  return response.data
}, errorHandle)

request.interceptors.request.use(config => {
  store.dispatch(addRequestCount())

  return config
})

export default request
