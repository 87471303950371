import { Button, notification, Space, Table, Typography } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import { ColumnsType } from 'antd/lib/table'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'
import {
  ICorporateCreditsDistributionScheduleMember,
  ICorporateMember,
} from '../../../pages/corporates-container/share-type'
import YesOrNoTag from '../../common/YesOrNoTag'
import { FormattedMessage } from 'react-intl'
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons'
import { editCorporateCreditsDistributionScheduleMember } from '../../../api/corporateApi'
import { useState } from 'react'
import CorporateMemberCreditsHistories from '../../corporate-member-credits-histories/corporate-member-credits-histories/corporate-member-credits-histories'

const { Title } = Typography

interface CorporateCreditsDistributionScheduleMemberTableProps {
  members: ICorporateCreditsDistributionScheduleMember[]
  corporateId: number
  corporateCreditsDistributionScheduleId: number
  onRefresh: () => void
}

const CorporateCreditsDistributionScheduleMemberTable = ({
  corporateId,
  corporateCreditsDistributionScheduleId,
  members,
  onRefresh,
}: CorporateCreditsDistributionScheduleMemberTableProps) => {
  const [openCreditsHistoriesModal, setOpenCreditsHistoriesModal] = useState<boolean>(false)
  const [creditsHistoriesModalCorporateMember, setCreditsHistoriesModalCorporateMember] =
    useState<ICorporateMember>()

  const onViewCorprateMemberCreditsHistories = (corporateMember: ICorporateMember) => {
    if (corporateMember) {
      setCreditsHistoriesModalCorporateMember(corporateMember)
      setOpenCreditsHistoriesModal(true)
    }
  }
  const onUpdateMemberStatus = (member: ICorporateCreditsDistributionScheduleMember) => {
    editCorporateCreditsDistributionScheduleMember(
      corporateId,
      corporateCreditsDistributionScheduleId,
      member.id,
      { isActive: !member.isActive },
    )
      .then(() => {
        onRefresh()
        notification.success({
          message: translate('corporateMember.editSuccess'),
        })
      })
      .catch(err => {
        notification.error({
          message: translate('corporateMember.editFailed'),
          description: err?.message || '网络请求失败，请稍后重试',
        })
      })
  }
  const columns: ColumnsType<ICorporateCreditsDistributionScheduleMember> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: '企业用户ID',
      dataIndex: ['corporateMember', 'id'],
      key: 'corporateMemberId',
      fixed: 'left',
      width: 60,
      render: (corporateMemberId: number, record: ICorporateCreditsDistributionScheduleMember) => (
        <Button
          type="link"
          onClick={() => onViewCorprateMemberCreditsHistories(record.corporateMember)}
        >
          {corporateMemberId}
        </Button>
      ),
    },
    {
      title: translate('corporateMemberCreditsHistories.phonNumber'),
      dataIndex: ['corporateMember', 'phoneNumber'],
      key: 'phoneNumber',
      fixed: 'left',
      width: 100,
    },
    {
      title: translate('isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      width: 80,
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (createdAt: string) => (createdAt ? getDateTime(createdAt) : ''),
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text: string, record: ICorporateCreditsDistributionScheduleMember) => (
        <Space>
          <AuthPermission
            permission={PermissionsEnum.CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_WRITE}
          >
            {record.isActive ? (
              <Button
                danger
                type="text"
                icon={<StopOutlined />}
                onClick={() => onUpdateMemberStatus(record)}
              >
                <FormattedMessage id="corporate.member.deactivate" />
              </Button>
            ) : (
              <Button
                type="text"
                style={{ color: 'green' }}
                icon={<CheckCircleOutlined />}
                onClick={() => onUpdateMemberStatus(record)}
              >
                <FormattedMessage id="corporate.member.activate" />
              </Button>
            )}
          </AuthPermission>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Title level={5}>{translate('navbar.corporateCreditsDistributionSchedule.members')}</Title>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={members}
        scroll={{ x: 1140 }}
        pagination={false}
      />

      {creditsHistoriesModalCorporateMember && (
        <CorporateMemberCreditsHistories
          open={openCreditsHistoriesModal}
          corporateMember={creditsHistoriesModalCorporateMember}
          corporateId={corporateId}
          onClose={() => setOpenCreditsHistoriesModal(false)}
        />
      )}
    </Space>
  )
}

export default CorporateCreditsDistributionScheduleMemberTable
