import { Button, Form, Space, Modal, Steps } from 'antd'
import { useEffect, useState } from 'react'
import styles from './short-url-form-modal.module.less'
import translate from '../../../i18n'
import { IShortURL } from '../../../pages/short-urls/share-type'
import ShortURLForm from '../short-url-form/short-url-form'
import WeChatURLLinkForm from '../../wechat-url-links/wechat-url-link-form/wechat-url-link-form'
import { IWeChatURLLink } from '../../../pages/wechat-url-links-container/share-type'
import FormattedMessage from '../../intl/format-message'

interface IShortURLFormModallProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
  shortURL: IShortURL | undefined
}

const ShortURLFormModal = (props: IShortURLFormModallProps) => {
  const { handleCancel, handleSubmit, open, shortURL } = props
  const [form] = Form.useForm()
  const [wechatURLLink, setWechatURLLink] = useState<IWeChatURLLink | undefined>(undefined)
  const [current, setCurrent] = useState<number>(0)
  const [shortURLType, setShortURLType] = useState<string>('')

  useEffect(() => {
    if (shortURL) {
      form.setFieldsValue(shortURL)
    } else {
      form.resetFields()
    }
  }, [shortURL])

  useEffect(() => {
    setCurrent(0)
    setShortURLType('')
  }, [open])

  const handleSubmitWeChatURLForm = (res: IWeChatURLLink) => {
    setWechatURLLink(res)
    setCurrent(1)
  }

  const items = [
    {
      title: 'WeChat URL Link',
    },
    {
      title: 'Short URL',
    },
  ]

  const onChangeSteps = (current: number) => {
    setCurrent(current)
  }

  return (
    <>
      <Modal
        title={
          shortURL ? translate('shortURLs.updateShortURLs') : translate('shortURLs.newShortURLs')
        }
        open={open}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        width={800}
      >
        {!shortURL && shortURLType === '' && (
          <Space size={16} className={styles['button-group']}>
            <Button
              className={styles['wechat-url-button']}
              type="primary"
              onClick={() => setShortURLType('wechat')}
            >
              <FormattedMessage id="shortURLs.createFromWeChatURLLInk" />
            </Button>
            <Button
              type="primary"
              className={styles['web-url-button']}
              onClick={() => setShortURLType('weblink')}
            >
              <FormattedMessage id="shortURLs.createFromWeblink" />
            </Button>
          </Space>
        )}

        {shortURLType === 'wechat' && (
          <>
            <Steps
              current={current}
              labelPlacement="vertical"
              items={items}
              onChange={onChangeSteps}
            />
            {current === 0 && (
              <WeChatURLLinkForm
                handleCancel={handleCancel}
                handleSubmit={handleSubmitWeChatURLForm}
              />
            )}
            {current === 1 && (
              <ShortURLForm
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                shortURL={shortURL}
                wechatURLLink={wechatURLLink}
              />
            )}
          </>
        )}
        {(shortURL || shortURLType === 'weblink') && (
          <ShortURLForm
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            shortURL={shortURL}
          />
        )}
      </Modal>
    </>
  )
}

export default ShortURLFormModal
