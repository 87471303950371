import { SET_ACTIVE_CITIES } from './constants'

export interface SetActiveCitiesAction {
  type: SET_ACTIVE_CITIES
  payload: any
}

export const setActiveCitiesAction = (payload: any): SetActiveCitiesAction => {
  return {
    type: SET_ACTIVE_CITIES,
    payload,
  }
}

export type CitiesAction = SetActiveCitiesAction
