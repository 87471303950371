import { Card, Space } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { getCities } from '../../api/authApi'
import { getPaginatedCardStack } from '../../api/cardStackApi'
import NavBar from '../../components/nav-bar/nav-bar'
import translate from '../../i18n'
import { setAllCardStacksAction } from '../../store/card-stack/action'
import { setActiveCitiesAction } from '../../store/city/action'

const CardConfigsContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/card-configs\/[0-9]+$/)) {
      return translate('navBar.viewCardConfigTitle')
    }
    if (pathName.match(/^\/card-configs\/new$/)) {
      return translate('navBar.newCardConfig')
    }
    if (pathName.match(/^\/card-configs\/[0-9]+\/edit$/)) {
      return translate('navBar.editCardConfig')
    }
    return translate('navBar.cardConfigsTitle')
  }

  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })

    getPaginatedCardStack({ page: 0, size: 999 }).then(res => {
      const { setAllCardStacksAction } = props
      if (res && res.data) {
        setAllCardStacksAction(res.data)
      }
    })
  }, [])

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
  setAllCardStacksAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(CardConfigsContainer)
