import { notification } from 'antd'

import translate from '../i18n'

interface ApiError {
  message?: string
  code?: string
  status?: number
}

interface ErrorHandlerConfig {
  defaultMessage?: string
  showNotification?: boolean
}

export const handleApiError = (
  error: ApiError,
  customMessage?: string,
  config: ErrorHandlerConfig = { showNotification: true },
) => {
  const errorMessage = customMessage || translate('apiError')
  const errorDescription = error?.message || translate('networkError')

  if (config.showNotification) {
    notification.error({
      message: errorMessage,
      description: errorDescription,
    })
  }

  return {
    message: errorMessage,
    description: errorDescription,
    originalError: error,
  }
}
