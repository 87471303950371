import { Descriptions, Tag } from 'antd'
import {
  ISearchTrend,
  SearchTrendStatusEnums,
} from '../../../pages/search-trends-container/share-type'
import translate from '../../../i18n'
import { SearchTrendableTypeOptions } from '../../../pages/search-trends-container/constant'

const SearchTrendDescriptions = ({ searchTrend }: { searchTrend: ISearchTrend }) => {
  const renderStatusTag = (status: SearchTrendStatusEnums) => {
    switch (status) {
      case SearchTrendStatusEnums.ACTIVE:
        return <Tag color="green">{status}</Tag>
      case SearchTrendStatusEnums.EXPIRED:
        return <Tag color="red">{status}</Tag>
      default:
        return <Tag color="blue">{status}</Tag>
    }
  }
  return (
    <Descriptions
      style={{ width: 1200 }}
      title={translate('navBar.viewSearchTrends')}
      labelStyle={{ alignItems: 'center', fontWeight: 600 }}
      column={2}
      bordered
    >
      <Descriptions.Item label="ID">{searchTrend.id}</Descriptions.Item>
      <Descriptions.Item label={translate('searchTrends.generatedDate')}>
        {searchTrend.generatedDate}
      </Descriptions.Item>
      <Descriptions.Item label={translate('searchTrends.trendableType')}>
        <Tag color="blue">
          {SearchTrendableTypeOptions.find(item => item.value === searchTrend.trendableType)?.label}
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item label={translate('searchTrends.city')}>
        {searchTrend.city.name}
      </Descriptions.Item>
      <Descriptions.Item label={translate('searchTrends.status')}>
        {renderStatusTag(searchTrend.status)}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default SearchTrendDescriptions
