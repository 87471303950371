import config from '../config/index'
import {
  IPaginatedReferralCampaignsFilterParams,
  IReferralCampaign,
  PaginatedReferralCampaignsResponse,
  ReferralCampaignRequest,
} from '../pages/referral-campaign-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedReferralCampaigns = (data: IPaginatedReferralCampaignsFilterParams) =>
  authRequest.get<MISSING_TYPE, PaginatedReferralCampaignsResponse>(
    `${mainApi}/referral-campaigns`,
    {
      params: data,
    },
  )

export const getReferralCampaignDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IReferralCampaign>(`${mainApi}/referral-campaigns/${id}`)

export const createReferralCampaign = (data: ReferralCampaignRequest) =>
  authRequest.post<MISSING_TYPE, IReferralCampaign>(`${mainApi}/referral-campaigns`, data)

export const editReferralCampaign = (id: number, data: ReferralCampaignRequest) =>
  authRequest.put<MISSING_TYPE, IReferralCampaign>(`${mainApi}/referral-campaigns/${id}`, data)
