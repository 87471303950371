import {
  SET_COUPON_TEMPLATE_DRAFT,
  CLEAR_COUPON_TEMPLATE_DRAFT,
  SET_ALL_COUPON_TEMPLATES,
} from './constants'
import { CouponTemplateAction } from './action'
import update from 'immutability-helper'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { CouponTemplate } from '../../pages/coupon-templates-container/share-type'
interface InitialState {
  couponTemplates: CouponTemplate[]
  couponTemplateDraft: any
}

const initialState: InitialState = {
  couponTemplates: [],
  couponTemplateDraft: null,
}

const persistConfig = {
  key: 'couponTemplateDraft',
  storage,
}

function couponTemplateReducer(state = initialState, action: CouponTemplateAction) {
  switch (action.type) {
    case SET_COUPON_TEMPLATE_DRAFT:
      return update(state, { $merge: { couponTemplateDraft: action.payload } })
    case CLEAR_COUPON_TEMPLATE_DRAFT:
      return Object.assign({}, initialState)
    case SET_ALL_COUPON_TEMPLATES:
      return update(state, { $merge: { couponTemplates: action.payload } })
    default:
      return state
  }
}

export default persistReducer(persistConfig, couponTemplateReducer)
