import { useEffect } from 'react'
import { Form, Button, Select, InputNumber } from 'antd'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { FormattedMessage } from 'react-intl'
import {
  AdjustableTypeEnum,
  AdjustmentTypeEnum,
} from '../../../pages/lesson-credit-adjustments-container/share-type'

const { Option } = Select
const LessonCreditAdjustmentFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: any) => void
  formData: any
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  const onFinish = (values: any) => {
    onSearch(values)
  }

  return (
    <Form layout="inline" form={form} onFinish={onFinish}>
      <Form.Item name="isActive" label={translate('isActive')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>
            <FormattedMessage id="yes"></FormattedMessage>
          </Option>
          <Option value={false}>
            <FormattedMessage id="no"></FormattedMessage>
          </Option>
        </Select>
      </Form.Item>

      <Form.Item name="adjustmentType" label={translate('lessonCreditAdjustments.AdjustmentType')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {Object.values(AdjustmentTypeEnum).map((value, index) => (
            <Option value={value} key={index}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="adjustableType" label={translate('lessonCreditAdjustments.AdjustableType')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {Object.values(AdjustableTypeEnum).map((value, index) => (
            <Option value={value} key={index}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="adjustableId" label={translate('lessonCreditAdjustments.AdjustableId')}>
        <InputNumber />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LessonCreditAdjustmentFilter
