import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { getCities, getPlans } from '../../api/authApi'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { setActiveCitiesAction } from '../../store/city/action'
import { setPlansAction } from '../../store/global/action'
import translate from '../../i18n'
import { getPaginatedSegments } from '../../api/segmentApi'
import { setFirstPageSegmentsAction } from '../../store/segment/action'
import { getTagsByContext } from '../../api/tagsApi'
import { setAllCategoriesAction } from '../../store/category/action'
import { TaggingContextEnums } from '../tags-container/constant'

const UserSegmentContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })

    getPlans({ page: 0, size: 999, type: 3 }).then(res => {
      const { setPlansAction } = props
      const miniProgramSelectablePlan = res.data.filter((item: any) => item.miniProgramSelectable)
      setPlansAction(miniProgramSelectablePlan)
    })
    getPaginatedSegments({ size: 10, page: 0 }).then(res => {
      const { setFirstPageSegmentsAction } = props
      setFirstPageSegmentsAction(res.data)
    })

    getTagsByContext(TaggingContextEnums.ACTIVITIES).then(res => {
      const { setAllCategoriesAction } = props
      setAllCategoriesAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/segment-rules\/[0-9]+\/edit$/)) {
      return translate('navBar.editSegmentRule')
    }

    if (pathName.match(/^\/segment-rules\/new/)) {
      return translate('navBar.newSegmentRule')
    }

    if (pathName.match(/^\/segment-rules\/[0-9]+$/)) {
      return translate('navBar.viewSegmentRule')
    }

    return translate('navBar.segmentRulesTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
  setPlansAction,
  setFirstPageSegmentsAction,
  setAllCategoriesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(UserSegmentContainer)
