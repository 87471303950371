export enum BillingDetailType {
  NO_SHOW = 'NO_SHOW',
  EARLY_VISIT = 'EARLY_VISIT',
  LATE_CANCELLED = 'LATE_CANCELLED',
  CANCELLED = 'CANCELLED',
  CHECKED_IN = 'CHECKED_IN',
  COMPLETED = 'COMPLETED',
}

export const BillingDetailTypeOptions = [
  {
    label: '没到',
    value: BillingDetailType.NO_SHOW,
  },
  {
    label: '初访',
    value: BillingDetailType.EARLY_VISIT,
  },
  {
    label: '延迟取消(12小时内)',
    value: BillingDetailType.LATE_CANCELLED,
  },
  {
    label: '取消',
    value: BillingDetailType.CANCELLED,
  },
  {
    label: '已签到',
    value: BillingDetailType.CHECKED_IN,
  },
  {
    label: '已完成',
    value: BillingDetailType.COMPLETED,
  },
]
