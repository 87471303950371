import React, { useEffect, useState } from 'react'
import { Table, Typography } from 'antd'
import translate from '../../../i18n'
import YesOrNoTag from '../../common/YesOrNoTag'
import LinkButton from '../../link-button/link-button'
import {
  ILessonDescription,
  IPaginatedLessonDescription,
  IPaginatedLessonDescriptionFilterParams,
  LessonTranslation,
} from '../../../pages/lesson-description-container/share-type'
import { ColumnsType } from 'antd/lib/table'
import { getLessonDescriptions } from '../../../api/lessonDescriptionsApi'
import { PageInfo } from '../../../share-types'
const { Text } = Typography

interface LessonDescriptionTableProps {
  lessonDescriptionIds: number[]
  organizationId: number | undefined
  handleSelectLessonDescriptions: (lessonDescriptionIds: number[]) => void
}

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const LessonDescriptionTable: React.FC<LessonDescriptionTableProps> = ({
  lessonDescriptionIds,
  organizationId,
  handleSelectLessonDescriptions,
}) => {
  const [lessonDescriptions, setLessonDescriptions] = useState<ILessonDescription[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  useEffect(() => {
    if (organizationId) {
      getPaginatedLessonDescriptionssAction({
        organizationId: organizationId,
        size: defaultPage.pageSize,
        page: 0,
      })
    }
  }, [organizationId])

  useEffect(() => {
    setSelectedRowKeys(lessonDescriptionIds)
  }, [lessonDescriptionIds])

  const getPaginatedLessonDescriptionssAction = (data: IPaginatedLessonDescriptionFilterParams) => {
    getLessonDescriptions(data).then((res: IPaginatedLessonDescription) => {
      if (res) {
        setLessonDescriptions(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      organizationId: organizationId,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedLessonDescriptionssAction(data)
  }

  const columns: ColumnsType<ILessonDescription> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('lessonDescription.name'),
      dataIndex: 'lessonDescriptionTranslations',
      key: 'name',
      render: (lessonDescriptionTranslations: LessonTranslation[]) => {
        return (
          <Text>
            {lessonDescriptionTranslations.length ? lessonDescriptionTranslations[0]?.name : ''}
          </Text>
        )
      },
    },
    {
      title: translate('lessonDescription.organizationId'),
      dataIndex: 'organizationId',
      key: 'organizationId',
      render: (organizationId: number) => (
        <LinkButton
          to={`/organizations/${organizationId}`}
          style={{ padding: 0, textAlign: 'left' }}
        >
          {organizationId}
        </LinkButton>
      ),
    },
    {
      title: translate('lessonDescription.duration'),
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: translate('lessonDescription.externalProviderId'),
      dataIndex: 'externalProviderId',
      key: 'externalProviderId',
    },
    {
      title: translate('lessonDescription.isRedirected'),
      dataIndex: 'isRedirected',
      key: 'isRedirected',
      render: (isRedirected: boolean) => {
        return <YesOrNoTag value={isRedirected} />
      },
    },
    {
      title: translate('lessonDescription.isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => {
        return <YesOrNoTag value={isActive} />
      },
    },
    {
      title: translate('lessonDescription.isAllDay'),
      dataIndex: 'isAllDay',
      key: 'isAllDay',
      render: (isAllDay: boolean) => {
        return <YesOrNoTag value={isAllDay} />
      },
    },
    {
      title: translate('lessonDescription.credits'),
      dataIndex: 'credits',
      key: 'credits',
    },
  ]

  const onChangeSelectedRowKeys = (selectedRowKeys: React.Key[]) => {
    const deduplicatedKeys = Array.from(new Set(selectedRowKeys))
    setSelectedRowKeys(deduplicatedKeys)
    handleSelectLessonDescriptions(deduplicatedKeys.map(key => Number(key)))
  }

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record: ILessonDescription, selected: boolean) => {
      if (selected) {
        onChangeSelectedRowKeys([...selectedRowKeys, record.id])
      } else {
        onChangeSelectedRowKeys(selectedRowKeys.filter(key => key != record.id))
      }
    },
    onSelectAll: (
      selected: boolean,
      selectedRows: ILessonDescription[],
      changeRows: ILessonDescription[],
    ) => {
      const selectedRowIds: React.Key[] = changeRows
        .filter(selectedRow => !!selectedRow)
        .map(selectedRow => selectedRow?.id)

      if (selected) {
        onChangeSelectedRowKeys([...selectedRowKeys, ...selectedRowIds])
      } else {
        onChangeSelectedRowKeys(selectedRowKeys.filter(key => !selectedRowIds.includes(key)))
      }
    },
  }

  return (
    <Table
      rowKey={record => record.id}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={lessonDescriptions}
      pagination={{
        current: pageInfo.currentPage,
        pageSize: pageInfo.pageSize,
        total: pageInfo.count,
        onChange: paginationOnChange,
      }}
    />
  )
}

export default LessonDescriptionTable
