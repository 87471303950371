import { Button, Form, Input, Modal, Space } from 'antd'
import { FormattedMessage } from '../../intl'
import styles from './reject-reason-modal.module.less'

interface IProps {
  show: boolean
  loadingButton: boolean
  handleCancel: () => void
  handleSubmit: (values: { rejectReason: string }) => void
}

const RejectReasonModal = (props: IProps) => {
  const { show, handleCancel, loadingButton, handleSubmit } = props

  const onFinish = (values: { rejectReason: string }) => {
    handleSubmit(values)
  }

  return (
    <Modal title="拒绝原因" open={show} footer={null} onCancel={handleCancel}>
      <Form onFinish={onFinish}>
        <Form.Item label="拒绝原因" name="rejectReason">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Space className={styles['button-group']}>
            <Button loading={loadingButton} onClick={handleCancel}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button loading={loadingButton} type="primary" htmlType="submit">
              <FormattedMessage id="submit" />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RejectReasonModal
