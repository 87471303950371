import { Descriptions, List, Space, Tag } from 'antd'
import {
  IPublicPrelaunchStudioDto,
  PrelaunchConfirmationStatusEnum,
} from '../../../pages/prelaunch-organization-forms-container/share-types'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'

interface IProps {
  data: IPublicPrelaunchStudioDto[]
  isExpired: boolean
  uniqueId: string
  organizationName: string
}

const PrelaunchStudios = (props: IProps) => {
  const { data, uniqueId, isExpired, organizationName } = props

  const getStudioStatusTagColor = (status: string) => {
    switch (status) {
      case PrelaunchConfirmationStatusEnum.SUBMITTED:
        return 'green'
      case PrelaunchConfirmationStatusEnum.CONFIRMED:
        return 'blue'
      default:
        return 'blue'
    }
  }

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <Descriptions column={{ xs: 1, sm: 2 }}>
              <Descriptions.Item label={translate('studioTranslation.name')}>
                <Space>
                  {item.name}
                  {!isExpired && (
                    <a
                      href={`/public/prelaunch-organization-form/${uniqueId}/studios/${item.id}/${organizationName}/duplicate`}
                    >
                      {translate('duplicate')}
                    </a>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.status')}>
                <Tag color={getStudioStatusTagColor(item.status)}>{item.status}</Tag>
              </Descriptions.Item>
              <Descriptions.Item label={translate('city')}>{item.city}</Descriptions.Item>
              <Descriptions.Item label={translate('studioTranslation.address1')}>
                {item.address1}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studioTranslation.address2')}>
                {item.address2}
              </Descriptions.Item>
              <Descriptions.Item label={translate('createdAt')}>
                {getDateTime(item.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label={translate('updatedAt')}>
                {getDateTime(item.updatedAt)}
              </Descriptions.Item>
            </Descriptions>
          </List.Item>
        )}
      />
    </>
  )
}

export default PrelaunchStudios
