import React, { Component } from 'react'
import styles from './container.module.less'
import { Button, Layout, Space } from 'antd'
import { Link, Outlet } from 'react-router-dom'
import { FormattedMessage } from '../../components/intl'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch, ReduxStore } from '../../store'
import { clearUserInfoAction } from '../../store/userInfo/action'
import { clearAuthAction } from '../../store/userAuth/action'
import Menus from '../../components/menus/menus'
import { MenuModeEnum } from '../../share-types'

const { Header, Content } = Layout

interface IState {
  current: string
}

type IProps = ConnectedProps<typeof withConnect>

class Container extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      current: '',
    }
  }

  clickMenu = (e: any) => {
    this.setState({
      current: e.key,
    })
  }

  goBackHomePage = () => {
    this.setState({
      current: '',
    })
  }

  logout = () => {
    this.props.clearAuth()
    this.props.clearUserInfo()
  }

  render(): React.ReactNode {
    const { userInfo } = this.props
    return (
      <Layout className={styles['layout']}>
        <Header className={styles['header']}>
          <div className={styles['left-menu-container']}>
            <Link to={'/'} className={styles['logo']} onClick={this.goBackHomePage}>
              ClassPass
            </Link>
            <Menus mode={MenuModeEnum.MENU} />
          </div>

          <Space className={styles['right-menu']}>
            <div>{userInfo?.email}</div>
            <Button type="link" onClick={this.logout}>
              <FormattedMessage id="logout" />
            </Button>
          </Space>
        </Header>
        <Content className={styles['content']}>
          <Outlet />
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    userInfo: state.userInfo,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearAuth() {
      dispatch(clearAuthAction())
    },
    clearUserInfo() {
      dispatch(clearUserInfoAction())
    },
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Container)
