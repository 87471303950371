import { ISegment } from '../../pages/segments-container/share-type'
import { SET_FIRST_PAGE_SEGEMENTS } from './constants'

export interface SetFirstPageSegmentsAction {
  type: SET_FIRST_PAGE_SEGEMENTS
  payload: ISegment[]
}

export const setFirstPageSegmentsAction = (payload: ISegment[]): SetFirstPageSegmentsAction => {
  return {
    type: SET_FIRST_PAGE_SEGEMENTS,
    payload,
  }
}

export type SegmentsAction = SetFirstPageSegmentsAction
