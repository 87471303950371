import { Button, Modal, Form, Select, Input, Space } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { BookingReviewAuditStatusOptions } from '../../../pages/booking-reviews-container/constant'
import {
  AuditStatusEnum,
  IBookingReviewAuditParams,
  IRejectReason,
} from '../../../pages/booking-reviews-container/share-type'
import { ReduxStore } from '../../../store'

type PropsFromRedux = ConnectedProps<typeof withConnect>

type IRevokeCouponTemplateModalProps = PropsFromRedux & {
  visible: boolean
  loading: boolean
  rejectReasons: IRejectReason[]
  handleCancel: () => void
  handleOk: (value: IBookingReviewAuditParams) => void
  auditStatus?: AuditStatusEnum.APPROVED | AuditStatusEnum.REJECTED
}

const { Option } = Select
const { TextArea } = Input

const AuditBookingReviewModal = (props: IRevokeCouponTemplateModalProps) => {
  const { visible, handleOk, handleCancel, loading, rejectReasons, locale, auditStatus } = props
  const [form] = Form.useForm<IBookingReviewAuditParams>()
  const auditStatusValue = Form.useWatch('status', form)

  const onFinish = (value: IBookingReviewAuditParams) => {
    handleOk(value)
  }
  useEffect(() => {
    if (auditStatus) {
      form.setFieldsValue({
        status: auditStatus,
      })
    }
  }, [auditStatus])
  return (
    <Modal
      centered
      open={visible}
      onCancel={handleCancel}
      title="审核评论"
      footer={null}
      width={450}
      bodyStyle={{ padding: '24px 80px' }}
    >
      <Form
        name="audit_booking_review"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          code: '',
          isAuto: false,
          showInPopup: false,
          showCountDown: false,
        }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item name="status" label="审核状态">
          <Select style={{ minWidth: 130 }}>
            {BookingReviewAuditStatusOptions.filter(
              option => option.value !== AuditStatusEnum.PENDING,
            ).map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {auditStatusValue === AuditStatusEnum.REJECTED && (
          <Form.Item
            label="拒绝原因"
            name="rejectReason"
            rules={[{ required: true, message: '请输入拒绝原因' }]}
          >
            {rejectReasons && rejectReasons.length ? (
              <Select>
                {rejectReasons.map(_ => {
                  const desc = _.translations.find(t => t.locale === locale)?.name
                  return (
                    <Option key={_.id} value={desc}>
                      {desc}
                    </Option>
                  )
                })}
              </Select>
            ) : (
              <TextArea />
            )}
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              确定
            </Button>
            <Button onClick={handleCancel}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}
const mapStateToProps = (state: ReduxStore) => {
  return {
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(AuditBookingReviewModal)
