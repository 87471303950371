import { SET_USER_AUTH_INFO, CLEAR_USER_AUTH_INFO } from './constants'

export interface SetAuthAction {
  type: SET_USER_AUTH_INFO
  payload: any
}

export const setAuthAction = (payload: any): SetAuthAction => {
  return {
    type: SET_USER_AUTH_INFO,
    payload,
  }
}

export interface ClearAuthAction {
  type: CLEAR_USER_AUTH_INFO
}

export const clearAuthAction = (): ClearAuthAction => {
  return {
    type: CLEAR_USER_AUTH_INFO,
  }
}

export type AuthAction = SetAuthAction | ClearAuthAction
