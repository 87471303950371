import { IAdminUser } from '../../share-types'
import { SET_USER_INFO, CLEAR_USER_INFO } from './constants'

export interface SetUserInfoAction {
  type: SET_USER_INFO
  payload: IAdminUser
}

export const setUserInfoAction = (payload: IAdminUser): SetUserInfoAction => {
  return {
    type: SET_USER_INFO,
    payload,
  }
}

export interface ClearUserInfoAction {
  type: CLEAR_USER_INFO
}

export const clearUserInfoAction = (): ClearUserInfoAction => {
  return {
    type: CLEAR_USER_INFO,
  }
}

export type UserInfoAction = SetUserInfoAction | ClearUserInfoAction
