import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

import Icon from '@ant-design/icons'

const BillingSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 21H19"
      stroke="white"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.27943C3 6.25995 3 4.75022 3.77346 3.69464C4.01514 3.3648 4.30597 3.07398 4.6358 2.8323C5.69138 2.05884 7.20111 2.05884 10.2206 2.05884H13.7794C16.7989 2.05884 18.3086 2.05884 19.3642 2.8323C19.694 3.07398 19.9849 3.3648 20.2265 3.69464C21 4.75022 21 6.25995 21 9.27943V9.27943C21 12.2989 21 13.8086 20.2265 14.8642C19.9849 15.194 19.694 15.4849 19.3642 15.7266C18.3086 16.5 16.7989 16.5 13.7794 16.5H10.2206C7.20111 16.5 5.69138 16.5 4.6358 15.7266C4.30597 15.4849 4.01514 15.194 3.77346 14.8642C3 13.8086 3 12.2989 3 9.27943V9.27943Z"
      stroke="white"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.5L9.39339 9.06156C9.70694 8.51285 10.4445 8.38896 10.9201 8.80512L13.0259 10.6477C13.5187 11.0789 14.286 10.928 14.5788 10.3423L16.5 6.5"
      stroke="white"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const BillingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BillingSvg} {...props} />
)

export default BillingIcon
