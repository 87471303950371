import Container from '../studios-area/pages/container/container'
import BillingSummaries from '../studios-area/pages/billing-summaries/billing-summaries'
import BillingInvoices from '../studios-area/pages/billing-invoices/billing-invoices'
import Login from '../pages/login/login'
import { Navigate } from 'react-router-dom'

const StudiosAreaRoutes = [
  {
    path: '/studios-area/login',
    element: <Login />,
  },
  {
    path: '/studios-area',
    element: <Container />,
    backUrl: '/studios-area/login',
    auth: true,

    children: [
      {
        index: true,
        backUrl: '/studios-area/login',
        auth: true,
        element: <Navigate to="/studios-area/billing-summaries" replace />,
      },
      {
        index: true,
        path: 'billing-summaries',
        backUrl: '/studios-area/login',
        auth: true,
        element: <BillingSummaries />,
      },
      {
        path: 'billing-invoices',
        backUrl: '/studios-area/login',
        auth: true,
        element: <BillingInvoices />,
      },
    ],
  },
]

export default StudiosAreaRoutes
