import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styles from './one-time-code-batch.module.less'
import { IOneTimeCodeBatch, IOneTimeCodeBatchesFilterParams } from '../share-type'

import * as _ from 'lodash'
import { PageInfo } from '../../../share-types'
import { getSearchValues } from '../../../helpers/utils'
import { getOneTimeCodeBatchDetail, getPaginatedOneTimeCodes } from '../../../api/oneTimeCodeApi'
import OneTimeCodeBatchDescriptions from '../../../components/one-time-code-batches/one-time-code-batch-descriptions/one-time-code-batch-descriptions'
import OneTimeCodesTable from '../../../components/one-time-codes/one-time-codes-table/one-time-codes-table'
import {
  IOneTimeCode,
  IOneTimeCodesFilterParams,
  IPaginatedOneTimeCodesParams,
} from '../../one-time-codes-container/share-type'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const OneTimeCodeBatch = () => {
  const params = useParams()
  const [batchId] = useState<number | undefined>(Number(params?.id))
  const [oneTimeCodeBatch, setOneTimeCodeBatch] = useState<IOneTimeCodeBatch>()

  const [oneTimeCodes, setOneTimeCodes] = useState<IOneTimeCode[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IOneTimeCodesFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    getOneTimeCodeBatchAction()
  }, [batchId])

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'code', isBoolean: false },
        { key: 'redeemedById', isBoolean: false },
        { key: 'isRedeemed', isBoolean: true },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedOneTimeCodesAction(data)
  }, [])

  const getPaginatedOneTimeCodesAction = (data: IPaginatedOneTimeCodesParams) => {
    if (batchId) {
      data.batchId = batchId
      getPaginatedOneTimeCodes(data).then(res => {
        if (res && res.pageInfo && res.data) {
          setPageInfo({
            ...res.pageInfo,
            currentPage: res.pageInfo.currentPage + 1,
          })
          setOneTimeCodes(res.data)
        }
      })
    }
  }

  const getOneTimeCodeBatchAction = () => {
    if (!batchId) {
      return
    }
    getOneTimeCodeBatchDetail(batchId).then(res => {
      setOneTimeCodeBatch(res)
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedOneTimeCodesAction(data)
  }

  const onSearch = (value: IOneTimeCodeBatchesFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedOneTimeCodesAction(data)
  }

  const getSearchParams = (data: any) => {
    return _.pickBy(data, item => item !== undefined)
  }

  const onReloadOneTimeCodes = () => {
    const value = {
      size: pageInfo.pageSize,
      page: 0,
      ...searchValues,
    }
    setSearchParams(getSearchParams(value))
    getPaginatedOneTimeCodesAction(value)
  }

  return (
    <>
      <Space
        className={styles['one-time-code-batch-desc-wrapper']}
        direction="vertical"
        wrap
        size={16}
      >
        {oneTimeCodeBatch && <OneTimeCodeBatchDescriptions oneTimeCodeBatch={oneTimeCodeBatch} />}

        {batchId && (
          <OneTimeCodesTable
            oneTimeCodes={oneTimeCodes}
            pageInfo={pageInfo}
            paginationOnChange={paginationOnChange}
            onSearch={onSearch}
            searchValues={searchValues}
            onReloadOneTimeCodes={onReloadOneTimeCodes}
          />
        )}
      </Space>
    </>
  )
}

export default OneTimeCodeBatch
