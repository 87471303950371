import { Button, Col, Row, Space, Table, Tag, Typography } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import {
  getDateTime,
  getSearchParams,
  getSearchValues,
  getWeChatURLLinkIsExpiredTag,
} from '../../../helpers/utils'
import {
  IPaginatedWeChatURLLinksFilterParams,
  IWeChatURLLink,
  IWeChatURLLinksFilterParams,
} from '../share-type'
import { getPaginatedWeChatURLLinks } from '../../../api/weChatURLLinksApi'
import { ApplicationTypeEnums, ApplicationTypeOptions } from '../constant'
import WeChatURLLinksFilter from '../../../components/wechat-url-links/wechat-url-links-filter/wechat-url-links-filter'
import WeChatURLLinkFormModal from '../../../components/wechat-url-links/wechat-url-link-form-modal/wechat-url-link-form-modal'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const { Paragraph } = Typography

const WeChatURLLinks = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [weChatURLLinks, setWeChatURLLinks] = useState<IWeChatURLLink[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IWeChatURLLinksFilterParams>()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
        { key: 'path', isBoolean: false },
        { key: 'applicationType', isBoolean: false },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedWeChatURLLinksAction(data)
  }, [])

  const getPaginatedWeChatURLLinksAction = (data: IPaginatedWeChatURLLinksFilterParams) => {
    getPaginatedWeChatURLLinks(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setWeChatURLLinks(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))

    getPaginatedWeChatURLLinksAction(data)
  }

  const onSearch = (value: IWeChatURLLinksFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(data))
    getPaginatedWeChatURLLinksAction(data)
  }

  const handleSubmit = () => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues({
      path: '',
      applicationType: undefined,
    })
    setSearchParams(getSearchParams(data))
    getPaginatedWeChatURLLinksAction(data)
    setOpen(false)
  }

  const columns: ColumnsType<IWeChatURLLink> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('weChatURLLinks.path'),
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: translate('weChatURLLinks.parameters'),
      dataIndex: 'parameters',
      key: 'parameters',
    },
    {
      title: translate('weChatURLLinks.applicationType'),
      dataIndex: 'applicationType',
      key: 'applicationType',
      render: (applicationType: ApplicationTypeEnums) => {
        return ApplicationTypeOptions.find((item: any) => item.value === applicationType)?.label
      },
    },
    {
      title: translate('weChatURLLinks.urlLink'),
      dataIndex: 'urlLink',
      key: 'urlLink',
      render: (urlLink: string) => {
        return <Paragraph copyable>{urlLink}</Paragraph>
      },
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('isExpired'),
      dataIndex: 'createdAt',
      key: 'expiredAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          const tagLable = getWeChatURLLinkIsExpiredTag(createdAt)
          return <Tag color={tagLable === 'Expired' ? 'red' : 'green'}>{tagLable}</Tag>
        }
        return ''
      },
    },
    {
      title: translate('createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.weChatURLLinksTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.WECHAT_URL_LINKS_WRITE}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)}>
              {translate('navBar.newWeChatURLLinks')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>

      <Row className="filter-bar">
        <WeChatURLLinksFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={weChatURLLinks}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />

      <WeChatURLLinkFormModal
        open={open}
        handleCancel={() => setOpen(false)}
        handleSubmit={handleSubmit}
      />
    </Space>
  )
}

export default WeChatURLLinks
