import { Space, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styles from './corporate.module.less'
import {
  distributeCorporateCredits,
  getCorporateCreditsDistributionSchedules,
  getCorporateDetail,
  getPaginatedCorporateMembersByCorporateId,
} from '../../../api/corporateApi'
import {
  CorporateCreditsRequest,
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleFilterParams,
  ICorporateDetailResponse,
  ICorporateMember,
  ICorporatesFilterParams,
  IPaginatedCorporateCreditsDistributionScheduleFilterParams,
  IPaginatedCorporateMembersParams,
} from '../share-type'
import CorporateDescriptions from '../../../components/corporates/corporate-descriptions/corporate-descriptions'
import CorporateMembers from '../corporate-members/corporate-members'
import * as _ from 'lodash'
import { PageInfo } from '../../../share-types'
import DistributeCreditsModal from '../../../components/corporates/distribute-credits-modal/distribute-credits-modal'
import CorporateCreditsModal from '../../../components/corporates/corporate-credits-modal/corporate-credits-modal'
import BatchDistributeCreditsModal from '../../../components/corporates/batch-distribute-credits-modal/batch-distribute-credits-modal'
import translate from '../../../i18n'
import { getSearchValues } from '../../../helpers/utils'
import CreateCorporateCreditsDistributionScheduleModal from '../../../components/corporates/create-corporate-credits-dirtibution-schedule-modal/create-corporate-credits-dirtibution-schedule-modal'
import CorporateCreditsDistributionScheduleTable from '../../../components/corporates/corporate-credits-distribution-schedule-table/corporate-credits-distribution-schedule-table'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const Corporate = () => {
  const params = useParams()
  const [corporateId] = useState<number | undefined>(Number(params?.id))
  const [corporate, setCorporate] = useState<ICorporateDetailResponse>()

  const [corporateMembers, setCorporateMembers] = useState<ICorporateMember[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [corporateCreditsDistributionSchedules, setCorporateCreditsDistributionSchedules] =
    useState<ICorporateCreditsDistributionSchedule[]>([])
  const [creditsDistributionSchedulePageInfo, setCreditsDistributionSchedulePageInfo] =
    useState<PageInfo>(defaultPage)
  const [creditsDistributionScheduleSearchValues, setCreditsDistributionScheduleSearchValues] =
    useState<ICorporateCreditsDistributionScheduleFilterParams>()

  const [searchValues, setSearchValues] = useState<ICorporatesFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  const [adjustmentCorporateVisible, setAdjustmentCorporateVisible] = useState<boolean>(false)

  const [openBatchDistributeCreditsModal, setOpenBatchDistributeCreditsModal] =
    useState<boolean>(false)

  const [
    openCreateCorporateDistributionScheduleModal,
    setOpenCreateCorporateDistributionScheduleModal,
  ] = useState<boolean>(false)

  const [distributeCreditsModalVisible, setDistributeCreditsModalVisible] = useState<boolean>(false)
  const [distributeCreditsLoading, setDistributeCreditsLoading] = useState<boolean>(false)

  useEffect(() => {
    getCorporateDetailAction()
  }, [corporateId])

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'phoneNumber', isBoolean: false },
        { key: 'isActive', isBoolean: true },
        { key: 'expiredStartDate', isBoolean: false },
        { key: 'expiredEndDate', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedCorporateMembersAction(data)
    getCreditsDistributionSchedulesAction({
      size: creditsDistributionSchedulePageInfo.pageSize,
      page: 0,
    })
  }, [])

  const reloadCorporateDetailAndMembers = () => {
    const data = {
      ...searchValues,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedCorporateMembersAction(data)
    getCorporateDetailAction()
  }

  const reloadCorporateCreditsDistribution = () => {
    const data = {
      ...creditsDistributionScheduleSearchValues,
      size: creditsDistributionSchedulePageInfo.pageSize,
      page: 0,
    }
    getCreditsDistributionSchedulesAction(data)
    getCorporateDetailAction()
  }

  const getPaginatedCorporateMembersAction = (data: IPaginatedCorporateMembersParams) => {
    if (corporateId) {
      getPaginatedCorporateMembersByCorporateId(corporateId, data).then(res => {
        if (res && res.pageInfo && res.data) {
          setPageInfo({
            ...res.pageInfo,
            currentPage: res.pageInfo.currentPage + 1,
          })
          setCorporateMembers(res.data)
        }
      })
    }
  }

  const getCorporateDetailAction = () => {
    if (!corporateId) {
      return
    }
    getCorporateDetail(corporateId).then(res => {
      setCorporate(res)
    })
  }

  const getCreditsDistributionSchedulesAction = (
    data: IPaginatedCorporateCreditsDistributionScheduleFilterParams,
  ) => {
    if (!corporateId) {
      return
    }

    getCorporateCreditsDistributionSchedules(corporateId, data).then(res => {
      if (res && res.pageInfo && res.data) {
        setCreditsDistributionSchedulePageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setCorporateCreditsDistributionSchedules(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedCorporateMembersAction(data)
  }

  const creditsDistributionSchedulePaginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    getCreditsDistributionSchedulesAction(data)
  }

  const onSearchCreditsDistributionSchedule = (value: ICorporatesFilterParams) => {
    const data = {
      size: creditsDistributionSchedulePageInfo.pageSize,
      page: 0,
      ...value,
    }
    setCreditsDistributionScheduleSearchValues(value)
    getCreditsDistributionSchedulesAction(data)
  }

  const onSearch = (value: ICorporatesFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedCorporateMembersAction(data)
  }

  const getSearchParams = (data: any) => {
    return _.pickBy(data, item => item !== undefined)
  }

  const onSubmitAdjustmentCorporate = () => {
    getCorporateDetailAction()
  }

  const onCancelBatchDistributeCredits = () => {
    setOpenBatchDistributeCreditsModal(false)
  }

  const onSubmitBatchDistributeCredits = () => {
    setOpenBatchDistributeCreditsModal(false)
    reloadCorporateDetailAndMembers()
  }

  const onCancelCreateCorporateCreditsDistributionSchedule = () => {
    setOpenCreateCorporateDistributionScheduleModal(false)
  }

  const onSubmitCreateCorporateCreditsDistributionSchedule = () => {
    setOpenCreateCorporateDistributionScheduleModal(false)
    reloadCorporateCreditsDistribution()
  }

  const onCreditsNotesFormFinish = (value: CorporateCreditsRequest) => {
    if (!corporateId) {
      return
    }
    setDistributeCreditsLoading(true)
    distributeCorporateCredits(corporateId, value)
      .then(() => {
        notification.success({
          message: translate('corporate.credits.distributionSuccess'),
        })
        reloadCorporateDetailAndMembers()
      })
      .catch(err => {
        notification.error({
          message: translate('corporate.credits.distributionFailed'),
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        setDistributeCreditsLoading(false)
        setDistributeCreditsModalVisible(false)
      })
  }

  return (
    <>
      <Space className={styles['corprate-desc-wrapper']} direction="vertical" wrap size={16}>
        {corporate && (
          <CorporateDescriptions
            corporate={corporate}
            onAdjustmentCredits={() => setAdjustmentCorporateVisible(true)}
            onDistributeCredits={() => setDistributeCreditsModalVisible(true)}
            onBatchDistributeCredit={() => setOpenBatchDistributeCreditsModal(true)}
            onCreateCorporateDistributionScheduleModal={() =>
              setOpenCreateCorporateDistributionScheduleModal(true)
            }
          />
        )}

        {corporate && (
          <CorporateMembers
            corporateId={corporate.corporate.id}
            reloadPageData={reloadCorporateDetailAndMembers}
            corporateMembers={corporateMembers}
            pageInfo={pageInfo}
            paginationOnChange={paginationOnChange}
            onSearch={onSearch}
            searchValues={searchValues}
            isTrialCorporate={corporate.corporate.isTrial}
          />
        )}

        {corporate && (
          <CorporateCreditsDistributionScheduleTable
            corporateId={corporate.corporate.id}
            pageInfo={creditsDistributionSchedulePageInfo}
            paginationOnChange={creditsDistributionSchedulePaginationOnChange}
            schedules={corporateCreditsDistributionSchedules}
            onSearch={onSearchCreditsDistributionSchedule}
            searchValues={creditsDistributionScheduleSearchValues}
          />
        )}
      </Space>

      <DistributeCreditsModal
        open={distributeCreditsModalVisible}
        loading={distributeCreditsLoading}
        onFinish={onCreditsNotesFormFinish}
        handleCancel={() => setDistributeCreditsModalVisible(false)}
        isTrialCorporate={corporate?.corporate.isTrial || false}
      />

      {corporate && (
        <CorporateCreditsModal
          adjustmentCorporate={corporate.corporate}
          visiable={adjustmentCorporateVisible}
          onCancel={() => setAdjustmentCorporateVisible(false)}
          onSubmit={onSubmitAdjustmentCorporate}
        />
      )}

      {corporate && (
        <BatchDistributeCreditsModal
          corporateId={corporate.corporate.id}
          isTrialCorporate={corporate.corporate.isTrial}
          open={openBatchDistributeCreditsModal}
          handleCancel={onCancelBatchDistributeCredits}
          handleSubmit={onSubmitBatchDistributeCredits}
        />
      )}

      {corporate && (
        <CreateCorporateCreditsDistributionScheduleModal
          corporateId={corporate.corporate.id}
          isTrialCorporate={corporate.corporate.isTrial}
          open={openCreateCorporateDistributionScheduleModal}
          handleCancel={onCancelCreateCorporateCreditsDistributionSchedule}
          handleSubmit={onSubmitCreateCorporateCreditsDistributionSchedule}
        />
      )}
    </>
  )
}

export default Corporate
