import { persistStore } from 'redux-persist'

import bookingReviewsReducer from './booking-review/reducer'
import cardStacksReducer from './card-stack/reducer'
import categoriesReducer from './category/reducer'
import citiesReducer from './city/reducer'
import configureStore from './configureStore'
import couponTemplateReducer from './coupon-template/reducer'
import globalReducer from './global/reducer'
import referralCampaignsReducer from './referral-campaign/reducer'
import segmentsReducer from './segment/reducer'
import spinnerReducer from './spinner/reducer'
import studioAdminUserInfoReducer from './studio-admin-user-info/reducer'
import studioLeaderboardReducer from './studio-leaderboard/reducer'
import userAuthReducer from './userAuth/reducer'
import userInfoReducer from './userInfo/reducer'

const initialState = {}

// Extensions
const defaultReducers = {
  auth: userAuthReducer,
  spinner: spinnerReducer,
  global: globalReducer,
  userInfo: userInfoReducer,
  cities: citiesReducer,
  couponTemplate: couponTemplateReducer,
  bookingReviews: bookingReviewsReducer,
  cardStacks: cardStacksReducer,
  referralCampaigns: referralCampaignsReducer,
  segments: segmentsReducer,
  categories: categoriesReducer,
  studioLeaderboard: studioLeaderboardReducer,
  studioAdminUserInfo: studioAdminUserInfoReducer,
} // Reducer registry

const store = configureStore(initialState, defaultReducers)
const persistor = persistStore(store)

export type Dispatch = typeof store.dispatch
export type ReduxStore = ReturnType<typeof store.getState>

export { store, persistor }
