import { Modal } from 'antd'
import translate from '../../../i18n'

interface IProps {
  open: boolean
  formName: string
  handleCancel: () => void
  handleConfirm: () => void
}

const PrelaunchConfirmModal = (props: IProps) => {
  const { open, formName, handleCancel, handleConfirm } = props

  return (
    <Modal
      title={translate('prelaunchOrganizationForms.confirm')}
      open={open}
      onCancel={handleCancel}
      onOk={handleConfirm}
      destroyOnClose
      width={800}
    >
      {translate('prelaunchOrganizationForms.confirm.message', { formName: formName })}
    </Modal>
  )
}

export default PrelaunchConfirmModal
