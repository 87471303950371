import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'

const ReferralCampaignContainer = () => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/referral-campaigns\/[0-9]+\/edit$/)) {
      return translate('navBar.editReferralCampaign')
    }

    if (pathName.match(/^\/referral-campaigns\/new/)) {
      return translate('navBar.newReferralCampaign')
    }

    if (pathName.match(/^\/referral-campaigns\/[0-9]+$/)) {
      return translate('navBar.viewReferralCampaign')
    }

    return translate('navBar.referralCampaignTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default ReferralCampaignContainer
