import { useEffect, useState } from 'react'
import BillingInvoicesList from '../../../components/billing-invoices/billing-invoices-list/billing-invoices-list'
import BillingInvoicesFilter from '../../../components/billing-invoices/billing-invoices-filter/billing-invoices-filter'
import { getSearchParams, getSearchValues } from '../../../helpers/utils'
import { useSearchParams } from 'react-router-dom'
import { IBillingInvoiceFilterParams, IPaginatedBillingInvoiceFilterParams } from '../share-type'

const BillingInvoices = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValues, setSearchValues] = useState<IPaginatedBillingInvoiceFilterParams>()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'organizationId', isBoolean: false },
        { key: 'status', isBoolean: false },
        { key: 'size', isBoolean: false },
        { key: 'page', isBoolean: false },
      ],
      searchParams,
    ) as IPaginatedBillingInvoiceFilterParams

    setSearchValues(newSearchValues)
  }, [])
  const onSearch = (values: { organizationId: number }) => {
    setSearchValues({
      ...values,
      organizationId: values.organizationId,
    } as IPaginatedBillingInvoiceFilterParams)
  }

  const onSetSearchParams = (params: IBillingInvoiceFilterParams) => {
    setSearchParams(getSearchParams(params))
  }

  return (
    <>
      <BillingInvoicesFilter organizationId={searchValues?.organizationId} onFinish={onSearch} />
      <BillingInvoicesList {...searchValues} onSetSearchParams={onSetSearchParams} />
    </>
  )
}

export default BillingInvoices
