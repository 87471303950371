import { Button, Form, Input, InputNumber, Modal, notification, Switch, Upload } from 'antd'
import styles from './corporate-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createCorporate, editCorporate, getCorporateDetail } from '../../../api/corporateApi'
import { getStudiosSuggestion, getStudiosSuggestionsByIds } from '../../../api/studiosApi'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getOptions } from '../../../helpers/utils'
import { CorporateRequest, ICorporate } from '../share-type'
import { ExclamationCircleFilled, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons'
import Select from 'antd/es/select'
import * as _ from 'lodash'
import translate from '../../../i18n'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'
import { TaggingContextEnums } from '../../tags-container/constant'
import { ITag } from '../../tags-container/share-type'
import { getTagsByContext } from '../../../api/tagsApi'

const { confirm } = Modal

const CorporateForm = () => {
  const [form] = Form.useForm()

  const params = useParams()
  const navigation = useNavigate()
  const [corporateId] = useState<number | undefined>(Number(params?.id))
  const [corporate, setCorporate] = useState<ICorporate>()
  const [defaultStudioSuggestionsOptions, setDefaultStudioSuggestionsOptions] = useState<
    { label: string; value: number }[]
  >([])
  const [activityTags, setActivityTags] = useState<ITag[]>([])

  useEffect(() => {
    if (corporateId) {
      getCorporateDetail(corporateId).then(data => {
        setCorporate(data.corporate)

        if (
          data.corporate.studioIds?.length &&
          data.corporate.studioIds.length <= MAX_QS_STRINGIFY_LENGTH
        ) {
          getStudiosSuggestionsByIds(data.corporate.studioIds).then(studioSuggestions => {
            const sortStudioSuggestions = _.sortBy(studioSuggestions, suggestion =>
              _.findIndex(data.corporate.studioIds, id => id === suggestion.id),
            )
            setDefaultStudioSuggestionsOptions(getOptions(sortStudioSuggestions))
          })
        }

        form.setFieldsValue(data.corporate)
      })
    }
    
    getTagsByContext(TaggingContextEnums.ACTIVITIES).then(res => {
      if (res) {
        setActivityTags(
          Object.values(res).flat()
        )
      }
    })
  }, [corporateId])

  const getTagsOptions = (tags: ITag[]) => {
    return tags.map(tag => {
      const categoryAndSubCategoryLabel = [tag.category, tag.subCategory]
        .filter(item => item)
        .join("/")
      const tagName = tag.tagTranslations.find(t => t.locale === 'zh-CN')?.name
      return {
        label: `${tagName} (${categoryAndSubCategoryLabel})`,
        value: tag.id
      }
    })
  }

  const onFinish = (values: CorporateRequest) => {
    const data = new FormData()
    if (values.file && values.file.length) {
      data.append('file', values.file[0].originFileObj)
    }

    data.append('name', values.name)
    data.append('isActive', String(values.isActive))
    data.append('isTrial', String(values.isTrial))
    data.append(
      'unusedDistributedCreditsThreshold',
      String(values.unusedDistributedCreditsThreshold),
    )

    if (values.creditsValidityPeriodInDays) {
      data.append('creditsValidityPeriodInDays', String(values.creditsValidityPeriodInDays))
    }
    if (values.studioIds) {
      data.append('studioIds', JSON.stringify(values.studioIds))
    }
    if (values.disallowActivityList) {
      data.append('disallowActivityList', JSON.stringify(values.disallowActivityList))
    }
    if (values.maxCreditsUsageThreshold) {
      data.append('maxCreditsUsageThreshold', String(values.maxCreditsUsageThreshold))
    }

    if (corporateId) {
      if (corporate?.isActive && !values.isActive) {
        confirm({
          title: '是否冻结企业?',
          icon: <ExclamationCircleFilled />,
          content: '如果冻结企业，该企业下所有用户也会被冻结',
          onOk() {
            onConfirmUpdateCorporte(data)
          },
        })
      } else {
        onConfirmUpdateCorporte(data)
      }
    } else {
      createCorporate(data)
        .then(res => {
          notification.success({
            message: '创建合作企业成功',
          })
          navigation(`/corporates/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建合作企业失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  const onConfirmUpdateCorporte = (data: FormData) => {
    if (corporateId) {
      editCorporate(corporateId, data)
        .then(res => {
          notification.success({
            message: '编辑合作企业成功',
          })
          navigation(`/corporates/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '编辑合作企业失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  async function fetchStudioList(keyword: string): Promise<{ label: string; value: number }[]> {
    return getStudiosSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  return (
    <Form
      name="corporate"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false, unusedDistributedCreditsThreshold: 0, isTrial: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['corporate-form']}
    >
      <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称！' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="是否激活" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="是否是试用企业" name="isTrial" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="disallowActivityList" label="不允许预约的activities">
        <Select
          mode="multiple"
          allowClear
          placeholder="搜索activities"
          options={getTagsOptions(activityTags)}
        />
      </Form.Item>
      <Form.Item label="单次预约允许使用最大点数" name="maxCreditsUsageThreshold">
        <InputNumber addonAfter="点" />
      </Form.Item>
      <Form.Item
        name="file"
        label={translate('corporate.studioIds.bulkUpload')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="搜索允许预约场馆" name="studioIds">
        <DebounceSelect
          mode="multiple"
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="搜索场馆"
          fetchOptions={fetchStudioList}
          defaultOptions={defaultStudioSuggestionsOptions}
        />
      </Form.Item>

      <Form.Item
        label={translate('corporate.unusedDistributedCreditsThreshold')}
        name="unusedDistributedCreditsThreshold"
        tooltip={{
          title: translate('corporate.unusedDistributedCreditsThreshold.tips'),
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: translate('corporate.unusedDistributedCreditsThreshold.required'),
          },
        ]}
      >
        <InputNumber min={0} addonAfter="点" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CorporateForm
