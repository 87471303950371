import moment from 'moment'
import { PageInfo } from '../../share-types'

export enum AdjustmentTypeEnum {
  PEAK = 'PEAK',
  OFF_PEAK = 'OFF_PEAK',
}

export enum AdjustableTypeEnum {
  STUDIO = 'STUDIO',
  ORGANIZATION = 'ORGANIZATION',
}

export enum LessonCreditAdjustmentRuleTypeEnum {
  PEAK_OFF_PEAK = 'PEAK_OFF_PEAK',
}

export type IBillingItemPricing = BillingItemPricing & {
  id: number
  priceableId: number
  isActive: boolean
  priceableType: string
  completedClassCredits: number
  lateCancelledCredits: number
  noShowCredits: number
  createdAt: string
  updatedAt: string
}

export type BillingItemPricing = {
  completedClassPriceCents: number
  lateCancelledPriceCents: number
  earlyVisitPriceCents: number
  noShowPriceCents: number
}

export type ILessonCreditAdjustment = {
  id: number
  isActive: boolean
  adjustmentType: AdjustmentTypeEnum
  adjustableType: AdjustableTypeEnum
  adjustableId: number
  rule: LessonCreditAdjustmentRule
  billingItemPricing: IBillingItemPricing | null
  createdAt: string
  updatedAt: string
}

export type LessonCreditAdjustmentRule = {
  type: LessonCreditAdjustmentRuleTypeEnum
  adjustCredits: number
  lessonDescriptionIds: number[]
  startDate?: string
  endDate?: string
  lessonStartTimeFrom?: string
  lessonStartTimeTo?: string
  daysOfWeek?: number[]
}

export type ILessonCreditAdjustmentFilterParams = {
  isActive?: boolean
  adjustableType?: AdjustableTypeEnum
  adjustmentType?: AdjustmentTypeEnum
  adjustableId?: number
}

export type IPaginatedLessonCreditAdjustmentFilterParams = {
  page?: number
  size?: number
} & ILessonCreditAdjustmentFilterParams

export type PaginatedLessonCreditAdjustmentResponse = {
  pageInfo: PageInfo
  data: ILessonCreditAdjustment[]
}

export type LessonCreditAdjustmentRequest = {
  isActive: boolean
  adjustmentType: AdjustmentTypeEnum
  adjustableType: AdjustableTypeEnum
  adjustableId: number
  rule: LessonCreditAdjustmentRule
  billingItemPricing: BillingItemPricing | null
}

export type LessonCreditAdjustmentUpdateRequest = {
  isActive?: boolean
  adjustCredits?: number
}

export type LessonCreditAdjustmentCreationFormValues = {
  isActive: boolean
  adjustmentType: AdjustmentTypeEnum
  adjustableType: AdjustableTypeEnum
  adjustableId: number
  lessonStartTimeRange?: moment.Moment[] | undefined
  lessonDateRange?: moment.Moment[] | undefined
  daysOfWeek?: number[]
  lessonDescriptionIds: number[]
  adjustCredits: number
  billingItemPricingEnabled: boolean
  billingItemPricing: BillingItemPricing
}
