import qs from 'qs'

import config from '../config'
import {
  CreateDuplicateLessonDescriptionRequest,
  IDuplicateLessonDescription,
  IPaginatedDuplicateLessonDescriptionFilterParams,
  PaginatedDuplicateLessonDescriptionResponse,
  UpdateDuplicateLessonDescriptionRequest,
} from '../pages/lesson-description-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedDuplicateLessonDescriptions = (
  params: IPaginatedDuplicateLessonDescriptionFilterParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedDuplicateLessonDescriptionResponse>(
    `${mainApi}/duplication-lesson-descriptions`,
    {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const createDuplicateLessonDescription = (data: CreateDuplicateLessonDescriptionRequest) =>
  authRequest.post<MISSING_TYPE, IDuplicateLessonDescription>(
    `${mainApi}/duplication-lesson-descriptions`,
    data,
  )

export const updateDuplicateLessonDescription = (data: UpdateDuplicateLessonDescriptionRequest) =>
  authRequest.put<MISSING_TYPE, IDuplicateLessonDescription[]>(
    `${mainApi}/duplication-lesson-descriptions`,
    data,
  )
