import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { Button, Descriptions, Typography, Space, Image, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import moment from 'moment'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'
import { PermissionsEnum } from '../../../share-types'
import { DATE_TIME_FORMAT } from '../../../helpers/constant'
import styles from './studio-leaderboard-category-descriptions.module.less'
import { IStudioLeaderboardCategory } from '../../../pages/studio-leaderboard-categories-container/share-type'
import YesOrNoTag from '../../common/YesOrNoTag'

interface Iprops extends ConnectedProps<typeof withConnect> {
  studioLeaderboardCategory: IStudioLeaderboardCategory
}

const StudioLeaderboardCategoryDescriptions = (props: Iprops) => {
  const { studioLeaderboardCategory } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/studio-leaderboard-categories/${studioLeaderboardCategory?.id}/edit`)
  }

  return (
    <>
      <Descriptions
        style={{ width: 1200 }}
        title={translate('navBar.studioLeaderboardCategoryDetail')}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: 300 }}
        column={2}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.REFERRAL_CAMPAIGNS_WRITE}>
              <Button onClick={toEdit} type="primary">
                {translate('edit')}
              </Button>
            </AuthPermission>
          </Space>
        }
      >
        <Descriptions.Item label="ID" span={1}>
          {studioLeaderboardCategory?.id}
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboardCategories.isActive')} span={1}>
          <YesOrNoTag value={studioLeaderboardCategory?.isActive} />
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboardCategories.name')} span={2}>
          {studioLeaderboardCategory?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={translate('studioLeaderboardCategories.studioLeaderboardCategoryTags')}
        >
          {studioLeaderboardCategory?.studioLeaderboardCategoryTags.map(item => (
            <Tag key={item.tag.id}>
              {item.tag.id}-{item.tag.name}
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={translate('createdAt')}>
          {moment(studioLeaderboardCategory?.createdAt).format(DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('updatedAt')}>
          {moment(studioLeaderboardCategory?.updatedAt).format(DATE_TIME_FORMAT)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title={
          <Typography.Title level={5} className={styles['section-title']}>
            <FormattedMessage id="studioLeaderboardCategories.iconImage" />
          </Typography.Title>
        }
        style={{ width: 1200, marginTop: '25px' }}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: 300 }}
        column={2}
        bordered
      >
        <Descriptions.Item
          label={translate('studioLeaderboardCategories.iconSmallDefaultFileName')}
        >
          <Image width={21} src={studioLeaderboardCategory?.iconSmallDefaultFileUrl} />
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboardCategories.iconSmallActiveFileName')}>
          <Image width={21} src={studioLeaderboardCategory.iconSmallActiveFileUrl} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate('studioLeaderboardCategories.iconLargeDefaultFileName')}
        >
          <Image width={34} src={studioLeaderboardCategory?.iconLargeDefaultFileUrl} />
        </Descriptions.Item>

        <Descriptions.Item label={translate('studioLeaderboardCategories.iconLargeActiveFileName')}>
          <Image width={34} src={studioLeaderboardCategory?.iconLargeActiveFileUrl} />
        </Descriptions.Item>
      </Descriptions>

      <Typography.Title level={5} style={{ marginTop: '25px' }}>
        <FormattedMessage id="studioLeaderboardCategories.translations" />
      </Typography.Title>

      {studioLeaderboardCategory.translations.map((item, i) => (
        <Descriptions
          key={i}
          style={{ width: 1200, marginTop: '20px' }}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label={translate('locale')}>{item.locale}</Descriptions.Item>
          <Descriptions.Item label={translate('studioLeaderboardCategories.translations.title')}>
            {item.title}
          </Descriptions.Item>
        </Descriptions>
      ))}
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioLeaderboardCategoryDescriptions)
