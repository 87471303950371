import { Button, DatePicker, Form } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DebounceSelect from '../../debounce-select/debounce-select'
import {
  getOrganizationsSuggestion,
  getOrganizationsSuggestionsByIds,
} from '../../../api/organizationsApi'
import { ISimpleOrganization } from '../../../share-types'

interface FormValues {
  expiredAtAfter: Date
}

interface PrelaunchOrganizationFormProps {
  onFinish: (values: FormValues) => void
  organizationId?: number
}

const PrelaunchOrganizationFormsForm = (props: PrelaunchOrganizationFormProps) => {
  const { onFinish, organizationId } = props
  const [form] = Form.useForm()
  const [defaultOrganizationOption, setDefaultOrganizationOption] = useState<
    { label: string; value: number }[]
  >([])

  useEffect(() => {
    if (organizationId) {
      form.setFieldsValue({
        organizationId: organizationId,
      })

      getOrganizationsSuggestionsByIds([organizationId]).then(res => {
        if (res) {
          const options = getOrganizationsSuggestionOptions(res)
          setDefaultOrganizationOption(options)
        }
      })
    }
  }, [organizationId])

  async function fetchOrganizationsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getOrganizationsSuggestion(keyword).then(res => {
      return getOrganizationsSuggestionOptions(res)
    })
  }

  const getOrganizationsSuggestionOptions = (organizationsSuggestions: ISimpleOrganization[]) => {
    return organizationsSuggestions.map(item => {
      const name = item.organizationTranslations[0].name
      return {
        label: `${item.id}-${name}`,
        value: item.id,
      }
    })
  }

  return (
    <Form
      name="organization_form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      initialValues={{
        organizationId: organizationId,
        expiredAtAfter: moment('23:59:59', 'HH:mm:ss'),
      }}
    >
      <Form.Item
        label="Organization"
        name="organizationId"
        rules={[{ required: true, message: '请输入Organization！' }]}
      >
        <DebounceSelect
          allowClear
          showSearch={true}
          placeholder="可以通过id/name搜索organization"
          fetchOptions={fetchOrganizationsSuggestionOptions}
          defaultOptions={defaultOrganizationOption}
          disabled={!!organizationId}
        />
      </Form.Item>

      <Form.Item
        label="过期时间"
        name="expiredAtAfter"
        rules={[{ required: true, message: '请输入过期时间！' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PrelaunchOrganizationFormsForm
