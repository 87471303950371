import { Button, Descriptions, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import { ISegment } from '../../../pages/segments-container/share-type'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'

interface Iprops {
  segment: ISegment
}

const SegmentDescriptions = (props: Iprops) => {
  const { segment } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/segments/${segment?.id}/edit`)
  }

  return (
    <Descriptions
      style={{ width: 1200 }}
      title="用户分组详情"
      labelStyle={{ alignItems: 'center', fontWeight: 600 }}
      column={2}
      bordered
      extra={
        <Space>
          <AuthPermission permission={PermissionsEnum.SEGMENTS_WRITE}>
            <Button onClick={toEdit} type="primary">
              编辑
            </Button>
          </AuthPermission>
        </Space>
      }
    >
      <Descriptions.Item label="ID">{segment.id}</Descriptions.Item>
      <Descriptions.Item label="名称">{segment.name}</Descriptions.Item>
      <Descriptions.Item label="描述">{segment.description}</Descriptions.Item>
      <Descriptions.Item label="活跃">
        <YesOrNoTag value={segment.isActive} />
      </Descriptions.Item>
      <Descriptions.Item label="创建时间">{segment.createdAt}</Descriptions.Item>
      <Descriptions.Item label="更改时间">{segment.updatedAt}</Descriptions.Item>
    </Descriptions>
  )
}

export default SegmentDescriptions
