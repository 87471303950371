import { ReduxStore } from '../../../store'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import { Space, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './studio-leaderboard.module.less'
import { IStudioLeaderboard, StudioLeaderboardStatusEnum } from '../share-type'
import {
  getStudioLeaderboardDetail,
  updateStudioLeaderboardStatus,
} from '../../../api/studioLeaderboardsApi'
import StudioLeaderboardDescriptions from '../../../components/studio-leaderboards/studio-leaderboard-descriptions/studio-leaderboard-descriptions'
import translate from '../../../i18n'

const StudioLeaderboard = (props: ConnectedProps<typeof withConnect>) => {
  const { locale } = props
  const params = useParams()
  const [studioLeaderboardId] = useState<string | undefined>(params?.id)
  const [studioLeaderboard, setStudioLeaderboard] = useState<IStudioLeaderboard>()
  useEffect(() => {
    if (studioLeaderboardId) {
      getStudioLeaderboard(Number(studioLeaderboardId))
    }
  }, [studioLeaderboardId])

  const getStudioLeaderboard = (id: number) => {
    getStudioLeaderboardDetail(id).then(res => {
      setStudioLeaderboard(res)
    })
  }

  const updateStatus = (status: StudioLeaderboardStatusEnum) => {
    updateStudioLeaderboardStatus(Number(studioLeaderboardId), { status })
      .then(() => {
        getStudioLeaderboard(Number(studioLeaderboardId))
        notification.success({
          message: translate('studioLeaderboards.updateStudioLeaderboardStatusSuccess'),
          duration: 3,
        })
      })
      .catch(err => {
        notification.error({
          message: translate('studioLeaderboards.updateStudioLeaderboardStatusError'),
          description: err?.message || '网络请求失败!',
        })
      })
  }

  return (
    <Space
      className={styles['studio-leaderboard-desc-wrapper']}
      direction="vertical"
      wrap
      size={16}
    >
      {studioLeaderboard && (
        <StudioLeaderboardDescriptions
          studioLeaderboard={studioLeaderboard}
          locale={locale}
          updateStatus={updateStatus}
        />
      )}
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioLeaderboard)
