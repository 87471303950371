import { Button, Col, Row, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import CorporatesFilter from '../../../components/corporates/corporates-filter/corporates-filter'
import { ICorporate, ICorporatesFilterParams, IPaginatedCorporatesParams } from '../share-type'
import { getPaginatedCorporates } from '../../../api/corporateApi'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const Corporates = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [corporates, setCorporates] = useState<ICorporate[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ICorporatesFilterParams>()

  useEffect(() => {
    const searchName = searchParams.get('name')
    const searchIsActive = searchParams.get('isActive')
    const searchPhoneNumber = searchParams.get('phoneNumber')
    const searchUserId = searchParams.get('userId')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const newSearchValues = {
      name: searchName ?? undefined,
      isActive: searchIsActive ? (searchIsActive === 'true' ? true : false) : undefined,
      phoneNumber: searchPhoneNumber ?? undefined,
      userId: searchUserId ? Number(searchUserId) : undefined,
    }
    const data = {
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
      ...newSearchValues,
    }
    setSearchValues(newSearchValues)
    getPaginatedCorporatesAction(data)
  }, [])

  const getPaginatedCorporatesAction = (data: IPaginatedCorporatesParams) => {
    getPaginatedCorporates(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setCorporates(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
    }
    getPaginatedCorporatesAction(data)
  }
  const onSearch = (value: ICorporatesFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedCorporatesAction(data)
  }

  const columns: ColumnsType<ICorporate> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '点数',
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: number) => <YesOrNoTag value={isActive} />,
    },
    {
      title: '是否试用企业',
      dataIndex: 'isTrial',
      key: 'isTrial',
      render: (isTrial: number) => (
        <YesOrNoTag value={isTrial} yesColor={'yellow'} noColor={'green'} />
      ),
    },
    {
      title: translate('corporate.unusedDistributedCredits'),
      dataIndex: 'unusedDistributedCredits',
      key: 'unusedDistributedCredits',
    },
    {
      title: translate('corporate.unusedDistributedCreditsThreshold'),
      dataIndex: 'unusedDistributedCreditsThreshold',
      key: 'unusedDistributedCreditsThreshold',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: ICorporate) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.CORPORATES_WRITE}>
            <LinkButton to={`/corporates/${record.id}/edit`}>编辑</LinkButton>
          </AuthPermission>

          <LinkButton to={`/corporates/${record.id}`}>查看</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>合作企业</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.CORPORATES_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/corporates/new')}
            >
              创建新的合作企业
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <CorporatesFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={corporates}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default Corporates
