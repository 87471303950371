import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { ICardConfigsFilterParams } from '../../../pages/card-stacks-container/share-type'
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select
const CardConfigsFilter = ({
  onSearch,
  formData,
  allCardStacks,
}: {
  onSearch: (value: ICardConfigsFilterParams) => void
  formData: ICardConfigsFilterParams | undefined
  allCardStacks: {
    id: number
    name: string
  }[]
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: any) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="name" label="名字">
        <Input />
      </Form.Item>

      <Form.Item name="cardStackId" label="卡片堆">
        <Select style={{ minWidth: 130 }} allowClear>
          {allCardStacks.map(cardStack => {
            return (
              <Option key={cardStack.id} value={cardStack.id}>
                {cardStack?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CardConfigsFilter
