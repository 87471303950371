import { AuditStatusEnum, BookingReviewRatingEnum } from './share-type'

export const BookingReviewRatingOptions = [
  {
    label: '课程专业',
    value: BookingReviewRatingEnum.LESSON_PROFESSIONAL_DEGREE,
  },
  {
    label: '总体打分',
    value: BookingReviewRatingEnum.OVERALL_SERVICE_LEVEL,
  },
  {
    label: '服务态度',
    value: BookingReviewRatingEnum.SERVE_ATTITUDE,
  },
  {
    label: '场馆环境',
    value: BookingReviewRatingEnum.STUDIO_ENVIRONMENT,
  },
]

export const BookingReviewAuditStatusOptions = [
  {
    text: '待审核',
    label: '待审核',
    value: AuditStatusEnum.PENDING,
  },
  {
    text: '通过',
    label: '通过',
    value: AuditStatusEnum.APPROVED,
  },
  {
    text: '拒绝',
    label: '拒绝',
    value: AuditStatusEnum.REJECTED,
  },
]
