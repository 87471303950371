import config from '../config/index'
import {
  ICardStack,
  IPaginatedCardStacksParams,
  PaginatedCardStacksResponse,
} from '../pages/card-stacks-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedCardStack = (data: IPaginatedCardStacksParams) =>
  authRequest.get<MISSING_TYPE, PaginatedCardStacksResponse>(`${mainApi}/card-stacks`, {
    params: data,
  })

export const getCardStackDetail = (cardStackId: number) =>
  authRequest.get<MISSING_TYPE, ICardStack>(`${mainApi}/card-stacks/${cardStackId}`)

export const createCardStack = (data: MISSING_TYPE) =>
  authRequest.post<MISSING_TYPE, ICardStack>(`${mainApi}/card-stacks`, data)

export const editCardStack = (cardStackId: number, data: MISSING_TYPE) =>
  authRequest.put<MISSING_TYPE, ICardStack>(`${mainApi}/card-stacks/${cardStackId}`, data)
