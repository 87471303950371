import { Button, Descriptions, Dropdown, Modal, Space, Tag } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import { ICorporateDetailResponse } from '../../../pages/corporates-container/share-type'
import config from '../../../config'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'
import styles from './corporate-descriptions.module.less'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import { getStudiosSuggestionsByIds } from '../../../api/studiosApi'
import { useEffect, useState } from 'react'
import {
  CloudUploadOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  SolutionOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'
import { TaggingContextEnums } from '../../../pages/tags-container/constant'
import { getTagsByContext } from '../../../api/tagsApi'
import { ITag } from '../../../pages/tags-container/share-type'

const { guavapassUrl } = config

const { confirm } = Modal

interface Iprops {
  corporate: ICorporateDetailResponse
  onAdjustmentCredits: () => void
  onDistributeCredits: () => void
  onBatchDistributeCredit: () => void
  onCreateCorporateDistributionScheduleModal: () => void
}
const CorporateDescriptions = (props: Iprops) => {
  const {
    corporate,
    onAdjustmentCredits,
    onDistributeCredits,
    onBatchDistributeCredit,
    onCreateCorporateDistributionScheduleModal,
  } = props
  const navigate = useNavigate()
  const [disabledCorporateCreditsHistoriesButton, setDisabledCorporateCreditsHistoriesButton] =
    useState<boolean>(false)
  const [activityTags, setActivityTags] = useState<ITag[]>([])

  const location = useLocation()

  useEffect(() => {
    if (location.pathname.match(/^\/corporates\/[0-9]+\/credits\/histories$/)) {
      setDisabledCorporateCreditsHistoriesButton(true)
    } else {
      setDisabledCorporateCreditsHistoriesButton(false)
    }

    getTagsByContext(TaggingContextEnums.ACTIVITIES).then(res => {
      if (res) {
        setActivityTags(
          Object.values(res).flat()
        )
      }
    })
  }, [location.pathname])

  useEffect(() => {
    if (
      corporate.corporate.studioIds?.length &&
      corporate.corporate.studioIds.length <= MAX_QS_STRINGIFY_LENGTH
    ) {
      getStudiosSuggestionsByIds(corporate.corporate.studioIds).then(res => {
        setStudioState(res)
      })
    }
  }, [corporate])

  const toEdit = () => {
    navigate(`/corporates/${corporate?.corporate.id}/edit`)
  }
  const [studiosState, setStudioState] = useState<{ id: number; name: string }[]>([])

  const distributionCreditsMenuItems = [
    {
      key: '1',
      label: (
        <Button type="link" icon={<SolutionOutlined />}>
          <FormattedMessage id="corporate.credits.distributeToAll" />
        </Button>
      ),
      onClick: () => {
        confirm({
          title:
            corporate && corporate.report.activatedMemberCount > 0
              ? translate('corporate.credits.distributeToAll.confirm', {
                  activatedMemberCount: corporate.report.activatedMemberCount,
                })
              : translate('corporate.credits.distributeToAll.confirmNoMember'),
          icon: <ExclamationCircleOutlined />,
          onOk() {
            if (corporate && corporate.corporate.id && corporate.report.activatedMemberCount > 0) {
              onDistributeCredits()
            }
          },
        })
      },
    },
    {
      key: '2',
      label: (
        <Button type="link" icon={<CloudUploadOutlined />}>
          <FormattedMessage id="corporate.credits.distributeToUploaded" />
        </Button>
      ),
      onClick: () => {
        onBatchDistributeCredit()
      },
    },
    {
      key: '3',
      label: (
        <Button type="link" icon={<ToolOutlined />}>
          <FormattedMessage id="navbar.corporateCreditsDistributionSchedule.create" />
        </Button>
      ),
      onClick: () => {
        onCreateCorporateDistributionScheduleModal()
      },
    },
  ]

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title="合作企业详情"
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
          extra={
            <Space>
              <AuthPermission permission={PermissionsEnum.CORPORATES_WRITE}>
                <Dropdown
                  menu={{
                    items: distributionCreditsMenuItems,
                  }}
                >
                  <a>
                    {translate('corporate.credits.distribution')} <DownOutlined />
                  </a>
                </Dropdown>
              </AuthPermission>

              <AuthPermission permission={PermissionsEnum.CORPORATES_WRITE}>
                <Button onClick={toEdit} type="primary">
                  {translate('edit')}
                </Button>
              </AuthPermission>
            </Space>
          }
        >
          <Descriptions.Item label="ID">{corporate?.corporate.id}</Descriptions.Item>
          <Descriptions.Item label="名称">{corporate?.corporate.name}</Descriptions.Item>
          <Descriptions.Item label={translate('corporate.unusedDistributedCreditsThreshold')}>
            {corporate?.corporate.unusedDistributedCreditsThreshold}
          </Descriptions.Item>
          <Descriptions.Item label="活跃">
            <YesOrNoTag value={corporate?.corporate.isActive} />
          </Descriptions.Item>
          <Descriptions.Item label="是否试用企业">
            <YesOrNoTag
              value={corporate?.corporate.isTrial}
              yesColor={'yellow'}
              noColor={'green'}
            />
          </Descriptions.Item>
          <Descriptions.Item label="创建时间">
            {getDateTime(corporate?.corporate.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="更改时间">
            {getDateTime(corporate?.corporate.updatedAt)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="企业点数"
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
          extra={
            <Space>
              <Button
                disabled={disabledCorporateCreditsHistoriesButton}
                type="link"
                className={styles['view-credits-history']}
                href={`/corporates/${corporate?.corporate.id}/credits/histories`}
              >
                {translate('corporate.credits.viewDetails')}
              </Button>

              <AuthPermission permission={PermissionsEnum.CORPORATES_WRITE}>
                <Button type="primary" onClick={onAdjustmentCredits}>
                  {translate('corporate.credits.adjustment')}
                </Button>
              </AuthPermission>
            </Space>
          }
        >
          <Descriptions.Item label="点数">{corporate?.corporate.credits}</Descriptions.Item>
          <Descriptions.Item label="分发的点数（含buffer）">
            {corporate?.report.distributedCredits}
          </Descriptions.Item>
          <Descriptions.Item label={translate('corporate.unusedDistributedCredits')}>
            {corporate?.corporate.unusedDistributedCredits}
          </Descriptions.Item>
          <Descriptions.Item label="成员已使用的点数">
            {corporate?.report.memberUsedCredits}
          </Descriptions.Item>
          <Descriptions.Item label="成员账户显示未使用的点数（含buffer）">
            {corporate?.report.memberUnusedCredits}
          </Descriptions.Item>
          <Descriptions.Item label="所有成员数">
            {corporate?.report.allMemberCount}
          </Descriptions.Item>
          <Descriptions.Item label="当前激活成员数">
            {corporate?.report.activatedMemberCount}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="企业预约限制"
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label="允许预约的场馆列表">
            {corporate.corporate.studioIds &&
              corporate.corporate.studioIds?.length > 100 &&
              corporate.corporate.studioIds?.map(id => {
                return (
                  <Tag color="blue">
                    <Button
                      type="link"
                      target="_black"
                      href={`${guavapassUrl}/admin/studios/${id}`}
                    >
                      {id}
                    </Button>
                  </Tag>
                )
              })}
            {studiosState?.map(studio => {
              return (
                <Tag color="blue" key={studio.id}>
                  <Button
                    type="link"
                    target="_black"
                    href={`${guavapassUrl}/admin/studios/${studio.id}`}
                  >
                    {studio.name}
                  </Button>
                </Tag>
              )
            })}
          </Descriptions.Item>
          <Descriptions.Item label="单次预约允许使用最大点数">
            {corporate.corporate.maxCreditsUsageThreshold || '无限制'}
          </Descriptions.Item>
          <Descriptions.Item label="不允许预约的activities">
            {corporate.corporate.disallowActivityList?.map(id => {
              const tag = activityTags
                .find(item => item.id === id)
              if (tag) {
                const tagName = tag.tagTranslations
                  .find(t => t.locale === 'zh-CN')
                const tagCategoryAndSubCategory = [tag.category, tag.subCategory].join("/")
                const tagDisplayName = `${tagName?.name} (${tagCategoryAndSubCategory})`
                return <Tag key={id}>{tagDisplayName}</Tag>
              }
            })}
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </>
  )
}

export default CorporateDescriptions
