import { Button, Descriptions, notification, Space, Tag, Image } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  IPrelaunchStudioWithFormattedTags,
  PrelaunchConfirmationStatusEnum,
  PrelaunchStudioPhoneNumberRequest,
} from '../share-types'
import {
  confirmPrelaunchStudios,
  getPrelaunchStudioDetail,
} from '../../../api/prelaunchOrganizationFormsApi'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import LinkButton from '../../../components/link-button/link-button'
import translate from '../../../i18n'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { getTagsByContext } from '../../../api/tagsApi'
import { TaggingContextEnums } from '../../tags-container/constant'
import { getFormattedTags } from '../../../helpers/prelaunch-organization-helper'
import PrelaunchConfirmModal from '../../../components/prelaunch-organization-forms/prelaunch-confirm-modal/prelaunch-confirm-modal'
import { keysToCamel } from '../../../helpers/keys-to-case'
import { StudioPhoneNumberTypeOptions } from '../constant'

const PrelaunchStudio = () => {
  const params = useParams()
  const [prelaunchStudioId] = useState<string | undefined>(params?.prelaunchStudioId)
  const [prelaunchOrganizationId] = useState<string | undefined>(params?.id)
  const [prelaunchStudio, setPrelaunchStudio] = useState<IPrelaunchStudioWithFormattedTags>()
  const [open, setOpen] = useState<boolean>(false)
  const [studioPhoneNumbers, setStudioPhoneNumbers] = useState<PrelaunchStudioPhoneNumberRequest[]>(
    [],
  )

  const handleConfirmButtonClick = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  const formatFormName = () => {
    if (prelaunchStudio) {
      return `【${translate('navBar.prelaunchStudios')} ${prelaunchStudio.studioTranslations.map(t => t.name).join(' / ')}】`
    } else {
      return `【${translate('navBar.prelaunchStudios')}】`
    }
  }

  const handleConfirmPrelaunchStudio = () => {
    if (prelaunchStudio) {
      confirmPrelaunchStudios({
        prelaunchStudioIds: [prelaunchStudio.id],
      })
        .then(response => {
          setOpen(false)

          if (response) {
            notification.success({
              message: 'Confirm Success',
            })
            window.location.reload()
          }
        })

        .catch(err => {
          setOpen(false)

          notification.error({
            message: 'Confirm Failed',
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  useEffect(() => {
    if (prelaunchStudioId) {
      getPrelaunchStudioDetail(Number(prelaunchStudioId)).then(data => {
        const promises = [
          getTagsByContext(TaggingContextEnums.ACTIVITIES),
          getTagsByContext(TaggingContextEnums.AMENITIES),
          getTagsByContext(TaggingContextEnums.PRIMARY_CATEGORY),
          getTagsByContext(TaggingContextEnums.LABELS),
        ]

        Promise.all(promises).then(([activities, amenities, primaryCategories, labels]) => {
          setPrelaunchStudio({
            formattedActivities: getFormattedTags(activities, data.activities),
            formattedAmenities: getFormattedTags(amenities, data.amenities),
            formattedPrimaryCategory: getFormattedTags(
              primaryCategories,
              data.primaryCategory.toString(),
            ),
            formattedLabels: getFormattedTags(labels, data.labels),
            ...data,
          })
          const phoneNumbers = JSON.parse(data.studioPhoneNumbers).map(
            (phoneNumber: PrelaunchStudioPhoneNumberRequest) => {
              return keysToCamel(phoneNumber)
            },
          )

          setStudioPhoneNumbers(phoneNumbers)
        })
      })
    }
  }, [prelaunchStudioId])

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {prelaunchStudio && (
          <>
            <Descriptions
              title={translate('navBar.viewPrelaunchStudios')}
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={2}
              bordered
              extra={
                prelaunchStudio.status == PrelaunchConfirmationStatusEnum.SUBMITTED && (
                  <Space>
                    <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                      <LinkButton
                        type="default"
                        to={`/prelaunch-organization-forms/${prelaunchOrganizationId}/studios/${prelaunchStudioId}/edit`}
                      >
                        {translate('edit')}
                      </LinkButton>
                    </AuthPermission>

                    <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                      <Button type="primary" onClick={handleConfirmButtonClick}>
                        {translate('prelaunchOrganizationForms.confirm')}
                      </Button>
                    </AuthPermission>
                  </Space>
                )
              }
            >
              <Descriptions.Item label="ID">{prelaunchStudio.id}</Descriptions.Item>
              <Descriptions.Item label={translate('studio.studioId')}>
                <LinkButton to={`/studios/${prelaunchStudio.studioId}`}>
                  {prelaunchStudio.studioId || ''}
                </LinkButton>
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.fxiaokeAccountObjectId')}>
                {prelaunchStudio.fxiaokeAccountObjectId || ''}
              </Descriptions.Item>
              <Descriptions.Item label={translate('status')}>
                {prelaunchStudio.status}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studioTranslation.name')}>
                {prelaunchStudio.studioTranslations.length
                  ? prelaunchStudio.studioTranslations.map(t => t.name).join(' / ')
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.isRequiredQrCodeCheckIn')}>
                <YesOrNoTag value={prelaunchStudio.isRequiredScanStudioQrCodeCheckIn} />
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.cityId')}>
                {prelaunchStudio.city?.id || ''}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studioTranslation.city')}>
                {prelaunchStudio.city?.translations.length
                  ? prelaunchStudio.city?.translations.map(t => t.name).join(' / ')
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.neighborhoodId')}>
                {prelaunchStudio.neighborhood?.id || ''}
              </Descriptions.Item>
              <Descriptions.Item label={translate('neighborhood')}>
                {prelaunchStudio.neighborhood?.translations.length
                  ? prelaunchStudio.neighborhood?.translations.map(t => t.name).join(' / ')
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.latitude')}>
                {prelaunchStudio.latitude}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.longitude')}>
                {prelaunchStudio.longitude}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.amenities')}>
                {prelaunchStudio.formattedAmenities
                  ? prelaunchStudio.formattedAmenities.map((label, index) => (
                      <Tag key={index}>{label}</Tag>
                    ))
                  : prelaunchStudio.amenities}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.activities')}>
                {prelaunchStudio.formattedActivities
                  ? prelaunchStudio.formattedActivities.map((label, index) => (
                      <Tag key={index}>{label}</Tag>
                    ))
                  : prelaunchStudio.activities}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.labels')}>
                {prelaunchStudio.formattedLabels
                  ? prelaunchStudio.formattedLabels.map((label, index) => (
                      <Tag key={index}>{label}</Tag>
                    ))
                  : prelaunchStudio.labels}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.primaryCategory')}>
                {prelaunchStudio.formattedPrimaryCategory
                  ? prelaunchStudio.formattedPrimaryCategory.map((label, index) => (
                      <Tag key={index}>{label}</Tag>
                    ))
                  : prelaunchStudio.primaryCategory}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.transitStations')}>
                {prelaunchStudio.transitStations}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.businessDistricts')}>
                {prelaunchStudio.businessDistricts}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.checkInOpenInMinutes')}>
                {prelaunchStudio.checkInOpenInMinutes}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.checkInDurationInMinutes')}>
                {prelaunchStudio.checkInDurationInMinutes}
              </Descriptions.Item>
              <Descriptions.Item label={translate('createdAt')}>
                {prelaunchStudio.createdAt}
              </Descriptions.Item>
              <Descriptions.Item label={translate('updatedAt')}>
                {prelaunchStudio.updatedAt}
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              title="电话号码"
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={2}
              bordered
            >
              {studioPhoneNumbers.map((phoneNumber, index) => {
                const typeLabel = StudioPhoneNumberTypeOptions.find(
                  option => option.value === phoneNumber.type,
                )?.label
                return (
                  <Descriptions.Item label={`type: ${typeLabel}`} key={index}>
                    {phoneNumber.phoneNumber}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>

            <Descriptions
              title={translate('studio.adminUser')}
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={2}
              bordered
            >
              <Descriptions.Item label={translate('studio.adminUser.email')}>
                {prelaunchStudio.adminEmails?.split(',')}
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              title="图片"
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={1}
              bordered
            >
              <Descriptions.Item label="Logo">
                <Image width={200} src={prelaunchStudio.profile} />
              </Descriptions.Item>

              <Descriptions.Item label="Banner">
                <Image width={200} src={prelaunchStudio.banner} />
              </Descriptions.Item>
              <Descriptions.Item label="图片">
                <Image.PreviewGroup>
                  {prelaunchStudio.photos.map((photo, index) => (
                    <Image key={index} width={200} src={photo} />
                  ))}
                </Image.PreviewGroup>
              </Descriptions.Item>
            </Descriptions>

            {prelaunchStudio.studioTranslations.map((translation, index) => (
              <Descriptions
                key={index}
                title={translate('studio.studioTranslation') + ' - ' + translation.locale}
                labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
                contentStyle={{ minWidth: 300 }}
                column={2}
                bordered
              >
                <Descriptions.Item label={translate('locale')}>
                  {translation.locale}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.name')}>
                  {translation.name}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.address1')}>
                  {translation.address1}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.address2')}>
                  {translation.address2}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.howToFindStudio')}>
                  {translation.howToFindStudio}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.transitToStudioPath')}>
                  {translation.transitToStudioPath}
                </Descriptions.Item>
                <Descriptions.Item label={translate('funFact')}>
                  {translation.funFact}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.highlights')}>
                  {translation.highlights}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.description')}>
                  {translation.description}
                </Descriptions.Item>
                <Descriptions.Item label={translate('studioTranslation.specialNoteToUsers')}>
                  {translation.specialNoteToUsers}
                </Descriptions.Item>
                <Descriptions.Item label={translate('createdAt')}>
                  {translation.createdAt}
                </Descriptions.Item>
                <Descriptions.Item label={translate('updatedAt')}>
                  {translation.updatedAt}
                </Descriptions.Item>
              </Descriptions>
            ))}
          </>
        )}
      </Space>

      <PrelaunchConfirmModal
        open={open}
        formName={formatFormName()}
        handleCancel={handleCloseModal}
        handleConfirm={handleConfirmPrelaunchStudio}
      />
    </>
  )
}

export default PrelaunchStudio
