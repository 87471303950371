import { UploadFile } from 'antd'
import { PageInfo } from '../../share-types'
import { ICompanyPaymentDetail, IOrganization } from '../organization-container/share-type'
import { StudioStatusEnums } from '../studios-container/constants'

export interface PrelaunchOrganizationCreateRequest {
  expiredAtAfter: Date
  organizationId?: number
}

export enum PrelaunchOrganizationFormStatusEnum {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  EXPIRED = 'EXPIRED',
  CLOSED = 'CLOSED',
}

export enum PrelaunchConfirmationStatusEnum {
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
}

export interface ICity {
  id: number
  longitude: number
  latitude: number
  status: number
  translations: ICityTranslation[]
}

export interface ICityTranslation {
  id: number
  locale: string
  name: string
  createdAt: string
  updatedAt: string
}

export interface INeighborhood {
  id: number
  cityId: number
  latitude: number
  longitude: number
  createdAt: string
  updatedAt: string
  isManualCoordinates: boolean
  nameGooglePlaces: string
  translations: INeighborhoodTranslation[]
}

export interface INeighborhoodTranslation {
  id: number
  locale: string
  createdAt: string
  updatedAt: string
  name: string
}

export interface IPrelaunchStudioTranslation {
  id: number
  locale: string
  name: string
  address1: string
  address2: string
  howToFindStudio: string
  transitToStudioPath: string
  funFact: string
  highlights: string
  description: string
  specialNoteToUsers: string
  createdAt: string
  updatedAt: string
}

export interface IPrelaunchStudio {
  id: number
  city?: ICity
  neighborhood?: INeighborhood
  latitude: number
  longitude: number
  amenities: string
  activities: string
  labels: string
  primaryCategory: number
  transitStations: string
  businessDistricts: string
  isRequiredScanStudioQrCodeCheckIn: boolean
  checkInOpenInMinutes: number
  checkInDurationInMinutes: number
  studioPhoneNumbers: string
  status: PrelaunchConfirmationStatusEnum
  studioTranslations: IPrelaunchStudioTranslation[]
  createdAt: string
  updatedAt: string
  prelaunchOrganizationFormUniqueId: string
  profile: string
  banner: string
  photos: string[]
  studioId?: number
  fxiaokeAccountObjectId?: string
  adminEmails: string
}

export interface IPrelaunchStudioWithFormattedTags extends IPrelaunchStudio {
  formattedActivities?: string[]
  formattedAmenities?: string[]
  formattedLabels?: string[]
  formattedPrimaryCategory?: string[]
}

export interface IPrelaunchLessonDescription {
  id: number
  lessonDescriptionId: number | null
  name: string
  duration: number
  credits: number
  activityList: string
  labelList: string
  startBookable: number
  bookBefore: number
  lateBefore: number
  maxCapacity: number
  difficultLevel: number
  isAllDay: boolean
  status: PrelaunchConfirmationStatusEnum
  lessonDescriptionTranslations: IPrelaunchLessonDescriptionTranslation[]
  createdAt: string
  updatedAt: string
  prelaunchOrganizationFormUniqueId: string
  photos: string[]
}

export interface IPrelaunchLessonDescriptionWithFormattedTags extends IPrelaunchLessonDescription {
  formattedActivities?: string[]
  formattedLabels?: string[]
}

export interface IPrelaunchLessonDescriptionTranslation {
  id: number
  locale: string
  name: string
  funFact: string
  highlights: string
  description: string
  specialNoteToUsers: string
  lessonPrerequisites: string
  checkInNotes: string
  otherNotes: string
  targetUserGroups: string
  createdAt: string
  updatedAt: string
}

export interface IPrelaunchCompanyPaymentDetail extends ICompanyPaymentDetail {
  status: PrelaunchConfirmationStatusEnum
}

export interface ISimplePrelaunchOrganizationForm {
  id: number
  name: string
  organizationId: number
  organizationName: string
  status: PrelaunchOrganizationFormStatusEnum
  createdAt: string
  updatedAt: string
  uniqueId: string
  expiredAt: string
}

export interface IPrelaunchOrganizationForm {
  id: number
  expiredAt: string
  organization: IOrganization
  createdAt: string
  updatedAt: string
  uniqueId: string
  status: PrelaunchOrganizationFormStatusEnum
  prelaunchStudios: IPrelaunchStudio[]
  prelaunchLessonDescription: IPrelaunchLessonDescription[]
  prelaunchCompanyPaymentDetail: IPrelaunchCompanyPaymentDetail
}

export interface IIPrelaunchOrganizationFormsFilterParams {
  organizationId?: number
  uniqueId?: string
  status?: string
  expiredAtAfter?: number
}

export type IPaginatedIPrelaunchOrganizationFormsFilterParams = {
  page?: number
  size?: number
} & IIPrelaunchOrganizationFormsFilterParams

export interface PaginatedPrelaunchOrganizationFormsResponse {
  pageInfo: PageInfo
  data: ISimplePrelaunchOrganizationForm[]
}

export interface IPublicPrelaunchStudioDto {
  id: number
  name: string
  city: string
  address1: string
  address2: string
  status: StudioStatusEnums
  createdAt: string
  updatedAt: string
}

export interface IPublicPrelaunchLessonDescriptionDto {
  id: number
  name: string
  duration: number
  credits: number
  status: PrelaunchConfirmationStatusEnum
}

export interface IPublicPrelaunchOrganizationForm {
  organizationName: string
  uniqueId: string
  status: PrelaunchOrganizationFormStatusEnum
  studios: IPublicPrelaunchStudioDto[]
  lessonDescriptions: IPublicPrelaunchLessonDescriptionDto[]
  hasCompanyPaymentDetail: boolean
  expiredAt: string
}

export enum StudioPhoneNumberTypeEnums {
  NOTIFICATION = 'NOTIFICATION',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
}

export interface PrelaunchStudioPhoneNumberRequest {
  phoneNumber: string
  type: StudioPhoneNumberTypeEnums
}

export interface PrelaunchStudioTranslationRequest {
  locale: string
  name: string
  address1?: string
  address2?: string
  howToFindStudio?: string
  transitToStudioPath?: string
  funFact?: string
  highlights?: string
  description?: string
  specialNoteToUsers?: string
}

export interface BasePrelaunchStudioCreationFormValues {
  neighborhoodId: number
  cityId: number
  latitude: number
  longitude: number
  amenities: number[]
  activities: number[]
  labels: number[]
  primaryCategory: number
  transitStations?: number[][]
  businessDistricts?: number[]
  isRequiredScanStudioQrCodeCheckIn: boolean
  checkInOpenInMinutes: number
  checkInDurationInMinutes: number
  translations: PrelaunchStudioTranslationRequest[]
  photos?: UploadFile[]
  profile?: UploadFile[]
  banner?: UploadFile[]
  adminEmails: string
  customerSupportPhoneNumber: string
  notificationPhoneNumber: string
}

export interface PrelaunchStudioCreationFormValues {
  neighborhoodId: number
  cityId: number
  latitude: number
  longitude: number
  amenities: number[]
  activities: number[]
  labels: number[]
  primaryCategory: number
  transitStations?: number[]
  businessDistricts?: number[]
  isRequiredScanStudioQrCodeCheckIn: boolean
  checkInOpenInMinutes: number
  checkInDurationInMinutes: number
  studioPhoneNumbers: PrelaunchStudioPhoneNumberRequest[]
  translations: PrelaunchStudioTranslationRequest[]
  profile?: UploadFile[]
  banner?: UploadFile[]
  photos?: UploadFile[]
  profileFileName?: string | null
  bannerFileName?: string | null
  photoFileNames?: string[]
  adminEmails: string[]
}

export interface PrelaunchStudioCreationRequest extends PrelaunchStudioCreationFormValues {
  uniqueId: string
  businessDistricts?: number[]
}

export interface PrelaunchLessonDescriptionTranslationCreationRequest {
  locale: string
  name: string
  funFact?: string
  highlights?: string
  description: string
  specialNoteToUsers?: string
  lessonPrerequisites?: string
  checkInNotes?: string
  otherNotes?: string
  targetUserGroups?: string
}

export interface PrelaunchLessonDescriptionFormValues {
  duration: number
  credits: number
  activityList: number[]
  labelList: number[]
  startBookable: number
  bookBefore: number
  lateBefore: number
  maxCapacity: number
  difficultLevel: number
  isAllDay: boolean
  translations: PrelaunchLessonDescriptionTranslationCreationRequest[]
  photos?: UploadFile[]
}

export interface PrelaunchLessonDescriptionCreationRequest
  extends PrelaunchLessonDescriptionFormValues {
  uniqueId: string
}

export interface PrelaunchCompanyPaymentDetailFormValues {
  companyName: string
  companyAddress?: string
  bankName: string
  bankAccountNumber: string
  taxIdentificationNumber: string
}

export interface PrelaunchCompanyPaymentDetailCreationRequest
  extends PrelaunchCompanyPaymentDetailFormValues {
  uniqueId: string
}

export interface PrelaunchStudioConfirmRequest {
  prelaunchStudioIds: number[]
}

export interface PrelaunchLessonDescriptionsConfirmRequest {
  prelaunchLessonDescriptionIds: number[]
}

export interface PrelaunchCompanyPaymentDetailConfirmRequest {
  prelaunchCompanyPaymentDetailId: number
}
