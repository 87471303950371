import qs from 'qs'

import config from '../config/index'
import {
  CreditsAdjustmentRequest,
  IPaginatedLessonDescriptionsCreditsExchangeRatesListParams,
  IPaginatedOrganizationCreditsExchangeRatesParams,
  IPaginatedStudiosCreditsExchangeRatesListParams,
  LessonDescriptionsCreditsExchangeRatesListDto,
  OrganizationCreditsExchangeRatesListDto,
  StudiosCreditsExchangeRatesListDto,
} from '../pages/exchange-rates-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getStudiosCreditsExchangeRates = (
  id: number,
  data: IPaginatedStudiosCreditsExchangeRatesListParams,
) =>
  authRequest.get<MISSING_TYPE, StudiosCreditsExchangeRatesListDto>(
    `${mainApi}/exchange-rates/organizations/${id}/studios`,
    { params: data },
  )

export const getLessonDescriptionsCreditsExchangeRates = (
  organizationId: number,
  id: number,
  data: IPaginatedLessonDescriptionsCreditsExchangeRatesListParams,
) =>
  authRequest.get<MISSING_TYPE, LessonDescriptionsCreditsExchangeRatesListDto>(
    `${mainApi}/exchange-rates/organizations/${organizationId}/studios/${id}/lesson-descriptions`,
    { params: data },
  )

export const getOrganiztionCreditsExchangeRates = (
  data: IPaginatedOrganizationCreditsExchangeRatesParams,
) =>
  authRequest.get<MISSING_TYPE, OrganizationCreditsExchangeRatesListDto>(
    `${mainApi}/exchange-rates/organizations`,
    {
      params: data,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const exchangeRateAdjustment = (data: CreditsAdjustmentRequest) => {
  return authRequest.post(`${mainApi}/exchange-rates`, data)
}
