import { Button, Row, Space, Table, Tag, notification } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'
import config from '../../../config'
import OneTimeCodesFilter from '../one-time-codes-filter/one-time-codes-filter'
import { PageInfo } from '../../../share-types'
import {
  IOneTimeCodeBatch,
  IOneTimeCodeBatchesFilterParams,
  OneTimeCodeStatus,
} from '../../../pages/one-time-code-batches-container/share-type'
import { IOneTimeCode } from '../../../pages/one-time-codes-container/share-type'
import LinkButton from '../../link-button/link-button'
import { DownOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons'
import { OneTimeCodeStatusOptions } from '../../../pages/one-time-code-batches-container/constant'
import { oneTimeCodeChangeStatus } from '../../../api/oneTimeCodeApi'

interface OneTimeCodesTableProps {
  oneTimeCodes: IOneTimeCode[]
  pageInfo: PageInfo
  paginationOnChange: (page: number, pageSize: number) => void
  onSearch: (values: IOneTimeCodeBatchesFilterParams) => void
  searchValues?: IOneTimeCodeBatchesFilterParams
  isNeedBatchId?: boolean
  onReloadOneTimeCodes?: () => void
}

const { guavapassUrl } = config
const OneTimeCodesTable = (props: OneTimeCodesTableProps) => {
  const {
    oneTimeCodes,
    pageInfo,
    paginationOnChange,
    onSearch,
    searchValues,
    isNeedBatchId,
    onReloadOneTimeCodes,
  } = props

  const baseColumns: ColumnsType<IOneTimeCode> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      fixed: 'left',
      width: 100,
    },
    {
      title: translate('oneTimeCode.creditsValidityPeriodInDays'),
      dataIndex: 'batch',
      key: 'creditsValidityPeriodInDays',
      fixed: 'left',
      width: 100,
      render: (batch: IOneTimeCodeBatch) => {
        if (batch) {
          return <span>{batch.creditsValidityPeriodInDays}</span>
        }
        return ''
      },
    },
    {
      title: translate('oneTimeCode.redeemedBy'),
      dataIndex: 'redeemedById',
      key: 'redeemedById',
      width: 80,
      render: (redeemedById: string) => {
        if (!redeemedById) {
          return ''
        }
        return (
          <Button type="link" target="_black" href={`${guavapassUrl}/admin/users/${redeemedById}`}>
            {redeemedById}
          </Button>
        )
      },
    },
    {
      title: translate('oneTimeCode.redeemedByUser'),
      dataIndex: 'redeemedByUser',
      key: 'redeemedByUser',
      width: 80,
    },
    {
      title: translate('oneTimeCode.redeemedAt'),
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      width: 100,
      render: (redeemedAt: string | null) => {
        if (redeemedAt) {
          return <span>{getDateTime(redeemedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('oneTimeCode.status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: OneTimeCodeStatus) => {
        const tag = OneTimeCodeStatusOptions.find(item => item.value === status)
        return <Tag color={tag?.color}>{tag?.label}</Tag>
      },
      filters: OneTimeCodeStatusOptions,
      filterIcon: <DownOutlined />,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (record: IOneTimeCode) => {
        return <Space>{getActionItems(record)}</Space>
      },
    },
  ]

  const batchIdColumn = {
    title: 'BatchId',
    dataIndex: 'batch',
    key: 'BatchId',
    fixed: 'left',
    width: 80,
    render: (batch: IOneTimeCodeBatch) => {
      if (batch) {
        return <LinkButton to={`/one-time-code-batches/${batch.id}`}>{batch.id}</LinkButton>
      }
      return ''
    },
  }

  const onChangeStatus = (record: IOneTimeCode, status: OneTimeCodeStatus) => {
    oneTimeCodeChangeStatus(record.id, status)
      .then(() => {
        if (onReloadOneTimeCodes) {
          onReloadOneTimeCodes()
        }
        notification.success({
          message: translate('oneTimeCodeBatches.updateSuccess'),
        })
      })
      .catch(err => {
        notification.error({
          message: translate('oneTimeCodeBatches.updateError'),
          description: err?.message || translate('networkError'),
        })
      })
  }

  const getActionItems = (record: IOneTimeCode) => {
    const actionList = OneTimeCodeStatusOptions.filter(item => item.value !== record.status)

    return actionList.map(item => {
      const icon =
        item.value === OneTimeCodeStatus.INACTIVE ? <StopOutlined /> : <CheckCircleOutlined />

      return (
        <Button
          key={item.value}
          type="text"
          className={`status-button-${item.color}`}
          icon={icon}
          onClick={() => onChangeStatus(record, item.value)}
        >
          {item.label}
        </Button>
      )
    })
  }

  const batchIdColumns: ColumnsType<IOneTimeCode> = [
    ...baseColumns.slice(0, 1),
    batchIdColumn,
    ...baseColumns.slice(1),
  ] as ColumnsType<IOneTimeCode>

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row className="filter-bar">
        <OneTimeCodesFilter
          isNeedBatchId={isNeedBatchId}
          onSearch={onSearch}
          formData={searchValues}
        />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={isNeedBatchId ? batchIdColumns : baseColumns}
        dataSource={oneTimeCodes}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
        scroll={{ x: 1140 }}
      />
    </Space>
  )
}

export default OneTimeCodesTable
