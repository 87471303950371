import { Link, useLocation } from 'react-router-dom'
import { Breadcrumb, PageHeader } from 'antd'
import styles from './nav-bar.module.less'
import translate from '../../i18n'
import { BREADCRUMB_NAMES } from '../../helpers/constant'
import { useEffect, useState } from 'react'

interface Iprops {
  title: string
}

const NavBar = (props: Iprops) => {
  const location = useLocation()
  const [breadcrumbItems, setBreadcrumbItems] = useState<any>()

  useEffect(() => {
    const extraBreadcrumbItems = BREADCRUMB_NAMES()
      .filter(item => location.pathname.match(item.regexp))
      .map(item => {
        const matchPath = location.pathname.match(item.regexp)
        const path = matchPath ? matchPath[0] : ''
        return (
          <Breadcrumb.Item key={path}>
            <Link to={path}>{item.title}</Link>
          </Breadcrumb.Item>
        )
      })

    const tempBreadcrumbItems = [
      <Breadcrumb.Item key="home">
        <Link to="/">{translate('navBar.home')}</Link>
      </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems)

    setBreadcrumbItems(tempBreadcrumbItems)
  }, [location.pathname])

  return (
    <PageHeader
      className={styles['page-header']}
      breadcrumbRender={() => <Breadcrumb>{breadcrumbItems}</Breadcrumb>}
      title={props.title}
    ></PageHeader>
  )
}

export default NavBar
