import config from '../config/index'
import {
  ILessonCreditAdjustment,
  IPaginatedLessonCreditAdjustmentFilterParams,
  LessonCreditAdjustmentRequest,
  LessonCreditAdjustmentUpdateRequest,
  PaginatedLessonCreditAdjustmentResponse,
} from '../pages/lesson-credit-adjustments-container/share-type'
import { authRequest } from '../request'
const { mainApi } = config

export const getPaginatedLessonCreditAdjustments = (
  params: IPaginatedLessonCreditAdjustmentFilterParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedLessonCreditAdjustmentResponse>(
    `${mainApi}/lesson-credit-adjustments`,
    {
      params,
    },
  )

export const createLessonCreditAdjustment = (data: LessonCreditAdjustmentRequest) =>
  authRequest.post<MISSING_TYPE, ILessonCreditAdjustment>(
    `${mainApi}/lesson-credit-adjustments`,
    data,
  )

export const getLessonCreditAdjustmentDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ILessonCreditAdjustment>(
    `${mainApi}/lesson-credit-adjustments/${id}`,
  )

export const updateLessonCreditAdjustmentDetail = (
  id: number,
  data: LessonCreditAdjustmentUpdateRequest,
) =>
  authRequest.put<MISSING_TYPE, ILessonCreditAdjustment>(
    `${mainApi}/lesson-credit-adjustments/${id}`,
    data,
  )

export const changeLessonCreditAdjustmentStatus = (id: number, data: { isActive: boolean }) =>
  authRequest.put<MISSING_TYPE, ILessonCreditAdjustment>(
    `${mainApi}/lesson-credit-adjustments/${id}/change-status`,
    data,
  )
