import { Button, Descriptions, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import YesOrNoTag from '../../common/YesOrNoTag'
import { ITaskReward } from '../../../pages/task-rewards-container/share-type'
import {
  TaskRewardableTypeEnums,
  TaskRewardableTypeOptions,
} from '../../../pages/task-rewards-container/constant'
import LinkButton from '../../link-button/link-button'

interface Iprops {
  taskReward: ITaskReward
}

const TaskRewardDescriptions = (props: Iprops) => {
  const { taskReward } = props

  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/task-rewards/${taskReward?.id}/edit`)
  }

  return (
    <>
      <Descriptions
        style={{ width: 1200 }}
        title={translate('navBar.taskRewardsDetail')}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: 200 }}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.TASK_REWARDS_READ}>
              <Button onClick={toEdit} type="primary">
                {translate('edit')}
              </Button>
            </AuthPermission>
          </Space>
        }
      >
        <Descriptions.Item label={translate('taskRewards.title')}>
          {taskReward.title}
        </Descriptions.Item>

        <Descriptions.Item label={translate('taskRewards.taskRewardableType')}>
          {
            TaskRewardableTypeOptions.find(item => item.value === taskReward.taskRewardableType)
              ?.label
          }
        </Descriptions.Item>
        <Descriptions.Item label={translate('taskRewards.taskRewardableId')}>
          {taskReward.taskRewardableType === TaskRewardableTypeEnums.COUPON_TEMPLATE ? (
            <LinkButton to={`/coupon-templates/${taskReward.taskRewardableId}`}>
              {taskReward.taskRewardableId}
            </LinkButton>
          ) : (
            taskReward.taskRewardableId
          )}
        </Descriptions.Item>
        <Descriptions.Item label={translate('taskRewards.beans')}>
          {taskReward.beans}
        </Descriptions.Item>
        <Descriptions.Item label={translate('taskRewards.isActive')}>
          <YesOrNoTag value={taskReward.isActive} />
        </Descriptions.Item>
        <Descriptions.Item label={translate('createdAt')}>
          {getDateTime(taskReward.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('updatedAt')}>
          {getDateTime(taskReward.updatedAt)}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

export default TaskRewardDescriptions
