import { Button, Form, Select, Input, DatePicker } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'
import {
  IIPrelaunchOrganizationFormsFilterParams,
  PrelaunchOrganizationFormStatusEnum,
} from '../../../pages/prelaunch-organization-forms-container/share-types'

const { Option } = Select
const PrelaunchOrganizationFormsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IIPrelaunchOrganizationFormsFilterParams) => void
  formData: IIPrelaunchOrganizationFormsFilterParams | undefined
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        expiredAtAfter: formData.expiredAtAfter
          ? moment(Number(formData.expiredAtAfter))
          : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: IIPrelaunchOrganizationFormsFilterParams) => {
    onSearch({
      ...values,
      expiredAtAfter: values.expiredAtAfter
        ? moment(values.expiredAtAfter).startOf('day').valueOf()
        : undefined,
    })
  }
  const onReset = () => {
    onSearch({})
  }
  return (
    <Form
      form={form}
      layout="inline"
      className="filter-inline-form"
      onFinish={onFinish}
      onReset={onReset}
      name="prelaunchOrganizationFormsFilter"
      initialValues={{ expiredAtAfter: moment('23:59:59', 'HH:mm:ss') }}
    >
      <Form.Item
        name="organizationId"
        label={translate('prelaunchOrganizationForms.organizationId')}
      >
        <Input />
      </Form.Item>

      <Form.Item name="uniqueId" label={translate('prelaunchOrganizationForms.uniqueId')}>
        <Input />
      </Form.Item>

      <Form.Item name="status" label={translate('status')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {Object.values(PrelaunchOrganizationFormStatusEnum).map(status => {
            return (
              <Option key={status} value={status}>
                {status}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="expiredAtAfter"
        label={translate('prelaunchOrganizationForms.expiredAtAfter')}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PrelaunchOrganizationFormsFilter
