import config from '../config/index'
import {
  IPaginatedWeChatURLLinks,
  IPaginatedWeChatURLLinksFilterParams,
  IWeChatURLLink,
  IWeChatURLLinkRequest,
} from '../pages/wechat-url-links-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedWeChatURLLinks = (data: IPaginatedWeChatURLLinksFilterParams) =>
  authRequest.get<MISSING_TYPE, IPaginatedWeChatURLLinks>(`${mainApi}/wechat-url-links`, {
    params: data,
  })

export const createWeChatURLLink = (data: IWeChatURLLinkRequest) =>
  authRequest.post<MISSING_TYPE, IWeChatURLLink>(`${mainApi}/wechat-url-links`, data)
