import { SET_FIRST_PAGE_SEGEMENTS } from './constants'
import { SegmentsAction } from './action'
import update from 'immutability-helper'
import { ISegment } from '../../pages/segments-container/share-type'

interface InitialState {
  firstPageSegments: ISegment[]
}

const initialState: InitialState = {
  firstPageSegments: [],
}

function segmentsReducer(state = initialState, action: SegmentsAction) {
  switch (action.type) {
    case SET_FIRST_PAGE_SEGEMENTS:
      return update(state, { $merge: { firstPageSegments: action.payload } })
    default:
      return state
  }
}

export default segmentsReducer
