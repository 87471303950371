import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { IStudioFilterParams } from '../../../pages/studios-container/share-type'
import { City, CityTranslation } from '../../../store/city/reducer'
import { LocaleType } from '../../../share-types'
import { StudioStatusEnumOptions } from '../../../pages/studios-container/constants'

const { Option } = Select
const StudiosFilter = ({
  onSearch,
  formData,
  activeCities,
  locale,
}: {
  onSearch: (value: IStudioFilterParams) => void
  formData: IStudioFilterParams | undefined
  activeCities: City[]
  locale: LocaleType
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: IStudioFilterParams) => {
    const { organizationId, neighborhoodId, ...others } = values
    onSearch({
      ...others,
      organizationId: organizationId ? Number(organizationId) : undefined,
      neighborhoodId: neighborhoodId ? Number(neighborhoodId) : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" style={{ gap: '16px' }} onFinish={onFinish}>
      <Form.Item name="name" label={translate('studioTranslation.name')}>
        <Input />
      </Form.Item>

      <Form.Item label={translate('studio.cityId')} name="cityId">
        <Select style={{ minWidth: 130 }} allowClear>
          {activeCities.map(city => {
            const translation = city?.translations.find(
              (translation: CityTranslation) => translation.locale === locale,
            )
            return (
              <Option key={city.id} value={city.id}>
                {translation?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name="status" label={translate('studio.status')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {StudioStatusEnumOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={translate('studio.organizationId')} name="organizationId">
        <Input />
      </Form.Item>

      <Form.Item name="neighborhoodId" label={translate('studio.neighborhoodId')}>
        <Input />
      </Form.Item>

      <Form.Item name="isFirstClassDiscounted" label={translate('studio.isFirstClassDiscounted')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>{translate('yes')}</Option>
          <Option value={false}>{translate('no')}</Option>
        </Select>
      </Form.Item>

      <Form.Item label={translate('hasPeakOffPeak')} name="hasPeakOffPeakDiscount">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default StudiosFilter
