import { Button, Col, Row, Space, Table, Tag } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo } from '../../../share-types'
import { useEffect, useState } from 'react'
import SegmentRulesFilter from '../../../components/segment-rules/segment-rules-filter/segment-rules-filter'
import {
  ISegmentRule,
  ISegmentRulesFilterParams,
  IPaginatedSegmentRulesParams,
} from '../share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import { getPaginatedSegmentRules } from '../../../api/segmentRuleApi'
import { ISegment } from '../../segments-container/share-type'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const SegmentRules = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [segmentRules, setSegmentRules] = useState<ISegmentRule[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ISegmentRulesFilterParams>()

  useEffect(() => {
    const searchName = searchParams.get('name')
    const searchIsActive = searchParams.get('isActive')
    const searchType = searchParams.get('type')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const newSearchValues = {
      name: searchName ?? undefined,
      isActive: searchIsActive ? (searchIsActive === 'true' ? true : false) : undefined,
      type: searchType ?? undefined,
    }
    const data = {
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
      ...newSearchValues,
    }
    setSearchValues(newSearchValues)
    getPaginatedSegmentRulesAction(data)
  }, [])

  const getPaginatedSegmentRulesAction = (data: IPaginatedSegmentRulesParams) => {
    getPaginatedSegmentRules(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setSegmentRules(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
    }
    getPaginatedSegmentRulesAction(data)
  }
  const onSearch = (value: ISegmentRulesFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedSegmentRulesAction(data)
  }

  const columns: ColumnsType<ISegmentRule> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: ISegmentRule, b: ISegmentRule) => a.id - b.id,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '用户分组',
      dataIndex: 'segment',
      key: 'segment',
      render: (segment: ISegment) => {
        return <Tag key={segment.id}>{segment.name}</Tag>
      },
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: ISegmentRule) => (
        <Space>
          <AuthPermission permission="segment-rules:write">
            <LinkButton to={`/segment-rules/${record.id}/edit`}>编辑</LinkButton>
          </AuthPermission>

          <LinkButton to={`/segment-rules/${record.id}`}>查看</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>已创建用户分组规则</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission="segment-rules:write">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/segment-rules/new')}
            >
              创建新的用户分组规则
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <SegmentRulesFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={segmentRules}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default SegmentRules
