import {
  Button,
  Cascader,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Upload,
  UploadFile,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import translate from '../../i18n'
import { getNeighborhoodsByCityId } from '../../api/authApi'
import {
  IBusinessDistrict,
  INeightborhood,
  ITransitLine,
  ITransitStation,
  LocaleType,
  SupportedPrelaunchUploadImageTypeEnums,
} from '../../share-types'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { ReduxStore } from '../../store'
import { City } from '../../store/city/reducer'
import { ITag, ITagsWithCategory } from '../../pages/tags-container/share-type'
import { TaggingContextEnums } from '../../pages/tags-container/constant'
import { LocaleOptions } from '../../helpers/constant'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { request } from '../../request'
import { getTagsByContext } from '../../api/tagsApi'
import {
  BasePrelaunchStudioCreationFormValues,
  PrelaunchStudioCreationFormValues,
  PrelaunchStudioPhoneNumberRequest,
  StudioPhoneNumberTypeEnums,
} from '../../pages/prelaunch-organization-forms-container/share-types'
import { getTransitLinesByCityId } from '../../api/transitLinesApi'
import type { DefaultOptionType } from 'antd/es/cascader'
import { getBusinessDistrictsByCityId } from '../../api/businessDistrictsApi'
import { prelaunchUploadImage } from '../../api/imageApi'
import { getImageFileName } from '../../helpers/image-upload-helper'

interface Option {
  value?: string | number | null
  label: React.ReactNode
  children?: Option[]
  isLeaf?: boolean
  loading?: boolean
}

interface IProps extends ConnectedProps<typeof withConnect> {
  onFinish: (values: PrelaunchStudioCreationFormValues) => void
  formData?: PrelaunchStudioCreationFormValues
  organizationUniqueId: string
  isEdit: boolean
}

const { TextArea } = Input
const { Option } = Select

const StudioForm = ({
  onFinish,
  formData,
  locale,
  activeCities,
  organizationUniqueId,
  isEdit,
}: IProps) => {
  const [form] = Form.useForm()
  const [cityOptions, setCityOptions] = useState<Option[]>([])
  const [options, setOptions] = useState<Option[]>([])
  const [amenitiesTags, setAmenitiesTags] = useState<ITag[]>([])
  const [activitiesTags, setActivitiesTags] = useState<ITag[]>([])
  const [labelsTags, setLabelsTags] = useState<ITag[]>([])
  const [primaryCategoryTags, setPrimaryCategoryTags] = useState<ITag[]>([])
  const cityId = Form.useWatch('cityId', form)
  const [transitStationsOptions, setTransitStationsOptions] = useState<Option[]>([])
  const [businessDistrictOptions, setBusinessDistrictOptions] = useState<Option[]>([])

  useEffect(() => {
    if (formData) {
      const studioPhoneNumbers = formData.studioPhoneNumbers
      const customerSupportPhoneNumber =
        studioPhoneNumbers.find(
          phoneNumber => phoneNumber.type === StudioPhoneNumberTypeEnums.CUSTOMER_SUPPORT,
        )?.phoneNumber || ''
      const notificationPhoneNumber =
        studioPhoneNumbers.find(
          phoneNumber => phoneNumber.type === StudioPhoneNumberTypeEnums.NOTIFICATION,
        )?.phoneNumber || ''
      form.setFieldsValue({
        ...formData,
        adminEmails: formData.adminEmails?.[0],
        customerSupportPhoneNumber,
        notificationPhoneNumber,
      })
    }
  }, [formData])

  useEffect(() => {
    if (!activeCities) {
      return
    }

    setCityOptions(
      activeCities.map((city: City) => {
        const label = city.translations.find(t => t.locale === locale)?.name
        return {
          value: city.id,
          label: `${city.id} - ${label}`,
        }
      }),
    )
  }, [activeCities])

  useEffect(() => {
    if (!cityId) {
      return
    }

    Promise.all([
      getNeighborhoodsByCityId({ cityId: Number(cityId) }),
      getTransitLinesByCityId(Number(cityId)),
      getBusinessDistrictsByCityId(Number(cityId)),
    ]).then(([neighborhoods, transitLines, businessDistricts]) => {
      const neighborhoodOptions = neighborhoods.map((item: INeightborhood) => {
        const label = item.translations.find(t => t.locale === locale)?.name
        return {
          value: item.id,
          label: `${item.id} - ${label}`,
        }
      })
      setOptions(neighborhoodOptions)

      const transitLineOptions = transitLines.map((item: ITransitLine) => {
        const label = item.translations.find(t => t.locale === locale)?.name
        return {
          value: item.id,
          label: `${item.id} - ${label}`,
          children: item.transitStations.map(station => ({
            value: station.id,
            label: `${station.id} - ${station.translations.find(t => t.locale === locale)?.name}`,
          })),
        }
      })
      setTransitStationsOptions(transitLineOptions)

      const businessDistrictOptions = businessDistricts.map((item: IBusinessDistrict) => {
        const label = item.translations.find(t => t.locale === locale)?.name
        return {
          value: item.id,
          label: `${item.id} - ${label}`,
        }
      })

      setBusinessDistrictOptions(businessDistrictOptions)

      if (formData?.transitStations) {
        const transitLinesAndTransitStations = formData.transitStations?.map(
          (stationId: number) => {
            const transitLine = transitLines.find((line: ITransitLine) => {
              return line.transitStations.map((station: ITransitStation) => {
                if (station.id === stationId) {
                  return [line.id, station.id]
                }
              })
            })
            return [transitLine?.id, stationId]
          },
        )

        form.setFieldValue('transitStations', transitLinesAndTransitStations)
      }
    })
  }, [cityId])

  useEffect(() => {
    const promises = [
      getTagsByContext(TaggingContextEnums.ACTIVITIES),
      getTagsByContext(TaggingContextEnums.AMENITIES),
      getTagsByContext(TaggingContextEnums.PRIMARY_CATEGORY),
      getTagsByContext(TaggingContextEnums.LABELS),
    ]
    Promise.all(promises).then(([activities, amenities, primaryCategory, labels]) => {
      if (activities) {
        setActivitiesTags(flatTagsWithContext(activities))
      }
      if (amenities) {
        setAmenitiesTags(flatTagsWithContext(amenities))
      }
      if (primaryCategory) {
        setPrimaryCategoryTags(flatTagsWithContext(primaryCategory))
      }
      if (labels) {
        setLabelsTags(flatTagsWithContext(labels))
      }
    })
  }, [])

  const flatTagsWithContext = (res: ITagsWithCategory): ITag[] => {
    return Object.values(res).flat()
  }

  const getTagsOptions = (tags: ITag[], taggingContex: TaggingContextEnums) => {
    return tags.map(tag => {
      const categoryAndSubCategoryLabel = [tag.category, tag.subCategory]
        .filter(item => item)
        .join('/')
      const tagName = tag.tagTranslations.find(t => t.locale === locale)?.name
      if (taggingContex === TaggingContextEnums.ACTIVITIES) {
        return {
          label: `${tagName} (${categoryAndSubCategoryLabel})`,
          value: tag.id,
        }
      }

      return {
        label: `${tagName}`,
        value: tag.id,
      }
    })
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const uploadUrl = (options: any, type: SupportedPrelaunchUploadImageTypeEnums) => {
    const { onSuccess, onError, file, filename } = options
    const fileExtension = file.name.split('.').pop()
    const imageFilename = `${filename}_${file.uid}.${fileExtension}`
    prelaunchUploadImage(organizationUniqueId, imageFilename, type)
      .then(res => {
        onSuccess(res)
        request({
          url: res,
          method: 'PUT',
          data: file,
          headers: {
            'x-amz-acl': 'public-read',
          },
        })
      })
      .catch(err => {
        onError({ err })
      })
  }

  const transitStationsFilter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    )

  const onSubimtForms = (values: BasePrelaunchStudioCreationFormValues) => {
    const {
      profile,
      banner,
      photos,
      adminEmails,
      isRequiredScanStudioQrCodeCheckIn,
      checkInOpenInMinutes,
      checkInDurationInMinutes,
      latitude,
      longitude,
      customerSupportPhoneNumber,
      notificationPhoneNumber,
      ...others
    } = values
    const transitStations = values.transitStations?.map((transitStationsId: number[]) => {
      return transitStationsId[transitStationsId.length - 1]
    })

    const studioPhoneNumbers: PrelaunchStudioPhoneNumberRequest[] = []

    if (customerSupportPhoneNumber) {
      studioPhoneNumbers.push({
        phoneNumber: customerSupportPhoneNumber,
        type: StudioPhoneNumberTypeEnums.CUSTOMER_SUPPORT,
      })
    }

    if (notificationPhoneNumber) {
      studioPhoneNumbers.push({
        phoneNumber: notificationPhoneNumber,
        type: StudioPhoneNumberTypeEnums.NOTIFICATION,
      })
    }

    onFinish({
      ...others,
      latitude: isEdit ? latitude : 0.0,
      longitude: isEdit ? longitude : 0.0,
      transitStations: Array.from(new Set(transitStations)),
      profileFileName: getImageFileName('profile', profile),
      bannerFileName: getImageFileName('banner', banner),
      adminEmails: values.adminEmails ? [adminEmails] : [],
      isRequiredScanStudioQrCodeCheckIn: isEdit ? isRequiredScanStudioQrCodeCheckIn : true,
      checkInOpenInMinutes: isEdit ? checkInOpenInMinutes : 30,
      checkInDurationInMinutes: isEdit ? checkInDurationInMinutes : 120,
      photoFileNames: photos
        ?.map((photo: UploadFile) => getImageFileName('photos', [photo]))
        .filter(item => item),
      studioPhoneNumbers: studioPhoneNumbers,
    })
  }

  return (
    <Form
      name="studio-form"
      form={form}
      onFinish={onSubimtForms}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{
        amenities: [],
        activities: [],
        labels: [],
      }}
    >
      {isEdit && (
        <>
          <Form.Item
            name="fxiaokeAccountObjectId"
            label={translate('studio.fxiaokeAccountObjectId')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="latitude"
            label={translate('studio.latitude')}
            rules={[{ required: true, message: translate('studio.latitude.message') }]}
            help={
              <span>
                {translate('studio.latitude.tips')}
                <a href="https://lbs.qq.com/getPoint/" target="_blank" rel="noreferrer">
                  https://lbs.qq.com/getPoint/
                </a>
              </span>
            }
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="longitude"
            label={translate('studio.longitude')}
            rules={[{ required: true, message: translate('studio.latitude.message') }]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      <Form.Item
        label={translate('city')}
        name="cityId"
        rules={[{ required: true, message: translate('city.message') }]}
      >
        <Select
          style={{ width: '100%' }}
          placeholder={translate('city.placeholder')}
          options={cityOptions}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label={translate('neighborhood')}
        name="neighborhoodId"
        rules={[{ required: true, message: translate('neighborhood.message') }]}
        help={translate('searchPlaceholder.tips')}
      >
        <Select
          style={{ width: '100%' }}
          placeholder={translate('neighborhood.placeholder')}
          options={options}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        name="transitStations"
        label={translate('studio.transitStations')}
        rules={[{ required: true, message: translate('transitStations.message') }]}
        help={translate('searchPlaceholder.tips')}
      >
        <Cascader
          multiple
          showCheckedStrategy={Cascader.SHOW_CHILD}
          options={transitStationsOptions}
          placeholder={translate('transitStations.placeholder')}
          showSearch={{ filter: transitStationsFilter }}
        />
      </Form.Item>

      <Form.Item
        name="businessDistricts"
        label={translate('studio.businessDistricts')}
        rules={[{ required: true, message: translate('businessDistricts.message') }]}
        help={translate('searchPlaceholder.tips')}
      >
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          placeholder={translate('businessDistricts.placeholder')}
          options={businessDistrictOptions}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Divider style={{ fontSize: '14px' }}>{translate('studio.taggings')}</Divider>

      <Form.Item name="amenities" label={translate('studio.amenities')}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={translate('amenities.placeholder')}
          options={getTagsOptions(amenitiesTags, TaggingContextEnums.AMENITIES)}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item name="activities" label={translate('studio.activities')}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={translate('activities.placeholder')}
          options={getTagsOptions(activitiesTags, TaggingContextEnums.ACTIVITIES)}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item name="labels" label={translate('studio.labels')}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={translate('labels.placeholder')}
          options={getTagsOptions(labelsTags, TaggingContextEnums.LABELS)}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        name="primaryCategory"
        label={translate('studio.primaryCategory')}
        rules={[{ required: true, message: translate('studio.primaryCategory.message') }]}
        help={translate('studio.primaryCategory.tips')}
      >
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder={translate('primaryCategory.placeholder')}
          options={getTagsOptions(primaryCategoryTags, TaggingContextEnums.PRIMARY_CATEGORY)}
          showSearch
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      {isEdit && (
        <>
          <Divider style={{ fontSize: '14px' }}>{translate('studio.checkInInfomation')}</Divider>

          <Form.Item
            name="isRequiredScanStudioQrCodeCheckIn"
            label={translate('studio.isRequiredQrCodeCheckIn')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="checkInOpenInMinutes"
            label={translate('studio.checkInOpenInMinutes')}
            rules={[{ required: true, message: translate('studio.checkInOpenInMinutes.message') }]}
            help={translate('studio.checkInOpenInMinutes.tips')}
          >
            <InputNumber min={0} addonAfter="分钟" />
          </Form.Item>

          <Form.Item
            name="checkInDurationInMinutes"
            label={translate('studio.checkInDurationInMinutes')}
            rules={[
              { required: true, message: translate('studio.checkInDurationInMinutes.message') },
            ]}
            help={translate('studio.checkInDurationInMinutes.tips')}
          >
            <InputNumber min={0} addonAfter="分钟" />
          </Form.Item>
        </>
      )}

      <Divider style={{ fontSize: '14px' }}>{translate('studio.baseInformation')}</Divider>

      <Form.Item
        name="profile"
        label="Logo"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        help="建议图片尺寸：1:1"
      >
        <Upload
          maxCount={1}
          name="profile"
          customRequest={config =>
            uploadUrl(config, SupportedPrelaunchUploadImageTypeEnums.STUDIO_PROFILE)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="banner"
        label="Banner"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        help="建议图片尺寸：9：16或者4：3"
      >
        <Upload
          maxCount={1}
          name="banner"
          customRequest={config =>
            uploadUrl(config, SupportedPrelaunchUploadImageTypeEnums.STUDIO_BANNER)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="photos"
        label="图片"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        help="建议图片尺寸：9：16或者4：3"
      >
        <Upload
          name="photos"
          customRequest={config =>
            uploadUrl(config, SupportedPrelaunchUploadImageTypeEnums.STUDIO_PHOTOS)
          }
          listType="picture"
          accept=".jpeg,.jpg,.png,.gif"
          multiple
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </Upload>
      </Form.Item>

      <Divider style={{ fontSize: '14px' }}>{translate('studio.phoneNumbers')}</Divider>

      <Form.Item
        label={translate('studio.customerSupportPhoneNumber')}
        name="customerSupportPhoneNumber"
        rules={[{ required: true, message: 'Please input phone number!' }]}
        help={translate('studio.phoneNumbers.tips')}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={translate('studio.notificationPhoneNumber')}
        name="notificationPhoneNumber"
        help={translate('studio.notificationPhoneNumber.tips')}
      >
        <Input />
      </Form.Item>

      <Divider style={{ fontSize: '14px' }}>{translate('studio.adminUser')}</Divider>

      <Form.Item
        label={translate('studio.adminUser.email')}
        name="adminEmails"
        rules={[
          { type: 'email' },
          { required: true, message: translate('studio.adminUser.email.message') },
        ]}
        help={translate('studio.adminUser.email.tips')}
      >
        <Input />
      </Form.Item>

      <Divider style={{ fontSize: '14px' }}>{translate('studio.info')}</Divider>

      <Form.List name="translations" initialValue={[{ locale: LocaleType.zh_CN }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                {isEdit && (
                  <Form.Item
                    {...restField}
                    label={translate('locale')}
                    name={[name, 'locale']}
                    rules={[{ required: true, message: '请选择语言！' }]}
                  >
                    <Select disabled={!isEdit}>
                      {LocaleOptions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  label={translate('name')}
                  rules={[{ required: true, message: translate('name.message') }]}
                  {...restField}
                  name={[name, 'name']}
                >
                  <TextArea showCount />
                </Form.Item>
                <Form.Item
                  label={translate('studioTranslation.description')}
                  {...restField}
                  name={[name, 'description']}
                  rules={[
                    { required: true, message: translate('studioTranslation.description.message') },
                  ]}
                  help={translate('studioTranslation.description.tips')}
                >
                  <TextArea showCount />
                </Form.Item>
                <Form.Item
                  label={translate('studioTranslation.address1')}
                  {...restField}
                  name={[name, 'address1']}
                  rules={[
                    { required: true, message: translate('studioTranslation.address1.message') },
                  ]}
                >
                  <TextArea showCount />
                </Form.Item>
                <Form.Item
                  label={translate('studioTranslation.howToFindStudio')}
                  help={translate('studioTranslation.howToFindStudio.tips')}
                  {...restField}
                  name={[name, 'howToFindStudio']}
                >
                  <TextArea showCount />
                </Form.Item>

                <Form.Item
                  label={translate('studioTranslation.transitToStudioPath')}
                  help={translate('studioTranslation.transitToStudioPath.tips')}
                  {...restField}
                  name={[name, 'transitToStudioPath']}
                >
                  <TextArea showCount />
                </Form.Item>
                <Form.Item
                  label={translate('studioTranslation.highlights')}
                  {...restField}
                  name={[name, 'highlights']}
                >
                  <TextArea showCount />
                </Form.Item>

                <Form.Item
                  label={translate('studioTranslation.specialNoteToUsers')}
                  {...restField}
                  name={[name, 'specialNoteToUsers']}
                  help={
                    <>
                      <div>“01、开课前分钟尽量不要进食；</div>
                      <div>02、课程满人开课，若不满人，您的预约将提前小时取消；</div>
                      <div>03、请您提前*分钟到店并签到，请勿迟到；”</div>
                    </>
                  }
                >
                  <TextArea showCount />
                </Form.Item>

                {fields.length > 1 ? (
                  <Form.Item colon={false} label="  ">
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  </Form.Item>
                ) : null}
              </div>
            ))}

            {isEdit && fields.length < Object.keys(LocaleType).length && (
              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {translate('addNewTranslation')}
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
        <Button htmlType="submit" type="primary">
          <FormattedMessage id="submit"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioForm)
