import { Button, Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { ITagsFilterParams } from '../../../pages/tags-container/share-type'
import {
  TagCategoryOptions,
  TagSubCategoryOptions,
  TaggingContextEnums,
} from '../../../pages/tags-container/constant'

const { Option } = Select
const TagsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: ITagsFilterParams) => void
  formData: ITagsFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: ITagsFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" className="filter-inline-form" onFinish={onFinish}>
      <Form.Item name="name" label={translate('tags.name')}>
        <Input />
      </Form.Item>

      <Form.Item name="isActive" label={translate('tags.isActive')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="context" label={translate('tags.cachedCategoryList')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {Object.keys(TaggingContextEnums).map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="category" label={translate('tags.category')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {TagCategoryOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="subCategory" label={translate('tags.subCategory')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {TagSubCategoryOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TagsFilter
