import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'
import { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { setActiveCitiesAction } from '../../store/city/action'
import { compose } from 'redux'
import { getCities } from '../../api/authApi'

const StudiosContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/studios\/[0-9]+$/)) {
      return translate('navBar.viewStudio')
    }
    return translate('navBar.studiosTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(StudiosContainer)
