import { SET_USER_INFO, CLEAR_USER_INFO } from './constants'
import { UserInfoAction } from './action'
import update from 'immutability-helper'
import { IAdminUser } from '../../share-types'

const initialState: IAdminUser | null = null
function userInfoReducer(state = initialState, action: UserInfoAction) {
  switch (action.type) {
    case SET_USER_INFO:
      return update(state, { $set: action.payload })
    case CLEAR_USER_INFO:
      return Object.assign({}, initialState)
    default:
      return state
  }
}

export default userInfoReducer
