import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getStudioIntegrationConfigDetail,
  getStudioIntegrationLessonConfigs,
  getStudioIntegrationLessonDescriptionConfigs,
} from '../../../api/studioIntegrationConfigsApi'
import {
  ConfigType,
  IStudioIntegrationConfig,
  IStudioIntegrationLessonConfig,
  IStudioIntegrationLessonConfigFilterParams,
  IStudioIntegrationLessonDescriptionConfig,
} from '../share-types'
import StudioIntegrationConfigDescriptions from '../../../components/studio-integration-configs/studio-integration-config-descriptions/studio-integration-config-descriptions'
import StudioIntegrationLessonConfigTable from '../../../components/studio-integration-configs/studio-integration-lesson-config-table/studio-integration-lesson-config-table'

const StudioIntegrationConfig = () => {
  const params = useParams()
  const [studioIntegrationConfigId] = useState<string | undefined>(params?.id)
  const [studioIntegrationConfig, setStudioIntegrationConfig] = useState<IStudioIntegrationConfig>()
  const [studioIntegrationLessonConfigs, setStudioIntegrationLessonConfigs] = useState<
    IStudioIntegrationLessonConfig[]
  >([])

  const [studioIntegrationLessonDescriptionConfigs, setStudioIntegrationLessonDescriptionConfigs] =
    useState<IStudioIntegrationLessonDescriptionConfig[]>([])

  const [lessonConfigSearchValues, setLessonConfigSearchValues] =
    useState<IStudioIntegrationLessonConfigFilterParams>()

  const [lessonDescConfigSearchValues, setLessonDescConfigSearchValues] =
    useState<IStudioIntegrationLessonConfigFilterParams>()

  useEffect(() => {
    if (studioIntegrationConfigId) {
      getStudioIntegrationConfigDetail(Number(studioIntegrationConfigId)).then(res => {
        setStudioIntegrationConfig(res)
        setStudioIntegrationLessonConfigs(res.studioIntegrationLessonConfigs)
        setStudioIntegrationLessonDescriptionConfigs(res.studioIntegrationLessonDescriptionConfigs)
      })
    }
  }, [studioIntegrationConfigId])

  const getStudioIntegrationLessonConfigsAction = (
    data: IStudioIntegrationLessonConfigFilterParams,
  ) => {
    if (studioIntegrationConfigId) {
      data.studioIntegrationConfigId = studioIntegrationConfigId
      getStudioIntegrationLessonConfigs(data).then(res => {
        if (res) {
          setStudioIntegrationLessonConfigs(res)
        }
      })
    }
  }

  const getStudioIntegrationLessonDescriptionConfigsAction = (
    data: IStudioIntegrationLessonConfigFilterParams,
  ) => {
    if (studioIntegrationConfigId) {
      data.studioIntegrationConfigId = studioIntegrationConfigId
      getStudioIntegrationLessonDescriptionConfigs(data).then(res => {
        if (res) {
          setStudioIntegrationLessonDescriptionConfigs(res)
        }
      })
    }
  }

  const onReloadLessonDescriptionConfigs = () => {
    if (studioIntegrationConfigId) {
      const data = { studioIntegrationConfigId }
      onSearchLessonDescriptionConfig(data)
    }
  }

  const onReloadLessonConfigs = () => {
    if (studioIntegrationConfigId) {
      const data = { studioIntegrationConfigId }
      onSearchLessonConfig(data)
    }
  }

  const onSearchLessonConfig = (value: IStudioIntegrationLessonConfigFilterParams) => {
    setLessonConfigSearchValues(value)
    getStudioIntegrationLessonConfigsAction(value)
  }

  const onSearchLessonDescriptionConfig = (value: IStudioIntegrationLessonConfigFilterParams) => {
    setLessonDescConfigSearchValues(value)
    getStudioIntegrationLessonDescriptionConfigsAction(value)
  }

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {studioIntegrationConfig && studioIntegrationConfigId && (
          <>
            <StudioIntegrationConfigDescriptions
              studioIntegrationConfig={studioIntegrationConfig}
            />
            <StudioIntegrationLessonConfigTable
              type={ConfigType.LESSON}
              studioIntegrationConfigId={studioIntegrationConfigId}
              data={studioIntegrationLessonConfigs}
              onSearch={onSearchLessonConfig}
              searchValues={lessonConfigSearchValues}
              onReloadConfigs={onReloadLessonConfigs}
            />
            <StudioIntegrationLessonConfigTable
              type={ConfigType.LESSON_DESCRIPTION}
              studioIntegrationConfigId={studioIntegrationConfigId}
              data={studioIntegrationLessonDescriptionConfigs}
              onSearch={onSearchLessonDescriptionConfig}
              searchValues={lessonDescConfigSearchValues}
              onReloadConfigs={onReloadLessonDescriptionConfigs}
            />
          </>
        )}
      </Space>
    </>
  )
}

export default StudioIntegrationConfig
