import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { IFeatureFlagsFilterParams } from '../../../pages/feature-flag-container/share-type'

const { Option } = Select
const FeatureFlagsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IFeatureFlagsFilterParams) => void
  formData: IFeatureFlagsFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: IFeatureFlagsFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="name" label="名字">
        <Input />
      </Form.Item>
      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FeatureFlagsFilter
