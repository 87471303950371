import { Card, Space } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { getPermissions } from '../../api/roleApi'
import NavBar from '../../components/nav-bar/nav-bar'
import translate from '../../i18n'
import { setPermissionsAction } from '../../store/global/action'
import styles from './index.module.less'

const AdminUserRolesContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()
  useEffect(() => {
    getPermissions().then(res => {
      const { setPermissionsAction } = props
      setPermissionsAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/admin-roles\/[0-9]+\/edit$/)) {
      return translate('navBar.editAdminRoles')
    }

    if (pathName.match(/^\/admin-roles\/new/)) {
      return translate('navBar.newAdminRoles')
    }

    return translate('navBar.adminRoles')
  }
  return (
    <Space className={styles['dashboard-wrapper']} direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setPermissionsAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(AdminUserRolesContainer)
