import React from 'react'
import BildivngInvoicesInfo from '../../components/billing-invoices/billing-invoices-info/billing-invoices-info'
import BillingInvoicesTable from '../../components/billing-invoices/billing-invoices-table/billing-invoices-table'
import { Space } from 'antd'

const BildivngInvoices: React.FC = () => {
  return (
    <Space direction="vertical" size={18}>
      <div className="studios-area-page-title">发票相关</div>
      <Space direction="vertical" size={24}>
        <BildivngInvoicesInfo />
        <BillingInvoicesTable />
      </Space>
    </Space>
  )
}
export default BildivngInvoices
