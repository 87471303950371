import {
  IPrelaunchCompanyPaymentDetail,
  IPrelaunchLessonDescription,
  IPrelaunchStudio,
  PrelaunchCompanyPaymentDetailFormValues,
  PrelaunchLessonDescriptionFormValues,
  PrelaunchStudioCreationFormValues,
} from '../pages/prelaunch-organization-forms-container/share-types'
import { ITagsWithCategory } from '../pages/tags-container/share-type'
import { getImageUrlToUploadFileInput } from './image-upload-helper'
import { keysToCamel } from './keys-to-case'

export const convertIPrelaunchStudioToFormValues = (
  studio: IPrelaunchStudio,
): PrelaunchStudioCreationFormValues => {
  return {
    neighborhoodId: studio.neighborhood?.id || 0,
    cityId: studio.city?.id || 0,
    latitude: studio.latitude,
    longitude: studio.longitude,
    amenities: studio.amenities ? studio.amenities.split(',').map(Number) : [],
    activities: studio.activities ? studio.activities.split(',').map(Number) : [],
    labels: studio.labels ? studio.labels.split(',').map(Number) : [],
    primaryCategory: studio.primaryCategory,
    transitStations: studio.transitStations ? studio.transitStations.split(',').map(Number) : [],
    businessDistricts: studio.businessDistricts
      ? studio.businessDistricts.split(',').map(Number)
      : [],
    isRequiredScanStudioQrCodeCheckIn: studio.isRequiredScanStudioQrCodeCheckIn,
    checkInOpenInMinutes: studio.checkInOpenInMinutes,
    checkInDurationInMinutes: studio.checkInDurationInMinutes,
    studioPhoneNumbers: studio.studioPhoneNumbers
      ? keysToCamel(JSON.parse(studio.studioPhoneNumbers))
      : [],
    translations: studio.studioTranslations.map(translation => ({
      locale: translation.locale,
      name: translation.name,
      address1: translation.address1,
      address2: translation.address2,
      howToFindStudio: translation.howToFindStudio,
      transitToStudioPath: translation.transitToStudioPath,
      highlights: translation.highlights,
      description: translation.description,
      specialNoteToUsers: translation.specialNoteToUsers,
    })),
    profile: studio.profile ? getImageUrlToUploadFileInput([studio.profile]) : undefined,
    banner: studio.banner ? getImageUrlToUploadFileInput([studio.banner]) : undefined,
    photos: getImageUrlToUploadFileInput(studio.photos),
    adminEmails: studio.adminEmails?.split(','),
  }
}

export const convertLessonDescriptionToFormValues = (
  lessonDescription: IPrelaunchLessonDescription,
): PrelaunchLessonDescriptionFormValues => {
  return {
    duration: lessonDescription.duration,
    credits: lessonDescription.credits,
    activityList: lessonDescription.activityList
      ? lessonDescription.activityList.split(',').map(Number)
      : [],
    labelList: lessonDescription.labelList
      ? lessonDescription.labelList.split(',').map(Number)
      : [],
    startBookable: lessonDescription.startBookable,
    bookBefore: lessonDescription.bookBefore,
    lateBefore: lessonDescription.lateBefore,
    maxCapacity: lessonDescription.maxCapacity,
    difficultLevel: lessonDescription.difficultLevel,
    isAllDay: lessonDescription.isAllDay,
    translations: lessonDescription.lessonDescriptionTranslations.map(translation => ({
      locale: translation.locale,
      name: translation.name,
      funFact: translation.funFact || '',
      highlights: translation.highlights || '',
      description: translation.description,
      specialNoteToUsers: translation.specialNoteToUsers || '',
      lessonPrerequisites: translation.lessonPrerequisites || '',
      checkInNotes: translation.checkInNotes || '',
      otherNotes: translation.otherNotes || '',
      targetUserGroups: translation.targetUserGroups || '',
    })),
    photos: getImageUrlToUploadFileInput(lessonDescription.photos),
  }
}

export const convertCompanyPaymentDetailToFormValues = (
  paymentDetail: IPrelaunchCompanyPaymentDetail,
): PrelaunchCompanyPaymentDetailFormValues => {
  return {
    companyName: paymentDetail.companyName,
    companyAddress: paymentDetail.companyAddress || '', // Provide a default empty string if undefined
    bankName: paymentDetail.bankName,
    bankAccountNumber: paymentDetail.bankAccountNumber,
    taxIdentificationNumber: paymentDetail.taxIdentificationNumber,
  }
}

export const getFormattedTags = (tagsWithCategory: ITagsWithCategory, tagIds: string): string[] => {
  const tagIdArray = tagIds.split(',').map(id => parseInt(id.trim()))
  const tagList = Object.values(tagsWithCategory).flat()

  const formattedTags = tagList
    .filter(tag => tagIdArray.includes(tag.id))
    .map(tag => `${tag.id} - ${tag.name}`)

  return formattedTags
}
