import { Button, Descriptions, Space, Tag } from 'antd'

import YesOrNoTag from '../../common/YesOrNoTag'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import { IOrganization } from '../../../pages/organization-container/share-type'
import { ITag } from '../../../pages/tags-container/share-type'
import LinkButton from '../../link-button/link-button'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import PrelaunchOrganizationFormsFormModal from '../../prelaunch-organization-forms/prelaunch-organization-forms-form-modal/prelaunch-organization-forms-form-modal'
import { IPrelaunchOrganizationForm } from '../../../pages/prelaunch-organization-forms-container/share-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RetweetOutlined } from '@ant-design/icons'
import CalculateBillingSummariesModal from '../calculate-billing-summaries-modal/calculate-billing-summaries-modal'

interface Iprops {
  organization: IOrganization
  showCreatePrelaunchOrganizationFormButton?: boolean
}
const OrganizationDescriptions = (props: Iprops) => {
  const { organization, showCreatePrelaunchOrganizationFormButton = true } = props
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)
  const [openCalculateBillingSummariesModal, setOpenCalculateBillingSummariesModal] =
    useState<boolean>(false)

  const toCreatePrelaunchOrganizationsForm = () => {
    setOpen(true)
  }

  const onPrelaunchOrganizationFormSubmit = (res: IPrelaunchOrganizationForm) => {
    setOpen(true)
    navigate(`/prelaunch-organization-forms/${res.id}`)
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title={translate('navBar.organizationsTitle')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
          extra={
            showCreatePrelaunchOrganizationFormButton && (
              <Space>
                <Button
                  type="primary"
                  icon={<RetweetOutlined />}
                  onClick={() => setOpenCalculateBillingSummariesModal(true)}
                >
                  刷新账单统计
                </Button>
                <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                  <Button onClick={toCreatePrelaunchOrganizationsForm} type="primary">
                    {translate('prelaunchOrganizationsForm.create')}
                  </Button>
                </AuthPermission>
              </Space>
            )
          }
        >
          <Descriptions.Item label="ID">
            <LinkButton to={`/organizations/${organization?.id}`}>{organization?.id}</LinkButton>
          </Descriptions.Item>
          <Descriptions.Item label={translate('organizationTranslation.name')}>
            {organization?.organizationTranslations.length
              ? organization?.organizationTranslations[0]?.name
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.phoneNumber')}>
            {organization?.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.email')}>
            {organization?.email}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.bookBefore')}>
            {organization?.bookBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.cancelBefore')}>
            {organization?.cancelBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.lateBefore')}>
            {organization?.lateBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.maxCapacity')}>
            {organization?.maxCapacity}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.startBookable')}>
            {organization?.startBookable}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.cityId')}>
            {organization?.cityId}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.liteBookingLimit')}>
            {organization?.liteBookingLimit}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.attendanceMarkingRequired')}>
            <YesOrNoTag value={organization?.attendanceMarkingRequired} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.maxCapacityCeiling')}>
            {organization?.maxCapacityCeiling}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.allowSlotAvailabilityNotification')}>
            <YesOrNoTag value={organization?.allowSlotAvailabilityNotification} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.label')}>
            {organization?.label}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.showClassRatings')}>
            <YesOrNoTag value={organization?.showClassRatings} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.integratorLessonDescriptionsSync')}>
            <YesOrNoTag value={organization?.integratorLessonDescriptionsSync} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.integratorQrCodeCheckIn')}>
            <YesOrNoTag value={organization?.integratorQrCodeCheckIn} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.isUserRealNameRequired')}>
            <YesOrNoTag value={organization?.isUserRealNameRequired} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.isInternalPreviewOnly')}>
            <YesOrNoTag value={organization?.isInternalPreviewOnly} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.tier')}>
            {organization?.tier}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.accountManager')}>
            <LinkButton
              style={{ padding: 0, textAlign: 'left' }}
              to={`/admin-users/${organization?.accountManager?.id}`}
            >
              {organization?.accountManager?.email}
            </LinkButton>
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(organization?.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(organization?.updatedAt)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={translate('taggings')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          {organization.taggings &&
            Object.entries(organization.taggings).map(([key, value]) => (
              <Descriptions.Item label={key} key={key}>
                {value.map((tag: ITag, index: number) => {
                  return (
                    <Tag key={index} color="blue">
                      {tag.name}
                    </Tag>
                  )
                })}
              </Descriptions.Item>
            ))}
        </Descriptions>

        <Descriptions
          title={translate('organization.picturesAndBanners')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label={translate('organization.profileFileName')}>
            {organization?.profileFileName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.profileContentType')}>
            {organization?.profileContentType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.profileFileSize')}>
            {organization?.profileFileSize}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.profileUpdatedAt')}>
            {organization?.profileUpdatedAt ? getDateTime(organization?.profileUpdatedAt) : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.bannerFileName')}>
            {organization?.bannerFileName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.bannerContentType')}>
            {organization?.bannerContentType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.bannerFileSize')}>
            {organization?.bannerFileSize}
          </Descriptions.Item>
          <Descriptions.Item label={translate('organization.bannerUpdatedAt')}>
            {organization?.bannerUpdatedAt ? getDateTime(organization?.bannerUpdatedAt) : 'N/A'}
          </Descriptions.Item>
        </Descriptions>

        {organization.organizationTranslations.map((organizationTranslation, index) => (
          <Descriptions
            title={`${translate('organization.organizationTranslation')} - ${organizationTranslation.locale}`}
            labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
            contentStyle={{ minWidth: 300 }}
            column={2}
            bordered
            key={index}
          >
            <Descriptions.Item label={translate('organizationTranslation.name')}>
              {organizationTranslation.name}
            </Descriptions.Item>
            <Descriptions.Item label={translate('organizationTranslation.romanizedName')}>
              {organizationTranslation.romanizedName}
            </Descriptions.Item>
            <Descriptions.Item label={translate('organizationTranslation.blurb')}>
              {organizationTranslation.blurb}
            </Descriptions.Item>
            <Descriptions.Item label={translate('organizationTranslation.description')}>
              {organizationTranslation.description}
            </Descriptions.Item>
            <Descriptions.Item label={translate('organizationTranslation.specialNoteToUsers')}>
              {organizationTranslation.specialNoteToUsers}
            </Descriptions.Item>
          </Descriptions>
        ))}

        <Descriptions
          title={translate('companyPaymentDetail')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label={translate('paymentDetail.companyName')}>
            {organization?.companyPaymentDetail?.companyName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('paymentDetail.companyAddress')}>
            {organization?.companyPaymentDetail?.companyAddress}
          </Descriptions.Item>
          <Descriptions.Item label={translate('paymentDetail.taxIdentificationNumber')}>
            {organization?.companyPaymentDetail?.taxIdentificationNumber}
          </Descriptions.Item>
          <Descriptions.Item label={translate('paymentDetail.bankName')}>
            {organization?.companyPaymentDetail?.bankName}
          </Descriptions.Item>

          <Descriptions.Item label={translate('paymentDetail.bankAccountNumber')}>
            {organization?.companyPaymentDetail?.bankAccountNumber}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(organization?.companyPaymentDetail?.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(organization?.companyPaymentDetail?.updatedAt)}
          </Descriptions.Item>
        </Descriptions>
      </Space>

      <PrelaunchOrganizationFormsFormModal
        organizationId={organization.id}
        open={open}
        handleCancel={() => setOpen(false)}
        handleSubmit={onPrelaunchOrganizationFormSubmit}
      />

      <CalculateBillingSummariesModal
        organizationId={organization.id}
        open={openCalculateBillingSummariesModal}
        handleCancel={() => setOpenCalculateBillingSummariesModal(false)}
        handleOk={() => setOpenCalculateBillingSummariesModal(false)}
      />
    </>
  )
}

export default OrganizationDescriptions
