export enum TagSubCategoryEnums {
  GROUP_CLASSES = 'GROUP_CLASSES',
  OPEN_GYM = 'OPEN_GYM',
  HOTELS = 'HOTELS',
  BALL_GAMES = 'BALL_GAMES',
  CLIMBING = 'CLIMBING',
  OTHERS = 'OTHERS',
  PERSON_TRAINING = 'PERSON_TRAINING',
  REHABILITATION = 'REHABILITATION',
  SPA = 'SPA',
  BEAUTY = 'BEAUTY',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
}

export enum TagCategoryEnums {
  FITNESS = 'FITNESS',
  LIFE = 'LIFE',
  AMENITY = 'AMENITY',
  LABEL = 'LABEL',
  PRIMARY_CATEGORY = 'PRIMARY_CATEGORY',
}

export enum TaggableTypeEnums {
  LESSON_DESCRIPTION = 'LESSON_DESCRIPTION',
  STUDIO = 'STUDIO',
  ORGANIZATION = 'ORGANIZATION',
}

export enum TaggingContextEnums {
  ACTIVITIES = 'ACTIVITIES',
  AMENITIES = 'AMENITIES',
  LABELS = 'LABELS',
  PRIMARY_CATEGORY = 'PRIMARY_CATEGORY',
}

export class TagCategory {
  constructor(
    public name: TagCategoryEnums,
    public context: TaggingContextEnums,
    public subCategories: TagSubCategoryEnums[],
  ) {}

  static readonly FITNESS = new TagCategory(
    TagCategoryEnums.FITNESS,
    TaggingContextEnums.ACTIVITIES,
    [
      TagSubCategoryEnums.GROUP_CLASSES,
      TagSubCategoryEnums.OPEN_GYM,
      TagSubCategoryEnums.HOTELS,
      TagSubCategoryEnums.BALL_GAMES,
      TagSubCategoryEnums.CLIMBING,
      TagSubCategoryEnums.OTHERS,
      TagSubCategoryEnums.PERSON_TRAINING,
      TagSubCategoryEnums.REHABILITATION,
    ],
  )

  static readonly LIFE = new TagCategory(TagCategoryEnums.LIFE, TaggingContextEnums.ACTIVITIES, [
    TagSubCategoryEnums.SPA,
    TagSubCategoryEnums.BEAUTY,
    TagSubCategoryEnums.FOOD_AND_BEVERAGE,
    TagSubCategoryEnums.OTHERS,
  ])

  static readonly AMENITY = new TagCategory(
    TagCategoryEnums.AMENITY,
    TaggingContextEnums.AMENITIES,
    [],
  )
  static readonly LABEL = new TagCategory(TagCategoryEnums.LABEL, TaggingContextEnums.LABELS, [])
  static readonly PRIMARY_CATEGORY = new TagCategory(
    TagCategoryEnums.PRIMARY_CATEGORY,
    TaggingContextEnums.PRIMARY_CATEGORY,
    [],
  )
}

export type TagActivityCategoryEnums = TagCategoryEnums.FITNESS | TagCategoryEnums.LIFE

const activityCategoryNames = Object.values(TagCategory)
  .filter(category => category.context === TaggingContextEnums.ACTIVITIES)
  .map(category => category.name)

export const TagCategoryOptions = Object.values(TagCategoryEnums).map(value => ({
  label: value,
  value: value,
}))

export const TagActivityCategoryOptions = Object.values(activityCategoryNames).map(value => ({
  label: value,
  value: value,
}))

export const TagSubCategoryOptions = Object.values(TagSubCategoryEnums).map(value => ({
  label: value,
  value,
}))

export const TagCategoryWithSubCategoryOptions = Object.values(TagCategory).map(value => ({
  label: value.name,
  value: value.name,
  children: value.subCategories.map((subCategory: TagSubCategoryEnums) => ({
    label: subCategory,
    value: subCategory,
  })),
}))
