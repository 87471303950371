import { SET_ALL_CARD_STACKS } from './constants'
import { CardStacksAction } from './action'
import update from 'immutability-helper'
import { ICardStack } from '../../pages/card-stacks-container/share-type'

interface InitialState {
  allCardStacks: ICardStack[]
}

const initialState: InitialState = {
  allCardStacks: [],
}

function cardStacksReducer(state = initialState, action: CardStacksAction) {
  switch (action.type) {
    case SET_ALL_CARD_STACKS:
      return update(state, { $merge: { allCardStacks: action.payload } })
    default:
      return state
  }
}

export default cardStacksReducer
