import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import {
  IStudioIntegrationLessonConfig,
  IStudioIntegrationLessonDescriptionConfig,
  StudioIntegrationFieldsAutoSetRuleEnum,
  StudioIntegrationLessonConfigRequest,
} from '../pages/studio-integration-config-container/share-types'
import { TIME_FORMAT } from './constant'
import { keysToCamel, keysToSnake } from './keys-to-case'

dayjs.extend(customParseFormat)

export const isStudioIntegrationLessonConfig = (
  record: any,
): record is IStudioIntegrationLessonConfig => {
  return `studioIntegrationLessonFieldsAutoSetRules` in record
}

export const configToFormValues = (
  data: IStudioIntegrationLessonConfig | IStudioIntegrationLessonDescriptionConfig,
) => {
  const rules = isStudioIntegrationLessonConfig(data)
    ? data.studioIntegrationLessonFieldsAutoSetRules
    : data.studioIntegrationLessonDescriptionFieldsAutoSetRules
  return {
    fieldValue: data.fieldValue,
    fieldType: data.fieldType,
    isActive: data.isActive,
    rules: rules.map(ruleItem => {
      const rule = keysToCamel(ruleItem.rule)

      if (ruleItem.rule.type === StudioIntegrationFieldsAutoSetRuleEnum.TIME_RANGE_RULE) {
        return {
          type: rule.type,
          timeRanges: rule.timeRanges.map((time: { startTime: string; endTime: string }) => {
            return [dayjs(time.startTime, TIME_FORMAT), dayjs(time.endTime, TIME_FORMAT)]
          }),
        }
      } else {
        return rule
      }
    }),
  }
}

export const formValuesToRequest = (
  values: StudioIntegrationLessonConfigRequest,
  studioIntegrationConfigId: string,
) => {
  return {
    ...values,
    studioIntegrationConfigId,
    rules: values.rules.map(rule => {
      if (rule.type === StudioIntegrationFieldsAutoSetRuleEnum.TIME_RANGE_RULE && rule.timeRanges) {
        return keysToSnake({
          type: rule.type,
          timeRanges: rule.timeRanges.map(timeRange => {
            return {
              startTime: timeRange[0].format(TIME_FORMAT),
              endTime: timeRange[1].format(TIME_FORMAT),
            }
          }),
        })
      } else {
        return keysToSnake(rule)
      }
    }),
  }
}
