import { IPaginatedParams, PageInfo } from '../../share-types'

export type IOneTimeCodeBatchesFilterParams = {
  id?: string
  name?: string
  isNewUserOnly?: boolean
  startDate?: string
  endDate?: string
}

export type IPaginatedOneTimeCodeBatchesParams = IPaginatedParams & IOneTimeCodeBatchesFilterParams

export type IOneTimeCodeBatch = {
  id: number
  name: string
  credits: number
  createdAt: string
  updatedAt: string
  isNewUserOnly: boolean
  creditsValidityPeriodInDays: number
  usableWindowStartTime: null | string
  usableWindowEndTime: null | string
  ikeaSegmentId: null | number
  status: OneTimeCodeStatus
  numberOfCodesClaimed: number
  numberOfCodesCreated: number
}

export type PaginatedOneTimeCodeBatchesResponse = {
  pageInfo: PageInfo
  data: IOneTimeCodeBatch[]
}

export type OneTimeCodeBatchRequest = {
  prefix?: string
  name: string
  numberOfCodes?: number
  credits: number
  isNewUserOnly: boolean
  creditsValidityPeriodInDays: number
  usableWindowStartTime: null | any
  usableWindowEndTime: null | any
  ikeaSegmentId: null | string
  status: OneTimeCodeStatus
}

export type OneTimeCodeBatchFormValue = {
  prefix?: string
  name: string
  numberOfCodes?: number
  credits: number
  isNewUserOnly: boolean
  creditsValidityPeriodInDays: number
  timeRange: null | string
  ikeaSegmentId: null | string
  status: OneTimeCodeStatus
}

export enum OneTimeCodeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INTERNAL_ONLY = 'INTERNAL_ONLY',
}
