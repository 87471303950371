import qs from 'qs'

import config from '../config/index'
import {
  IPaginatedSegmentsParams,
  IPaginatedUserSegmentsParams,
  ISegment,
  PaginatedSegmentsResponse,
  PaginatedUserSegmentsResponse,
  SegmentRequest,
  SegmentTypeEnum,
} from '../pages/segments-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedSegments = (data: IPaginatedSegmentsParams) =>
  authRequest.get<MISSING_TYPE, PaginatedSegmentsResponse>(`${mainApi}/segments`, {
    params: data,
  })

export const getSegmentDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ISegment>(`${mainApi}/segments/${id}`)

export const createSegment = (data: SegmentRequest) =>
  authRequest.post<MISSING_TYPE, ISegment>(`${mainApi}/segments`, data)

export const editSegment = (id: number, data: SegmentRequest) =>
  authRequest.put<MISSING_TYPE, ISegment>(`${mainApi}/segments/${id}`, data)

export const getSegmentSuggestion = (keyword: string, type?: SegmentTypeEnum) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(`${mainApi}/segments/suggestions`, {
    params: {
      type,
      keyword,
    },
  })

export const getSegmentSuggestionsByIds = (ids: number[]) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/segments/suggestions-by-ids`,
    {
      params: {
        ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const getPaginatedUserSegmentsBySegmentId = (
  id: number,
  data: IPaginatedUserSegmentsParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedUserSegmentsResponse>(
    `${mainApi}/segments/${id}/user-segments`,
    {
      params: data,
    },
  )

export const userSegmentsBulkUpload = (id: number, data: FormData) =>
  authRequest.post(`${mainApi}/segments/${id}/users-bulk-upload`, data)

export const deleteUserSegments = (id: number) =>
  authRequest.delete(`${mainApi}/segments/user-segments/${id}`)
