import { Checkbox, Col, Divider, Row, Space } from 'antd'
import { IPublicPrelaunchOrganizationForm } from '../../../prelaunch-organization-forms-container/share-types'
import { useEffect, useState } from 'react'
import LinkButton from '../../../../components/link-button/link-button'
import { useParams } from 'react-router-dom'
import { getPublicPrelaunchOrganizationFormByUniqueId } from '../../../../api/prelaunchOrganizationFormsApi'
import PrelaunchStudios from '../../../../components/prelaunch-organizations/prelaunch-studios/prelaunch-studios'
import PrelaunchLessonDescriptions from '../../../../components/prelaunch-organizations/prelaunch-lesson-descriptions/prelaunch-lesson-descriptions'
import moment from 'moment'
import translate from '../../../../i18n'

const PrelaunchOrganizationForm = () => {
  const [prelaunchOrganizationForm, setPrelaunchOrganizationForm] =
    useState<IPublicPrelaunchOrganizationForm>()
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const params = useParams()
  const uniqueId = params?.uniqueId

  useEffect(() => {
    if (uniqueId) {
      getPublicPrelaunchOrganizationFormByUniqueId(uniqueId).then(response => {
        if (response) {
          setPrelaunchOrganizationForm(response)
          const now = new Date()
          setIsExpired(moment(response.expiredAt).isBefore(now))
        }
      })
    }
  }, [uniqueId])

  if (!prelaunchOrganizationForm) {
    return null
  }

  return (
    <>
      <Row gutter={16}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <h1>{prelaunchOrganizationForm.organizationName}</h1>
        </Col>
        {!isExpired && (
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Space wrap>
              <LinkButton
                to={`/public/prelaunch-organization-form/${prelaunchOrganizationForm.uniqueId}/studios/${prelaunchOrganizationForm.organizationName}`}
                type="primary"
              >
                {translate('addStudios')}
              </LinkButton>
              <LinkButton
                to={`/public/prelaunch-organization-form/${prelaunchOrganizationForm.uniqueId}/lesson-descriptions/${prelaunchOrganizationForm.organizationName}`}
                type="primary"
              >
                {translate('addLessonDescriptions')}
              </LinkButton>
              {!prelaunchOrganizationForm.hasCompanyPaymentDetail && (
                <LinkButton
                  to={`/public/prelaunch-organization-form/${prelaunchOrganizationForm.uniqueId}/payment-details/${prelaunchOrganizationForm.organizationName}`}
                  type="primary"
                >
                  {translate('addCompanyPaymentDetail')}
                </LinkButton>
              )}
            </Space>
          </Col>
        )}
      </Row>

      <Divider />

      <Space className="table-space" direction="vertical" size={16}>
        <Row align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
            <h2>{translate('studio')}</h2>
          </Col>
        </Row>

        <PrelaunchStudios
          isExpired={isExpired}
          uniqueId={prelaunchOrganizationForm.uniqueId}
          organizationName={prelaunchOrganizationForm.organizationName}
          data={prelaunchOrganizationForm.studios}
        />
      </Space>

      <Divider />

      <Space className="table-space" direction="vertical" size={16}>
        <Row align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
            <h2>{translate('lessonDescription')}</h2>
          </Col>
        </Row>

        <PrelaunchLessonDescriptions
          isExpired={isExpired}
          uniqueId={prelaunchOrganizationForm.uniqueId}
          organizationName={prelaunchOrganizationForm.organizationName}
          data={prelaunchOrganizationForm.lessonDescriptions}
        />
      </Space>
      <Divider />
      <Space className="table-space" direction="vertical" size={16}>
        <Row align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
            <h2>{translate('companyPaymentDetail')}</h2>
          </Col>
        </Row>
        <Checkbox checked={prelaunchOrganizationForm.hasCompanyPaymentDetail}>
          {translate('filledPaymentInfo')}
        </Checkbox>
      </Space>
    </>
  )
}

export default PrelaunchOrganizationForm
