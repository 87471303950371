import { notification, PageHeader, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import translate from '../../../../i18n'
import { PrelaunchStudioCreationFormValues } from '../../../prelaunch-organization-forms-container/share-types'
import {
  createPrelaunchStudios,
  getPrelaunchStudioDetail,
} from '../../../../api/prelaunchOrganizationFormsApi'
import StudioForm from '../../../../components/common/studio-form'
import { convertIPrelaunchStudioToFormValues } from '../../../../helpers/prelaunch-organization-helper'

const prelaunchStudioForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [uniqueId] = useState<string | undefined>(params?.uniqueId)
  const [organizationName] = useState<string | undefined>(params?.organizationName)
  const [studioId] = useState<string | undefined>(params?.studioId)
  const [prelaunchStudioFormData, setPrelaunchStudioFormData] =
    useState<PrelaunchStudioCreationFormValues>()

  useEffect(() => {
    if (studioId) {
      getPrelaunchStudioDetail(Number(studioId)).then(data => {
        setPrelaunchStudioFormData(convertIPrelaunchStudioToFormValues(data))
      })
    }
  }, [studioId])
  const onFinish = (value: PrelaunchStudioCreationFormValues) => {
    if (!uniqueId) {
      return
    }
    createPrelaunchStudios({
      ...value,
      uniqueId: uniqueId,
    })
      .then(response => {
        if (response) {
          notification.success({
            message: translate('addStudios.success'),
          })
          navigate(`/public/prelaunch-organization-form/${uniqueId}`)
        }
      })
      .catch(err => {
        notification.error({
          message: translate('addStudios.error'),
          description: err?.message || translate('networkError'),
        })
      })
  }
  const onBack = () => {
    navigate(-1)
  }

  if (!uniqueId) {
    return null
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <PageHeader
        className="site-page-header"
        onBack={onBack}
        title={organizationName}
        subTitle={translate('addStudios')}
      />
      <StudioForm
        isEdit={false}
        organizationUniqueId={uniqueId}
        onFinish={onFinish}
        formData={prelaunchStudioFormData}
      />
    </Space>
  )
}

export default prelaunchStudioForm
