import { Button, Form, Input } from 'antd'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import translate from '../../../i18n'
import DebounceSelect from '../../debounce-select/debounce-select'
import { useEffect } from 'react'
import { getOrganizationsSuggestion } from '../../../api/organizationsApi'
import { ISimpleOrganization } from '../../../share-types'

const DuplicateLessonDescriptionFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: any) => void
  formData: any
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  const onFinish = (values: any) => {
    onSearch(values)
  }

  async function fetchOrganizationsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getOrganizationsSuggestion(keyword).then(res => {
      return getOrganizationsSuggestionOptions(res)
    })
  }

  const getOrganizationsSuggestionOptions = (organizationsSuggestions: ISimpleOrganization[]) => {
    return organizationsSuggestions.map(item => {
      const name = item.organizationTranslations[0].name
      return {
        label: `${item.id}-${name}`,
        value: item.id,
      }
    })
  }

  return (
    <Form layout="inline" form={form} onFinish={onFinish}>
      <Form.Item name="organizationId" label={translate('lessonDescription.organizationId')}>
        <DebounceSelect
          allowClear
          showSearch={true}
          placeholder="可以通过id/name搜索organization"
          fetchOptions={fetchOrganizationsSuggestionOptions}
        />
      </Form.Item>

      <Form.Item name="name" label={translate('lessonDescription.name')}>
        <Input placeholder={translate('name.message')} />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default DuplicateLessonDescriptionFilter
