import { Card, Space } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { getBookingReviewRejectReasons } from '../../api/bookingReviewApi'
import NavBar from '../../components/nav-bar/nav-bar'
import translate from '../../i18n'
import { setRejectReasonsAction } from '../../store/global/action'

const BookingReviewContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()
  const { setRejectReasonsAction } = props

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/booking-reviews\/[0-9]+$/)) {
      return translate('navBar.viewBookingReview')
    }

    return translate('navBar.bookingReviewsTitle')
  }

  useEffect(() => {
    getBookingReviewRejectReasons().then(res => {
      setRejectReasonsAction(res)
    })
  }, [])

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setRejectReasonsAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(BookingReviewContainer)
