import config from '../config/index'
import { request } from '../request'
import { IBusinessDistrict } from '../share-types'

const { mainApi } = config

export const getBusinessDistrictsByCityId = (cityId: number) =>
  request.get<MISSING_TYPE, IBusinessDistrict[]>(`${mainApi}/business-districts`, {
    params: { cityId },
  })
