import { Button, Col, Row, Space, Table, Tag, notification } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import {
  IPaginatedSearchTrendsParams,
  ISearchFilterParams,
  ISearchTrend,
  SearchTrendableTypeEnums,
  SearchTrendStatusEnums,
} from '../share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchValues } from '../../../helpers/utils'
import { getPaginatedSearchTrends, updateSearchTrendsStatus } from '../../../api/searchTrendsApi'
import { SearchTrendableTypeOptions } from '../constant'
import { useSearchParams } from 'react-router-dom'
import * as _ from 'lodash'
import SearchTrendsFilter from '../../../components/search-trends/search-trends-filter/search-trends-filter'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import { ReduxStore } from '../../../store'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const SearchTrends = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale } = props
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchTrends, setSearchTrends] = useState<ISearchTrend[]>([])
  const [searchValues, setSearchValues] = useState<ISearchFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'cityId', isBoolean: false },
        { key: 'status', isBoolean: false },
        { key: 'trendableType', isBoolean: false },
        { key: 'generatedDate', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedsearchTrendsAction(data)
  }, [])

  const getPaginatedsearchTrendsAction = (data: IPaginatedSearchTrendsParams) => {
    getPaginatedSearchTrends(data).then(res => {
      if (res) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setSearchTrends(res.data)
      }
    })
  }

  const onSearch = (value: ISearchFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedsearchTrendsAction(data)
  }

  const getSearchParams = (data: any) => {
    return _.pickBy(data, item => item !== undefined)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    getPaginatedsearchTrendsAction(data)
  }

  const changeStatus = (record: ISearchTrend, status: SearchTrendStatusEnums) => {
    updateSearchTrendsStatus(record.id, status)
      .then(() => {
        const data = {
          size: pageInfo.pageSize,
          page: pageInfo.currentPage - 1,
          ...searchValues,
        }
        getPaginatedsearchTrendsAction(data)

        notification.success({
          message: translate('searchTrends.updateStatusSuccess'),
        })
      })
      .catch(err => {
        notification.error({
          message: translate('searchTrends.updateStatusError'),
          description: err?.message || translate('networkError'),
        })
      })
  }

  const columns: ColumnsType<ISearchTrend> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: ISearchTrend, b: ISearchTrend) => a.id - b.id,
    },
    {
      title: translate('searchTrends.city'),
      dataIndex: 'city',
      key: 'city',
      render: (city: { name: string }) => {
        return <>{city.name}</>
      },
    },
    {
      title: translate('searchTrends.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: SearchTrendStatusEnums) => {
        switch (status) {
          case SearchTrendStatusEnums.ACTIVE:
            return <Tag color="green">{status}</Tag>
          case SearchTrendStatusEnums.EXPIRED:
            return <Tag color="red">{status}</Tag>
          default:
            return <Tag color="blue">{status}</Tag>
        }
      },
    },
    {
      title: translate('searchTrends.trendableType'),
      dataIndex: 'trendableType',
      key: 'trendableType',
      render: (trendableType: SearchTrendableTypeEnums) => {
        const label = SearchTrendableTypeOptions.find(item => item.value === trendableType)?.label
        const color = trendableType === SearchTrendableTypeEnums.STUDIOS ? 'blue' : 'green'
        return <Tag color={color}>{label}</Tag>
      },
    },
    {
      title: translate('searchTrends.generatedDate'),
      dataIndex: 'generatedDate',
      key: 'generatedDate',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: ISearchTrend) => (
        <Space>
          {record.status === SearchTrendStatusEnums.ACTIVE && (
            <AuthPermission permission={PermissionsEnum.SEARCH_TRENDS_WRITE}>
              <Button
                type="link"
                block
                danger
                onClick={() => changeStatus(record, SearchTrendStatusEnums.EXPIRED)}
              >
                EXPIRED
              </Button>
            </AuthPermission>
          )}

          {record.status === SearchTrendStatusEnums.PENDING && (
            <>
              <AuthPermission permission={PermissionsEnum.SEARCH_TRENDS_WRITE}>
                <Button
                  type="link"
                  block
                  onClick={() => changeStatus(record, SearchTrendStatusEnums.ACTIVE)}
                >
                  ACTIVE
                </Button>
              </AuthPermission>

              <AuthPermission permission={PermissionsEnum.SEARCH_TRENDS_WRITE}>
                <Button
                  type="link"
                  danger
                  block
                  onClick={() => changeStatus(record, SearchTrendStatusEnums.EXPIRED)}
                >
                  EXPIRED
                </Button>
              </AuthPermission>
            </>
          )}

          <LinkButton to={`/search-trends/${record.id}`}>{translate('view')}</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.searchTrendsTitle')}</div>
        </Col>
      </Row>

      <Row className="filter-bar">
        <SearchTrendsFilter
          activeCities={activeCities}
          locale={locale}
          onSearch={onSearch}
          formData={searchValues}
        />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={searchTrends}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(SearchTrends)
