import { IPaginatedParams, PageInfo } from '../../share-types'

export interface ICardStacksFilterParams {
  name?: string
  rank?: number
  isActive?: boolean
  cityId?: number
  targetGroup?: string
  pageType?: number
}

export type IPaginatedCardStacksParams = IPaginatedParams & ICardStacksFilterParams

export enum TargetUserGroupEnum {
  EXISTING_USERS,
  NEW_USERS,
  ALL_USERS,
  TRIAL_USERS,
}

export enum CardStackPageTypeEnum {
  HOME,
  PAYMENT,
  STUDIO_LIST,
  LESSON_LIST,
}

export enum CardStackTypeEnum {
  CAROUSEL = 'carousel',
  GRID = 'grid',
  LISTABLE_BANNER = 'list banner',
}

export enum CardActionType {
  DEEP_LINK = 'DEEP_LINK',
  EXTERNAL = 'EXTERNAL',
}

export enum CardConfigTypeEnum {
  ADVERTISEMENT = 'advertisement',
  ICON = 'icon',
  LESSON_DESCRUPTION = 'lesson description',
  STUDIO = 'studio',
  STUDIO_LIST = 'studio list',
  UPCOMING_LESSONS = 'upcoming lessons',
  FREQUENTLY_BOOKED_LESSONS = 'frequently booked lessons',
  TITLE_SUBTITLE_ICON = 'title subtitle icon',
  COUPON_CARD = 'coupon card',
  FIRST_CLASS_DISCOUNTED_STUDIO_CARD = 'first class discounted studio card',
}

export enum LessonDescriptionContentStrategyEnum {
  PERSONALIZED_LESSON_DESCRIPTIONS = 'personalized lesson descriptions',
  RECOMMENDED_LESSON_DESCRIPTIONS = 'recommended lesson descriptions',
  FILTER = 'filterable lesson descriptions',
}

export enum StudioContentStrategyEnum {
  NEARBY_STUDIOS = 'nearby studios',
  RECOMMENDED_STUDIOS = 'recommended studios',
}

export enum StudioListContentStrategyEnum {
  NEW_STUDIOS = 'new studios',
  RECOMMENDED_STUDIOS = 'recommended studios',
}

export enum UpcomingLessonContentStrategyEnum {
  UPCOMING_LESSONS = 'upcoming lessons',
}

export enum FrequentlyBookedLessonsContentStrategyEnum {
  FREQUENTLY_BOOKED_LESSONS = 'frequently booked lessons',
}

export enum FirstClassDiscountedStudiosContentStrategyConfigEnum {
  FIRST_CLASS_DISCOUNTED_STUDIOS = 'first class discounted studios',
}

export type LocaleString = {
  locale: string
  value: string
}

export type Action = {
  type?: CardActionType
  url?: string
  text?: LocaleString | null
  textTranslations?: LocaleString[] | null
}

type CardStackConfigFilterContent = {
  locale: string
  text: string
  filter: string
}

export type CardStackConfigFilter = {
  content: CardStackConfigFilterContent
  contentTranslations: CardStackConfigFilterContent[]
  isDefault: boolean
}

export interface OutputCardStackConfig {
  '@class': string
  title: LocaleString | null
  titleTranslations: LocaleString[] | null
  action?: Action | null
  filters?: CardStackConfigFilter[] | null
  intervalInMs?: number
  numberOfRow?: number
  numberOfColumn?: number
  position?: number
}

export type ICardStack = {
  id: number
  name: string
  rank: number
  createdAt: string
  updatedAt: string
  isActive: boolean
  cityIds: string
  targetGroup: TargetUserGroupEnum
  config: OutputCardStackConfig
  pageType: CardStackPageTypeEnum
}

export type PaginatedCardStacksResponse = {
  pageInfo: PageInfo
  data: ICardStack[]
}

export interface ICardConfigsFilterParams {
  name?: string
  cardStackId?: number
  isActive?: boolean
}

export type CardConfig = {
  '@class': string
  rank: number
}

export type ICardConfig = {
  id: number
  name: string
  rank: number
  createdAt: string
  updatedAt: string
  isActive: boolean
  cardStack: ICardStack
  config: CardConfig
}

export type IPaginatedCardConfigsParams = {
  page: number
  size: number
} & ICardConfigsFilterParams

export type PaginatedCardConfigsResponse = {
  pageInfo: PageInfo
  data: ICardConfig[]
}
