import config from '../config/index'
import { IStudio } from '../pages/booking-reviews-container/share-type'
import { ILessonDescription } from '../pages/lesson-description-container/share-type'
import {
  IPaginatedIPrelaunchOrganizationFormsFilterParams,
  IPrelaunchCompanyPaymentDetail,
  IPrelaunchLessonDescription,
  IPrelaunchOrganizationForm,
  IPrelaunchStudio,
  IPublicPrelaunchOrganizationForm,
  IPublicPrelaunchStudioDto,
  PaginatedPrelaunchOrganizationFormsResponse,
  PrelaunchCompanyPaymentDetailConfirmRequest,
  PrelaunchCompanyPaymentDetailCreationRequest,
  PrelaunchCompanyPaymentDetailFormValues,
  PrelaunchLessonDescriptionCreationRequest,
  PrelaunchLessonDescriptionFormValues,
  PrelaunchLessonDescriptionsConfirmRequest,
  PrelaunchOrganizationCreateRequest,
  PrelaunchStudioConfirmRequest,
  PrelaunchStudioCreationFormValues,
  PrelaunchStudioCreationRequest,
} from '../pages/prelaunch-organization-forms-container/share-types'
import { authRequest, request } from '../request'

const { mainApi } = config

export const createPrelaunchOrganizationForms = (data: PrelaunchOrganizationCreateRequest) =>
  authRequest.post<MISSING_TYPE, IPrelaunchOrganizationForm>(
    `${mainApi}/prelaunch-organization-forms`,
    data,
  )

export const getPrelaunchOrganizationForm = (formId: number) =>
  authRequest.get<MISSING_TYPE, IPrelaunchOrganizationForm>(
    `${mainApi}/prelaunch-organization-forms/${formId}`,
  )

export const getPaginatedPrelaunchOrganizationForms = (
  data: IPaginatedIPrelaunchOrganizationFormsFilterParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedPrelaunchOrganizationFormsResponse>(
    `${mainApi}/prelaunch-organization-forms`,
    { params: data },
  )

export const getPublicPrelaunchOrganizationFormByUniqueId = (uniqueId: string) => {
  return request.get<MISSING_TYPE, IPublicPrelaunchOrganizationForm>(
    `${mainApi}/prelaunch-organization-forms/public/${uniqueId}`,
  )
}

export const createPrelaunchStudios = (data: PrelaunchStudioCreationRequest) =>
  request.post<MISSING_TYPE, IPublicPrelaunchStudioDto>(`${mainApi}/prelaunch-studios`, data)

export const createPrelaunchLessonDescriptions = (
  data: PrelaunchLessonDescriptionCreationRequest,
) => request.post(`${mainApi}/prelaunch-lesson-descriptions`, data)

export const createPrelaunchCompanyPaymentDetails = (
  data: PrelaunchCompanyPaymentDetailCreationRequest,
) => request.post(`${mainApi}/prelaunch-company-payment-details`, data)

export const getPrelaunchStudioDetail = (id: number | string) =>
  authRequest.get<MISSING_TYPE, IPrelaunchStudio>(`${mainApi}/prelaunch-studios/${id}`)

export const editPrelaunchStudioDetail = (
  id: number | string,
  data: PrelaunchStudioCreationFormValues,
) =>
  authRequest.put<MISSING_TYPE, IPublicPrelaunchStudioDto>(
    `${mainApi}/prelaunch-studios/${id}`,
    data,
  )

export const getPrelaunchLessonDescriptionDetail = (id: number | string) =>
  authRequest.get<MISSING_TYPE, IPrelaunchLessonDescription>(
    `${mainApi}/prelaunch-lesson-descriptions/${id}`,
  )

export const editPrelaunchLessonDescriptionDetail = (
  id: number | string,
  data: PrelaunchLessonDescriptionFormValues,
) =>
  authRequest.put<MISSING_TYPE, IPrelaunchLessonDescription>(
    `${mainApi}/prelaunch-lesson-descriptions/${id}`,
    data,
  )

export const getPrelaunchCompanyPaymentDetail = (id: number | string) =>
  authRequest.get<MISSING_TYPE, IPrelaunchCompanyPaymentDetail>(
    `${mainApi}/prelaunch-company-payment-details/${id}`,
  )

export const editPrelaunchCompanyPaymentDetail = (
  id: number | string,
  data: PrelaunchCompanyPaymentDetailFormValues,
) => authRequest.put<MISSING_TYPE, any>(`${mainApi}/prelaunch-company-payment-details/${id}`, data)

export const confirmPrelaunchStudios = (data: PrelaunchStudioConfirmRequest) =>
  authRequest.post<MISSING_TYPE, IStudio[]>(`${mainApi}/prelaunch-studios/confirm`, data)

export const confirmPrelaunchLessonDescriptions = (
  data: PrelaunchLessonDescriptionsConfirmRequest,
) =>
  authRequest.post<MISSING_TYPE, ILessonDescription[]>(
    `${mainApi}/prelaunch-lesson-descriptions/confirm`,
    data,
  )

export const confirmPrelaunchCompanyPaymentDetail = (
  data: PrelaunchCompanyPaymentDetailConfirmRequest,
) =>
  authRequest.post<MISSING_TYPE, any>(`${mainApi}/prelaunch-company-payment-details/confirm`, data)
