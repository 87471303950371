import { RouteObject } from 'react-router-dom'
import ChangeLanguage from '../pages/change-language/change-language'
import Container from '../pages/container/container'
import CouponTemplatesContainer from '../pages/coupon-templates-container'
import CouponTemplates from '../pages/coupon-templates-container/coupon-templates/coupon-templates'
import CouponTemplate from '../pages/coupon-templates-container/coupon-template/coupon-template'
import CouponTemplateForm from '../pages/coupon-templates-container/coupon-template-form/coupon-template-form'
import Home from '../pages/home/home'
import AdminRoleForm from '../pages/admin-roles-container/admin-role-form/admin-role-form'
import AdminRoles from '../pages/admin-roles-container/admin-roles/admin-roles'
import AdminUserForm from '../pages/admin-users-container/admin-user-form/admin-user-form'
import AdminUsers from '../pages/admin-users-container/admin-users/admin-users'
import AdminUser from '../pages/admin-users-container/admin-user/admin-user'
import AdminUsersContainer from '../pages/admin-users-container'
import AdminUserRolesContainer from '../pages/admin-roles-container'
import BookingReviewContainer from '../pages/booking-reviews-container'
import BookingReviews from '../pages/booking-reviews-container/booking-reviews/booking-reviews'
import BookingReview from '../pages/booking-reviews-container/booking-review/booking-review'
import CardStacksContainer from '../pages/card-stacks-container'
import CardStacks from '../pages/card-stacks-container/card-stacks/card-stacks'
import CardStack from '../pages/card-stacks-container/card-stack/card-stack'
import CardStackForm from '../pages/card-stacks-container/card-stack-form/card-stack-form'
import CardConfigsContainer from '../pages/card-configs-container'
import CardConfigs from '../pages/card-configs-container/card-configs/card-configs'
import CardConfigForm from '../pages/card-configs-container/card-config-form/card-config-form'
import CardConfig from '../pages/card-configs-container/card-config/card-config'
import CorporatesContainer from '../pages/corporates-container'
import Corporates from '../pages/corporates-container/corporates/corporates'
import CorporateForm from '../pages/corporates-container/corporate-form/corporate-form'
import Corporate from '../pages/corporates-container/corporate/corporate'
import SegmentsContainer from '../pages/segments-container'
import Segments from '../pages/segments-container/segments/segments'
import SegmentForm from '../pages/segments-container/segment-form/segment-form'
import Segment from '../pages/segments-container/segment/segment'
import SegmentRulesContainer from '../pages/segment-rules-container'
import SegmentRules from '../pages/segment-rules-container/segment-rules/segment-rules'
import SegmentRuleForm from '../pages/segment-rules-container/segment-rule-form/segment-rule-form'
import SegmentRule from '../pages/segment-rules-container/segment-rule/segment-rule'
import ReferralCampaignContainer from '../pages/referral-campaign-container'
import ReferralCampaigns from '../pages/referral-campaign-container/referral-campaigns/referral-campaigns'
import ReferralCampaign from '../pages/referral-campaign-container/referral-campaign/referral-campaign'
import ReferralCampaignForm from '../pages/referral-campaign-container/referral-campaign-form/referral-campaign-form'
import { PermissionsEnum } from '../share-types'
import UserSegments from '../pages/segments-container/user-segments/user-segments'
import UserSegmentsForm from '../pages/segments-container/user-segments-form/user-segments-form'

import StudioLeaderboardsContainer from '../pages/studio-leaderboards-container'
import StudioLeaderboard from '../pages/studio-leaderboards-container/studio-leaderboard/studio-leaderboard'
import StudioLeaderboards from '../pages/studio-leaderboards-container/studio-leaderboards/studio-leaderboards'
import StudioLeaderboardForm from '../pages/studio-leaderboards-container/studio-leaderboard-form/studio-leaderboard-form'
import StudioLeaderboardCategoriesContainer from '../pages/studio-leaderboard-categories-container'
import StudioLeaderboardCategories from '../pages/studio-leaderboard-categories-container/studio-leaderboard-categories/studio-leaderboard-categories'
import StudioLeaderboardCategory from '../pages/studio-leaderboard-categories-container/studio-leaderboard-category/studio-leaderboard-category'
import StudioLeaderboardCategoryForm from '../pages/studio-leaderboard-categories-container/studio-leaderboard-category-form/studio-leaderboard-category-form'
import SearchTrendsContainer from '../pages/search-trends-container'
import SearchTrends from '../pages/search-trends-container/search-trends/search-trends'
import SearchTrend from '../pages/search-trends-container/search-trend/search-trend'
import LessonCreditPricingsContainer from '../pages/lesson-credit-pricings-container'
import LessonCreditPricings from '../pages/lesson-credit-pricings-container/lesson-credit-pricings/lesson-credit-pricings'
import CorporateCreditsHistories from '../pages/corporates-container/corporate-credits-histories/corporate-credits-histories'
import TaskReward from '../pages/task-rewards-container/task-reward/task-reward'
import TaskRewardForm from '../pages/task-rewards-container/task-reward-form/task-reward-form'
import TaskRewards from '../pages/task-rewards-container/task-rewards/task-rewards'
import TaskRewardsContainer from '../pages/task-rewards-container'
import FeatureFlag from '../pages/feature-flag-container/feature-flag/feature-flag'
import FeatureFlags from '../pages/feature-flag-container/feature-flags/feature-flags'
import FeatureFlagForm from '../pages/feature-flag-container/feature-flag-form/feature-flag-form'
import FeatureFlagContainer from '../pages/feature-flag-container'
import WeChatURLLinksContainer from '../pages/wechat-url-links-container'
import WeChatURLLinks from '../pages/wechat-url-links-container/wechat-url-links/wechat-url-links'
import ShortURLsContainer from '../pages/short-urls'
import ShortURLs from '../pages/short-urls/short-urls/short-urls'
import OneTimeCodeBatchesContainer from '../pages/one-time-code-batches-container'
import OneTimeCodeBatchForm from '../pages/one-time-code-batches-container/one-time-code-batch-form/one-time-code-batch-form'
import OneTimeCodeBatch from '../pages/one-time-code-batches-container/one-time-code-batch/one-time-code-batch'
import OneTimeCodeBatches from '../pages/one-time-code-batches-container/one-time-code-batches/one-time-code-batches'
import OneTimeCodesContainer from '../pages/one-time-codes-container'
import OneTimeCodes from '../pages/one-time-codes-container/one-time-codes/one-time-code'
import TagsContainer from '../pages/tags-container'
import Tags from '../pages/tags-container/tags/tags'
import Tag from '../pages/tags-container/tag/tag'
import TagForm from '../pages/tags-container/tag-form/tag-form'
import LessonDescriptions from '../pages/lesson-description-container/lesson-descriptions/lesson-descriptions'
import LessonDescriptionsContainer from '../pages/lesson-description-container'
import LessonDescription from '../pages/lesson-description-container/lesson-description/lesson-description'
import OrganizationContainer from '../pages/organization-container'
import Organizations from '../pages/organization-container/organizations/organizations'
import Organization from '../pages/organization-container/organization/organization'
import StudiosContainer from '../pages/studios-container'
import Studios from '../pages/studios-container/studios/studios'
import Studio from '../pages/studios-container/studio/studio'
import StudioIntegrationConfigContainer from '../pages/studio-integration-config-container'
import StudioIntegrationConfigs from '../pages/studio-integration-config-container/studio-integration-configs/studio-integration-configs'
import StudioIntegrationConfig from '../pages/studio-integration-config-container/studio-integration-config/studio-integration-config'
import StudioIntegrationLessonConfigForm from '../pages/studio-integration-config-container/studio-integration-lesson-config-form/studio-integration-lesson-config-form'
import StudioIntegrationLessonConfig from '../pages/studio-integration-config-container/studio-integration-lesson-config/studio-integration-lesson-config'
import StudioIntegrationLessonDescConfigForm from '../pages/studio-integration-config-container/studio-integration-lesson-desc-config-form/studio-integration-lesson-desc-config-form'
import StudioIntegrationLessonDescConfig from '../pages/studio-integration-config-container/studio-integration-lesson-desc-config/studio-integration-lesson-desc-config'
import PrelaunchOrganizationFormsContainer from '../pages/prelaunch-organization-forms-container'
import PrelaunchOrganizationForms from '../pages/prelaunch-organization-forms-container/prelaunch-organization-forms/prelaunch-organization-forms'
import PrelaunchOrganizationForm from '../pages/prelaunch-organization-forms-container/prelaunch-organization-form/prelaunch-organization-form'
import PrelaunchStudio from '../pages/prelaunch-organization-forms-container/prelaunch-studio/prelaunch-studio'
import PrelaunchLessonDescription from '../pages/prelaunch-organization-forms-container/prelaunch-lesson-description/prelaunch-lesson-description'
import PrelaunchStudioForm from '../pages/prelaunch-organization-forms-container/prelaunch-studio-form/prelaunch-studio-form'
import PrelaunchLessonDescriptionForm from '../pages/prelaunch-organization-forms-container/prelaunch-lesson-description-form/prelaunch-lesson-description-form'
import PrelaunchCompanyPaymentDetailForm from '../pages/prelaunch-organization-forms-container/prelaunch-company-payment-detail-form/prelaunch-company-payment-detail-form'
import FirstClassDiscountDisabledLessonsContainer from '../pages/first-class-discount-disabled-lessons-container'
import FirstClassDiscountDisabledLessons from '../pages/first-class-discount-disabled-lessons-container/first-class-discount-disabled-lessons/first-class-discount-disabled-lessons'
import FirstClassDiscountDisabledLessonForm from '../pages/first-class-discount-disabled-lessons-container/first-class-discount-disabled-lesson-form/first-class-discount-disabled-lesson-form'
import CorporateCreditsDistributionSchedule from '../pages/corporates-container/corporate-credits-distribution-schedule/corporate-credits-distribution-schedule'
import CorporateCreditsDistributionScheduleForm from '../pages/corporates-container/corporate-credits-distribution-schedule-form/corporate-credits-distribution-schedule-form'
import LessonCreditAdjustmentsContainer from '../pages/lesson-credit-adjustments-container'
import LessonCreditAdjustmentsForm from '../pages/lesson-credit-adjustments-container/lesson-credit-adjustments-form/lesson-credit-adjustments-form'
import LessonCreditAdjustments from '../pages/lesson-credit-adjustments-container/lesson-credit-adjustments/lesson-credit-adjustments'
import LessonCreditAdjustment from '../pages/lesson-credit-adjustments-container/lesson-credit-adjustment/lesson-credit-adjustment'
import BillingInvoices from '../pages/billing-invoices-container/billing-invoices/billing-invoices'
import BillingInvoicesContainer from '../pages/billing-invoices-container'
import ExchangeRatesOrganizations from '../pages/exchange-rates-container/exchange-rates-organizations/exchange-rates-organizations'
import ExchangeRatesStudios from '../pages/exchange-rates-container/exchange-rates-studios/exchange-rates-studios'
import ExchangeRatesLessonDescriptions from '../pages/exchange-rates-container/exchange-rates-lesson-descriptions/exchange-rates-lesson-descriptions'
import ExchangeRatesContainer from '../pages/exchange-rates-container'

export type IRoute = Omit<RouteObject, 'children'> & {
  backUrl: string
  permission?: string
  auth: boolean
  children?: IRoute[]
}

const AdminRoutes: IRoute[] = [
  {
    path: '/',
    element: <Container />,
    backUrl: '/login',
    auth: true,
    children: [
      {
        backUrl: '/login',
        index: true,
        auth: true,
        element: <Home />,
      },
      {
        path: 'coupon-templates',
        element: <CouponTemplatesContainer />,
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.COUPON_TEMPLATES_READ,
        children: [
          {
            backUrl: '/login',
            auth: true,
            index: true,
            permission: PermissionsEnum.COUPON_TEMPLATES_READ,
            element: <CouponTemplates />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: 'new',
            permission: PermissionsEnum.COUPON_TEMPLATES_WRITE,
            element: <CouponTemplateForm />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: ':id/edit',
            permission: PermissionsEnum.COUPON_TEMPLATES_WRITE,
            element: <CouponTemplateForm />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: ':id/duplicate',
            permission: PermissionsEnum.COUPON_TEMPLATES_WRITE,
            element: <CouponTemplateForm />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: ':id',
            permission: PermissionsEnum.COUPON_TEMPLATES_READ,
            element: <CouponTemplate />,
          },
        ],
      },
      {
        path: 'admin-users',
        backUrl: '/login',
        permission: PermissionsEnum.ADMIN_USERS_READ,
        auth: true,
        element: <AdminUsersContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_USERS_READ,
            element: <AdminUsers />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_USERS_WRITE,
            element: <AdminUserForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_USERS_WRITE,
            element: <AdminUserForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_USERS_READ,
            element: <AdminUser />,
          },
        ],
      },
      {
        path: 'admin-roles',
        backUrl: '/login',
        auth: true,
        element: <AdminUserRolesContainer />,
        permission: PermissionsEnum.ADMIN_ROLE_READ,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_ROLE_READ,
            element: <AdminRoles />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_ROLE_WRITE,
            element: <AdminRoleForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ADMIN_ROLE_WRITE,
            element: <AdminRoleForm />,
          },
        ],
      },
      {
        path: 'booking-reviews',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.BOOKING_REVIEWS_READ,
        element: <BookingReviewContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.BOOKING_REVIEWS_READ,
            element: <BookingReviews />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.BOOKING_REVIEWS_READ,
            element: <BookingReview />,
          },
        ],
      },
      {
        path: 'card-stacks',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.CARD_STACKS_READ,
        element: <CardStacksContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_STACKS_READ,
            element: <CardStacks />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_STACKS_READ,
            element: <CardStack />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_STACKS_WRITE,
            element: <CardStackForm />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_STACKS_WRITE,
            element: <CardStackForm />,
          },
        ],
      },
      {
        path: 'card-configs',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.CARD_CONFIGS_READ,
        element: <CardConfigsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_CONFIGS_READ,
            element: <CardConfigs />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_CONFIGS_READ,
            element: <CardConfig />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_CONFIGS_WRITE,
            element: <CardConfigForm />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CARD_CONFIGS_WRITE,
            element: <CardConfigForm />,
          },
        ],
      },
      {
        path: 'corporates',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.CORPORATES_READ,
        element: <CorporatesContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CORPORATES_READ,
            element: <Corporates />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CORPORATES_READ,
            element: <Corporate />,
          },
          {
            path: ':id/credits/histories',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CORPORATES_READ,
            element: <CorporateCreditsHistories />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: 'corporates:write',
            element: <CorporateForm />,
          },
          {
            path: ':id/credits-distribution-schedules/:creditsDistributionScheduleId/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_WRITE,
            element: <CorporateCreditsDistributionScheduleForm />,
          },
          {
            path: ':id/credits-distribution-schedules/:creditsDistributionScheduleId',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_READ,
            element: <CorporateCreditsDistributionSchedule />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: 'corporates:write',
            element: <CorporateForm />,
          },
        ],
      },
      {
        path: 'referral-campaigns',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.REFERRAL_CAMPAIGNS_READ,
        element: <ReferralCampaignContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.REFERRAL_CAMPAIGNS_READ,
            element: <ReferralCampaigns />,
          },
          {
            path: ':id',
            backUrl: '/referral-campaigns',
            auth: true,
            permission: PermissionsEnum.REFERRAL_CAMPAIGNS_READ,
            element: <ReferralCampaign />,
          },
          {
            path: ':id/edit',
            backUrl: '/referral-campaigns',
            auth: true,
            permission: PermissionsEnum.REFERRAL_CAMPAIGNS_WRITE,
            element: <ReferralCampaignForm />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.REFERRAL_CAMPAIGNS_WRITE,
            element: <ReferralCampaignForm />,
          },
        ],
      },
      {
        path: 'segments',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.SEGMENTS_READ,
        element: <SegmentsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENTS_READ,
            element: <Segments />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENTS_WRITE,
            element: <SegmentForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENTS_WRITE,
            element: <SegmentForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENTS_READ,
            element: <Segment />,
            children: [
              {
                index: true,
                backUrl: '/login',
                auth: true,
                permission: PermissionsEnum.USER_SEGMENTS_READ,
                element: <UserSegments />,
              },
              {
                path: 'user-segments/new',
                backUrl: '/login',
                auth: true,
                permission: PermissionsEnum.USER_SEGMENTS_WRITE,
                element: <UserSegmentsForm />,
              },
            ],
          },
        ],
      },
      {
        path: 'segment-rules',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.SEGMENT_RULES_READ,
        element: <SegmentRulesContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENT_RULES_READ,
            element: <SegmentRules />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENT_RULES_WRITE,
            element: <SegmentRuleForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENT_RULES_WRITE,
            element: <SegmentRuleForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEGMENT_RULES_READ,
            element: <SegmentRule />,
          },
        ],
      },
      {
        path: 'studio-leaderboards',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.STUDIO_LEADERBOARDS_READ,
        element: <StudioLeaderboardsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARDS_READ,
            element: <StudioLeaderboards />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARDS_WRITE,
            element: <StudioLeaderboardForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARDS_WRITE,
            element: <StudioLeaderboardForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARDS_READ,
            element: <StudioLeaderboard />,
          },
        ],
      },
      {
        path: 'studio-leaderboard-categories',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_READ,
        element: <StudioLeaderboardCategoriesContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_READ,
            element: <StudioLeaderboardCategories />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_WRITE,
            element: <StudioLeaderboardCategoryForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_WRITE,
            element: <StudioLeaderboardCategoryForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_READ,
            element: <StudioLeaderboardCategory />,
          },
        ],
      },
      {
        path: 'search-trends',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.SEARCH_TRENDS_READ,
        element: <SearchTrendsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEARCH_TRENDS_READ,
            element: <SearchTrends />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SEARCH_TRENDS_WRITE,
            element: <SearchTrend />,
          },
        ],
      },
      {
        backUrl: '/lesson-credit-pricings',
        auth: true,
        path: 'lesson-credit-pricings',
        element: <LessonCreditPricingsContainer />,
        permission: PermissionsEnum.LESSON_CREDIT_PRICINGS_READ,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.LESSON_CREDIT_PRICINGS_READ,
            element: <LessonCreditPricings />,
          },
        ],
      },
      {
        backUrl: '/login',
        auth: true,
        path: '/exchange-rates/organizations',
        element: <ExchangeRatesContainer />,
        permission: PermissionsEnum.EXCHANGE_RATE_READ,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.EXCHANGE_RATE_READ,
            element: <ExchangeRatesOrganizations />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: ':id/studios',
            permission: PermissionsEnum.EXCHANGE_RATE_READ,
            element: <ExchangeRatesStudios />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: ':organizationId/studios/:studioId/lesson-descriptions',
            permission: PermissionsEnum.EXCHANGE_RATE_READ,
            element: <ExchangeRatesLessonDescriptions />,
          },
        ],
      },
      {
        path: 'task-rewards',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.TASK_REWARDS_READ,
        element: <TaskRewardsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TASK_REWARDS_READ,
            element: <TaskRewards />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TASK_REWARDS_WRITE,
            element: <TaskRewardForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TASK_REWARDS_WRITE,
            element: <TaskRewardForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TASK_REWARDS_READ,
            element: <TaskReward />,
          },
        ],
      },
      {
        path: 'feature-flags',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.FEATURE_FLAGS_READ,
        element: <FeatureFlagContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.FEATURE_FLAGS_WRITE,
            element: <FeatureFlags />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.FEATURE_FLAGS_WRITE,
            element: <FeatureFlagForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.FEATURE_FLAGS_READ,
            element: <FeatureFlag />,
          },
        ],
      },
      {
        path: 'wechat-url-links',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.WECHAT_URL_LINKS_READ,
        element: <WeChatURLLinksContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.WECHAT_URL_LINKS_READ,
            element: <WeChatURLLinks />,
          },
        ],
      },
      {
        path: 'short-urls',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.SHORT_URLS_READ,
        element: <ShortURLsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.SHORT_URLS_READ,
            element: <ShortURLs />,
          },
        ],
      },
      {
        path: 'one-time-codes',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.ONE_TIME_CODES_READ,
        element: <OneTimeCodesContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ONE_TIME_CODES_READ,
            element: <OneTimeCodes />,
          },
        ],
      },
      {
        path: 'one-time-code-batches',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.ONE_TIME_CODES_READ,
        element: <OneTimeCodeBatchesContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ONE_TIME_CODES_READ,
            element: <OneTimeCodeBatches />,
          },
          {
            backUrl: '/login',
            auth: true,
            path: 'new',
            permission: PermissionsEnum.ONE_TIME_CODES_WRITE,
            element: <OneTimeCodeBatchForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ONE_TIME_CODES_WRITE,
            element: <OneTimeCodeBatchForm />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.ONE_TIME_CODES_READ,
            element: <OneTimeCodeBatch />,
          },
        ],
      },
      {
        path: 'lesson-descriptions',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.LESSON_DESCRIPTIONS_READ,
        element: <LessonDescriptionsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.LESSON_DESCRIPTIONS_READ,
            element: <LessonDescriptions />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.LESSON_DESCRIPTIONS_READ,
            element: <LessonDescription />,
          },
        ],
      },
      {
        path: 'organizations',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.STUDIOS_READ,
        element: <OrganizationContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIOS_READ,
            element: <Organizations />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIOS_READ,
            element: <Organization />,
          },
        ],
      },
      {
        path: 'tags',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.TAGS_READ,
        element: <TagsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TAGS_READ,
            element: <Tags />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TAGS_READ,
            element: <Tag />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TAGS_WRITE,
            element: <TagForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.TAGS_WRITE,
            element: <TagForm />,
          },
        ],
      },
      {
        path: 'studios',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.STUDIOS_READ,
        element: <StudiosContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIOS_READ,
            element: <Studios />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIOS_READ,
            element: <Studio />,
          },
        ],
      },
      {
        path: 'studio-integration-config',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.STUDIO_INTEGRATION_READ,
        element: <StudioIntegrationConfigContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_READ,
            element: <StudioIntegrationConfigs />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_READ,
            element: <StudioIntegrationConfig />,
          },
          {
            path: ':id/lesson-config/new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_WRITE,
            element: <StudioIntegrationLessonConfigForm />,
          },
          {
            path: ':id/lesson-config/:lessonConfigId/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_WRITE,
            element: <StudioIntegrationLessonConfigForm />,
          },
          {
            path: ':id/lesson-config/:lessonConfigId',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_READ,
            element: <StudioIntegrationLessonConfig />,
          },
          {
            path: ':id/lesson-description-config/new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_WRITE,
            element: <StudioIntegrationLessonDescConfigForm />,
          },
          {
            path: ':id/lesson-description-config/:lessonDescConfigId/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_WRITE,
            element: <StudioIntegrationLessonDescConfigForm />,
          },
          {
            path: ':id/lesson-description-config/:lessonDescConfigId',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.STUDIO_INTEGRATION_READ,
            element: <StudioIntegrationLessonDescConfig />,
          },
        ],
      },
      {
        path: 'lesson-credit-adjustments',
        backUrl: '/login',
        auth: true,
        permission: PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_READ,
        element: <LessonCreditAdjustmentsContainer />,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_READ,
            element: <LessonCreditAdjustments />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_READ,
            element: <LessonCreditAdjustment />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_WRITE,
            element: <LessonCreditAdjustmentsForm />,
          },
        ],
      },
      {
        backUrl: '/login',
        auth: true,
        path: 'prelaunch-organization-forms',
        element: <PrelaunchOrganizationFormsContainer />,
        permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ,
            element: <PrelaunchOrganizationForms />,
          },
          {
            path: ':id',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ,
            element: <PrelaunchOrganizationForm />,
          },
          {
            path: ':id/studios/:prelaunchStudioId/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE,
            element: <PrelaunchStudioForm />,
          },
          {
            path: ':id/studios/:prelaunchStudioId',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ,
            element: <PrelaunchStudio />,
          },
          {
            path: ':id/lesson-descriptions/:prelaunchLessonDescriptionId/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE,
            element: <PrelaunchLessonDescriptionForm />,
          },
          {
            path: ':id/lesson-descriptions/:prelaunchLessonDescriptionId',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ,
            element: <PrelaunchLessonDescription />,
          },
          {
            path: ':id/company-payment-details/:prelaunchCompanyPaymentDetailId/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE,
            element: <PrelaunchCompanyPaymentDetailForm />,
          },
        ],
      },
      {
        backUrl: '/login',
        auth: true,
        path: 'first-class-discount-disabled-lessons',
        element: <FirstClassDiscountDisabledLessonsContainer />,
        permission: PermissionsEnum.FIRST_CLASS_DISCOUNTS_READ,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.FIRST_CLASS_DISCOUNTS_READ,
            element: <FirstClassDiscountDisabledLessons />,
          },
          {
            path: 'new',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.FIRST_CLASS_DISCOUNTS_WRITE,
            element: <FirstClassDiscountDisabledLessonForm />,
          },
          {
            path: ':id/edit',
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.FIRST_CLASS_DISCOUNTS_WRITE,
            element: <FirstClassDiscountDisabledLessonForm />,
          },
        ],
      },
      {
        backUrl: '/login',
        auth: true,
        path: 'billing-invoices',
        element: <BillingInvoicesContainer />,
        permission: PermissionsEnum.BILLING_INVOICES_READ,
        children: [
          {
            index: true,
            backUrl: '/login',
            auth: true,
            permission: PermissionsEnum.BILLING_INVOICES_READ,
            element: <BillingInvoices />,
          },
        ],
      },
      {
        backUrl: '/login',
        auth: true,
        path: 'change-language',
        element: <ChangeLanguage />,
      },
    ],
  },
]
export default AdminRoutes
