import config from '../config/index'
import { CorporateCreditsRequest } from '../pages/corporates-container/share-type'
import {
  CreateCouponTemplateParams,
  IPaginatedCouponTemplateFilterParams,
  PaginatedCouponTemplate,
} from '../pages/coupon-templates-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedCouponTemplates = (data: IPaginatedCouponTemplateFilterParams) =>
  authRequest.get<MISSING_TYPE, PaginatedCouponTemplate>(`${mainApi}/coupon-templates`, {
    params: data,
  })

export const createCouponTemplate = (data: FormData) =>
  authRequest.post(`${mainApi}/coupon-templates`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

export const getCouponTemplateDetail = (couponTemplateId: number) =>
  authRequest.get(`${mainApi}/coupon-templates/${couponTemplateId}`)

export const editCouponTemplateDetail = (
  couponTemplateId: number,
  data: CreateCouponTemplateParams,
) => authRequest.put(`${mainApi}/coupon-templates/${couponTemplateId}`, data)

export const revokeCouponTemplate = (couponTemplateId: number) =>
  authRequest.delete(`${mainApi}/coupon-templates/${couponTemplateId}`)

export const getCouponTemplatesSuggestions = (params: {
  keyword: string
  isValid: boolean
  isAuto?: boolean
}) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/coupon-templates/suggestions`,
    {
      params: params,
    },
  )

export const adjustCorporateCredits = (corporateId: number, data: CorporateCreditsRequest) =>
  authRequest.put(`${mainApi}/corporates/${corporateId}/credits`, data)
