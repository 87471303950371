import {
  IStudioLeaderboardRankingType,
  PaginatedStudioLeaderboardsResponse,
  StudioLeaderboardStatusEnum,
} from '../../pages/studio-leaderboards-container/share-type'
import {
  SET_APPROVED_STUDIO_LEADERBOARDS_TYPES,
  SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES,
  SET_PENDING_STUDIO_LEADERBOARDS_TYPES,
  SET_REJECTED_STUDIO_LEADERBOARDS_TYPES,
  SET_STUDIO_LEADERBOARD_RANKING_TYPES,
} from './constants'

export interface SetStudioLeaderboardRaningTypesAction {
  type: SET_STUDIO_LEADERBOARD_RANKING_TYPES
  payload: IStudioLeaderboardRankingType[]
}

export const setStudioLeaderboardRaningTypesAction = (
  studioLeaderboardRankingTypes: IStudioLeaderboardRankingType[],
): SetStudioLeaderboardRaningTypesAction => {
  return {
    type: SET_STUDIO_LEADERBOARD_RANKING_TYPES,
    payload: studioLeaderboardRankingTypes,
  }
}

export interface SetStudioLeaderboardsAction {
  type:
    | SET_PENDING_STUDIO_LEADERBOARDS_TYPES
    | SET_APPROVED_STUDIO_LEADERBOARDS_TYPES
    | SET_REJECTED_STUDIO_LEADERBOARDS_TYPES
    | SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES
  paginatedStudioLeaderboards: PaginatedStudioLeaderboardsResponse
}

export const setStudioLeaderboardsAction = (
  paginatedStudioLeaderboards: PaginatedStudioLeaderboardsResponse,
  tab: StudioLeaderboardStatusEnum,
): SetStudioLeaderboardsAction => {
  switch (tab) {
    case StudioLeaderboardStatusEnum.REJECTED:
      return {
        type: SET_REJECTED_STUDIO_LEADERBOARDS_TYPES,
        paginatedStudioLeaderboards,
      }
    case StudioLeaderboardStatusEnum.APPROVED:
      return {
        type: SET_APPROVED_STUDIO_LEADERBOARDS_TYPES,
        paginatedStudioLeaderboards,
      }
    case StudioLeaderboardStatusEnum.EXPIRED:
      return {
        type: SET_EXPIRED_STUDIO_LEADERBOARDS_TYPES,
        paginatedStudioLeaderboards,
      }
    case StudioLeaderboardStatusEnum.PENDING:
    default:
      return {
        type: SET_PENDING_STUDIO_LEADERBOARDS_TYPES,
        paginatedStudioLeaderboards,
      }
  }
}

export type StudioLeaderboardAction =
  | SetStudioLeaderboardRaningTypesAction
  | SetStudioLeaderboardsAction
