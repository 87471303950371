import config from '../config/index'
import {
  IPaginatedSearchTrendsParams,
  ISearchTrend,
  PaginatedSearchTrendsResponse,
  SearchTrendRankerRequest,
  SearchTrendStatusEnums,
} from '../pages/search-trends-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedSearchTrends = (data: IPaginatedSearchTrendsParams) =>
  authRequest.get<MISSING_TYPE, PaginatedSearchTrendsResponse>(`${mainApi}/search-trends`, {
    params: data,
  })

export const updateSearchTrendsStatus = (id: number, status: SearchTrendStatusEnums) =>
  authRequest.put(`${mainApi}/search-trends/${id}`, { status })

export const getSearchTrendDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ISearchTrend>(`${mainApi}/search-trends/${id}`)

export const updateSearchTrendRankers = (id: number, data: SearchTrendRankerRequest[]) =>
  authRequest.put<MISSING_TYPE, ISearchTrend>(`${mainApi}/search-trends/${id}/rankers`, data)
