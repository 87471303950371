import { Modal, notification } from 'antd'
import { createPrelaunchOrganizationForms } from '../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../i18n'
import {
  IPrelaunchOrganizationForm,
  PrelaunchOrganizationCreateRequest,
} from '../../../pages/prelaunch-organization-forms-container/share-types'
import PrelaunchOrganizationFormsForm from '../prelaunch-organization-forms-form/prelaunch-organization-forms-form'

interface IProps {
  open: boolean
  handleCancel: () => void
  organizationId?: number
  handleSubmit: (res: IPrelaunchOrganizationForm) => void
}

const PrelaunchOrganizationFormsFormModal = (props: IProps) => {
  const { handleSubmit, open, handleCancel, organizationId } = props

  const onFinish = (values: PrelaunchOrganizationCreateRequest) => {
    createPrelaunchOrganizationForms({
      expiredAtAfter: values.expiredAtAfter,
      organizationId: values.organizationId,
    })
      .then(res => {
        notification.success({
          message: '创建成功',
        })
        handleSubmit(res)
      })
      .catch(err => {
        notification.error({
          message: '创建失败',
          description: err.message ?? translate('networkError'),
        })
      })
  }

  return (
    <Modal
      title="创建prelaunch organization"
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      width={800}
    >
      <PrelaunchOrganizationFormsForm organizationId={organizationId} onFinish={onFinish} />
    </Modal>
  )
}

export default PrelaunchOrganizationFormsFormModal
