import { Button, Divider, Form, InputNumber, Radio, Select, Upload, UploadFile } from 'antd'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TaggingContextEnums } from '../../pages/tags-container/constant'
import { getTagsByContext } from '../../api/tagsApi'
import { ITag, ITagsWithCategory } from '../../pages/tags-container/share-type'
import translate from '../../i18n'
import { difficultLevelOptions, LocaleOptions } from '../../helpers/constant'
import TextArea from 'antd/lib/input/TextArea'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { LocaleType, SupportedPrelaunchUploadImageTypeEnums } from '../../share-types'
import { request } from '../../request'
import { PrelaunchLessonDescriptionFormValues } from '../../pages/prelaunch-organization-forms-container/share-types'
import {
  lessonDescriptionsTargetUserGroupsEnOptions,
  lessonDescriptionsTargetUserGroupsZhCNOptions,
} from '../../pages/card-stacks-container/constant'
import { prelaunchUploadImage } from '../../api/imageApi'
import { getImageFileName } from '../../helpers/image-upload-helper'

const { Option } = Select

const LessonDescriptionForm = ({
  onFinish,
  formData,
  organizationUniqueId,
  isEdit,
}: {
  onFinish: (values: PrelaunchLessonDescriptionFormValues) => void
  formData?: PrelaunchLessonDescriptionFormValues
  organizationUniqueId: string
  isEdit: boolean
}) => {
  const [form] = Form.useForm()
  const [activitiesTags, setActivitiesTags] = useState<ITag[]>([])
  const [labelsTags, setLabelsTags] = useState<ITag[]>([])
  const [lessonDescriptionsTargetUserGroupsOptions] = useState<{ [key: string]: any }>({
    [LocaleType.zh_CN]: lessonDescriptionsTargetUserGroupsZhCNOptions,
    [LocaleType.en]: lessonDescriptionsTargetUserGroupsEnOptions,
  })
  const translationsValues = Form.useWatch('translations', form)

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  useEffect(() => {
    const promises = [
      getTagsByContext(TaggingContextEnums.ACTIVITIES),
      getTagsByContext(TaggingContextEnums.LABELS),
    ]
    Promise.all(promises).then(([activities, labels]) => {
      if (activities) {
        setActivitiesTags(flatTagsWithContext(activities))
      }
      if (labels) {
        setLabelsTags(flatTagsWithContext(labels))
      }
    })
  }, [])

  const flatTagsWithContext = (res: ITagsWithCategory): ITag[] => {
    return Object.values(res).flat()
  }

  const getTagsOptions = (tags: ITag[], taggingContex: TaggingContextEnums) => {
    return tags.map(tag => {
      const categoryAndSubCategoryLabel = [tag.category, tag.subCategory]
        .filter(item => item)
        .join('/')
      const tagName = tag.tagTranslations.find(t => t.locale === LocaleType.zh_CN)?.name
      if (taggingContex === TaggingContextEnums.ACTIVITIES) {
        return {
          label: `${tagName} (${categoryAndSubCategoryLabel})`,
          value: tag.id,
        }
      }

      return {
        label: `${tagName}`,
        value: tag.id,
      }
    })
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const uploadUrl = (options: any, type: SupportedPrelaunchUploadImageTypeEnums) => {
    const { onSuccess, onError, file, filename } = options
    const fileExtension = file.name.split('.').pop()
    const imageFilename = `${filename}_${file.uid}.${fileExtension}`
    prelaunchUploadImage(organizationUniqueId, imageFilename, type)
      .then(res => {
        onSuccess(res)
        request({
          url: res,
          method: 'PUT',
          data: file,
          headers: {
            'x-amz-acl': 'public-read',
          },
        })
      })
      .catch(err => {
        onError({ err })
      })
  }

  const getOptionsByLocale = (index: number) => {
    if (!translationsValues) {
      return lessonDescriptionsTargetUserGroupsZhCNOptions
    }
    const locale = translationsValues[index]?.locale
    if (!locale) {
      return lessonDescriptionsTargetUserGroupsZhCNOptions
    }
    return lessonDescriptionsTargetUserGroupsOptions[locale]
  }

  const onSubimtForms = (values: PrelaunchLessonDescriptionFormValues) => {
    const { photos, credits, startBookable, lateBefore, labelList, ...others } = values

    const data = {
      ...others,
      credits: isEdit ? credits : 0,
      startBookable: isEdit ? startBookable : 10,
      lateBefore: isEdit ? lateBefore : 12,
      labelList: isEdit ? labelList : [],
      photoFileNames: photos
        ?.map((photo: UploadFile) => getImageFileName('photos', [photo]))
        .filter(item => item),
    }
    onFinish(data)
  }

  return (
    <Form
      name="lesson-description-form"
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      onFinish={onSubimtForms}
      initialValues={{
        isAllDay: false,
        activityList: [],
      }}
    >
      <Form.Item
        name="duration"
        label={translate('lessonDescription.duration')}
        rules={[{ required: true, message: translate('lessonDescription.duration.message') }]}
      >
        <InputNumber addonAfter="分钟" />
      </Form.Item>

      {isEdit && (
        <Form.Item
          name="credits"
          label={translate('lessonDescription.credits')}
          rules={[{ required: true, message: translate('lessonDescription.credits.message') }]}
        >
          <InputNumber addonAfter="点数" />
        </Form.Item>
      )}

      <Divider style={{ fontSize: '14px' }}>{translate('lessonInformation')}</Divider>

      <Form.List
        name="translations"
        initialValue={[
          {
            locale: LocaleType.zh_CN,
            checkInNotes:
              '我们温馨提醒您，请提前X分钟抵达场馆，若您迟到，将不被允许进入，感谢您的理解与支持！',
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key}>
                {isEdit && (
                  <Form.Item
                    {...restField}
                    label={translate('locale')}
                    name={[name, 'locale']}
                    rules={[{ required: true, message: '请选择语言！' }]}
                  >
                    <Select>
                      {LocaleOptions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  label={translate('name')}
                  rules={[{ required: true, message: translate('name.message') }]}
                  {...restField}
                  name={[name, 'name']}
                >
                  <TextArea showCount />
                </Form.Item>
                <Form.Item
                  label={translate('lessonDescriptionTranslations.description')}
                  {...restField}
                  name={[name, 'description']}
                  rules={[
                    { required: true, message: translate('lessonDescription.description.message') },
                  ]}
                >
                  <TextArea showCount />
                </Form.Item>

                <Form.Item
                  label={translate('lessonDescriptionTranslations.highlights')}
                  {...restField}
                  name={[name, 'highlights']}
                >
                  <TextArea showCount />
                </Form.Item>
                <Form.Item
                  label={translate('lessonDescriptionTranslations.specialNoteToUsers')}
                  {...restField}
                  name={[name, 'specialNoteToUsers']}
                  help={
                    <>
                      <div>如果是球类或是需要特殊装备的课程，请备注是否有提供、或是需要自备:</div>
                      <div>
                        1. 有提供球拍/道服/攀岩鞋等装备; 如果没有，请备注是否有租赁服务以及费用
                      </div>
                      <div>2. 请您穿防滑袜、瑜伽服、自备水杯毛巾，等等</div>
                    </>
                  }
                >
                  <TextArea showCount />
                </Form.Item>

                <Form.Item
                  label={translate('lessonDescriptionTranslations.checkInNotes')}
                  {...restField}
                  name={[name, 'checkInNotes']}
                >
                  <TextArea showCount />
                </Form.Item>

                <Form.Item
                  label={translate('lessonDescriptionTranslations.targetUserGroups')}
                  {...restField}
                  name={[name, 'targetUserGroups']}
                >
                  <Select allowClear options={getOptionsByLocale(index)}></Select>
                </Form.Item>

                {isEdit && (
                  <>
                    <Form.Item label={translate('funFact')} {...restField} name={[name, 'funFact']}>
                      <TextArea showCount />
                    </Form.Item>

                    <Form.Item
                      label={translate('lessonDescriptionTranslations.lessonPrerequisites')}
                      {...restField}
                      name={[name, 'lessonPrerequisites']}
                    >
                      <TextArea showCount />
                    </Form.Item>

                    <Form.Item
                      label={translate('lessonDescriptionTranslations.otherNotes')}
                      {...restField}
                      name={[name, 'otherNotes']}
                    >
                      <TextArea showCount />
                    </Form.Item>
                  </>
                )}

                {fields.length > 1 ? (
                  <Form.Item colon={false} label="  ">
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  </Form.Item>
                ) : null}
              </div>
            ))}

            {isEdit && fields.length < Object.keys(LocaleType).length && (
              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {translate('addNewTranslation')}
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      <Divider style={{ fontSize: '14px' }}>{translate('studio.taggings')}</Divider>

      <Form.Item name="activityList" label={translate('activityList')}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={translate('activities.placeholder')}
          options={getTagsOptions(activitiesTags, TaggingContextEnums.ACTIVITIES)}
        />
      </Form.Item>

      {isEdit && (
        <Form.Item name="labelList" label={translate('label')}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={translate('labels.placeholder')}
            options={getTagsOptions(labelsTags, TaggingContextEnums.LABELS)}
          />
        </Form.Item>
      )}

      <Divider style={{ fontSize: '14px' }}>{translate('bookingRules')}</Divider>

      {isEdit && (
        <Form.Item
          name="startBookable"
          label={translate('lessonDescription.startBookable')}
          help={translate('lessonDescription.startBookable.tips')}
          rules={[
            { required: true, message: translate('lessonDescription.startBookable.message') },
          ]}
        >
          <InputNumber addonAfter="天" />
        </Form.Item>
      )}

      <Form.Item
        name="bookBefore"
        label={translate('lessonDescription.bookBefore')}
        rules={[{ required: true, message: translate('lessonDescription.bookBefore.message') }]}
        help={translate('lessonDescription.bookBefore.tips')}
      >
        <InputNumber addonAfter="分钟" />
      </Form.Item>

      {isEdit && (
        <Form.Item
          name="lateBefore"
          label={translate('lessonDescription.lateBefore')}
          help={translate('lessonDescription.lateBefore.tips')}
          rules={[{ required: true, message: translate('lessonDescription.lateBefore.message') }]}
        >
          <InputNumber addonAfter="小时" />
        </Form.Item>
      )}

      <Divider style={{ fontSize: '14px' }}>{translate('lessonInformation')}</Divider>

      <Form.Item
        name="maxCapacity"
        label={translate('lessonDescription.maxCapacity')}
        rules={[{ required: true, message: translate('lessonDescription.maxCapacity.message') }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="difficultLevel"
        label={translate('lessonDescription.difficultLevel')}
        rules={[{ required: true, message: translate('lessonDescription.difficultLevel.message') }]}
      >
        <Select>
          {difficultLevelOptions.map(item => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="isAllDay"
        label={translate('lessonDescription.isAllDay')}
        rules={[{ required: true, message: translate('lessonDescription.isAllDay.message') }]}
      >
        <Radio.Group>
          <Radio value={true}>是</Radio>
          <Radio value={false}>否</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="photos"
        label={translate('photos')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        help="建议图片尺寸：9：16或者4：3"
      >
        <Upload
          name="photos"
          customRequest={config =>
            uploadUrl(config, SupportedPrelaunchUploadImageTypeEnums.LESSON_DESCRIPTION_PHOTOS)
          }
          listType="picture"
          multiple
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </Upload>
      </Form.Item>

      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
        <Button htmlType="submit" type="primary">
          <FormattedMessage id="submit"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LessonDescriptionForm
