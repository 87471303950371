import { ReduxStore } from '../../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './segment-rule.module.less'
import { ISegmentRule } from '../share-type'
import { getSegmentRuleDetail } from '../../../api/segmentRuleApi'
import SegmentRuleDescriptions from '../../../components/segment-rules/segment-rule-descriptions/segment-rule-descriptions'

const SegmentRule = () => {
  const params = useParams()
  const [segmentRuleId] = useState<string | undefined>(params?.id)
  const [segmentRule, setSegmentRule] = useState<ISegmentRule>()
  useEffect(() => {
    if (segmentRuleId) {
      getSegmentRuleDetail(Number(segmentRuleId)).then(res => {
        setSegmentRule(res)
      })
    }
  }, [segmentRuleId])

  return (
    <Space className={styles['segment-rule-desc-wrapper']} direction="vertical" wrap size={16}>
      {segmentRule && <SegmentRuleDescriptions segmentRule={segmentRule} />}
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(SegmentRule)
