import { SET_ALL_CATEGORIES } from './constants'
import { CategoriesAction } from './action'
import update from 'immutability-helper'
import { ITagsWithCategory } from '../../pages/tags-container/share-type'

interface InitialState {
  allCategories: ITagsWithCategory
}

const initialState: InitialState = {
  allCategories: {},
}

function categoriesReducer(state = initialState, action: CategoriesAction) {
  switch (action.type) {
    case SET_ALL_CATEGORIES:
      return update(state, { $merge: { allCategories: action.payload } })
    default:
      return state
  }
}

export default categoriesReducer
