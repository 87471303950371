import { useNavigate, useParams } from 'react-router-dom'
import CreditsDistributionScheduleForm from '../../../components/corporates/credits-distribution-schedule-form/credits-distribution-schedule-form'
import { useEffect, useState } from 'react'
import {
  editCorporateCreditsDistributionSchedule,
  getCorporateCreditsDistributionScheduleById,
} from '../../../api/corporateApi'
import { notification } from 'antd'
import translate from '../../../i18n'
import {
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleFormValue,
} from '../share-type'
import { formValueToRequest } from '../../../helpers/corporate-credits-distribution-schedule-helper'

const CorporateCreditsDistributionScheduleForm = () => {
  const navigate = useNavigate()

  const params = useParams()
  const [corporateId] = useState<number>(() => Number(params?.id))
  const [creditsDistributionScheduleId] = useState<number>(() =>
    Number(params?.creditsDistributionScheduleId),
  )
  const [corporateCreditsDistributionSchedule, setCorporateCreditsDistributionSchedule] =
    useState<ICorporateCreditsDistributionSchedule>()

  useEffect(() => {
    if (corporateId && creditsDistributionScheduleId) {
      getCorporateCreditsDistributionScheduleById(corporateId, creditsDistributionScheduleId).then(
        data => {
          setCorporateCreditsDistributionSchedule(data)
        },
      )
    }
  }, [corporateId, creditsDistributionScheduleId])

  const onFinish = (values: ICorporateCreditsDistributionScheduleFormValue) => {
    if (corporateId) {
      editCorporateCreditsDistributionScheduleAction(formValueToRequest(values))
    }
  }

  const editCorporateCreditsDistributionScheduleAction = (data: any) => {
    editCorporateCreditsDistributionSchedule(corporateId, creditsDistributionScheduleId, data)
      .then(res => {
        if (res) {
          notification.success({
            message: translate('corporate.corporateCreditsDistributionSchedule.edit.success'),
          })
          navigate(
            `/corporates/${corporateId}/credits-distribution-schedules/${creditsDistributionScheduleId}`,
          )
        }
      })
      .catch(err => {
        notification.error({
          message: translate('corporate.corporateCreditsDistributionSchedule.edit.failed'),
          description: err.message ?? translate('networkError'),
        })
      })
  }

  return (
    <CreditsDistributionScheduleForm
      onFinish={onFinish}
      isEdit={true}
      data={corporateCreditsDistributionSchedule}
      corporateId={corporateId}
    />
  )
}

export default CorporateCreditsDistributionScheduleForm
