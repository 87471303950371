import ClipLoader from 'react-spinners/ClipLoader'
import styles from './loading.module.less'

interface Iprops {
  loading: boolean
}
function Loading({ loading, ...props }: Iprops) {
  const innerStyle = {
    display: loading ? 'flex' : 'none',
    background: 'rgba(0, 0, 0, 0)',
  }

  return (
    <div className={styles['loading-wrap']} style={innerStyle}>
      <ClipLoader size={90} color={'#F90'} {...props} />
    </div>
  )
}

export default Loading
