import React from 'react'
import { Button, Col, Drawer, Row, Space, Tabs, Tag, Typography } from 'antd'
import translate from '../../../i18n'
import {
  DuplicateLessonDescriptionStatusEnums,
  DuplicationLessonDescriptionDto,
  GroupedDuplicationLessonDescriptionDto,
  IDuplicateLessonDescriptionFilterParams,
  ILessonDescription,
  IPaginatedDuplicateLessonDescriptionFilterParams,
  PaginatedDuplicateLessonDescriptionResponse,
} from '../../../pages/lesson-description-container/share-type'
import Table, { ColumnsType } from 'antd/lib/table'
import AuthPermission from '../../../high-order-components/auth-permission'
import { useEffect, useState } from 'react'
import { getPaginatedDuplicateLessonDescriptions } from '../../../api/duplicationLessonDescriptionsApi'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import LinkButton from '../../link-button/link-button'
import DuplicateLessonDescriptionFilter from '../duplicate-lesson-descriptions-filter/duplicate-lesson-descriptions-filter'
import AddDuplicateLessonDescriptionsModal from '../add-duplicate-lesson-descriptions-modal/add-duplicate-lesson-descriptions-modal'
import { useNavigate } from 'react-router-dom'
import DuplicateLessonDescriptionStatusTag from '../duplicate-lesson-description-status-tag/duplicate-lesson-description-status-tag'
import DuplicateLessonDescriptionStatusMenu, {
  handleUpdateStatus,
  showConfirmModal,
} from '../duplicate-lesson-description-status-menu/duplicate-lesson-description-status-menu'

const { Text } = Typography

const defaultPage = {
  pageSize: 20,
  currentPage: 1,
  count: 0,
}

interface IProps {
  open: boolean
  onClose: () => void
}

const DuplicateLessonDescriptionDrawer = ({ open, onClose }: IProps) => {
  const navigate = useNavigate()

  const [duplicateLessonDescriptions, setDuplicateLessonDescriptions] = useState<{
    [key: string]: GroupedDuplicationLessonDescriptionDto[]
  }>({
    [DuplicateLessonDescriptionStatusEnums.APPROVED]: [],
    [DuplicateLessonDescriptionStatusEnums.PENDING]: [],
    [DuplicateLessonDescriptionStatusEnums.REJECTED]: [],
  })

  const [pageInfo, setPageInfo] = useState<{
    [key: string]: PageInfo
  }>({
    [DuplicateLessonDescriptionStatusEnums.APPROVED]: defaultPage,
    [DuplicateLessonDescriptionStatusEnums.PENDING]: defaultPage,
    [DuplicateLessonDescriptionStatusEnums.REJECTED]: defaultPage,
  })

  const [searchValues, setSearchValues] = useState<IDuplicateLessonDescriptionFilterParams>()
  const [searchTab, setSearchTab] = useState<DuplicateLessonDescriptionStatusEnums>(
    DuplicateLessonDescriptionStatusEnums.PENDING,
  )
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [loadingTable, setLoadingTable] = useState<boolean>(false)

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRows, setSelectedRows] = useState<GroupedDuplicationLessonDescriptionDto[]>([])
  const [openDuplicateLessonDescriptionModal, setOpenDuplicateLessonDescriptionModal] =
    useState<boolean>(false)

  useEffect(() => {
    if (open) {
      getPaginatedDuplicateLessonDescriptionsAction({
        size: defaultPage.pageSize,
        page: defaultPage.currentPage - 1,
        statuses: [searchTab],
        ...searchValues,
      })
    }
  }, [open])

  const getPaginatedDuplicateLessonDescriptionsAction = (
    data: IPaginatedDuplicateLessonDescriptionFilterParams,
  ) => {
    setLoadingTable(true)
    getPaginatedDuplicateLessonDescriptions(data)
      .then((res: PaginatedDuplicateLessonDescriptionResponse) => {
        if (res) {
          setSelectedRowKeys([])
          setSelectedRows([])
          const status = data.statuses?.[0] ?? DuplicateLessonDescriptionStatusEnums.PENDING

          setDuplicateLessonDescriptions({
            ...duplicateLessonDescriptions,
            [status]: res.data,
          })

          setPageInfo({
            ...pageInfo,
            [status]: {
              ...res.pageInfo,
              currentPage: res.pageInfo.currentPage + 1,
            },
          })
        }
      })
      .finally(() => setLoadingTable(false))
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      statuses: [searchTab],
      ...searchValues,
    }

    getPaginatedDuplicateLessonDescriptionsAction(data)
  }

  const onSearch = (values: IDuplicateLessonDescriptionFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo[searchTab].pageSize,
      statuses: [searchTab],
      ...values,
    }

    setSearchValues(values)
    getPaginatedDuplicateLessonDescriptionsAction(data)
  }

  const onReload = () => {
    getPaginatedDuplicateLessonDescriptionsAction({
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
      statuses: [searchTab],
      ...searchValues,
    })
  }

  const handleClose = () => {
    clearDuplicateLessonDescriptions()
    onClose()
  }

  const clearDuplicateLessonDescriptions = () => {
    setDuplicateLessonDescriptions({
      [DuplicateLessonDescriptionStatusEnums.APPROVED]: [],
      [DuplicateLessonDescriptionStatusEnums.PENDING]: [],
      [DuplicateLessonDescriptionStatusEnums.REJECTED]: [],
    })
  }

  const getLabel = (value: DuplicateLessonDescriptionStatusEnums) => {
    switch (value) {
      case DuplicateLessonDescriptionStatusEnums.APPROVED:
        return translate('duplicateLessonDescription.merged')
      case DuplicateLessonDescriptionStatusEnums.REJECTED:
        return translate('duplicateLessonDescription.rejected')
      case DuplicateLessonDescriptionStatusEnums.PENDING:
      default:
        return translate('duplicateLessonDescription.notMerged')
    }
  }

  const handleTabChange = (key: string) => {
    const status = key as DuplicateLessonDescriptionStatusEnums
    setSearchTab(key as DuplicateLessonDescriptionStatusEnums)
    getPaginatedDuplicateLessonDescriptionsAction({
      size: pageInfo[status].pageSize,
      page: 0,
      statuses: [status],
      ...searchValues,
    })
  }

  const columns: ColumnsType<GroupedDuplicationLessonDescriptionDto> = [
    {
      title: translate('lessonDescription.organizationId'),
      dataIndex: 'organizationId',
      key: 'id',
      render: (organizationId: number) => {
        return <LinkButton to={`/organizations/${organizationId}`}>{organizationId}</LinkButton>
      },
    },
    {
      title: translate('duplicateLessonDescription.mainLessonDescription.id'),
      dataIndex: 'mainLessonDescription',
      key: 'mainLessonDescriptionId',
      width: 130,
      render: (mainLessonDescription: ILessonDescription) => {
        return (
          <LinkButton to={`/lesson-descriptions/${mainLessonDescription.id}`}>
            {mainLessonDescription.id}
          </LinkButton>
        )
      },
    },
    {
      title: translate('duplicateLessonDescription.mainLessonDescription.name'),
      dataIndex: 'mainLessonDescription',
      key: 'mainLessonDescriptionName',
      render: (mainLessonDescription: ILessonDescription) => {
        return formatLessonDescriptionNames(mainLessonDescription)
      },
    },
    {
      title: translate('duplicateLessonDescription.duplicateLessonDescriptions'),
      dataIndex: 'duplicateLessonDescriptions',
      key: 'duplicateLessonDescriptions',
      render: (duplicateLessonDescriptionDtos: DuplicationLessonDescriptionDto[]) => (
        <div style={{ width: '100%' }}>
          {duplicateLessonDescriptionDtos.map((duplicateLessonDescriptionDto, index) => {
            return (
              <Row
                key={index}
                align="middle"
                wrap={false}
                style={{ width: '100%', justifyContent: 'space-between', marginBottom: '8px' }}
              >
                <Col>
                  <Tag
                    className="clickable"
                    onClick={() =>
                      navigate(
                        `/lesson-descriptions/${duplicateLessonDescriptionDto.duplicationLessonDescriptionDto.id}`,
                      )
                    }
                  >
                    {duplicateLessonDescriptionDto.duplicationLessonDescriptionDto.id} -{' '}
                    {formatLessonDescriptionNames(
                      duplicateLessonDescriptionDto.duplicationLessonDescriptionDto,
                    )}
                  </Tag>
                </Col>
                <Col>
                  <DuplicateLessonDescriptionStatusTag
                    status={duplicateLessonDescriptionDto.status}
                  />
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                  <AuthPermission
                    permission={PermissionsEnum.DUPLICATION_LESSON_DESCRIPTIONS_WRITE}
                  >
                    <DuplicateLessonDescriptionStatusMenu
                      duplicateLessonDescription={duplicateLessonDescriptionDto}
                      setLoadingButton={setLoadingButton}
                      action={onReload}
                    />
                  </AuthPermission>
                </Col>
              </Row>
            )
          })}
        </div>
      ),
    },
  ]

  const formatLessonDescriptionNames = (lessonDescription: ILessonDescription) => {
    return lessonDescription?.lessonTranslations?.map(translation => translation.name).join(' / ')
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (
      newSelectedRowKeys: React.Key[],
      selectedRows: GroupedDuplicationLessonDescriptionDto[],
    ) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(selectedRows)
    },
  }

  return (
    <Drawer
      title={translate('duplicateLessonDescription.search')}
      placement="right"
      onClose={handleClose}
      open={open}
      width={1200}
      extra={
        <AuthPermission permission={PermissionsEnum.DUPLICATION_LESSON_DESCRIPTIONS_READ}>
          <Button type="link" onClick={() => setOpenDuplicateLessonDescriptionModal(true)}>
            {translate('duplicateLessonDescription.addDuplicates.prompt')}
          </Button>
        </AuthPermission>
      }
      footer={
        selectedRowKeys.length > 0 && (
          <Row className="footer-bar" justify="end">
            <Space>
              <Text disabled>已选中{selectedRowKeys.length}个项目</Text>
              <Button
                type="primary"
                onClick={() => {
                  const selectedIds = selectedRows
                    .map(selectedRow =>
                      selectedRow.duplicateLessonDescriptions.map(
                        duplicateLessonDescription => duplicateLessonDescription.id,
                      ),
                    )
                    .flat()
                  if (searchTab === DuplicateLessonDescriptionStatusEnums.PENDING) {
                    showConfirmModal(
                      selectedIds,
                      DuplicateLessonDescriptionStatusEnums.APPROVED,
                      translate('duplicateLessonDescription.merge'),
                      translate('duplicateLessonDescription.merge.confirmText'),
                      onReload,
                      setLoadingButton,
                    )
                  } else {
                    showConfirmModal(
                      selectedIds,
                      DuplicateLessonDescriptionStatusEnums.PENDING,
                      translate('duplicateLessonDescription.cancelMerge'),
                      translate('duplicateLessonDescription.cancelMerge.confirmText'),
                      onReload,
                      setLoadingButton,
                    )
                  }
                }}
                loading={loadingButton}
              >
                {searchTab === DuplicateLessonDescriptionStatusEnums.PENDING
                  ? translate('duplicateLessonDescription.mergeSelected')
                  : translate('duplicateLessonDescription.cancelMerge')}
              </Button>
              <Button
                type="primary"
                danger={true}
                onClick={() =>
                  handleUpdateStatus(
                    selectedRows
                      .map(selectedRow =>
                        selectedRow.duplicateLessonDescriptions.map(
                          duplicateLessonDescription => duplicateLessonDescription.id,
                        ),
                      )
                      .flat(),
                    DuplicateLessonDescriptionStatusEnums.REJECTED,
                    onReload,
                    setLoadingButton,
                  )
                }
                loading={loadingButton}
              >
                {translate('reject')}
              </Button>
            </Space>
          </Row>
        )
      }
    >
      <Space className="table-space" direction="vertical" wrap size={16}>
        <Row className="filter-bar">
          <DuplicateLessonDescriptionFilter onSearch={onSearch} formData={searchValues} />
        </Row>

        <Tabs
          activeKey={searchTab}
          onChange={handleTabChange}
          items={Object.values(DuplicateLessonDescriptionStatusEnums).map(key => {
            return {
              key: key.toString(),
              label: getLabel(key),
              children: (
                <Table
                  loading={loadingTable}
                  rowSelection={
                    key === DuplicateLessonDescriptionStatusEnums.REJECTED
                      ? undefined
                      : rowSelection
                  }
                  rowKey={record => record.mainLessonDescription.id}
                  columns={columns}
                  dataSource={duplicateLessonDescriptions[key]}
                  pagination={{
                    current: pageInfo[key].currentPage,
                    pageSize: pageInfo[key].pageSize,
                    total: pageInfo[key].count,
                    onChange: paginationOnChange,
                  }}
                />
              ),
            }
          })}
        />
      </Space>

      <AddDuplicateLessonDescriptionsModal
        open={openDuplicateLessonDescriptionModal}
        handleCancel={() => setOpenDuplicateLessonDescriptionModal(false)}
        handleSubmit={() => {
          setOpenDuplicateLessonDescriptionModal(false)
          onReload()
        }}
      />
    </Drawer>
  )
}

export default DuplicateLessonDescriptionDrawer
