import config from '../config/index'
import {
  IPaginatedShortURLs,
  IPaginatedShortURLsFilterParams,
  IShortURL,
  IShortURLRequest,
} from '../pages/short-urls/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedShortURLs = (data: IPaginatedShortURLsFilterParams) =>
  authRequest.get<MISSING_TYPE, IPaginatedShortURLs>(`${mainApi}/short-urls`, {
    params: data,
  })

export const createShortURL = (data: IShortURLRequest) =>
  authRequest.post<MISSING_TYPE, IShortURL>(`${mainApi}/short-urls`, data)

export const updateShortURL = (id: number, data: IShortURLRequest) =>
  authRequest.put<MISSING_TYPE, IShortURL>(`${mainApi}/short-urls/${id}`, data)
