import {
  Button,
  Input,
  Form,
  Space,
  Upload,
  InputNumber,
  Switch,
  Select,
  DatePicker,
  Row,
} from 'antd'
import {
  CorporateCreditsDistributionScheduleType,
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleFormValue,
  IntervalUnitType,
  IScheduleFormValue,
} from '../../../pages/corporates-container/share-type'
import translate from '../../../i18n'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { useEffect, useState } from 'react'
import {
  CorporateCreditsDistributionScheduleToFormValue,
  scheduleFormValueToRequest,
} from '../../../helpers/corporate-credits-distribution-schedule-helper'
import { getCorporateCreditsDistributionScheduleExecutePlans } from '../../../api/corporateApi'

const { TextArea } = Input
const { Option } = Select

interface IProps {
  corporateId: number
  onFinish: (values: ICorporateCreditsDistributionScheduleFormValue) => void
  onCancel?: () => void
  data?: ICorporateCreditsDistributionSchedule
  isEdit: boolean
  isTrialCorporate?: boolean
}

const DurationTooltip = ({ showExtendDays = false }: { showExtendDays?: boolean }) => (
  <Space direction="vertical">
    <Row>DAYS: 天</Row>
    <Row>WEEKS: 周 </Row>
    <Row>MONTHS: 自然月</Row>
    <Row>QUARTERS: 季度</Row>
    <Row>YEARS: 年</Row>
    {showExtendDays && (
      <Row>
        例：若执行日期为3月1日，点数过期时长=1个自然月+2天延长天数，点数过期日期=4月3日；若延长天数为0天（默认）那么点数过期时间为4月1日最后一秒；若延长天数为-1天，那么点数过期时间为31日的最后一秒。
      </Row>
    )}
  </Space>
)

const CreditsDistributionScheduleForm = ({
  onFinish,
  onCancel,
  data,
  isEdit = false,
  isTrialCorporate,
  corporateId,
}: IProps) => {
  const [form] = Form.useForm()
  const isApplyForAllMembersValue = Form.useWatch('isApplyForAllMembers', form)
  const scheduleTypeValue = Form.useWatch<CorporateCreditsDistributionScheduleType>(
    ['schedule', 'type'],
    form,
  )
  const scheduleValue = Form.useWatch<IScheduleFormValue>('schedule', form)

  const durationUnit = Form.useWatch(['creditsValidityPeriod', 'durationUnit'], form)
  const [executePlans, setExecutePlans] = useState<string[]>([])

  useEffect(() => {
    if (isEdit && data) {
      form.setFieldsValue(CorporateCreditsDistributionScheduleToFormValue(data))
    }
  }, [data])

  useEffect(() => {
    setExecutePlans([])
    if (
      scheduleValue?.type === CorporateCreditsDistributionScheduleType.REPEATABLE &&
      scheduleValue?.interval &&
      scheduleValue?.intervalUnit &&
      scheduleValue?.startDate
    ) {
      getCorporateCreditsDistributionScheduleExecutePlans(
        corporateId,
        scheduleFormValueToRequest(scheduleValue),
      ).then(res => setExecutePlans(res))
    }
  }, [scheduleValue])

  const clearScheduleValue = () => {
    form.setFieldsValue({
      schedule: {
        interval: undefined,
        IntervalUnitType: undefined,
        startDate: undefined,
        endDate: undefined,
        specificTimes: [],
      },
    })
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  return (
    <Form
      layout="horizontal"
      name="create_corporate_credits_distribution_schedules"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{
        isApplyForAllMembers: true,
        isReclaimBeforeDistribute: true,
        isActive: false,
        bufferPercentage: 0,
        creditsValidityPeriod: {
          extendDays: 0,
        },
      }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        label={translate(
          'corporate.corporateCreditsDistributionSchedule.modal.isApplyForAllMembers',
        )}
        name="isApplyForAllMembers"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch disabled={isEdit} />
      </Form.Item>

      {!isEdit && !isApplyForAllMembersValue && (
        <>
          <Form.Item
            name="file"
            label={translate('corporate.phoneNumber.bulkUpload')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="phoneNumbers" label={translate('corporate.phoneNumber.specify')}>
            <TextArea placeholder="多个用户phone number，使用,隔开" showCount />
          </Form.Item>
        </>
      )}

      <Form.Item
        label={translate('isActive')}
        name="isActive"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="credits"
        label={translate('corporate.credits')}
        rules={[{ required: true, message: '请输入点数!' }]}
      >
        <InputNumber min={1} />
      </Form.Item>

      <Form.Item
        label={translate('corporate.bufferPercentage')}
        name="bufferPercentage"
        rules={[
          {
            required: true,
            message: translate('corporate.bufferPercentage.required'),
          },
        ]}
      >
        <InputNumber disabled={isTrialCorporate} addonAfter="%" min={0} />
      </Form.Item>

      <Form.Item
        label={translate(
          'corporate.corporateCreditsDistributionSchedule.modal.creditsValidityPeriod',
        )}
        style={{ marginBottom: 0 }}
        rules={[{ required: true }]}
        tooltip={<DurationTooltip showExtendDays={true} />}
      >
        <Form.Item
          name={['creditsValidityPeriod', 'duration']}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            placeholder={translate(
              'corporate.corporateCreditsDistributionSchedule.modal.duration.placeholder',
            )}
          />
        </Form.Item>
        <Form.Item
          name={['creditsValidityPeriod', 'durationUnit']}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(33% - 8px)', marginLeft: 8 }}
        >
          <Select
            placeholder={translate(
              'corporate.corporateCreditsDistributionSchedule.modal.durationUnit.placeholder',
            )}
          >
            {Object.keys(IntervalUnitType).map(key => (
              <Option key={key} value={key}>
                {key}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {(durationUnit === IntervalUnitType.MONTHS ||
          durationUnit === IntervalUnitType.QUARTERS ||
          durationUnit === IntervalUnitType.YEARS) && (
          <Form.Item
            name={['creditsValidityPeriod', 'extendDays']}
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: '36%', marginLeft: 8 }}
          >
            <InputNumber
              style={{ width: '100%' }}
              addonAfter="天"
              placeholder={translate(
                'corporate.corporateCreditsDistributionSchedule.modal.extendDays.placeholder',
              )}
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label={translate(
          'corporate.corporateCreditsDistributionSchedule.modal.isReclaimBeforeDistribute',
        )}
        name="isReclaimBeforeDistribute"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={translate('corporate.corporateCreditsDistributionSchedule.modal.schedule.type')}
        tooltip={translate(
          'corporate.corporateCreditsDistributionSchedule.modal.schedule.type.desc',
        )}
        name={['schedule', 'type']}
        rules={[{ required: true }]}
      >
        <Select
          onChange={clearScheduleValue}
          placeholder={translate(
            'corporate.corporateCreditsDistributionSchedule.modal.schedule.type.message',
          )}
          allowClear
        >
          {Object.keys(CorporateCreditsDistributionScheduleType).map(key => (
            <Option key={key} value={key}>
              {key}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {scheduleTypeValue === CorporateCreditsDistributionScheduleType.SPECIFIC_TIME && (
        <Form.List name={['schedule', 'specificTimes']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Form.Item
                  {...restField}
                  label={'运行时间' + (fields.length > 1 ? index + 1 : '')}
                  key={key}
                >
                  <Space align="baseline">
                    <Form.Item
                      {...restField}
                      noStyle
                      name={[name]}
                      rules={[{ required: true, message: '' }]}
                    >
                      <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Space>
                </Form.Item>
              ))}
              <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {translate('add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}

      {scheduleTypeValue === CorporateCreditsDistributionScheduleType.REPEATABLE && (
        <>
          <Form.Item
            label={translate(
              'corporate.corporateCreditsDistributionSchedule.modal.schedule.startDate',
            )}
            name={['schedule', 'startDate']}
            rules={[{ required: true }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            label={translate(
              'corporate.corporateCreditsDistributionSchedule.modal.schedule.endDate',
            )}
            name={['schedule', 'endDate']}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            label={translate(
              'corporate.corporateCreditsDistributionSchedule.modal.schedule.interval',
            )}
            tooltip={<DurationTooltip />}
            extra={
              executePlans.length &&
              translate('corporate.corporateCreditsDistributionSchedule.modal.executePlans', {
                executePlans: `${executePlans.slice(0, 5).join(', ')}${executePlans.length < 6 ? '' : '...'}`,
              })
            }
          >
            <Form.Item
              name={['schedule', 'interval']}
              rules={[{ required: true }]}
              style={{ display: 'inline-block', width: 'calc(70% - 8px)', marginBottom: 0 }}
            >
              <InputNumber style={{ width: '100%' }} min={1} />
            </Form.Item>
            <Form.Item
              name={['schedule', 'intervalUnit']}
              rules={[{ required: true }]}
              style={{ display: 'inline-block', width: '30%', marginLeft: 8, marginBottom: 0 }}
            >
              <Select
                placeholder={translate(
                  'corporate.corporateCreditsDistributionSchedule.modal.schedule.intervalUnit.placeholder',
                )}
              >
                {Object.keys(IntervalUnitType).map(key => (
                  <Option key={key} value={key}>
                    {key}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
        </>
      )}

      <Form.Item name="notes" label={translate('corporate.notes')}>
        <TextArea placeholder={translate('corporate.notes.placeholder')} showCount />
      </Form.Item>

      <Form.Item label=" " colon={false}>
        <Space>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="submit" />
          </Button>
          {onCancel && (
            <Button onClick={onCancel}>
              <FormattedMessage id="cancel" />
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CreditsDistributionScheduleForm
