import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getStudioIntegrationLessonConfigDetail } from '../../../api/studioIntegrationConfigsApi'
import { IStudioIntegrationLessonConfig } from '../share-types'
import StudioIntegrationLessonConfigDescriptions from '../../../components/studio-integration-configs/studio-integration-lesson-config-descriptions/studio-integration-lesson-config-descriptions'

const StudioIntegrationLessonConfig = () => {
  const params = useParams()
  const [lessonConfigId] = useState<string | undefined>(params?.lessonConfigId)
  const [studioIntegrationLessonConfig, setStudioIntegrationLessonConfig] =
    useState<IStudioIntegrationLessonConfig>()

  useEffect(() => {
    if (lessonConfigId) {
      getStudioIntegrationLessonConfigDetail(Number(lessonConfigId)).then(data => {
        setStudioIntegrationLessonConfig(data)
      })
    }
  }, [lessonConfigId])

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {studioIntegrationLessonConfig && (
          <>
            <StudioIntegrationLessonConfigDescriptions data={studioIntegrationLessonConfig} />
          </>
        )}
      </Space>
    </>
  )
}

export default StudioIntegrationLessonConfig
