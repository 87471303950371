import { Button, Form, Modal, Input, Space } from 'antd'
import { ReclaimCorporateCreditsRequest } from '../../../pages/corporates-container/share-type'
import translate from '../../../i18n'
import styles from './reclaim-credits-modal.module.less'
import { FormattedMessage } from '../../intl'

interface ReclaimCreditsModalProps {
  onFinish: (value: ReclaimCorporateCreditsRequest) => void
  handleCancel: () => void
  open: boolean
  loading: boolean
}

const { TextArea } = Input

const ReclaimCreditsModal = (props: ReclaimCreditsModalProps) => {
  const [form] = Form.useForm()
  const { onFinish, loading, open, handleCancel } = props

  return (
    <Modal
      open={open}
      footer={null}
      width={800}
      forceRender
      title={translate('corporate.credits.reclaim')}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        layout="horizontal"
        name="reclaim-credits-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item name="notes" label={translate('corporate.notes')}>
          <TextArea placeholder={translate('corporate.notes.placeholder')} showCount />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Space className={styles['button-group']}>
            <Button loading={loading} type="primary" htmlType="submit">
              <FormattedMessage id="submit" />
            </Button>
            <Button onClick={handleCancel}>
              <FormattedMessage id="cancel" />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReclaimCreditsModal
