import { Button, Dropdown, Modal, notification } from 'antd'
import { updateDuplicateLessonDescription } from '../../../api/duplicationLessonDescriptionsApi'
import {
  DuplicateLessonDescriptionStatusEnums,
  DuplicationLessonDescriptionDto,
} from '../../../pages/lesson-description-container/share-type'
import translate from '../../../i18n'
import { FormattedMessage } from 'react-intl'
import {
  CheckCircleOutlined,
  DownOutlined,
  StopOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

export const showConfirmModal = (
  ids: number[],
  status: DuplicateLessonDescriptionStatusEnums,
  actionText: string,
  warningText: string,
  action: () => void,
  setLoadingButton?: (loading: boolean) => void,
) => {
  Modal.confirm({
    content: warningText,
    okText: actionText,
    cancelText: translate('no'),
    onOk: () => handleUpdateStatus(ids, status, action, setLoadingButton),
  })
}

export const handleUpdateStatus = (
  ids: number[],
  status: DuplicateLessonDescriptionStatusEnums,
  action: () => void,
  setLoadingButton?: (loading: boolean) => void,
) => {
  if (setLoadingButton) {
    setLoadingButton(true)
  }
  updateDuplicateLessonDescription({
    ids: ids,
    status: status,
  })
    .then(res => {
      if (res) {
        notification.success({
          message: translate('duplicateLessonDescription.mergeSuccess'),
        })
      }
    })
    .catch(err => {
      notification.error({
        message: translate('duplicateLessonDescription.mergeFail'),
        description: err?.message || translate('networkError'),
      })
    })
    .finally(() => {
      action()
      if (setLoadingButton) {
        setLoadingButton(false)
      }
    })
}

const DuplicateLessonDescriptionStatusMenu = (props: {
  duplicateLessonDescription: DuplicationLessonDescriptionDto
  setLoadingButton?: (loading: boolean) => void
  action: () => void
}) => {
  const { duplicateLessonDescription, setLoadingButton, action } = props
  const { status } = duplicateLessonDescription

  const renderMenu = (status: DuplicateLessonDescriptionStatusEnums) => {
    const menuItems = [
      {
        key: DuplicateLessonDescriptionStatusEnums.APPROVED.toString(),
        label: (
          <Button type="link" icon={<CloseCircleOutlined />}>
            <FormattedMessage id="duplicateLessonDescription.merge" />
          </Button>
        ),
      },
      {
        key: DuplicateLessonDescriptionStatusEnums.PENDING.toString(),
        label: (
          <Button type="link" className="status-button-green" icon={<CheckCircleOutlined />}>
            <FormattedMessage id="duplicateLessonDescription.cancelMerge" />
          </Button>
        ),
      },
      {
        key: DuplicateLessonDescriptionStatusEnums.REJECTED.toString(),
        label: (
          <Button type="link" danger icon={<StopOutlined />}>
            <FormattedMessage id="reject" />
          </Button>
        ),
      },
    ]

    return menuItems.filter(item => item.key !== status.toString())
  }

  const handleMenuClick = (e: any, record: DuplicationLessonDescriptionDto) => {
    switch (e.key) {
      case DuplicateLessonDescriptionStatusEnums.APPROVED.toString():
        showConfirmModal(
          [record.id],
          DuplicateLessonDescriptionStatusEnums.APPROVED,
          translate('duplicateLessonDescription.merge'),
          translate('duplicateLessonDescription.merge.confirmText'),
          action,
          setLoadingButton,
        )
        return

      case DuplicateLessonDescriptionStatusEnums.PENDING.toString():
        showConfirmModal(
          [record.id],
          DuplicateLessonDescriptionStatusEnums.PENDING,
          translate('duplicateLessonDescription.cancelMerge'),
          translate('duplicateLessonDescription.cancelMerge.confirmText'),
          action,
          setLoadingButton,
        )
        return

      case DuplicateLessonDescriptionStatusEnums.REJECTED.toString():
        handleUpdateStatus(
          [record.id],
          DuplicateLessonDescriptionStatusEnums.REJECTED,
          action,
          setLoadingButton,
        )
        return
    }
  }

  if (status === DuplicateLessonDescriptionStatusEnums.REJECTED) {
    return (
      <Button
        type="link"
        onClick={() =>
          handleUpdateStatus(
            [duplicateLessonDescription.id],
            DuplicateLessonDescriptionStatusEnums.PENDING,
            action,
            setLoadingButton,
          )
        }
      >
        <FormattedMessage id="duplicateLessonDescription.recover" />
      </Button>
    )
  } else {
    return (
      <Dropdown
        menu={{
          items: renderMenu(status),
          onClick: e => handleMenuClick(e, duplicateLessonDescription),
        }}
      >
        <a>
          <FormattedMessage id="tableActionColumn" /> <DownOutlined />
        </a>
      </Dropdown>
    )
  }
}

export default DuplicateLessonDescriptionStatusMenu
