import * as _ from 'lodash'

import {
  IStudioLeaderboardCategory,
  StudioLeaderboardCategoryFormValues,
  StudioLeaderboardCategoryRequest,
} from '../pages/studio-leaderboard-categories-container/share-type'
import { ITagsWithCategory } from '../pages/tags-container/share-type'
import { getImageFileName, getImageUrlToUploadFileInput } from './image-upload-helper'

const handleTags = (tagIds: (string | null)[][]): number[] => {
  const tags = tagIds?.map(item => Number(item[1])).filter(item => item)
  return tags
}
export const studioLeaderboardCategoryFormValuesToRequest = (
  values: StudioLeaderboardCategoryFormValues,
): StudioLeaderboardCategoryRequest => {
  const {
    iconSmallDefaultFileUrl,
    iconLargeDefaultFileUrl,
    iconSmallActiveFileUrl,
    iconLargeActiveFileUrl,
    studioLeaderboardCategoryTags,
    ...other
  } = values

  return {
    ...other,
    iconSmallDefaultFileName: getImageFileName('iconSmallDefaultFileUrl', iconSmallDefaultFileUrl),
    iconLargeDefaultFileName: getImageFileName('iconLargeDefaultFileUrl', iconLargeDefaultFileUrl),
    iconSmallActiveFileName: getImageFileName('iconSmallActiveFileUrl', iconSmallActiveFileUrl),
    iconLargeActiveFileName: getImageFileName('iconLargeActiveFileUrl', iconLargeActiveFileUrl),
    studioLeaderboardCategoryTags: handleTags(studioLeaderboardCategoryTags),
  }
}

export const studioLeaderboardCategoryFormValues = (
  data: IStudioLeaderboardCategory,
): StudioLeaderboardCategoryFormValues => {
  const {
    studioLeaderboardCategoryTags,
    iconSmallDefaultFileUrl,
    iconLargeDefaultFileUrl,
    iconSmallActiveFileUrl,
    iconLargeActiveFileUrl,
    ...other
  } = data

  const tagIds = studioLeaderboardCategoryTags.map(item => [
    item.tag.category,
    item.tag.id.toString(),
  ])

  return {
    ...other,
    studioLeaderboardCategoryTags: tagIds,
    iconSmallDefaultFileUrl: getImageUrlToUploadFileInput([iconSmallDefaultFileUrl], '0'),
    iconSmallActiveFileUrl: getImageUrlToUploadFileInput([iconSmallActiveFileUrl], '1'),
    iconLargeDefaultFileUrl: getImageUrlToUploadFileInput([iconLargeDefaultFileUrl], '2'),
    iconLargeActiveFileUrl: getImageUrlToUploadFileInput([iconLargeActiveFileUrl], '3'),
  }
}

export function findArrayByID(id: number, allCategories: ITagsWithCategory) {
  const categories = _.cloneDeep(allCategories) as ITagsWithCategory
  const key = _.findKey(categories, items => _.some(items, { id }))

  const tag = Object.values(categories)
    .flat()
    .find(item => item.id === id)

  return key && tag ? [key, tag.id.toString()] : null
}
