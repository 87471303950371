import { Button, Form, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { IShortURLsFilterParams } from '../../../pages/short-urls/share-type'
const ShortURLsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IShortURLsFilterParams) => void
  formData: IShortURLsFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: IShortURLsFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="name" label={translate('shortURLs.name')}>
        <Input />
      </Form.Item>

      <Form.Item name="redirectUrl" label={translate('shortURLs.redirectURL')}>
        <Input />
      </Form.Item>

      <Form.Item name="hashKey" label={translate('shortURLs.hashKey')}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ShortURLsFilter
