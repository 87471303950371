import { AuditStatusEnum } from '../../pages/booking-reviews-container/share-type'
import {
  SET_PENDING_BOOKING_REVIEWS,
  SET_APPROVED_BOOKING_REVIEWS,
  SET_REJECTED_BOOKING_REVIEWS,
} from './constants'
import { IBookingReviewsWithPageInfo } from './reducer'

export interface SetBookingReviewAction {
  type: SET_PENDING_BOOKING_REVIEWS | SET_APPROVED_BOOKING_REVIEWS | SET_REJECTED_BOOKING_REVIEWS
  bookingReviews: IBookingReviewsWithPageInfo
}

export const setBookingReviewsAction = (
  bookingReviews: IBookingReviewsWithPageInfo,
  tab: string,
): SetBookingReviewAction => {
  switch (tab) {
    case AuditStatusEnum.REJECTED:
      return {
        type: SET_REJECTED_BOOKING_REVIEWS,
        bookingReviews,
      }
    case AuditStatusEnum.APPROVED:
      return {
        type: SET_APPROVED_BOOKING_REVIEWS,
        bookingReviews,
      }
    case AuditStatusEnum.PENDING:
    default:
      return {
        type: SET_PENDING_BOOKING_REVIEWS,
        bookingReviews,
      }
  }
}

export type CitiesAction = SetBookingReviewAction
