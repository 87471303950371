import config from '../config/index'
import {
  IAdminUserParams,
  IPaginatedAdminUsersParams,
  PaginatedAdminUserResponse,
} from '../pages/admin-users-container/share-type'
import { authRequest } from '../request'
import { IAdminUser } from '../share-types'

const { mainApi } = config

export const getPaginatedAdminUsers = (data: IPaginatedAdminUsersParams) =>
  authRequest.get<MISSING_TYPE, PaginatedAdminUserResponse>(`${mainApi}/admin-users`, {
    params: data,
  })

export const getAdminUserInfo = (userId: number) =>
  authRequest.get<MISSING_TYPE, IAdminUser>(`${mainApi}/admin-users/${userId}`)

export const createAdminUser = (data: IAdminUserParams) =>
  authRequest.post<MISSING_TYPE, IAdminUser>(`${mainApi}/admin-users`, data)

export const editAdminUser = (userId: number, data: IAdminUserParams) =>
  authRequest.put<MISSING_TYPE, IAdminUser>(`${mainApi}/admin-users/${userId}`, data)

export const changeAdminUserPassword = (userId: number, data: { password: string }) =>
  authRequest.put(`${mainApi}/admin-users-password/${userId}`, data)
