import { URLSearchParams } from 'url'

import { UploadFile } from 'antd'
import * as _ from 'lodash'
import moment from 'moment'

import { Plan } from '../store/global/reducer'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT_SHORT,
  WECHAT_URL_LINK_EXPIRED_DAYS,
} from './constant'

const momentFormat = (date: string | number, format: string) => {
  return moment(date).format(format)
}

export const getDateTime = (date: string | number) => {
  return momentFormat(date, DATE_TIME_FORMAT)
}

export const getDate = (date: string | number) => {
  return momentFormat(date, DATE_FORMAT)
}

export const getTime = (date: string | number) => {
  return momentFormat(date, TIME_FORMAT_SHORT)
}

export const getWeChatURLLinkIsExpiredTag = (
  createdAt: string | number,
  expiredDays = WECHAT_URL_LINK_EXPIRED_DAYS,
) => {
  const expiredAt = moment(createdAt).add(expiredDays, 'days')
  const isExpired = moment().isAfter(expiredAt)
  return isExpired ? 'Expired' : `Expires ${expiredAt.fromNow()}`
}

export const getDiscountOff = (plans: Plan[], planId: number, price: number) => {
  const originPrice = plans.find(item => item.id === planId)?.price
  if (!originPrice) {
    return 0
  }
  return (((price * 100) / originPrice) * 10).toFixed(1)
}

export const getLowestDiscountOff = (
  plans: Plan[],
  percentageOffPlans: { planId: number; price: number }[],
) => {
  const percentageOffPlansWithDiscountOff = percentageOffPlans.map(
    (item: { planId: number; price: number }) => {
      return {
        ...item,
        discountOff: getDiscountOff(plans, item.planId, item.price),
      }
    },
  )
  const lowestDiscountOff = _.sortBy(percentageOffPlansWithDiscountOff, 'discountOff')[0]
    .discountOff
  return {
    lowestDiscountOff,
    percentageOffPlansWithDiscountOff,
  }
}

export const getOptions = (
  array: { id: number; name: string }[],
): { label: string; value: number }[] => {
  const options = array.map(item => {
    if (item.id <= 0) {
      return {
        label: item.name,
        value: item.id,
      }
    } else {
      return {
        label: `${item.id}-${item.name}`,
        value: item.id,
      }
    }
  })
  return options
}

export const getSearchValues = (
  keys: { key: string; isBoolean: boolean; isArray?: boolean; default?: any }[],
  searchParams: URLSearchParams,
) => {
  const searchValues: { [key: string]: any } = {}
  keys.forEach(element => {
    let value = undefined
    if (element.isArray) {
      value = searchParams.getAll(element.key)
    } else {
      value = searchParams.get(element.key)
    }
    const defaultValue = element.default ?? undefined

    if (element.isBoolean) {
      searchValues[element.key] = value === null ? defaultValue : value === 'true'
    } else {
      searchValues[element.key] = value ?? defaultValue
    }
  })

  return searchValues
}

export const getImageUrl = (filename: string, path: string, files?: UploadFile[]) => {
  if (!files || files.length === 0) {
    return ''
  }

  const file = files[0]
  if (_.hasIn(file, 'url')) {
    return file.url ?? ''
  } else {
    const fileExtension = file?.name.split('.').pop()
    return `${path}/${filename}_${file.uid}.${fileExtension}`
  }
}

export const getSearchParams = (data: any) => {
  return _.pickBy(data, item => item !== undefined && item !== null)
}

export const renderNullableValue = (
  value: number | null,
  formatter: (val: number) => string = String,
): string => {
  if (value === null) {
    return 'N/A'
  }
  return formatter(value)
}
