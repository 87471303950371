import { Button, Form, InputNumber, Modal, Switch } from 'antd'
import translate from '../../../i18n'
import { AdjustmentTypeEnum, BillingItemPricing, ILessonCreditAdjustment } from '../share-type'
import { useState } from 'react'

interface IProps {
  onFinish: (values: { adjustCredits: number }) => void
  handleCancel: () => void
  open: boolean
  lessonCreditAdjustment: ILessonCreditAdjustment
}

const EditAdjustCreditsModal = ({
  lessonCreditAdjustment,
  open,
  handleCancel,
  onFinish,
}: IProps) => {
  const [form] = Form.useForm<{
    adjustCredits: number
    billingItemPricing: BillingItemPricing | null
  }>()
  const [isPricingEnabled, setIsPricingEnabled] = useState<boolean>(
    lessonCreditAdjustment.billingItemPricing?.isActive ?? false,
  )

  const handleSubmit = (values: {
    adjustCredits: number
    billingItemPricing: BillingItemPricing | null
  }) => {
    if (!isPricingEnabled) {
      values.billingItemPricing = null
    }
    onFinish(values)
    handleCancel()
  }

  return (
    <Modal
      open={open}
      footer={null}
      forceRender
      title={translate('lessonCreditAdjustments.adjustCredits.edit')}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          adjustCredits: lessonCreditAdjustment?.rule?.adjustCredits,
          billingItemPricing: lessonCreditAdjustment.billingItemPricing,
        }}
      >
        <Form.Item
          name="adjustCredits"
          label={translate('lessonCreditAdjustments.adjustCredits')}
          rules={[
            {
              required: true,
              message: translate('lessonCreditAdjustments.adjustCredits.message'),
            },
            () => ({
              validator(_, value) {
                if (
                  lessonCreditAdjustment.adjustmentType === AdjustmentTypeEnum.PEAK &&
                  value < 0
                ) {
                  return Promise.reject(
                    new Error(translate('lessonCreditAdjustments.adjustCredits.minError')),
                  )
                }
                if (
                  lessonCreditAdjustment.adjustmentType === AdjustmentTypeEnum.OFF_PEAK &&
                  value > 0
                ) {
                  return Promise.reject(
                    new Error(translate('lessonCreditAdjustments.adjustCredits.maxError')),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputNumber addonAfter="点" />
        </Form.Item>
        <Form.Item
          label={translate('lessonCreditAdjustments.billingItemPricing')}
          wrapperCol={{ span: 6, offset: 0 }}
          valuePropName="checked"
        >
          <Switch checked={isPricingEnabled} onChange={setIsPricingEnabled} />
        </Form.Item>
        {isPricingEnabled && (
          <>
            <Form.Item
              name={['billingItemPricing', 'completedClassPriceCents']}
              label={translate(
                'lessonCreditAdjustments.billingItemPricing.completedClassPriceCents',
              )}
              rules={[
                {
                  type: 'number',
                  min: 0,
                },
              ]}
            >
              <InputNumber addonAfter="分" />
            </Form.Item>

            <Form.Item
              name={['billingItemPricing', 'lateCancelledPriceCents']}
              label={translate(
                'lessonCreditAdjustments.billingItemPricing.lateCancelledPriceCents',
              )}
              rules={[
                {
                  type: 'number',
                  min: 0,
                },
              ]}
            >
              <InputNumber addonAfter="分" />
            </Form.Item>

            <Form.Item
              name={['billingItemPricing', 'earlyVisitPriceCents']}
              label={translate('lessonCreditAdjustments.billingItemPricing.earlyVisitPriceCents')}
              rules={[
                {
                  type: 'number',
                  min: 0,
                },
              ]}
            >
              <InputNumber addonAfter="分" />
            </Form.Item>

            <Form.Item
              name={['billingItemPricing', 'noShowPriceCents']}
              label={translate('lessonCreditAdjustments.billingItemPricing.noShowPriceCents')}
              rules={[
                {
                  type: 'number',
                  min: 0,
                },
              ]}
            >
              <InputNumber addonAfter="分" />
            </Form.Item>
          </>
        )}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {translate('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditAdjustCreditsModal
