import { RuleClass, SegmentRuleClass } from '../pages/segment-rules-container/share-type'

class SegmentRule {
  name: string
  className: string
  constructor(name: string) {
    this.name = name
    this.className = `com.classpass.api.entities.${name}`
  }

  getParams(): SegmentRuleClass {
    return {
      '@class': this.className,
    }
  }
}

export class AssertRule extends SegmentRule {
  _enabledRules: RuleClass[] | null = null
  _disabledRules: RuleClass[] | null = null
  constructor() {
    super('AssertRule')
  }

  getRules({
    organizationIds,
    tagIds,
    studioIds,
  }: {
    organizationIds: number[]
    tagIds: number[]
    studioIds: number[]
  }) {
    const rules = []
    if (organizationIds && organizationIds.length) {
      const organizationRule = new OrganizationRule(organizationIds)
      rules.push(organizationRule.getParams())
    }

    if (tagIds && tagIds.length) {
      const lessonTagRule = new LessonTagRule(tagIds)
      rules.push(lessonTagRule.getParams())
    }

    if (studioIds && studioIds.length) {
      const studioIdRule = new StudioIdRule(studioIds)
      rules.push(studioIdRule.getParams())
    }

    return rules
  }

  public set enabledRules({
    organizationIds,
    tagIds,
    studioIds,
  }: {
    organizationIds: number[]
    tagIds: number[]
    studioIds: number[]
  }) {
    const rules = this.getRules({ organizationIds, tagIds, studioIds })
    this._enabledRules = rules.length ? rules : null
  }

  public set disabledRules({
    organizationIds,
    tagIds,
    studioIds,
  }: {
    organizationIds: number[]
    tagIds: number[]
    studioIds: number[]
  }) {
    const rules = this.getRules({ organizationIds, tagIds, studioIds })
    this._disabledRules = rules.length ? rules : null
  }

  getParams(): SegmentRuleClass {
    return {
      '@class': this.className,
      enabledRules: this._enabledRules,
      disabledRules: this._disabledRules,
    }
  }
}

export class FCFRule extends SegmentRule {
  constructor() {
    super('FCFRule')
  }
}

class CustomRule {
  name: string
  className: string
  constructor(name: string) {
    this.name = name
    this.className = `com.classpass.api.entities.${name}`
  }
}

export class OrganizationRule extends CustomRule {
  _organizationIds: number[] = []
  constructor(organizationIds: number[]) {
    super('OrganizationRule')
    this._organizationIds = organizationIds
  }

  getParams() {
    return {
      '@class': this.className,
      organizationIds: this._organizationIds,
    }
  }
}

export class LessonTagRule extends CustomRule {
  _tagIds: number[] = []
  constructor(tagIds: number[]) {
    super('LessonTagRule')
    this._tagIds = tagIds
  }

  getParams() {
    return {
      '@class': this.className,
      tagIds: this._tagIds,
    }
  }
}

export class StudioIdRule extends CustomRule {
  _studioIds: number[] = []
  constructor(studioIds: number[]) {
    super('StudioIdRule')
    this._studioIds = studioIds
  }

  getParams() {
    return {
      '@class': this.className,
      studioIds: this._studioIds,
    }
  }
}
