import { Button, Form, InputNumber, Modal, Space, Switch } from 'antd'
import translate from '../../../i18n'
import { StudioFirstClassDiscountRequest } from '../../../pages/first-class-discount-disabled-lessons-container/share-type'
import { useEffect } from 'react'

interface IStudioFirstClassDiscountsFormModalProps {
  loading: boolean
  isOpen: boolean
  formData?: StudioFirstClassDiscountRequest | null
  onClose: () => void
  onSubmit: (values: StudioFirstClassDiscountRequest) => void
}

const StudioFirstClassDiscountsFormModal = (props: IStudioFirstClassDiscountsFormModalProps) => {
  const { isOpen, loading, onSubmit, onClose, formData } = props
  const [form] = Form.useForm<StudioFirstClassDiscountRequest>()

  useEffect(() => {
    if (!isOpen) {
      form.resetFields()
    }
  }, [isOpen])

  useEffect(() => {
    if (!formData) {
      return
    }
    form.setFieldsValue(formData)
  }, [formData])

  return (
    <Modal title={translate('firstClassDiscount')} open={isOpen} footer={null} onCancel={onClose}>
      <Form
        form={form}
        name="studioFirstClassDiscountsForm"
        onFinish={onSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{
          isDisabled: false,
          isFixed: true,
        }}
      >
        <Form.Item
          label={translate('firstClassDiscount.discountPercentage')}
          name="discountPercentage"
          rules={[
            { required: true, message: translate('firstClassDiscount.discountPercentage.message') },
          ]}
        >
          <InputNumber min={0} max={100} addonAfter="%" />
        </Form.Item>

        <Form.Item
          label={translate('firstClassDiscount.isDisabled')}
          name="isDisabled"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={translate('firstClassDiscount.isFixed')}
          name="isFixed"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              {translate('submit')}
            </Button>
            <Button onClick={onClose}>{translate('cancel')}</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default StudioFirstClassDiscountsFormModal
