export enum StudioStatusEnums {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TEMPORARY_CLOSED = 'TEMPORARY_CLOSED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export const StudioStatusEnumOptions = [
  {
    value: StudioStatusEnums.ACTIVE,
    label: 'Active',
    color: 'green',
  },
  {
    value: StudioStatusEnums.INACTIVE,
    label: 'Inactive',
    color: 'red',
  },
  {
    value: StudioStatusEnums.TEMPORARY_CLOSED,
    label: 'Temporary Closed',
    color: 'orange',
  },
  {
    value: StudioStatusEnums.PENDING_ACTIVATION,
    label: 'Pending Activation',
    color: 'blue',
  },
]
