import { useEffect, useState } from 'react'
import {
  AdjustableTypeEnum,
  ILessonCreditAdjustment,
  ILessonCreditAdjustmentFilterParams,
  IPaginatedLessonCreditAdjustmentFilterParams,
  LessonCreditAdjustmentRule,
  LessonCreditAdjustmentRuleTypeEnum,
  LessonCreditAdjustmentUpdateRequest,
  PaginatedLessonCreditAdjustmentResponse,
} from '../share-type'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getSearchParams, getSearchValues } from '../../../helpers/utils'
import Table, { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { Button, Col, Dropdown, notification, Row, Space, Tag } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import {
  CheckCircleOutlined,
  DownOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons'
import {
  changeLessonCreditAdjustmentStatus,
  getPaginatedLessonCreditAdjustments,
  updateLessonCreditAdjustmentDetail,
} from '../../../api/lessonCreditAdjustmentApi'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import LinkButton from '../../../components/link-button/link-button'
import LessonCreditAdjustmentFilter from '../../../components/lesson-credit-adjustments/lesson-credit-adjustment-filter/lesson-credit-adjustment-filter'
import { keysToCamel } from '../../../helpers/keys-to-case'
import { FormattedMessage } from 'react-intl'
import styles from './lesson-credit-adjustments.module.less'
import EditAdjustCreditsModal from '../edit-adjust-credit-modal/edit-adjust-credit-modal'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const LessonCreditAdjustments = () => {
  const navigate = useNavigate()
  const [lessonCreditAdjustments, setLessonCreditAdjustments] =
    useState<ILessonCreditAdjustment[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ILessonCreditAdjustmentFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [selectedRecord, setselectedRecord] = useState<ILessonCreditAdjustment | null>(null)

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'isActive', isBoolean: true },
        { key: 'adjustmentType', isBoolean: false },
        { key: 'adjustableType', isBoolean: false },
        { key: 'adjustableId', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedLessonCreditAdjustmentsAction(data)
  }, [])

  const getPaginatedLessonCreditAdjustmentsAction = (
    data: IPaginatedLessonCreditAdjustmentFilterParams,
  ) => {
    getPaginatedLessonCreditAdjustments(data).then(
      (res: PaginatedLessonCreditAdjustmentResponse) => {
        if (res) {
          setLessonCreditAdjustments(res.data)
          setPageInfo({
            ...res.pageInfo,
            currentPage: res.pageInfo.currentPage + 1,
          })
        }
      },
    )
  }

  const reload = () => {
    const data = {
      ...searchValues,
      size: pageInfo.pageSize,
      page: pageInfo.currentPage - 1,
    }
    getPaginatedLessonCreditAdjustmentsAction(data)
  }

  const onSearch = (values: ILessonCreditAdjustmentFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo.pageSize,
      ...values,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(values))
    getPaginatedLessonCreditAdjustmentsAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedLessonCreditAdjustmentsAction(data)
  }

  const updateLessonCreditAdjustmentAction = (
    record: ILessonCreditAdjustment,
    request: LessonCreditAdjustmentUpdateRequest,
    message?: string,
  ) => {
    updateLessonCreditAdjustmentDetail(record.id, request)
      .then(response => {
        if (response) {
          notification.success({
            message: message || 'Edit Lesson Credit Adjustment Success',
          })
          reload()
        }
      })
      .catch(err => {
        notification.error({
          message: 'Edit Lesson Credit Adjustment Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  const onChangeLessonCreditAdjustmentStatus = (
    record: ILessonCreditAdjustment,
    request: { isActive: boolean },
    message?: string,
  ) => {
    changeLessonCreditAdjustmentStatus(record.id, request)
      .then(res => {
        if (res) {
          notification.success({
            message: message || 'Change Lesson Credit Adjustment Status Success',
          })
          reload()
        }
      })
      .catch(err => {
        notification.error({
          message: 'Change Lesson Credit Adjustment Status Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  const handleMenuClick = (e: any, record: ILessonCreditAdjustment) => {
    switch (e.key) {
      case 'deactivate':
        onChangeLessonCreditAdjustmentStatus(
          record,
          {
            isActive: false,
          },
          '停用成功',
        )
        break
      case 'activate':
        onChangeLessonCreditAdjustmentStatus(
          record,
          {
            isActive: true,
          },
          '激活成功',
        )
        break
      case 'edit':
        setOpenEditModal(true)
        setselectedRecord(record)
        break
      default:
        break
    }
  }

  const columns: ColumnsType<ILessonCreditAdjustment> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      width: 100,
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('lessonCreditAdjustments.AdjustmentType'),
      dataIndex: 'adjustmentType',
      key: 'adjustmentType',
    },
    {
      title: translate('lessonCreditAdjustments.AdjustableType'),
      dataIndex: 'adjustableType',
      key: 'adjustableType',
    },
    {
      title: translate('lessonCreditAdjustments.AdjustableId'),
      dataIndex: 'adjustableId',
      key: 'adjustableId',
      render: (adjustableId: number, record: ILessonCreditAdjustment) => (
        <LinkButton
          to={`/${record.adjustableType === AdjustableTypeEnum.STUDIO ? 'studios' : 'organizations'}/${adjustableId}`}
        >
          {adjustableId}
        </LinkButton>
      ),
    },
    {
      title: translate('lessonCreditAdjustments.lessonDescriptionIds'),
      dataIndex: 'rule',
      key: 'rule',
      render: (rule: LessonCreditAdjustmentRule) => {
        const adjustmentRule = keysToCamel(rule)
        if (adjustmentRule.type == LessonCreditAdjustmentRuleTypeEnum.PEAK_OFF_PEAK) {
          if (adjustmentRule.lessonDescriptionIds?.length > 0) {
            return adjustmentRule.lessonDescriptionIds.map(
              (lessonDescriptionId: number, index: number) => (
                <Tag key={index} color="blue">
                  {lessonDescriptionId}
                </Tag>
              ),
            )
          } else {
            return (
              <Tag color="blue">
                {translate('lessonCreditAdjustments.lessonDescriptionIds.all')}
              </Tag>
            )
          }
        }
      },
    },
    {
      title: translate('lessonCreditAdjustments.adjustCredits'),
      dataIndex: 'rule',
      key: 'rule',
      render: (rule: LessonCreditAdjustmentRule) => {
        if (rule.type == LessonCreditAdjustmentRuleTypeEnum.PEAK_OFF_PEAK) {
          return keysToCamel(rule).adjustCredits
        }
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: ILessonCreditAdjustment) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_WRITE}>
            <Dropdown
              menu={{
                items: [
                  record.isActive
                    ? {
                        key: 'deactivate',
                        label: (
                          <Button type="link" danger icon={<StopOutlined />}>
                            <FormattedMessage id="deactivate" />
                          </Button>
                        ),
                      }
                    : {
                        key: 'activate',
                        label: (
                          <Button
                            type="link"
                            className={styles['activate-button']}
                            icon={<CheckCircleOutlined />}
                          >
                            <FormattedMessage id="activate" />
                          </Button>
                        ),
                      },
                  {
                    key: 'edit',
                    label: (
                      <Button type="link" icon={<PlusCircleOutlined />}>
                        <FormattedMessage id="lessonCreditAdjustments.adjustCredits.edit" />
                      </Button>
                    ),
                  },
                ],
                onClick: e => handleMenuClick(e, record),
              }}
            >
              <a className={styles['action-item']}>
                <FormattedMessage id="tableActionColumn" /> <DownOutlined />
              </a>
            </Dropdown>
          </AuthPermission>

          <LinkButton to={`/lesson-credit-adjustments/${record.id}`}>
            {translate('view')}
          </LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.lessonCreditPricingTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Space size={16}>
            <AuthPermission permission={PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_WRITE}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('/lesson-credit-adjustments/new')}
              >
                {translate('navBar.newLessonCreditAdjustmentsTitle')}
              </Button>
            </AuthPermission>
          </Space>
        </Col>
      </Row>

      <Row className="filter-bar">
        <LessonCreditAdjustmentFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={lessonCreditAdjustments}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />

      {selectedRecord && (
        <EditAdjustCreditsModal
          open={openEditModal}
          handleCancel={() => {
            setOpenEditModal(false)
            setselectedRecord(null)
          }}
          lessonCreditAdjustment={keysToCamel(selectedRecord)}
          onFinish={(values: { adjustCredits: number }) =>
            updateLessonCreditAdjustmentAction(selectedRecord, values, '编辑成功')
          }
        />
      )}
    </Space>
  )
}

export default LessonCreditAdjustments
