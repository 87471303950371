import { PageInfo } from '../../share-types'

export type IReferralCampaignsFilterParams = {
  name?: string
  isDefault?: boolean
  isActive?: boolean
  startDatetime?: string
  endDatetime?: string
}

export type IPaginatedReferralCampaignsFilterParams = {
  page?: number
  size?: number
} & IReferralCampaignsFilterParams

export type PaginatedReferralCampaignsResponse = {
  pageInfo: PageInfo
  data: IReferralCampaign[]
}

export type IReferralCampaign = {
  id: number
  isActive: boolean
  isDefault: boolean
  name: string
  startDatetime: string
  endDatetime: string
  countDownBeforeMinutes: number
  senderRewardScheme?: SenderRewardScheme
  receiverRewardScheme: ReceiverRewardScheme
  pageBackgroundColor: string
  savePosterButtonColor: string
  sharingButtonColor: string
  generatingPosterButtonColor: string
  createdAt: string
  updated: string
  translations: ReferralCampaignTranslation[]
}

export type ReferralCampaignTranslation = {
  id?: number
  locale: string
  title: string
  rewardTitle?: string
  receiverPageTitle?: string
  bannerUrl: string
  receiverPageBannerUrl: string
  shareImageUrl: string
  posterImageUrl: string
  referralEntranceImageUrl: string
  referralPosterModalDescription: string
  sharingDescription: string
  receiverPageIntroImageUrl?: string
  receiverPagePriceImageUrl?: string
}

export type SenderRewardScheme = {
  credits?: number
  receiverCount?: number
  couponTemplateId?: number
  rewardTiers?: CountBasedRewardTierType[]
  type: SenderRewardSchemeType
}

export type ReceiverRewardScheme = {
  couponTemplateId: number
  type: ReceiverRewardSchemeType
}

export enum SenderRewardSchemeType {
  RECEIVER_COUNT_BASED = 'RECEIVER_COUNT_BASED',
  REFERRAL_CREDITS = 'REFERRAL_CREDITS',
  RECEIVER_COUNT_BASED_TIERED = 'RECEIVER_COUNT_BASED_TIERED',
}

export const defaultCampaignSenderRewardOptions = [SenderRewardSchemeType.REFERRAL_CREDITS]

export const referralCampaignSenderRewardOptions = [
  SenderRewardSchemeType.RECEIVER_COUNT_BASED,
  SenderRewardSchemeType.RECEIVER_COUNT_BASED_TIERED,
]

export enum ReceiverRewardSchemeType {
  REFERRAL_RECEIPT = 'REFERRAL_RECEIPT',
}

export type CountBasedRewardTierType = {
  receiverCount: number
  couponTemplateId: number
}

export interface ReferralCampaignRequest {
  name: string
  isActive: boolean
  isDefault: boolean
  startDatetime: string
  endDatetime: string
  countDownBeforeMinutes: number
  senderRewardScheme?: SenderRewardScheme
  receiverRewardScheme: ReceiverRewardScheme
  pageBackgroundColor: string
  savePosterButtonColor: string
  sharingButtonColor: string
  generatingPosterButtonColor: string
  translations: ReferralCampaignTranslation[]
}

export enum ReferralCampaignFileDirectoryEnum {
  ADVERTISEMENT = 'mini-program/assets/raf',
}
