import {
  Action,
  CardActionType,
  CardStackConfigFilter,
  LocaleString,
  OutputCardStackConfig,
} from '../pages/card-stacks-container/share-type'

class CardStackConfig {
  name: string
  className: string
  _title: LocaleString | null = null
  _titleTranslations: LocaleString[] | null = null
  _action?: Action | null
  _filters: CardStackConfigFilter[] = []
  constructor(name: string) {
    this.name = name
    this.className = `com.classpass.api.entities.${this.name}`
  }

  setFieldsCardConfigFormValue(params: OutputCardStackConfig) {
    const { action, title, titleTranslations, filters, ...other } = params
    const tempFilters = filters?.map((item: CardStackConfigFilter) => ({
      text: item.content.text,
      textTranslation:
        item.contentTranslations && item.contentTranslations.length
          ? item.contentTranslations[0].text
          : '',
      isDefault: item.isDefault,
      filter: item.content.filter,
      filterTranslation:
        item.contentTranslations && item.contentTranslations.length
          ? item.contentTranslations[0].filter
          : '',
    }))
    return {
      ...other,
      title: title?.value,
      titleTranslation:
        titleTranslations && titleTranslations.length ? titleTranslations[0].value : '',
      actionText: action?.text?.value ?? '',
      actionTextTranslation:
        action?.textTranslations && action.textTranslations.length
          ? action.textTranslations[0].value
          : '',
      actionUrl: action?.url ?? '',
      actionType: action?.type ?? '',
      filters: tempFilters,
    }
  }

  public set title(value: string) {
    if (value) {
      this._title = {
        locale: 'zh-CN',
        value: value,
      }
    } else {
      this._title = null
    }
  }

  public set titleTranslation(value: string) {
    if (value) {
      this._titleTranslations = [
        {
          locale: 'en',
          value: value,
        },
      ]
    } else {
      this._titleTranslations = null
    }
  }

  public set action({
    actionUrl,
    actionType,
    actionText,
    actionTextTranslation,
  }: {
    actionUrl: string
    actionType: CardActionType
    actionText: string
    actionTextTranslation: string
  }) {
    let obj = {}
    if (actionText) {
      obj = {
        text: {
          locale: 'zh-CN',
          value: actionText,
        },
      }
    }
    if (actionTextTranslation) {
      obj = {
        ...obj,
        textTranslations: [
          {
            locale: 'en',
            value: actionTextTranslation,
          },
        ],
      }
    }

    if (actionUrl) {
      obj = {
        ...obj,
        url: actionUrl,
      }
    }

    if (actionType) {
      obj = {
        ...obj,
        type: actionType,
      }
    }
    if (Object.keys(obj).length) {
      this._action = obj
    } else {
      this._action = null
    }
  }

  public set filters(
    filters: {
      text: string
      textTranslation: string
      filter: string
      filterTranslation: string
      isDefault: boolean
    }[],
  ) {
    if (filters) {
      this._filters = filters.map(filter => ({
        isDefault: filter.isDefault || false,
        content: {
          locale: 'zh-CN',
          text: filter.text,
          filter: filter.filter,
        },
        contentTranslations: [
          {
            locale: 'en',
            text: filter.textTranslation,
            filter: filter.filterTranslation,
          },
        ],
      }))
    }
  }
}

export class GridCardStack extends CardStackConfig {
  _numberOfRow = 0
  _numberOfColumn = 0
  constructor() {
    super('GridCardStack')
  }

  public set numberOfRow(v: number) {
    this._numberOfRow = v
  }

  public set numberOfColumn(v: number) {
    this._numberOfColumn = v
  }

  getConfig(): OutputCardStackConfig {
    return {
      '@class': this.className,
      title: this._title,
      titleTranslations: this._titleTranslations,
      action: this._action,
      filters: this._filters,
      numberOfRow: this._numberOfRow,
      numberOfColumn: this._numberOfColumn,
    }
  }
}

export class CarouselCardStack extends CardStackConfig {
  _intervalInMs = 0
  constructor() {
    super('CarouselCardStack')
  }

  public set intervalInMs(v: number) {
    this._intervalInMs = v
  }

  getConfig(): OutputCardStackConfig {
    return {
      '@class': this.className,
      title: this._title,
      titleTranslations: this._titleTranslations,
      action: this._action,
      filters: this._filters,
      intervalInMs: this._intervalInMs,
    }
  }
}

export class ListableBannerCardStack extends CardStackConfig {
  _position = 0
  constructor() {
    super('ListableBannerCardStack')
  }

  public set position(p: number) {
    this._position = p
  }

  getConfig(): OutputCardStackConfig {
    return {
      '@class': this.className,
      title: this._title,
      titleTranslations: this._titleTranslations,
      action: this._action,
      filters: this._filters,
      position: this._position,
    }
  }
}
