import { useEffect, useMemo, useRef, useState } from 'react'
import type { DefaultOptionType, SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import { Select, Spin } from 'antd'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import SortableItem from './sortable-item'
import * as _ from 'lodash'

interface OptionType extends DefaultOptionType {
  value: string | number
}

interface OnChangeHandler {
  (e: any): void
}

interface OrderSelectProps<ValueType = any> extends SelectProps {
  fetchOptions: (search: string) => Promise<OptionType[]>
  debounceTimeout?: number
  value: number[]
  onChange: OnChangeHandler
  defaultOptions: ValueType[]
}

function OrderSelect({
  fetchOptions,
  debounceTimeout = 800,
  value,
  onChange,
  defaultOptions = [],
  ...props
}: OrderSelectProps) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([])
  const [items, setItems] = useState<OptionType[]>([])

  const fetchRef = useRef(0)

  useEffect(() => {
    if (defaultOptions) {
      setItems(defaultOptions)
    }
  }, [defaultOptions])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = value.findIndex((i: number) => i.toString() === active.id)
      const overIndex = value.findIndex((i: number) => i.toString() === over?.id)
      const arr = arrayMove(value, activeIndex, overIndex)
      const newItems = _.sortBy(items, item => _.findIndex(arr, id => id === item.value))
      setItems(newItems)
      onChange(arr)
    }
  }

  /** * hide display of tags in Select ***/
  const tagRender = () => {
    return <span style={{ display: 'none' }}></span>
  }
  const onSelect = (value: any, option: any) => {
    setItems(items.concat([option]))
  }

  const onDeselect = (value: number) => {
    setItems(items.filter(item => item.value !== value))
  }

  const onClose = (s: OptionType) => {
    setItems(items.filter(item => item.value !== s.value))
    onChange(value.filter(v => v !== s.value))
  }

  return (
    <div>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={value ? value.map(v => v.toString()) : []}
          strategy={rectSortingStrategy}
        >
          {items &&
            items.map(s => (
              <SortableItem
                id={s.value.toString()}
                key={s.value}
                label={s.label}
                onClose={() => onClose(s)}
                closable
              />
            ))}
        </SortableContext>
      </DndContext>

      <Select
        showSearch
        value={value}
        onChange={onChange}
        tagRender={tagRender}
        onSearch={debounceFetcher}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={fetching ? <Spin size="small" /> : '暂无搜索内容'}
        options={options}
        onSelect={onSelect}
        onDeselect={onDeselect}
        {...props}
      />
    </div>
  )
}

export default OrderSelect
