import { ICardStack } from '../../pages/card-stacks-container/share-type'
import { SET_ALL_CARD_STACKS } from './constants'

export interface SetAllCardStacksAction {
  type: SET_ALL_CARD_STACKS
  payload: ICardStack[]
}

export const setAllCardStacksAction = (payload: ICardStack[]): SetAllCardStacksAction => {
  return {
    type: SET_ALL_CARD_STACKS,
    payload,
  }
}

export type CardStacksAction = SetAllCardStacksAction
