export const PAGINATION_CONFIG = {
  DEFAULT: {
    CURRENT_PAGE: 1,
    PAGE_SIZE: 10,
    TOTAL: 0,
  },
} as const

export const paginationUtils = {
  toBackendPage: (page: number) => Math.max(0, page - 1),
  toFrontendPage: (page: number) => page + 1,
  validatePageSize: (size: number) => Math.max(1, size),
}

export type PaginationConfig = typeof PAGINATION_CONFIG
