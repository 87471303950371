import { createIntl, createIntlCache } from 'react-intl'

import enMessage from './react-intl/en'
import zhCN from './react-intl/zh-cn'
import { LocaleType } from './share-types'
import { store } from './store'
import { setLocaleAction } from './store/global/action'

export type IntlMessageKeys = keyof typeof zhCN

export const messages: MISSING_TYPE = {
  [LocaleType.en]: enMessage,
  [LocaleType.zh_CN]: zhCN,
}

const cache = createIntlCache()

let int = createIntl(
  {
    locale: LocaleType.zh_CN,
    messages: messages[LocaleType.zh_CN],
  },
  cache,
)

export function changeLanguage(lang: LocaleType) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang],
    },
    cache,
  )
  store.dispatch(setLocaleAction(lang))
  int = newIntl
}

const translate = (id: IntlMessageKeys, values?: any) => {
  return int.formatMessage({ id }, values)
}

export default translate
