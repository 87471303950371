import { notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PrelaunchCompanyPaymentDetailFormValues } from '../share-types'
import {
  editPrelaunchCompanyPaymentDetail,
  getPrelaunchCompanyPaymentDetail,
} from '../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../i18n'
import PaymentDetailForm from '../../../components/common/payment-detail-form'
import { convertCompanyPaymentDetailToFormValues } from '../../../helpers/prelaunch-organization-helper'

const PrelaunchCompanyPaymentDetailForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [prelaunchCompanyPaymentDetailId] = useState<string | undefined>(
    params?.prelaunchCompanyPaymentDetailId,
  )
  const [prelaunchOrganizationId] = useState<string | undefined>(params?.id)
  const [prelaunchCompanyPaymentDetailFormData, setPrelaunchCompanyPaymentDetailFormData] =
    useState<PrelaunchCompanyPaymentDetailFormValues>()

  useEffect(() => {
    if (prelaunchCompanyPaymentDetailId) {
      getPrelaunchCompanyPaymentDetail(prelaunchCompanyPaymentDetailId).then(data => {
        setPrelaunchCompanyPaymentDetailFormData(convertCompanyPaymentDetailToFormValues(data))
      })
    }
  }, [prelaunchCompanyPaymentDetailId])

  const onFinish = (value: PrelaunchCompanyPaymentDetailFormValues) => {
    if (!prelaunchCompanyPaymentDetailId) {
      return
    }

    editPrelaunchCompanyPaymentDetail(prelaunchCompanyPaymentDetailId, value)
      .then(response => {
        if (response) {
          notification.success({
            message: 'Edit Company Payment Detail Success',
          })
          navigate(
            `/prelaunch-organization-forms/${prelaunchOrganizationId}/company-payment-details/${prelaunchCompanyPaymentDetailId}`,
          )
        }
      })
      .catch(err => {
        notification.error({
          message: 'Edit  Company Payment Detail Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  return <PaymentDetailForm onFinish={onFinish} formData={prelaunchCompanyPaymentDetailFormData} />
}

export default PrelaunchCompanyPaymentDetailForm
