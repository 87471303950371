import * as _ from 'lodash'

import { CouponTemplateDistributionFrequencyModeEnums } from '../pages/coupon-templates-container/constant'
import {
  CouponDiscountTypeEnums,
  CouponTemplate,
  CouponTemplateRule,
  CouponTemplateTranslation,
  CouponTypeRulesEnums,
  CouponUsageRulesEnums,
  ForPeopleTypeEnums,
  FormatCouponTemplate,
} from '../pages/coupon-templates-container/share-type'
import { LocaleType } from '../share-types'
import { City } from '../store/city/reducer'
import { ONE_DAY, ONE_MINUTE } from './constant'
import {
  CitySpecificRule,
  CorporateSpecificRule,
  IkeaSegmentRule,
  NewUserOnlyRule,
  OldUserOnlyRule,
  RepeatableRule,
  RepurchaseRule,
  TotalAmountLimitRule,
  TrialUserRepurchaseRule,
  UsageLimitRule,
  UserSpecificRule,
} from './coupon-rules'

const usageLimitRule = new UsageLimitRule()
const repeatableRule = new RepeatableRule()
const citySpecificRule = new CitySpecificRule()
const totalAmountLimitRule = new TotalAmountLimitRule()
const userSpecificRule = new UserSpecificRule()
const newUserOnlyRule = new NewUserOnlyRule()
const oldUserOnlyRule = new OldUserOnlyRule()
const repurchaseRule = new RepurchaseRule()
const ikeaSegmentRule = new IkeaSegmentRule()
const trialUserRepurchaseRule = new TrialUserRepurchaseRule()
const corporateSpecificRule = new CorporateSpecificRule()

export const setDiscount = (discount: any, translations: CouponTemplateTranslation[]) => {
  if (discount.discountType === CouponDiscountTypeEnums.PERCENTAGE_OFF) {
    return {
      discountOff: translations.length ? translations[0].discountOff / 10 : '',
    }
  }

  if (discount.discountType === CouponDiscountTypeEnums.CASH_OFF) {
    return {
      offAmount: discount.offAmount / 100,
      maxAmount: discount.maxAmount / 100,
    }
  }

  if (discount.discountType === CouponDiscountTypeEnums.BUY_ONE_GET_MORE) {
    return {
      buyOneGetMorePlans: discount.plans,
      buyOneGetMoreDiscountOff: translations.length ? translations[0].discountOff : '',
    }
  }

  return null
}

export const getDiscountDetail = (discount: any, discountOff: number) => {
  let label = ''
  if (discount.discountType === CouponDiscountTypeEnums.PERCENTAGE_OFF) {
    label = `${discountOff / 10} 折`
  }

  if (discount.discountType === CouponDiscountTypeEnums.CASH_OFF) {
    const offAmount = discount.offAmount / 100
    const maxAmount = discount.maxAmount / 100
    label = `满${maxAmount}减${offAmount}`
  }

  if (discount.discountType === CouponDiscountTypeEnums.BUY_ONE_GET_MORE) {
    label = `购买奖励${discountOff}点`
  }

  if (discount.discountType === CouponDiscountTypeEnums.STACKABLE_CASH_OFF) {
    const offAmount = discount.offAmount / 100
    label = `减${offAmount}元，${discount.stackable ? '' : '不'}可叠加`
  }

  if (discount.discountType === CouponDiscountTypeEnums.GIFT_CARD) {
    label = `兑换${discount.credits}点`
  }

  return label
}

export const groupRules = ({
  couponRule,
  refreshInterval,
  distributionFrequencyMode,
  cityIds,
  totalAmount,
  forPeople,
  userIds,
  couponRuleType,
  segmentId,
  usageLimit,
  corporateIds,
}: {
  couponRule: CouponUsageRulesEnums
  refreshInterval: number
  distributionFrequencyMode: CouponTemplateDistributionFrequencyModeEnums
  cityIds: number[] | null
  totalAmount: number
  forPeople: ForPeopleTypeEnums | null
  userIds: string
  couponRuleType: CouponTypeRulesEnums
  segmentId?: number
  usageLimit: number
  corporateIds?: number[]
}) => {
  const rules = []
  // 单次使用
  if (couponRule === CouponUsageRulesEnums.SINGLE_USE) {
    usageLimitRule.usageLimit = usageLimit
    rules.push(usageLimitRule.getParams())
  }

  // 重复使用
  if (couponRule === CouponUsageRulesEnums.REUSE) {
    usageLimitRule.usageLimit = usageLimit
    rules.push(usageLimitRule.getParams())
    repeatableRule.refreshInterval =
      distributionFrequencyMode === CouponTemplateDistributionFrequencyModeEnums.ONE_DAY
        ? refreshInterval * (ONE_DAY / ONE_MINUTE)
        : refreshInterval
    rules.push(repeatableRule.getParams())
  }

  if (cityIds && cityIds.length) {
    citySpecificRule.cityIds = cityIds
    rules.push(citySpecificRule.getParams())
  }

  if (totalAmount) {
    totalAmountLimitRule.totalAmount = totalAmount
    rules.push(totalAmountLimitRule.getParams())
  }

  if (forPeople && forPeople === ForPeopleTypeEnums.SPECIAL_USER_ONLY) {
    userSpecificRule.userIds = userIds
      ? userIds.indexOf(',') !== -1
        ? userIds.split(',').map(value => Number(value))
        : [Number(userIds)]
      : []
    rules.push(userSpecificRule.getParams())
  }

  if (forPeople && forPeople === ForPeopleTypeEnums.CORPORATE_USERS) {
    corporateSpecificRule.corporateIds = corporateIds
    rules.push(corporateSpecificRule.getParams())
  }

  if (forPeople && forPeople === ForPeopleTypeEnums.NEW_USER_ONLY) {
    rules.push(newUserOnlyRule.getParams())

    if (segmentId) {
      ikeaSegmentRule.segmentId = segmentId
      rules.push(ikeaSegmentRule.getParams())
    }
  }

  if (forPeople && forPeople === ForPeopleTypeEnums.OLD_USER_ONLY) {
    rules.push(oldUserOnlyRule.getParams())
  }

  if (couponRuleType === CouponTypeRulesEnums.REPURCHASE_RULE) {
    rules.push(repurchaseRule.getParams())
  } else if (couponRuleType === CouponTypeRulesEnums.TRIAL_USER_REPURCHASE_RULE) {
    rules.push(trialUserRepurchaseRule.getParams())
  }

  return rules
}

export const getUserSpecificRule = (userIds: string): UserSpecificRule => {
  const userSpecificRule = new UserSpecificRule()
  userSpecificRule.userIds = userIds ? userIds.split(',').map(value => Number(value)) : []
  return userSpecificRule.getParams()
}

interface RuleFormValue {
  couponRule?: CouponUsageRulesEnums
  usageLimit?: number
  refreshInterval?: number
  distributionFrequencyMode?: CouponTemplateDistributionFrequencyModeEnums
  cityIds?: number[]
  totalAmount?: number
  forPeople?: ForPeopleTypeEnums
  userIds?: number[]
  couponRuleType: CouponTypeRulesEnums
  segmentId?: number
  corporateIds?: number[]
}

export const getSplitRules = (rules: CouponTemplateRule[]): RuleFormValue => {
  let obj: RuleFormValue = {
    couponRuleType: CouponTypeRulesEnums.NONE,
  }
  const usageLimit = rules.find(item => item.rule['class'] === usageLimitRule.params['@class'])
  const repeatable = rules.find(item => item.rule['class'] === repeatableRule.params['@class'])
  const getRefreshIntervalAndDistributionFrequencyMode = (refreshInterval?: number) => {
    if (!refreshInterval) {
      return {
        refreshInterval: undefined,
        distributionFrequencyMode: CouponTemplateDistributionFrequencyModeEnums.ONE_MINUTE,
      }
    }
    const days = refreshInterval / (ONE_DAY / ONE_MINUTE)
    if (days >= 1) {
      return {
        refreshInterval: refreshInterval / (ONE_DAY / ONE_MINUTE),
        distributionFrequencyMode: CouponTemplateDistributionFrequencyModeEnums.ONE_DAY,
      }
    }
    return {
      refreshInterval,
      distributionFrequencyMode: CouponTemplateDistributionFrequencyModeEnums.ONE_MINUTE,
    }
  }
  if (usageLimit) {
    if (repeatable) {
      const { refreshInterval, distributionFrequencyMode } =
        getRefreshIntervalAndDistributionFrequencyMode(repeatable.rule.refreshInterval)
      obj = {
        ...obj,
        couponRule: CouponUsageRulesEnums.REUSE,
        usageLimit: usageLimit.rule.usageLimit,
        refreshInterval: refreshInterval,
        distributionFrequencyMode: distributionFrequencyMode,
      }
    } else {
      obj = {
        ...obj,
        couponRule: CouponUsageRulesEnums.SINGLE_USE,
        usageLimit: usageLimit.rule.usageLimit,
      }
    }
  }
  rules
    .filter(
      item =>
        item.rule['class'] !== usageLimitRule.params['@class'] &&
        item.rule['class'] !== repeatableRule.params['@class'],
    )
    .forEach(item => {
      const { rule } = item
      const ruleName = rule['class']
      if (ruleName === citySpecificRule.params['@class']) {
        obj = {
          ...obj,
          cityIds: rule.cityIds,
        }
      }

      if (ruleName === totalAmountLimitRule.params['@class']) {
        obj = {
          ...obj,
          totalAmount: rule.totalAmount,
        }
      }

      if (ruleName === userSpecificRule.params['@class']) {
        obj = {
          ...obj,
          forPeople: ForPeopleTypeEnums.SPECIAL_USER_ONLY,
          userIds: rule.userIds,
        }
      }

      if (ruleName === newUserOnlyRule.params['@class']) {
        obj = {
          ...obj,
          forPeople: ForPeopleTypeEnums.NEW_USER_ONLY,
        }
      }

      if (ruleName === ikeaSegmentRule.params['@class']) {
        obj = {
          ...obj,
          forPeople: ForPeopleTypeEnums.NEW_USER_ONLY,
          segmentId: rule.segmentId,
        }
      }

      if (ruleName === oldUserOnlyRule.params['@class']) {
        obj = {
          ...obj,
          forPeople: ForPeopleTypeEnums.OLD_USER_ONLY,
        }
      }

      if (ruleName === repurchaseRule.params['@class']) {
        obj = {
          ...obj,
          couponRuleType: CouponTypeRulesEnums.REPURCHASE_RULE,
        }
      }

      if (ruleName === trialUserRepurchaseRule.params['@class']) {
        obj = {
          ...obj,
          couponRuleType: CouponTypeRulesEnums.TRIAL_USER_REPURCHASE_RULE,
        }
      }

      if (ruleName === corporateSpecificRule.params['@class']) {
        obj = {
          ...obj,
          forPeople: ForPeopleTypeEnums.CORPORATE_USERS,
          corporateIds: rule.corporateIds,
        }
      }
    })
  return obj
}

export const getCouponTemplatePlans = (discount: any) => {
  switch (discount.discountType) {
    case CouponDiscountTypeEnums.PERCENTAGE_OFF:
    case CouponDiscountTypeEnums.BUY_ONE_GET_MORE:
      return discount.plans
    default:
      return []
  }
}

export const formatCouponTemplate = (couponTemplate: CouponTemplate): FormatCouponTemplate => {
  const splitRules = getSplitRules(couponTemplate.rules)
  const plans = getCouponTemplatePlans(couponTemplate.discount)
  return {
    ...couponTemplate,
    cities: splitRules.cityIds ?? [],
    couponUsageRule: splitRules.couponRule,
    forPeople: splitRules.forPeople ?? ForPeopleTypeEnums.ALL_USER,
    userIds: splitRules.userIds ?? [],
    corporateIds: splitRules.corporateIds ?? [],
    totalAmount: splitRules.totalAmount,
    refreshInterval: splitRules.refreshInterval,
    distributionFrequencyMode: splitRules.distributionFrequencyMode,
    usageLimit: splitRules.usageLimit,
    couponRuleType: splitRules?.couponRuleType,
    segmentId: splitRules?.segmentId,
    plans,
  }
}

export const formatCitySpecificRuleDetail = (
  rules: any[],
  activeCities: City[],
  locale: LocaleType,
) => {
  const splitRules = getSplitRules(rules)
  let cityLabel = ''
  if (splitRules?.cityIds) {
    cityLabel = getCityLabel(splitRules?.cityIds, activeCities, locale)
  }
  return cityLabel
}

export const getCityLabel = (
  cityIds: string | number[],
  activeCities: City[],
  locale: LocaleType,
) => {
  let cityIdList: number[] = []
  if (!cityIds) {
    return ''
  }
  if (typeof cityIds === 'string') {
    cityIdList = cityIds.split(',').map(item => Number(item))
  } else {
    cityIdList = cityIds
  }
  const cityNames = cityIdList.map(id => {
    const item = activeCities.find(city => city.id === id)
    return item?.translations.find(t => t.locale === locale)?.name
  })
  const cityLabel = _.join(cityNames, ',')
  return cityLabel
}
