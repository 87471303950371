import { Card, Space } from 'antd'
import NavBar from '../../components/nav-bar/nav-bar'
import { Outlet } from 'react-router-dom'
import translate from '../../i18n'

const FirstClassDiscountDisabledLessonsContainer = () => {
  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/first-class-discount-disabled-lessons\/[0-9]+\/edit$/)) {
      return translate('navBar.editFirstClassDiscountDisabledLesson')
    }

    if (pathName.match(/^\/first-class-discount-disabled-lessons\/new/)) {
      return translate('navBar.newFirstClassDiscountDisabledLesson')
    }

    return translate('navBar.firstClassDiscountDisabledLessonsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default FirstClassDiscountDisabledLessonsContainer
