import _ from 'lodash'
import moment from 'moment'

import {
  CorporateCreditsDistributionScheduleResponse,
  CorporateCreditsDistributionScheduleType,
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleFormValue,
  ISchedule,
  IScheduleFormValue,
  IntervalUnitType,
} from '../pages/corporates-container/share-type'
import { keysToCamel, keysToSnake } from './keys-to-case'

export const scheduleFormValueToRequest = (schedule: IScheduleFormValue): ISchedule =>
  keysToSnake({
    ...schedule,
    specificTimes: schedule.specificTimes?.map(time => time.format('YYYY-MM-DD')),
    startDate: schedule.startDate?.format('YYYY-MM-DD'),
    endDate: schedule.endDate?.format('YYYY-MM-DD'),
  })

export const formValueToRequest = (value: ICorporateCreditsDistributionScheduleFormValue) => {
  const { creditsValidityPeriod, schedule, ...restFields } = value

  return {
    ...restFields,
    schedule: scheduleFormValueToRequest(schedule),
    creditsValidityPeriod: keysToSnake(creditsValidityPeriod),
  }
}

export const CorporateCreditsDistributionScheduleToFormValue = (
  data: ICorporateCreditsDistributionSchedule,
) => {
  const { creditsValidityPeriod, schedule, ...restFields } = data
  const scheduleValue = keysToCamel(schedule)

  return {
    ...restFields,
    creditsValidityPeriod: keysToCamel(creditsValidityPeriod),
    schedule: {
      ...scheduleValue,
      specificTimes: scheduleValue.specificTimes?.map((time: string) => moment(time)),
      startDate: scheduleValue.startDate ? moment(scheduleValue.startDate) : undefined,
      endDate: scheduleValue.endDate ? moment(scheduleValue.endDate) : undefined,
    },
  }
}

export const formValueToFormData = (value: ICorporateCreditsDistributionScheduleFormValue) => {
  const data = new FormData()
  if (!value.isApplyForAllMembers) {
    if (value.file && value.file.length) {
      data.append('file', value.file[0].originFileObj)
    }
    if (value.phoneNumbers) {
      const phoneNumbers = _.split(value.phoneNumbers, ',')
      data.append('phoneNumbers', JSON.stringify(phoneNumbers))
    }
  }

  data.append('isApplyForAllMembers', value.isApplyForAllMembers.toString())
  data.append('credits', value.credits.toString())
  data.append('bufferPercentage', value.bufferPercentage.toString())
  data.append('isReclaimBeforeDistribute', value.isReclaimBeforeDistribute.toString())
  data.append('isActive', value.isActive.toString())
  data.append('notes', value.notes || '')
  data.append('ignoreInvalidPhoneNumbers', false.toString())

  if (value.schedule.type === CorporateCreditsDistributionScheduleType.REPEATABLE) {
    const repeatableSchedule = {
      type: value.schedule.type,
      startDate: value.schedule.startDate?.format('YYYY-MM-DD'),
      endDate: value.schedule.endDate?.format('YYYY-MM-DD'),
      interval: value.schedule.interval,
      intervalUnit: value.schedule.intervalUnit,
    }
    data.append('schedule', JSON.stringify(keysToSnake(repeatableSchedule)))
  } else if (value.schedule.type === CorporateCreditsDistributionScheduleType.SPECIFIC_TIME) {
    const specificTimesSchedule = {
      type: value.schedule.type,
      specificTimes: value.schedule?.specificTimes?.map(time => time.format('YYYY-MM-DD')),
    }
    data.append('schedule', JSON.stringify(keysToSnake(specificTimesSchedule)))
  }

  data.append(
    'creditsValidityPeriod',
    JSON.stringify(
      keysToSnake({
        duration: value.creditsValidityPeriod.duration,
        durationUnit: value.creditsValidityPeriod.durationUnit,
        extendDays: value.creditsValidityPeriod.extendDays,
      }),
    ),
  )
  return data
}

export function formatDuration(
  data:
    | {
        duration?: number
        interval?: number
        duration_unit?: string
        interval_unit?: string
        extend_days?: number
      }
    | undefined,
): string {
  if (!data) {
    return ''
  }

  const unitMap: { [key: string]: string } = {
    [IntervalUnitType.DAYS]: '天',
    [IntervalUnitType.MONTHS]: '月',
    [IntervalUnitType.QUARTERS]: '季度',
    [IntervalUnitType.YEARS]: '年',
  }

  const mainValue = data.duration ?? data.interval
  const mainUnit = data.duration_unit ?? data.interval_unit
  const extendDays = data.extend_days

  if (!mainUnit) {
    return ''
  }

  const durationStr = `${mainValue}${unitMap[mainUnit] || ''}`

  if (extendDays) {
    return `${durationStr}${extendDays > 0 ? '+' + extendDays : extendDays}天`
  } else {
    return durationStr
  }
}

export const extractPhoneNumberCheckResult = (
  result: CorporateCreditsDistributionScheduleResponse,
) => {
  const joinedOtherCorporatePhoneNumbers = result.joinedOtherCorporatePhoneNumbers || []
  const inactivePhoneNumbers = result.inactivePhoneNumbers || []
  const notCorporateMembers = result.notCorporateMembers || []

  const invalidResult = new Set<string>([
    ...joinedOtherCorporatePhoneNumbers,
    ...inactivePhoneNumbers,
    ...notCorporateMembers,
  ])

  return {
    invalidCount: invalidResult.size,
    validCount: result.successfulPhoneNumbers?.length || 0,
  }
}
