import { Button, Select, Space } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { IStudioAreaUserDto } from '../../../../share-types'
import styles from './billing-summary-month-selector.module.less'

interface IOption {
  label: string
  value: string
}

interface IProps {
  studioAdminUserInfo: IStudioAreaUserDto
  onChangeSelectedMonth: (value: string) => void
  value: string | null
}

const BillingSummaryMonthSelector = (props: IProps) => {
  const { studioAdminUserInfo, onChangeSelectedMonth, value } = props
  const [billingSummaryCreatedTimeOptions, setBillingSummaryCreatedTimeOptions] = useState<
    IOption[]
  >([])

  const [selectedCreatedTimeRangeValue, setSelectedCreatedTimeRangeValue] = useState<string | null>(
    null,
  )

  useEffect(() => {
    if (studioAdminUserInfo) {
      getBillingSummaryCreatedTimeOptions()
    }
  }, [studioAdminUserInfo])

  useEffect(() => {
    if (value) {
      const startDate = moment(value)
      const endDate = moment(value).endOf('month')
      setSelectedCreatedTimeRangeValue(
        `${startDate.format('YYYY年MM月DD日')} - ${endDate.format('YYYY年MM月DD日')}`,
      )
    }
  }, [value])

  const getBillingSummaryCreatedTimeOptions = () => {
    const organizationCreatedTime = studioAdminUserInfo.organization.createdAt
    const createdTimeOptions = []
    const startDate = moment(organizationCreatedTime)
    const endDate = moment()

    while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
      createdTimeOptions.push({
        label: startDate.format('YYYY年MM月'),
        value: startDate.startOf('month').toISOString(),
      })
      startDate.add(1, 'months')
    }

    setBillingSummaryCreatedTimeOptions(createdTimeOptions)
  }

  const onSelectedMonth = (value: string) => {
    onChangeSelectedMonth(value)
  }

  const onClickCurrentMonth = () => {
    onChangeSelectedMonth(moment().startOf('month').toISOString())
  }
  const onClickLastMonth = () => {
    onChangeSelectedMonth(moment().subtract(1, 'months').startOf('month').toISOString())
  }

  return (
    <div className="display-flex justify-between">
      <Space size={12}>
        <Button
          type={moment(value).isSame(moment(), 'month') ? 'primary' : 'default'}
          shape="round"
          onClick={onClickCurrentMonth}
        >
          本月概览
        </Button>
        <Button
          type={
            moment(value).isSame(moment().subtract(1, 'month'), 'month') ? 'primary' : 'default'
          }
          shape="round"
          onClick={onClickLastMonth}
        >
          上月概览
        </Button>
        <Select
          placeholder="选择想查看的月份"
          value={value}
          className={styles['billing-summary-month-select']}
          options={billingSummaryCreatedTimeOptions}
          onChange={onSelectedMonth}
        />
      </Space>

      <div>{selectedCreatedTimeRangeValue}</div>
    </div>
  )
}

export default BillingSummaryMonthSelector
