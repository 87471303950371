import { Table, Space, Button, Row, Col, Tag, Typography, Tooltip } from 'antd'
import styles from './coupon-templates.module.less'
import { PlusOutlined, DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import {
  CouponTemplateStatus,
  CouponTemplateTranslation,
  FormatCouponTemplate,
  ICouponTemplateFilterParams,
  IPaginatedCouponTemplateFilterParams,
  PaginatedCouponTemplate,
} from '../share-type'
import { useEffect, useState } from 'react'
import { getPaginatedCouponTemplates } from '../../../api/couponApi'
import { CouponTemplateStatusOptions, couponTypeList } from '../constant'
import { keysToCamel } from '../../../helpers/keys-to-case'
import { formatCouponTemplate, getDiscountDetail } from '../../../helpers/coupon-template-helper'
import { City, CityTranslation } from '../../../store/city/reducer'
import AuthPermission from '../../../high-order-components/auth-permission'
import translate from '../../../i18n'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import { Plan, PlanTranslation } from '../../../store/global/reducer'
import { ColumnsType } from 'antd/lib/table'
import LinkButton from '../../../components/link-button/link-button'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import CouponTemplateFilter from '../../../components/coupon-templates/coupon-template-filter/coupon-template-filter'

type PropsFromRedux = ConnectedProps<typeof withConnect>

type IProps = PropsFromRedux

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const CouponTemplates = (props: IProps) => {
  const navigate = useNavigate()
  const { activeCities, locale } = props
  const [couponTemplates, setCouponTemplates] = useState<FormatCouponTemplate[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ICouponTemplateFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'name', isBoolean: false },
        { key: 'code', isBoolean: false },
        { key: 'status', isBoolean: false },
        { key: 'discountType', isBoolean: false },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedCouponTemplatesAction(data)
  }, [])

  const getPaginatedCouponTemplatesAction = (data: IPaginatedCouponTemplateFilterParams) => {
    getPaginatedCouponTemplates(data).then((res: PaginatedCouponTemplate) => {
      const formatResponse = res.data.map((item: any) => formatCouponTemplate(keysToCamel(item)))
      setCouponTemplates(formatResponse)
      setPageInfo({
        ...res.pageInfo,
        currentPage: res.pageInfo.currentPage + 1,
      })
    })
  }

  const onSearch = (values: ICouponTemplateFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo.pageSize,
      ...values,
    }
    setSearchValues(values)
    setSearchParams(getSearchParams(values))
    getPaginatedCouponTemplatesAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedCouponTemplatesAction(data)
  }

  const columns: ColumnsType<FormatCouponTemplate> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('couponTemplates.name'),
      dataIndex: 'translations',
      key: 'couponName',
      render: (translations: CouponTemplateTranslation[], record: MISSING_TYPE) => {
        const couponNameList = translations.map(translation => translation.name)
        const couponName = couponNameList.join('/')
        return (
          <Space direction="vertical">
            <Tooltip placement="topLeft" title={couponName}>
              <Typography.Text style={{ maxWidth: 200 }} ellipsis>
                {couponName}
              </Typography.Text>
            </Tooltip>
            <Typography.Link style={{ maxWidth: 200 }} ellipsis copyable={{ tooltips: false }}>
              {record.code}
            </Typography.Link>
          </Space>
        )
      },
    },
    {
      title: translate('couponTemplates.discountType'),
      dataIndex: 'discount',
      key: 'couponType',
      filters: couponTypeList,
      render: (discount: any) => {
        const couponType = couponTypeList.find(item => item.value === discount.discountType)
        return <span>{couponType?.label}</span>
      },
      filterIcon: <DownOutlined />,
      onFilter: (value, record) => record?.discount.discountType.indexOf(value) === 0,
    },
    {
      title: '优惠详情',
      dataIndex: 'discount',
      key: 'discountOff',
      render: (discount: any, record) => {
        return <span>{getDiscountDetail(discount, record.translations[0].discountOff)}</span>
      },
    },
    {
      title: '课包详情',
      dataIndex: 'plans',
      key: 'plans',
      render: (couponTemplatePlans: any[]) => {
        const displayPlans = couponTemplatePlans
          .map((item: { planId: number; bonusCredits: number | null; price: number | null }) => {
            const filterPlan = props.plans.find((plan: Plan) => plan.id === item.planId)
            if (filterPlan) {
              const name = filterPlan.translations.find(
                (t: PlanTranslation) => t.locale === locale,
              )?.name
              if (item.price && filterPlan.price !== item.price) {
                return `${filterPlan.id}-${name}-¥${filterPlan.price / 100}-${
                  filterPlan.credits
                }点-(¥${item.price / 100})`
              }
              if (item.bonusCredits && filterPlan.credits !== item.bonusCredits) {
                return `${filterPlan.id}-${name}-¥${filterPlan.price / 100}-${
                  filterPlan.credits
                }点-(+${item.bonusCredits}点)`
              }

              return `${filterPlan.id}-${name}-¥${filterPlan.price / 100}-${filterPlan.credits}点`
            }
            return ''
          })
          .filter((item: string) => item)
        if (displayPlans.length) {
          return displayPlans.map((item, index) => {
            return <div key={index}>{item}</div>
          })
        } else {
          return ''
        }
      },
    },
    {
      title: '适用城市',
      dataIndex: 'cities',
      key: 'cities',
      render: (cities: number[]) => {
        let citiesLabel = ''
        if (!cities.length) {
          citiesLabel = activeCities
            .map((city: City) => {
              const translation = city.translations.find(
                (translation: CityTranslation) => translation.locale === locale,
              )
              return translation?.name
            })
            .join(',')
        } else {
          citiesLabel = cities
            .map((city: any) => {
              const findCity = activeCities.find((item: City) => item.id === city)
              const translation = findCity?.translations.find(
                (translation: CityTranslation) => translation.locale === locale,
              )
              return translation?.name
            })
            .join(',')
        }
        return <span>{citiesLabel}</span>
      },
      filters: activeCities.map((city: City) => {
        const translation = city.translations.find(
          (translation: CityTranslation) => translation.locale === locale,
        )
        return {
          text: translation?.name,
          value: city.id,
        }
      }),
      filterIcon: <DownOutlined />,
      onFilter: (value, record) => record.cities?.indexOf(Number(value)) === 0,
    },
    {
      title: translate('couponTemplates.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: string) => {
        return <span>{getDateTime(startDate)}</span>
      },
      sorter: (a, b) => {
        if (moment(a.startDate).isBefore(b.startDate)) {
          return 1
        }
        return 0
      },
    },
    {
      title: translate('couponTemplates.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate: string) => {
        return <span>{getDateTime(endDate)}</span>
      },
      sorter: (a, b) => {
        if (moment(a.endDate).isBefore(b.endDate)) {
          return 1
        }
        return 0
      },
    },
    {
      title: translate('couponTemplates.usedCount'),
      dataIndex: 'usedCount',
      key: 'usedCount',
      render: (usedCount: string) => {
        return <span>{usedCount}</span>
      },
      sorter: (a, b) => a.usedCount - b.usedCount,
    },
    {
      title: translate('couponTemplates.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: CouponTemplateStatus) => {
        const tag = CouponTemplateStatusOptions.find(item => item.value === status)?.label
        return <Tag color={status === CouponTemplateStatus.INACTIVE ? 'red' : 'green'}>{tag}</Tag>
      },
      filters: CouponTemplateStatusOptions,
      filterIcon: <DownOutlined />,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: any) => (
        <Space size="middle">
          <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
            <LinkButton
              to={`/coupon-templates/${record.id}/edit`}
              disabled={record.status === CouponTemplateStatus.INACTIVE}
            >
              {translate('edit')}
            </LinkButton>
          </AuthPermission>

          <LinkButton to={`/coupon-templates/${record.id}`}>{translate('view')}</LinkButton>

          <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
            <LinkButton to={`/coupon-templates/${record.id}/duplicate`}>
              {translate('duplicate')}
            </LinkButton>
          </AuthPermission>
        </Space>
      ),
    },
  ]

  return (
    <Space className={styles['coupon-list-space']} direction="vertical" size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['left-col']}>
          <div>优惠券模板列表</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['right-col']}>
          <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/coupon-templates/new')}
            >
              创建新的优惠券模板
            </Button>
          </AuthPermission>
        </Col>
      </Row>

      <div className={styles['filter-bar']}>
        <CouponTemplateFilter locale={locale} onSearch={onSearch} formData={searchValues} />
      </div>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={couponTemplates}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
    plans: state.global.plans,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CouponTemplates)
