import { Button, Descriptions, Space, Tag } from 'antd'
import YesOrNoTag from '../../common/YesOrNoTag'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import { ILessonCreditAdjustment } from '../../../pages/lesson-credit-adjustments-container/share-type'
import { PermissionsEnum } from '../../../share-types'
import AuthPermission from '../../../high-order-components/auth-permission'
import { CheckCircleOutlined, PlusCircleOutlined, StopOutlined } from '@ant-design/icons'

interface ILessonCreditAdjustmentDescriptionProps {
  lessonCreditAdjustment: ILessonCreditAdjustment
  handleActivate: (request: { isActive: boolean }, message?: string) => void
  handleEdit: () => void
}

const LessonCreditAdjustmentDescription = ({
  lessonCreditAdjustment,
  handleEdit,
  handleActivate,
}: ILessonCreditAdjustmentDescriptionProps) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      <Descriptions
        title={translate('navBar.lessonCreditAdjustmentsTitle')}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
        contentStyle={{ minWidth: 300 }}
        column={2}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_WRITE}>
              <Button
                danger={lessonCreditAdjustment.isActive}
                icon={lessonCreditAdjustment.isActive ? <StopOutlined /> : <CheckCircleOutlined />}
                onClick={() =>
                  handleActivate(
                    { isActive: !lessonCreditAdjustment.isActive },
                    lessonCreditAdjustment.isActive
                      ? translate('deactivate.success')
                      : translate('activate.success'),
                  )
                }
              >
                {lessonCreditAdjustment.isActive ? translate('deactivate') : translate('activate')}
              </Button>
            </AuthPermission>
            <AuthPermission permission={PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_WRITE}>
              <Button type="primary" icon={<PlusCircleOutlined />} onClick={handleEdit}>
                {translate('lessonCreditAdjustments.adjustCredits.edit')}
              </Button>
            </AuthPermission>
          </Space>
        }
      >
        <Descriptions.Item label="ID">{lessonCreditAdjustment.id}</Descriptions.Item>
        <Descriptions.Item label={translate('isActive')}>
          <YesOrNoTag value={lessonCreditAdjustment.isActive} />
        </Descriptions.Item>
        <Descriptions.Item label={translate('lessonCreditAdjustments.AdjustmentType')}>
          {lessonCreditAdjustment.adjustmentType}
        </Descriptions.Item>
        <Descriptions.Item label={translate('lessonCreditAdjustments.AdjustableType')}>
          {lessonCreditAdjustment.adjustableType}
        </Descriptions.Item>
        <Descriptions.Item label={translate('createdAt')}>
          {getDateTime(lessonCreditAdjustment.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('updatedAt')}>
          {getDateTime(lessonCreditAdjustment.updatedAt)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title={translate('rule')}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
        contentStyle={{ minWidth: 300 }}
        column={2}
        bordered
      >
        <Descriptions.Item label={translate('type')}>
          {lessonCreditAdjustment.rule.type}
        </Descriptions.Item>
        <Descriptions.Item label={translate('lessonCreditAdjustments.adjustCredits')}>
          {lessonCreditAdjustment.rule.adjustCredits}
        </Descriptions.Item>
        <Descriptions.Item label={translate('lessonCreditAdjustments.lessonDescriptionIds')}>
          {lessonCreditAdjustment.rule.lessonDescriptionIds &&
          lessonCreditAdjustment.rule.lessonDescriptionIds.length > 0
            ? lessonCreditAdjustment.rule.lessonDescriptionIds.map(name => (
                <Tag color="blue" key={name}>
                  {name}
                </Tag>
              ))
            : translate('lessonCreditAdjustments.lessonDescriptionIds.all')}
        </Descriptions.Item>
        <Descriptions.Item label={translate('lessonCreditAdjustments.dateRange')}>
          {lessonCreditAdjustment.rule.startDate && lessonCreditAdjustment.rule.endDate
            ? `${lessonCreditAdjustment.rule.startDate} ~ ${lessonCreditAdjustment.rule.endDate}`
            : '-'}
        </Descriptions.Item>

        <Descriptions.Item label={translate('lessonCreditAdjustments.lessonStartTimeRange')}>
          {lessonCreditAdjustment.rule.lessonStartTimeFrom &&
          lessonCreditAdjustment.rule.lessonStartTimeTo
            ? `${lessonCreditAdjustment.rule.lessonStartTimeFrom} ~ ${lessonCreditAdjustment.rule.lessonStartTimeTo}`
            : '-'}
        </Descriptions.Item>

        <Descriptions.Item label={translate('lessonCreditAdjustments.daysOfWeek')}>
          {lessonCreditAdjustment.rule.daysOfWeek
            ? lessonCreditAdjustment.rule.daysOfWeek.join(', ')
            : '-'}
        </Descriptions.Item>
      </Descriptions>

      {lessonCreditAdjustment.billingItemPricing && (
        <Descriptions
          title={translate('lessonCreditAdjustments.billingItemPricing')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label="ID">
            {lessonCreditAdjustment.billingItemPricing.id}
          </Descriptions.Item>
          <Descriptions.Item label="priceableId">
            {lessonCreditAdjustment.billingItemPricing.priceableId}
          </Descriptions.Item>
          <Descriptions.Item label="priceableType">
            {lessonCreditAdjustment.billingItemPricing.priceableType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('isActive')}>
            <YesOrNoTag value={lessonCreditAdjustment.billingItemPricing.isActive} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('lessonCreditAdjustments.billingItemPricing.completedClassPriceCents')}
          >
            {lessonCreditAdjustment.billingItemPricing.completedClassPriceCents}
          </Descriptions.Item>
          <Descriptions.Item label="completedClassCredits">
            {lessonCreditAdjustment.billingItemPricing.completedClassCredits}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('lessonCreditAdjustments.billingItemPricing.lateCancelledPriceCents')}
          >
            {lessonCreditAdjustment.billingItemPricing.lateCancelledPriceCents}
          </Descriptions.Item>
          <Descriptions.Item label="lateCancelledCredits">
            {lessonCreditAdjustment.billingItemPricing.lateCancelledCredits}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('lessonCreditAdjustments.billingItemPricing.noShowPriceCents')}
          >
            {lessonCreditAdjustment.billingItemPricing.noShowPriceCents}
          </Descriptions.Item>
          <Descriptions.Item label="noShowCredits">
            {lessonCreditAdjustment.billingItemPricing.noShowCredits}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('lessonCreditAdjustments.billingItemPricing.earlyVisitPriceCents')}
          >
            {lessonCreditAdjustment.billingItemPricing.earlyVisitPriceCents}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(lessonCreditAdjustment.billingItemPricing.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(lessonCreditAdjustment.billingItemPricing.updatedAt)}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Space>
  )
}

export default LessonCreditAdjustmentDescription
