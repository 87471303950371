import { Category } from '../share-types'
import dev from './config-dev'
import prod from './config-production'
import staging from './config-staging'

type Config = {
  mainApi: string
  authApi: string
  assetUrl: string
  guavapassUrl: string
  categories: Category[]
}

const config = {}

if (process.env.REACT_APP_ENV === 'staging') {
  Object.assign(config, staging)
} else if (process.env.REACT_APP_ENV === 'production') {
  Object.assign(config, prod)
} else if (process.env.REACT_APP_ENV === 'development') {
  Object.assign(config, dev)
}

export default config as Config
