import { notification, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { PageInfo } from '../../../share-types'
import { useState } from 'react'

import {
  CorporateCreditsRequest,
  ICorporatesFilterParams,
  ReclaimCorporateCreditsRequest,
} from '../share-type'
import {
  distributeCorporateCreditsByCorporateMember,
  editCorporateMember,
  reclaimCorporateCreditsByCorporateMember,
} from '../../../api/corporateApi'
import translate from '../../../i18n'
import { ICorporateMember } from '../share-type'
import DistributeCreditsModal from '../../../components/corporates/distribute-credits-modal/distribute-credits-modal'
import ReclaimCreditsModal from '../../../components/corporates/reclaim-credits-modal/reclaim-credits-modal'
import CorporateMemberCreditsHistories from '../../../components/corporate-member-credits-histories/corporate-member-credits-histories/corporate-member-credits-histories'
import BatchReclaimCreditsModal from '../../../components/corporates/batch-reclaim-credits-modal/batch-reclaim-credits-modal'
import CorporateMembersTable from '../../../components/corporate-members/corporate-members-table/corporate-members-table'
import NewCorporateMembersModal from '../../../components/corporate-members/new-corporate-members-modal/new-corporate-members-modal'
import BatchDeactivateMembersModal from '../../../components/corporates/batch-deactivate-members-modal/batch-deactivate-members-modal'

const { confirm } = Modal

interface ICorporateMembersProps {
  corporateId: number
  corporateMembers: ICorporateMember[]
  pageInfo: PageInfo
  reloadPageData: () => void
  paginationOnChange: (page: number, pageSize: number) => void
  onSearch: (values: ICorporatesFilterParams) => void
  searchValues: ICorporatesFilterParams | undefined
  isTrialCorporate: boolean
}

const CorporateMembers = (props: ICorporateMembersProps) => {
  const {
    corporateId,
    reloadPageData,
    corporateMembers,
    pageInfo,
    paginationOnChange,
    onSearch,
    searchValues,
    isTrialCorporate,
  } = props

  const [distributeCorporateMember, setDistributeCorporateMember] = useState<ICorporateMember>()
  const [reclaimCreditsModalVisible, setReclaimCreditsModalVisible] = useState<boolean>(false)
  const [reclaimCreditsLoading, setReclaimCreditsLoading] = useState<boolean>(false)
  const [reclaimCorporateMember, setReclaimCorporateMember] = useState<ICorporateMember>()
  const [openCreditsHistoriesModal, setOpenCreditsHistoriesModal] = useState<boolean>(false)
  const [creditsHistoriesModalCorporateMember, setCreditsHistoriesModalCorporateMember] =
    useState<ICorporateMember>()

  const [batchReclaimCreditsModalVisible, setBatchReclaimCreditsModalVisible] =
    useState<boolean>(false)

  const [distributeCreditsModalVisible, setDistributeCreditsModalVisible] = useState<boolean>(false)
  const [distributeCreditsLoading, setDistributeCreditsLoading] = useState<boolean>(false)
  const [newCorporateMembersModalVisible, setNewCorporateMembersModalVisible] =
    useState<boolean>(false)

  const [batchDeactivateMembersModalVisible, setBatchDeactivateMembersModalVisible] =
    useState<boolean>(false)

  const changeStatus = (corporateMember: ICorporateMember) => {
    if (corporateId) {
      confirm({
        title: translate('corporate.changeStatus.confirm', {
          status: corporateMember.isActive ? '停用' : '启用',
          phoneNumber: corporateMember.phoneNumber,
        }),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          editCorporateMember(corporateId, corporateMember.id, {
            isActive: !corporateMember.isActive,
          })
            .then(() => {
              reloadPageData()
              notification.success({
                message: translate('corporateMember.editSuccess'),
              })
            })
            .catch(err => {
              notification.error({
                message: translate('corporateMember.editFailed'),
                description: err?.message || '网络请求失败，请稍后重试',
              })
            })
        },
      })
    }
  }

  const distributeCredits = (corporateMember: ICorporateMember) => {
    if (corporateId && corporateMember) {
      setDistributeCorporateMember(corporateMember)
      setDistributeCreditsModalVisible(true)
    }
  }
  const onDistributedCreditsFormFinish = (value: CorporateCreditsRequest) => {
    if (corporateId && distributeCorporateMember?.id) {
      setDistributeCreditsLoading(true)
      distributeCorporateCreditsByCorporateMember(corporateId, distributeCorporateMember.id, value)
        .then(() => {
          notification.success({
            message: translate('corporate.credits.distributionSuccess'),
          })
          reloadPageData()
        })
        .catch(err => {
          notification.error({
            message: translate('corporate.credits.distributionFailed'),
            description: err.message ?? '',
          })
        })
        .finally(() => {
          setDistributeCreditsLoading(false)
          setDistributeCreditsModalVisible(false)
        })
    }
  }

  const reclaimCredits = (corporateMember: ICorporateMember) => {
    if (corporateId && corporateMember) {
      setReclaimCorporateMember(corporateMember)
      setReclaimCreditsModalVisible(true)
    }
  }

  const onReclaimCreditsFormFinish = (value: ReclaimCorporateCreditsRequest) => {
    if (corporateId && reclaimCorporateMember?.id) {
      setReclaimCreditsLoading(true)
      reclaimCorporateCreditsByCorporateMember(corporateId, reclaimCorporateMember.id, value)
        .then(() => {
          notification.success({
            message: translate('corporate.credits.reclaimSuccess'),
          })
          reloadPageData()
        })
        .catch(err => {
          notification.error({
            message: translate('corporate.credits.reclaimFailed'),
            description: err.message ?? translate('networkError'),
          })
        })
        .finally(() => {
          setReclaimCreditsLoading(false)
          setReclaimCreditsModalVisible(false)
        })
    }
  }

  const onViewCorprateMemberCreditsHistories = (record: ICorporateMember) => {
    setCreditsHistoriesModalCorporateMember(record)
    setOpenCreditsHistoriesModal(true)
  }

  const onBatchReclaimCredits = () => {
    confirm({
      title: translate('corporate.credits.reclaim.confirm'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setBatchReclaimCreditsModalVisible(true)
      },
    })
  }

  const onBatchReclaimCreditsFormFinish = () => {
    reloadPageData()
    setBatchReclaimCreditsModalVisible(false)
  }

  const onAddNewCorporateMember = () => {
    setNewCorporateMembersModalVisible(true)
  }

  const onNewCorporateMembersFormFinish = () => {
    reloadPageData()
    setNewCorporateMembersModalVisible(false)
  }

  const onBatchDeactivateMembers = () => {
    confirm({
      title: translate('corporate.batchDeactivateMembers.confirm'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setBatchDeactivateMembersModalVisible(true)
      },
    })
  }

  const onBatchDescativateMembersFinish = () => {
    reloadPageData()
    setBatchDeactivateMembersModalVisible(false)
  }

  return (
    <>
      <CorporateMembersTable
        corporateId={corporateId}
        corporateMembers={corporateMembers}
        pageInfo={pageInfo}
        changeStatus={changeStatus}
        distributeCredits={distributeCredits}
        reclaimCredits={reclaimCredits}
        onBatchReclaimCredits={onBatchReclaimCredits}
        onViewCorprateMemberCreditsHistories={onViewCorprateMemberCreditsHistories}
        paginationOnChange={paginationOnChange}
        onSearch={onSearch}
        searchValues={searchValues}
        onAddNewCorporateMember={onAddNewCorporateMember}
        onBatchDeactivateMembers={onBatchDeactivateMembers}
      />

      <DistributeCreditsModal
        open={distributeCreditsModalVisible}
        loading={distributeCreditsLoading}
        onFinish={onDistributedCreditsFormFinish}
        handleCancel={() => setDistributeCreditsModalVisible(false)}
        isTrialCorporate={isTrialCorporate}
      />

      <ReclaimCreditsModal
        open={reclaimCreditsModalVisible}
        loading={reclaimCreditsLoading}
        onFinish={onReclaimCreditsFormFinish}
        handleCancel={() => setReclaimCreditsModalVisible(false)}
      />

      <BatchReclaimCreditsModal
        corporateId={corporateId}
        open={batchReclaimCreditsModalVisible}
        handleSubmit={onBatchReclaimCreditsFormFinish}
        handleCancel={() => setBatchReclaimCreditsModalVisible(false)}
      />

      {creditsHistoriesModalCorporateMember && (
        <CorporateMemberCreditsHistories
          open={openCreditsHistoriesModal}
          corporateMember={creditsHistoriesModalCorporateMember}
          corporateId={corporateId}
          onClose={() => setOpenCreditsHistoriesModal(false)}
        />
      )}

      <NewCorporateMembersModal
        open={newCorporateMembersModalVisible}
        corporateId={corporateId}
        handleSubmit={onNewCorporateMembersFormFinish}
        handleCancel={() => setNewCorporateMembersModalVisible(false)}
      />

      <BatchDeactivateMembersModal
        corporateId={corporateId}
        open={batchDeactivateMembersModalVisible}
        handleCancel={() => setBatchDeactivateMembersModalVisible(false)}
        handleSubmit={onBatchDescativateMembersFinish}
      />
    </>
  )
}

export default CorporateMembers
