import OneTimeCodesTable from '../../../components/one-time-codes/one-time-codes-table/one-time-codes-table'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  IOneTimeCode,
  IOneTimeCodesFilterParams,
  IPaginatedOneTimeCodesParams,
} from '../share-type'
import { PageInfo } from '../../../share-types'
import { getSearchParams, getSearchValues } from '../../../helpers/utils'
import { getPaginatedOneTimeCodes } from '../../../api/oneTimeCodeApi'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const OneTimeCodes = () => {
  const [oneTimeCodes, setOneTimeCodes] = useState<IOneTimeCode[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IOneTimeCodesFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'code', isBoolean: false },
        { key: 'redeemedById', isBoolean: false },
        { key: 'isRedeemed', isBoolean: true },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
        { key: 'batchId', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedOneTimeCodesAction(data)
  }, [])

  const getPaginatedOneTimeCodesAction = (data: IPaginatedOneTimeCodesParams) => {
    getPaginatedOneTimeCodes(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setOneTimeCodes(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedOneTimeCodesAction(data)
  }

  const onSearch = (value: IOneTimeCodesFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedOneTimeCodesAction(data)
  }

  return (
    <OneTimeCodesTable
      oneTimeCodes={oneTimeCodes}
      pageInfo={pageInfo}
      paginationOnChange={paginationOnChange}
      onSearch={onSearch}
      searchValues={searchValues}
      isNeedBatchId={true}
    />
  )
}

export default OneTimeCodes
