import Login from '../pages/login/login'
import PrelaunchOrganizationFormContainer from '../pages/public/prelaunch-organization-form-container'
import PrelaunchLessonDescriptionForm from '../pages/public/prelaunch-organization-form-container/prelaunch-lesson-description-form/prelaunch-lesson-description-form'
import PrelaunchOrganizationForm from '../pages/public/prelaunch-organization-form-container/prelaunch-organization-form/prelaunch-organization-form'
import PrelaunchPaymentDetailForm from '../pages/public/prelaunch-organization-form-container/prelaunch-payment-detail-form/prelaunch-payment-detail-form'
import PrelaunchStudioForm from '../pages/public/prelaunch-organization-form-container/prelaunch-studio-form/prelaunch-studio-form'

const PublicRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/public/prelaunch-organization-form/:uniqueId',
    element: <PrelaunchOrganizationFormContainer />,
    children: [
      {
        index: true,
        element: <PrelaunchOrganizationForm />,
      },
      {
        path: 'studios/:organizationName',
        element: <PrelaunchStudioForm />,
      },
      {
        path: 'studios/:studioId/:organizationName/duplicate',
        element: <PrelaunchStudioForm />,
      },
      {
        path: 'lesson-descriptions/:organizationName',
        element: <PrelaunchLessonDescriptionForm />,
      },
      {
        path: 'lesson-descriptions/:lessonDescriptionId/:organizationName/duplicate',
        element: <PrelaunchLessonDescriptionForm />,
      },
      {
        path: 'payment-details/:organizationName',
        element: <PrelaunchPaymentDetailForm />,
      },
    ],
  },
]

export default PublicRoutes
