import { Button, DatePicker, Form, Select } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { ICorporateCreditsHistoryFilterParams } from '../../pages/corporates-container/share-type'
import translate from '../../i18n'
import { CorporateCreditsHistoryTypeEnumOptions } from '../../pages/corporates-container/constant'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const CorporateCreditsHistoriesFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: ICorporateCreditsHistoryFilterParams) => void
  formData: ICorporateCreditsHistoryFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      const { startDate, endDate, ...other } = formData
      form.setFieldsValue({
        ...other,
        startTimeRange: startDate && endDate ? [moment(startDate), moment(endDate)] : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: any) => {
    const { startTimeRange, ...other } = values

    onSearch({
      ...other,
      startDate: startTimeRange ? moment(startTimeRange[0]).utc().format() : undefined,
      endDate: startTimeRange ? moment(startTimeRange[1]).utc().format() : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="type" label={translate('corporateCreditsHistories.type')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {CorporateCreditsHistoryTypeEnumOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="startTimeRange"
        label={translate('corporateCreditsHistories.creatTimeRange')}
      >
        <RangePicker
          showTime
          ranges={{
            Today: [moment(), moment()],
            '7 days ago': [moment().add(-7, 'd').startOf('day'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CorporateCreditsHistoriesFilter
