import { Button, Col, Row, Space, Table, Tag } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import StudioLeaderboardCategoriesFilter from '../../../components/studio-leaderboard-categories/studio-leaderboard-categories-filter/studio-leaderboard-categories-filter'
import {
  IStudioLeaderboardCategoriesFilterParams,
  IStudioLeaderboardCategory,
  StudioLeaderboardCategoryTag,
} from '../share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import { getPaginatedStudioLeaderboardCategories } from '../../../api/studioLeaderboardCategoriesApi'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const StudioLeaderboardCategories = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [studioLeaderboardCategories, setStudioLeaderboardCategories] = useState<
    IStudioLeaderboardCategory[]
  >([])
  const [searchValues, setSearchValues] = useState<IStudioLeaderboardCategoriesFilterParams>()

  useEffect(() => {
    const searchName = searchParams.get('name')
    const newSearchValues = {
      name: searchName ?? undefined,
    }
    setSearchValues(newSearchValues)
    getPaginatedStudioLeaderboardCategoriesAction(newSearchValues)
  }, [])

  const getPaginatedStudioLeaderboardCategoriesAction = (
    data: IStudioLeaderboardCategoriesFilterParams,
  ) => {
    getPaginatedStudioLeaderboardCategories(data).then(res => {
      if (res) {
        setStudioLeaderboardCategories(res)
      }
    })
  }

  const onSearch = (value: IStudioLeaderboardCategoriesFilterParams) => {
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedStudioLeaderboardCategoriesAction(value)
  }

  const columns: ColumnsType<IStudioLeaderboardCategory> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('studioLeaderboardCategories.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('studioLeaderboardCategories.isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('studioLeaderboardCategories.studioLeaderboardCategoryTags'),
      dataIndex: 'studioLeaderboardCategoryTags',
      key: 'studioLeaderboardCategoryTags',
      width: 400,
      render: (studioLeaderboardCategoryTags: StudioLeaderboardCategoryTag[]) => {
        return studioLeaderboardCategoryTags.map(item => (
          <Tag key={item.tag.id}>
            {item.tag.id}-{item.tag.name}
          </Tag>
        ))
      },
    },
    {
      title: translate('studioLeaderboardCategories.iconImage'),
      dataIndex: 'iconImage',
      key: 'iconImage',
      render: (iconImage: MISSING_TYPE, record: IStudioLeaderboardCategory) => {
        return (
          <>
            {record?.iconSmallDefaultFileUrl && (
              <img src={record.iconSmallDefaultFileUrl} width={21} height={20} />
            )}
            {record?.iconSmallActiveFileUrl && (
              <img src={record.iconSmallActiveFileUrl} width={21} height={20} />
            )}
            {record?.iconLargeDefaultFileUrl && (
              <img src={record.iconLargeDefaultFileUrl} width={34} height={34} />
            )}
            {record?.iconLargeActiveFileUrl && (
              <img src={record.iconLargeActiveFileUrl} width={34} height={34} />
            )}
          </>
        )
      },
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IStudioLeaderboardCategory) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_WRITE}>
            <LinkButton to={`/studio-leaderboard-categories/${record.id}/edit`}>
              {translate('edit')}
            </LinkButton>
          </AuthPermission>

          <LinkButton to={`/studio-leaderboard-categories/${record.id}`}>
            {translate('view')}
          </LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.studioLeaderboardCategoriesTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/studio-leaderboard-categories/new')}
            >
              {translate('navBar.newStudioLeaderboardCategories')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <StudioLeaderboardCategoriesFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={studioLeaderboardCategories}
      />
    </Space>
  )
}

export default StudioLeaderboardCategories
