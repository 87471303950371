import moment from 'moment'
import { IPaginatedParams, PageInfo } from '../../share-types'
import {
  CorporateCreditsHistoryTypeEnum,
  CorporateMemberCreditsHistoryReferenceTypeEnums,
  CorporateMemberCreditsHistoryTypeEnums,
} from './constant'
import { TagActivityCategoryEnums } from '../tags-container/constant'

export type ICorporatesFilterParams = {
  name?: string
  isActive?: boolean
}

export type IPaginatedCorporatesParams = IPaginatedParams & ICorporatesFilterParams

export type ICorporate = {
  id: number
  name: string
  credits: number
  createdAt: string
  updatedAt: string
  isActive: boolean
  creditsValidityPeriodInDays: number
  tagIds: number[] | null
  allowActivityCategories: TagActivityCategoryEnums[] | null
  disallowActivityList: number[] | null
  maxCreditsUsageThreshold: number | null
  studioIds: number[] | null
  unusedDistributedCredits: number
  unusedDistributedCreditsThreshold: number
  isTrial: boolean
}

export type ICorporateReport = {
  distributedCredits: number
  memberUsedCredits: number
  memberUnusedCredits: number
  activatedMemberCount: number
  allMemberCount: number
}

export type ICorporateDetailResponse = {
  corporate: ICorporate
  report: ICorporateReport
}

export type PaginatedCorporatesResponse = {
  pageInfo: PageInfo
  data: ICorporate[]
}

export type ICorporateMembersFilterParams = {
  isActive?: boolean
  phoneNumber?: string
  expiredStartDate?: string
  expiredEndDate?: string
}

export type IPaginatedCorporateMembersParams = {
  page: number
  size: number
} & ICorporateMembersFilterParams

export type ICorporateMember = {
  id: number
  phoneNumber: string
  createdAt: string
  updatedAt: string
  isActive: boolean
  credits: number
  userCredits: number | null
  frozenCredits: number | null
  uid: string | null
  userId: number | null
  firstName: string
}

export type PaginatedCorporateMembersResponse = {
  pageInfo: PageInfo
  data: ICorporateMember[]
}

export type ICorporateMembersBulkUploadParams = {
  file: MISSING_TYPE
  phoneNumbers: string
}

export type IUpdateCorporateMembers = {
  isActive: boolean
}

export type CorporateRequest = {
  name: string
  allowActivityCategories?: TagActivityCategoryEnums[]
  disallowActivityList?: number[]
  maxCreditsUsageThreshold?: number
  studioIds?: number[]
  isActive: boolean
  creditsValidityPeriodInDays?: number
  file?: MISSING_TYPE
  unusedDistributedCreditsThreshold: number
  isTrial: boolean
}

export type ICorporateCreditsHistory = {
  id: number
  type: CorporateCreditsHistoryTypeEnum
  credits: number
  notes: string
  createdAt: string
  createdBy: string
  corporateId: number
}

export type ICorporateCreditsHistoryFilterParams = {
  type?: CorporateCreditsHistoryTypeEnum
  startDate?: string
  endDate?: string
}

export type IPaginatedCorporateCreditsHistoriesParams = IPaginatedParams & ICorporatesFilterParams

export type PaginatedCorporateCreditsHistoriesResponse = {
  pageInfo: PageInfo
  data: ICorporateCreditsHistory[]
}

export type CorporateCreditsRequest = {
  credits: number
  notes?: string
  bufferPercentage?: number
  expiredAt?: string
}

export type ReclaimCorporateCreditsRequest = {
  notes?: string
}

export type IBatchReclaimCreditsFormValue = {
  file: MISSING_TYPE
  phoneNumbers: string
  notes: string
  deactivate: boolean
  ignoreInactiveUsers?: boolean
}

export type IBatchUploadByCorporateIdResponse = {
  isSuccess: boolean
  inactivePhoneNumbers: string[] | null
  joinedOtherCorporatePhoneNumbers: string[] | null
  existingPhoneNumbers: string[] | null
  successfulPhoneNumbers: string[] | null
  notCorporateMembers: string[] | null
  corporateCreditsDistributionSchedule: string[] | null
}

export type IBatchDistributeCreditsFormValue = {
  file: MISSING_TYPE
  phoneNumbers: string
  credits: number
  notes: string
  activate?: boolean
  bufferPercentage: number
  expiredAt: moment.Moment
}

export type ICorporateMemberCreditsHistoryFilterParams = {
  type?: CorporateMemberCreditsHistoryTypeEnums
  referenceType?: CorporateMemberCreditsHistoryReferenceTypeEnums
  referenceId?: number
}

export type IPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdParams =
  ICorporateMemberCreditsHistoryFilterParams & IPaginatedParams

export type ICorporateMemberCreditsHistory = {
  id: number
  corporateId: number
  corporateMemberId: number
  userId: number
  credits: number
  type: CorporateMemberCreditsHistoryTypeEnums
  notes: string | null
  referenceId: number
  referenceType: CorporateMemberCreditsHistoryReferenceTypeEnums
  createdAt: string
  createdBy: string
}

export type PaginatedCorporateMemberCreditsHistoriesByCorproateMemberIdResponse = {
  pageInfo: PageInfo
  data: ICorporateMemberCreditsHistory[]
}

export type IBatchDeactiveMemberFormValue = {
  file: MISSING_TYPE
  phoneNumbers: string
  notes?: string
}

export enum CorporateCreditsDistributionScheduleType {
  SPECIFIC_TIME = 'SPECIFIC_TIME',
  REPEATABLE = 'REPEATABLE',
}

export enum IntervalUnitType {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  QUARTERS = 'QUARTERS',
  YEARS = 'YEARS',
}

export type IScheduleFormValue = {
  specificTimes?: moment.Moment[]
  type: CorporateCreditsDistributionScheduleType
  startDate?: moment.Moment
  interval?: number
  intervalUnit?: IntervalUnitType
  endDate?: moment.Moment
}

export type ICorporateCreditsDistributionScheduleFormValue = {
  file?: MISSING_TYPE
  phoneNumbers?: string
  isApplyForAllMembers: boolean
  credits: number
  bufferPercentage: number
  isReclaimBeforeDistribute: boolean
  isActive: boolean
  schedule: IScheduleFormValue
  creditsValidityPeriod: {
    duration: string
    durationUnit: IntervalUnitType
    extendDays: number
  }
  notes: string
}

export type ISchedule = {
  specific_times?: string[]
  type: CorporateCreditsDistributionScheduleType
  start_date?: string
  interval?: number
  interval_unit?: IntervalUnitType
  end_date?: string
}

export type CorporateCreditsDistributionScheduleResponse = {
  isSuccess: boolean
  inactivePhoneNumbers?: string[]
  successfulPhoneNumbers?: string[]
  joinedOtherCorporatePhoneNumbers?: string[]
  existingPhoneNumbers?: string[]
  notCorporateMembers?: string[]
  corporateCreditsDistributionSchedule?: ICorporateCreditsDistributionSchedule
}

export type ICorporateCreditsDistributionSchedule = {
  id: number
  corporateId: number
  isActive: boolean
  credits: number
  bufferPercentage: number
  creditsValidityPeriod?: {
    duration: number
    duration_unit: IntervalUnitType
    extend_days: number
  }
  notes?: string
  isApplyForAllMembers: boolean
  isReclaimBeforeDistribute: boolean
  schedule: ISchedule
  createdAt: string
  updatedAt: string
}

export type ICorporateCreditsDistributionScheduleMember = {
  id: number
  corporateCreditsDistributionScheduleId: number
  isActive: boolean
  createdAt: string
  updatedAt: string
  corporateMember: ICorporateMember
}

export type ICorporateCreditsDistributionScheduleHistory = {
  id: number
  corporateCreditsDistributionScheduleId: number
  status: string
  notes: string
  createdAt: string
}

export type PaginatedCorporateCreditsDistributionSchedulesResponse = {
  pageInfo: PageInfo
  data: ICorporateCreditsDistributionSchedule[]
}

export type ICorporateCreditsDistributionScheduleFilterParams = {
  isActive?: boolean
}

export type IPaginatedCorporateCreditsDistributionScheduleFilterParams = IPaginatedParams &
  ICorporateCreditsDistributionScheduleFilterParams
