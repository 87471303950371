import { Button, Col, Row, Space, Table, Tag, Typography } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import {
  getDateTime,
  getSearchParams,
  getSearchValues,
  getWeChatURLLinkIsExpiredTag,
} from '../../../helpers/utils'
import { IPaginatedShortURLsFilterParams, IShortURL, IShortURLsFilterParams } from '../share-type'
import { getPaginatedShortURLs } from '../../../api/shortURLApi'
import ShortURLsFilter from '../../../components/short-urls/short-urls-filter/short-urls-filter'
import ShortURLFormModal from '../../../components/short-urls/short-url-form-modal/short-url-form-modal'
import { IWeChatURLLink } from '../../wechat-url-links-container/share-type'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const { Paragraph } = Typography

const ShortURLs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [ShortURLs, setShortURLs] = useState<IShortURL[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IShortURLsFilterParams>()
  const [shortURLFormModalOpen, setShortURLFormModalOpen] = useState<boolean>(false)
  const [actionShortURL, setActionShortURL] = useState<IShortURL>()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
        { key: 'hashKey', isBoolean: false },
        { key: 'redirectUrl', isBoolean: false },
        { key: 'name', isBoolean: false },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedShortURLsAction(data)
  }, [])

  const getPaginatedShortURLsAction = (data: IPaginatedShortURLsFilterParams) => {
    getPaginatedShortURLs(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setShortURLs(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))

    getPaginatedShortURLsAction(data)
  }

  const editShortURL = (shortURL: IShortURL) => {
    setActionShortURL(shortURL)
    setShortURLFormModalOpen(true)
  }

  const createShortURL = () => {
    setActionShortURL(undefined)
    setShortURLFormModalOpen(true)
  }

  const onSearch = (value: IShortURLsFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(data))
    getPaginatedShortURLsAction(data)
  }

  const handleSubmit = () => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues({
      redirectUrl: undefined,
      hashKey: undefined,
    })
    setSearchParams(getSearchParams(data))
    getPaginatedShortURLsAction(data)
    setShortURLFormModalOpen(false)
  }

  const columns: ColumnsType<IShortURL> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('shortURLs.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('shortURLs.description'),
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: translate('shortURLs.redirectURL'),
      dataIndex: 'redirectUrl',
      key: 'redirectUrl',
      render: (redirectUrl: string) => {
        return <Paragraph copyable>{redirectUrl}</Paragraph>
      },
    },
    {
      title: translate('shortURLs.wechatURLLink'),
      key: 'wechatURLLink',
      children: [
        {
          title: translate('weChatURLLinks.path'),
          dataIndex: 'wechatURLLink',
          key: 'path',
          render: (wechatURLLink: IWeChatURLLink | null) => {
            if (wechatURLLink) {
              return <Paragraph>{wechatURLLink.path}</Paragraph>
            }
            return ''
          },
        },
        {
          title: translate('weChatURLLinks.parameters'),
          dataIndex: 'wechatURLLink',
          key: 'parameters',
          render: (wechatURLLink: IWeChatURLLink | null) => {
            if (wechatURLLink) {
              return <Paragraph>{wechatURLLink.parameters}</Paragraph>
            }
            return ''
          },
        },
        {
          title: translate('isExpired'),
          dataIndex: 'wechatURLLink',
          key: 'expiredAt',
          render: (wechatURLLink: IWeChatURLLink | null) => {
            if (wechatURLLink?.createdAt) {
              const tagLable = getWeChatURLLinkIsExpiredTag(wechatURLLink.createdAt)
              return <Tag color={tagLable === 'Expired' ? 'red' : 'green'}>{tagLable}</Tag>
            }
            return ''
          },
        },
      ],
    },
    {
      title: translate('shortURLs.hashKey'),
      dataIndex: 'hashKey',
      key: 'hashKey',
      render: (hashKey: string) => {
        return <Paragraph copyable>{hashKey}</Paragraph>
      },
    },
    {
      title: translate('shortURLs.shortURL'),
      dataIndex: 'shortUrl',
      key: 'shortUrl',
      render: (urlLink: string) => {
        return <Paragraph copyable>{urlLink}</Paragraph>
      },
    },
    {
      title: translate('shortURLs.isRedirectUrlRefreshable'),
      dataIndex: 'isRedirectUrlRefreshable',
      key: 'isRedirectUrlRefreshable',
      render: (isRedirectUrlRefreshable: boolean) => {
        return (
          <Tag color={isRedirectUrlRefreshable ? 'green' : 'red'}>
            {isRedirectUrlRefreshable ? 'Yes' : 'No'}
          </Tag>
        )
      },
    },
    {
      title: translate('shortURLs.refreshIntervalDays'),
      dataIndex: 'refreshIntervalDays',
      key: 'refreshIntervalDays',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: IShortURL) => {
        return (
          <Space>
            <AuthPermission permission={PermissionsEnum.SHORT_URLS_WRITE}>
              <Button type="link" onClick={() => editShortURL(record)}>
                {translate('edit')}
              </Button>
            </AuthPermission>
          </Space>
        )
      },
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.shortURLsTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Space size={16}>
            <AuthPermission permission={PermissionsEnum.SHORT_URLS_WRITE}>
              <Button type="primary" icon={<PlusOutlined />} onClick={createShortURL}>
                {translate('navBar.newShortURLs')}
              </Button>
            </AuthPermission>
          </Space>
        </Col>
      </Row>

      <Row className="filter-bar">
        <ShortURLsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={ShortURLs}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />

      <ShortURLFormModal
        open={shortURLFormModalOpen}
        handleCancel={() => setShortURLFormModalOpen(false)}
        handleSubmit={handleSubmit}
        shortURL={actionShortURL}
      />
    </Space>
  )
}

export default ShortURLs
