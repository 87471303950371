import React, { useEffect, useRef } from 'react'
import styles from './container.module.less'
import { Layout, Menu, Space } from 'antd'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch, ReduxStore } from '../../../store'
import { clearUserInfoAction } from '../../../store/userInfo/action'
import { clearAuthAction } from '../../../store/userAuth/action'
import type { MenuProps } from 'antd'
import { BillingIcon, InvoiceIcon } from '../../../components/icons'
import { UserOutlined, LogoutOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { LogoIcon } from '../../../components/icons'

const { Sider, Content } = Layout

type IProps = ConnectedProps<typeof withConnect>
type MenuItem = Required<MenuProps>['items'][number]

const Container = (props: IProps) => {
  const { studioAdminUserInfo } = props
  const location = useLocation()
  const [collapsed, setCollapsed] = React.useState(false)
  const layoutRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (layoutRef.current) {
      layoutRef.current.scrollTop = 0
    }
  }, [location])

  const getDefalutSelectedKeys = () => {
    const path = location.pathname.split('/').filter(item => item)
    if (path[1]) {
      return [path[1]]
    } else {
      return ['billing-summaries']
    }
  }

  const items: MenuItem[] = [
    {
      label: <Link to="billing-summaries">结算账单</Link>,
      key: 'billing-summaries',
      icon: <BillingIcon />,
    },
    {
      label: <Link to="billing-invoices">发票相关</Link>,
      key: 'billing-invoices',
      icon: <InvoiceIcon />,
    },
  ]

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider
        collapsedWidth={100}
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={styles['silder']}
      >
        <div className={styles['silder-content']}>
          <div
            className={
              styles['silder-logo'] + (collapsed ? ` ${styles['silder-logo-collapsed']}` : '')
            }
          >
            <LogoIcon />
            {!collapsed && <span className={styles['logo-text']}>ClassPass万馆达</span>}
          </div>
          <Menu
            className={styles['silder-menu']}
            theme="dark"
            defaultSelectedKeys={getDefalutSelectedKeys()}
            mode="inline"
            items={items}
          />
          <Space direction="vertical" className={styles['silder-footer']}>
            <Space>
              <UserOutlined className={styles['user-icon']} />
              {!collapsed && (
                <Space direction="vertical">
                  <div className={styles['user-email']}>{studioAdminUserInfo?.email}</div>
                  <div className={styles['user-role']}>VP Administer</div>
                </Space>
              )}
            </Space>

            <Space className={styles['silder-footer-operation']}>
              <LogoutOutlined className={styles['logout-icon']} onClick={props.clearAuth} />
              {collapsed ? (
                <RightOutlined
                  className={styles['right-icon']}
                  onClick={() => setCollapsed(false)}
                />
              ) : (
                <LeftOutlined className={styles['left-icon']} onClick={() => setCollapsed(true)} />
              )}
            </Space>
          </Space>
        </div>
      </Sider>
      <Layout className={styles['site-layout']} ref={layoutRef}>
        <Content style={{ margin: '0 16px' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    studioAdminUserInfo: state.studioAdminUserInfo,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearAuth() {
      dispatch(clearAuthAction())
    },
    clearUserInfo() {
      dispatch(clearUserInfoAction())
    },
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Container)
