import config from '../config'
import {
  IPaginatedStudioIntegrationConfigFilterParams,
  IStudioIntegrationConfig,
  IStudioIntegrationLessonConfig,
  IStudioIntegrationLessonConfigFilterParams,
  IStudioIntegrationLessonDescriptionConfig,
  PaginatedStudioIntegrationConfigResponse,
} from '../pages/studio-integration-config-container/share-types'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedStudioIntegrationConfigs = (
  data: IPaginatedStudioIntegrationConfigFilterParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedStudioIntegrationConfigResponse>(
    `${mainApi}/studio-integration-configs`,
    {
      params: data,
    },
  )

export const getStudioIntegrationConfigDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IStudioIntegrationConfig>(
    `${mainApi}/studio-integration-configs/${id}`,
  )

export const getStudioIntegrationLessonConfigs = (
  data: IStudioIntegrationLessonConfigFilterParams,
) =>
  authRequest.get<MISSING_TYPE, IStudioIntegrationLessonConfig[]>(
    `${mainApi}/studio-integration-lesson-configs`,
    {
      params: data,
    },
  )

export const getStudioIntegrationLessonConfigDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IStudioIntegrationLessonConfig>(
    `${mainApi}/studio-integration-lesson-configs/${id}`,
  )

export const createStudioIntegrationLessonConfig = (data: MISSING_TYPE) =>
  authRequest.post<MISSING_TYPE, IStudioIntegrationLessonConfig>(
    `${mainApi}/studio-integration-lesson-configs`,
    data,
  )

export const editStudioIntegrationLessonConfig = (id: number, data: MISSING_TYPE) =>
  authRequest.put<MISSING_TYPE, IStudioIntegrationLessonConfig>(
    `${mainApi}/studio-integration-lesson-configs/${id}`,
    data,
  )

export const getStudioIntegrationLessonDescriptionConfigs = (
  data: IStudioIntegrationLessonConfigFilterParams,
) =>
  authRequest.get<MISSING_TYPE, IStudioIntegrationLessonDescriptionConfig[]>(
    `${mainApi}/studio-integration-lesson-description-configs`,
    {
      params: data,
    },
  )

export const getStudioIntegrationLessonDescriptionConfigDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IStudioIntegrationLessonDescriptionConfig>(
    `${mainApi}/studio-integration-lesson-description-configs/${id}`,
  )

export const createStudioIntegrationLessonDescriptionConfig = (data: MISSING_TYPE) =>
  authRequest.post<MISSING_TYPE, IStudioIntegrationLessonDescriptionConfig>(
    `${mainApi}/studio-integration-lesson-description-configs`,
    data,
  )

export const editStudioIntegrationLessonDescriptionConfig = (id: number, data: MISSING_TYPE) =>
  authRequest.put<MISSING_TYPE, IStudioIntegrationLessonDescriptionConfig>(
    `${mainApi}/studio-integration-lesson-description-configs/${id}`,
    data,
  )

export const getStudioIntegrationLessonFieldsAutoSetRules = (data: MISSING_TYPE) =>
  authRequest.get<MISSING_TYPE, string[]>(
    `${mainApi}/studio-integration-lesson-fields-auto-set-rules`,
    {
      params: data,
    },
  )

export const getStudioIntegrationLessonDescriptionFieldsAutoSetRules = (data: MISSING_TYPE) =>
  authRequest.get<MISSING_TYPE, string[]>(
    `${mainApi}/studio-integration-lesson-description-fields-auto-set-rules`,
    {
      params: data,
    },
  )
