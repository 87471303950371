import { Button, Descriptions, notification, Space, Tag } from 'antd'

import YesOrNoTag from '../../common/YesOrNoTag'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import { IStudio } from '../../../pages/studios-container/share-type'
import {
  StudioStatusEnumOptions,
  StudioStatusEnums,
} from '../../../pages/studios-container/constants'
import LinkButton from '../../link-button/link-button'
import { ITag } from '../../../pages/tags-container/share-type'
import StudioFirstClassDiscountsFormModal from '../studio-first-class-discounts-form-modal/studio-first-class-discounts-form-modal'
import { useState } from 'react'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import { StudioFirstClassDiscountRequest } from '../../../pages/first-class-discount-disabled-lessons-container/share-type'
import { createFirstClassDiscountDisabledLessonByStudio } from '../../../api/firstClassDiscountDisabledLessonsApi'
interface Iprops {
  studio: IStudio
  onLoadStudioDetail: () => void
}
const StudioDescriptions = (props: Iprops) => {
  const { studio, onLoadStudioDetail } = props
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const renderStatus = (status: StudioStatusEnums) => {
    const statusOption = StudioStatusEnumOptions.find(option => option.value === status)
    if (statusOption) {
      return <Tag color={statusOption.color}>{statusOption.label}</Tag>
    }
  }

  const onShowStudioFirstClassDiscountsFormModal = () => {
    setIsOpen(true)
  }

  const onSubmitStudioFirstClassDiscountsFormModal = (values: StudioFirstClassDiscountRequest) => {
    setLoading(true)
    createFirstClassDiscountDisabledLessonByStudio(studio.id, values)
      .then(() => {
        onLoadStudioDetail()
        notification.success({
          message: studio?.firstClassDiscount
            ? translate('firstClassDiscount.update.success')
            : translate('firstClassDiscount.create.success'),
        })
      })
      .catch(err => {
        notification.error({
          message: studio?.firstClassDiscount
            ? translate('firstClassDiscount.update.error')
            : translate('firstClassDiscount.create.error'),
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        setLoading(false)
        setIsOpen(false)
      })
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title={translate('navBar.studiosTitle')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300, maxWidth: 600 }}
          column={2}
          bordered
        >
          <Descriptions.Item label="ID">{studio?.id}</Descriptions.Item>
          <Descriptions.Item label={translate('studioTranslation.name')}>
            {studio?.studioTranslations.length ? studio?.studioTranslations[0]?.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.cityId')}>{studio?.cityId}</Descriptions.Item>
          <Descriptions.Item label={translate('studio.organizationId')}>
            <LinkButton
              to={`/organizations/${studio?.organizationId}`}
              style={{ padding: 0, textAlign: 'left' }}
            >
              {studio?.organizationId}
            </LinkButton>
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.neighborhoodId')}>
            {studio?.neighborhoodId}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.neighborhoodSlug')}>
            {studio?.neighborhoodSlug}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.externalProviderId')}>
            {studio?.externalProviderId}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.zip')}>{studio?.zip}</Descriptions.Item>
          <Descriptions.Item label={translate('studio.phoneNumber')}>
            {studio?.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.email')}>{studio?.email}</Descriptions.Item>
          <Descriptions.Item label={translate('studio.timeZone')}>
            {studio?.timeZone}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.bookBefore')}>
            {studio?.bookBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.cancelBefore')}>
            {studio?.cancelBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.latitude')}>
            {studio?.latitude}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.longitude')}>
            {studio?.longitude}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.cachedActivityList')}>
            {studio?.cachedActivityList}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.cachedNeighborhoodList')}>
            {studio?.cachedNeighborhoodList}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.status')}>
            {renderStatus(studio?.status)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.businessDistrictId')}>
            {studio?.businessDistrictId}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.isSurgePricingEnabled')}>
            <YesOrNoTag value={studio?.isSurgePricingEnabled} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.maxVisitTimesThreshold')}>
            {studio?.maxVisitTimesThreshold}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.ratingScore')}>
            {studio?.ratingScore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.ratingVotes')}>
            {studio?.ratingVotes}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.isRequiredQrCodeCheckIn')}>
            <YesOrNoTag value={studio?.isRequiredQrCodeCheckIn} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.needUploadScheduleReminder')}>
            <YesOrNoTag value={studio?.needUploadScheduleReminder} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.checkInOpenInMinutes')}>
            {studio?.checkInOpenInMinutes}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.checkInDurationInMinutes')}>
            {studio?.checkInDurationInMinutes}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.integratorLessonSync')}>
            {studio?.integratorLessonSync}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(studio?.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(studio?.updatedAt)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={translate('studio.picturesAndBanners')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300, maxWidth: 600 }}
          column={2}
          bordered
        >
          <Descriptions.Item label={translate('studio.profileFileName')}>
            {studio?.profileFileName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.profileContentType')}>
            {studio?.profileContentType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.profileFileSize')}>
            {studio?.profileFileSize}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.profileUpdatedAt')}>
            {studio?.profileUpdatedAt ? getDateTime(studio?.profileUpdatedAt) : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.bannerFileName')}>
            {studio?.bannerFileName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.bannerContentType')}>
            {studio?.bannerContentType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.bannerFileSize')}>
            {studio?.bannerFileSize}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studio.bannerUpdatedAt')}>
            {studio?.bannerUpdatedAt ? getDateTime(studio?.bannerUpdatedAt) : 'N/A'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={translate('taggings')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          {Object.entries(studio?.taggings).map(([key, value]) => (
            <Descriptions.Item label={key} key={key}>
              {value.map((tag: ITag, index: number) => {
                return (
                  <Tag key={index} color="blue">
                    {tag.name}
                  </Tag>
                )
              })}
            </Descriptions.Item>
          ))}
        </Descriptions>

        <Descriptions
          title={translate('firstClassDiscount')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300, maxWidth: 600 }}
          column={2}
          bordered
          extra={
            <AuthPermission permission={PermissionsEnum.FIRST_CLASS_DISCOUNTS_WRITE}>
              <Button type="primary" onClick={onShowStudioFirstClassDiscountsFormModal}>
                {studio?.firstClassDiscount
                  ? translate('firstClassDiscount.update')
                  : translate('firstClassDiscount.create')}
              </Button>
            </AuthPermission>
          }
        >
          <Descriptions.Item label={translate('firstClassDiscount.discountPercentage')}>
            {studio?.firstClassDiscount?.discountPercentage ?? 'N/A'}%
          </Descriptions.Item>
          <Descriptions.Item label={translate('firstClassDiscount.isDisabled')}>
            <YesOrNoTag value={studio?.firstClassDiscount?.isDisabled} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('firstClassDiscount.isFixed')}>
            <YesOrNoTag value={studio?.firstClassDiscount?.isFixed} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {studio?.firstClassDiscount?.updatedAt
              ? getDateTime(studio?.firstClassDiscount?.updatedAt)
              : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {studio?.firstClassDiscount?.createdAt
              ? getDateTime(studio?.firstClassDiscount?.createdAt)
              : 'N/A'}
          </Descriptions.Item>

          <Descriptions.Item label={translate('updatedBy')}>
            {studio?.firstClassDiscount?.updatedBy ?? 'N/A'}
          </Descriptions.Item>
        </Descriptions>

        {studio.studioTranslations.map((studioTranslation, index) => (
          <Descriptions
            title={`${translate('studio.studioTranslation')} - ${studioTranslation.locale}`}
            labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
            contentStyle={{ minWidth: 300, maxWidth: 600 }}
            column={2}
            bordered
            key={index}
          >
            <Descriptions.Item label={translate('studioTranslation.name')}>
              {studioTranslation.name}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.address1')}>
              {studioTranslation.address1}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.address2')}>
              {studioTranslation.address2}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.howToFindStudio')}>
              {studioTranslation.howToFindStudio}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.transitToStudioPath')}>
              {studioTranslation.transitToStudioPath}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.country')}>
              {studioTranslation.country}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.city')}>
              {studioTranslation.city}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.area')}>
              {studioTranslation.area}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.state')}>
              {studioTranslation.state}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.highlights')}>
              {studioTranslation.highlights}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.blurb')}>
              {studioTranslation.blurb}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.description')}>
              {studioTranslation.description}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.specialNoteToUsers')}>
              {studioTranslation.specialNoteToUsers}
            </Descriptions.Item>
            <Descriptions.Item label={translate('studioTranslation.announcement')}>
              {studioTranslation.announcement}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Space>

      <StudioFirstClassDiscountsFormModal
        loading={loading}
        isOpen={isOpen}
        formData={studio?.firstClassDiscount}
        onClose={() => setIsOpen(false)}
        onSubmit={onSubmitStudioFirstClassDiscountsFormModal}
      />
    </>
  )
}

export default StudioDescriptions
