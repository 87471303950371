import { Button, Form, InputNumber, Radio, Space } from 'antd'
import translate from '../../../i18n'
import { useEffect } from 'react'
import { ExchangeRateAdjustmentRequestParams } from '../../../pages/exchange-rates-container/share-type'

interface ExchangeRateAdjustmentFormProps {
  loading: boolean
  onSubmit: (exchangeRate: ExchangeRateAdjustmentRequestParams) => void
  onCancel: () => void
  isResetForm?: boolean
}

enum ExchangeRateMode {
  ExchangeRate,
  BatchUpdateCredits,
}

interface ExchangeRateAdjustmentFormValues {
  exchangeRateMode: ExchangeRateMode
  rate?: number
  adjustCredits?: number
}

const ExchangeRateAdjustmentForm = (props: ExchangeRateAdjustmentFormProps) => {
  const { onCancel, onSubmit, isResetForm, loading } = props
  const [form] = Form.useForm()
  const exchangeRateModeValue = Form.useWatch('exchangeRateMode', form)

  useEffect(() => {
    if (isResetForm) {
      form.resetFields()
    }
  }, [isResetForm])

  const handleSubmit = (value: ExchangeRateAdjustmentFormValues) => {
    const { rate, adjustCredits } = value
    onSubmit({
      rate,
      adjustCredits,
    })
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{ exchangeRateMode: ExchangeRateMode.ExchangeRate }}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
    >
      <Form.Item label={translate('exchangeRates.exchangeRateChangeMode')} name="exchangeRateMode">
        <Radio.Group>
          <Radio value={ExchangeRateMode.ExchangeRate}>
            {translate('exchangeRates.exchangeRate')}
          </Radio>
          <Radio value={ExchangeRateMode.BatchUpdateCredits}>
            {translate('exchangeRates.batchUpdateCredits')}
          </Radio>
        </Radio.Group>
      </Form.Item>

      {exchangeRateModeValue === ExchangeRateMode.ExchangeRate ? (
        <Form.Item label={translate('exchangeRates.exchangeRate')} name="rate">
          <InputNumber
            min={0}
            step={0.01}
            placeholder={translate('exchangeRates.exchangeRate.message')}
            style={{ width: '150px' }}
            precision={2}
          />
        </Form.Item>
      ) : (
        <Form.Item label={translate('exchangeRates.batchUpdateCredits')} name="adjustCredits">
          <InputNumber
            step={1}
            precision={0}
            placeholder={translate('exchangeRates.batchUpdateCredits.message')}
            addonAfter="点数"
          />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 4, span: 14 }} style={{ marginBottom: 0 }}>
        <p>
          <span style={{ color: 'red' }}>*</span>
          {translate('exchangeRates.adjuestment.tips1')}
        </p>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 4, span: 14 }} style={{ marginBottom: 0 }}>
        <p>
          <span style={{ color: 'red' }}>*</span>
          {translate('exchangeRates.adjuestment.tips2')}
        </p>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            {translate('submit')}
          </Button>
          <Button onClick={onCancel} loading={loading}>
            {translate('cancel')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default ExchangeRateAdjustmentForm
