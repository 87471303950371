import translate from '../../../i18n'
import {
  IStudioInfoTranslation,
  IStudioLeaderboardRanker,
} from '../../../pages/studio-leaderboards-container/share-type'
import { LocaleType } from '../../../share-types'
import { Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { LocaleOptions } from '../../../helpers/constant'
import FormattedMessage from '../../intl/format-message'

interface Iprops {
  dataSource: IStudioLeaderboardRanker[]
}

const StudioLeaderboardsBoardRankers = (props: Iprops) => {
  const { dataSource } = props
  const columns: ColumnsType<IStudioLeaderboardRanker> = [
    {
      title: '排名',
      dataIndex: 'rank',
      key: 'rank',
      width: 100,
      fixed: 'left',
      sorter: (a: any, b: any) => a.rank - b.rank,
    },
    {
      title: 'studio ID',
      dataIndex: 'studioId',
      key: 'studioId',
      fixed: 'left',
      width: 150,
      sorter: (a: any, b: any) => a.studioId - b.studioId,
    },
    {
      title: `${translate('studioLeaderboards.translations')} ${
        LocaleOptions.find(lang => lang.value === LocaleType.zh_CN)?.label
      }`,
      key: 'zh-cn',
      children: [
        {
          title: 'name',
          dataIndex: 'translations',
          key: 'name',
          width: 150,
          render: (translations: IStudioInfoTranslation[]) => {
            const zhCnStudioInfo = translations.find(i => i.locale === LocaleType.zh_CN)
            return <Typography.Text ellipsis>{zhCnStudioInfo?.name ?? ''}</Typography.Text>
          },
        },
        {
          title: 'description',
          dataIndex: 'translations',
          key: 'description',
          width: 200,
          render: (translations: IStudioInfoTranslation[]) => {
            const zhCnStudioInfo = translations.find(i => i.locale === LocaleType.zh_CN)
            return <Typography.Text ellipsis>{zhCnStudioInfo?.description ?? ''}</Typography.Text>
          },
        },
        {
          title: 'address1',
          dataIndex: 'translations',
          key: 'address1',
          width: 100,
          render: (translations: IStudioInfoTranslation[]) => {
            const zhCnStudioInfo = translations.find(i => i.locale === LocaleType.zh_CN)
            return <Typography.Text ellipsis>{zhCnStudioInfo?.address1 ?? ''}</Typography.Text>
          },
        },
        {
          title: 'address2',
          dataIndex: 'translations',
          key: 'address2',
          width: 100,
          render: (translations: IStudioInfoTranslation[]) => {
            const zhCnStudioInfo = translations.find(i => i.locale === LocaleType.zh_CN)
            return <Typography.Text ellipsis>{zhCnStudioInfo?.address2 ?? ''}</Typography.Text>
          },
        },
      ],
    },
    {
      title: `${translate('studioLeaderboards.translations')} ${
        LocaleOptions.find(lang => lang.value === LocaleType.en)?.label
      }`,
      key: 'en',
      children: [
        {
          title: 'name',
          dataIndex: 'translations',
          key: 'name_en',
          width: 150,
          render: (translations: IStudioInfoTranslation[]) => {
            const enStudioInfo = translations.find(i => i.locale === LocaleType.en)
            return <Typography.Text ellipsis>{enStudioInfo?.name ?? ''}</Typography.Text>
          },
        },
        {
          title: 'description',
          dataIndex: 'translations',
          key: 'description_en',
          width: 200,
          render: (translations: IStudioInfoTranslation[]) => {
            const enStudioInfo = translations.find(i => i.locale === LocaleType.en)
            return <Typography.Text ellipsis>{enStudioInfo?.description ?? ''}</Typography.Text>
          },
        },
        {
          title: 'address1',
          dataIndex: 'translations',
          key: 'address1_en',
          width: 100,
          render: (translations: IStudioInfoTranslation[]) => {
            const enStudioInfo = translations.find(i => i.locale === LocaleType.en)
            return <Typography.Text ellipsis>{enStudioInfo?.address1 ?? ''}</Typography.Text>
          },
        },
        {
          title: 'address2',
          dataIndex: 'translations',
          key: 'address2_en',
          width: 100,
          render: (translations: IStudioInfoTranslation[]) => {
            const enStudioInfo = translations.find(i => i.locale === LocaleType.en)
            return <Typography.Text ellipsis>{enStudioInfo?.address2 ?? ''}</Typography.Text>
          },
        },
      ],
    },
  ]
  return (
    <Space direction="vertical" wrap size={16}>
      <Typography.Title level={5} style={{ marginTop: '25px' }}>
        <FormattedMessage id="studioLeaderboards.studioRankers" />
      </Typography.Title>

      <Table
        rowKey={record => record.studioId}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 1000 }}
        style={{ width: 1200 }}
      />
    </Space>
  )
}

export default StudioLeaderboardsBoardRankers
