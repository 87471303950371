import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './lesson-description.module.less'
import { ILessonDescription } from '../share-type'
import { getLessonDescriptionDetail } from '../../../api/lessonDescriptionsApi'
import LessonDescriptionDescriptions from '../../../components/lesson-descriptions/lesson-description-descriptions/lesson-description-descriptions'
import UpdateTaggings from '../../../components/tags/update-taggings/update-taggings'
import { TaggableTypeEnums } from '../../tags-container/constant'

const LessonDescription = () => {
  const params = useParams()
  const [lessonDescriptionId, setLessonDescriptionId] = useState<string | undefined>(params?.id)
  const [lessonDescription, setLessonDescription] = useState<ILessonDescription>()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setLessonDescriptionId(params?.id)
  }, [params?.id])

  useEffect(() => {
    loadLessonDescriptionDetail()
  }, [lessonDescriptionId])

  const loadLessonDescriptionDetail = () => {
    if (lessonDescriptionId) {
      getLessonDescriptionDetail(Number(lessonDescriptionId)).then(res => {
        setLessonDescription(res)
      })
    }
  }

  const onEditTaggings = () => {
    setOpen(true)
  }

  const handleSubmit = () => {
    setOpen(false)
    loadLessonDescriptionDetail()
  }

  return (
    <Space
      className={styles['lesson-description-desc-wrapper']}
      direction="vertical"
      wrap
      size={16}
    >
      {lessonDescription && (
        <LessonDescriptionDescriptions
          lessonDescription={lessonDescription}
          onEditTaggings={onEditTaggings}
          onReload={loadLessonDescriptionDetail}
        />
      )}

      {lessonDescription && (
        <UpdateTaggings
          open={open}
          taggableId={lessonDescription.id}
          taggableType={TaggableTypeEnums.LESSON_DESCRIPTION}
          handleCancel={() => setOpen(false)}
          handleSubmit={handleSubmit}
          taggings={lessonDescription.taggings}
        ></UpdateTaggings>
      )}
    </Space>
  )
}

export default LessonDescription
