import { Button, Form } from 'antd'
import { useEffect, useState } from 'react'

import DebounceSelect from '../../debounce-select/debounce-select'
import {
  getOrganizationsSuggestion,
  getOrganizationsSuggestionsByIds,
} from '../../../api/organizationsApi'
import { ISimpleOrganization } from '../../../share-types'
import translate from '../../../i18n'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import BatchDownloadModal from '../batch-download-modal/batch-download-modal'

interface FormValues {
  organizationId: number
}
const BillingInvoicesFilter = ({
  onFinish,
  organizationId,
}: {
  onFinish: (values: FormValues) => void
  organizationId?: number
}) => {
  const [form] = Form.useForm()

  const [defaultOrganizationOption, setDefaultOrganizationOption] = useState<
    { label: string; value: number }[]
  >([])
  const [showBatchDownloadModal, setShowBatchDownloadModal] = useState(false)

  useEffect(() => {
    if (organizationId) {
      form.setFieldsValue({
        organizationId: organizationId,
      })
      getOrganizationsSuggestionsByIds([organizationId]).then(res => {
        if (res) {
          const options = getOrganizationsSuggestionOptions(res)
          setDefaultOrganizationOption(options)
        }
      })
    }
  }, [organizationId])

  async function fetchOrganizationsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getOrganizationsSuggestion(keyword).then(res => {
      return getOrganizationsSuggestionOptions(res)
    })
  }

  const getOrganizationsSuggestionOptions = (organizationsSuggestions: ISimpleOrganization[]) => {
    return organizationsSuggestions.map(item => {
      const name = item.organizationTranslations[0].name
      return {
        label: `${item.id}-${name}`,
        value: item.id,
      }
    })
  }

  const handleShowBatchDownloadModal = () => {
    setShowBatchDownloadModal(true)
  }

  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item label="Organization" name="organizationId">
        <DebounceSelect
          allowClear
          showSearch={true}
          placeholder="可以通过id/name搜索organization"
          fetchOptions={fetchOrganizationsSuggestionOptions}
          defaultOptions={defaultOrganizationOption}
          style={{ width: 200 }}
        />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {translate('search')}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" icon={<DownloadOutlined />} onClick={handleShowBatchDownloadModal}>
          {translate('batchDownload')}
        </Button>
      </Form.Item>
      <BatchDownloadModal
        show={showBatchDownloadModal}
        handleCancel={() => setShowBatchDownloadModal(false)}
      ></BatchDownloadModal>
    </Form>
  )
}

export default BillingInvoicesFilter
