import { Modal } from 'antd'
import translate from '../../../i18n'
import WeChatURLLinkForm from '../wechat-url-link-form/wechat-url-link-form'

interface IWeChatURLLinkFormModalProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
}

const WeChatURLLinkFormModal = (props: IWeChatURLLinkFormModalProps) => {
  const { handleCancel, open, handleSubmit } = props

  return (
    <Modal
      title={translate('weChatURLLinks.newWeChatURLLinks')}
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      width={800}
    >
      <WeChatURLLinkForm handleCancel={handleCancel} handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default WeChatURLLinkFormModal
