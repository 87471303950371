import { Button, Col, Row, Space } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import AuthPermission from '../../../high-order-components/auth-permission'
import { ReduxStore } from '../../../store'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import {
  ICardConfig,
  ICardConfigsFilterParams,
  IPaginatedCardConfigsParams,
} from '../../card-stacks-container/share-type'
import CardConfigsFilter from '../../../components/card-configs/card-configs-filter/card-configs-filter'
import { getPaginatedCardConfigs } from '../../../api/cardConfigApi'
import CardConfigsTable from '../../../components/card-configs/card-configs-table/card-configs-table'
import { getSearchParams } from '../../../helpers/utils'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const CardConfigs = (props: ConnectedProps<typeof withConnect>) => {
  const { allCardStacks } = props
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [cardConfigs, setCardConfigs] = useState<ICardConfig[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ICardConfigsFilterParams>()

  useEffect(() => {
    const searchName = searchParams.get('name')
    const searchCardStackId = searchParams.get('cardStackId')
    const searchIsActive = searchParams.get('isActive')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const newSearchValues = {
      name: searchName ?? undefined,
      cardStackId: searchCardStackId ? Number(searchCardStackId) : undefined,
      isActive: searchIsActive ? (searchIsActive === 'true' ? true : false) : undefined,
    }
    const data = {
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
      ...newSearchValues,
    }
    setSearchValues(newSearchValues)
    getPaginatedCardConfigsAction(data)
  }, [])

  const getPaginatedCardConfigsAction = (data: IPaginatedCardConfigsParams) => {
    getPaginatedCardConfigs(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setCardConfigs(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
    }
    getPaginatedCardConfigsAction(data)
  }
  const onSearch = (value: ICardConfigsFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedCardConfigsAction(data)
  }

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>卡片</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.CARD_CONFIGS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/card-configs/new')}
            >
              创建新的卡片设置
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <CardConfigsFilter
          onSearch={onSearch}
          formData={searchValues}
          allCardStacks={allCardStacks}
        />
      </Row>

      {cardConfigs && (
        <CardConfigsTable
          hasAction={true}
          cardConfigs={cardConfigs}
          pageInfo={pageInfo}
          paginationOnChange={paginationOnChange}
        />
      )}
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    allCardStacks: state.cardStacks.allCardStacks,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CardConfigs)
