import { Button, Form, Input, DatePicker } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  ILessonCreditPricingFilterFormParams,
  ILessonCreditPricingFilterParams,
} from '../../../pages/lesson-credit-pricings-container/share-type'
import translate from '../../../i18n'
import { getLessonDescriptionsSuggestion } from '../../../api/lessonDescriptionsApi'
import { getOptions } from '../../../helpers/utils'
import { getStudiosSuggestion } from '../../../api/studiosApi'
import moment from 'moment'
import DebounceSelect from '../../debounce-select/debounce-select'
import * as _ from 'lodash'
import FormattedMessage from '../../intl/format-message'

const { RangePicker } = DatePicker
const { TextArea } = Input

const LessonCreditPricingFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: ILessonCreditPricingFilterParams) => void
  formData: ILessonCreditPricingFilterParams | undefined
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      const { startDate, endDate, lessonIds, ...other } = formData

      form.setFieldsValue({
        ...other,
        lessonIds: lessonIds?.join(','),
        startTimeRange: startDate && endDate ? [moment(startDate), moment(endDate)] : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: ILessonCreditPricingFilterFormParams) => {
    const lessonIds = _.uniq(
      values.lessonIds
        ?.split(',')
        .map(item => Number(item))
        .filter(item => item),
    )
    onSearch({
      lessonIds,
      lessonDescriptionId: values.lessonDescriptionId,
      studioId: values.studioId,
      startDate: values.startTimeRange
        ? moment(values.startTimeRange[0]).utc().format()
        : undefined,
      endDate: values.startTimeRange ? moment(values.startTimeRange[1]).utc().format() : undefined,
    })
  }

  async function fetchLessonDescriptionsSuggestionList(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getLessonDescriptionsSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  async function fetchStudioList(keyword: string): Promise<{ label: string; value: number }[]> {
    return getStudiosSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  const onReset = () => {
    onSearch({})
  }

  return (
    <Form
      form={form}
      layout="inline"
      className="filter-inline-form"
      onFinish={onFinish}
      onReset={onReset}
    >
      <Form.Item
        label={translate('lessonCreditPricing.lessonDescriptionId')}
        name="lessonDescriptionId"
      >
        <DebounceSelect
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="选择课程"
          fetchOptions={fetchLessonDescriptionsSuggestionList}
        />
      </Form.Item>
      <Form.Item label={translate('lessonCreditPricing.studioId')} name="studioId">
        <DebounceSelect
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="选择场馆"
          fetchOptions={fetchStudioList}
        />
      </Form.Item>
      <Form.Item name="lessonIds" label={translate('lessonCreditPricing.lessonId')}>
        <TextArea />
      </Form.Item>
      <Form.Item name="startTimeRange" label={translate('lessonCreditPricing.startTimeRange')}>
        <RangePicker showTime />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LessonCreditPricingFilter
