import { Button, Form, Input, notification, Upload, Space, Modal, Typography } from 'antd'
import { useState } from 'react'
import { corporateMembersBulkUpload } from '../../../api/corporateApi'
import { UploadOutlined } from '@ant-design/icons'
import {
  IBatchUploadByCorporateIdResponse,
  ICorporateMembersBulkUploadParams,
} from '../../../pages/corporates-container/share-type'
import * as _ from 'lodash'
import styles from './new-corporate-members-modal.module.less'
import PhoneNumbersCheckModal from '../../corporates/phone-numbers-check-modal/phone-numbers-check-modal'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'

const { TextArea } = Input

const { Paragraph } = Typography

interface INewCorporateMembersModallProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
  corporateId: number
}

const NewCorporateMembersModal = (props: INewCorporateMembersModallProps) => {
  const { handleCancel, handleSubmit, open, corporateId } = props
  const [form] = Form.useForm()
  const [phoneNumbersCheckModalVisible, setPhoneNumbersCheckModalVisible] = useState<boolean>(false)
  const [phoneNumbersCheckModalResult, setPhoneNumbersCheckModalResult] =
    useState<IBatchUploadByCorporateIdResponse>()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [batchUploadPhoneNumberFormData, setBatchUploadPhoneNumberFormData] = useState<FormData>()

  const onFinish = (values: ICorporateMembersBulkUploadParams) => {
    const data = new FormData()
    if (values.file && values.file.length) {
      data.append('file', values.file[0].originFileObj)
    }
    if (values.phoneNumbers) {
      const phoneNumbers = _.split(values.phoneNumbers, ',')
      data.append('phoneNumbers', JSON.stringify(phoneNumbers))
    }

    setBatchUploadPhoneNumberFormData(data)

    corporateMembersBulkUploadAction(data)
  }

  const corporateMembersBulkUploadAction = (data: FormData) => {
    if (!corporateId) {
      return
    }
    corporateMembersBulkUpload(corporateId, data)
      .then(res => {
        if (!res.isSuccess) {
          handleCancel()
          setPhoneNumbersCheckModalResult(res)
          setPhoneNumbersCheckModalVisible(true)
        } else {
          const successfulNotificationDescriptionRender = (
            successfulPhoneNumbers: string[] | null,
          ) => {
            if (!successfulPhoneNumbers) {
              return null
            }
            return (
              <div>
                成功上传的电话号码：{successfulPhoneNumbers.length} 个
                {successfulPhoneNumbers.length > 0 && (
                  <Paragraph copyable={{ text: successfulPhoneNumbers.join(',') }}>
                    一键复制
                  </Paragraph>
                )}
              </div>
            )
          }
          notification.success({
            message: '上传用户电话号码成功',
            description: successfulNotificationDescriptionRender(res?.successfulPhoneNumbers),
          })

          if (phoneNumbersCheckModalVisible) {
            setPhoneNumbersCheckModalVisible(false)
          }
          handleSubmit()
        }
      })
      .catch(err => {
        notification.error({
          message: '上传用户电话号码失败',
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const onConfrimBatchUploadPhoneNumber = () => {
    if (!batchUploadPhoneNumberFormData) {
      return
    }
    batchUploadPhoneNumberFormData.append('ignoreIncorrectFormatUser', 'true')
    setBatchUploadPhoneNumberFormData(batchUploadPhoneNumberFormData)

    corporateMembersBulkUploadAction(batchUploadPhoneNumberFormData)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  return (
    <>
      <Modal
        title="新增企业用户"
        open={open}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        width={800}
      >
        <Form
          layout="horizontal"
          name="corporate_member"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ isActive: false }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="file"
            label={translate('corporate.phoneNumber.bulkUpload')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item name="phoneNumbers" label={translate('corporate.phoneNumber.specify')}>
            <TextArea placeholder="多个用户phone number，使用,隔开" showCount />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Space className={styles['button-group']}>
              <Button loading={loadingButton} type="primary" htmlType="submit">
                <FormattedMessage id="submit" />
              </Button>
              <Button loading={loadingButton} onClick={handleCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      {phoneNumbersCheckModalResult && (
        <PhoneNumbersCheckModal
          visibale={phoneNumbersCheckModalVisible}
          result={phoneNumbersCheckModalResult}
          onConfirm={onConfrimBatchUploadPhoneNumber}
          okText={translate('corporate.batchUploadPhoneNumber.modal.okText')}
          onCancel={() => setPhoneNumbersCheckModalVisible(false)}
        />
      )}
    </>
  )
}

export default NewCorporateMembersModal
