import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import translate from '../../i18n'
import { useEffect } from 'react'
import { compose } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import { setActiveCitiesAction } from '../../store/city/action'
import { getCities } from '../../api/authApi'

const SearchTrendsContainer = (props: ConnectedProps<typeof withConnect>) => {
  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    return translate('navBar.searchTrendsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(SearchTrendsContainer)
