import { Button, Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { IStudioLeaderboardCategoriesFilterParams } from '../../../pages/studio-leaderboard-categories-container/share-type'
import translate from '../../../i18n'
const { Option } = Select

const StudioLeaderboardCategoriesFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IStudioLeaderboardCategoriesFilterParams) => void
  formData: IStudioLeaderboardCategoriesFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: IStudioLeaderboardCategoriesFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="name" label={translate('studioLeaderboardCategories.name')}>
        <Input />
      </Form.Item>
      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {translate('search')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default StudioLeaderboardCategoriesFilter
