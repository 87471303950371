import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Select,
  Space,
  Switch,
  Upload,
} from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { ReduxStore } from '../../../store'
import { City } from '../../../store/city/reducer'
import styles from './card-config-form.module.less'
import { useEffect, useState } from 'react'
import {
  CardActionType,
  CardConfigTypeEnum,
  ICardStack,
  LessonDescriptionContentStrategyEnum,
  StudioContentStrategyEnum,
  StudioListContentStrategyEnum,
  CardStackPageTypeEnum,
} from '../../card-stacks-container/share-type'
import { cardConfigTypeOptionsByCardStackPageType } from '../../card-stacks-container/constant'
import InputColor from '../../../components/input-color/input-color'
import {
  getCardConfigFormData,
  setFieldsCardConfigFormValue,
} from '../../../helpers/card-config-helper'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  createCardConfig,
  editCardConfig,
  getCardConfigDetail,
  uploadImage,
} from '../../../api/cardConfigApi'
import { getStudiosSuggestion, getStudiosSuggestionsByIds } from '../../../api/studiosApi'
import OrderSelect from '../../../components/ordered-select/order-select'
import {
  getLessonDescriptionsSuggestion,
  getLessonDescriptionsSuggestionsByIds,
} from '../../../api/lessonDescriptionsApi'
import { UploadOutlined } from '@ant-design/icons'
import { request } from '../../../request'
import * as _ from 'lodash'
import { getOptions } from '../../../helpers/utils'
import { OptionType, UploadFileDirectoryEnum } from '../../../share-types'
import paymentPageBgTooltipImage from '../../../assets/images/payment-page-bg-tooltip-image.png'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getCouponTemplatesSuggestions } from '../../../api/couponApi'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'
import cpCategoriesStyles from '../../../assets/iconfont/cp-categories/iconfont.module.less'

const { Option } = Select

const CardConfigForm = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, allCardStacks } = props
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigation = useNavigate()
  const [cardConfigId] = useState<string | undefined>(params?.id)
  const [cardConfigTypeOptions, setCardConfigTypeOptions] = useState<Array<any>>(
    cardConfigTypeOptionsByCardStackPageType[CardStackPageTypeEnum.HOME],
  )
  const [form] = Form.useForm()
  const cardTypeValue = Form.useWatch('cardType', form)
  const contentStrategyValue = Form.useWatch('contentStrategy', form)
  const cardStackIdValue = Form.useWatch('cardStackId', form)
  const [defaultStudioSuggestionsOptions, setDefaultStudioSuggestionsOptions] = useState<
    OptionType[]
  >([])
  const [defaultCouponTemplateOptions, setDefaultCouponTemplateOptions] = useState<OptionType[]>([])

  const [
    defaultLessonDescriptionsSuggestionsOptions,
    setDefaultLessonDescriptionsSuggestionsOptions,
  ] = useState<{ label: string; value: number }[]>([])

  useEffect(() => {
    const cardStackId = searchParams.get('cardStackId')
    if (cardConfigId) {
      getCardConfigDetail(Number(cardConfigId)).then(res => {
        const data = setFieldsCardConfigFormValue(res)
        if (data.studioIds?.length && data.studioIds.length <= MAX_QS_STRINGIFY_LENGTH) {
          getStudiosSuggestionsByIds(data.studioIds).then(studioSuggestions => {
            const sortStudioSuggestions = _.sortBy(studioSuggestions, suggestion =>
              _.findIndex(data.studioIds, id => id === suggestion.id),
            )
            setDefaultStudioSuggestionsOptions(getOptions(sortStudioSuggestions))
          })
        }
        if (data.lessonDescriptionIds?.length && data.lessonDescriptionIds.length <= 100) {
          getLessonDescriptionsSuggestionsByIds(data.lessonDescriptionIds).then(
            lessonDescriptionSuggestions => {
              const sortLessonDescriptionSuggestions = _.sortBy(
                lessonDescriptionSuggestions,
                suggestion => _.findIndex(data.lessonDescriptionIds, id => id === suggestion.id),
              )
              setDefaultLessonDescriptionsSuggestionsOptions(
                getOptions(sortLessonDescriptionSuggestions),
              )
            },
          )
        }
        form.setFieldsValue(data)
      })
    }
    if (cardStackId) {
      form.setFieldValue('cardStackId', Number(cardStackId))
    }
  }, [cardConfigId])

  useEffect(() => {
    if (cardStackIdValue) {
      const cardStack = allCardStacks.find(
        (item: ICardStack) => item.id === Number(cardStackIdValue),
      )
      if (cardStack) {
        const pageType = cardStack.pageType as keyof typeof cardConfigTypeOptionsByCardStackPageType
        setCardConfigTypeOptions(cardConfigTypeOptionsByCardStackPageType[pageType])
      }
    }
  }, [cardStackIdValue, allCardStacks])

  useEffect(() => {
    fetchCouponTemplatesSuggestions('').then(options => setDefaultCouponTemplateOptions(options))
  }, [])

  const onFinish = (values: any) => {
    const data = getCardConfigFormData(values)

    if (cardConfigId) {
      editCardConfig(Number(cardConfigId), data)
        .then(res => {
          notification.success({
            message: '修改卡片设置成功',
          })
          navigation(`/card-configs/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '修改卡片设置失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    } else {
      createCardConfig(data)
        .then(res => {
          notification.success({
            message: '创建卡片设置成功',
          })
          navigation(`/card-configs/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建卡片设置失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  const onCardTypeChange = () => {
    form.setFieldValue('contentStrategy', '')
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const uploadUrl = (options: any, directory: UploadFileDirectoryEnum) => {
    const { onSuccess, onError, file, filename } = options
    const fileExtension = file.name.split('.').pop()
    const imageFilename = `${filename}_${file.uid}.${fileExtension}`
    uploadImage(imageFilename, directory)
      .then(res => {
        onSuccess(res)
        request({
          url: res,
          method: 'PUT',
          data: file,
          headers: {
            'x-amz-acl': 'public-read',
          },
        })
      })
      .catch(err => {
        onError({ err })
      })
  }

  async function fetchStudioList(keyword: string): Promise<{ label: string; value: number }[]> {
    return getStudiosSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  async function fetchLessonDescriptionsSuggestionList(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getLessonDescriptionsSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  async function fetchCouponTemplatesSuggestions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getCouponTemplatesSuggestions({ keyword: keyword ?? '', isValid: true }).then(res => {
      return getOptions(res)
    })
  }

  return (
    <Form
      name="card-stack"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{
        isActive: false,
      }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['card-config-form']}
    >
      <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称！' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="card stack"
        name="cardStackId"
        rules={[{ required: true, message: '请选择卡片堆！' }]}
      >
        <Select>
          {allCardStacks.map((item: ICardStack) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="卡片类型"
        name="cardType"
        rules={[{ required: true, message: '请选择卡片类型！' }]}
        hidden={cardStackIdValue === undefined}
      >
        <Radio.Group onChange={onCardTypeChange}>
          <Space direction="vertical">
            {cardConfigTypeOptions.map(item => (
              <Radio key={item.value} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      {cardTypeValue === CardConfigTypeEnum.ADVERTISEMENT && (
        <>
          <Form.Item label="顺序" name="rank" rules={[{ required: true, message: '请输入顺序！' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="标题（中文）" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="标题（英文）" name="titleTranslation">
            <Input />
          </Form.Item>
          <Form.Item label="描述" name="description">
            <Input />
          </Form.Item>
          <Form.Item label="描述（英文）" name="descriptionTranslation">
            <Input />
          </Form.Item>
          <Form.Item
            name="bannerUrl"
            label="横幅地址（中文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              name="banner_url"
              customRequest={config => uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_ADS)}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="bannerUrlTranslation"
            label="横幅地址（英文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              name="banner_url_translation"
              customRequest={config => uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_ADS)}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="bannerBiggerUrl"
            label="横幅地址（大）（中文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              name="banner_bigger_url"
              customRequest={config => uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_ADS)}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="bannerBiggerUrlTranslation"
            label="横幅地址（大）（英文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              name="banner_bigger_url_translation"
              customRequest={config => uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_ADS)}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="充值页面背景颜色"
            name="backgroundColor"
            tooltip={
              <div className={styles['tooltip-inner']}>
                <div className={styles['tooltip-title']}>首页不需要设置</div>
                <img src={paymentPageBgTooltipImage} className={styles['tooltip-inner-image']} />
              </div>
            }
          >
            <InputColor />
          </Form.Item>
        </>
      )}

      {cardTypeValue === CardConfigTypeEnum.ICON && (
        <>
          <Form.Item label="顺序" name="rank" rules={[{ required: true, message: '请输入顺序！' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="标题（中文）"
            name="title"
            rules={[{ required: true, message: '请输入标题！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标题（英文）"
            name="titleTranslation"
            rules={[{ required: true, message: '请输入标题！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="iconFont"
            name="iconFont"
            rules={[{ required: true, message: '请输入iconFont！' }]}
          >
            <Radio.Group onChange={onCardTypeChange} optionType="button">
              <div className={styles['icon-wrapper']}>
                {Object.keys(cpCategoriesStyles)
                  .filter(className => {
                    return className.includes('icon-')
                  })
                  .map((className, index) => {
                    return (
                      <Radio className={styles['icon-radio']} key={index} value={className}>
                        <div
                          className={`${cpCategoriesStyles['category-iconfont']} ${cpCategoriesStyles[className]} ${styles['icon']}`}
                        ></div>
                      </Radio>
                    )
                  })}
              </div>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="imageUrl"
            label="图片地址（中文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              name="image_url"
              customRequest={config =>
                uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_ACTIVITY_ICONS)
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="imageUrlTranslation"
            label="图片地址（英文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              name="image_url_translation"
              customRequest={config =>
                uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_ACTIVITY_ICONS)
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
        </>
      )}

      {cardTypeValue === CardConfigTypeEnum.TITLE_SUBTITLE_ICON && (
        <>
          <Form.Item label="顺序" name="rank" rules={[{ required: true, message: '请输入顺序！' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="标题（中文）"
            name="title"
            rules={[{ required: true, message: '请输入标题！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标题（英文）"
            name="titleTranslation"
            rules={[{ required: true, message: '请输入标题！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="描述" name="description">
            <Input />
          </Form.Item>
          <Form.Item label="描述（英文）" name="descriptionTranslation">
            <Input />
          </Form.Item>
          <Form.Item
            name="imageUrl"
            label="图片地址（中文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: '请上传图片地址！' }]}
          >
            <Upload
              maxCount={1}
              name="image_url"
              customRequest={config =>
                uploadUrl(config, UploadFileDirectoryEnum.HOMEPAGE_TITLE_SUBTILE_ICONS)
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
        </>
      )}

      {cardTypeValue === CardConfigTypeEnum.LESSON_DESCRUPTION && (
        <>
          <Form.Item label="内容策略" name="contentStrategy">
            <Select>
              <Option value={LessonDescriptionContentStrategyEnum.PERSONALIZED_LESSON_DESCRIPTIONS}>
                {LessonDescriptionContentStrategyEnum.PERSONALIZED_LESSON_DESCRIPTIONS}
              </Option>
              <Option value={LessonDescriptionContentStrategyEnum.RECOMMENDED_LESSON_DESCRIPTIONS}>
                {LessonDescriptionContentStrategyEnum.RECOMMENDED_LESSON_DESCRIPTIONS}
              </Option>
              <Option value={LessonDescriptionContentStrategyEnum.FILTER}>
                {LessonDescriptionContentStrategyEnum.FILTER}
              </Option>
            </Select>
          </Form.Item>
          {contentStrategyValue ===
            LessonDescriptionContentStrategyEnum.RECOMMENDED_LESSON_DESCRIPTIONS && (
            <Form.Item label="推荐课程描述" name="lessonDescriptionIds">
              {/* @ts-ignore */}
              <OrderSelect
                mode="multiple"
                placeholder="选择课程"
                fetchOptions={fetchLessonDescriptionsSuggestionList}
                defaultOptions={defaultLessonDescriptionsSuggestionsOptions}
              />
            </Form.Item>
          )}
        </>
      )}
      {cardTypeValue === CardConfigTypeEnum.STUDIO && (
        <>
          <Form.Item label="内容策略" name="contentStrategy">
            <Select>
              <Option value={StudioContentStrategyEnum.NEARBY_STUDIOS}>
                {StudioContentStrategyEnum.NEARBY_STUDIOS}
              </Option>
              <Option value={StudioContentStrategyEnum.RECOMMENDED_STUDIOS}>
                {StudioContentStrategyEnum.RECOMMENDED_STUDIOS}
              </Option>
            </Select>
          </Form.Item>
          {contentStrategyValue === StudioContentStrategyEnum.NEARBY_STUDIOS && (
            <Form.Item
              label="附近好馆最大数量"
              name="max"
              rules={[{ required: true, message: '请输入附近好馆最大数量！' }]}
            >
              <InputNumber />
            </Form.Item>
          )}
          {contentStrategyValue === StudioContentStrategyEnum.RECOMMENDED_STUDIOS && (
            <Form.Item label="推荐场馆" name="studioIds">
              {/* @ts-ignore */}
              <OrderSelect
                mode="multiple"
                placeholder="选择场馆"
                fetchOptions={fetchStudioList}
                defaultOptions={defaultStudioSuggestionsOptions}
              />
            </Form.Item>
          )}
        </>
      )}
      {cardTypeValue === CardConfigTypeEnum.STUDIO_LIST && (
        <>
          <Form.Item label="顺序" name="rank" rules={[{ required: true, message: '请输入顺序！' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="城市" name="cityIds">
            <Select mode="multiple" allowClear>
              {activeCities.map((city: City) => {
                const cityTranslations = city.translations.find(
                  item => item.locale === props.locale,
                )
                return (
                  <Option key={city.id} value={city.id}>
                    {city.id}-{cityTranslations?.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="标题（中文）"
            name="title"
            rules={[{ required: true, message: '请输入标题！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标题（英文）"
            name="titleTranslation"
            rules={[{ required: true, message: '请输入标题！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标签文本（中文）"
            name="tagText"
            rules={[{ required: true, message: '请输入标签！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标签文本（英文）"
            name="tagTextTranslation"
            rules={[{ required: true, message: '请输入标签！' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标签背景颜色"
            name="tagBgColor"
            rules={[{ required: true, message: '请输入标签背景颜色！' }]}
          >
            <InputColor />
          </Form.Item>
          <Form.Item
            label="内容策略"
            name="contentStrategy"
            rules={[{ required: true, message: '请选择内容策略' }]}
          >
            <Select allowClear>
              <Option value={StudioListContentStrategyEnum.NEW_STUDIOS}>
                {StudioListContentStrategyEnum.NEW_STUDIOS}
              </Option>
              <Option value={StudioListContentStrategyEnum.RECOMMENDED_STUDIOS}>
                {StudioListContentStrategyEnum.RECOMMENDED_STUDIOS}
              </Option>
            </Select>
          </Form.Item>
          {contentStrategyValue === StudioContentStrategyEnum.NEARBY_STUDIOS && (
            <Form.Item
              label="附近好馆最大数量"
              name="max"
              rules={[{ required: true, message: '请输入附近好馆最大数量！' }]}
            >
              <InputNumber />
            </Form.Item>
          )}
          {contentStrategyValue === StudioContentStrategyEnum.RECOMMENDED_STUDIOS && (
            <Form.Item label="推荐场馆" name="studioIds">
              {/* @ts-ignore */}
              <OrderSelect
                mode="multiple"
                placeholder="选择场馆"
                fetchOptions={fetchStudioList}
                defaultOptions={defaultStudioSuggestionsOptions}
              />
            </Form.Item>
          )}
        </>
      )}
      {cardTypeValue === CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS && (
        <>
          <Form.Item
            label="课程最大数量"
            name="max"
            rules={[{ required: true, message: '请输入课程最大数量！' }]}
          >
            <InputNumber />
          </Form.Item>
        </>
      )}

      {cardTypeValue === CardConfigTypeEnum.COUPON_CARD && (
        <>
          <Form.Item
            label="优惠劵模板"
            name="couponTemplateId"
            rules={[
              {
                required: true,
                message: '请选择优惠劵模板!',
              },
            ]}
          >
            <DebounceSelect
              showSearch
              placeholder="输入ID, 优惠劵名称，优惠码搜索优惠劵模版"
              fetchOptions={fetchCouponTemplatesSuggestions}
              defaultOptions={defaultCouponTemplateOptions}
            />
          </Form.Item>
          <Form.Item
            name="imageUrl"
            label="图片地址（中文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: '请上传图片地址！' }]}
          >
            <Upload
              maxCount={1}
              name="image_url"
              customRequest={config =>
                uploadUrl(config, UploadFileDirectoryEnum.STUDIO_LESSON_LIST_BANNERS)
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="imageUrlTranslation"
            label="图片地址（英文）"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: '请上传图片地址！' }]}
          >
            <Upload
              maxCount={1}
              name="image_url_translation"
              customRequest={config =>
                uploadUrl(config, UploadFileDirectoryEnum.STUDIO_LESSON_LIST_BANNERS)
              }
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </Form.Item>
        </>
      )}

      {cardTypeValue === CardConfigTypeEnum.FIRST_CLASS_DISCOUNTED_STUDIO_CARD && (
        <>
          <Form.Item
            label="场馆最大数量"
            name="max"
            rules={[{ required: true, message: '请输入场馆最大数量' }]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </>
      )}

      <Form.Item label="是否激活" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>

      {(cardTypeValue === CardConfigTypeEnum.ADVERTISEMENT ||
        cardTypeValue === CardConfigTypeEnum.ICON ||
        cardTypeValue === CardConfigTypeEnum.TITLE_SUBTITLE_ICON ||
        cardTypeValue === CardConfigTypeEnum.STUDIO_LIST ||
        cardTypeValue === CardConfigTypeEnum.COUPON_CARD) && (
        <>
          <Form.Item
            label="跳转路径"
            name="actionUrl"
            rules={[
              { required: cardTypeValue === CardConfigTypeEnum.ICON, message: '请输入跳转路径！' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="跳转类型"
            name="actionType"
            rules={[
              { required: cardTypeValue === CardConfigTypeEnum.ICON, message: '请选择跳转类型！' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('actionUrl') && !value) {
                    return Promise.reject(new Error('填写跳转路径后跳转类型必选'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Select allowClear>
              <Option value={CardActionType.DEEP_LINK}>{CardActionType.DEEP_LINK}</Option>
              <Option value={CardActionType.EXTERNAL}>{CardActionType.EXTERNAL}</Option>
            </Select>
          </Form.Item>
          <Form.Item label="跳转文本（中文）" name="actionText">
            <Input />
          </Form.Item>
          <Form.Item
            label="跳转文本（英文）"
            name="actionTextTranslation"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('actionText') && !value) {
                    return Promise.reject(new Error('填写跳转文本（中文）后跳转文本（英文）必填'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
    allCardStacks: state.cardStacks.allCardStacks,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CardConfigForm)
