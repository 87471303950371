import config from '../config/index'
import {
  IPaginatedSegmentRulesParams,
  ISegmentRule,
  PaginatedSegmentRulesResponse,
  SegmentRuleRequest,
} from '../pages/segment-rules-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedSegmentRules = (data: IPaginatedSegmentRulesParams) =>
  authRequest.get<MISSING_TYPE, PaginatedSegmentRulesResponse>(`${mainApi}/segment-rules`, {
    params: data,
  })

export const getSegmentRuleDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ISegmentRule>(`${mainApi}/segment-rules/${id}`)

export const createSegmentRule = (data: SegmentRuleRequest) =>
  authRequest.post<MISSING_TYPE, ISegmentRule>(`${mainApi}/segment-rules`, data)

export const editSegmentRule = (id: number, data: SegmentRuleRequest) =>
  authRequest.put<MISSING_TYPE, ISegmentRule>(`${mainApi}/segment-rules/${id}`, data)
