import { Link, LinkProps } from 'react-router-dom'
import { ButtonProps } from 'antd'

interface LinkButtonProps extends LinkProps {
  type?: ButtonProps['type']
  disabled?: boolean
}

export default function LinkButton(props: LinkButtonProps) {
  const { to, type = 'link', children, disabled, ...otherProps } = props

  const validButtonTypes = ['primary', 'ghost', 'dashed', 'link', 'text', 'default']

  const buttonType = type && validButtonTypes.includes(type) ? type : 'link'

  return (
    <Link
      className={'ant-btn ant-btn-' + buttonType}
      to={to}
      style={
        disabled
          ? {
              pointerEvents: 'none',
              color: 'rgba(0, 0, 0, 0.25)',
              cursor: 'not-allowed',
            }
          : {}
      }
      {...otherProps}
    >
      {children}
    </Link>
  )
}
