import config from '../config/index'
import {
  IBookingReview,
  IBookingReviewAuditParams,
  IPaginatedBookingReviewsParams,
  IRejectReason,
  PaginatedBookingReviewResponse,
} from '../pages/booking-reviews-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedBookingReviews = (data: IPaginatedBookingReviewsParams) =>
  authRequest.get<MISSING_TYPE, PaginatedBookingReviewResponse>(`${mainApi}/booking-reviews`, {
    params: data,
  })

export const getBookingReviewInfo = (bookingReviewId: number) =>
  authRequest.get<MISSING_TYPE, IBookingReview>(`${mainApi}/booking-reviews/${bookingReviewId}`)

export const auditBookingReview = (bookingReviewId: number, data: IBookingReviewAuditParams) =>
  authRequest.put(`${mainApi}/booking-reviews/${bookingReviewId}`, null, { params: data })

export const deleteBookingReview = (bookingReviewId: number, deleteReason: string) =>
  authRequest.delete(`${mainApi}/booking-reviews/${bookingReviewId}`, { data: { deleteReason } })

export const getBookingReviewRejectReasons = () =>
  authRequest.get<MISSING_TYPE, IRejectReason[]>(`${mainApi}/booking-reviews/reject-reasons`)
