import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems'
import {
  MailOutlined,
  GlobalOutlined,
  UserOutlined,
  ApartmentOutlined,
  SmileOutlined,
  CreditCardOutlined,
  FundProjectionScreenOutlined,
  SettingOutlined,
  GroupOutlined,
  UsergroupAddOutlined,
  ClusterOutlined,
  SearchOutlined,
  CrownOutlined,
  PayCircleOutlined,
  TransactionOutlined,
  BlockOutlined,
  WechatOutlined,
  HighlightOutlined,
  CodeSandboxOutlined,
  CodepenOutlined,
  RobotOutlined,
  TagsOutlined,
  ReadOutlined,
  ProfileOutlined,
  PartitionOutlined,
  ShopOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons'
import { FormattedMessage } from '../intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MenuModeEnum, PermissionsEnum } from '../../share-types'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ReduxStore } from '../../store'
import { Card, Col, Empty, Menu, Row, Space, Typography } from 'antd'
import { Permission } from '../../pages/admin-roles-container/share-type'
import * as _ from 'lodash'
import styles from './menus.module.less'
import { setCurrentMenuTypeAction } from '../../store/global/action'
import { useEffect } from 'react'
import { InvoiceIcon } from '../icons'

type MeunItem = ItemType & {
  permissions?: string[]
  children?: MeunItem[]
}

const permissionMenuItems: MeunItem[] = [
  {
    label: <FormattedMessage id="menuBar.accountManageTitle" />,
    key: 'userManage',
    icon: <FundProjectionScreenOutlined />,
    permissions: [
      PermissionsEnum.ADMIN_USERS_READ,
      PermissionsEnum.ADMIN_ROLE_READ,
      PermissionsEnum.CORPORATES_READ,
      PermissionsEnum.SEGMENTS_READ,
      PermissionsEnum.SEGMENT_RULES_READ,
    ],
    children: [
      {
        label: (
          <Link to={'admin-users'}>
            <FormattedMessage id="menuBar.adminUsersTiltle" />
          </Link>
        ),
        key: 'admin-users',
        icon: <UserOutlined />,
        permissions: [PermissionsEnum.ADMIN_USERS_READ],
      },
      {
        label: (
          <Link to={'admin-roles'}>
            <FormattedMessage id="menuBar.adminRolesTiltle" />
          </Link>
        ),
        key: 'admin-roles',
        icon: <ApartmentOutlined />,
        permissions: [PermissionsEnum.ADMIN_ROLE_READ],
      },
      {
        label: (
          <Link to={'corporates'}>
            <FormattedMessage id="menuBar.corporatesTitle" />
          </Link>
        ),
        key: 'corporates',
        icon: <GroupOutlined />,
        permissions: [PermissionsEnum.CORPORATES_READ],
      },
      {
        label: (
          <Link to={'segments'}>
            <FormattedMessage id="menuBar.segmentsTitle" />
          </Link>
        ),
        key: 'segments',
        icon: <CreditCardOutlined />,
        permissions: [PermissionsEnum.SEGMENTS_READ],
      },
      {
        label: (
          <Link to={'segment-rules'}>
            <FormattedMessage id="menuBar.segmentRulesTitle" />
          </Link>
        ),
        key: 'segment-rules',
        icon: <SettingOutlined />,
        permissions: [PermissionsEnum.SEGMENT_RULES_READ],
      },
    ],
  },
  {
    label: <FormattedMessage id="menuBar.couponTitle" />,
    key: 'coupons',
    icon: <MailOutlined />,
    permissions: [
      PermissionsEnum.COUPON_TEMPLATES_READ,
      PermissionsEnum.ONE_TIME_CODES_READ,
      PermissionsEnum.TASK_REWARDS_READ,
    ],
    children: [
      {
        label: (
          <Link to={'coupon-templates'}>
            <FormattedMessage id="menuBar.couponTemplatesTitle" />
          </Link>
        ),
        key: 'coupon-templates',
        icon: <MailOutlined />,
        permissions: [PermissionsEnum.COUPON_TEMPLATES_READ],
      },
      {
        label: (
          <Link to={'one-time-codes'}>
            <FormattedMessage id="menuBar.oneTimeCodesTitle" />
          </Link>
        ),
        key: 'one-time-codes',
        icon: <CodepenOutlined />,
        permissions: [PermissionsEnum.ONE_TIME_CODES_READ],
      },
      {
        label: (
          <Link to={'one-time-code-batches'}>
            <FormattedMessage id="menuBar.oneTimeCodeBatchesTitle" />
          </Link>
        ),
        key: 'one-time-code-batches',
        icon: <CodeSandboxOutlined />,
        permissions: [PermissionsEnum.ONE_TIME_CODES_READ],
      },
      {
        label: (
          <Link to={'task-rewards'}>
            <FormattedMessage id="menuBar.taskRewardsTitle" />
          </Link>
        ),
        key: 'task-rewards',
        icon: <TransactionOutlined />,
        permissions: [PermissionsEnum.TASK_REWARDS_READ],
      },
    ],
  },
  {
    label: <FormattedMessage id="menuBar.LessonsTitle" />,
    key: 'lessons',
    icon: <ReadOutlined />,
    permissions: [
      PermissionsEnum.LESSON_DESCRIPTIONS_READ,
      PermissionsEnum.STUDIOS_READ,
      PermissionsEnum.STUDIO_INTEGRATION_READ,
    ],
    children: [
      {
        label: (
          <Link to={'lesson-descriptions'}>
            <FormattedMessage id="menuBar.lessonDescriptionsTitle" />
          </Link>
        ),
        key: 'lesson-descriptions',
        icon: <ProfileOutlined />,
        permissions: [PermissionsEnum.LESSON_DESCRIPTIONS_READ],
      },
      {
        label: (
          <Link to={'organizations'}>
            <FormattedMessage id="menuBar.organizationsTitle" />
          </Link>
        ),
        key: 'organizations',
        icon: <ApartmentOutlined />,
        permissions: [PermissionsEnum.STUDIOS_READ],
      },
      {
        label: (
          <Link to={'prelaunch-organization-forms'}>
            <FormattedMessage id="navBar.prelaunchOrganizationFormsTitle" />
          </Link>
        ),
        key: 'prelaunch-organization-forms',
        icon: <ApartmentOutlined />,
        permissions: [PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_READ],
      },
      {
        label: (
          <Link to={'studios'}>
            <FormattedMessage id="navBar.studiosTitle" />
          </Link>
        ),
        key: 'studios',
        icon: <PartitionOutlined />,
        permissions: [PermissionsEnum.STUDIOS_READ],
      },
      {
        label: (
          <Link to={'studio-integration-config'}>
            <FormattedMessage id="menuBar.StudioIntegrationConfigs"></FormattedMessage>
          </Link>
        ),
        key: 'studio-integration-config',
        icon: <ShopOutlined />,
        permissions: [PermissionsEnum.STUDIO_INTEGRATION_READ],
      },
      {
        label: (
          <Link to={'first-class-discount-disabled-lessons'}>
            <FormattedMessage id="menuBar.firstClassDiscountDisabledLessonsTitle"></FormattedMessage>
          </Link>
        ),
        key: 'first-class-discount-disabled-lessons',
        icon: <ShopOutlined />,
        permissions: [PermissionsEnum.FIRST_CLASS_DISCOUNTS_READ],
      },
      {
        label: (
          <Link to={'lesson-credit-pricings'}>
            <FormattedMessage id="menuBar.lessonCreditPricingsTitle" />
          </Link>
        ),
        key: 'lesson-credit-pricings',
        icon: <MoneyCollectOutlined />,
        permissions: [PermissionsEnum.LESSON_CREDIT_PRICINGS_READ],
      },
      {
        label: (
          <Link to={'lesson-credit-adjustments'}>
            <FormattedMessage id="navBar.lessonCreditAdjustmentsTitle" />
          </Link>
        ),
        key: 'lesson-credit-adjustments',
        icon: <PayCircleOutlined />,
        permissions: [PermissionsEnum.LESSON_CREDIT_ADJUSTMENTS_READ],
      },
      {
        label: (
          <Link to={'exchange-rates/organizations'}>
            <FormattedMessage id="navBar.exchangeRatesTitle" />
          </Link>
        ),
        key: 'exchange-rates/organizations',
        icon: <PayCircleOutlined />,
        permissions: [PermissionsEnum.EXCHANGE_RATE_READ],
      },
    ],
  },
  {
    label: (
      <Link to={'booking-reviews'}>
        <FormattedMessage id="menuBar.bookingReviewsTitle" />
      </Link>
    ),
    key: 'booking-reviews',
    icon: <SmileOutlined />,
    permissions: [PermissionsEnum.BOOKING_REVIEWS_READ],
  },
  {
    label: (
      <Link to={'search-trends'}>
        <FormattedMessage id="menuBar.searchTrendsTitle" />
      </Link>
    ),
    key: 'search-trends',
    icon: <SearchOutlined />,
    permissions: [PermissionsEnum.SEARCH_TRENDS_READ],
  },
  {
    label: <FormattedMessage id="menuBar.marketingTitle" />,
    key: 'marketing',
    icon: <FundProjectionScreenOutlined />,
    permissions: [
      PermissionsEnum.CARD_STACKS_READ,
      PermissionsEnum.CARD_CONFIGS_READ,
      PermissionsEnum.REFERRAL_CAMPAIGNS_READ,
      PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_READ,
      PermissionsEnum.WECHAT_URL_LINKS_READ,
      PermissionsEnum.SHORT_URLS_READ,
    ],
    children: [
      {
        label: (
          <Link to={'card-stacks'}>
            <FormattedMessage id="menuBar.cardStacksTitle" />
          </Link>
        ),
        key: 'card-stacks',
        icon: <CreditCardOutlined />,
        permissions: [PermissionsEnum.CARD_STACKS_READ],
      },
      {
        label: (
          <Link to={'card-configs'}>
            <FormattedMessage id="menuBar.cardConfigsTitle" />
          </Link>
        ),
        key: 'card-configs',
        icon: <SettingOutlined />,
        permissions: [PermissionsEnum.CARD_CONFIGS_READ],
      },
      {
        label: (
          <Link to={'referral-campaigns'}>
            <FormattedMessage id="menuBar.referralCampaignsTitle" />
          </Link>
        ),
        key: 'referral-campaigns',
        icon: <UsergroupAddOutlined />,
        permissions: [PermissionsEnum.REFERRAL_CAMPAIGNS_READ],
      },
      {
        label: (
          <Link to={'studio-leaderboards'}>
            <FormattedMessage id="menuBar.studioLeaderboardsTitle" />
          </Link>
        ),
        key: 'studio-leaderboards',
        icon: <CrownOutlined />,
        permissions: [PermissionsEnum.STUDIO_LEADERBOARDS_READ],
      },
      {
        label: (
          <Link to={'studio-leaderboard-categories'}>
            <FormattedMessage id="menuBar.studioLeaderboardCategoriesTitle" />
          </Link>
        ),
        key: 'studio-leaderboard-categories',
        icon: <ClusterOutlined />,
        permissions: [PermissionsEnum.STUDIO_LEADERBOARD_CATEGORIES_READ],
      },
      {
        label: (
          <Link to={'short-urls'}>
            <FormattedMessage id="menuBar.shortURLsTitle" />
          </Link>
        ),
        key: 'short-urls',
        icon: <HighlightOutlined />,
        permissions: [PermissionsEnum.SHORT_URLS_READ],
      },
      {
        label: (
          <Link to={'wechat-url-links'}>
            <FormattedMessage id="menuBar.weChatURLLinksTitle" />
          </Link>
        ),
        key: 'wechat-url-links',
        icon: <WechatOutlined />,
        permissions: [PermissionsEnum.WECHAT_URL_LINKS_READ],
      },
    ],
  },
  {
    label: <FormattedMessage id="menuBar.miscTitle" />,
    key: 'misc',
    icon: <RobotOutlined />,
    permissions: [PermissionsEnum.TAGS_READ, PermissionsEnum.BILLING_INVOICES_READ],
    children: [
      {
        label: (
          <Link to={'tags'}>
            <FormattedMessage id="menuBar.tagsTitle" />
          </Link>
        ),
        key: 'tags',
        icon: <TagsOutlined />,
        permissions: [PermissionsEnum.TAGS_READ],
      },
      {
        label: (
          <Link to={'billing-invoices'}>
            <FormattedMessage id="menuBar.billingInvoicesTitle" />
          </Link>
        ),
        key: 'billing-invoices',
        icon: <InvoiceIcon />,
        permissions: [PermissionsEnum.BILLING_INVOICES_READ],
      },
    ],
  },
  {
    label: (
      <Link to={'feature-flags'}>
        <FormattedMessage id="menuBar.featureFlagTitle"></FormattedMessage>
      </Link>
    ),
    key: 'feature-flags',
    icon: <BlockOutlined />,
    permissions: [PermissionsEnum.FEATURE_FLAGS_READ],
  },
  {
    label: (
      <Link to={'change-language'}>
        <FormattedMessage id="menuBar.changeLanguageTitle"></FormattedMessage>
      </Link>
    ),
    key: 'change-language',
    icon: <GlobalOutlined />,
  },
]

interface IProps extends ConnectedProps<typeof withConnect> {
  mode: MenuModeEnum
}

const GridMenuCard = ({
  item,
  span,
  clickMenu,
}: {
  item: MenuItemType
  span: number
  clickMenu: (item: MenuItemType) => void
}) => {
  const navigate = useNavigate()
  const onclick = () => {
    clickMenu(item)
    navigate(`/${item.key}`)
  }
  return (
    <Col className={styles['menu-col']} span={span} offset={1} onClick={onclick}>
      <Card className={styles['menu-card']}>
        <Space direction="vertical">
          <div className={styles['menu-icon']}>{item.icon}</div>
          <Typography>{item.label}</Typography>
        </Space>
      </Card>
    </Col>
  )
}

const Menus = ({
  mode = MenuModeEnum.MENU,
  userInfo,
  currentMenuType,
  setCurrentMenuTypeAction,
}: IProps) => {
  const location = useLocation()

  useEffect(() => {
    const keys = _.split(location.pathname, '/')
    if (currentMenuType === '' && currentMenuType !== keys[1]) {
      setCurrentMenuTypeAction(keys[1])
    }
  }, [])
  const getUserPermissionMenu = (
    permissionMenuItems: MeunItem[],
    userPermissions: string[],
  ): MISSING_TYPE => {
    const menuItems = permissionMenuItems
      .map(item => {
        const { permissions, children, ...other } = item
        if (children) {
          const newChildren = getUserPermissionMenu(children, userPermissions)
          if (newChildren.length) {
            return {
              ...other,
              children: newChildren,
            }
          } else {
            return null
          }
        } else if (permissions) {
          if (_.intersection(userPermissions, permissions).length) {
            return other
          } else {
            return null
          }
        } else {
          return other
        }
      })
      .filter(item => item)
    return menuItems
  }

  const getMenuItems = () => {
    if (userInfo && userInfo.permissions) {
      const userPermissions = userInfo?.permissions.map((item: Permission) => item.scope)
      return getUserPermissionMenu(permissionMenuItems, userPermissions)
    }
    return []
  }

  const clickMenu = (e: any) => {
    setCurrentMenuTypeAction(e.key)
  }

  const renderGridMenu = (permissionMenuItems: MISSING_TYPE) => {
    return (
      <Row gutter={[16, 16]}>
        {permissionMenuItems.map((item: MISSING_TYPE) => {
          if (item?.children) {
            return item.children.map((child: MenuItemType) => {
              return <GridMenuCard span={4} item={child} key={child.key} clickMenu={clickMenu} />
            })
          } else {
            return <GridMenuCard span={4} item={item} key={item.key} clickMenu={clickMenu} />
          }
        })}

        {!permissionMenuItems.length && <Empty />}
      </Row>
    )
  }

  const getMenuTemplate = () => {
    const permissionMenuItems = getMenuItems()
    if (!permissionMenuItems || !permissionMenuItems.length) {
      return null
    }
    if (mode === MenuModeEnum.MENU) {
      return (
        <Menu
          theme="dark"
          mode="horizontal"
          items={permissionMenuItems}
          selectedKeys={[currentMenuType]}
          onClick={e => clickMenu(e)}
        />
      )
    } else {
      return renderGridMenu(permissionMenuItems)
    }
  }

  return <div>{getMenuTemplate()}</div>
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    userInfo: state.userInfo,
    currentMenuType: state.global.currentMenuType,
  }
}

const mapDispatchToProps = {
  setCurrentMenuTypeAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Menus)
