import { Button, DatePicker, Form, notification, TimePicker } from 'antd'
import translate from '../../../i18n'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import {
  getLessonDescriptionsSuggestion,
  getLessonDescriptionsSuggestionsByIds,
} from '../../../api/lessonDescriptionsApi'
import { getOptions } from '../../../helpers/utils'
import { useEffect, useState } from 'react'
import {
  createFirstClassDiscountDisabledLesson,
  getFirstClassDiscountDisabledLesson,
  updateFirstClassDiscountDisabledLesson,
} from '../../../api/firstClassDiscountDisabledLessonsApi'
import { useNavigate, useParams } from 'react-router-dom'
import { DATE_FORMAT } from '../../../helpers/constant'
import moment from 'moment'
import styles from './first-class-discount-disabled-lesson-form.module.less'
import { FirstClassDiscountDisabledLessonFormValues } from '../share-type'

const FirstClassDiscountDisabledLessonForm = () => {
  const [form] = Form.useForm()
  const params = useParams()
  const navigation = useNavigate()
  const [firstClassDiscountDisabledLessonId] = useState<string | undefined>(params?.id)
  const [
    defaultLessonDescriptionsSuggestionsOptions,
    setDefaultLessonDescriptionsSuggestionsOptions,
  ] = useState<{ label: string; value: number }[]>([])

  useEffect(() => {
    if (firstClassDiscountDisabledLessonId) {
      getFirstClassDiscountDisabledLesson(Number(firstClassDiscountDisabledLessonId)).then(data => {
        const minStartTime = data.minStartTime ? moment(data.minStartTime, 'HH:mm:ss') : null
        const maxStartTime = data.maxStartTime ? moment(data.maxStartTime, 'HH:mm:ss') : null
        const minStartDate = data.minStartDate ? moment(data.minStartDate, 'YYYY-MM-DD') : null
        const maxStartDate = data.maxStartDate ? moment(data.maxStartDate, 'YYYY-MM-DD') : null

        const formValue = {
          ...data,
          rangeStartTime: minStartTime && maxStartTime ? [minStartTime, maxStartTime] : null,
          rangeStartDate: minStartDate && maxStartDate ? [minStartDate, maxStartDate] : null,
        }

        if (data.lessonDescriptionId) {
          getLessonDescriptionsSuggestionsByIds([data.lessonDescriptionId]).then(
            lessonDescriptionSuggestions => {
              setDefaultLessonDescriptionsSuggestionsOptions(
                getOptions(lessonDescriptionSuggestions),
              )
            },
          )
        }
        form.setFieldsValue(formValue)
      })
    }
  }, [firstClassDiscountDisabledLessonId])

  async function fetchLessonDescriptionsSuggestionList(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getLessonDescriptionsSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  const onFinish = (values: FirstClassDiscountDisabledLessonFormValues) => {
    const { rangeStartTime, rangeStartDate, ...other } = values
    const data = {
      ...other,
      minStartTime: rangeStartTime ? moment(rangeStartTime[0]).format('HH:mm:ss') : null,
      maxStartTime: rangeStartTime ? moment(rangeStartTime[1]).format('HH:mm:ss') : null,
      minStartDate: rangeStartDate ? moment(rangeStartDate[0]).format('YYYY-MM-DD') : null,
      maxStartDate: rangeStartDate ? moment(rangeStartDate[1]).format('YYYY-MM-DD') : null,
    }
    if (firstClassDiscountDisabledLessonId) {
      updateFirstClassDiscountDisabledLesson(Number(firstClassDiscountDisabledLessonId), data)
        .then(res => {
          notification.success({
            message: translate('firstClassDiscountDisabledLessons.updateSuccess'),
          })
          navigation(
            `/first-class-discount-disabled-lessons?lessonDescriptionId=${res.lessonDescriptionId}&page=0&size=10`,
          )
        })
        .catch(err => {
          notification.error({
            message: translate('firstClassDiscountDisabledLessons.updateError'),
            description: err?.message || translate('networkError'),
          })
        })
    } else {
      createFirstClassDiscountDisabledLesson(data)
        .then(res => {
          notification.success({
            message: translate('firstClassDiscountDisabledLessons.createSuccess'),
          })
          navigation(
            `/first-class-discount-disabled-lessons?lessonDescriptionId=${res.lessonDescriptionId}&page=0&size=10`,
          )
        })
        .catch(err => {
          notification.error({
            message: translate('firstClassDiscountDisabledLessons.createError'),
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  return (
    <Form
      name="first-class-discount-disabled-lesson-form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['first-class-discount-disabled-lesson-form']}
    >
      <Form.Item
        label={translate('lessonCreditPricing.lessonDescriptionId')}
        name="lessonDescriptionId"
      >
        <DebounceSelect
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="选择课程"
          fetchOptions={fetchLessonDescriptionsSuggestionList}
          defaultOptions={defaultLessonDescriptionsSuggestionsOptions}
        />
      </Form.Item>

      <Form.Item
        label={translate('firstClassDiscountDisabledLessons.rangeStartTime')}
        name="rangeStartTime"
      >
        <TimePicker.RangePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label={translate('firstClassDiscountDisabledLessons.rangeStartDate')}
        name="rangeStartDate"
      >
        <DatePicker.RangePicker showTime format={DATE_FORMAT} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FirstClassDiscountDisabledLessonForm
