import { Button, Form, Input } from 'antd'
import { IAdminUserFilterParams } from '../../../pages/admin-users-container/share-type'
import FormattedMessage from '../../intl/format-message'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import translate from '../../../i18n'

interface IProps {
  onSearch: (value: IAdminUserFilterParams) => void
}

const AdminUsersFilter = (props: IProps) => {
  const { onSearch } = props

  const onReset = () => {
    onSearch({})
  }

  return (
    <Form layout="inline" onFinish={onSearch} onReset={onReset}>
      <Form.Item name="email" label={translate('email')}>
        <Input placeholder={translate('email.message')} />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AdminUsersFilter
