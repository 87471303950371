import { Button, Col, Row, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import {
  IPaginatedReferralCampaignsFilterParams,
  IReferralCampaign,
  IReferralCampaignsFilterParams,
} from '../share-type'
import { getPaginatedReferralCampaigns } from '../../../api/referralCampaignsApi'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import ReferralCampaignsFilter from '../../../components/referral-campaigns/referral-campaigns-filter/referral-campaigns-filter'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const ReferralCampaigns = () => {
  const navigate = useNavigate()
  const [referralCampaigns, setReferralCampaigns] = useState<IReferralCampaign[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IReferralCampaignsFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'name', isBoolean: true },
        { key: 'isActive', isBoolean: true },
        { key: 'isDefault', isBoolean: true },
        { key: 'startDatetime', isBoolean: false },
        { key: 'endDatetime', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedReferralCampaignsAction(data)
  }, [])

  const getPaginatedReferralCampaignsAction = (data: IPaginatedReferralCampaignsFilterParams) => {
    getPaginatedReferralCampaigns(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setReferralCampaigns(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedReferralCampaignsAction(data)
  }
  const onSearch = (value: IReferralCampaignsFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedReferralCampaignsAction(data)
  }

  const columns: ColumnsType<IReferralCampaign> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('referralCampaigns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('referralCampaigns.isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('referralCampaigns.isDefault'),
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (isDefault: boolean) => <YesOrNoTag value={isDefault} />,
    },
    {
      title: translate('referralCampaigns.startDatetime'),
      dataIndex: 'startDatetime',
      key: 'startDatetime',
      render: (startDatetime: string | null) => {
        if (startDatetime) {
          return <span>{getDateTime(startDatetime)}</span>
        }
        return ''
      },
    },
    {
      title: translate('referralCampaigns.endDatetime'),
      dataIndex: 'endDatetime',
      key: 'endDatetime',
      render: (endDatetime: string | null) => {
        if (endDatetime) {
          return <span>{getDateTime(endDatetime)}</span>
        }
        return ''
      },
    },

    {
      title: translate('referralCampaigns.pageBackgroundColor'),
      dataIndex: 'pageBackgroundColor',
      key: 'pageBackgroundColor',
      render: (pageBackgroundColor: string) => (
        <Space align="center">
          <div className="color-preview" style={{ background: pageBackgroundColor }}></div>
          {pageBackgroundColor}
        </Space>
      ),
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IReferralCampaign) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.CORPORATES_WRITE}>
            <LinkButton to={`/referral-campaigns/${record.id}/edit`}>
              {translate('edit')}
            </LinkButton>
          </AuthPermission>

          <LinkButton to={`/referral-campaigns/${record.id}`}>{translate('view')}</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.referralCampaignTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.REFERRAL_CAMPAIGNS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/referral-campaigns/new')}
            >
              {translate('referralCampaigns.createNewCampaign')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <ReferralCampaignsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={referralCampaigns}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default ReferralCampaigns
