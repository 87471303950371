import {
  Button,
  Input,
  Form,
  Space,
  Upload,
  notification,
  InputNumber,
  Modal,
  DatePicker,
} from 'antd'
import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import styles from './batch-distribute-credits-modal.module.less'
import translate from '../../../i18n'
import * as _ from 'lodash'
import {
  IBatchDistributeCreditsFormValue,
  IBatchUploadByCorporateIdResponse,
} from '../../../pages/corporates-container/share-type'
import { distributeCreditsBulkUploadByCorporateId } from '../../../api/corporateApi'
import { FormattedMessage } from '../../intl'
import PhoneNumbersCheckModal from '../phone-numbers-check-modal/phone-numbers-check-modal'

const { TextArea } = Input

interface IBatchDistributeCreditsModalProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
  corporateId: number
  isTrialCorporate: boolean
}

const BatchDistributeCreditsModal = (props: IBatchDistributeCreditsModalProps) => {
  const { open, handleCancel, handleSubmit, corporateId, isTrialCorporate } = props
  const [form] = Form.useForm()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [phoneNumbersCheckModalVisible, setPhoneNumbersCheckModalVisible] = useState<boolean>(false)
  const [phoneNumbersCheckModalResult, setPhoneNumbersCheckModalResult] =
    useState<IBatchUploadByCorporateIdResponse>()
  const [formValue, setFormValue] = useState<FormData>()

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  const onFinish = (values: IBatchDistributeCreditsFormValue) => {
    if (corporateId) {
      const data = new FormData()
      if (values.file && values.file.length) {
        data.append('file', values.file[0].originFileObj)
      }
      if (values.phoneNumbers) {
        const phoneNumbers = _.split(values.phoneNumbers, ',')
        data.append('phoneNumbers', JSON.stringify(phoneNumbers))
      }
      data.append('credits', values.credits.toString())
      data.append('notes', values.notes || '')
      data.append('bufferPercentage', values.bufferPercentage.toString())
      data.append('expiredAt', values.expiredAt.format().toString())

      setFormValue(data)
      setLoadingButton(true)
      distributeCreditsBulkUploadByCorporateIdAction(data)
    }
  }

  const distributeCreditsBulkUploadByCorporateIdAction = (data: FormData) => {
    if (!corporateId) {
      return
    }
    distributeCreditsBulkUploadByCorporateId(corporateId, data)
      .then(res => {
        if (!res.isSuccess) {
          handleCancel()
          setPhoneNumbersCheckModalResult(res)
          setPhoneNumbersCheckModalVisible(true)
        } else {
          notification.success({
            message: translate('corporate.batchDistributeCredits.success'),
          })

          if (phoneNumbersCheckModalVisible) {
            setPhoneNumbersCheckModalVisible(false)
          }
          handleSubmit()
        }
      })
      .catch(err => {
        notification.error({
          message: translate('corporate.batchDistributeCredits.failed'),
          description: err.message ?? translate('networkError'),
        })
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const onActiveAndDistribute = () => {
    if (!formValue) {
      return
    }
    formValue.append('activate', 'true')
    setFormValue(formValue)
    distributeCreditsBulkUploadByCorporateIdAction(formValue)
  }

  const onSkip = () => {
    if (!formValue) {
      return
    }
    formValue.append('activate', 'false')
    setFormValue(formValue)
    distributeCreditsBulkUploadByCorporateIdAction(formValue)
  }

  const onCancel = () => {
    setPhoneNumbersCheckModalVisible(false)
  }

  return (
    <>
      <Modal
        title={translate('corporate.batchDistributeCredits')}
        open={open}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        width={800}
      >
        <Form
          layout="horizontal"
          name="batch_distribute_credits"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ deactivate: false, bufferPercentage: 0 }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="file"
            label={translate('corporate.phoneNumber.bulkUpload')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item name="phoneNumbers" label={translate('corporate.phoneNumber.specify')}>
            <TextArea placeholder="多个用户phone number，使用,隔开" showCount />
          </Form.Item>

          <Form.Item
            name="credits"
            label={translate('corporate.credits')}
            rules={[{ required: true, message: '请输入点数!' }]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label={translate('corporate.bufferPercentage')}
            name="bufferPercentage"
            rules={[
              {
                required: true,
                message: translate('corporate.bufferPercentage.required'),
              },
            ]}
          >
            <InputNumber disabled={isTrialCorporate} addonAfter="%" min={0} />
          </Form.Item>

          <Form.Item
            label={translate('corporate.expiredAt')}
            name="expiredAt"
            rules={[
              {
                required: true,
                message: translate('corporate.expiredAt.required'),
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item name="notes" label={translate('corporate.notes')}>
            <TextArea placeholder={translate('corporate.notes.placeholder')} showCount />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Space className={styles['button-group']}>
              <Button loading={loadingButton} type="primary" htmlType="submit">
                <FormattedMessage id="submit" />
              </Button>
              <Button loading={loadingButton} onClick={handleCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {phoneNumbersCheckModalResult && (
        <PhoneNumbersCheckModal
          visibale={phoneNumbersCheckModalVisible}
          result={phoneNumbersCheckModalResult}
          onCancel={onCancel}
          title={translate('corporate.batchDistributeCredits.modal.title', {
            number: phoneNumbersCheckModalResult?.inactivePhoneNumbers?.length,
          })}
          footer={
            <Space>
              <Button onClick={onCancel}>{translate('cancel')}</Button>
              <Button onClick={onSkip}>
                {translate('corporate.batchDistributeCredits.modal.cancelText')}
              </Button>
              <Button onClick={onActiveAndDistribute} type="primary">
                {translate('corporate.batchDistributeCredits.modal.okText')}
              </Button>
            </Space>
          }
        />
      )}
    </>
  )
}

export default BatchDistributeCreditsModal
