import { Button, Form, Select, Input, Cascader, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import DebounceSelect from '../../debounce-select/debounce-select'
import { getOptions } from '../../../helpers/utils'
import { ReduxStore } from '../../../store'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import {
  IStudioLeaderboardFilterParams,
  IStudioLeaderboardFilterValues,
  IStudioLeaderboardRankingType,
} from '../../../pages/studio-leaderboards-container/share-type'
import { City } from '../../../store/city/reducer'
import { INeightborhood, LocaleType } from '../../../share-types'
import translate from '../../../i18n'
import { getPaginatedStudioLeaderboardCategories } from '../../../api/studioLeaderboardCategoriesApi'
import { getNeighborhoodsByCityId } from '../../../api/authApi'
import {
  allCatroeyOption,
  allNeighborhoodOption,
} from '../../../pages/studio-leaderboards-container/constant'

const { Option } = Select

interface IProps extends ConnectedProps<typeof withConnect> {
  onSearch: (value: IStudioLeaderboardFilterParams) => void
  formData: IStudioLeaderboardFilterParams | undefined
  activeCities: City[]
  locale: LocaleType
  studioLeaderboardRankingTypes: IStudioLeaderboardRankingType[]
}

interface OptionType {
  label: string
  value: number
}

interface Option {
  value?: string | number | null
  label: React.ReactNode
  children?: Option[]
  isLeaf?: boolean
  loading?: boolean
}

const StudioLeaderboardsFilter = ({
  onSearch,
  formData,
  activeCities,
  locale,
  studioLeaderboardRankingTypes,
}: IProps) => {
  const [form] = Form.useForm()
  const [defaultStudioCategoryOptions, setDefaultStudioCategoryOptionsOptions] = useState<
    OptionType[]
  >([])
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    getPaginatedStudioLeaderboardCategories({}).then(res => {
      setDefaultStudioCategoryOptionsOptions(getOptions([allCatroeyOption].concat(res)))
    })
  }, [])
  useEffect(() => {
    setOptions(
      activeCities.map((city: City) => {
        const label = city.translations.find(t => t.locale === locale)?.name
        return {
          value: city.id,
          label: `${city.id} - ${label}`,
          isLeaf: false,
        }
      }),
    )
  }, [activeCities])
  useEffect(() => {
    if (!activeCities || !activeCities.length) {
      return
    }

    if (formData) {
      form.setFieldsValue({
        ...formData,
        area: [formData?.cityId, formData?.neighborhoodId],
      })
    }
  }, [formData, activeCities])
  const onFinish = (values: IStudioLeaderboardFilterValues) => {
    const { area, ...others } = values
    onSearch({
      cityId: area?.length ? area[0] : undefined,
      neighborhoodId: area?.length > 1 ? area[1] : undefined,
      ...others,
    })
  }

  async function fetchStudioCategorySuggestions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getPaginatedStudioLeaderboardCategories({ name: keyword }).then(res => {
      return getOptions([allCatroeyOption].concat(res))
    })
  }

  const loadData = (selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    getNeighborhoodsByCityId({ cityId: Number(targetOption.value) }).then(res => {
      targetOption.loading = false
      const neighborhoodOptions = res.map((item: INeightborhood) => {
        const label = item.translations.find(t => t.locale === locale)?.name
        return {
          value: item.id,
          label: `${item.id} - ${label}`,
        }
      })
      targetOption.children = [allNeighborhoodOption].concat(neighborhoodOptions)
      setOptions([...options])
    })
  }

  return (
    <Form
      className="filter-inline-form"
      form={form}
      layout="inline"
      initialValues={{ isActive: true }}
      onFinish={onFinish}
    >
      <Form.Item name="title" label={translate('studioLeaderboards.title')}>
        <Input />
      </Form.Item>
      <Form.Item name="type" label={translate('studioLeaderboards.type')}>
        <Select style={{ width: 130 }} allowClear>
          {studioLeaderboardRankingTypes.map((item: IStudioLeaderboardRankingType) => {
            return (
              <Option key={item.id} value={item.name}>
                {item.translations.find(t => t.locale === locale)?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="studioLeaderboardCategoryId"
        label={translate('studioLeaderboards.category')}
      >
        <DebounceSelect
          style={{ width: 130 }}
          showSearch={true}
          allowClear
          placeholder={translate('studioLeaderboards.category.placeholder')}
          fetchOptions={fetchStudioCategorySuggestions}
          defaultOptions={defaultStudioCategoryOptions}
        />
      </Form.Item>
      <Form.Item label={translate('studioLeaderboards.area')} name="area">
        <Cascader
          style={{ width: 200 }}
          options={options}
          loadData={loadData}
          changeOnSelect
          allowClear
        />
      </Form.Item>
      <Form.Item name="numberOfRankers" label={translate('studioLeaderboards.numberOfRankers')}>
        <InputNumber type="number" prefix=">=" min={1} />
      </Form.Item>

      <Form.Item
        name="isActive"
        label="活跃"
        tooltip={translate('studioLeaderboards.isActive.desc')}
      >
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {translate('search')}
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    firstPageSegments: state.segments.firstPageSegments,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioLeaderboardsFilter)
