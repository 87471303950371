import {
  Button,
  TimePicker,
  Form,
  FormInstance,
  FormItemProps,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
} from 'antd'
import { PlusOutlined, MinusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  AutoSetRuleToTypeMap,
  ConfigType,
  IStudioIntegrationLessonConfig,
  IStudioIntegrationLessonDescriptionConfig,
  StudioIntegrationFieldsAutoSetRule,
  StudioIntegrationFieldsAutoSetRuleEnum,
  StudioIntegrationLessonConfigFieldTypeEnums,
  StudioIntegrationLessonConfigRequest,
  StudioIntegrationLessonDescriptionFieldTypeEnums,
} from '../../../pages/studio-integration-config-container/share-types'
import translate from '../../../i18n'
import { TIME_FORMAT } from '../../../helpers/constant'
import { JSX } from 'react/jsx-runtime'
import { useEffect, useState } from 'react'
import {
  configToFormValues,
  formValuesToRequest,
} from '../../../helpers/studio-integration-config-helper'
import {
  getStudioIntegrationConfigDetail,
  getStudioIntegrationLessonDescriptionFieldsAutoSetRules,
  getStudioIntegrationLessonFieldsAutoSetRules,
} from '../../../api/studioIntegrationConfigsApi'

const { Option } = Select
const { RangePicker } = TimePicker
const { confirm } = Modal

interface StudioIntegrationConfigFormProps {
  type: ConfigType
  onSubmit: (data: any) => void
  data: IStudioIntegrationLessonConfig | IStudioIntegrationLessonDescriptionConfig | undefined
  studioIntegrationConfigId: string
}

const StudioIntegrationConfigForm = (props: StudioIntegrationConfigFormProps) => {
  const { type, data, onSubmit, studioIntegrationConfigId } = props

  const [form] = Form.useForm()
  const [fieldTypeOptions, setFieldTypeOptions] = useState<string[]>([])
  const [rules, setRules] = useState<StudioIntegrationFieldsAutoSetRuleEnum[]>([])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(configToFormValues(data))
    }
  }, [data])

  useEffect(() => {
    getStudioIntegrationConfigDetail(Number(studioIntegrationConfigId)).then(res => {
      const integratorType = res.integratorType

      if (type === ConfigType.LESSON) {
        getStudioIntegrationLessonFieldsAutoSetRules({ integratorType: integratorType }).then(
          data => {
            setFieldTypeOptions(Object.values(StudioIntegrationLessonConfigFieldTypeEnums))
            setRules(data.map(item => AutoSetRuleToTypeMap[item]))
          },
        )
      } else {
        getStudioIntegrationLessonDescriptionFieldsAutoSetRules({
          integratorType: integratorType,
        }).then(data => {
          setFieldTypeOptions(Object.values(StudioIntegrationLessonDescriptionFieldTypeEnums))
          setRules(data.map(item => AutoSetRuleToTypeMap[item]))
        })
      }
    })
  }, [type])

  const rangeConfig = {
    rules: [
      {
        type: 'array' as const,
        required: true,
      },
    ],
  }

  const validateRules = (rules: StudioIntegrationFieldsAutoSetRule[]) => {
    const seenTypes = new Set()

    for (const rule of rules) {
      if (seenTypes.has(rule.type)) {
        confirm({
          title: '规则类型重复',
          icon: <ExclamationCircleOutlined />,
          content: '规则类型不能重复，请重新选择',
        })
        return false
      }
      seenTypes.add(rule.type)
    }

    return true
  }

  const onFinish = (values: StudioIntegrationLessonConfigRequest) => {
    if (studioIntegrationConfigId && values.rules && validateRules(values.rules)) {
      const data = formValuesToRequest(values, studioIntegrationConfigId)
      onSubmit(data)
    }
  }

  const renderIdSelector = (
    restField: JSX.IntrinsicAttributes & FormItemProps<any>,
    name: number,
    fieldName: string,
    labelName: string,
  ) => (
    <Form.Item
      {...restField}
      label={labelName}
      name={[name, fieldName]}
      style={{ width: 450, marginBottom: 0 }}
      rules={[{ required: true }]}
      initialValue={[]}
    >
      <Select
        mode="tags"
        style={{ width: '100%' }}
        tokenSeparators={[',']}
        placeholder={`多个${labelName}，使用,隔开`}
      />
    </Form.Item>
  )

  const renderInputBasedOnType = (
    form: FormInstance<any>,
    index: number,
    restField: JSX.IntrinsicAttributes & FormItemProps<any>,
    name: number,
  ) => {
    const type = form.getFieldValue(['rules', index, 'type'])

    switch (type) {
      case StudioIntegrationFieldsAutoSetRuleEnum.TIME_RANGE_RULE:
        return (
          <Form.List name={[name, 'timeRanges']}>
            {(timeFields, { add: addTime, remove: removeTime }) => (
              <>
                {timeFields.map(({ key: timeKey, name: timeName, ...restTimeField }) => (
                  <Space key={timeKey} style={{ display: 'flex', marginBottom: 8 }} align="start">
                    <Form.Item
                      {...restTimeField}
                      name={[timeName]}
                      label={translate('studioIntegrationLessonConfig.timeRanges')}
                      {...rangeConfig}
                    >
                      <RangePicker format={TIME_FORMAT} style={{ width: '100%' }} />
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>
                  <Space>
                    <Button type="dashed" onClick={() => addTime()} icon={<PlusOutlined />}>
                      Add Time Range
                    </Button>
                    {timeFields.length > 0 && (
                      <Button
                        type="default"
                        onClick={() => removeTime(timeFields.length - 1)}
                        icon={<MinusCircleOutlined />}
                        danger
                      >
                        Remove Last Time Range
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </>
            )}
          </Form.List>
        )
      case StudioIntegrationFieldsAutoSetRuleEnum.SPECIFIC_LESSON_DESCRIPTIONS_RULE:
        return renderIdSelector(restField, name, 'lessonDescriptionIds', 'Lesson Description IDs')
      case StudioIntegrationFieldsAutoSetRuleEnum.SPECIFIC_ORGANIZATIONS_RULE:
        return renderIdSelector(restField, name, 'organizationIds', 'Organization IDs')
      case StudioIntegrationFieldsAutoSetRuleEnum.SPECIFIC_STUDIOS_RULE:
        return renderIdSelector(restField, name, 'studioIds', 'Studio IDs')
      default:
        return null
    }
  }

  return (
    <Form
      name="studio-integration-lesson-config"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        label={translate('studioIntegrationConfig.fieldType')}
        name="fieldType"
        rules={[
          { required: true, message: translate('studioIntegrationConfig.fieldType.message') },
        ]}
      >
        <Select style={{ minWidth: 130 }} allowClear>
          {fieldTypeOptions.map(value => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={translate('studioIntegrationConfig.fieldValue')}
        name="fieldValue"
        rules={[
          { required: true, message: translate('studioIntegrationConfig.fieldType.message') },
        ]}
      >
        <InputNumber style={{ minWidth: 140 }} />
      </Form.Item>

      <Form.Item label={translate('isActive')} name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.List name="rules" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Form.Item {...restField} label={`配置规则${index + 1}`} key={key}>
                <Space align="baseline">
                  <Space align="baseline" wrap>
                    <Form.Item
                      {...restField}
                      noStyle
                      name={[name, 'type']}
                      rules={[{ required: true }]}
                    >
                      <Select style={{ width: 300 }}>
                        {rules.map(value => (
                          <Select.Option key={value} value={value}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.rules[index]?.type !== curValues.rules[index]?.type
                      }
                      noStyle
                    >
                      {() => renderInputBasedOnType(form, index, restField, name)}
                    </Form.Item>
                  </Space>
                </Space>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ marginLeft: '10px' }}
                    onClick={() => remove(name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item colon={false} label="  ">
              <Button
                type="dashed"
                onClick={() => {
                  add()
                }}
                block
                icon={<PlusOutlined />}
              >
                添加规则
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item wrapperCol={{ offset: 11, span: 18 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default StudioIntegrationConfigForm
