import { Button, Descriptions, Drawer, Row } from 'antd'
import { PageInfo } from '../../../share-types'
import { useEffect, useState } from 'react'
import { getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberId } from '../../../api/corporateApi'
import Table, { ColumnsType } from 'antd/lib/table'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'
import {
  ICorporateMember,
  ICorporateMemberCreditsHistory,
  ICorporateMemberCreditsHistoryFilterParams,
  IPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdParams,
} from '../../../pages/corporates-container/share-type'
import {
  CorporateMemberCreditsHistoryReferenceTypeEnums,
  CorporateMemberCreditsHistoryReferenceTypeEnumsOptions,
  CorporateMemberCreditsHistoryTypeEnums,
  CorporateMemberCreditsHistoryTypeEnumsOptions,
} from '../../../pages/corporates-container/constant'
import CorporateMemberCreditsHistoriesFilter from '../corporate-member-credits-histories-filter/corporate-member-credits-histories-filter'
import config from '../../../config/index'

const defaultPage = {
  pageSize: 20,
  currentPage: 1,
  count: 0,
}

interface IProps {
  corporateMember: ICorporateMember
  corporateId: number
  open: boolean
  onClose: () => void
}

const { guavapassUrl } = config

const CorporateMemberCreditsHistories = (props: IProps) => {
  const { corporateMember, corporateId, onClose, open } = props
  const [corporateMemberCreditsHistories, setCorporateMemberCreditsHistories] = useState<
    ICorporateMemberCreditsHistory[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ICorporateMemberCreditsHistoryFilterParams>()

  useEffect(() => {
    if (open && corporateMember.id) {
      const data = {
        size: defaultPage.pageSize,
        page: defaultPage.currentPage - 1,
      }
      getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdAction(data)
    }
  }, [open])

  const getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdAction = (
    data: IPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdParams,
  ) => {
    getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberId(corporateMember.id, data).then(
      res => {
        if (res && res.pageInfo && res.data) {
          setPageInfo({
            ...res.pageInfo,
            currentPage: res.pageInfo.currentPage + 1,
          })
          setCorporateMemberCreditsHistories(res.data)
        }
      },
    )
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
    }
    getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdAction(data)
  }
  const onSearch = (value: ICorporateMemberCreditsHistoryFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    getPaginatedCorporateMemberCreditsHistoriesByCorporateMemberIdAction(data)
  }

  const columns: ColumnsType<ICorporateMemberCreditsHistory> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      width: 80,
      fixed: 'left',
    },
    {
      title: translate('corporateMemberCreditsHistories.credits'),
      dataIndex: 'credits',
      key: 'credits',
      width: 80,
      fixed: 'left',
      render: (credits: number) => {
        if (credits > 0) {
          return <span>+{credits}</span>
        }
        return credits
      },
    },
    {
      title: translate('corporateMemberCreditsHistories.type'),
      dataIndex: 'type',
      key: 'type',
      width: 120,
      fixed: 'left',
      render: (type: CorporateMemberCreditsHistoryTypeEnums) => {
        return CorporateMemberCreditsHistoryTypeEnumsOptions.find(item => item.value === type)
          ?.label
      },
    },
    {
      title: translate('corporateMemberCreditsHistories.referenceId'),
      dataIndex: 'referenceId',
      key: 'referenceId',
    },
    {
      title: translate('corporateMemberCreditsHistories.referenceType'),
      dataIndex: 'referenceType',
      key: 'referenceType',
      render: (referenceType: CorporateMemberCreditsHistoryReferenceTypeEnums) => {
        return CorporateMemberCreditsHistoryReferenceTypeEnumsOptions.find(
          item => item.value === referenceType,
        )?.label
      },
    },
    {
      title: translate('corporateMemberCreditsHistories.notes'),
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: translate('corporateMemberCreditsHistories.createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return getDateTime(createdAt)
        }
        return ''
      },
      width: 180,
    },
  ]

  return (
    <Drawer
      title={translate('corporateMemberCreditsHistories.drwerTitle')}
      placement="right"
      onClose={onClose}
      open={open}
      width={1200}
    >
      <Descriptions title="">
        <Descriptions.Item label={translate('corporateMemberCreditsHistories.corporateId')}>
          {corporateId}
        </Descriptions.Item>
        <Descriptions.Item label={translate('corporateMemberCreditsHistories.corporateMemberId')}>
          {corporateMember.id}
        </Descriptions.Item>
        <Descriptions.Item label={translate('corporateMemberCreditsHistories.phonNumber')}>
          {corporateMember.phoneNumber}
        </Descriptions.Item>
        {corporateMember.userId && (
          <Descriptions.Item label={translate('userId')}>
            <Button
              type="link"
              target="_black"
              href={`${guavapassUrl}/admin/users/${corporateMember.userId}`}
              style={{ padding: 0, lineHeight: 'normal' }}
            >
              {corporateMember.userId}
            </Button>
          </Descriptions.Item>
        )}
      </Descriptions>

      <Row className="filter-bar" style={{ marginBottom: 16 }}>
        <CorporateMemberCreditsHistoriesFilter onSearch={onSearch} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={corporateMemberCreditsHistories}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
        scroll={{ x: 1200 }}
      />
    </Drawer>
  )
}

export default CorporateMemberCreditsHistories
