import { Form, InputNumber } from 'antd'
import React from 'react'
import styles from './range-input-form-item.module.less'

interface RangeInputProps {
  minField: string
  maxField: string
  label: string
  addonAfter?: string
  min?: number
  max?: number
}

const createRangeValidatorRules = (minField: string, maxField: string) => ({
  min: [
    ({ getFieldValue }: { getFieldValue: (field: string) => any }) => ({
      validator(_: any, value: number) {
        if (getFieldValue(maxField) && value === undefined) {
          return Promise.reject(new Error('请输入最小值'))
        }
        if (getFieldValue(maxField) && getFieldValue(maxField) < value) {
          return Promise.reject(new Error('最小值不能大于最大值'))
        }
        return Promise.resolve()
      },
    }),
  ],
  max: [
    ({ getFieldValue }: { getFieldValue: (field: string) => any }) => ({
      validator(_: any, value: number) {
        if (getFieldValue(minField) && value === undefined) {
          return Promise.reject(new Error('请输入最大值'))
        }
        if (getFieldValue(minField) && getFieldValue(minField) > value) {
          return Promise.reject(new Error('最大值不能小于最小值'))
        }
        return Promise.resolve()
      },
    }),
  ],
})

const RangeInputFormItem: React.FC<RangeInputProps> = ({
  minField,
  maxField,
  label,
  addonAfter,
  min = 0,
  max,
}) => {
  const rules = createRangeValidatorRules(minField, maxField)

  return (
    <Form.Item label={label}>
      <div style={{ display: 'flex' }}>
        <Form.Item name={minField} rules={rules.min} style={{ display: 'inline-block' }}>
          <InputNumber addonAfter={addonAfter} min={min} max={max} />
        </Form.Item>
        <span className={styles['range-separator']}>-</span>
        <Form.Item name={maxField} rules={rules.max} style={{ display: 'inline-block' }}>
          <InputNumber addonAfter={addonAfter} min={min} max={max} />
        </Form.Item>
      </div>
    </Form.Item>
  )
}

export default RangeInputFormItem
