import { Button, Modal, Space, Form, Input } from 'antd'
import { IBookingReviewDeleteParams } from '../../../pages/booking-reviews-container/share-type'
import style from './delete-booking-review-modal.module.less'

interface IDeleteBookingReviewModalProps {
  loading: boolean
  visible: boolean
  handleCancel: () => void
  handleOk: (value: IBookingReviewDeleteParams) => void
}

const { TextArea } = Input

const DeleteBookingReviewModal = (props: IDeleteBookingReviewModalProps) => {
  const { visible, handleOk, handleCancel, loading } = props
  const [form] = Form.useForm<IBookingReviewDeleteParams>()

  return (
    <Modal
      centered
      open={visible}
      onCancel={handleCancel}
      title={
        <Space>
          <div>删除当前评论?</div>
          <div className={style['sub-title']}>删除评论后，将不在小程序课程评论，场馆评论中显示</div>
        </Space>
      }
      footer={null}
      width={600}
      bodyStyle={{ padding: '24px 80px' }}
    >
      <Form
        name="delete_booking_review"
        initialValues={{
          code: '',
          isAuto: false,
          showInPopup: false,
          showCountDown: false,
        }}
        onFinish={handleOk}
        form={form}
      >
        <Form.Item
          label="删除原因"
          name="deleteReason"
          rules={[{ required: true, message: '请输入删除原因' }]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              确认删除
            </Button>
            <Button onClick={handleCancel}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DeleteBookingReviewModal
