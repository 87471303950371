/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import createRootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../saga/index'

export default function configureStore(initialState = {}, defaultReducers) {
  // Create the store with  middlewares
  // routerMiddleware: Syncs the location/URL path to the state
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [sagaMiddleware]

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // eslint-disable-next-line no-undef
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false,
        })
      : compose
  /* eslint-enable */

  const reducers = { ...defaultReducers }

  const store = createStore(
    createRootReducer(reducers),
    initialState,
    composeEnhancers(...enhancers),
  )

  sagaMiddleware.run(rootSaga)

  store.injectedReducers = reducers

  return store
}
