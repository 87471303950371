import React, { useEffect, useState } from 'react'
import { Table, Tag, Space, Button, Row, Col, Modal, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import styles from './admin-roles.module.less'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ReduxStore } from '../../../store'
import { deleteAdminRole, getAdminRoles } from '../../../api/roleApi'
import { Permission, Role } from '../share-type'
import { getFormatAdminRole } from '../../../helpers/role-helper'
import { keysToCamel } from '../../../helpers/keys-to-case'
import AuthPermission from '../../../high-order-components/auth-permission'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import LinkButton from '../../../components/link-button/link-button'
import { PermissionsEnum } from '../../../share-types'

const { confirm } = Modal

const AdminRoles = () => {
  const navigate = useNavigate()
  const [roles, setRoles] = useState<Role[]>()

  const deleteRole = (event: React.MouseEvent<HTMLElement>, roleId: number) => {
    event.stopPropagation()
    confirm({
      title: '确认删除角色？',
      onOk: async () => {
        await deleteAdminRole(roleId)
        notification.success({
          message: '删除角色成功',
        })
        getRoles()
      },
    })
  }

  useEffect(() => {
    getRoles()
  }, [])

  const getRoles = () => {
    getAdminRoles().then(res => {
      const roles = res.map(item => getFormatAdminRole(keysToCamel(item)))
      setRoles(roles)
    })
  }

  const columns = [
    {
      title: '角色名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '修改时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: '角色权限',
      dataIndex: 'permissions',
      key: 'permissions',
      name: 'permissions',
      render: (permissions: Permission[]) => {
        return permissions.map(item => {
          return (
            <Tag key={item.id} color="blue">
              {item.scope}
            </Tag>
          )
        })
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.PERMISSIONS_WRITE}>
            <LinkButton to={`/admin-roles/${record.id}/edit`}>编辑</LinkButton>
          </AuthPermission>

          <AuthPermission permission={PermissionsEnum.PERMISSIONS_WRITE}>
            <Button danger type="link" block onClick={event => deleteRole(event, record.id)}>
              删除
            </Button>
          </AuthPermission>
        </Space>
      ),
    },
  ]

  return (
    <Space className={styles['coupon-list-space']} direction="vertical" wrap size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['left-col']}>
          <div>角色列表</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['right-col']}>
          <AuthPermission permission={PermissionsEnum.PERMISSIONS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/admin-roles/new')}
            >
              创建新角色
            </Button>
          </AuthPermission>
        </Col>
      </Row>

      <Table rowKey={record => record.id} dataSource={roles} columns={columns} />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    permissions: state.global.permissions,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(AdminRoles)
