import qs from 'qs'

import config from '../config/index'
import {
  CalculateBillingSummaryRequest,
  IOrganization,
  IPaginatedOrganization,
  IPaginatedOrganizationFilterParams,
} from '../pages/organization-container/share-type'
import { authRequest } from '../request'
import { ISimpleOrganization } from '../share-types'

const { mainApi } = config

export const getOrganizationsSuggestion = (keyword: string) =>
  authRequest.get<MISSING_TYPE, ISimpleOrganization[]>(
    `${mainApi}/organizations/suggestions?keyword=${keyword}`,
  )

export const getOrganizationsSuggestionsByIds = (ids: number[]) =>
  authRequest.get<MISSING_TYPE, ISimpleOrganization[]>(
    `${mainApi}/organizations/suggestions-by-ids`,
    {
      params: {
        ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const getOrganizations = (params: IPaginatedOrganizationFilterParams) =>
  authRequest.get<MISSING_TYPE, IPaginatedOrganization>(`${mainApi}/organizations`, {
    params,
  })

export const getOrganizationDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IOrganization>(`${mainApi}/organizations/${id}`)

export const calculateBillingSummaries = (data: CalculateBillingSummaryRequest) =>
  authRequest.post<MISSING_TYPE, MISSING_TYPE>(
    `${mainApi}/organizations/calculate-billing-summaries`,
    data,
  )
