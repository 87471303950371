import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { ILessonDescriptionFilterParams } from '../../../pages/lesson-description-container/share-type'

const { Option } = Select
const LessonDescriptionsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: ILessonDescriptionFilterParams) => void
  formData: ILessonDescriptionFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: ILessonDescriptionFilterParams) => {
    const { organizationId, externalProviderId, ...others } = values
    onSearch({
      ...others,
      organizationId: organizationId ? Number(organizationId) : undefined,
      externalProviderId: externalProviderId ? Number(externalProviderId) : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" style={{ gap: '16px' }} onFinish={onFinish}>
      <Form.Item name="name" label={translate('lessonDescription.name')}>
        <Input />
      </Form.Item>

      <Form.Item name="isRedirected" label={translate('lessonDescription.isRedirected')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="organizationId" label={translate('lessonDescription.organizationId')}>
        <Input />
      </Form.Item>

      <Form.Item name="isAllDay" label={translate('lessonDescription.isAllDay')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="isActive" label={translate('lessonDescription.isActive')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="hasExternalProviderId"
        label={translate('lessonDescription.hasExternalProviderId')}
      >
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="externalProviderId"
        label={translate('lessonDescription.externalProviderId')}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LessonDescriptionsFilter
