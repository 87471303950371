import { useEffect, useState } from 'react'

import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'

import Table, { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { Button, Col, Row, Space, Typography } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import {
  ILessonDescription,
  ILessonDescriptionFilterParams,
  IPaginatedLessonDescription,
  IPaginatedLessonDescriptionFilterParams,
  LessonTranslation,
} from '../share-type'
import { getLessonDescriptions } from '../../../api/lessonDescriptionsApi'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import LessonDescriptionsFilter from '../../../components/lesson-descriptions/lesson-descriptions-filter/lesson-descriptions-filter'
import LinkButton from '../../../components/link-button/link-button'
import DuplicateLessonDescriptionDrawer from '../../../components/lesson-descriptions/duplicate-lesson-descriptions-drawer/duplicate-lesson-descriptions-drawer'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const { Text } = Typography

const LessonDescriptions = () => {
  const navigate = useNavigate()
  const [lessonDescriptions, setLessonDescriptionss] = useState<ILessonDescription[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ILessonDescriptionFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [openDuplicateLessonDescriptionModal, setDuplicateLessonDescriptionModal] =
    useState<boolean>(false)

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'name', isBoolean: false },
        {
          key: 'isRedirected',
          isBoolean: true,
        },
        { key: 'organizationId', isBoolean: false },
        { key: 'isAllDay', isBoolean: true },
        { key: 'isActive', isBoolean: true },
        { key: 'hasExternalProviderId', isBoolean: true },
        {
          key: 'externalProviderId',
          isBoolean: false,
        },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedLessonDescriptionssAction(data)
  }, [])

  const getPaginatedLessonDescriptionssAction = (data: IPaginatedLessonDescriptionFilterParams) => {
    getLessonDescriptions(data).then((res: IPaginatedLessonDescription) => {
      if (res) {
        setLessonDescriptionss(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const onSearch = (values: ILessonDescriptionFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo.pageSize,
      ...values,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(values))
    getPaginatedLessonDescriptionssAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedLessonDescriptionssAction(data)
  }

  const columns: ColumnsType<ILessonDescription> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('lessonDescription.name'),
      dataIndex: 'lessonDescriptionTranslations',
      key: 'name',
      render: (lessonDescriptionTranslations: LessonTranslation[]) => {
        return (
          <Text>
            {lessonDescriptionTranslations.length ? lessonDescriptionTranslations[0]?.name : ''}
          </Text>
        )
      },
    },
    {
      title: translate('lessonDescription.organizationId'),
      dataIndex: 'organizationId',
      key: 'organizationId',
      render: (organizationId: number) => (
        <LinkButton
          to={`/organizations/${organizationId}`}
          style={{ padding: 0, textAlign: 'left' }}
        >
          {organizationId}
        </LinkButton>
      ),
    },
    {
      title: translate('lessonDescription.duration'),
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: translate('lessonDescription.externalProviderId'),
      dataIndex: 'externalProviderId',
      key: 'externalProviderId',
    },
    {
      title: translate('lessonDescription.isRedirected'),
      dataIndex: 'isRedirected',
      key: 'isRedirected',
      render: (isRedirected: boolean) => {
        return <YesOrNoTag value={isRedirected} />
      },
    },
    {
      title: translate('lessonDescription.isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => {
        return <YesOrNoTag value={isActive} />
      },
    },
    {
      title: translate('lessonDescription.isAllDay'),
      dataIndex: 'isAllDay',
      key: 'isAllDay',
      render: (isAllDay: boolean) => {
        return <YesOrNoTag value={isAllDay} />
      },
    },
    {
      title: translate('lessonDescription.credits'),
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: ILessonDescription) => (
        <AuthPermission permission={PermissionsEnum.LESSON_DESCRIPTIONS_READ}>
          <Button type="link" onClick={() => onViewLessonDescriptions(record)}>
            {translate('view')}
          </Button>
        </AuthPermission>
      ),
    },
  ]

  const onViewLessonDescriptions = (record: ILessonDescription) => {
    navigate(`/lesson-descriptions/${record.id}`)
  }

  return (
    <Space className="table-space" direction="vertical" size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.lessonDescriptionsTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Space size={16}>
            <AuthPermission permission={PermissionsEnum.DUPLICATION_LESSON_DESCRIPTIONS_READ}>
              <Button type="primary" onClick={() => setDuplicateLessonDescriptionModal(true)}>
                {translate('duplicateLessonDescription.search')}
              </Button>
            </AuthPermission>
          </Space>
        </Col>
      </Row>

      <div className="filter-bar">
        <LessonDescriptionsFilter onSearch={onSearch} formData={searchValues} />
      </div>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={lessonDescriptions}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
      <DuplicateLessonDescriptionDrawer
        open={openDuplicateLessonDescriptionModal}
        onClose={() => setDuplicateLessonDescriptionModal(false)}
      />
    </Space>
  )
}

export default LessonDescriptions
