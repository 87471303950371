import { Button, Modal, notification, Space, Table, Tabs, Image, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { getDateTime } from '../../../helpers/utils'
import {
  changeBillingInvoiceStatus,
  getBillingInvoiceDownloadUrl,
  getBillingInvoices,
} from '../../../api/billingInvoicesApi'
import translate from '../../../i18n'
import RejectReasonModal from '../reject-reason-modal/reject-reason-modal'
import {
  BillingInvoiceStatusEnums,
  IBillingInvoice,
  IBillingInvoiceFilterParams,
  IBillingInvoiceStatusChangeParams,
  IPaginatedBillingInvoiceFilterParams,
  PaginatedBillingInvoicesResponse,
} from '../../../pages/billing-invoices-container/share-type'
import { BillingInvoiceStatusOptions } from '../../../pages/billing-invoices-container/constant'
import AuthPermission from '../../../high-order-components/auth-permission'
import LinkButton from '../../link-button/link-button'
import { FilePdfOutlined } from '@ant-design/icons'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

interface IProps {
  organizationId?: number
  status?: BillingInvoiceStatusEnums
  page?: number
  size?: number
  showOrganizationColumn?: boolean
  onSetSearchParams?: (params: IBillingInvoiceFilterParams) => void
}
const BillingInvoicesList = (props: IProps) => {
  const {
    organizationId,
    page,
    size,
    status,
    showOrganizationColumn = true,
    onSetSearchParams = () => {},
  } = props
  const [searchTab, setSearchTab] = useState<BillingInvoiceStatusEnums>(
    status ?? BillingInvoiceStatusEnums.PENDING,
  )
  const [billingInvoices, setBillingInvoices] = useState<IBillingInvoice[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [billingInvoiceId, setBillingInvoiceId] = useState<number | null>(null)
  const [defaultPageSize] = useState<number>(size ?? defaultPage.pageSize)
  const [defaultCurrentPage] = useState<number>(page ?? defaultPage.currentPage - 1)

  useEffect(() => {
    const data = {
      size: defaultPageSize,
      page: defaultCurrentPage,
      status: searchTab,
    }

    getBillingInvoicesAction(data)
  }, [organizationId])

  const getBillingInvoicesAction = (data: IPaginatedBillingInvoiceFilterParams) => {
    onSetSearchParams({ ...data, organizationId })

    getBillingInvoices({ ...data, organizationId }).then(
      (res: PaginatedBillingInvoicesResponse) => {
        if (res) {
          setBillingInvoices(res.data)
          setPageInfo({
            ...res.pageInfo,
            currentPage: res.pageInfo.currentPage + 1,
          })
        }
      },
    )
  }

  const baseColumns: ColumnsType<IBillingInvoice> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'organizationId',
      dataIndex: 'organizationId',
      key: 'organizationId',
      render: (organizationId: number) => (
        <LinkButton
          to={`/organizations/${organizationId}`}
          style={{ padding: 0, textAlign: 'left' }}
        >
          {organizationId}
        </LinkButton>
      ),
    },
    {
      title: '组织名称',
      dataIndex: 'organizationName',
      key: 'organizationName',
      render: (organizationName: string) => {
        return <span>{organizationName}</span>
      },
    },
    {
      title: '发票文件',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      render: (fileUrl: string) => {
        const url = fileUrl.split('?')[0]
        if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png')) {
          return <Image src={fileUrl} width={200} />
        }
        if (url.endsWith('.pdf')) {
          return <FilePdfOutlined style={{ fontSize: 100, color: '#D42F60' }} />
        }
        return <Image src={url} width={200} />
      },
    },
    {
      title: '发票状态',
      dataIndex: 'status',
      key: 'status',
      render: (status: BillingInvoiceStatusEnums) => {
        const option = BillingInvoiceStatusOptions.find(item => item.value === status)
        return <Tag color={option?.color}>{option?.label}</Tag>
      },
    },
    {
      title: '发票金额',
      dataIndex: 'amountCents',
      key: 'amountCents',
      render: (amountCents: number) => {
        return `${amountCents / 100} 元`
      },
    },
    {
      title: '备注',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: '拒绝原因',
      dataIndex: 'rejectReason',
      key: 'rejectReason',
    },
    {
      title: '发票上传时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
  ]

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      status: searchTab,
    }
    getBillingInvoicesAction(data)
  }

  const onChangeStatus = (id: number, status: BillingInvoiceStatusEnums) => {
    if (status === BillingInvoiceStatusEnums.REJECTED) {
      setShowRejectModal(true)
      setBillingInvoiceId(id)
    } else {
      Modal.confirm({
        title: '确认',
        content: `确认将该发票状态修改为${BillingInvoiceStatusOptions.find(item => item.value === status)?.label}吗？`,
        onOk: () => {
          changeBillingInvoiceStatusAction(id, { status })
        },
      })
    }
  }

  const handleSubmit = (values: { rejectReason: string }) => {
    if (!billingInvoiceId) {
      return
    }
    changeBillingInvoiceStatusAction(billingInvoiceId, {
      status: BillingInvoiceStatusEnums.REJECTED,
      rejectReason: values.rejectReason,
    })
  }

  const handleCancel = () => {
    setShowRejectModal(false)
  }

  const changeBillingInvoiceStatusAction = (
    id: number,
    data: IBillingInvoiceStatusChangeParams,
  ) => {
    setLoadingButton(true)
    changeBillingInvoiceStatus(id, data)
      .then(() => {
        getBillingInvoicesAction({
          size: defaultPageSize,
          page: defaultPage.currentPage - 1,
          status: searchTab,
        })

        notification.success({
          message: '操作成功',
        })
      })
      .catch(err => {
        notification.error({
          message: '操作失败',
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        setLoadingButton(false)
        setShowRejectModal(false)
      })
  }

  const downloadActionColumns = [
    {
      title: '操作',
      key: 'action2',
      render: (record: IBillingInvoice) => {
        return (
          <Space>
            <Button type="link" onClick={() => onDownloadBillingInvoice(record.id)}>
              下载文件
            </Button>
          </Space>
        )
      },
    },
  ]

  const onDownloadBillingInvoice = (id: number) => {
    getBillingInvoiceDownloadUrl(id)
      .then(res => {
        if (res) {
          window.open(res.preSignedUrl, '_blank')
        }
      })
      .catch(err => {
        notification.error({
          message: '下载文件失败',
          description: err?.message || translate('networkError'),
        })
      })
  }

  const tabsItems = BillingInvoiceStatusOptions.map(item => {
    let columns: ColumnsType<IBillingInvoice> = []
    const formatBaseColumns = showOrganizationColumn
      ? baseColumns
      : baseColumns.filter(column => column.key !== 'organizationId')
    if (item.value === BillingInvoiceStatusEnums.PENDING) {
      columns = formatBaseColumns
        .filter(column => column.key !== 'rejectReason')
        .concat([
          {
            title: '操作',
            key: 'action3',
            render: (record: IBillingInvoice) => {
              return (
                <Space>
                  <Button type="link" onClick={() => onDownloadBillingInvoice(record.id)}>
                    下载文件
                  </Button>
                  <AuthPermission permission={PermissionsEnum.BILLING_INVOICES_WRITE}>
                    <Space>
                      <Button
                        type="link"
                        disabled={loadingButton}
                        onClick={() =>
                          onChangeStatus(record.id, BillingInvoiceStatusEnums.APPROVED)
                        }
                      >
                        同意
                      </Button>
                      <Button
                        type="link"
                        danger
                        disabled={loadingButton}
                        onClick={() =>
                          onChangeStatus(record.id, BillingInvoiceStatusEnums.REJECTED)
                        }
                      >
                        拒绝
                      </Button>
                    </Space>
                  </AuthPermission>
                </Space>
              )
            },
          },
        ])
    } else if (item.value === BillingInvoiceStatusEnums.APPROVED) {
      columns = formatBaseColumns
        .filter(column => column.key !== 'rejectReason')
        .concat(downloadActionColumns)
    } else if (item.value === BillingInvoiceStatusEnums.REJECTED) {
      columns = formatBaseColumns.concat(downloadActionColumns)
    } else if (item.value === BillingInvoiceStatusEnums.REVOKED) {
      columns = formatBaseColumns
        .filter(column => column.key !== 'rejectReason')
        .concat(downloadActionColumns)
    }

    return {
      label: item.label,
      key: item.value,
      children: (
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={billingInvoices}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
        />
      ),
    }
  })

  const onChange = (key: string) => {
    setSearchTab(key as BillingInvoiceStatusEnums)
    const data = {
      size: defaultPageSize,
      page: defaultCurrentPage,
      status: key as BillingInvoiceStatusEnums,
    }

    getBillingInvoicesAction(data)
  }
  return (
    <>
      <Tabs activeKey={searchTab} onChange={onChange} items={tabsItems} />

      <RejectReasonModal
        show={showRejectModal}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        loadingButton={loadingButton}
      ></RejectReasonModal>
    </>
  )
}

export default BillingInvoicesList
