import { IPaginatedParams, PageInfo, UserDto } from '../../share-types'

export enum SegmentTypeEnum {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
  IKEA = 'IKEA',
}

export interface ISegment {
  id: number
  name: string
  description: string
  type: string
  isActive: boolean
  createdAt: string
  updatedAt: string
}

export interface PaginatedSegmentsResponse {
  pageInfo: PageInfo
  data: ISegment[]
}

export interface ISegmentsFilterParams {
  name?: string
  type?: string
  isActive?: boolean
}

export type IPaginatedSegmentsParams = IPaginatedParams & ISegmentsFilterParams

export interface SegmentRequest {
  name: string
  type: string
  description: string
  isActive: boolean
}

export interface IUserSegment {
  id: number
  userId: number
  user: UserDto
  segmentId: number
  createdAt: number
  updatedAt: number
}

export interface IPaginatedUserSegmentsParams {
  page: number
  size: number
}

export interface PaginatedUserSegmentsResponse {
  pageInfo: PageInfo
  data: IUserSegment[]
}

export type IUserSegmentsBulkUploadParams = {
  file: MISSING_TYPE
  userIds: string
}
