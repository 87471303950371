import { Button, Col, Row, Space, Table, Tabs, Tag, Tooltip, notification } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { INeightborhood, PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import StudioLeaderboardsFilter from '../../../components/studio-leaderboards/studio-leaderboards-filter/studio-leaderboards-filter'
import {
  IStudioLeaderboard,
  IStudioLeaderboardFilterParams,
  IPaginatedStudioLeaderboardsParams,
  IStudioLeaderboardRankingType,
  StudioLeaderboardStatusEnum,
} from '../share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import {
  getPaginatedStudioLeaderboards,
  updateStudioLeaderboardStatus,
  batchApproveStudioLeaderboards,
} from '../../../api/studioLeaderboardsApi'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import { ReduxStore } from '../../../store'
import { City } from '../../../store/city/reducer'
import { StudioLeaderboardStatusOptions } from '../constant'
import { setStudioLeaderboardsAction } from '../../../store/studio-leaderboard/action'
import { IStudioLeaderboardCategory } from '../../studio-leaderboard-categories-container/share-type'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const StudioLeaderboards = (props: ConnectedProps<typeof withConnect>) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams({
    status: StudioLeaderboardStatusEnum.PENDING,
  })
  const [searchValues, setSearchValues] = useState<IStudioLeaderboardFilterParams>()
  const {
    locale,
    activeCities,
    studioLeaderboardRankingTypes,
    setStudioLeaderboardsAction,
    pendingStudioLeaderboards,
    approvedStudioLeaderboards,
    rejectedStudioLeaderboards,
    expiredStudioLeaderboards,
  } = props
  const [searchTab, setSearchTab] = useState<StudioLeaderboardStatusEnum>(
    StudioLeaderboardStatusEnum.PENDING,
  )

  useEffect(() => {
    const searchTitle = searchParams.get('title')
    const searchType = searchParams.get('type')
    const searchStudioLeaderboardCategory = searchParams.get('studioLeaderboardCategoryId')
    const searchCity = searchParams.get('cityId')
    const searchNeighborhoods = searchParams.get('neighborhoodId')
    const searchNumberOfRankers = searchParams.get('numberOfRankers')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const searchIsActive = searchParams.get('isActive')

    const newSearchValues = {
      title: searchTitle ?? undefined,
      type: searchType ?? undefined,
      studioLeaderboardCategoryId: searchStudioLeaderboardCategory
        ? Number(searchStudioLeaderboardCategory)
        : undefined,
      neighborhoodId: searchNeighborhoods ? Number(searchNeighborhoods) : undefined,
      numberOfRankers: searchNumberOfRankers ? Number(searchNumberOfRankers) : undefined,
      cityId: searchCity ? Number(searchCity) : undefined,
      isActive: searchIsActive === 'false' ? false : true,
    }
    const searchStatus =
      (searchParams.get('status') as StudioLeaderboardStatusEnum) ??
      StudioLeaderboardStatusEnum.PENDING
    const data = {
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
      ...newSearchValues,
      status: searchStatus,
    }
    setSearchValues(newSearchValues)
    setSearchTab(searchStatus)
    getPaginatedStudioLeaderboardsAction(data)
  }, [])

  const getPaginatedStudioLeaderboardsAction = (data: IPaginatedStudioLeaderboardsParams) => {
    getPaginatedStudioLeaderboards(data).then(res => {
      if (res) {
        setStudioLeaderboardsAction(
          {
            ...res,
            pageInfo: {
              ...res.pageInfo,
              currentPage: res.pageInfo.currentPage + 1,
            },
          },
          data.status,
        )
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
      status: searchTab,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedStudioLeaderboardsAction(data)
  }
  const onSearch = (value: IStudioLeaderboardFilterParams) => {
    const pageInfo = getPageInfo(searchTab)
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      status: searchTab,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedStudioLeaderboardsAction(data)
  }

  const getDataSource = (key: StudioLeaderboardStatusEnum): IStudioLeaderboard[] => {
    switch (key) {
      case StudioLeaderboardStatusEnum.APPROVED:
        return approvedStudioLeaderboards.data
      case StudioLeaderboardStatusEnum.REJECTED:
        return rejectedStudioLeaderboards.data
      case StudioLeaderboardStatusEnum.EXPIRED:
        return expiredStudioLeaderboards.data
      case StudioLeaderboardStatusEnum.PENDING:
      default:
        return pendingStudioLeaderboards.data
    }
  }

  const getPageInfo = (key: StudioLeaderboardStatusEnum): PageInfo => {
    switch (key) {
      case StudioLeaderboardStatusEnum.APPROVED:
        return approvedStudioLeaderboards.pageInfo
      case StudioLeaderboardStatusEnum.REJECTED:
        return rejectedStudioLeaderboards.pageInfo
      case StudioLeaderboardStatusEnum.EXPIRED:
        return expiredStudioLeaderboards.pageInfo
      case StudioLeaderboardStatusEnum.PENDING:
      default:
        return pendingStudioLeaderboards.pageInfo
    }
  }

  const refreshCurrentTab = () => {
    const pageInfo = getPageInfo(searchTab)
    const data = {
      size: pageInfo.pageSize,
      page: pageInfo.currentPage - 1,
      ...searchValues,
      status: searchTab,
    }
    getPaginatedStudioLeaderboardsAction(data)
  }

  const updateStatus = (id: number, status: StudioLeaderboardStatusEnum) => {
    updateStudioLeaderboardStatus(id, { status })
      .then(() => {
        refreshCurrentTab()
        notification.success({
          message: translate('studioLeaderboards.updateStudioLeaderboardStatusSuccess'),
          duration: 3,
        })
      })
      .catch(err => {
        notification.error({
          message: translate('studioLeaderboards.updateStudioLeaderboardStatusError'),
          description: err?.message || '网络请求失败!',
        })
      })
  }

  const batchApprove = () => {
    batchApproveStudioLeaderboards()
      .then(() => {
        refreshCurrentTab()
        notification.success({
          message: translate('operationSuccess'),
        })
      })
      .catch(err => {
        notification.error({
          message: translate('studioLeaderboards.batchApproveStudioLeaderboardError'),
          description: err?.message || '网络请求失败!',
        })
      })
  }

  const columns: ColumnsType<IStudioLeaderboard> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('studioLeaderboards.title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: translate('studioLeaderboards.numberOfRankers'),
      dataIndex: 'numberOfRankers',
      key: 'numberOfRankers',
    },
    {
      title: translate('studioLeaderboards.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: IStudioLeaderboardRankingType) => {
        return type.translations.find(t => t.locale === locale)?.name
      },
    },
    {
      title: translate('studioLeaderboards.city'),
      dataIndex: 'city',
      key: 'city',
      render: (city: City) => {
        return city?.translations.find(t => t.locale === locale)?.name
      },
    },
    {
      title: translate('studioLeaderboards.neighborhoods'),
      dataIndex: 'neighborhood',
      key: 'neighborhood',
      render: (neighborhood: INeightborhood, record: IStudioLeaderboard) => {
        if (record.neighborhoodId === -1) {
          return translate('studioLeaderboards.allNeighborhoods')
        }
        return neighborhood?.translations.find(t => t.locale === locale)?.name
      },
    },
    {
      title: translate('studioLeaderboards.category'),
      dataIndex: 'studioLeaderboardCategory',
      key: 'studioLeaderboardCategory',
      render: (
        studioLeaderboardCategory: IStudioLeaderboardCategory,
        record: IStudioLeaderboard,
      ) => {
        if (record.studioLeaderboardCategoryId === -1) {
          return translate('studioLeaderboards.allCategories')
        }
        return studioLeaderboardCategory?.name
      },
    },
    {
      title: translate('studioLeaderboards.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: StudioLeaderboardStatusEnum) => {
        switch (status) {
          case StudioLeaderboardStatusEnum.APPROVED:
            return <Tag color="green">{status}</Tag>
          case StudioLeaderboardStatusEnum.REJECTED:
            return <Tag color="red">{status}</Tag>
          default:
            return <Tag color="blue">{status}</Tag>
        }
      },
    },

    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IStudioLeaderboard) => (
        <Space>
          {record.status === StudioLeaderboardStatusEnum.PENDING && (
            <>
              <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
                <Button
                  type="primary"
                  block
                  onClick={() => updateStatus(record.id, StudioLeaderboardStatusEnum.APPROVED)}
                >
                  {StudioLeaderboardStatusEnum.APPROVED}
                </Button>
              </AuthPermission>
              <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
                <Button
                  danger
                  block
                  onClick={() => updateStatus(record.id, StudioLeaderboardStatusEnum.REJECTED)}
                >
                  {StudioLeaderboardStatusEnum.REJECTED}
                </Button>
              </AuthPermission>
            </>
          )}

          {record.status === StudioLeaderboardStatusEnum.APPROVED && (
            <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
              <Button
                danger
                block
                onClick={() => updateStatus(record.id, StudioLeaderboardStatusEnum.REJECTED)}
              >
                {StudioLeaderboardStatusEnum.REJECTED}
              </Button>
            </AuthPermission>
          )}

          <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
            <LinkButton to={`/studio-leaderboards/${record.id}/edit`}>
              {translate('edit')}
            </LinkButton>
          </AuthPermission>

          <LinkButton to={`/studio-leaderboards/${record.id}`}>{translate('view')}</LinkButton>
        </Space>
      ),
    },
  ]

  const onChange = (key: string) => {
    const status = key as StudioLeaderboardStatusEnum
    const pageInfo = getPageInfo(key as StudioLeaderboardStatusEnum)
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      status,
      ...searchValues,
    }
    setSearchTab(key as StudioLeaderboardStatusEnum)
    setSearchParams(getSearchParams({ status: key }))
    getPaginatedStudioLeaderboardsAction(data)
  }

  const tabsItems = StudioLeaderboardStatusOptions.map(item => {
    const dataSource = getDataSource(item.value)
    const pageInfo = getPageInfo(item.value)
    return {
      label: item.label,
      key: item.value,
      children: (
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
        />
      ),
    }
  })

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.studioLeaderboardsTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
            <Space>
              {searchTab === StudioLeaderboardStatusEnum.PENDING && (
                <Tooltip title={translate('studioLeaderboards.batchApprove.desc')}>
                  <Button
                    type="primary"
                    onClick={batchApprove}
                    disabled={pendingStudioLeaderboards.data.length === 0}
                  >
                    {translate('studioLeaderboards.batchApprove')}
                  </Button>
                </Tooltip>
              )}
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('/studio-leaderboards/new')}
              >
                {translate('studioLeaderboards.createNewStudioLeaderboard')}
              </Button>
            </Space>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <StudioLeaderboardsFilter
          onSearch={onSearch}
          formData={searchValues}
          locale={locale}
          activeCities={activeCities}
          studioLeaderboardRankingTypes={studioLeaderboardRankingTypes}
        />
      </Row>

      <Tabs activeKey={searchTab} onChange={onChange} items={tabsItems} />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  const {
    pendingStudioLeaderboards,
    approvedStudioLeaderboards,
    rejectedStudioLeaderboards,
    expiredStudioLeaderboards,
  } = state.studioLeaderboard
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
    studioLeaderboardRankingTypes: state.studioLeaderboard.studioLeaderboardRankingTypes,
    pendingStudioLeaderboards,
    approvedStudioLeaderboards,
    rejectedStudioLeaderboards,
    expiredStudioLeaderboards,
  }
}

const mapDispatchToProps = {
  setStudioLeaderboardsAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(StudioLeaderboards)
