import { INeightborhood, LocaleType, PageInfo } from '../../share-types'
import { City } from '../../store/city/reducer'
import { IStudioLeaderboardCategory } from '../studio-leaderboard-categories-container/share-type'

export interface IStudioLeaderboardTranslation {
  id: number
  locale: LocaleType
  name: string
}

export interface IStudioLeaderboardRankingType {
  id: number
  name: string
  translations: {
    locale: LocaleType
    name: string
  }[]
}

export interface IStudioLeaderboardRankerDto {
  id: number
  rank: number
  score: number
  studioId: number
  studioLeaderboardId: number
  studio: IStudioDto
}

export interface IStudioDto {
  studioTranslations: IStudioInfoTranslation[]
}

export enum StudioLeaderboardStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export interface IStudioLeaderboard {
  uuid: string
  id: number
  type: IStudioLeaderboardRankingType
  title: string
  neighborhoodId: number
  neighborhood?: INeightborhood
  city: City
  createdAt: string
  updatedAt: string
  priority: number
  studioLeaderboardCategoryId: number
  studioLeaderboardCategory: IStudioLeaderboardCategory
  translations: IStudioLeaderboardTranslation[]
  rankers: IStudioLeaderboardRankerDto[]
  status: StudioLeaderboardStatusEnum
  numberOfRankers: number
}

export interface IStudioLeaderboardFilterParams {
  cityId?: number
  neighborhoodId?: number
  studioLeaderboardCategoryId?: number
  typeId?: number
  title?: string
  numberOfRankers?: number
  isActive?: boolean
}

export interface IStudioLeaderboardFilterValues {
  area: number[]
  studioLeaderboardCategoryId?: number
  typeId?: number
  title?: string
  numberOfRankers?: number
}

export type IPaginatedStudioLeaderboardsParams = {
  page: number
  size: number
  status: StudioLeaderboardStatusEnum
} & IStudioLeaderboardFilterParams

export interface PaginatedStudioLeaderboardsResponse {
  data: IStudioLeaderboard[]
  pageInfo: PageInfo
}

export interface StudioLeaderboardRequest {
  type: string
  title: string
  neighborhoodId: number
  cityId: number
  isActive: boolean
  priority: number
  studioLeaderboardCategoryId: number
  translations: IStudioLeaderboardTranslation[]
}

export interface StudioLeaderboardFormValues {
  type: string
  title: string
  area: number[]
  createdAt: string
  updatedAt: string
  isActive: boolean
  priority: number
  studioLeaderboardCategoryId: number
  translations: IStudioLeaderboardTranslation[]
  batchMode: boolean
}

export interface IStudioInfoTranslation {
  id: number
  locale: LocaleType
  address1: string
  address2: string
  description: string
  highlights: string
  name: string
}

export interface IStudioLeaderboardRanker {
  rank: number
  score: number
  studioId: number
  translations: IStudioInfoTranslation[]
}
