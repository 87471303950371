import { notification, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ILessonCreditAdjustment, LessonCreditAdjustmentUpdateRequest } from '../share-type'
import {
  changeLessonCreditAdjustmentStatus,
  getLessonCreditAdjustmentDetail,
  updateLessonCreditAdjustmentDetail,
} from '../../../api/lessonCreditAdjustmentApi'
import LessonCreditAdjustmentDescription from '../../../components/lesson-credit-adjustments/lesson-credit-adjustment-description/lesson-credit-adjustment-description'
import { keysToCamel } from '../../../helpers/keys-to-case'
import translate from '../../../i18n'
import EditAdjustCreditsModal from '../edit-adjust-credit-modal/edit-adjust-credit-modal'

const LessonCreditAdjustment = () => {
  const params = useParams()
  const [lessonCreditAdjustmentId] = useState<number>(() => Number(params?.id))
  const [lessonCreditAdjustment, setLessonCreditAdjustment] = useState<ILessonCreditAdjustment>()
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)

  useEffect(() => {
    if (lessonCreditAdjustmentId) {
      getLessonCreditAdjustmentDetail(lessonCreditAdjustmentId).then(res =>
        setLessonCreditAdjustment(keysToCamel(res)),
      )
    }
  }, [lessonCreditAdjustmentId])

  const updateLessonCreditAdjustmentAction = (
    request: LessonCreditAdjustmentUpdateRequest,
    message?: string,
  ) => {
    updateLessonCreditAdjustmentDetail(lessonCreditAdjustmentId, request)
      .then(response => {
        if (response) {
          notification.success({
            message: message || 'Edit Lesson Credit Adjustment Success',
          })
          getLessonCreditAdjustmentDetail(lessonCreditAdjustmentId).then(res =>
            setLessonCreditAdjustment(keysToCamel(res)),
          )
        }
      })
      .catch(err => {
        notification.error({
          message: 'Edit Lesson Credit Adjustment Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  const onChangeLessonCreditAdjustmentStatus = (
    request: { isActive: boolean },
    message?: string,
  ) => {
    changeLessonCreditAdjustmentStatus(lessonCreditAdjustmentId, request)
      .then(res => {
        if (res) {
          notification.success({
            message: message || 'Change Lesson Credit Adjustment Status Success',
          })
          getLessonCreditAdjustmentDetail(lessonCreditAdjustmentId).then(res =>
            setLessonCreditAdjustment(keysToCamel(res)),
          )
        }
      })
      .catch(err => {
        notification.error({
          message: 'Change Lesson Credit Adjustment Status Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {lessonCreditAdjustment && (
          <>
            <LessonCreditAdjustmentDescription
              lessonCreditAdjustment={lessonCreditAdjustment}
              handleActivate={onChangeLessonCreditAdjustmentStatus}
              handleEdit={() => setOpenEditModal(true)}
            />
            <EditAdjustCreditsModal
              open={openEditModal}
              handleCancel={() => setOpenEditModal(false)}
              lessonCreditAdjustment={lessonCreditAdjustment}
              onFinish={(values: { adjustCredits: number }) =>
                updateLessonCreditAdjustmentAction(values, translate('edit.success'))
              }
            />
          </>
        )}
      </Space>
    </>
  )
}

export default LessonCreditAdjustment
