import { Button, Divider, Form } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { getStudiosSuggestion } from '../../../api/studiosApi'
import { getOptions } from '../../../helpers/utils'
import { getLessonDescriptionsSuggestion } from '../../../api/lessonDescriptionsApi'
import { useEffect, useState } from 'react'
import styles from './search-trend-rankers-form.module.less'
import translate from '../../../i18n'
import {
  ISearchTrend,
  SearchTrendableTypeEnums,
  SearchTrendRankerRequest,
} from '../../../pages/search-trends-container/share-type'
import DebounceSelect from '../../debounce-select/debounce-select'

interface Iprops {
  title?: string
}
const SectionTitle = (props: Iprops) => {
  return <Divider style={{ fontSize: '14px' }}>{props.title}</Divider>
}

const SearchTrendRankersForm = ({
  searchTrend,
  onConfirm,
}: {
  searchTrend: ISearchTrend
  onConfirm: (values: SearchTrendRankerRequest[]) => void
}) => {
  const [form] = Form.useForm()

  const [defaultStudioSuggestionsOptions, setDefaultStudioSuggestionsOptions] = useState<
    { label: string; value: number }[][]
  >([])
  const [
    defaultLessonDescriptionsSuggestionsOptions,
    setDefaultLessonDescriptionsSuggestionsOptions,
  ] = useState<{ label: string; value: number }[][]>([])

  useEffect(() => {
    if (searchTrend.trendableType === SearchTrendableTypeEnums.STUDIOS) {
      form.setFieldsValue({
        rankers:
          searchTrend?.ranks.map(rank => ({
            trendableId: rank?.studio.id,
          })) || [],
      })
      const studioSuggestionsOptions =
        searchTrend?.ranks.map(rank => {
          return getOptions([
            {
              id: rank?.studio.id,
              name: rank?.studio.name,
            },
          ])
        }) || []
      setDefaultStudioSuggestionsOptions(studioSuggestionsOptions)
    }

    if (searchTrend.trendableType === SearchTrendableTypeEnums.LESSON_DESCRIPTIONS) {
      form.setFieldsValue({
        rankers:
          searchTrend?.ranks.map(rank => ({
            trendableId: rank?.lessonDescription.id,
          })) || [],
      })
      const lessonDescriptionsSuggestionsOptions =
        searchTrend?.ranks.map(rank => {
          return getOptions([
            {
              id: rank?.lessonDescription.id,
              name: rank?.lessonDescription.name,
            },
          ])
        }) || []
      setDefaultLessonDescriptionsSuggestionsOptions(lessonDescriptionsSuggestionsOptions)
    }
  }, [searchTrend])

  async function fetchStudioList(keyword: string): Promise<{ label: string; value: number }[]> {
    return getStudiosSuggestion(keyword).then(res => {
      return getOptions(res.filter(t => t.cityId === searchTrend.city.id))
    })
  }

  async function fetchLessonDescriptionsSuggestionList(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getLessonDescriptionsSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }
  const onFinish = (values: { rankers: { referenceId: number }[] }) => {
    const { rankers } = values

    onConfirm(
      rankers.map((item, index) => {
        return {
          ...item,
          trendableType: searchTrend.trendableType,
          rank: index + 1,
        }
      }),
    )
  }
  const getDefaultOptions = (options: any[], index: number) => {
    if (options.length - 1 >= index) {
      return options[index]
    }
    return options[options.length - 1] ?? []
  }
  return (
    <Form
      name="search-trend-rankers-form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{
        isActive: false,
      }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['search-trend-rankers-form']}
    >
      {searchTrend.trendableType === SearchTrendableTypeEnums.STUDIOS && (
        <SectionTitle title={translate('searchTrendRankersForm.studioTips')} />
      )}

      {searchTrend.trendableType === SearchTrendableTypeEnums.LESSON_DESCRIPTIONS && (
        <SectionTitle title={translate('searchTrendRankersForm.lessonDescriptionTips')} />
      )}
      <Form.List name="rankers" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key}>
                {searchTrend.trendableType === SearchTrendableTypeEnums.STUDIOS && (
                  <Form.Item
                    {...restField}
                    label={`选择场馆${index + 1}`}
                    name={[name, 'trendableId']}
                    rules={[{ required: true, message: translate('searchTrends.studio.message') }]}
                  >
                    <DebounceSelect
                      showSearch={true}
                      allowClear
                      placeholder="选择场馆"
                      fetchOptions={fetchStudioList}
                      defaultOptions={getDefaultOptions(defaultStudioSuggestionsOptions, index)}
                    />
                  </Form.Item>
                )}

                {searchTrend.trendableType === SearchTrendableTypeEnums.LESSON_DESCRIPTIONS && (
                  <Form.Item
                    {...restField}
                    label={`选择课程${index + 1}`}
                    name={[name, 'trendableId']}
                    rules={[
                      {
                        required: true,
                        message: translate('searchTrends.lessonDescription.message'),
                      },
                    ]}
                  >
                    <DebounceSelect
                      showSearch={true}
                      allowClear
                      placeholder="选择课程"
                      fetchOptions={fetchLessonDescriptionsSuggestionList}
                      defaultOptions={getDefaultOptions(
                        defaultLessonDescriptionsSuggestionsOptions,
                        index,
                      )}
                    />
                  </Form.Item>
                )}

                {fields.length > 1 ? (
                  <Form.Item colon={false} label="  ">
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  </Form.Item>
                ) : null}
              </div>
            ))}

            {fields.length && fields.length < 20 && (
              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {translate('searchTrends.addRankders')}
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          {translate('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SearchTrendRankersForm
