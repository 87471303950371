import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'

const TagsContainer = () => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/tags\/[0-9]+\/edit$/)) {
      return translate('navBar.editTag')
    }

    if (pathName.match(/^\/tags\/new/)) {
      return translate('navBar.newTag')
    }

    if (pathName.match(/^\/tags\/[0-9]+$/)) {
      return translate('navBar.viewTag')
    }

    return translate('navBar.tagsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default TagsContainer
