import { Card, Col, Modal, Row, Typography } from 'antd'
import {
  CorporateCreditsDistributionScheduleResponse,
  IBatchUploadByCorporateIdResponse,
} from '../../../pages/corporates-container/share-type'
import styles from './phone-numbers-check-modal.module.less'
import React from 'react'

interface PhoneNumerCheckModalProps {
  visibale: boolean
  onConfirm?: () => void
  onCancel: () => void
  result: IBatchUploadByCorporateIdResponse | CorporateCreditsDistributionScheduleResponse
  title?: string
  okText?: string
  cancelText?: string
  footer?: React.ReactNode
}

const { Paragraph } = Typography

const PhoneNumbersCheckModal = (props: PhoneNumerCheckModalProps) => {
  const { visibale, result, onConfirm, onCancel, title, okText, cancelText, footer } = props

  return (
    <Modal
      title={title ?? '抱歉，你上传的文件或输入的电话号码有误，请检查后重新上传或输入。'}
      open={visibale}
      onOk={onConfirm}
      onCancel={onCancel}
      width={800}
      okText={okText ?? '确定'}
      cancelText={cancelText ?? '取消'}
      footer={footer}
    >
      <Typography.Title level={4} style={{ marginTop: 16 }}>
        具体错误如下：
      </Typography.Title>
      <Row gutter={[16, 16]}>
        {!!result.inactivePhoneNumbers?.length && (
          <Col span={8}>
            <Card className={styles['phone-number-card']} title="在当前企业中未被激活">
              {result.inactivePhoneNumbers.map((item, index) => {
                return (
                  <Paragraph copyable key={index}>
                    {item}
                  </Paragraph>
                )
              })}
            </Card>
          </Col>
        )}

        {!!result.joinedOtherCorporatePhoneNumbers?.length && (
          <Col span={8}>
            <Card className={styles['phone-number-card']} title="已成为其他企业成员">
              {result.joinedOtherCorporatePhoneNumbers.map((item, index) => {
                return (
                  <Paragraph copyable key={index}>
                    {item}
                  </Paragraph>
                )
              })}
            </Card>
          </Col>
        )}

        {!!result.existingPhoneNumbers?.length && (
          <Col span={8}>
            <Card className={styles['phone-number-card']} title="已成为本企业成员">
              {result.existingPhoneNumbers.map((item, index) => {
                return (
                  <Paragraph copyable key={index}>
                    {item}
                  </Paragraph>
                )
              })}
            </Card>
          </Col>
        )}

        {!!result.notCorporateMembers?.length && (
          <Col span={8}>
            <Card className={styles['phone-number-card']} title="不是企业成员">
              {result.notCorporateMembers.map((item, index) => {
                return (
                  <Paragraph copyable key={index}>
                    {item}
                  </Paragraph>
                )
              })}
            </Card>
          </Col>
        )}
      </Row>
    </Modal>
  )
}

export default PhoneNumbersCheckModal
