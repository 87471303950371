import {
  Button,
  Cascader,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Switch,
} from 'antd'
import styles from './studio-leaderboard-form.module.less'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  editStudioLeaderboard,
  getStudioLeaderboardDetail,
  createStudioLeaderboard,
} from '../../../api/studioLeaderboardsApi'
import { IStudioLeaderboardRankingType, StudioLeaderboardFormValues } from '../share-type'
import translate from '../../../i18n'
import { LocaleOptions } from '../../../helpers/constant'
import { INeightborhood, LocaleType } from '../../../share-types'
import { FormattedMessage } from '../../../components/intl'
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ReduxStore } from '../../../store'
import { City } from '../../../store/city/reducer'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getPaginatedStudioLeaderboardCategories } from '../../../api/studioLeaderboardCategoriesApi'
import { getOptions } from '../../../helpers/utils'
import { getNeighborhoodsByCityId } from '../../../api/authApi'
import { allCatroeyOption, allNeighborhoodOption } from '../constant'

interface Iprops {
  title?: string
}

interface OptionType {
  label: string
  value: number
}

interface Option {
  value?: string | number | null
  label: React.ReactNode
  children?: Option[]
  isLeaf?: boolean
  loading?: boolean
}

const SectionTitle = (props: Iprops) => {
  return <Divider style={{ fontSize: '14px' }}>{props.title}</Divider>
}

const { Option } = Select
const { TextArea } = Input

const StudioLeaderboardForm = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale, studioLeaderboardRankingTypes } = props

  const [form] = Form.useForm()

  const params = useParams()
  const navigation = useNavigate()
  const [studioLeaderboardId] = useState<string | undefined>(params?.id)
  const [defaultStudioCategoryOptions, setDefaultStudioCategoryOptionsOptions] = useState<
    OptionType[]
  >([])
  const [options, setOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const batchModeValue = Form.useWatch('batchMode', form)

  useEffect(() => {
    getPaginatedStudioLeaderboardCategories({}).then(res => {
      setDefaultStudioCategoryOptionsOptions(getOptions([allCatroeyOption].concat(res)))
    })
  }, [])

  useEffect(() => {
    if (!activeCities) {
      return
    }

    const areaOptions = activeCities.map((city: City) => {
      const label = city.translations.find(t => t.locale === locale)?.name
      return {
        value: city.id,
        label: `${city.id} - ${label}`,
        isLeaf: false,
      }
    })

    if (studioLeaderboardId) {
      getStudioLeaderboardDetail(Number(studioLeaderboardId)).then(data => {
        let selectedOption = areaOptions.find((option: Option) => option.value === data.city.id)
        if (data.neighborhood) {
          selectedOption = {
            ...selectedOption,
            children: [allNeighborhoodOption].concat([
              {
                value: data.neighborhood.id,
                label: `${data.neighborhood.id} - ${
                  data.neighborhood.translations.find(t => t.locale === locale)?.name
                }`,
              },
            ]),
          }
        }
        setOptions([selectedOption])

        form.setFieldsValue({
          ...data,
          area: [data.city.id, data.neighborhood?.id],
          type: data?.type.name,
        })
      })
    } else {
      setOptions(areaOptions)
    }
  }, [studioLeaderboardId, activeCities])

  const onFinish = (values: StudioLeaderboardFormValues) => {
    const { area, batchMode, ...other } = values
    const cityId = area[0] ?? 0
    const neighborhoodId = area[1]
    const data = {
      ...other,
      cityId,
      neighborhoodId,
    }

    if (studioLeaderboardId) {
      editStudioLeaderboard(Number(studioLeaderboardId), data)
        .then(res => {
          notification.success({
            message: translate('studioLeaderboards.editSuccess'),
          })
          navigation(`/studio-leaderboards/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: translate('studioLeaderboards.editError'),
            description: err?.message || translate('networkError'),
          })
        })
    } else {
      setLoading(true)
      createStudioLeaderboard(batchMode, data)
        .then(res => {
          notification.success({
            message: translate('studioLeaderboards.createSuccess'),
          })
          if (res?.id) {
            navigation(`/studio-leaderboards/${res?.id}`)
          } else {
            navigation(`/studio-leaderboards`)
          }
        })
        .catch(err => {
          notification.error({
            message: translate('studioLeaderboards.createError'),
            description: err?.message || translate('networkError'),
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  async function fetchStudioCategorySuggestions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getPaginatedStudioLeaderboardCategories({ name: keyword, isActive: true }).then(res => {
      return getOptions(res)
    })
  }

  const loadData = (selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    getNeighborhoodsByCityId({ cityId: Number(targetOption.value) }).then(res => {
      targetOption.loading = false
      const neighborhoodOptions = res.map((item: INeightborhood) => {
        const label = item.translations.find(t => t.locale === locale)?.name
        return {
          value: item.id,
          label: `${item.id} - ${label}`,
        }
      })
      targetOption.children = [allNeighborhoodOption].concat(neighborhoodOptions)
      setOptions([...options])
    })
  }

  return (
    <>
      <Form
        name="studio-leaderboard"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ batchMode: false }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        className={styles['studio-leaderboard-form']}
      >
        {!studioLeaderboardId && (
          <Form.Item
            label={translate('studioLeaderboards.batchMode')}
            name="batchMode"
            valuePropName="checked"
            tooltip={<FormattedMessage id="studioLeaderboards.batchMode.tooltip.message" />}
          >
            <Switch />
          </Form.Item>
        )}

        <Form.Item
          label={translate('studioLeaderboards.title')}
          name="title"
          rules={[
            {
              required: !batchModeValue || !!studioLeaderboardId,
              message: translate('studioLeaderboards.title.message'),
            },
          ]}
          tooltip={{
            title: translate('studioLeaderboards.title.tooltip.message'),
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input disabled={batchModeValue} />
        </Form.Item>

        <SectionTitle title={translate('studioLeaderboards.rules')} />
        <Form.Item
          label={translate('studioLeaderboards.type')}
          name="type"
          rules={[
            {
              required: !batchModeValue || !!studioLeaderboardId,
              message: translate('studioLeaderboards.type.message'),
            },
          ]}
        >
          <Select disabled={!!studioLeaderboardId} allowClear>
            {studioLeaderboardRankingTypes.map((item: IStudioLeaderboardRankingType) => {
              return (
                <Option key={item.id} value={item.name}>
                  {item.translations.find(t => t.locale === locale)?.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={translate('studioLeaderboards.category')}
          name="studioLeaderboardCategoryId"
          rules={[
            {
              required: !batchModeValue || !!studioLeaderboardId,
              message: translate('studioLeaderboards.category.message'),
            },
          ]}
        >
          <DebounceSelect
            showSearch
            placeholder={translate('studioLeaderboards.category.placeholder')}
            fetchOptions={fetchStudioCategorySuggestions}
            defaultOptions={defaultStudioCategoryOptions}
            disabled={!!studioLeaderboardId}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={translate('studioLeaderboards.area')}
          name="area"
          rules={[{ required: true, message: translate('studioLeaderboards.area.message') }]}
        >
          <Cascader
            disabled={!!studioLeaderboardId}
            options={options}
            loadData={loadData}
            changeOnSelect
          />
        </Form.Item>

        <Form.Item
          label={translate('studioLeaderboards.priority')}
          name="priority"
          rules={[{ required: true, message: translate('studioLeaderboards.priority.message') }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <SectionTitle title={translate('studioLeaderboards.translations')} />

        <Form.List name="translations" initialValue={[{ locale: 'zh-CN' }, { locale: 'en' }]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <div key={key}>
                    <Form.Item
                      {...restField}
                      label={translate('locale')}
                      name={[name, 'locale']}
                      rules={[
                        {
                          required: !batchModeValue || !!studioLeaderboardId,
                          message: translate('locale.message'),
                        },
                      ]}
                    >
                      <Select>
                        {LocaleOptions.map(item => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={translate('studioLeaderboards.translation.name')}
                      {...restField}
                      name={[name, 'name']}
                      tooltip={{
                        title: translate('studioLeaderboards.translations.tooltip.message'),
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: !batchModeValue || !!studioLeaderboardId,
                          message: translate('studioLeaderboards.translation.name.message'),
                        },
                      ]}
                    >
                      <TextArea
                        showCount
                        maxLength={100}
                        placeholder={translate('studioLeaderboards.translation.name')}
                        disabled={batchModeValue}
                      />
                    </Form.Item>

                    {fields.length > 1 ? (
                      <Form.Item colon={false} label="  ">
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      </Form.Item>
                    ) : null}
                  </div>
                )
              })}

              {fields.length < Object.keys(LocaleType).length && (
                <Form.Item colon={false} label="  ">
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    <FormattedMessage id="addNewTranslation" />
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            {translate('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
    studioLeaderboardRankingTypes: state.studioLeaderboard.studioLeaderboardRankingTypes,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioLeaderboardForm)
