import { Button, Descriptions, Space, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAdminUserInfo } from '../../../api/userApi'
import { keysToCamel } from '../../../helpers/keys-to-case'
import styles from './admin-user.module.less'
import ResetUserPasswordModal from '../../../components/admin-users/reset-user-password-modal/reset-user-password-modal'
import { IAdminUser, PermissionsEnum } from '../../../share-types'
import AuthPermission from '../../../high-order-components/auth-permission'
import { getDateTime } from '../../../helpers/utils'

interface IProps {
  user: IAdminUser
}

const UserDescriptions = ({ user }: IProps) => (
  <Descriptions
    title={user.email}
    layout="vertical"
    bordered
    column={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
  >
    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
    <Descriptions.Item label="Provider">{user.provider}</Descriptions.Item>
    <Descriptions.Item label="时区">{user.timeZone}</Descriptions.Item>
    <Descriptions.Item label="创建时间">
      {user.createdAt ? getDateTime(user.createdAt) : ''}
    </Descriptions.Item>
    <Descriptions.Item label="用户角色" span={2}>
      {user.roles.map(role => {
        return <Tag key={role.id}>{role.name}</Tag>
      })}
    </Descriptions.Item>
    <Descriptions.Item label="用户权限" span={2}>
      {user.permissions.map((per, index) => {
        return <Tag key={index}>{per.scope}</Tag>
      })}
    </Descriptions.Item>
  </Descriptions>
)

const AdminUser = () => {
  const params = useParams()
  const navigate = useNavigate()
  const userId = params?.id
  const [adminUserInfo, setAdminUserInfo] = useState<IAdminUser | undefined>()
  const [showResetUserPasswordModal, setShowResetUserPasswordModal] = useState<boolean>(false)
  useEffect(() => {
    getAdminUserInfo(Number(userId)).then(res => {
      setAdminUserInfo(keysToCamel(res))
    })
  }, [])

  const goToEdit = () => {
    navigate(`/admin-users/${userId}/edit`)
  }

  const goBack = () => {
    navigate(`/admin-users`)
  }

  const resetPassord = () => {
    setShowResetUserPasswordModal(true)
  }

  const handleCancel = () => {
    setShowResetUserPasswordModal(false)
  }

  return (
    <div className={styles['user-desc-wrapper']}>
      {adminUserInfo && <UserDescriptions user={adminUserInfo} />}
      <div className={styles['button-group']}>
        <Space align="center">
          <AuthPermission permission={PermissionsEnum.ADMIN_USERS_WRITE}>
            <Button type="primary" onClick={goToEdit}>
              编辑
            </Button>
          </AuthPermission>

          <Button onClick={goBack}>返回</Button>
          <AuthPermission permission={PermissionsEnum.ADMIN_USERS_WRITE}>
            <Button type="primary" danger onClick={resetPassord}>
              重置密码
            </Button>
          </AuthPermission>
        </Space>
      </div>

      <ResetUserPasswordModal
        visible={showResetUserPasswordModal}
        user={adminUserInfo}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default AdminUser
