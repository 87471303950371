import { ReduxStore } from '../../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getReferralCampaignDetail } from '../../../api/referralCampaignsApi'
import { IReferralCampaign } from '../share-type'
import { Outlet } from 'react-router-dom'
import ReferralCampaignDescriptions from '../../../components/referral-campaigns/referral-campaign-descriptions/referral-campaign-descriptions'

const ReferralCampaign = () => {
  const params = useParams()
  const [referralCampaignId] = useState<string | undefined>(params?.id)
  const [referralCampaign, setReferralCampaign] = useState<IReferralCampaign>()
  useEffect(() => {
    if (referralCampaignId) {
      getReferralCampaignDetail(Number(referralCampaignId)).then(res => {
        setReferralCampaign(res)
      })
    }
  }, [referralCampaignId])

  return (
    <Space className="desc-wrapper" direction="vertical" align="center" wrap size={16}>
      {referralCampaign && <ReferralCampaignDescriptions referralCampaign={referralCampaign} />}
      <Outlet />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ReferralCampaign)
