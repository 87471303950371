import { BillingInvoiceStatusEnums } from './share-type'

export const BillingInvoiceStatusOptions = [
  {
    label: '待审核',
    value: BillingInvoiceStatusEnums.PENDING,
    color: 'blue',
  },
  {
    label: '已通过',
    value: BillingInvoiceStatusEnums.APPROVED,
    color: 'green',
  },
  {
    label: '已拒绝',
    value: BillingInvoiceStatusEnums.REJECTED,
    color: 'red',
  },
  {
    label: '已撤销',
    value: BillingInvoiceStatusEnums.REVOKED,
    color: 'gray',
  },
]
