import { Button, Descriptions, Space, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'
import {
  FeatureFlagTypeOptiosEnums,
  FeatureFlagTypeRulesEnums,
  IFeatureFlag,
  featureFlagRuleTypeMenus,
  featureFlagRuleTypeOptions,
} from '../../../pages/feature-flag-container/share-type'
import { useEffect, useState } from 'react'
import styles from './feature-flag-descriptions.module.less'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'
import { getSegmentSuggestionsByIds } from '../../../api/segmentApi'
import { getOptions } from '../../../helpers/utils'
import LinkButton from '../../link-button/link-button'
import { getCorporatesSuggestionsByIds } from '../../../api/corporateApi'

interface Iprops {
  featureFlag: IFeatureFlag
}

interface FormattedData {
  id: number
  type: FeatureFlagTypeRulesEnums
  name?: string
  referenceIds: number[] | null
  createdAt: string
}

const FeatureFlagDescriptions = (props: Iprops) => {
  const { featureFlag } = props
  const [featureFlagRulesFormatted, setFeatureFlagRulesFormatted] = useState<FormattedData[]>([])
  const [segmentOptions, setSegmentOptions] = useState<{ label: string; value: number }[]>([])
  const [corporateOptions, setCorporateOptions] = useState<{ label: string; value: number }[]>([])

  useEffect(() => {
    if (featureFlag && featureFlag.rules.length) {
      const formattedData: FormattedData[] = []
      featureFlag.rules.forEach(rule => {
        const getRuleName = () => {
          if (rule.rule.type === FeatureFlagTypeRulesEnums.PUBLIC_USER) {
            return featureFlagRuleTypeMenus.find(
              menu => menu.value === FeatureFlagTypeOptiosEnums.PUBLIC_USER,
            )?.label
          }
          return featureFlagRuleTypeOptions.find(option => option.value === rule.rule.type)?.label
        }
        const getRuleValue: () => number[] | null = () => {
          switch (rule.rule.type) {
            case FeatureFlagTypeRulesEnums.SEGMENT_SPECIFIC:
              if (rule.rule.segmentIds) {
                if (rule.rule.segmentIds.length <= MAX_QS_STRINGIFY_LENGTH) {
                  asyncGetSegmentSuggestionsByIds(rule.rule.segmentIds).then(res => {
                    setSegmentOptions(getOptions(res))
                  })
                } else {
                  setSegmentOptions(
                    rule.rule.segmentIds.map(id => ({ label: id.toString(), value: id })),
                  )
                }
              }
              return rule.rule.segmentIds
            case FeatureFlagTypeRulesEnums.CORPORATE_USER_SPECIFIC:
              if (rule.rule.corporateIds) {
                if (rule.rule.corporateIds.length <= MAX_QS_STRINGIFY_LENGTH) {
                  asyncGetCorporateSuggestionsByIds(rule.rule.corporateIds).then(res => {
                    setCorporateOptions(getOptions(res))
                  })
                } else {
                  setCorporateOptions(
                    rule.rule.corporateIds.map(id => ({ label: id.toString(), value: id })),
                  )
                }
              }
              return rule.rule.corporateIds
            case FeatureFlagTypeRulesEnums.USER_SPECIFIC:
              return rule.rule.userIds
            default:
              return null
          }
        }
        formattedData.push({
          id: rule.id,
          type: rule.rule.type,
          name: getRuleName(),
          referenceIds: getRuleValue(),
          createdAt: rule.createdAt,
        })
      })
      setFeatureFlagRulesFormatted(formattedData)
    }
  }, [featureFlag])

  const columns = [
    {
      title: '规则id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '规则类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '类型名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '值（对应ids）',
      dataIndex: 'referenceIds',
      key: 'referenceIds',
      className: styles['feature-flag-rule-value'],
      width: 300,
      render: (ids: number[] | null, record: FormattedData) => {
        return renderRuleRefferenceIds(ids, record.type)
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]

  const asyncGetSegmentSuggestionsByIds = async (queryParams: number[]) => {
    const res = await getSegmentSuggestionsByIds(queryParams)
    return res
  }

  const asyncGetCorporateSuggestionsByIds = async (queryParams: number[]) => {
    const res = await getCorporatesSuggestionsByIds(queryParams)
    return res
  }

  const renderRuleRefferenceIds = (ids: number[] | null, type: FeatureFlagTypeRulesEnums) => {
    switch (type) {
      case FeatureFlagTypeRulesEnums.SEGMENT_SPECIFIC:
        return (
          <Space wrap>
            {segmentOptions.map(option => (
              <LinkButton target="_black" to={`/segments/${option.value}`} key={option.value}>
                {option.label}
              </LinkButton>
            ))}
          </Space>
        )
      case FeatureFlagTypeRulesEnums.CORPORATE_USER_SPECIFIC:
        return (
          <Space wrap>
            {corporateOptions.map(option => (
              <LinkButton target="_black" to={`/corporates/${option.value}`} key={option.value}>
                {option.label}
              </LinkButton>
            ))}
          </Space>
        )
      default:
        return <span>{ids ? ids.join(',') : '无'}</span>
    }
  }

  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/feature-flags/${featureFlag?.id}/edit`)
  }

  return (
    <Space direction="vertical" wrap size={16} style={{ width: '100%', padding: '20px' }}>
      <Descriptions
        style={{ width: 1200 }}
        title="功能开关详情"
        labelStyle={{ alignItems: 'center', fontWeight: 600 }}
        column={2}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.FEATURE_FLAGS_WRITE}>
              <Button onClick={toEdit} type="primary">
                编辑
              </Button>
            </AuthPermission>
          </Space>
        }
      >
        <Descriptions.Item label="ID">{featureFlag.id}</Descriptions.Item>
        <Descriptions.Item label="名称">{featureFlag.name}</Descriptions.Item>
        <Descriptions.Item label="活跃">
          <YesOrNoTag value={featureFlag.isActive} />
        </Descriptions.Item>
        <Descriptions.Item label="发布受众">
          {featureFlag.rules.length === 0
            ? '未设置'
            : featureFlag?.rules[0]?.rule.type === FeatureFlagTypeRulesEnums.PUBLIC_USER
              ? '全部用户'
              : '部分用户'}
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">{featureFlag.createdAt}</Descriptions.Item>
        <Descriptions.Item label="更改时间">{featureFlag.updatedAt}</Descriptions.Item>
      </Descriptions>

      <Space className="table-space" direction="vertical" wrap size={16}>
        <div>规则详情</div>
      </Space>
      <Table
        rowKey={record => record.id}
        dataSource={featureFlagRulesFormatted}
        columns={columns}
      />
    </Space>
  )
}

export default FeatureFlagDescriptions
