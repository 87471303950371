import config from '../config/index'
import {
  BillingInvoiceCreateRequest,
  BillingInvoiceStatusEnums,
  BillingInvoiceUploadPreSignedUrlResponse,
  IPaginatedBillingInvoiceFilterParams,
  PaginatedBillingInvoicesResponse,
  PresignedGetUrlResponse,
} from '../pages/billing-invoices-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getBillingInvoices = (params: IPaginatedBillingInvoiceFilterParams) =>
  authRequest.get<MISSING_TYPE, PaginatedBillingInvoicesResponse>(
    `${mainApi}/studios-area/billing-invoices`,
    { params },
  )

export const revokeBillingInvoice = (id: number, status: BillingInvoiceStatusEnums) => {
  return authRequest.put(`${mainApi}/studios-area/billing-invoices/${id}/change-status`, {
    status: status,
  })
}

export const getBillingInvoiceUploadPreSignedUrl = (filename: string) => {
  return authRequest.post<MISSING_TYPE, BillingInvoiceUploadPreSignedUrlResponse>(
    `${mainApi}/studios-area/billing-invoices/pre-signed-url?filename=${filename}`,
  )
}

export const createBillingInvoice = (data: BillingInvoiceCreateRequest) => {
  return authRequest.post(`${mainApi}/studios-area/billing-invoices`, data)
}

export const getBillingInvoiceDownloadUrl = (id: number) => {
  return authRequest.get<MISSING_TYPE, PresignedGetUrlResponse>(
    `${mainApi}/studios-area/billing-invoices/${id}/download-url`,
  )
}
