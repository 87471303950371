import {
  Button,
  Form,
  FormInstance,
  FormItemProps,
  Input,
  notification,
  Radio,
  Select,
  Space,
  Switch,
  Modal,
  Upload,
} from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import styles from './feature-flag-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { editFeatureFlag, getFeatureFlagDetail } from '../../../api/featureFlagApi'
import {
  FeatureFlagRequest,
  featureFlagRuleTypeMenus,
  FeatureFlagTypeOptiosEnums,
  featureFlagRuleTypeOptions,
  FeatureFlagTypeRulesEnums,
} from '../share-type'
import { JSX } from 'react/jsx-runtime'
import { getSegmentSuggestion, getSegmentSuggestionsByIds } from '../../../api/segmentApi'
import { getOptions } from '../../../helpers/utils'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { keysToCamel, keysToSnake } from '../../../helpers/keys-to-case'
import { getCorporatesSuggestions, getCorporatesSuggestionsByIds } from '../../../api/corporateApi'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'

const { confirm } = Modal

const FeatureFlagForm = () => {
  const [form] = Form.useForm()
  const targetAudienceTypeValue = Form.useWatch('targetAudience', form)
  const params = useParams()
  const navigation = useNavigate()
  const [featureFlagId] = useState<string | undefined>(params?.id)
  const [defaultSegmentOption, setDefaultSegmentOption] = useState<
    { label: string; value: number }[][]
  >([])

  const [defaultCorporateOption, setDefaultCorporateOption] = useState<
    { label: string; value: number }[][]
  >([])

  useEffect(() => {
    if (featureFlagId) {
      getFeatureFlagDetail(Number(featureFlagId)).then(data => {
        const dataFormated: FeatureFlagRequest = {
          name: data.name,
          isActive: data.isActive,
          rules: [],
        }
        if (
          data.rules.length === 1 &&
          data.rules[0].rule.type === FeatureFlagTypeRulesEnums.PUBLIC_USER
        ) {
          dataFormated.targetAudience = FeatureFlagTypeOptiosEnums.PUBLIC_USER
        } else if (data.rules.length) {
          dataFormated.targetAudience = FeatureFlagTypeOptiosEnums.RESTRICTED_USER
          dataFormated.rules = data.rules.map(rule => keysToCamel(rule.rule))
        }

        setSegmentSuggestionsOptions(dataFormated.rules)
        setCorporateSuggestionsOptions(dataFormated.rules)
        form.setFieldsValue(dataFormated)
      })
    }
  }, [featureFlagId])

  useEffect(() => {
    if (
      targetAudienceTypeValue === FeatureFlagTypeOptiosEnums.RESTRICTED_USER &&
      form.getFieldValue('rules').length === 0
    ) {
      form.setFieldsValue({ rules: [{}] })
    }
  }, [targetAudienceTypeValue])

  const beforeUploadUserFile = () => {
    return false
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onFinish = (values: FeatureFlagRequest) => {
    const seenTypes = new Set()
    const data = new FormData()
    data.append('name', values.name)
    data.append('isActive', String(values.isActive))

    if (values.rules) {
      for (const rule of values.rules) {
        if (seenTypes.has(rule.type)) {
          confirm({
            title: '规则类型重复',
            icon: <ExclamationCircleOutlined />,
            content: '规则类型不能重复，请重新选择',
          })
          return
        }
        seenTypes.add(rule.type)
      }

      let fileData: MISSING_TYPE[] = []

      const formatRules = values.rules.map(rule => {
        const modRule = { ...rule }
        if (rule.file) {
          fileData = rule.file
          delete modRule.file
        }

        if (rule.userIds) {
          modRule.userIds = rule.userIds.map((id: string) => Number(id))
        }

        return modRule
      })

      if (values.targetAudience === FeatureFlagTypeOptiosEnums.RESTRICTED_USER) {
        data.append('rules', JSON.stringify(keysToSnake(formatRules)))
      }
      if (fileData && fileData.length) {
        data.append('file', fileData[0].originFileObj)
      }
    }

    if (values.targetAudience === FeatureFlagTypeOptiosEnums.PUBLIC_USER) {
      data.append('rules', JSON.stringify([{ type: FeatureFlagTypeRulesEnums.PUBLIC_USER }]))
    }

    onConfirmUpdateFeatureFlag(data)
  }

  const onConfirmUpdateFeatureFlag = (data: FormData) => {
    if (featureFlagId) {
      editFeatureFlag(Number(featureFlagId), data)
        .then(res => {
          notification.success({
            message: '编辑功能开关成功',
          })
          navigation(`/feature-flags/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '编辑功能开关失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  const asyncGetSegmentSuggestionsByIds = async (queryParams: number[]) => {
    const res = await getSegmentSuggestionsByIds(queryParams)
    return res
  }

  const setSegmentSuggestionsOptions = (rules: any[]) => {
    const segmentPromises = rules.map(rule => {
      if (
        rule.type === FeatureFlagTypeRulesEnums.SEGMENT_SPECIFIC &&
        rule.segmentIds &&
        rule.segmentIds.length <= MAX_QS_STRINGIFY_LENGTH
      ) {
        return asyncGetSegmentSuggestionsByIds(rule.segmentIds)
      }
      return Promise.resolve([])
    })

    Promise.all(segmentPromises).then(res => {
      setDefaultSegmentOption(res.filter(r => r).map(r => getOptions(r)))
    })
  }

  const setCorporateSuggestionsOptions = (rules: any[]) => {
    const corporatePromises = rules.map(rule => {
      if (
        rule.type === FeatureFlagTypeRulesEnums.CORPORATE_USER_SPECIFIC &&
        rule.corporateIds &&
        rule.corporateIds.length <= MAX_QS_STRINGIFY_LENGTH
      ) {
        return getCorporatesSuggestionsByIds(rule.corporateIds)
      }
      return Promise.resolve([])
    })

    Promise.all(corporatePromises).then(res => {
      setDefaultCorporateOption(res.filter(r => r).map(r => getOptions(r)))
    })
  }

  const getDefaultOptions = (options: any[], index: number) => {
    if (options.length - 1 >= index) {
      return options[index]
    }
    return options[options.length - 1] ?? []
  }

  const renderInputBasedOnType = (
    form: FormInstance<any>,
    index: number,
    restField: JSX.IntrinsicAttributes & FormItemProps<any>,
    name: number,
  ) => {
    const type = form.getFieldValue(['rules', index, 'type'])
    switch (type) {
      case FeatureFlagTypeRulesEnums.USER_SPECIFIC:
        return (
          <Space align="start" wrap>
            <Form.Item
              name={[name, 'file']}
              style={{ width: 450, marginBottom: 0 }}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              {...restField}
              name={[name, 'userIds']}
              style={{ width: 450, marginBottom: 0 }}
              initialValue={[]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                tokenSeparators={[',']}
                placeholder="多个用户id，使用,隔开"
              />
            </Form.Item>
          </Space>
        )
      case FeatureFlagTypeRulesEnums.SEGMENT_SPECIFIC:
        return (
          <Form.Item
            {...restField}
            name={[name, 'segmentIds']}
            rules={[{ required: true, message: '分组id不能为空' }]}
            style={{ width: 240, marginBottom: 0 }}
          >
            <DebounceSelect
              showSearch={true}
              placeholder="可通过id/name搜索用户分组"
              fetchOptions={fetchSegmentSuggestionOptions}
              style={{ width: 240, marginBottom: 0 }}
              mode="multiple"
              defaultOptions={getDefaultOptions(defaultSegmentOption, index)}
            />
          </Form.Item>
        )
      case FeatureFlagTypeRulesEnums.CORPORATE_USER_SPECIFIC:
        return (
          <Form.Item
            {...restField}
            name={[name, 'corporateIds']}
            rules={[{ required: true, message: '企业id不能为空' }]}
            style={{ width: 240, marginBottom: 0 }}
          >
            <DebounceSelect
              showSearch={true}
              placeholder="可通过id/name搜索企业"
              fetchOptions={fetchCorporateSuggestionOptions}
              style={{ width: 240, marginBottom: 0 }}
              mode="multiple"
              defaultOptions={getDefaultOptions(defaultCorporateOption, index)}
            />
          </Form.Item>
        )
      default:
        return null
    }
  }

  async function fetchSegmentSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getSegmentSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  async function fetchCorporateSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getCorporatesSuggestions(keyword).then(res => {
      return getOptions(res)
    })
  }

  return (
    <Form
      name="feature-flag-form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['feature-flag-form']}
    >
      <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称！' }]}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="是否激活" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="发布受众" name="targetAudience">
        <Radio.Group options={featureFlagRuleTypeMenus}></Radio.Group>
      </Form.Item>

      {targetAudienceTypeValue === FeatureFlagTypeOptiosEnums.RESTRICTED_USER && (
        <>
          <Form.List name="rules" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Form.Item {...restField} label="分组规则" key={key}>
                    <Space align="baseline">
                      <Space style={{ width: 450 }} wrap>
                        <Form.Item
                          {...restField}
                          noStyle
                          name={[name, 'type']}
                          rules={[{ required: true }]}
                        >
                          <Select style={{ width: 200 }}>
                            {featureFlagRuleTypeOptions.map(option => (
                              <Select.Option key={option.value} value={option.value}>
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.rules[index]?.type !== curValues.rules[index]?.type
                          }
                          noStyle
                        >
                          {() => renderInputBasedOnType(form, index, restField, name)}
                        </Form.Item>
                      </Space>

                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item colon={false} label="  ">
                  <Button
                    type="dashed"
                    onClick={() => {
                      add()
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加规则
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FeatureFlagForm
