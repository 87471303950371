import {
  SET_PENDING_BOOKING_REVIEWS,
  SET_REJECTED_BOOKING_REVIEWS,
  SET_APPROVED_BOOKING_REVIEWS,
} from './constants'
import { CitiesAction } from './action'
import update from 'immutability-helper'
import { PageInfo } from '../../share-types'
import { IBookingReview } from '../../pages/booking-reviews-container/share-type'

export type IBookingReviewsWithPageInfo = {
  pageInfo: PageInfo
  data: IBookingReview[]
}

const defaultBookingReviews = {
  pageInfo: {
    pageSize: 10,
    currentPage: 1,
    count: 0,
  },
  data: [],
}

interface InitialState {
  pendingBookingReviews: IBookingReviewsWithPageInfo
  approvedBookingReviews: IBookingReviewsWithPageInfo
  rejectedBookingReviews: IBookingReviewsWithPageInfo
}

const initialState: InitialState = {
  pendingBookingReviews: defaultBookingReviews,
  approvedBookingReviews: defaultBookingReviews,
  rejectedBookingReviews: defaultBookingReviews,
}

function bookingReviewsReducer(state = initialState, action: CitiesAction) {
  switch (action.type) {
    case SET_PENDING_BOOKING_REVIEWS:
      return update(state, { $merge: { pendingBookingReviews: action.bookingReviews } })
    case SET_APPROVED_BOOKING_REVIEWS:
      return update(state, { $merge: { approvedBookingReviews: action.bookingReviews } })
    case SET_REJECTED_BOOKING_REVIEWS:
      return update(state, { $merge: { rejectedBookingReviews: action.bookingReviews } })
    default:
      return state
  }
}

export default bookingReviewsReducer
