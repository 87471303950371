import { Descriptions, List, Space, Tag } from 'antd'
import {
  IPublicPrelaunchLessonDescriptionDto,
  PrelaunchConfirmationStatusEnum,
} from '../../../pages/prelaunch-organization-forms-container/share-types'
import translate from '../../../i18n'
interface IProps {
  data: IPublicPrelaunchLessonDescriptionDto[]
  isExpired: boolean
  uniqueId: string
  organizationName: string
}

const PrelaunchLessonDescriptions = (props: IProps) => {
  const { data, isExpired, uniqueId, organizationName } = props

  const getStudioStatusTagColor = (status: string) => {
    switch (status) {
      case PrelaunchConfirmationStatusEnum.SUBMITTED:
        return 'green'
      case PrelaunchConfirmationStatusEnum.CONFIRMED:
        return 'blue'
      default:
        return 'blue'
    }
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item>
          <Descriptions column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label={translate('name')}>
              <Space>
                {item.name}
                {!isExpired && (
                  <a
                    href={`/public/prelaunch-organization-form/${uniqueId}/lesson-descriptions/${item.id}/${organizationName}/duplicate`}
                  >
                    {translate('duplicate')}
                  </a>
                )}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={translate('lessonDescription.status')}>
              <Tag color={getStudioStatusTagColor(item.status)}>{item.status}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label={translate('lessonDescription.duration')}>
              {item.duration}分钟
            </Descriptions.Item>
            <Descriptions.Item label={translate('lessonDescription.credits')}>
              {item.credits}点数
            </Descriptions.Item>
          </Descriptions>
        </List.Item>
      )}
    />
  )
}

export default PrelaunchLessonDescriptions
