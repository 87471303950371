import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'

const LessonDescriptionsContainer = () => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/lesson-descriptions\/[0-9]+$/)) {
      return translate('navBar.viewLessonDescription')
    }
    return translate('navBar.lessonDescriptionsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default LessonDescriptionsContainer
