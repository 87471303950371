import { PageInfo } from '../../share-types'
import { ITagsWithContext } from '../tags-container/share-type'

export type ILessonDescriptionFilterParams = {
  name?: string
  isRedirected?: boolean
  organizationId?: number
  isAllDay?: boolean
  isActive?: boolean
  hasExternalProviderId?: boolean
  externalProviderId?: number
}

export type IPaginatedLessonDescriptionFilterParams = {
  page?: number
  size?: number
} & ILessonDescriptionFilterParams

export interface LessonTranslation {
  locale: string
  name: string
  blurb: string | null
  description: string | null
  specialNoteToUsers: string
  highlights: string | null
  lessonPrerequisites: string | null
  equipmentPrerequisites: string | null
  checkInNotes: string | null
  otherNotes: string | null
  targetUserGroups: string
  updatedAt: string
  createdAt: string
}

export interface ILessonDescription {
  id: number
  organizationId: number
  externalProviderId: string | null
  duration: number | null
  bookBefore: number
  maxCapacity: number
  cachedActivityList: string | null
  cachedLabelList: string | null
  lateBefore: number
  startBookable: string | null
  isHidden: boolean
  isCancelled: boolean
  protip: string | null
  isRedirected: boolean | null
  isActive: boolean
  maxCapacityCeiling: number | null
  allowSignInWindowMarking: boolean | null
  credits: number
  ratingScore: number | null
  ratingVotes: number | null
  difficultyLevel: number
  isAllDay: boolean
  updatedAt: string
  createdAt: string
  lessonTranslations: LessonTranslation[]
  lessonDescriptionTranslations: LessonTranslation[]
  taggings: ITagsWithContext
  duplicateLessonDescriptions: GroupedDuplicationLessonDescriptionDto
}

export interface IPaginatedLessonDescription {
  data: ILessonDescription[]
  pageInfo: PageInfo
}

export interface DuplicationLessonDescriptionDto {
  id: number
  lessonDescriptionId: number
  duplicationLessonDescriptionDto: ILessonDescription
  status: DuplicateLessonDescriptionStatusEnums
}

export interface GroupedDuplicationLessonDescriptionDto {
  organizationId: number
  mainLessonDescription: ILessonDescription
  duplicateLessonDescriptions: DuplicationLessonDescriptionDto[]
}

export interface IDuplicateLessonDescription {
  id: number
  organizationId: number
  lessonDescriptionId: number
  duplicateLessonDescriptionId: number
  status: DuplicateLessonDescriptionStatusEnums
  createdAt: string
  updatedAt: string
}

export enum DuplicateLessonDescriptionStatusEnums {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface PaginatedDuplicateLessonDescriptionResponse {
  data: GroupedDuplicationLessonDescriptionDto[]
  pageInfo: PageInfo
}

export type IDuplicateLessonDescriptionFilterParams = {
  organizationId?: number
  name?: string
  statuses?: DuplicateLessonDescriptionStatusEnums[]
}

export type IPaginatedDuplicateLessonDescriptionFilterParams = {
  page?: number
  size?: number
} & IDuplicateLessonDescriptionFilterParams

export type CreateDuplicateLessonDescriptionRequest = {
  lessonDescriptionId: number
  duplicateLessonDescriptionIds: number[]
}

export type UpdateDuplicateLessonDescriptionRequest = {
  ids: number[]
  status: DuplicateLessonDescriptionStatusEnums
}
