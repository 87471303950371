import { Role, RoleResponse } from '../pages/admin-roles-container/share-type'

export const getFormatAdminRole = (role: RoleResponse): Role => {
  const { rolePermissions, ...other } = role
  return {
    ...other,
    permissions: rolePermissions.map(rolePermission => {
      return rolePermission.permission
    }),
  }
}
