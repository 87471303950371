import { Button, Descriptions, Space, Tag, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import { LocaleType, PermissionsEnum } from '../../../share-types'
import {
  IStudioLeaderboard,
  IStudioLeaderboardRanker,
  IStudioLeaderboardRankerDto,
  StudioLeaderboardStatusEnum,
} from '../../../pages/studio-leaderboards-container/share-type'
import { FormattedMessage } from '../../intl'
import translate from '../../../i18n'
import styles from './studio-leaderboard-descriptions.module.less'
import StudioLeaderboardsBoardRankers from '../studio-leaderboard-rankers/studio-leaderboard-rankers'
import { getDateTime } from '../../../helpers/utils'

interface Iprops {
  studioLeaderboard: IStudioLeaderboard
  locale: LocaleType
  updateStatus: (status: StudioLeaderboardStatusEnum) => void
}

const StudioLeaderboardDescriptions = (props: Iprops) => {
  const { studioLeaderboard, locale, updateStatus } = props

  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/studio-leaderboards/${studioLeaderboard?.id}/edit`)
  }

  const formatStudioRankers = (
    rankers: IStudioLeaderboardRankerDto[],
  ): IStudioLeaderboardRanker[] => {
    return rankers.map(item => {
      const { studio, ...other } = item
      return {
        ...other,
        translations: studio.studioTranslations,
      }
    })
  }

  const renderStatusTag = (status: StudioLeaderboardStatusEnum) => {
    switch (status) {
      case StudioLeaderboardStatusEnum.APPROVED:
        return <Tag color="green">{status}</Tag>
      case StudioLeaderboardStatusEnum.REJECTED:
        return <Tag color="red">{status}</Tag>
      default:
        return <Tag color="blue">{status}</Tag>
    }
  }

  return (
    <>
      <Descriptions
        style={{ width: 1200 }}
        title={translate('navBar.studioLeaderboardsDetail')}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: 200 }}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
              <Button onClick={toEdit} type="primary">
                {translate('edit')}
              </Button>
            </AuthPermission>
            {studioLeaderboard.status === StudioLeaderboardStatusEnum.PENDING && (
              <>
                <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
                  <Button
                    type="primary"
                    block
                    onClick={() => updateStatus(StudioLeaderboardStatusEnum.APPROVED)}
                  >
                    {StudioLeaderboardStatusEnum.APPROVED}
                  </Button>
                </AuthPermission>
                <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
                  <Button
                    danger
                    block
                    onClick={() => updateStatus(StudioLeaderboardStatusEnum.REJECTED)}
                  >
                    {StudioLeaderboardStatusEnum.REJECTED}
                  </Button>
                </AuthPermission>
              </>
            )}

            {studioLeaderboard.status === StudioLeaderboardStatusEnum.APPROVED && (
              <AuthPermission permission={PermissionsEnum.STUDIO_LEADERBOARDS_WRITE}>
                <Button
                  danger
                  block
                  onClick={() => updateStatus(StudioLeaderboardStatusEnum.REJECTED)}
                >
                  {StudioLeaderboardStatusEnum.REJECTED}
                </Button>
              </AuthPermission>
            )}
          </Space>
        }
      >
        <Descriptions.Item label="ID">{studioLeaderboard.id}</Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboards.title')}>
          {studioLeaderboard.title}
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboards.priority')}>
          {studioLeaderboard.priority}
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboards.status')}>
          {renderStatusTag(studioLeaderboard.status)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('createdAt')}>
          {getDateTime(studioLeaderboard.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('updatedAt')}>
          {getDateTime(studioLeaderboard.updatedAt)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title={
          <Typography.Title level={5} className={styles['section-title']}>
            <FormattedMessage id="studioLeaderboards.rules" />
          </Typography.Title>
        }
        style={{ width: 1200, marginTop: '25px' }}
        labelStyle={{ alignItems: 'center', fontWeight: 600, width: 200 }}
        column={2}
        bordered
      >
        <Descriptions.Item label={translate('studioLeaderboards.city')}>
          {studioLeaderboard.city.translations.find(t => t.locale === locale)?.name}
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboards.neighborhoods')}>
          {studioLeaderboard.neighborhoodId === -1 && (
            <FormattedMessage id="studioLeaderboards.allNeighborhoods" />
          )}
          {studioLeaderboard?.neighborhood?.translations.find(t => t.locale === locale)?.name}
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboards.type')}>
          {studioLeaderboard?.type?.translations.find(t => t.locale === locale)?.name}
        </Descriptions.Item>
        <Descriptions.Item label={translate('studioLeaderboards.category')}>
          {studioLeaderboard?.studioLeaderboardCategoryId === -1 && (
            <FormattedMessage id="studioLeaderboards.allCategories" />
          )}
          <Link
            to={`/studio-leaderboard-categories/${studioLeaderboard?.studioLeaderboardCategory?.id}`}
          >
            {studioLeaderboard?.studioLeaderboardCategory?.name}
          </Link>
        </Descriptions.Item>
      </Descriptions>

      <Typography.Title level={5} style={{ marginTop: '25px' }}>
        <FormattedMessage id="studioLeaderboards.translations" />
      </Typography.Title>
      {studioLeaderboard.translations.map(item => (
        <Descriptions
          key={item.id}
          style={{ width: 1200, marginTop: '20px' }}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: 200 }}
          column={2}
          bordered
        >
          <Descriptions.Item label={translate('locale')}>{item.locale}</Descriptions.Item>
          <Descriptions.Item label={translate('studioLeaderboards.translation.name')}>
            {item.name}
          </Descriptions.Item>
        </Descriptions>
      ))}

      <StudioLeaderboardsBoardRankers dataSource={formatStudioRankers(studioLeaderboard.rankers)} />
    </>
  )
}

export default StudioLeaderboardDescriptions
