import { Button, Form, Modal, Select, Space, notification } from 'antd'
import { TaggableTypeEnums, TaggingContextEnums } from '../../../pages/tags-container/constant'
import { useEffect, useState } from 'react'
import {
  ITag,
  ITagsWithCategory,
  ITagsWithContext,
  TaggingRequestForm,
} from '../../../pages/tags-container/share-type'
import { getTagsByContext, updateTaggings } from '../../../api/tagsApi'
import { FormattedMessage } from 'react-intl'
import styles from './update-taggings.module.less'
import { ReduxStore } from '../../../store'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

interface IProps extends ConnectedProps<typeof withConnect> {
  taggings: ITagsWithContext
  taggableType: TaggableTypeEnums
  taggableId: number
  open: boolean
  handleCancel: () => void
  handleSubmit: () => void
}

const UpdateTaggings = (props: IProps) => {
  const { taggableType, taggableId, open, handleCancel, handleSubmit, taggings, locale } = props
  const [amenitiesTags, setAmenitiesTags] = useState<ITag[]>([])
  const [labelsTags, setLabelsTags] = useState<ITag[]>([])
  const [activitiesTags, setActivitiesTags] = useState<ITag[]>([])
  const [primaryCategoryTags, setPrimaryCategoryTags] = useState<ITag[]>([])
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const [form] = Form.useForm<TaggingRequestForm>()

  useEffect(() => {
    if (taggings) {
      const initialValues = {
        activities: taggings[TaggingContextEnums.ACTIVITIES]?.map(tag => tag.id),
        labels: taggings[TaggingContextEnums.LABELS]?.map(tag => tag.id),
        amenities: taggings[TaggingContextEnums.AMENITIES]?.map(tag => tag.id),
        primaryCategory: taggings[TaggingContextEnums.PRIMARY_CATEGORY]?.[0]?.id,
      }
      form.setFieldsValue(initialValues)
    }
  }, [taggings])

  useEffect(() => {
    getTagsByContext(TaggingContextEnums.ACTIVITIES).then(res => {
      if (res) {
        setActivitiesTags(flatTagsWithContext(res))
      }
    })

    if (taggableType === TaggableTypeEnums.LESSON_DESCRIPTION) {
      getTagsByContext(TaggingContextEnums.LABELS).then(res => {
        if (res) {
          setLabelsTags(flatTagsWithContext(res))
        }
      })
    }

    if (taggableType === TaggableTypeEnums.STUDIO) {
      const promises = [
        getTagsByContext(TaggingContextEnums.AMENITIES),
        getTagsByContext(TaggingContextEnums.PRIMARY_CATEGORY),
        getTagsByContext(TaggingContextEnums.LABELS),
      ]
      Promise.all(promises).then(([amenities, primaryCategory, labels]) => {
        if (amenities) {
          setAmenitiesTags(flatTagsWithContext(amenities))
        }
        if (primaryCategory) {
          setPrimaryCategoryTags(flatTagsWithContext(primaryCategory))
        }
        if (labels) {
          setLabelsTags(flatTagsWithContext(labels))
        }
      })
    }
  }, [taggableType])

  const onFinish = (values: TaggingRequestForm) => {
    const data = {
      taggableType,
      taggableId,
      ...values,
    }
    setLoadingButton(true)
    updateTaggings(data)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Update taggings successfully',
        })
        handleSubmit()
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Update taggings failed',
        })
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const flatTagsWithContext = (res: ITagsWithCategory): ITag[] => {
    return Object.values(res).flat()
  }

  const getTagsOptions = (tags: ITag[], taggingContex: TaggingContextEnums) => {
    return tags.map(tag => {
      const categoryAndSubCategoryLabel = [tag.category, tag.subCategory]
        .filter(item => item)
        .join('/')
      const tagName = tag.tagTranslations.find(t => t.locale === locale)?.name
      if (taggingContex === TaggingContextEnums.ACTIVITIES) {
        return {
          label: `${tagName} (${categoryAndSubCategoryLabel})`,
          value: tag.id,
        }
      }

      return {
        label: `${tagName}`,
        value: tag.id,
      }
    })
  }

  return (
    <Modal
      title={'Update Taggings'}
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      width={800}
    >
      <Form
        layout="horizontal"
        name="udpate-taggings"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item name="activities" label="Activities">
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            options={getTagsOptions(activitiesTags, TaggingContextEnums.ACTIVITIES)}
          />
        </Form.Item>
        {taggableType === TaggableTypeEnums.LESSON_DESCRIPTION && (
          <>
            <Form.Item name="labels" label="Labels">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                options={getTagsOptions(labelsTags, TaggingContextEnums.LABELS)}
              />
            </Form.Item>
          </>
        )}

        {taggableType === TaggableTypeEnums.STUDIO && (
          <>
            <Form.Item name="amenities" label="Amenities">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                options={getTagsOptions(amenitiesTags, TaggingContextEnums.AMENITIES)}
              />
            </Form.Item>
            <Form.Item name="labels" label="Labels">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                options={getTagsOptions(labelsTags, TaggingContextEnums.LABELS)}
              />
            </Form.Item>
            <Form.Item name="primaryCategory" label="Primary Category">
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                options={getTagsOptions(primaryCategoryTags, TaggingContextEnums.PRIMARY_CATEGORY)}
              />
            </Form.Item>
          </>
        )}
        <Form.Item label=" " colon={false}>
          <Space className={styles['button-group']}>
            <Button loading={loadingButton} type="primary" htmlType="submit">
              <FormattedMessage id="submit" />
            </Button>
            <Button loading={loadingButton} onClick={handleCancel}>
              <FormattedMessage id="cancel" />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(UpdateTaggings)
