import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './task-reward.module.less'
import { ITaskReward } from '../share-type'
import { getTaskRewardDetail } from '../../../api/taskRewardsApi'
import TaskRewardDescriptions from '../../../components/task-rewards/task-reward-descroptions/task-reward-descroptions'

const TaskReward = () => {
  const params = useParams()
  const [taskRewardId] = useState<string | undefined>(params?.id)
  const [taskReward, setTaskReward] = useState<ITaskReward>()
  useEffect(() => {
    if (taskRewardId) {
      getTaskReward(Number(taskRewardId))
    }
  }, [taskRewardId])

  const getTaskReward = (id: number) => {
    getTaskRewardDetail(id).then(res => {
      setTaskReward(res)
    })
  }

  return (
    <Space className={styles['task-reward-desc-wrapper']} direction="vertical" wrap size={16}>
      {taskReward && <TaskRewardDescriptions taskReward={taskReward} />}
    </Space>
  )
}

export default TaskReward
