import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { Permission } from '../pages/admin-roles-container/share-type'
import React from 'react'

interface IProps extends ConnectedProps<typeof withConnect> {
  permission: string
  children?: React.ReactNode
}
const AuthPermission = (props: IProps) => {
  const { userInfo, permission, children } = props
  if (userInfo && userInfo.permissions) {
    const userPermissions = userInfo.permissions.map((item: Permission) => item.scope)
    if (userPermissions.indexOf(permission) > -1 && children) {
      return <>{children}</>
    } else {
      return null
    }
  }

  return null
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    userInfo: state.userInfo,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(AuthPermission)
