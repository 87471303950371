import { Button, Card, Modal, notification, Space, Table, Tabs, Tag, Image } from 'antd'
import styles from './billing-invoices-table.module.less'
import {
  BillingInvoiceCreateRequest,
  BillingInvoiceStatusEnums,
  IBillingInvoice,
  IPaginatedBillingInvoiceFilterParams,
  PaginatedBillingInvoicesResponse,
} from '../../../../pages/billing-invoices-container/share-type'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { PageInfo } from '../../../../share-types'
import { getDateTime } from '../../../../helpers/utils'
import {
  createBillingInvoice,
  getBillingInvoiceDownloadUrl,
  getBillingInvoices,
  revokeBillingInvoice,
} from '../../../../studios-area-api/billingInvoicesApi'
import translate from '../../../../i18n'
import { BillingInvoiceStatusOptions } from '../../../../pages/billing-invoices-container/constant'
import BillingInvoicesUploadModal from '../billing-invoices-upload-modal/billing-invoices-upload-modal'
import { FilePdfOutlined } from '@ant-design/icons'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}
const BillingInvoicesTable = () => {
  const [searchTab, setSearchTab] = useState<BillingInvoiceStatusEnums>(
    BillingInvoiceStatusEnums.PENDING,
  )
  const [billingInvoices, setBillingInvoices] = useState<IBillingInvoice[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const data = {
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
      status: searchTab,
    }
    getBillingInvoicesAction(data)
  }, [])

  const getBillingInvoicesAction = (data: IPaginatedBillingInvoiceFilterParams) => {
    getBillingInvoices(data).then((res: PaginatedBillingInvoicesResponse) => {
      if (res) {
        setBillingInvoices(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const onRevokeBillingInvoice = (id: number) => {
    Modal.confirm({
      title: '确认',
      content: `确认撤回该发票吗？`,
      onOk: () => {
        revokeBillingInvoice(id, BillingInvoiceStatusEnums.REVOKED)
          .then(() => {
            const data = {
              size: defaultPage.pageSize,
              page: defaultPage.currentPage - 1,
              status: searchTab,
            }

            getBillingInvoicesAction(data)

            notification.success({
              message: '撤回成功',
            })
          })
          .catch(err => {
            notification.error({
              message: '撤回失败',
              description: err?.message || translate('networkError'),
            })
          })
      },
    })
  }

  const onDownloadBillingInvoice = (id: number) => {
    getBillingInvoiceDownloadUrl(id)
      .then(res => {
        if (res) {
          window.open(res.preSignedUrl, '_blank')
        }
      })
      .catch(err => {
        notification.error({
          message: '下载文件失败',
          description: err?.message || translate('networkError'),
        })
      })
  }

  const baseColumns: ColumnsType<IBillingInvoice> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '发票文件',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      render: (fileUrl: string) => {
        const url = fileUrl.split('?')[0]
        if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png')) {
          return <Image src={fileUrl} width={200} />
        }
        if (url.endsWith('.pdf')) {
          return <FilePdfOutlined style={{ fontSize: 100, color: '#D42F60' }} />
        }
        return <Image src={url} width={200} />
      },
    },
    {
      title: '发票状态',
      dataIndex: 'status',
      key: 'status',
      render: (status: BillingInvoiceStatusEnums) => {
        const option = BillingInvoiceStatusOptions.find(item => item.value === status)
        return <Tag color={option?.color}>{option?.label}</Tag>
      },
    },
    {
      title: '发票金额',
      dataIndex: 'amountCents',
      key: 'amountCents',
      render: (amountCents: number) => {
        return `${amountCents / 100} 元`
      },
    },
    {
      title: '备注',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: '拒绝原因',
      dataIndex: 'rejectReason',
      key: 'rejectReason',
    },
    {
      title: '发票上传时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
  ]

  const downloadActionColumns: ColumnsType<IBillingInvoice> = [
    {
      title: '操作',
      key: 'action1',
      render: (record: IBillingInvoice) => {
        return (
          <Space>
            <Button type="link" onClick={() => onDownloadBillingInvoice(record.id)}>
              下载文件
            </Button>
          </Space>
        )
      },
    },
  ]

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      status: searchTab,
    }

    getBillingInvoicesAction(data)
  }

  const tabsItems = BillingInvoiceStatusOptions.map(item => {
    let columns: ColumnsType<IBillingInvoice> = []
    if (item.value === BillingInvoiceStatusEnums.PENDING) {
      columns = baseColumns
        .filter(column => column.key !== 'rejectReason')
        .concat([
          {
            title: '操作',
            key: 'action1',
            render: (record: IBillingInvoice) => {
              return (
                <Space>
                  <Button type="link" onClick={() => onDownloadBillingInvoice(record.id)}>
                    下载文件
                  </Button>
                  <Button type="link" danger onClick={() => onRevokeBillingInvoice(record.id)}>
                    撤回
                  </Button>
                </Space>
              )
            },
          },
        ])
    } else if (item.value === BillingInvoiceStatusEnums.APPROVED) {
      columns = baseColumns
        .filter(column => column.key !== 'rejectReason')
        .concat(downloadActionColumns)
    } else if (item.value === BillingInvoiceStatusEnums.REJECTED) {
      columns = baseColumns.concat(downloadActionColumns)
    } else if (item.value === BillingInvoiceStatusEnums.REVOKED) {
      columns = baseColumns
        .filter(column => column.key !== 'rejectReason')
        .concat(downloadActionColumns)
    }

    return {
      label: item.label,
      key: item.value,
      children: (
        <Table
          bordered
          rowKey={record => record.id}
          columns={columns}
          dataSource={billingInvoices}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
          className={styles['billing-invoices-table']}
        />
      ),
    }
  })

  const onChange = (key: string) => {
    setSearchTab(key as BillingInvoiceStatusEnums)
    const data = {
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
      status: key as BillingInvoiceStatusEnums,
    }

    getBillingInvoicesAction(data)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleSubmit = (data: BillingInvoiceCreateRequest) => {
    createBillingInvoice(data)
      .then(() => {
        const data = {
          size: defaultPage.pageSize,
          page: defaultPage.currentPage - 1,
          status: searchTab,
        }

        getBillingInvoicesAction(data)

        notification.success({
          message: '上传成功',
        })
        setOpen(false)
      })
      .catch(err => {
        notification.error({
          message: '上传失败',
          description: err?.message || translate('networkError'),
        })
      })
  }

  return (
    <Card bordered={false} className="studios-area-card">
      <div className="display-flex justify-between">
        <div className="studios-area-card-title">发票上传历史</div>
        <div>
          <Button shape="round" type="primary" onClick={() => setOpen(true)}>
            上传电子发票
          </Button>
        </div>
      </div>

      <Tabs activeKey={searchTab} onChange={onChange} items={tabsItems} />

      <BillingInvoicesUploadModal
        open={open}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </Card>
  )
}

export default BillingInvoicesTable
