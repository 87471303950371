import { useEffect, useState } from 'react'
import {
  Steps,
  Form,
  Select,
  Button,
  DatePicker,
  Checkbox,
  Switch,
  InputNumber,
  TimePicker,
  Modal,
  Tag,
  Space,
  notification,
} from 'antd'
import translate from '../../../i18n'
import {
  AdjustableTypeEnum,
  AdjustmentTypeEnum,
  LessonCreditAdjustmentCreationFormValues,
} from '../share-type'
import styles from './lesson-credit-adjustments-form.module.less'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getStudioDetail, getStudiosSuggestion } from '../../../api/studiosApi'
import { getOptions } from '../../../helpers/utils'
import { getOrganizationsSuggestion } from '../../../api/organizationsApi'
import { DAY_OF_WEEK, ISimpleOrganization } from '../../../share-types'
import LessonDescriptionTable from '../../../components/lesson-credit-adjustments/lesson-description-table/lesson-description-table'
import { convertLessonCreditAdjustmentCreationFormValuesToRequest } from '../../../helpers/lesson-credit-adjustment-helper'
import { createLessonCreditAdjustment } from '../../../api/lessonCreditAdjustmentApi'
import { useNavigate } from 'react-router-dom'

const { Option } = Select

const LessonCreditAdjustmentsForm = () => {
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(0)
  const [form] = Form.useForm<LessonCreditAdjustmentCreationFormValues>()
  const adjustableType = Form.useWatch<AdjustableTypeEnum>('adjustableType', form)
  const adjustableId = Form.useWatch<number>('adjustableId', form)

  const [organizationId, setOrganizationId] = useState<number>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectAllLessonDescriptions, setSelectAllLessonDescriptions] = useState(true)
  const [lessonDescriptionIds, setLessonDescriptionIds] = useState<number[]>([])
  const [isBillingItemPricingEnabled, setIsBillingItemPricingEnabled] = useState(false)

  useEffect(() => {
    if (adjustableId && adjustableType) {
      if (adjustableType === AdjustableTypeEnum.ORGANIZATION) {
        setOrganizationId(adjustableId)
      } else if (adjustableType === AdjustableTypeEnum.STUDIO) {
        getStudioDetail(adjustableId).then(res => {
          setOrganizationId(res.organizationId)
        })
      }
    }
  }, [adjustableId])

  const clearLessonDescriptionIds = () => {
    setLessonDescriptionIds([])
    form.setFieldValue('lessonDescriptionIds', [])
  }

  const handleSelectAllLessonDescriptions = () => {
    clearLessonDescriptionIds()
    setSelectAllLessonDescriptions(!selectAllLessonDescriptions)
  }

  const toggleBillingItemPricing = () => {
    setIsBillingItemPricingEnabled(!isBillingItemPricingEnabled)
    if (!isBillingItemPricingEnabled) {
      form.setFieldsValue({
        billingItemPricing: {
          completedClassPriceCents: 0,
          lateCancelledPriceCents: 0,
          earlyVisitPriceCents: 0,
          noShowPriceCents: 0,
        },
      })
    }
  }

  const steps = [
    {
      title: translate('lessonCreditAdjustments.selectAdjustmentType'),
      content: (
        <Form.Item
          name="adjustmentType"
          label={translate('lessonCreditAdjustments.AdjustmentType')}
          rules={[
            {
              required: true,
              message: translate('lessonCreditAdjustments.AdjustmentType.message'),
            },
          ]}
        >
          <Select placeholder={translate('lessonCreditAdjustments.AdjustmentType.message')}>
            {Object.values(AdjustmentTypeEnum).map((value, index) => (
              <Option value={value} key={index}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: translate('lessonCreditAdjustments.selectAdjustableType'),
      content:
        currentStep > 0 ? (
          <>
            <Form.Item
              name="adjustableType"
              label={translate('lessonCreditAdjustments.AdjustableType')}
              rules={[
                {
                  required: true,
                  message: translate('lessonCreditAdjustments.AdjustableType.message'),
                },
              ]}
            >
              <Select
                placeholder={translate('lessonCreditAdjustments.AdjustableType.message')}
                onChange={() => {
                  form.setFieldValue('adjustableId', undefined)
                  form.setFieldValue('lessonDescriptionIds', [])
                  fetchAdjustableItems('')
                }}
              >
                {Object.values(AdjustableTypeEnum).map(value => (
                  <Option value={value} key={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="adjustableId"
              label={translate('lessonCreditAdjustments.AdjustableId')}
              rules={[
                {
                  required: true,
                  message: translate('lessonCreditAdjustments.AdjustableId.message'),
                },
              ]}
            >
              <DebounceSelect
                style={{ minWidth: 130 }}
                showSearch={true}
                allowClear
                placeholder={translate('lessonCreditAdjustments.AdjustableId.message', {
                  adjustableType: adjustableType,
                })}
                fetchOptions={fetchAdjustableItems}
                onChange={clearLessonDescriptionIds}
              />
            </Form.Item>
          </>
        ) : undefined,
    },
    {
      title: translate('lessonCreditAdjustments.setRule'),
      content:
        currentStep > 1 ? (
          <>
            <Form.Item label={translate('isActive')} name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              name="lessonDescriptionIds"
              label={translate('lessonCreditAdjustments.lessonDescriptionIds')}
            >
              <Space direction="vertical" align="start">
                <Space wrap={true}>
                  {form.getFieldValue('lessonDescriptionIds')?.length
                    ? lessonDescriptionIds.map(id => <Tag key={id}>{id}</Tag>)
                    : translate('lessonCreditAdjustments.lessonDescriptionIds.all')}
                </Space>
                <Space>
                  <Button type="primary" onClick={() => setIsModalVisible(true)}>
                    {translate('lessonCreditAdjustments.selectLessonDescriptions')}
                  </Button>
                </Space>
              </Space>
            </Form.Item>
            <Form.Item
              name="adjustCredits"
              label={translate('lessonCreditAdjustments.adjustCredits')}
              rules={[
                {
                  required: true,
                  message: translate('lessonCreditAdjustments.adjustCredits.message'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const adjustmentType = getFieldValue('adjustmentType')
                    if (adjustmentType === AdjustmentTypeEnum.PEAK && value < 0) {
                      return Promise.reject(
                        new Error(translate('lessonCreditAdjustments.adjustCredits.minError')),
                      )
                    }
                    if (adjustmentType === AdjustmentTypeEnum.OFF_PEAK && value > 0) {
                      return Promise.reject(
                        new Error(translate('lessonCreditAdjustments.adjustCredits.maxError')),
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <InputNumber addonAfter="点" />
            </Form.Item>
            <Form.Item
              name="lessonStartTimeRange"
              label={translate('lessonCreditAdjustments.lessonStartTimeRange')}
            >
              <TimePicker.RangePicker format="HH:mm:ss" />
            </Form.Item>
            <Form.Item
              name="lessonDateRange"
              label={translate('lessonCreditAdjustments.dateRange')}
            >
              <DatePicker.RangePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item name="daysOfWeek" label={translate('lessonCreditAdjustments.dayOfWeek')}>
              <Checkbox.Group className={styles['check-box']}>
                <Checkbox value={DAY_OF_WEEK.MONDAY}>{translate('monday')}</Checkbox>
                <Checkbox value={DAY_OF_WEEK.TUESDAY}>{translate('tuesday')}</Checkbox>
                <Checkbox value={DAY_OF_WEEK.WEDNESDAY}>{translate('wednesday')}</Checkbox>
                <Checkbox value={DAY_OF_WEEK.THURSDAY}>{translate('thursday')}</Checkbox>
                <Checkbox value={DAY_OF_WEEK.FRIDAY}>{translate('friday')}</Checkbox>
                <Checkbox value={DAY_OF_WEEK.SATURDAY}>{translate('saturday')}</Checkbox>
                <Checkbox value={DAY_OF_WEEK.SUNDAY}>{translate('sunday')}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="billingItemPricingEnabled"
              label={translate('lessonCreditAdjustments.billingItemPricingEnabled')}
            >
              <Switch onChange={toggleBillingItemPricing} />
            </Form.Item>
            {isBillingItemPricingEnabled && (
              <>
                <Form.Item
                  name={['billingItemPricing', 'completedClassPriceCents']}
                  label={translate(
                    'lessonCreditAdjustments.billingItemPricing.completedClassPriceCents',
                  )}
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber addonAfter="分" />
                </Form.Item>
                <Form.Item
                  name={['billingItemPricing', 'lateCancelledPriceCents']}
                  label={translate(
                    'lessonCreditAdjustments.billingItemPricing.lateCancelledPriceCents',
                  )}
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber addonAfter="分" />
                </Form.Item>
                <Form.Item
                  name={['billingItemPricing', 'earlyVisitPriceCents']}
                  label={translate(
                    'lessonCreditAdjustments.billingItemPricing.earlyVisitPriceCents',
                  )}
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber addonAfter="分" />
                </Form.Item>
                <Form.Item
                  name={['billingItemPricing', 'noShowPriceCents']}
                  label={translate('lessonCreditAdjustments.billingItemPricing.noShowPriceCents')}
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber addonAfter="分" />
                </Form.Item>
              </>
            )}
          </>
        ) : undefined,
    },
  ]

  async function fetchAdjustableItems(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    if (adjustableType === AdjustableTypeEnum.STUDIO) {
      return getStudiosSuggestion(keyword).then(res => {
        return getOptions(res)
      })
    } else {
      return getOrganizationsSuggestion(keyword).then(res => {
        return getOptions(
          res.map((item: ISimpleOrganization) => ({
            id: item.id,
            name:
              item.organizationTranslations.find(
                translation => translation.locale === 'zh-CN' && translation.name,
              )?.name ||
              item.organizationTranslations.find(translation => translation.name)?.name ||
              '',
          })),
        )
      })
    }
  }

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1)
    }
  }

  const onFinish = (values: LessonCreditAdjustmentCreationFormValues) => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1)
    } else {
      const request = convertLessonCreditAdjustmentCreationFormValuesToRequest(values)
      createLessonCreditAdjustment(request)
        .then(response => {
          if (response) {
            notification.success({
              message: 'Create Lesson Credit Adjustment Success',
            })
            navigate(`/lesson-credit-adjustments`)
          }
        })
        .catch(err => {
          notification.error({
            message: 'Create Lesson Credit Adjustment Failed',
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  return (
    <Space
      direction="vertical"
      align="center"
      className={styles['lesson-credit-adjustments-form-container']}
    >
      <Form
        name="lesson-credit-adjustments"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        form={form}
        autoComplete="off"
        initialValues={{
          isActive: false,
          billingItemPricingEnabled: false,
          billingItemPricing: {
            completedClassPriceCents: 0,
            lateCancelledPriceCents: 0,
            earlyVisitPriceCents: 0,
            noShowPriceCents: 0,
          },
        }}
        className={styles['lesson-credit-adjustments-form']}
        onFinish={onFinish}
      >
        <Steps
          current={currentStep}
          items={steps}
          className={styles['lesson-credit-adjustments-steps']}
        ></Steps>
        {steps.map((step, index) => (
          <div key={index}>{step.content}</div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={prev} disabled={currentStep === 0}>
            {translate('previous')}
          </Button>
          <Button type="primary" htmlType="submit">
            {currentStep === steps.length - 1 ? translate('submit') : translate('next')}
          </Button>
        </div>
      </Form>

      <Modal
        title={translate('lessonCreditAdjustments.selectLessonDescriptions')}
        open={isModalVisible}
        onOk={() => {
          form.setFieldValue('lessonDescriptionIds', lessonDescriptionIds)
          setIsModalVisible(false)
        }}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <Form.Item label={translate('lessonCreditAdjustments.lessonDescriptionIds.all')}>
          <Switch
            checked={selectAllLessonDescriptions}
            onChange={handleSelectAllLessonDescriptions}
          />
        </Form.Item>
        {!selectAllLessonDescriptions && (
          <LessonDescriptionTable
            lessonDescriptionIds={lessonDescriptionIds}
            organizationId={organizationId}
            handleSelectLessonDescriptions={(lessonDescriptionIds: number[]) =>
              setLessonDescriptionIds(lessonDescriptionIds)
            }
          />
        )}
      </Modal>
    </Space>
  )
}

export default LessonCreditAdjustmentsForm
