import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import translate from '../../i18n'

const WeChatURLLinksContainer = () => {
  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={translate('navBar.weChatURLLinksTitle')}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default WeChatURLLinksContainer
