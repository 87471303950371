class ContentStrategy {
  name: string
  className: string
  constructor(name: string) {
    this.name = name
    this.className = `com.classpass.api.entities.${this.name}`
  }

  getContentStrategy() {
    return {
      '@class': this.className,
    }
  }
}

export class PersonalizedLessonDescriptionsContentStrategyConfig extends ContentStrategy {
  constructor() {
    super('PersonalizedLessonDescriptionsContentStrategyConfig')
  }
}

export class RecommendedLessonDescriptionsContentStrategyConfig extends ContentStrategy {
  _lessonDescriptionIds?: number[]
  constructor(lessonDescriptionIds?: number[]) {
    super('RecommendedLessonDescriptionsContentStrategyConfig')
    this._lessonDescriptionIds = lessonDescriptionIds
  }

  public set lessonDescriptionIds(v: number[]) {
    this._lessonDescriptionIds = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      lessonDescriptionIds: this._lessonDescriptionIds,
    }
  }
}

export class FilterableLessonDescriptionsContentStrategyConfig extends ContentStrategy {
  constructor() {
    super('FilterableLessonDescriptionsContentStrategyConfig')
  }
  getContentStrategy() {
    return {
      '@class': this.className,
    }
  }
}

export class NearbyStudiosContentStrategyConfig extends ContentStrategy {
  _max?: number
  constructor(max?: number) {
    super('NearbyStudiosContentStrategyConfig')
    this._max = max
  }

  public set max(v: number) {
    this._max = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      max: this._max,
    }
  }
}

export class RecommendedStudiosContentStrategyConfig extends ContentStrategy {
  _studioIds?: number[]
  constructor(studioIds?: number[]) {
    super('RecommendedStudiosContentStrategyConfig')
    this._studioIds = studioIds
  }

  public set studioIds(v: number[]) {
    this._studioIds = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      studioIds: this._studioIds,
    }
  }
}

export class NewStudioListContentStrategyConfig extends ContentStrategy {
  _max?: number
  constructor(max?: number) {
    super('NewStudioListContentStrategyConfig')
    this._max = max
  }

  public set max(v: number) {
    this._max = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      max: this._max,
    }
  }
}

export class RecommendedStudioListContentStrategyConfig extends ContentStrategy {
  _studioIds?: number[]
  constructor(studioIds?: number[]) {
    super('RecommendedStudioListContentStrategyConfig')
    this._studioIds = studioIds
  }

  public set studioIds(v: number[]) {
    this._studioIds = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      studioIds: this._studioIds,
    }
  }
}

export class UpcomingLessonCardContentStrategyConfig extends ContentStrategy {
  constructor() {
    super('UpcomingLessonCardContentStrategyConfig')
  }
}

export class FrequentlyBookedLessonCardContentStrategyConfig extends ContentStrategy {
  _max?: number
  constructor(max?: number) {
    super('FrequentlyBookedLessonCardContentStrategyConfig')
    this._max = max
  }

  public set max(v: number) {
    this._max = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      max: this._max,
    }
  }
}

export class CouponCardContentStrategyConfig extends ContentStrategy {
  _couponTemplateId?: number

  constructor(couponTemplateId?: number) {
    super('CouponCardContentStrategyConfig')
    this._couponTemplateId = couponTemplateId
  }

  public set couponTemplateId(v: number) {
    this._couponTemplateId = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      couponTemplateId: this._couponTemplateId,
    }
  }
}
export class FirstClassDiscountedStudiosContentStrategyConfig extends ContentStrategy {
  _max?: number
  constructor(max?: number) {
    super('FirstClassDiscountedStudiosContentStrategyConfig')
    this._max = max
  }

  public set max(v: number) {
    this._max = v
  }

  getContentStrategy() {
    return {
      '@class': this.className,
      max: this._max,
    }
  }
}
