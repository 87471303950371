import { Descriptions, Space, Tag } from 'antd'

import translate from '../../../i18n'
import { IStudioIntegrationConfig } from '../../../pages/studio-integration-config-container/share-types'
import { useNavigate } from 'react-router-dom'

const IdTag = ({
  id,
  path = 'organizations',
  color = 'blue',
}: {
  id: number
  path?: string
  color?: string
}) => {
  const navigate = useNavigate()
  return (
    <Tag
      key={id}
      color={color}
      onClick={() => navigate(`/${path}/${id}`)}
      style={{ marginBottom: '4px', cursor: 'pointer' }}
    >
      {id}
    </Tag>
  )
}

interface Iprops {
  studioIntegrationConfig: IStudioIntegrationConfig
}

const StudioIntegrationConfigDescription = (props: Iprops) => {
  const { studioIntegrationConfig } = props

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title={translate('studioIntegrationConfig.detail')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label="ID">{studioIntegrationConfig.id}</Descriptions.Item>
          <Descriptions.Item label={translate('studioIntegrationConfig.integratorType')}>
            {studioIntegrationConfig.integratorType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studioIntegrationConfig.organizationIds')} span={2}>
            {studioIntegrationConfig.config?.organization_ids.map(id => <IdTag id={id} key={id} />)}
          </Descriptions.Item>
          <Descriptions.Item label="Allow Use Check-in Code Organization IDs" span={2}>
            {studioIntegrationConfig.config?.allow_use_check_in_code_organization_ids.map(id => (
              <IdTag id={id} key={id} />
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Z&B Open Gym Lesson Description IDs" span={2}>
            {studioIntegrationConfig.config?.zb_open_gym_lesson_description_ids.map(id => (
              <IdTag id={id} key={id} path="lesson-descriptions" color="green" />
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </>
  )
}

export default StudioIntegrationConfigDescription
