import { Card, Space } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { getAdminRoles } from '../../api/roleApi'
import NavBar from '../../components/nav-bar/nav-bar'
import { keysToCamel } from '../../helpers/keys-to-case'
import { getFormatAdminRole } from '../../helpers/role-helper'
import translate from '../../i18n'
import { setRolesAction } from '../../store/global/action'
import styles from './index.module.less'

const AdminUserContainter = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getAdminRoles().then(res => {
      const roles = res.map(item => getFormatAdminRole(keysToCamel(item)))
      const { setRolesAction } = props
      setRolesAction(roles)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/admin-users\/[0-9]+\/edit$/)) {
      return translate('navBar.editAdminUser')
    }

    if (pathName.match(/^\/admin-users\/new/)) {
      return translate('navBar.createNewAdminUser')
    }

    if (pathName.match(/^\/admin-users\/[0-9]+$/)) {
      return translate('navBar.viewAdminUser')
    }

    return translate('navBar.manger')
  }

  return (
    <Space className={styles['dashboard-wrapper']} direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setRolesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(AdminUserContainter)
