import { Button, Descriptions, Empty, notification, Space } from 'antd'

import translate from '../../../i18n'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import {
  IPrelaunchCompanyPaymentDetail,
  PrelaunchConfirmationStatusEnum,
} from '../../../pages/prelaunch-organization-forms-container/share-types'
import LinkButton from '../../link-button/link-button'
import { getDateTime } from '../../../helpers/utils'
import { Typography } from 'antd'
import PrelaunchConfirmModal from '../prelaunch-confirm-modal/prelaunch-confirm-modal'
import { useState } from 'react'
import { confirmPrelaunchCompanyPaymentDetail } from '../../../api/prelaunchOrganizationFormsApi'
const { Title } = Typography

interface Iprops {
  prelaunchOrganizationId?: string
  prelaunchCompanyPaymentDetail: IPrelaunchCompanyPaymentDetail
}

const PrelaunchCompanyPaymentDetailDescriptions = ({
  prelaunchCompanyPaymentDetail,
  prelaunchOrganizationId,
}: Iprops) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleConfirmButtonClick = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleConfirmPrelaunchCompanyPaymentDetail = () => {
    if (prelaunchCompanyPaymentDetail) {
      confirmPrelaunchCompanyPaymentDetail({
        prelaunchCompanyPaymentDetailId: prelaunchCompanyPaymentDetail.id,
      })
        .then(response => {
          setOpen(false)

          if (response) {
            notification.success({
              message: 'Confirm Success',
            })
            setTimeout(() => window.location.reload(), 1000)
          }
        })

        .catch(err => {
          setOpen(false)

          notification.error({
            message: 'Confirm Failed',
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  return (
    <>
      {prelaunchCompanyPaymentDetail ? (
        <Descriptions
          title={translate('navBar.prelaunchCompanyPaymentDetail')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
          extra={
            prelaunchCompanyPaymentDetail.status == PrelaunchConfirmationStatusEnum.SUBMITTED && (
              <Space>
                <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                  <LinkButton
                    type="default"
                    to={`/prelaunch-organization-forms/${prelaunchOrganizationId}/company-payment-details/${prelaunchCompanyPaymentDetail.id}/edit`}
                  >
                    {translate('edit')}
                  </LinkButton>
                </AuthPermission>

                <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                  <Button type="primary" onClick={handleConfirmButtonClick}>
                    {translate('prelaunchOrganizationForms.confirm')}
                  </Button>
                </AuthPermission>
              </Space>
            )
          }
        >
          <Descriptions.Item label="ID">{prelaunchCompanyPaymentDetail.id}</Descriptions.Item>
          <Descriptions.Item label={translate('status')}>
            {prelaunchCompanyPaymentDetail.status}
          </Descriptions.Item>
          <Descriptions.Item label={translate('prelaunchCompanyPaymentDetail.companyName')}>
            {prelaunchCompanyPaymentDetail.companyName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('prelaunchCompanyPaymentDetail.companyAddress')}>
            {prelaunchCompanyPaymentDetail.companyAddress}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('prelaunchCompanyPaymentDetail.taxIdentificationNumber')}
          >
            {prelaunchCompanyPaymentDetail.taxIdentificationNumber}
          </Descriptions.Item>
          <Descriptions.Item label={translate('prelaunchCompanyPaymentDetail.bankName')}>
            {prelaunchCompanyPaymentDetail.bankName}
          </Descriptions.Item>
          <Descriptions.Item label={translate('prelaunchCompanyPaymentDetail.bankAccountNumber')}>
            {prelaunchCompanyPaymentDetail.bankAccountNumber}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(prelaunchCompanyPaymentDetail.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(prelaunchCompanyPaymentDetail.updatedAt)}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <>
          <Title level={5}>{translate('navBar.prelaunchCompanyPaymentDetail')}</Title>
          <Empty description="No Prelaunch Company Payment Detail Data Available" />
        </>
      )}

      <PrelaunchConfirmModal
        open={open}
        formName={`【${translate('navBar.prelaunchCompanyPaymentDetail')}${prelaunchCompanyPaymentDetail?.id}】`}
        handleCancel={handleCloseModal}
        handleConfirm={handleConfirmPrelaunchCompanyPaymentDetail}
      />
    </>
  )
}

export default PrelaunchCompanyPaymentDetailDescriptions
