import { useCallback, useEffect, useMemo, useState } from 'react'
import { Table, Space, Button, Popover, notification, Typography, Modal } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import styles from './exchange-rates-organizations.module.less'
import { ConnectedProps, connect } from 'react-redux'
import { ReduxStore } from '../../../store'
import { compose } from 'redux'
import {
  CreditsAdjustmentRequest,
  ExchangeRateAdjustmentRequestParams,
  IPaginatedOrganizationCreditsExchangeRatesParams,
  OrganizationCreditsExchangeRatesDto,
  OrganizationMetricFilter,
} from '../share-type'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import AuthPermission from '../../../high-order-components/auth-permission'
import ExchangeRatesFilter from '../../../components/exchange-rates/exchange-rates-filter/exchange-rates-filter'
import LinkButton from '../../../components/link-button/link-button'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import {
  exchangeRateAdjustment,
  getOrganiztionCreditsExchangeRates,
} from '../../../api/exchangeRatesApi'
import { InfoCircleOutlined } from '@ant-design/icons'
import ExchangeRateAdjustmentFormModal from '../../../components/exchange-rates/exchange-rate-adjustment-form-modal/exchange-rate-adjustment-form-modal'
import { CreditsAdjustmentType } from '../constant'
import translate from '../../../i18n'
import {
  getDateTime,
  getSearchParams,
  getSearchValues,
  renderNullableValue,
} from '../../../helpers/utils'
import { PAGINATION_CONFIG, paginationUtils } from '../../../helpers/pagination-helper'
import { handleApiError } from '../../../helpers/api-helper'

const { DEFAULT } = PAGINATION_CONFIG

const defaultPage = {
  pageSize: DEFAULT.PAGE_SIZE,
  currentPage: DEFAULT.CURRENT_PAGE,
  count: DEFAULT.TOTAL,
}

const { Text } = Typography

interface ModalState {
  open: boolean
  loading: boolean
  organization?: OrganizationCreditsExchangeRatesDto
}

const ExchangeRatesOrganizations = (props: ConnectedProps<typeof withConnect>) => {
  const [searchValues, setSearchValues] = useState<OrganizationMetricFilter>()
  const [organizationExchangeRates, setOrganizationExchangeRates] = useState<
    OrganizationCreditsExchangeRatesDto[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const navigate = useNavigate()
  const { activeCities, locale } = props
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    loading: false,
    organization: undefined,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'keyword', isBoolean: false },
        { key: 'activityIds', isBoolean: false },
        { key: 'cityId', isBoolean: false },
        { key: 'minEarlyVisitRate', isBoolean: false },
        { key: 'maxEarlyVisitRate', isBoolean: false },
        { key: 'minCostPerCredit', isBoolean: false },
        { key: 'maxCostPerCredit', isBoolean: false },
        { key: 'minExchangeRate', isBoolean: false },
        { key: 'maxExchangeRate', isBoolean: false },
        { key: 'minBlendedFcfPercentage', isBoolean: false },
        { key: 'maxBlendedFcfPercentage', isBoolean: false },
        { key: 'orderBy', isBoolean: false },
        { key: 'orderDirection', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getOrganiztionCreditsExchangeRatesAction(data)
  }, [])

  const getOrganiztionCreditsExchangeRatesAction = (
    data: IPaginatedOrganizationCreditsExchangeRatesParams,
  ) => {
    getOrganiztionCreditsExchangeRates(data).then(res => {
      if (res) {
        setOrganizationExchangeRates(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: paginationUtils.toFrontendPage(res.pageInfo.currentPage),
        })
      }
    })
  }

  const onSearch = (values: OrganizationMetricFilter) => {
    const data = {
      ...values,
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(data))
    getOrganiztionCreditsExchangeRatesAction(data)
  }

  const onChangeRate = (organization: OrganizationCreditsExchangeRatesDto) => {
    if (organization.isIntegrator) {
      Modal.confirm({
        title: translate('exchangeRates.integratorUpdateTitle'),
        content: translate('exchangeRates.updateContent'),
        cancelText: translate('cancel'),
        okText: translate('continue'),
        onOk: () => {
          setModalState(prev => ({
            ...prev,
            open: true,
            organization,
          }))
        },
      })
    } else {
      setModalState(prev => ({
        ...prev,
        open: true,
        organization,
      }))
    }
  }

  const navigateToStudios = (record: OrganizationCreditsExchangeRatesDto) => {
    navigate(`/exchange-rates/organizations/${record.organizationId}/studios`)
  }

  const columns: ColumnsType<OrganizationCreditsExchangeRatesDto> = useMemo(
    () => [
      {
        title: 'Organiztion ID',
        dataIndex: 'organizationId',
        key: 'organizationId',
        sorter: (a: OrganizationCreditsExchangeRatesDto, b: OrganizationCreditsExchangeRatesDto) =>
          a.organizationId - b.organizationId,
        render: (organizationId: number) => (
          <LinkButton
            to={`/organizations/${organizationId}`}
            style={{ padding: 0, textAlign: 'left' }}
          >
            {organizationId}
          </LinkButton>
        ),
      },
      {
        title: translate('cityName'),
        dataIndex: 'cityName',
        key: 'cityName',
      },
      {
        title: translate('organizationName'),
        dataIndex: 'organizationName',
        key: 'organizationName',
      },
      {
        title: translate('totalPayout'),
        dataIndex: 'totalPayoutInCents',
        key: 'totalPayoutInCents',
        render: (totalPayoutInCents: number | null) =>
          renderNullableValue(totalPayoutInCents, value => (value / 100).toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('exchangeRateTips')}>
            {translate('exchangeRate')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'exchangeRate',
        key: 'exchangeRate',
        render: (exchangeRate: number | null) =>
          renderNullableValue(exchangeRate, value => value.toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('costPerCreditTips')}>
            {translate('costPerCredit')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'costPerCredit',
        key: 'costPerCredit',
        render: (costPerCredit: number | null) =>
          renderNullableValue(costPerCredit, value => value.toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('fcfPercentageTips')}>
            {translate('fcfPercentage')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'blendedFcfPercentage',
        key: 'blendedFcfPercentage',
        render: (blendedFcfPercentage: number | null) =>
          renderNullableValue(blendedFcfPercentage, value => `${(value * 100).toFixed(2)}%`),
      },
      {
        title: (
          <Popover content={translate('earlyVisitRateTips')}>
            {translate('earlyVisitRate')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'earlyVisitRate',
        key: 'earlyVisitRate',
        render: (earlyVisitRate: number | null) =>
          renderNullableValue(earlyVisitRate, value => `${(value * 100).toFixed(2)}%`),
      },
      {
        title: translate('exchangeRates.isIntegrator'),
        dataIndex: 'isIntegrator',
        key: 'isIntegrator',
        render: (isIntegrator: boolean) => {
          return <YesOrNoTag value={isIntegrator} />
        },
      },
      {
        title: translate('activities'),
        dataIndex: 'activities',
        key: 'activities',
        render: (activities: string[]) => activities.join(', '),
      },
      {
        title: translate('updatedAt'),
        dataIndex: 'updatedTime',
        key: 'updatedTime',
        render: (updatedTime: string) => {
          return getDateTime(updatedTime)
        },
      },
      {
        title: translate('tableActionColumn'),
        key: 'action',
        render: (record: OrganizationCreditsExchangeRatesDto) => (
          <Space size="middle">
            <Button type="link" onClick={() => navigateToStudios(record)}>
              {translate('viewStudioList')}
            </Button>
            <AuthPermission permission={PermissionsEnum.EXCHANGE_RATE_WRITE}>
              <Button type="link" onClick={() => onChangeRate(record)}>
                {translate('exchangeRates.update')}
              </Button>
            </AuthPermission>
          </Space>
        ),
      },
    ],
    [translate, navigateToStudios, onChangeRate],
  )

  const paginationOnChange = useCallback(
    (page: number, pageSize: number) => {
      const data = {
        ...searchValues,
        size: paginationUtils.validatePageSize(pageSize),
        page: paginationUtils.toBackendPage(page),
      }

      setSearchParams(getSearchParams(data))
      getOrganiztionCreditsExchangeRatesAction(data)
    },
    [searchValues],
  )

  const buildAdjustmentRequest = (
    values: ExchangeRateAdjustmentRequestParams,
    organization: OrganizationCreditsExchangeRatesDto,
  ): CreditsAdjustmentRequest => {
    const baseRequest = {
      adjustableType: CreditsAdjustmentType.ORGANIZATION,
      adjustableId: organization.organizationId,
    }

    return values.rate
      ? {
          ...baseRequest,
          exchangeRateChangeFrom: organization.exchangeRate || 0,
          exchangeRateChangeTo: values.rate,
        }
      : {
          ...baseRequest,
          adjustCredits: values.adjustCredits,
        }
  }

  const onSubmit = useCallback(
    (values: ExchangeRateAdjustmentRequestParams) => {
      if (!modalState.organization) {
        return
      }
      const data = buildAdjustmentRequest(values, modalState.organization)

      setModalState(prev => ({ ...prev, loading: true }))
      exchangeRateAdjustment(data)
        .then(res => {
          if (res) {
            getOrganiztionCreditsExchangeRatesAction({
              ...searchValues,
              size: defaultPage.pageSize,
              page: defaultPage.currentPage - 1,
            })

            setModalState(prev => ({ ...prev, open: false, organization: undefined }))
            notification.success({
              message: translate('exchangeRates.updateSuccess'),
            })
          }
        })
        .catch(err => {
          handleApiError(err, translate('exchangeRates.updateFailed'), { showNotification: true })
        })
        .finally(() => {
          setModalState(prev => ({ ...prev, loading: false }))
        })
    },
    [modalState.organization, searchValues],
  )

  return (
    <>
      <Space className={styles['credit-exchange-rate-space']} direction="vertical" size={16}>
        <div className="filter-bar">
          <ExchangeRatesFilter
            onSearch={onSearch}
            formData={searchValues}
            activeCities={activeCities}
            locale={locale}
          />
        </div>
        <Table
          rowKey={record => record.organizationId.toString()}
          columns={columns}
          dataSource={organizationExchangeRates}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
        />
      </Space>
      <ExchangeRateAdjustmentFormModal
        open={modalState.open}
        loading={modalState.loading}
        title={translate('exchangeRates.update')}
        subTitle="对所有被选择的品牌进行批量修改，修后会根据现有支付价除以新汇率以推算出新的点数"
        description={
          <Space direction="vertical">
            <div>
              已选品牌: <Text type="danger">{modalState.organization?.organizationName}</Text>,
              场馆数量:
              <Text type="danger">{modalState.organization?.studioCount}</Text>, 课程数量:
              <Text type="danger">{modalState.organization?.lessonDescriptionCount}</Text>
            </div>
            <div>
              当前汇率：<Text type="danger">{modalState.organization?.exchangeRate}</Text>
            </div>
          </Space>
        }
        onCancel={() => setModalState(prev => ({ ...prev, open: false, organization: undefined }))}
        onSubmit={onSubmit}
      />
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ExchangeRatesOrganizations)
