import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { IOrganizationFilterParams } from '../../../pages/organization-container/share-type'
import { City, CityTranslation } from '../../../store/city/reducer'
import { LocaleType } from '../../../share-types'

const { Option } = Select
const OrganizationsFilter = ({
  onSearch,
  formData,
  activeCities,
  locale,
}: {
  onSearch: (value: IOrganizationFilterParams) => void
  formData: IOrganizationFilterParams | undefined
  activeCities: City[]
  locale: LocaleType
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: IOrganizationFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" style={{ gap: '16px' }} onFinish={onFinish}>
      <Form.Item name="name" label={translate('organizationTranslation.name')}>
        <Input />
      </Form.Item>

      <Form.Item
        label={translate('organization.email')}
        name="email"
        rules={[{ type: 'email', message: '输入的电子邮件无效！' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={translate('organization.cityId')} name="cityId">
        <Select style={{ minWidth: 130 }} allowClear>
          {activeCities.map(city => {
            const translation = city?.translations.find(
              (translation: CityTranslation) => translation.locale === locale,
            )
            return (
              <Option key={city.id} value={city.id}>
                {translation?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item label={translate('hasPeakOffPeak')} name="hasPeakOffPeakDiscount">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="tier" label={translate('organization.tier')}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default OrganizationsFilter
