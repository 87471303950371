import { Button, Descriptions, Space, Table, Tag } from 'antd'

import YesOrNoTag from '../../common/YesOrNoTag'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import {
  DuplicateLessonDescriptionStatusEnums,
  DuplicationLessonDescriptionDto,
  ILessonDescription,
} from '../../../pages/lesson-description-container/share-type'
import LinkButton from '../../link-button/link-button'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import { ITag } from '../../../pages/tags-container/share-type'
import { FormattedMessage } from '../../intl'
import { ColumnsType } from 'antd/lib/table'
import DuplicateLessonDescriptionStatusTag from '../duplicate-lesson-description-status-tag/duplicate-lesson-description-status-tag'
import DuplicateLessonDescriptionStatusMenu from '../duplicate-lesson-description-status-menu/duplicate-lesson-description-status-menu'

interface Iprops {
  lessonDescription: ILessonDescription
  onEditTaggings: () => void
  onReload: () => void
}
const LessonDescriptionDescriptions = (props: Iprops) => {
  const { lessonDescription, onEditTaggings, onReload } = props
  const formatLessonDescriptionName = (lessonDescription: ILessonDescription) => {
    return `${lessonDescription.id} - ${lessonDescription.lessonTranslations.map(translation => translation.name).join(' / ')}`
  }

  const columns: ColumnsType<DuplicationLessonDescriptionDto> = [
    {
      title: translate('duplicateLessonDescription.duplicateLessonDescriptions'),
      dataIndex: 'duplicationLessonDescriptionDto',
      key: 'duplicationLessonDescriptionDto',
      render: (duplicationLessonDescriptionDto: ILessonDescription) => (
        <LinkButton to={`/lesson-descriptions/${duplicationLessonDescriptionDto.id}`}>
          {formatLessonDescriptionName(duplicationLessonDescriptionDto)}
        </LinkButton>
      ),
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: DuplicateLessonDescriptionStatusEnums) => {
        return <DuplicateLessonDescriptionStatusTag status={status} />
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: DuplicationLessonDescriptionDto) => {
        return (
          <AuthPermission permission={PermissionsEnum.DUPLICATION_LESSON_DESCRIPTIONS_WRITE}>
            <DuplicateLessonDescriptionStatusMenu
              duplicateLessonDescription={record}
              action={onReload}
            />
          </AuthPermission>
        )
      },
    },
  ]

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title={translate('navBar.lessonDescriptionsTitle')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
          extra={
            <AuthPermission permission={PermissionsEnum.TAGS_WRITE}>
              <Button onClick={onEditTaggings} type="primary">
                <FormattedMessage id="editTaggings" />
              </Button>
            </AuthPermission>
          }
        >
          <Descriptions.Item label="ID">{lessonDescription?.id}</Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.name')}>
            {lessonDescription.lessonDescriptionTranslations.length
              ? lessonDescription.lessonDescriptionTranslations[0]?.name
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.organizationId')}>
            <LinkButton
              to={`/organizations/${lessonDescription?.organizationId}`}
              style={{ padding: 0, textAlign: 'left' }}
            >
              {lessonDescription?.organizationId}
            </LinkButton>
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.duration')}>
            {lessonDescription?.duration}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.isActive')}>
            <YesOrNoTag value={lessonDescription?.isActive} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.isHidden')}>
            <YesOrNoTag value={lessonDescription?.isHidden} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.isAllDay')}>
            <YesOrNoTag value={lessonDescription?.isAllDay} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.isCancelled')}>
            <YesOrNoTag value={lessonDescription?.isCancelled} />
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.protip')}>
            {lessonDescription?.protip}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.allowSignInWindowMarking')}>
            {lessonDescription?.allowSignInWindowMarking === null ? (
              'N/A'
            ) : (
              <YesOrNoTag value={lessonDescription?.allowSignInWindowMarking} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.credits')}>
            {lessonDescription?.credits}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.ratingScore')}>
            {lessonDescription?.ratingScore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.ratingVotes')}>
            {lessonDescription?.ratingVotes}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.difficultyLevel')}>
            {lessonDescription?.difficultyLevel}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.maxCapacity')}>
            {lessonDescription?.maxCapacity}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.maxCapacityCeiling')}>
            {lessonDescription?.maxCapacityCeiling}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.lateBefore')}>
            {lessonDescription?.lateBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.startBookable')}>
            {lessonDescription?.startBookable}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.bookBefore')}>
            {lessonDescription?.bookBefore}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.cachedActivityList')}>
            {lessonDescription?.cachedActivityList}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.cachedLabelList')}>
            {lessonDescription?.cachedLabelList}
          </Descriptions.Item>
          <Descriptions.Item label={translate('lessonDescription.externalProviderId')}>
            {lessonDescription?.externalProviderId}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(lessonDescription?.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(lessonDescription?.updatedAt)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={translate('taggings')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          {Object.entries(lessonDescription?.taggings).map(([key, value]) => (
            <Descriptions.Item label={key} key={key}>
              {value.map((tag: ITag, index: number) => {
                return (
                  <Tag key={index} color="blue">
                    {tag.name}
                  </Tag>
                )
              })}
            </Descriptions.Item>
          ))}
        </Descriptions>

        {lessonDescription.lessonDescriptionTranslations.map(
          (lessonDescriptionTranslation, index) => (
            <Descriptions
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={2}
              bordered
              key={index}
              title={`${translate('lessonDescription.lessonDescriptionTranslations')} - ${lessonDescriptionTranslation.locale}`}
            >
              <Descriptions.Item label={translate('lessonDescriptionTranslations.name')}>
                {lessonDescriptionTranslation.name}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescriptionTranslations.blurb')}>
                {lessonDescriptionTranslation.blurb}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescriptionTranslations.description')}>
                {lessonDescriptionTranslation.description}
              </Descriptions.Item>
              <Descriptions.Item
                label={translate('lessonDescriptionTranslations.specialNoteToUsers')}
              >
                {lessonDescriptionTranslation.specialNoteToUsers}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescriptionTranslations.highlights')}>
                {lessonDescriptionTranslation.highlights}
              </Descriptions.Item>
              <Descriptions.Item
                label={translate('lessonDescriptionTranslations.lessonPrerequisites')}
              >
                {lessonDescriptionTranslation.lessonPrerequisites}
              </Descriptions.Item>
              <Descriptions.Item
                label={translate('lessonDescriptionTranslations.equipmentPrerequisites')}
              >
                {lessonDescriptionTranslation.equipmentPrerequisites}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescriptionTranslations.checkInNotes')}>
                {lessonDescriptionTranslation.checkInNotes}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescriptionTranslations.otherNotes')}>
                {lessonDescriptionTranslation.otherNotes}
              </Descriptions.Item>
              <Descriptions.Item
                label={translate('lessonDescriptionTranslations.targetUserGroups')}
              >
                {lessonDescriptionTranslation.targetUserGroups}
              </Descriptions.Item>
              <Descriptions.Item label={translate('updatedAt')}>
                {getDateTime(lessonDescriptionTranslation.updatedAt)}
              </Descriptions.Item>
              <Descriptions.Item label={translate('createdAt')}>
                {getDateTime(lessonDescriptionTranslation.createdAt)}
              </Descriptions.Item>
            </Descriptions>
          ),
        )}

        {lessonDescription.duplicateLessonDescriptions && (
          <AuthPermission permission={PermissionsEnum.DUPLICATION_LESSON_DESCRIPTIONS_READ}>
            <Descriptions
              title={translate('duplicateLessonDescription.duplicateLessonDescriptions')}
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={1}
              bordered
            >
              <Descriptions.Item
                label={translate('duplicateLessonDescription.duplicateLessonDescriptions.main')}
              >
                <LinkButton
                  to={`/lesson-descriptions/${lessonDescription.duplicateLessonDescriptions.mainLessonDescription.id}`}
                >
                  {formatLessonDescriptionName(
                    lessonDescription.duplicateLessonDescriptions.mainLessonDescription,
                  )}
                </LinkButton>
              </Descriptions.Item>
            </Descriptions>

            <Table
              rowKey={record => record.id}
              columns={columns}
              dataSource={lessonDescription.duplicateLessonDescriptions.duplicateLessonDescriptions}
              pagination={false}
            />
          </AuthPermission>
        )}
      </Space>
    </>
  )
}

export default LessonDescriptionDescriptions
