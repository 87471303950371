export enum CorporateCreditsHistoryTypeEnum {
  DISTRIBUTE = 'DISTRIBUTE',
  EXPIRE_RECLAIM = 'EXPIRE_RECLAIM',
  ADMIN_RECLAIM = 'ADMIN_RECLAIM',
  CORPORATE_TOP_UP = 'CORPORATE_TOP_UP',
  CORPORATE_DEDUCT = 'CORPORATE_DEDUCT',
  INACTIVE_MEMBER_CANCEL_BOOKING = 'INACTIVE_MEMBER_CANCEL_BOOKING',
}

export enum CorporateMemberCreditsHistoryTypeEnums {
  DISTRIBUTE = 'DISTRIBUTE',
  DISTRIBUTE_BUFFER = 'DISTRIBUTE_BUFFER',
  EXPIRE_RECLAIM = 'EXPIRE_RECLAIM',
  ADMIN_RECLAIM = 'ADMIN_RECLAIM',
  BOOKING = 'BOOKING',
  CANCEL_BOOKING = 'CANCEL_BOOKING',
  LATE_CANCEL_PENALTY = 'LATE_CANCEL_PENALTY',
  LEGACY_DISTRIBUTE = 'LEGACY_DISTRIBUTE',
  INACTIVE_CANCEL_BOOKING = 'INACTIVE_CANCEL_BOOKING',
  INACTIVE_LATE_CANCEL_PENALTY = 'INACTIVE_LATE_CANCEL_PENALTY',
  DISTRIBUTE_TRIAL = 'DISTRIBUTE_TRIAL',
  DISTRIBUTE_TRIAL_RECLAIM = 'DISTRIBUTE_TRIAL_RECLAIM',
}

export enum CorporateMemberCreditsHistoryReferenceTypeEnums {
  CORPORATE_CREDITS_HISTORY = 'CORPORATE_CREDITS_HISTORY',
  USER_CREDITS_EXPIRATION = 'USER_CREDITS_EXPIRATION',
  BOOKING = 'BOOKING',
  CORPORATE = 'CORPORATE',
}

export const CorporateCreditsHistoryTypeEnumOptions = [
  {
    label: '管理员分发',
    value: CorporateCreditsHistoryTypeEnum.DISTRIBUTE,
  },
  {
    label: '过期回收',
    value: CorporateCreditsHistoryTypeEnum.EXPIRE_RECLAIM,
  },
  {
    label: '管理员回收',
    value: CorporateCreditsHistoryTypeEnum.ADMIN_RECLAIM,
  },
  {
    label: '点数充值',
    value: CorporateCreditsHistoryTypeEnum.CORPORATE_TOP_UP,
  },
  {
    label: '点数调整',
    value: CorporateCreditsHistoryTypeEnum.CORPORATE_DEDUCT,
  },
  {
    label: '非活跃用户取消预约',
    value: CorporateCreditsHistoryTypeEnum.INACTIVE_MEMBER_CANCEL_BOOKING,
  },
]

export const CorporateMemberCreditsHistoryTypeEnumsOptions = [
  {
    label: '管理员分发',
    value: CorporateMemberCreditsHistoryTypeEnums.DISTRIBUTE,
  },
  {
    label: '管理员分发（含buffer）',
    value: CorporateMemberCreditsHistoryTypeEnums.DISTRIBUTE_BUFFER,
  },
  {
    label: '管理员回收',
    value: CorporateMemberCreditsHistoryTypeEnums.ADMIN_RECLAIM,
  },
  {
    label: '过期回收',
    value: CorporateMemberCreditsHistoryTypeEnums.EXPIRE_RECLAIM,
  },
  {
    label: '预约',
    value: CorporateMemberCreditsHistoryTypeEnums.BOOKING,
  },
  {
    label: '取消预约',
    value: CorporateMemberCreditsHistoryTypeEnums.CANCEL_BOOKING,
  },
  {
    label: '逾期取消预约',
    value: CorporateMemberCreditsHistoryTypeEnums.LATE_CANCEL_PENALTY,
  },
  {
    label: '遗留点数',
    value: CorporateMemberCreditsHistoryTypeEnums.LEGACY_DISTRIBUTE,
  },
  {
    label: '非活跃用户取消预约',
    value: CorporateMemberCreditsHistoryTypeEnums.INACTIVE_CANCEL_BOOKING,
  },
  {
    label: '非活跃用户逾期取消预约',
    value: CorporateMemberCreditsHistoryTypeEnums.INACTIVE_LATE_CANCEL_PENALTY,
  },
  {
    label: '试用点数分发',
    value: CorporateMemberCreditsHistoryTypeEnums.DISTRIBUTE_TRIAL,
  },
  {
    label: '试用点数回收',
    value: CorporateMemberCreditsHistoryTypeEnums.DISTRIBUTE_TRIAL_RECLAIM,
  },
]

export const CorporateMemberCreditsHistoryReferenceTypeEnumsOptions = [
  {
    label: '企业点数历史',
    value: CorporateMemberCreditsHistoryReferenceTypeEnums.CORPORATE_CREDITS_HISTORY,
  },
  {
    label: '用户点数过期',
    value: CorporateMemberCreditsHistoryReferenceTypeEnums.USER_CREDITS_EXPIRATION,
  },
  {
    label: '预约',
    value: CorporateMemberCreditsHistoryReferenceTypeEnums.BOOKING,
  },
  {
    label: '企业',
    value: CorporateMemberCreditsHistoryReferenceTypeEnums.CORPORATE,
  },
]
