import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import CreateCouponSuccessModal from '../../../components/coupon-templates/create-coupon-success-modal/create-coupon-success-modal'
import CouponCodeForm from '../../../components/coupon-templates/coupon-code-form/coupon-code-form'
import { CouponFormMode, FormatCouponTemplate } from '../share-type'

const CouponTemplateForm = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [visible, setVisible] = useState<boolean>(false)
  const [couponTemplate, setCouponTemplate] = useState<FormatCouponTemplate>()

  const goBackCouponList = () => {
    navigate('/coupon-templates')
  }
  const handleOk = () => {
    handleCancel()
  }
  const handleCancel = () => {
    setVisible(false)
  }

  const onSubmitForm = (res: MISSING_TYPE) => {
    setVisible(true)
    setCouponTemplate(res)
  }

  const getFormMode = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/coupon-templates\/[0-9]+\/duplicate$/)) {
      return CouponFormMode.DUPLICATE
    } else if (pathName.match(/^\/coupon-templates\/[0-9]+\/edit$/)) {
      return CouponFormMode.EDIT
    } else {
      return CouponFormMode.CREATE
    }
  }

  return (
    <>
      <CouponCodeForm onSubmitForm={onSubmitForm} mode={getFormMode()} />
      {couponTemplate && (
        <CreateCouponSuccessModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          goBackCouponList={goBackCouponList}
          couponTemplate={couponTemplate}
        />
      )}
    </>
  )
}

export default () => <CouponTemplateForm />
