import { Button, Form, Select, Input, DatePicker } from 'antd'
import { useEffect } from 'react'
import { IReferralCampaignsFilterParams } from '../../../pages/referral-campaign-container/share-type'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import translate from '../../../i18n'
import { FormattedMessage } from '../../../components/intl'

const { Option } = Select
const ReferralCampaignsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IReferralCampaignsFilterParams) => void
  formData: IReferralCampaignsFilterParams | undefined
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        startDatetime: formData.startDatetime ? moment(formData.startDatetime) : undefined,
        endDatetime: formData.endDatetime ? moment(formData.endDatetime) : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: IReferralCampaignsFilterParams) => {
    onSearch({
      ...values,
      startDatetime: values.startDatetime ? moment(values.startDatetime).utc().format() : undefined,
      endDatetime: values.endDatetime ? moment(values.endDatetime).utc().format() : undefined,
    })
  }
  const onReset = () => {
    onSearch({})
  }
  return (
    <Form
      form={form}
      layout="inline"
      className="filter-inline-form"
      onFinish={onFinish}
      onReset={onReset}
    >
      <Form.Item name="name" label={translate('referralCampaigns.name')}>
        <Input />
      </Form.Item>

      <Form.Item name="isDefault" label={translate('referralCampaigns.isDefault')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>
            <FormattedMessage id="yes"></FormattedMessage>
          </Option>
          <Option value={false}>
            <FormattedMessage id="no"></FormattedMessage>
          </Option>
        </Select>
      </Form.Item>

      <Form.Item name="isActive" label={translate('referralCampaigns.isActive')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>
            <FormattedMessage id="yes"></FormattedMessage>
          </Option>
          <Option value={false}>
            <FormattedMessage id="no"></FormattedMessage>
          </Option>
        </Select>
      </Form.Item>

      <Form.Item name="startDatetime" label={translate('referralCampaigns.startDatetime')}>
        <DatePicker showTime />
      </Form.Item>

      <Form.Item name="endDatetime" label={translate('referralCampaigns.endDatetime')}>
        <DatePicker showTime />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ReferralCampaignsFilter
