import { FormattedMessage } from 'react-intl'
import { DuplicateLessonDescriptionStatusEnums } from '../../../pages/lesson-description-container/share-type'
import { Tag } from 'antd'

const DuplicateLessonDescriptionStatusTag = (props: {
  status: DuplicateLessonDescriptionStatusEnums
}) => {
  switch (props.status) {
    case DuplicateLessonDescriptionStatusEnums.APPROVED:
      return (
        <Tag color="green">
          <FormattedMessage id="duplicateLessonDescription.merged" />
        </Tag>
      )
    case DuplicateLessonDescriptionStatusEnums.PENDING:
      return (
        <Tag color="yellow">
          <FormattedMessage id="duplicateLessonDescription.notMerged" />
        </Tag>
      )
    case DuplicateLessonDescriptionStatusEnums.REJECTED:
      return (
        <Tag color="red">
          <FormattedMessage id="duplicateLessonDescription.rejected" />
        </Tag>
      )
  }
}

export default DuplicateLessonDescriptionStatusTag
