import { Button, Form, Input, notification, Select, Space, Typography } from 'antd'
import FormattedMessage from '../../intl/format-message'
import translate from '../../../i18n'
import {
  IWeChatURLLink,
  IWeChatURLLinkRequest,
} from '../../../pages/wechat-url-links-container/share-type'
import { createWeChatURLLink } from '../../../api/weChatURLLinksApi'
import { useState } from 'react'
import styles from './wechat-url-link-form.module.less'
import { ApplicationTypeOptions } from '../../../pages/wechat-url-links-container/constant'

const { TextArea } = Input

const { Paragraph } = Typography
const { Option } = Select

interface IWeChatURLLinkFormProps {
  handleCancel: () => void
  handleSubmit: (res: IWeChatURLLink) => void
}

const WeChatURLLinkForm = (props: IWeChatURLLinkFormProps) => {
  const { handleCancel, handleSubmit } = props
  const [form] = Form.useForm()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const onFinish = (values: IWeChatURLLinkRequest) => {
    setLoadingButton(true)
    createWeChatURLLink(values)
      .then(res => {
        if (res) {
          notification.success({
            message: translate('weChatURLLinks.createSuccess'),
            description: <Paragraph copyable>{res.urlLink}</Paragraph>,
          })
          handleSubmit(res)
        }
      })
      .catch(err => {
        notification.error({
          message: translate('weChatURLLinks.createError'),
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  return (
    <Form
      layout="horizontal"
      name="wechat-url-link-form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        name="path"
        label={translate('weChatURLLinks.path')}
        rules={[{ required: true, message: 'Please input path!' }]}
      >
        <TextArea placeholder="" showCount />
      </Form.Item>
      <Form.Item name="parameters" label={translate('weChatURLLinks.parameters')}>
        <TextArea placeholder="" showCount />
      </Form.Item>
      <Form.Item
        name="applicationType"
        label={translate('weChatURLLinks.applicationType')}
        rules={[{ required: true, message: 'Please input applicationType!' }]}
      >
        <Select style={{ minWidth: 130 }} allowClear>
          {ApplicationTypeOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label=" " colon={false}>
        <Space className={styles['button-group']}>
          <Button loading={loadingButton} type="primary" htmlType="submit">
            <FormattedMessage id="submit" />
          </Button>
          <Button loading={loadingButton} onClick={handleCancel}>
            <FormattedMessage id="cancel" />
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default WeChatURLLinkForm
