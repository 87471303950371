import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Select, Spin } from 'antd'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
  defaultOptions?: ValueType[]
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  defaultOptions,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  useEffect(() => {
    if (defaultOptions) {
      setOptions(defaultOptions)
    }
  }, [defaultOptions])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])
  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : '暂无搜索内容'}
      {...props}
      options={options}
    />
  )
}

export default DebounceSelect
