import { PageInfo } from '../../share-types'
import { ISegment } from '../segments-container/share-type'

export interface ISegmentRule {
  id: number
  name: string
  description: string
  rule: SegmentRuleClass
  segment: ISegment
  isActive: boolean
  createdAt: string
  updatedAt: string
}

export interface PaginatedSegmentRulesResponse {
  pageInfo: PageInfo
  data: ISegmentRule[]
}

export interface ISegmentRulesFilterParams {
  name?: string
  isActive?: boolean
  segmentIds?: number
}

export type IPaginatedSegmentRulesParams = {
  page: number
  size: number
} & ISegmentRulesFilterParams

export interface RuleClass {
  '@class': string
  organizationIds?: number[]
  tagIds?: number[]
  studioIds?: number[]
}

export interface SegmentRuleClass {
  '@class': string
  disabledRules?: RuleClass[] | null
  enabledRules?: RuleClass[] | null
}

export interface SegmentRuleRequest {
  name: string
  description: string
  isActive: boolean
  segmentId: number
  rule: SegmentRuleClass
}

export interface SegmentFormRule {
  matchMethod: SegmentRuleMatchMethod
  organizationIds: number[]
  studioIds: number[]
  tagIds: number[][]
}

export interface SegmentRuleFormValue {
  name: string
  description: string
  isActive: boolean
  segmentId: number
  rules?: SegmentFormRule[]
  ruleType?: SegmentRuleType
}

export enum SegmentRuleType {
  FCFRule,
  AssertRule,
}

export enum SegmentRuleMatchMethod {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}
