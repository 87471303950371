import { Button, Descriptions, notification, Space, Tag, Image } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  IPrelaunchLessonDescriptionWithFormattedTags,
  PrelaunchConfirmationStatusEnum,
} from '../share-types'
import {
  confirmPrelaunchLessonDescriptions,
  getPrelaunchLessonDescriptionDetail,
} from '../../../api/prelaunchOrganizationFormsApi'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import LinkButton from '../../../components/link-button/link-button'
import translate from '../../../i18n'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { getTagsByContext } from '../../../api/tagsApi'
import { TaggingContextEnums } from '../../tags-container/constant'
import { getFormattedTags } from '../../../helpers/prelaunch-organization-helper'
import PrelaunchConfirmModal from '../../../components/prelaunch-organization-forms/prelaunch-confirm-modal/prelaunch-confirm-modal'
import { getDateTime } from '../../../helpers/utils'

const PrelaunchLessonDescription = () => {
  const params = useParams()
  const [prelaunchLessonDescriptionId] = useState<string | undefined>(
    params?.prelaunchLessonDescriptionId,
  )
  const [prelaunchOrganizationId] = useState<string | undefined>(params?.id)
  const [prelaunchLessonDescription, setPrelaunchLessonDescription] =
    useState<IPrelaunchLessonDescriptionWithFormattedTags>()
  const [open, setOpen] = useState<boolean>(false)

  const handleConfirmButtonClick = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const formatFormName = () => {
    if (prelaunchLessonDescription) {
      return `【${translate('navBar.prelaunchLessonDescriptions')} ${prelaunchLessonDescription.lessonDescriptionTranslations.map(t => t.name).join(' / ')}】`
    } else {
      return `【${translate('navBar.prelaunchLessonDescriptions')}】`
    }
  }

  const handleConfirmPrelaunchLessonDescription = () => {
    if (prelaunchLessonDescription) {
      confirmPrelaunchLessonDescriptions({
        prelaunchLessonDescriptionIds: [prelaunchLessonDescription.id],
      })
        .then(response => {
          setOpen(false)

          if (response) {
            notification.success({
              message: 'Confirm Success',
            })
            setTimeout(() => window.location.reload(), 1000)
          }
        })

        .catch(err => {
          setOpen(false)

          notification.error({
            message: 'Confirm Failed',
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  useEffect(() => {
    if (prelaunchLessonDescriptionId) {
      getPrelaunchLessonDescriptionDetail(Number(prelaunchLessonDescriptionId)).then(data => {
        const promises = [
          getTagsByContext(TaggingContextEnums.ACTIVITIES),
          getTagsByContext(TaggingContextEnums.LABELS),
        ]
        Promise.all(promises).then(([activities, labels]) => {
          setPrelaunchLessonDescription({
            formattedActivities: getFormattedTags(activities, data.activityList),
            formattedLabels: getFormattedTags(labels, data.labelList),
            ...data,
          })
        })
      })
    }
  }, [prelaunchLessonDescriptionId])

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {prelaunchLessonDescription && (
          <>
            <Descriptions
              title={translate('navBar.viewPrelaunchLessonDescriptions')}
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={2}
              bordered
              extra={
                prelaunchLessonDescription.status == PrelaunchConfirmationStatusEnum.SUBMITTED && (
                  <Space>
                    <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                      <LinkButton
                        type="default"
                        to={`/prelaunch-organization-forms/${prelaunchOrganizationId}/lesson-descriptions/${prelaunchLessonDescriptionId}/edit`}
                      >
                        {translate('edit')}
                      </LinkButton>
                    </AuthPermission>

                    <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
                      <Button type="primary" onClick={handleConfirmButtonClick}>
                        {translate('prelaunchOrganizationForms.confirm')}
                      </Button>
                    </AuthPermission>
                  </Space>
                )
              }
            >
              <Descriptions.Item label="ID">{prelaunchLessonDescription.id}</Descriptions.Item>
              <Descriptions.Item label="Lesson Description ID">
                <LinkButton
                  to={`/lesson-descriptions/${prelaunchLessonDescription.lessonDescriptionId}`}
                >
                  {prelaunchLessonDescription.lessonDescriptionId}
                </LinkButton>
              </Descriptions.Item>

              <Descriptions.Item label={translate('status')}>
                {prelaunchLessonDescription.status}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescriptionTranslations.name')} span={2}>
                {prelaunchLessonDescription.lessonDescriptionTranslations
                  .map(t => t.name)
                  .join(' / ')}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.duration')}>
                {prelaunchLessonDescription.duration}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.credits')}>
                {prelaunchLessonDescription.credits}
              </Descriptions.Item>
              <Descriptions.Item label={translate('activityList')}>
                {prelaunchLessonDescription.formattedActivities
                  ? prelaunchLessonDescription.formattedActivities.map(label => <Tag>{label}</Tag>)
                  : prelaunchLessonDescription.activityList}
              </Descriptions.Item>
              <Descriptions.Item label={translate('studio.labels')}>
                {prelaunchLessonDescription.formattedLabels
                  ? prelaunchLessonDescription.formattedLabels.map(label => <Tag>{label}</Tag>)
                  : prelaunchLessonDescription.labelList}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.startBookable')}>
                {prelaunchLessonDescription.startBookable}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.bookBefore')}>
                {prelaunchLessonDescription.bookBefore}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.lateBefore')}>
                {prelaunchLessonDescription.lateBefore}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.maxCapacity')}>
                {prelaunchLessonDescription.maxCapacity}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.difficultLevel')}>
                {prelaunchLessonDescription.difficultLevel}
              </Descriptions.Item>
              <Descriptions.Item label={translate('lessonDescription.isAllDay')}>
                <YesOrNoTag value={prelaunchLessonDescription.isAllDay} />
              </Descriptions.Item>

              <Descriptions.Item label={translate('createdAt')}>
                {getDateTime(prelaunchLessonDescription.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label={translate('updatedAt')}>
                {getDateTime(prelaunchLessonDescription.updatedAt)}
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              title="Photos"
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={1}
              bordered
            >
              <Descriptions.Item label="Photos">
                <Image.PreviewGroup>
                  {prelaunchLessonDescription.photos.map((photo, index) => (
                    <Image key={index} width={200} src={photo} />
                  ))}
                </Image.PreviewGroup>
              </Descriptions.Item>
            </Descriptions>

            {prelaunchLessonDescription.lessonDescriptionTranslations.map((translation, index) => (
              <Descriptions
                key={index}
                title={
                  translate('lessonDescription.lessonDescriptionTranslations') +
                  ' - ' +
                  translation.locale
                }
                labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
                contentStyle={{ minWidth: 300 }}
                column={2}
                bordered
              >
                <Descriptions.Item label={translate('locale')}>
                  {translation.locale}
                </Descriptions.Item>
                <Descriptions.Item label={translate('lessonDescriptionTranslations.name')}>
                  {translation.name}
                </Descriptions.Item>
                <Descriptions.Item label={translate('funFact')}>
                  {translation.funFact}
                </Descriptions.Item>
                <Descriptions.Item label={translate('lessonDescriptionTranslations.highlights')}>
                  {translation.highlights}
                </Descriptions.Item>
                <Descriptions.Item label={translate('lessonDescriptionTranslations.description')}>
                  {translation.description}
                </Descriptions.Item>
                <Descriptions.Item
                  label={translate('lessonDescriptionTranslations.specialNoteToUsers')}
                >
                  {translation.specialNoteToUsers}
                </Descriptions.Item>
                <Descriptions.Item
                  label={translate('lessonDescriptionTranslations.lessonPrerequisites')}
                >
                  {translation.lessonPrerequisites}
                </Descriptions.Item>
                <Descriptions.Item label={translate('lessonDescriptionTranslations.checkInNotes')}>
                  {translation.checkInNotes}
                </Descriptions.Item>
                <Descriptions.Item label={translate('lessonDescriptionTranslations.otherNotes')}>
                  {translation.otherNotes}
                </Descriptions.Item>
                <Descriptions.Item
                  label={translate('lessonDescriptionTranslations.targetUserGroups')}
                >
                  {translation.targetUserGroups}
                </Descriptions.Item>
                <Descriptions.Item label={translate('createdAt')}>
                  {getDateTime(translation.createdAt)}
                </Descriptions.Item>
                <Descriptions.Item label={translate('updatedAt')}>
                  {getDateTime(translation.updatedAt)}
                </Descriptions.Item>
              </Descriptions>
            ))}
          </>
        )}
      </Space>

      <PrelaunchConfirmModal
        open={open}
        formName={formatFormName()}
        handleCancel={handleCloseModal}
        handleConfirm={handleConfirmPrelaunchLessonDescription}
      />
    </>
  )
}

export default PrelaunchLessonDescription
