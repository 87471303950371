import { useEffect, useState } from 'react'
import { Table, Space, Row, Col, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import { ColumnsType } from 'antd/lib/table'
import AuthPermission from '../../../high-order-components/auth-permission'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { setBookingReviewsAction } from '../../../store/booking-review/action'
import CardStacksFilter from '../../../components/card-stacks/card-stacks-filter/card-stacks-filter'
import {
  CardStackPageTypeEnum,
  ICardStack,
  ICardStacksFilterParams,
  IPaginatedCardStacksParams,
  TargetUserGroupEnum,
} from '../share-type'
import { getPaginatedCardStack } from '../../../api/cardStackApi'
import { pageTypeOptions, targetUserGroupsOptions } from '../constant'
import translate from '../../../i18n'
import styles from './card-stacks.module.less'
import { getCityLabel } from '../../../helpers/coupon-template-helper'
import LinkButton from '../../../components/link-button/link-button'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const CardStacks = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale } = props
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValues, setSearchValues] = useState<ICardStacksFilterParams>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [cardStacks, setCardStacks] = useState<ICardStack[]>([])

  useEffect(() => {
    const searchName = searchParams.get('name')
    const searchRank = searchParams.get('rank')
    const searchIsActive = searchParams.get('isActive')
    const searchCityId = searchParams.get('cityId')
    const searchTargetGroup = searchParams.get('targetGroup')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const searchPageType = searchParams.get('pageType')
    const newSearchValues = {
      name: searchName ?? undefined,
      rank: searchRank ? Number(searchRank) : undefined,
      isActive: searchIsActive ? (searchIsActive === 'true' ? true : false) : undefined,
      cityId: searchCityId ? Number(searchCityId) : undefined,
      targetGroup: searchTargetGroup ?? undefined,
      pageType: searchPageType ? Number(searchPageType) : undefined,
    }
    const data = {
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
      ...newSearchValues,
    }

    setSearchValues(newSearchValues)
    getPaginatedCardStacksAction(data)
  }, [])

  const getPaginatedCardStacksAction = (data: IPaginatedCardStacksParams) => {
    getPaginatedCardStack(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setCardStacks(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
    }

    setSearchParams(getSearchParams(data))
    getPaginatedCardStacksAction(data)
  }

  const onSearch = (value: ICardStacksFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedCardStacksAction(data)
  }

  const columns: ColumnsType<ICardStack> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '排名',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a: ICardStack, b: ICardStack) => a.rank - b.rank,
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: number) => <YesOrNoTag value={isActive} />,
    },
    {
      title: '目标用户',
      dataIndex: 'targetGroup',
      key: 'targetGroup',
      render: (targetGroup: TargetUserGroupEnum) => {
        return targetUserGroupsOptions.find(item => item.value === targetGroup)?.label
      },
    },
    {
      title: '城市',
      dataIndex: 'cityIds',
      key: 'cityIds',
      render: (cityIds: string) => {
        return <span>{getCityLabel(cityIds, activeCities, locale)}</span>
      },
    },
    {
      title: '页面种类',
      dataIndex: 'pageType',
      key: 'pageType',
      render: (pageType: CardStackPageTypeEnum) => {
        return pageTypeOptions.find(item => item.value === pageType)?.label
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.CARD_STACKS_WRITE}>
            <LinkButton to={`/card-stacks/${record.id}/edit`}>编辑</LinkButton>
          </AuthPermission>

          <LinkButton to={`/card-stacks/${record.id}`}>查看</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>卡片堆</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.CARD_STACKS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/card-stacks/new')}
            >
              创建新的卡片堆
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className={styles['filter-bar']}>
        <CardStacksFilter
          onSearch={onSearch}
          formData={searchValues}
          activeCities={activeCities}
          locale={locale}
        />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={cardStacks}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const mapDispatchToProps = {
  setBookingReviewsAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(CardStacks)
