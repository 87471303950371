import { Button, Form, Select } from 'antd'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'
import { IStudioIntegrationLessonConfigFilterParams } from '../../../pages/studio-integration-config-container/share-types'
import { useEffect } from 'react'
const { Option } = Select

const StudioIntegrationLessonConfigFilter = ({
  studioIntegrationConfigId,
  onSearch,
  formData,
}: {
  studioIntegrationConfigId: string
  onSearch: (value: IStudioIntegrationLessonConfigFilterParams) => void
  formData: IStudioIntegrationLessonConfigFilterParams | undefined
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  const onFinish = (values: { isActive?: boolean }) => {
    onSearch({
      ...values,
      studioIntegrationConfigId,
    })
  }

  const onReset = () => {
    onSearch({
      studioIntegrationConfigId,
    })
  }

  return (
    <Form
      form={form}
      layout="inline"
      className="filter-inline-form"
      onFinish={onFinish}
      onReset={onReset}
    >
      <Form.Item name="isActive" label={translate('referralCampaigns.isActive')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>
            <FormattedMessage id="yes"></FormattedMessage>
          </Option>
          <Option value={false}>
            <FormattedMessage id="no"></FormattedMessage>
          </Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default StudioIntegrationLessonConfigFilter
