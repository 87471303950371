import React, { useEffect, useState } from 'react'
import { Table, Tag, Space, Button, Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import styles from './admin-users.module.less'
import { getPaginatedAdminUsers } from '../../../api/userApi'
import { IAdminUser, AdminUserRole, PageInfo, PermissionsEnum } from '../../../share-types'
import {
  IAdminUserFilterParams,
  IPaginatedAdminUsersParams,
  PaginatedAdminUserResponse,
} from '../share-type'
import ResetUserPasswordModal from '../../../components/admin-users/reset-user-password-modal/reset-user-password-modal'
import AuthPermission from '../../../high-order-components/auth-permission'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import LinkButton from '../../../components/link-button/link-button'
import AdminUsersFilter from '../../../components/admin-users/admin-users-filter/admin-users-filter'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const AdminUsers = () => {
  const navigate = useNavigate()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [dataSource, setDataSource] = useState<IAdminUser[]>([])
  const [showResetUserPasswordModal, setShowResetUserPasswordModal] = useState<boolean>(false)
  const [selectAdminUser, setSelectAdminUser] = useState<IAdminUser | undefined>()
  useEffect(() => {
    const data = {
      size: pageInfo.pageSize,
      page: pageInfo.currentPage - 1,
    }

    getPaginatedAdminUsersAction(data)
  }, [])

  const getPaginatedAdminUsersAction = (data: IPaginatedAdminUsersParams) => {
    getPaginatedAdminUsers(data).then((res: PaginatedAdminUserResponse) => {
      setPageInfo({
        ...res.pageInfo,
        currentPage: res.pageInfo.currentPage + 1,
      })
      setDataSource(res.data)
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
    }
    getPaginatedAdminUsersAction(data)
  }

  const changePassword = (event: React.MouseEvent<HTMLElement>, record: IAdminUser) => {
    event.stopPropagation()
    setShowResetUserPasswordModal(true)
    setSelectAdminUser(record)
  }

  const handleCancel = () => {
    setShowResetUserPasswordModal(false)
  }

  const onSearch = (value: IAdminUserFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    getPaginatedAdminUsersAction(data)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '用户角色',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: AdminUserRole[]) => {
        return roles.map(role => {
          return (
            <Tag key={role.id} color="blue">
              {role.name}
            </Tag>
          )
        })
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.ADMIN_USERS_WRITE}>
            <LinkButton to={`/admin-users/${record.id}/edit`}>编辑</LinkButton>
          </AuthPermission>

          <LinkButton to={`/admin-users/${record.id}`}>查看</LinkButton>

          <AuthPermission permission={PermissionsEnum.ADMIN_USERS_WRITE}>
            <Button type="link" block onClick={event => changePassword(event, record)}>
              修改密码
            </Button>
          </AuthPermission>
        </Space>
      ),
    },
  ]

  return (
    <Space className={styles['coupon-list-space']} direction="vertical" wrap size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['left-col']}>
          <div>用户列表</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['right-col']}>
          <AuthPermission permission={PermissionsEnum.ADMIN_USERS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/admin-users/new')}
            >
              创建新用户
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <div className={styles['filter-bar']}>
        <AdminUsersFilter onSearch={onSearch} />
      </div>
      <Table
        rowKey={record => record.id}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
      <ResetUserPasswordModal
        visible={showResetUserPasswordModal}
        user={selectAdminUser}
        handleCancel={handleCancel}
      />
    </Space>
  )
}

export default AdminUsers
