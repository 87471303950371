export enum TaskRewardableTypeEnums {
  COUPON_TEMPLATE = 'COUPON_TEMPLATE',
}

export const TaskRewardableTypeOptions = [
  {
    label: '优惠券模版',
    value: TaskRewardableTypeEnums.COUPON_TEMPLATE,
  },
]
