import { Tag } from 'antd'

interface Iprops {
  value?: boolean | number
  yesColor?: string
  noColor?: string
}

const YesOrNoTag = (props: Iprops) => {
  const { yesColor = 'green', noColor = 'red' } = props
  return <Tag color={props.value ? yesColor : noColor}>{props.value ? 'YES' : 'NO'}</Tag>
}

export default YesOrNoTag
