import axios from 'axios'

import { store } from '../store'
import { addRequestCount, reduceRequestCount } from '../store/spinner/action'
import { clearAuthAction } from '../store/userAuth/action'

const authRequest = axios.create()

const errorHandle = (error: MISSING_TYPE) => {
  const { response } = error
  store.dispatch(reduceRequestCount())
  let msg
  let statusCode
  if (response && response instanceof Object) {
    const { data } = response
    statusCode = response.status
    if (statusCode === 401 || statusCode === 403) {
      store.dispatch(clearAuthAction())
    } else {
      if (data instanceof Object) {
        return Promise.reject({
          ...data,
          success: false,
        })
      } else {
        msg = data
      }
    }
  } else {
    store.dispatch(clearAuthAction())
    statusCode = 600
    msg = error.message || 'Network Error'
  }

  return Promise.reject({ success: false, statusCode, message: msg, error })
}

authRequest.interceptors.response.use(response => {
  store.dispatch(reduceRequestCount())
  return response.data
}, errorHandle)

authRequest.interceptors.request.use(config => {
  const { auth } = store.getState()
  store.dispatch(addRequestCount())
  if (auth && auth.accessToken) {
    config.headers.set('Authorization', `Bearer ${auth.accessToken}`)
  }

  return config
})

export default authRequest
