import { notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PrelaunchLessonDescriptionFormValues } from '../../prelaunch-organization-forms-container/share-types'
import {
  editPrelaunchLessonDescriptionDetail,
  getPrelaunchLessonDescriptionDetail,
} from '../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../i18n'
import { convertLessonDescriptionToFormValues } from '../../../helpers/prelaunch-organization-helper'
import LessonDescriptionForm from '../../../components/common/lesson-description-form'

const PrelaunchLessonDescriptionForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [prelaunchLessonDescriptionId] = useState<string | undefined>(
    params?.prelaunchLessonDescriptionId,
  )
  const [prelaunchOrganizationId] = useState<string | undefined>(params?.id)
  const [organizationUniqueId, setOrganizationUniqueId] = useState<string | undefined>()

  const [prelaunchLessonDescriptionFormData, setPrelaunchLessonDescriptionFormData] =
    useState<PrelaunchLessonDescriptionFormValues>()

  useEffect(() => {
    if (prelaunchLessonDescriptionId) {
      getPrelaunchLessonDescriptionDetail(Number(prelaunchLessonDescriptionId)).then(data => {
        setOrganizationUniqueId(data.prelaunchOrganizationFormUniqueId)
        setPrelaunchLessonDescriptionFormData(convertLessonDescriptionToFormValues(data))
      })
    }
  }, [prelaunchLessonDescriptionId])

  const onFinish = (value: PrelaunchLessonDescriptionFormValues) => {
    if (!prelaunchLessonDescriptionId) {
      return
    }

    editPrelaunchLessonDescriptionDetail(prelaunchLessonDescriptionId, value)
      .then(response => {
        if (response) {
          notification.success({
            message: 'Edit Lesson Description Success',
          })
          navigate(
            `/prelaunch-organization-forms/${prelaunchOrganizationId}/lesson-descriptions/${prelaunchLessonDescriptionId}`,
          )
        }
      })
      .catch(err => {
        notification.error({
          message: 'Edit Lesson Description Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  if (!organizationUniqueId) {
    return null
  }

  return (
    <LessonDescriptionForm
      organizationUniqueId={organizationUniqueId}
      onFinish={onFinish}
      formData={prelaunchLessonDescriptionFormData}
      isEdit={true}
    />
  )
}

export default PrelaunchLessonDescriptionForm
