import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { getCities } from '../../api/authApi'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { setActiveCitiesAction } from '../../store/city/action'
import { setPlansAction } from '../../store/global/action'
import translate from '../../i18n'
import { setStudioLeaderboardRaningTypesAction } from '../../store/studio-leaderboard/action'
import { getStudioLeaderboardRankingTypes } from '../../api/studioLeaderboardsApi'

const StudioLeaderboadersContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })

    getStudioLeaderboardRankingTypes().then(res => {
      const { setStudioLeaderboardRaningTypesAction } = props
      setStudioLeaderboardRaningTypesAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/studio-leaderboards\/[0-9]+\/edit$/)) {
      return translate('navBar.editStudioLeaderboards')
    }

    if (pathName.match(/^\/studio-leaderboards\/new/)) {
      return translate('navBar.newStudioLeaderboards')
    }

    if (pathName.match(/^\/studio-leaderboards\/[0-9]+$/)) {
      return translate('navBar.viewStudioLeaderboards')
    }

    return translate('navBar.studioLeaderboardsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
  setPlansAction,
  setStudioLeaderboardRaningTypesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(StudioLeaderboadersContainer)
