import { Button, Col, Row, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import SegmentsFilter from '../../../components/segments/segments-filter/segments-filter'
import {
  ISegment,
  ISegmentsFilterParams,
  IPaginatedSegmentsParams,
  SegmentTypeEnum,
} from '../share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import { getPaginatedSegments } from '../../../api/segmentApi'
import { SegmentTypeOptions } from '../constant'
import YesOrNoTag from '../../../components/common/YesOrNoTag'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const Segments = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [segments, setSegments] = useState<ISegment[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ISegmentsFilterParams>()

  useEffect(() => {
    const searchName = searchParams.get('name')
    const searchIsActive = searchParams.get('isActive')
    const searchType = searchParams.get('type')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const newSearchValues = {
      name: searchName ?? undefined,
      isActive: searchIsActive ? (searchIsActive === 'true' ? true : false) : undefined,
      type: searchType ?? undefined,
    }
    const data = {
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
      ...newSearchValues,
    }
    setSearchValues(newSearchValues)
    getPaginatedSegmentsAction(data)
  }, [])

  const getPaginatedSegmentsAction = (data: IPaginatedSegmentsParams) => {
    getPaginatedSegments(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setSegments(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      ...searchValues,
    }
    getPaginatedSegmentsAction(data)
  }
  const onSearch = (value: ISegmentsFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedSegmentsAction(data)
  }

  const columns: ColumnsType<ISegment> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => SegmentTypeOptions.find(option => option.value === type)?.label,
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: number) => <YesOrNoTag value={isActive} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: ISegment) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.SEGMENTS_WRITE}>
            <LinkButton
              disabled={record.type === SegmentTypeEnum.SYSTEM}
              to={`/segments/${record.id}/edit`}
            >
              编辑
            </LinkButton>
          </AuthPermission>

          <LinkButton
            disabled={record.type === SegmentTypeEnum.SYSTEM}
            to={`/segments/${record.id}`}
          >
            查看
          </LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>用户分组</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.SEGMENTS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/segments/new')}
            >
              创建新的用户分组
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <SegmentsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={segments}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default Segments
