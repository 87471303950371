import { Descriptions, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CorporateCreditsDistributionScheduleType,
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleHistory,
  ICorporateCreditsDistributionScheduleMember,
} from '../share-type'
import translate from '../../../i18n'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import LinkButton from '../../../components/link-button/link-button'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { getDateTime } from '../../../helpers/utils'
import {
  getCorporateCreditsDistributionScheduleById,
  getCorporateCreditsDistributionScheduleHistories,
  getCorporateCreditsDistributionScheduleMembers,
} from '../../../api/corporateApi'
import { formatDuration } from '../../../helpers/corporate-credits-distribution-schedule-helper'
import CorporateCreditsDistributionScheduleMemberTable from '../../../components/corporates/corporate-credits-distribution-schedule-member-table/corporate-credits-distribution-schedule-member-table'
import CorporateCreditsDistributionScheduleHistoriesTable from '../../../components/corporates/corporate-credits-distribution-schedule-histories-table/corporate-credits-distribution-schedule-histories-table'

const CorporateCreditsDistributionSchedule = () => {
  const params = useParams()
  const [corporateId] = useState<number>(() => Number(params?.id))
  const [creditsDistributionScheduleId] = useState<number>(() =>
    Number(params?.creditsDistributionScheduleId),
  )

  const [corporateCreditsDistributionSchedule, setCorporateCreditsDistributionSchedule] =
    useState<ICorporateCreditsDistributionSchedule>()

  const [
    corporateCreditsDistributionScheduleMembers,
    setCorporateCreditsDistributionScheduleMembers,
  ] = useState<ICorporateCreditsDistributionScheduleMember[]>([])

  const [
    corporateCreditsDistributionScheduleHistories,
    setCorporateCreditsDistributionScheduleHistories,
  ] = useState<ICorporateCreditsDistributionScheduleHistory[]>([])

  useEffect(() => {
    if (corporateId && creditsDistributionScheduleId) {
      getCorporateCreditsDistributionScheduleById(corporateId, creditsDistributionScheduleId).then(
        res => {
          if (!res.isApplyForAllMembers) {
            getCorporateCreditsDistributionScheduleMembersAction()
          }
          setCorporateCreditsDistributionSchedule(res)
        },
      )
      getCorporateCreditsDistributionScheduleHistoriesAction()
    }
  }, [corporateId, creditsDistributionScheduleId])

  const getCorporateCreditsDistributionScheduleMembersAction = () => {
    if (corporateId && creditsDistributionScheduleId) {
      getCorporateCreditsDistributionScheduleMembers(
        corporateId,
        creditsDistributionScheduleId,
      ).then(members => {
        setCorporateCreditsDistributionScheduleMembers(members)
      })
    }
  }

  const getCorporateCreditsDistributionScheduleHistoriesAction = () => {
    if (corporateId && creditsDistributionScheduleId) {
      getCorporateCreditsDistributionScheduleHistories(
        corporateId,
        creditsDistributionScheduleId,
      ).then(histories => {
        setCorporateCreditsDistributionScheduleHistories(histories)
      })
    }
  }

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {corporateCreditsDistributionSchedule && (
          <>
            <Descriptions
              title={translate('navbar.corporateCreditsDistributionSchedule.view')}
              labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
              contentStyle={{ minWidth: 300 }}
              column={2}
              bordered
              extra={
                <Space>
                  <AuthPermission
                    permission={PermissionsEnum.CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_WRITE}
                  >
                    <LinkButton
                      to={`/corporates/${corporateId}/credits-distribution-schedules/${corporateCreditsDistributionSchedule.id}/edit`}
                    >
                      {translate('edit')}
                    </LinkButton>
                  </AuthPermission>
                </Space>
              }
            >
              <Descriptions.Item label="ID">
                {corporateCreditsDistributionSchedule.id}
              </Descriptions.Item>
              <Descriptions.Item label="Corporate ID">
                <LinkButton to={`/corporates/${corporateCreditsDistributionSchedule.corporateId}`}>
                  {corporateCreditsDistributionSchedule.corporateId}
                </LinkButton>
              </Descriptions.Item>

              <Descriptions.Item label={translate('isActive')}>
                <YesOrNoTag value={corporateCreditsDistributionSchedule.isActive} />
              </Descriptions.Item>

              <Descriptions.Item
                label={translate(
                  'corporate.corporateCreditsDistributionSchedule.modal.isApplyForAllMembers',
                )}
              >
                <YesOrNoTag value={corporateCreditsDistributionSchedule.isApplyForAllMembers} />
              </Descriptions.Item>

              <Descriptions.Item label={translate('corporate.credits')}>
                {corporateCreditsDistributionSchedule.credits}
              </Descriptions.Item>
              <Descriptions.Item label={translate('corporate.bufferPercentage')}>
                {corporateCreditsDistributionSchedule.bufferPercentage}%
              </Descriptions.Item>

              <Descriptions.Item
                label={translate(
                  'corporate.corporateCreditsDistributionSchedule.modal.creditsValidityPeriod',
                )}
              >
                {formatDuration(corporateCreditsDistributionSchedule?.creditsValidityPeriod)}
              </Descriptions.Item>
              <Descriptions.Item
                label={translate(
                  'corporate.corporateCreditsDistributionSchedule.modal.isReclaimBeforeDistribute',
                )}
              >
                <YesOrNoTag
                  value={corporateCreditsDistributionSchedule.isReclaimBeforeDistribute}
                />
              </Descriptions.Item>

              <Descriptions.Item
                label={translate(
                  'corporate.corporateCreditsDistributionSchedule.modal.schedule.type',
                )}
              >
                {corporateCreditsDistributionSchedule?.schedule.type}
              </Descriptions.Item>

              {corporateCreditsDistributionSchedule?.schedule.type ===
                CorporateCreditsDistributionScheduleType.SPECIFIC_TIME && (
                <Descriptions.Item
                  label={translate(
                    'corporate.corporateCreditsDistributionSchedule.modal.schedule.specificTimes',
                  )}
                >
                  {corporateCreditsDistributionSchedule?.schedule.specific_times?.map(
                    (time, index) => <div key={index}>{time}</div>,
                  )}
                </Descriptions.Item>
              )}

              {corporateCreditsDistributionSchedule?.schedule.type ===
                CorporateCreditsDistributionScheduleType.REPEATABLE && (
                <>
                  <Descriptions.Item
                    label={translate(
                      'corporate.corporateCreditsDistributionSchedule.modal.schedule.interval',
                    )}
                  >
                    {formatDuration(corporateCreditsDistributionSchedule?.schedule)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={translate(
                      'corporate.corporateCreditsDistributionSchedule.modal.schedule.startDate',
                    )}
                  >
                    {corporateCreditsDistributionSchedule?.schedule.start_date}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={translate(
                      'corporate.corporateCreditsDistributionSchedule.modal.schedule.endDate',
                    )}
                  >
                    {corporateCreditsDistributionSchedule?.schedule.end_date}
                  </Descriptions.Item>
                </>
              )}

              <Descriptions.Item label={translate('corporate.notes')} span={2}>
                {corporateCreditsDistributionSchedule.notes}
              </Descriptions.Item>

              <Descriptions.Item label={translate('createdAt')}>
                {getDateTime(corporateCreditsDistributionSchedule.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label={translate('updatedAt')}>
                {getDateTime(corporateCreditsDistributionSchedule.updatedAt)}
              </Descriptions.Item>
            </Descriptions>

            {!corporateCreditsDistributionSchedule.isApplyForAllMembers &&
              corporateCreditsDistributionScheduleMembers.length > 0 && (
                <CorporateCreditsDistributionScheduleMemberTable
                  corporateId={corporateId}
                  corporateCreditsDistributionScheduleId={creditsDistributionScheduleId}
                  members={corporateCreditsDistributionScheduleMembers}
                  onRefresh={getCorporateCreditsDistributionScheduleMembersAction}
                />
              )}

            <CorporateCreditsDistributionScheduleHistoriesTable
              histories={corporateCreditsDistributionScheduleHistories}
            />
          </>
        )}
      </Space>
    </>
  )
}

export default CorporateCreditsDistributionSchedule
