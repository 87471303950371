import React, { useState } from 'react'
import { Row, Col, Card, Button, Descriptions, Typography } from 'antd'
import styles from './billing-invoices-info.module.less'
import BillingInvoicesProcessesModal from '../billing-invoices-processes-modal/billing-invoices-processes-modal'

const { Paragraph } = Typography
const BildivngInvoicesInfo: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <div>
      <Card className="studios-area-card" bordered={false}>
        <div className={`studios-area-card-title ${styles['invoice-card-title']}`}>
          开票/寄送发票
        </div>
        <Row className={styles['invoice-card-content']} gutter={87} justify="space-between">
          <Col span={8}>
            <Descriptions
              column={1}
              title="特别说明"
              className={styles['special-explanation-description']}
            >
              <Descriptions.Item>
                <div>1.发票项目名称请写体育服务*健身服务费。</div>
              </Descriptions.Item>
              <Descriptions.Item>
                <div>
                  2.发票<span className={styles['red-word']}>晚于当月12号</span>
                  寄送，将自动延迟至下月支付。
                </div>
              </Descriptions.Item>
              <Descriptions.Item>
                <div>
                  3.发票<span className={styles['red-word']}>金额不超过200人民币</span>，
                  财务会累积到这个金额下个月进行支付。
                </div>
              </Descriptions.Item>
              <Descriptions.Item>
                <div>
                  4.每月<span className={styles['red-word']}>18号</span>
                  打款(节假日会顺延至下一个工作日)。
                </div>
              </Descriptions.Item>

              <Descriptions.Item className={styles['show-modal-description-item']}>
                <Button type="link" onClick={showModal} className={styles['show-modal-button']}>
                  查看发票/结算相关流程
                </Button>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={8}>
            <Descriptions column={1} title="开票信息">
              <Descriptions.Item label="抬头">
                <Paragraph copyable>淘健乐企业管理咨询（上海）有限公司</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="税号">
                <Paragraph copyable>91310000MA1G039761</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="地址">
                <Paragraph copyable>上海市黄浦区浙江中路400号7楼712-048室</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="电话">
                <Paragraph copyable>021-62307675</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="开户银行">
                <Paragraph copyable>中国银行上海市长寿路支行</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="银行账户">
                <Paragraph copyable>441670962995</Paragraph>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={8}>
            <Descriptions column={1} title="纸质发票寄送信息">
              <Descriptions.Item label="姓名">
                <Paragraph copyable> 协瞳企业管理咨询（上海）有限公司</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="地址">
                <Paragraph copyable>上海市静安区恒丰路329号中汇港·静安809室</Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="电话">
                <Paragraph copyable>021-62307675</Paragraph>；
                <Paragraph copyable>+8618201812468</Paragraph>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>

      <BillingInvoicesProcessesModal open={isModalVisible} handleCancel={handleCancel} />
    </div>
  )
}
export default BildivngInvoicesInfo
