const en = {
  welcome: 'Welcome to using ClassPass',
  noMatchPageDesc: 'Sorry, the page you visited does not exist.',
  noMatchButton: 'Back Home',
  errorPageSubTitle: 'Sorry, something went wrong.',
  'menuBar.changeLanguageTitle': '语言 / Language',
  'menuBar.couponTemplatesTitle': 'Coupon Templates',
  'menuBar.adminRolesTiltle': 'Admin User Roles',
  'menuBar.adminUsersTiltle': 'Manager',
  'menuBar.bookingReviewsTitle': 'Booking Reviews',
  'menuBar.marketingTitle': 'Marketing',
  'menuBar.cardStacksTitle': 'Card Stacks',
  'menuBar.cardConfigsTitle': 'Card Configs',
  'menuBar.corporatesTitle': 'Corporates',
  'menuBar.referralCampaignsTitle': 'RAF Campaign',
  'menuBar.accountManageTitle': 'Account Management',
  'menuBar.searchTrendsTitle': 'Search Trends',
  'menuBar.featureFlagTitle': 'Feature Flag',
  'menuBar.StudioIntegrationConfigs': 'Studio Integration Configs',

  saveButton: 'Save settings',
  logout: 'Logout',
  loginUsernameTips: 'Please input your Username!',
  loginPasswordTips: 'Please input your Password!',
  loginUsernamePlaceholder: 'Username',
  loginPasswordPlaceholder: 'Password',
  'navBar.home': 'home',
  'navBar.couponTemplates': 'Coupon Templates',
  'navBar.couponTemplatesTitle': 'Coupon Templates',
  'navBar.newCouponTemplate': 'New Coupon Template',
  'navBar.editCouponTemplate': 'Edit Coupon Template',
  'navBar.viewCouponTemplate': 'View Coupon Template',
  'navBar.changeLanguage': 'Change Language',
  'navBar.manger': 'Manager',
  'navBar.createNewAdminUser': 'Create New Admin User',
  'navBar.editAdminUser': 'Edit Admin User',
  'navBar.viewAdminUser': 'View Admin User',
  'navBar.adminRoles': 'Roles',
  'navBar.newAdminRoles': 'New Roles',
  'navBar.editAdminRoles': 'Edit Role',
  'navBar.viewBookingReview': 'View User Review',
  'navBar.bookingReviewsTitle': 'User Booking Reviews',
  'navBar.bookingReviews': 'Booking Reviews',
  'navBar.viewBookingReviewsTitle': 'View Booking Reviews',
  'navBar.viewCardStackTitle': 'View Card Stack',
  'navBar.cardStacksTitle': 'Card Stacks',
  'navBar.newCardStack': 'New Card Stack',
  'navBar.editCardStack': 'Edit Card Stack',
  'navBar.cardConfigsTitle': 'Card config',
  'navBar.viewCardConfigTitle': 'View Card Config',
  'navBar.newCardConfig': 'New Card Config',
  'navBar.editCardConfig': 'Edit Card Config',
  'navBar.corporatesTitle': 'Corporates',
  'navBar.viewCorporate': 'View Corporate',
  'navBar.newCorporate': 'New Corporate',
  'navBar.editCorporate': 'Edit Corporate',
  'navBar.studioLeaderboardCategoryDetail': 'View Studio Leaderboard Category',
  'navBar.editStudioLeaderboardCategories': 'Edit Studio Leaderboard Category',
  'navBar.newStudioLeaderboardCategories': 'New Studio Leaderboard Category',
  'navBar.viewStudioLeaderboardCategories': 'View Studio Leaderboard Category',
  'navBar.studioLeaderboardCategoriesTitle': 'Studio Leaderboard Category',
  'menuBar.studioLeaderboardCategoriesTitle': 'Studio Leaderboard Category',
  'menuBar.studioLeaderboardsTitle': 'Studio Leaderboards',

  'navBar.taskRewardsTitle': 'Task Rewards',
  'navBar.viewTaskRewards': 'View Task Rewards',
  'navBar.newTaskRewards': 'New Task Rewards',
  'navBar.editTaskRewards': 'Edit Task Rewards',
  'menuBar.taskRewardsTitle': 'Task Rewards',
  'menuBar.couponTitle': 'Coupon',
  'navBar.taskRewardsDetail': 'Task Rewards Details',

  'menuBar.userManagementTitle': 'User Management',
  'menuBar.segmentsTitle': 'Segments',
  'menuBar.segmentRulesTitle': 'Segment Rules',
  'navBar.editSegment': 'Edit Segment',
  'navBar.newSegment': 'New Segment',
  'navBar.viewSegment': 'View Segment',
  'navBar.segmentsTitle': 'Segments',
  'navBar.newUserSegments': 'New User Segment',
  'navBar.editSegmentRule': '编辑分组规则',
  'navBar.newSegmentRule': '创建分组规则',
  'navBar.viewSegmentRule': '分组规则详情',
  'navBar.segmentRulesTitle': '分组规则列表',
  'navBar.referralCampaignTitle': 'RAF Campaigns',
  'navBar.viewReferralCampaign': 'View Campaign',
  'navBar.newReferralCampaign': 'New Campaign',
  'navBar.editReferralCampaign': 'Edit Campaign',
  'navBar.referralCampaignDetail': 'RAF Campaign Details',
  'referralCampaigns.createNewCampaign': 'Create New Campaign',
  'referralCampaigns.name': 'Name',
  'referralCampaigns.name.message': 'Please enter name',

  'referralCampaigns.isDefault': 'Default',
  'referralCampaigns.isDefault.desc':
    'Make the campaign the default campaign. Default Campaign is used to configure receiver reward coupon and sender reward credits in everyday mode. This option is not editable once the campaign is created.',

  'referralCampaigns.isActive': 'Active',
  'referralCampaigns.isActive.desc':
    'Activate the campaign and the campaign becomes visible to users',
  'referralCampaigns.startDatetime': 'Start Datetime',
  'referralCampaigns.endDatetime': 'End Datetime',
  'referralCampaigns.pageBackgroundColor': 'Page Background Color',
  'referralCampaigns.savePosterButtonColor': 'Save Poster Button Color',
  'referralCampaigns.sharingButtonColor': 'Sharing Button Color',
  'referralCampaigns.generatingPosterButtonColor': 'Generate Poster Button Color',
  'referralCampaigns.countDownBeforeMinutes': 'Countdown Minutes',
  'referralCampaigns.countDownBeforeMinutes.desc':
    'Display the countdown clock X minutes before the campaign ends',
  'referralCampaigns.senderRewardScheme': 'Sender Reward Scheme',
  'referralCampaigns.receiverRewardScheme': 'Receiver Reward Scheme',
  'referralCampaigns.couponTemplateId': 'Coupon Template',
  'referralCampaigns.couponTemplateId.message': 'Coupon Template is Required',
  'referralCampaigns.receiverCount': 'Receiver Count',
  'referralCampaigns.receiverCount.message': 'Receiver Count is required!',
  'referralCampaigns.credits': 'Reward Credits',
  'referralCampaigns.credits.message': 'Reward Credits is required!',
  'referralCampaigns.credits.desc':
    "Number of credits RAF sender will receive after RAF receiver's first purchase",
  'referralCampaigns.translations': 'Translations',
  'referralCampaigns.translations.title': 'Campaign Title',
  'referralCampaigns.translations.title.message': 'Campaign Title is required!',
  'referralCampaigns.translations.rewardTitle': 'Reward Title',
  'referralCampaigns.translations.rewardTitle.message': 'Reward Title is required!',
  'referralCampaigns.translations.receiverPageTitle': 'Receiver Page Title',
  'referralCampaigns.translations.receiverPageTitle.message': 'Receiver Page Title is required!',
  'referralCampaigns.translations.referralPosterModalDescription': 'Poster Model Description',
  'referralCampaigns.translations.referralPosterModalDescription.message':
    'Poster Model Description is required',
  'referralCampaigns.translations.sharingDescription': 'WeChat Sharing Message',
  'referralCampaigns.translations.highlightColor': 'Highlight Text Color',

  'referralCampaigns.translations.sharingDescription.message': 'WeChat Sharing Message is required',
  'referralCampaigns.translations.bannerUrl': 'Sender Page Banner',
  'referralCampaigns.translations.shareImageUrl': 'Share Image',
  'referralCampaigns.translations.posterImageUrl': 'Poster Image',
  'referralCampaigns.translations.referralEntranceImageUrl': 'RAF Entrance Image',
  'referralCampaigns.translations.receiverPageBannerUrl': 'Receiver Page Banner',
  'referralCampaigns.translations.receiverPageIntroImageUrl': 'Receiver Page Intro Image',
  'referralCampaigns.translations.receiverPagePriceImageUrl': 'Receiver Page Price Image',
  'referralCampaigns.editSuccess': 'Edit Campaign Success',
  'referralCampaigns.editError': 'Edit Campaign Error',
  'referralCampaigns.createSuccess': 'Create Campaign Success',
  'referralCampaigns.createError': 'Create Campaign Error',
  'referralCampaigns.campaignConfig': 'Campaign Config',
  'referralCampaigns.rangeTime': 'Campaign Duration',
  'referralCampaigns.rangeTime.message': 'Start Datetime and End Datetime are required!',
  'referralCampaigns.senderRewardScheme.type.desc':
    'Sender Reward Type (Only one type is supported currently)',
  'referralCampaigns.receiverCount.desc':
    'Will issue the coupon to sender if # of referral receiver if equal or greater than receiver count',
  'referralCampaigns.receiverRewardScheme.type.desc':
    'Receiver Reward Type (Only one type is supported currently)',
  'locale.message': 'Locale is required!',
  'referralCampaigns.receiverRewardScheme.type.message': 'Please select Sender Reward Scheme Type',
  'referralCampaigns.senderRewardScheme.type.message': 'Please select Receiver Reward Scheme Type',
  'referralCampaigns.couponTemplateId.placeholder':
    'Search coupon templates by ID, name or coupon code',

  'navBar.editStudioLeaderboards': 'Edit Studio Leaderboards',
  'navBar.newStudioLeaderboards': 'Create Studio Leaderboards',
  'navBar.viewStudioLeaderboards': 'View Studio Leaderboards',
  'navBar.studioLeaderboardsDetail': 'Studio Leaderboards Details',
  'navBar.studioLeaderboardsTitle': 'Studio Leaderboards',
  'studioLeaderboards.createNewStudioLeaderboard': 'Create New Studio Leaderboard',
  'studioLeaderboards.batchApprove': 'Batch Approve',
  'studioLeaderboards.batchApprove.desc': 'Batch approve all pending studio leaderboards',
  'studioLeaderboards.title': 'Title',
  'studioLeaderboards.title.message': 'Title is required!',
  'studioLeaderboards.translation.name': 'Name',
  'studioLeaderboards.translation.name.message': 'Name is required!',
  'studioLeaderboards.translations': 'Translations',
  'studioLeaderboards.rules': 'Rules',
  'studioLeaderboards.city': 'City',
  'studioLeaderboards.area': 'Area',
  'studioLeaderboards.area.message': 'Area is required!',
  'studioLeaderboards.neighborhoods': 'Neighborhoods',
  'studioLeaderboards.category': 'Category',
  'studioLeaderboards.category.message': 'Category is required!',
  'studioLeaderboards.priority': 'Priority',
  'studioLeaderboards.priority.message': 'Priority is required',
  'studioLeaderboards.type': 'Type',
  'studioLeaderboards.type.message': 'Type is required!',
  'studioLeaderboards.status': 'Status',
  'studioLeaderboards.createSuccess': 'Create Studio Leaderboards Successfully',
  'studioLeaderboards.createError': 'Create Studio Leaderboards Failed',
  'studioLeaderboards.editSuccess': 'Edit Studio Leaderboards Successfully',
  'studioLeaderboards.editError': 'Edit Studio Leaderboards Failed',
  'studioLeaderboards.studioRankers': 'Studio Rankers',
  'studioLeaderboards.preview': 'Preview',
  'studioLeaderboards.previewError': 'Preview Failed',
  'studioLeaderboards.category.placeholder': 'Search category by name',
  'studioLeaderboards.batchMode': 'Batch Mode',
  'studioLeaderboards.batchMode.tooltip.message':
    'Turn on the batch generation switch, if the city, region, and leaderboard type are not filled in, they will be automatically combined. If you do not select the list category, all corresponding list categories will be generated',
  'studioLeaderboards.translations.tooltip.message':
    '批量生成排行榜不需要设置语言·翻译，我们会根据榜单城市，区域，榜单类型自动选择生成语言·翻译',
  'studioLeaderboards.title.tooltip.message':
    '批量生成排行榜不需要设置标题，我们会根据榜单城市，区域，榜单类型自动选择生成标题',
  'studioLeaderboards.updateStudioLeaderboardStatusSuccess': 'Update status successfully',
  'studioLeaderboards.updateStudioLeaderboardStatusError': 'Update status failed',
  'studioLeaderboards.batchApproveStudioLeaderboardError':
    'Batch approve pending studio leaderboards failed',

  'studioLeaderboardCategories.name': 'Name',
  'studioLeaderboardCategories.name.message': 'Name is required!',
  'studioLeaderboardCategories.isActive': 'Active',
  'studioLeaderboardCategories.iconImage': 'Icons',
  'studioLeaderboardCategories.iconSmallDefaultFileName': 'Small Default Icon',
  'studioLeaderboardCategories.iconSmallActiveFileName': 'Small Active Icon',
  'studioLeaderboardCategories.iconLargeDefaultFileName': 'Large Default Icon',
  'studioLeaderboardCategories.iconLargeActiveFileName': 'Large Active Icon',
  'studioLeaderboardCategories.translations': 'Translations',
  'studioLeaderboardCategories.translations.title': 'Title',
  'studioLeaderboardCategories.translations.title.message': 'Title is required!',
  'studioLeaderboardCategories.studioLeaderboardCategoryTags': 'Tags',
  'studioLeaderboardCategories.studioLeaderboardCategoryTags.message': 'Tags is required!',
  'studioLeaderboardCategories.editSuccess': 'Edit category successful',
  'studioLeaderboardCategories.editError': 'Edit category failed',
  'studioLeaderboardCategories.createSuccess': 'Create category successful',
  'studioLeaderboardCategories.createError': 'Create category failed',
  'studioLeaderboards.numberOfRankers': 'The number of studios',
  'studioLeaderboards.allNeighborhoods': 'All Neighborhoods',
  'studioLeaderboards.allCategories': 'All Categories',
  'studioLeaderboards.isActive.desc':
    'isActive flag will be marked as false by the system every time during leaderboard data update. It is not advisable to edit inactive leaderboards',

  'navBar.searchTrendsTitle': 'Search Trends',
  'navBar.viewSearchTrends': 'Search Trend Detail',
  'searchTrends.generatedDate': 'Generated Date',
  'searchTrends.startDate': 'Start Date',
  'searchTrends.endDate': 'End Date',
  'searchTrends.city': 'City',
  'searchTrends.status': 'Status',
  'searchTrends.updateStatusSuccess': 'Modify status successfully',
  'searchTrends.updateStatusError': 'Modify status failed',
  'searchTrends.trendableType': 'Trendable Type',
  'searchTrends.rankers': 'Search Trend {trendableType} Rankers',
  'searchTrends.addRankders': 'Add Rankers',
  'searchTrends.rankers.clicksCount': 'Rankers Clicks Count',
  'searchTrends.rankers.rank': 'Rank',
  'searchTrends.rankers.studioId': 'Studio ID',
  'searchTrends.rankers.studioName': 'Studio Name',
  'searchTrends.rankers.lessonDescriptionId': 'Lesson Description ID',
  'searchTrends.rankers.lessonDescriptionName': 'Lesson Description Name',
  'searchTrends.rankers.updateSuccess': 'Update Rankers Success',
  'searchTrends.rankers.updateError': 'Update Rankers Error',
  'searchTrends.lessonDescription.message': "Please input lesson description's id",
  'searchTrends.studio.message': 'Please input studio id',

  'searchTrendRankersForm.studioTips':
    'The searched venues can only select the venues under the current city, and a maximum of 20 venues can be selected',
  'searchTrendRankersForm.lessonDescriptionTips':
    'A maximum of 20 lesson descriptions can be selected',

  'menuBar.lessonCreditPricingsTitle': 'Lesson Credit Pricing',
  'navBar.lessonCreditPricingTitle': 'Lesson Credit Pricing',
  'navBar.newLessonCreditPricing': 'New Lesson Credit Pricing',
  'navBar.batchUploadLessonCreditPricing': 'Batch New Lesson Credit Pricing',
  'navBar.exchangeRatesTitle': 'Credit Exchange Rate',
  'lessonCreditPricing.lessonDescriptionId': 'Lesson Description',
  'lessonCreditPricing.studioId': 'Studio',
  'lessonCreditPricing.lessonId': 'Lesson',
  'lessonCreditPricing.startDateRange': 'Lesson start date range',
  'lessonCreditPricing.startTimeRange': 'Lesson start time range',
  'lessonCreditPricing.editLessonCreditPricing': 'Edit Lesson Credit Pricing',
  'lessonCreditPricing.newLessonCreditPricing': 'New Lesson Credit Pricing',
  'lessonCreditPricing.batchUploadLessonCreditPricing': 'Batch Upload Lesson Credit Pricing',
  'lessonCreditPricing.adjustedCredits': 'Adjusted Credits',
  'lessonCreditPricing.originalCredits': 'Original Credits',
  'lessonCreditPricing.lessonStartTime': 'Lesson Start Time',
  'lessonCreditPricing.lessonEndTime': 'Lesson End Time',
  'lessonCreditPricing.studio': 'Studio',
  'lessonCreditPricing.lesson': 'Lesson',
  'lessonCreditPricing.lessonDescription': 'Lesson Description',
  'lessons.credits': 'Credits',
  'lessonCreditPricing.adjustedCredits.message': 'Adjusted Credits is required',
  'lessonCreditPricing.adjustments.view': 'View Lesson Credit Adjustments',

  'navBar.corporateCreditsHistories': 'Corporate Credits Histories',
  'corporateCreditsHistories.type': 'Type',
  'corporateCreditsHistories.notes': 'Notes',
  'corporateCreditsHistories.credits': 'Credits',
  'corporateCreditsHistories.createdBy': 'Created By',
  'corporate.credits': 'Credits',
  'corporate.originCredits': 'Credits (this Corporate has {credits} credits)',
  'corporate.notes': 'Notes',
  'corporate.notes.placeholder': 'Please enter notes',
  'corporate.credits.payment': 'Payment',
  'corporate.credits.paymentSuccess': 'Corporate credits pay successfully',
  'corporate.credits.paymentFailed': 'Corporate credits pay failed',
  'corporate.credits.adjust': 'Adjust Credits',
  'corporate.credits.adjustedSuccess': 'Corporate credits adjusted successfully',
  'corporate.credits.adjustedFailed': 'Corporate credits adjusted failed',
  'corporate.credits.distribution': 'Distribution Credits',
  'corporate.credits.distributionSuccess': 'Corporate credits distributed successfully',
  'corporate.credits.distributionFailed': 'Corporate credits distributed failed',
  'corporate.credits.viewDetails': 'View Details',
  'corporate.credits.reclaim': 'Reclaim Credits',
  'corporate.credits.reclaim.confirm': 'Are you sure you want to reclaim credits?',
  'corporate.credits.reclaimSuccess': 'Corporate credits reclaimed successfully',
  'corporate.credits.reclaimFailed': 'Corporate credits reclaimed failed',
  'corporate.batchReclaimCredits': 'Batch Reclaim Credits',
  'corporate.batchReclaimCredits.action': 'Batch Reclaim Credits Action',
  'corporate.batchReclaimCredits.action.onlyCredits': 'Only Reclaim Credits',
  'corporate.batchReclaimCredits.action.reclaimAndDisable': 'Reclaim Credits and Disable',
  'corporate.batchReclaimCredits.success': 'Batch Reclaim Credits Success',
  'corporate.batchReclaimCredits.failed': 'Batch Reclaim Credits Failed',
  'corporate.batchReclaimCredits.modal.title':
    'The list contains {number} inactive users. Do you want to skip these users and continue recycling?',
  'corporate.batchReclaimCredits.modal.okText': 'Skip',
  'corporate.phoneNumber.bulkUpload': 'Bulk Upload Phone Numbers',
  'corporate.phoneNumber.specify': 'Specify Phone Numbers',
  'corporate.member.activate': 'Activate',
  'corporate.member.deactivate': 'Deactivate',
  'corporate.credits.distributeToAll': 'Distribute to all users',
  'corporate.credits.distributeToUploaded': 'Distribute to uploaded users',
  'corporate.credits.distributeToAll.confirm':
    'There are {activatedMemberCount} activated Members, Are you sure you want to distribute credits?',
  'corporate.credits.distributeToAll.confirmNoMember':
    'There is no member in this corporate, please activate members first',
  'corporate.batchDistributeCredits': 'Batch Distribute Credits',
  'corporate.batchDistributeCredits.success': 'Batch distribute credits success',
  'corporate.batchDistributeCredits.failed': 'Batch distribute credits failed',
  'corporate.batchDistributeCredits.modal.title': 'Do you want to activate these users?',
  'corporate.batchDistributeCredits.modal.okText': 'Activate and Distribute',
  'corporate.batchDistributeCredits.modal.cancelText': 'Skip',
  'corporate.batchDistributeCredits.modal.cancel': 'Cancel',
  'corporate.corporateCreditsDistributionSchedule': 'Corporate Credits Distribution Schedule',
  'corporate.corporateCreditsDistributionSchedule.create': 'Create Credits Distribution Schedule',
  'corporate.corporateCreditsDistributionSchedule.modal.isApplyForAllMembers':
    'Apply For All Corporate Members',
  'corporate.corporateCreditsDistributionSchedule.modal.confirm':
    'Please confirm the following information：',
  'corporate.corporateCreditsDistributionSchedule.modal.isReclaimBeforeDistribute':
    'Reclaim before distribution',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.type': 'Schedule Type',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.type.message':
    'Please select schedule type',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.startDate': 'Start date',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.endDate': 'End date',

  'corporate.corporateCreditsDistributionSchedule.modal.schedule.interval': 'Interval',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.intervalUnit.placeholder':
    'Select Unit',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.specificTimes': 'Execute Time',
  'corporate.corporateCreditsDistributionSchedule.modal.schedule.specificTimes.message':
    'Please select date',
  'corporate.corporateCreditsDistributionSchedule.modal.creditsValidityPeriod':
    'Credits Validity Period',
  'corporate.corporateCreditsDistributionSchedule.modal.duration.placeholder': 'Duration',
  'corporate.corporateCreditsDistributionSchedule.modal.durationUnit.placeholder': 'Unit',
  'corporate.corporateCreditsDistributionSchedule.modal.extendDays.placeholder': 'Extend days',
  'navbar.corporateCreditsDistributionSchedule.create': 'Create Credits Distribution Schedule',
  'navbar.corporateCreditsDistributionSchedule': 'Corporate Credits Distribution Schedule',
  'navbar.corporateCreditsDistributionSchedule.view': 'View Credits Distribution Schedule',
  'navbar.corporateCreditsDistributionSchedule.edit': 'Edit Credits Distribution Schedule',
  'navbar.corporateCreditsDistributionSchedule.members': 'Credits Distribution Schedule Members',
  'navbar.corporateCreditsDistributionSchedule.histories':
    'Credits Distribution Schedule Histories',
  'corporate.corporateCreditsDistributionSchedule.create.success':
    'Create Credits Distribution Schedule Success',
  'corporate.corporateCreditsDistributionSchedule.create.failed':
    'Create Credits Distribution Schedule Failed',
  'corporate.corporateCreditsDistributionSchedule.modal.ok': 'Create',
  'corporate.corporateCreditsDistributionSchedule.modal.executePlans':
    'Execute Plans: {executePlans}',
  'corporateMember.editSuccess': 'Edit Corporate Member Success',
  'corporateMember.editFailed': 'Edit Corporate Member Failed',
  'corporate.corporateCreditsDistributionSchedule.modal.phoneNumberCheck.title':
    'The list contains {validCount} valid and {invalidCount} invalid phone numbers. Do you want to skip the invalid numbers and continue creating the scheduled task?',
  'corporate.corporateCreditsDistributionSchedule.modal.phoneNumberCheck.okText':
    'Skip Invalid Phone Numbers and Continue',
  'corporate.corporateCreditsDistributionSchedule.create.phoneNumberCheck.success':
    'Successfully added {number} members to schedule',

  'corporateCreditsHistories.viewDetail': '{corporateId}-{corporateName} view credits detail',

  'corporateMemberCreditsHistories.drwerTitle': 'Corporate Member Credits Histories',
  'corporateMemberCreditsHistories.corporateId': 'Corporate ID',
  'corporateMemberCreditsHistories.corporateMemberId': 'Corporate Member ID',
  'corporateMemberCreditsHistories.credits': 'Credits',
  'corporateMemberCreditsHistories.type': 'Type',
  'corporateMemberCreditsHistories.referenceId': 'Referrence ID',
  'corporateMemberCreditsHistories.referenceType': 'Referrence Type',
  'corporateMemberCreditsHistories.createdBy': 'Created By',
  'corporatemember.credits.viewCreditsHistories': 'View Credits Histories',
  'corporateMemberCreditsHistories.phonNumber': 'Phone Number',
  'corporate.totalCredits': 'The total credits of Corporate: {credits} credits',
  'corporateMemberCreditsHistories.notes': 'Notes',

  'corporate.batchDeactivateMembers': 'Batch Deactivate Corporate Members',
  'corporate.batchDeactivateMembers.success': 'Batch deactivate corporate members success',
  'corporate.batchDeactivateMembers.failed': 'Batch deactivate corporate members failed',
  'corporate.batchDeactivateMembers.confirm': 'Are you sure you want to deactivate members?',
  'corporate.changeStatus.confirm': 'Are you sure you want to change the status of {phoneNumber}?',

  'corporate.studioIds.bulkUpload': 'Bulk Upload Studio IDs for Booking(.csv)',
  'corporateCreditsHistories.creatTimeRange': 'Create Time Range',

  'corporate.batchUploadPhoneNumber.modal.okText': 'Skip',
  'corporate.unusedDistributedCreditsThreshold': 'Unused Distributed Credits Threshold',
  'corporate.unusedDistributedCredits': 'Unused Distributed Credits',
  'corporate.unusedDistributedCreditsThreshold.tips':
    'when the unused credits is less than this threshold, the corp admin will get notified. default value is 0, meaning no notification will get triggered.',
  'corporate.unusedDistributedCreditsThreshold.required':
    'Unused Distributed Credits Threshold is required',
  'corporateMember.expiredTimeRange': 'Expired Time Range',
  'corporateMember.export': 'Export',
  'corporateMember.creditsExpiredAt': 'Credits Expired At',
  'corporate.bufferPercentage': 'Buffer Percentage',
  'corporate.bufferPercentage.required': 'Buffer Percentage is required',
  'corporate.expiredAt': 'Expired At',
  'corporate.expiredAt.required': 'Expired At is required',

  'taskRewards.title': 'Title',
  'taskRewards.taskRewardableId': 'Task Rewardable ID',
  'taskRewards.taskRewardableType': 'Task Rewardable Type',
  'taskRewards.beans': 'Beans',
  'taskRewards.isActive': 'Is Active',

  'couponTemplates.usageLimit': 'Issue Counts',
  'couponTemplates.usageLimit.message': 'Issue Counts is required!',
  'couponTemplates.frequency.message': 'Frequency is required!',
  'couponTemplates.corporateIds': 'Corporate IDs',
  'couponTemplates.corporateIds.desc':
    'Corporate IDs (multiple selections allowed), optional IDs must belong to enterprises with an ACTIVE status.',

  'menuBar.weChatURLLinksTitle': 'WeChat URL Links',
  'navBar.weChatURLLinksTitle': 'WeChat URL Links',
  'weChatURLLinks.path': 'Path',
  'weChatURLLinks.parameters': 'Parameters',
  'weChatURLLinks.applicationType': 'Application Type',
  'weChatURLLinks.urlLink': 'URL Links',
  'navBar.newWeChatURLLinks': 'New WeChat URL Links',
  'weChatURLLinks.newWeChatURLLinks': 'New WeChat URL Links',
  'weChatURLLinks.createSuccess': 'Create WeChat URL Link Success',
  'weChatURLLinks.createError': 'Create WeChat URL Link Error',

  'navBar.shortURLsTitle': 'Short URLs',
  'navBar.newShortURLs': 'New Short URLs',
  'menuBar.shortURLsTitle': 'Short URLs',
  'shortURLs.updateShortURLs': 'Update Short URLs',
  'shortURLs.redirectURL': 'Redirect URL',
  'shortURLs.hashKey': 'Hash Key',
  'shortURLs.name': 'Name',
  'shortURLs.description': 'Description',
  'shortURLs.shortURL': 'Short URL',
  'shortURLs.createSuccess': 'Create Short URL Success',
  'shortURLs.createError': 'Create Short URL Error',
  'shortURLs.updateSuccess': 'Update Short URL Success',
  'shortURLs.updateError': 'Update Short URL Error',
  'shortURLs.newShortURLs': 'New Short URLs',
  'shortURLs.isRedirectUrlRefreshable': 'Is Redirect URL Refreshable',
  'shortURLs.refreshIntervalDays': 'Refresh Interval Days',

  'navBar.oneTimeCodeBatches': 'One Time Code Batch',
  'navBar.newOneTimeCodeBatch': 'New One Time Code Batch',
  'navBar.editOneTimeCodeBatch': 'Edit One Time Code Batch',
  'navBar.viewOneTimeCodeBatch': 'View One Time Code Batch',
  'navBar.oneTimeCodeBatchesTitle': 'One Time Code Batches',
  'menuBar.oneTimeCodeBatchesTitle': 'One Time Code Batch',
  'oneTimeCodeBatches.detail': 'One Time Code Batch Detail',
  'oneTimeCodeBatches.name': 'Name',
  'oneTimeCodeBatches.credits': 'Credits',
  'oneTimeCodeBatches.isNewUserOnly': 'Is New User Only',
  'oneTimeCodeBatches.creditsValidityPeriodInDays': 'Credits Validity Period In Days',
  'oneTimeCodeBatches.numberOfCodes': 'Number Of Codes',
  'oneTimeCodeBatches.prefix': 'Prefix',
  'oneTimeCodeBatches.timeRange': 'Usable Window Time Range',
  'oneTimeCodeBatches.ikeaSegment': 'IKEA Segment',
  'oneTimeCodeBatches.status': 'Status',
  'oneTimeCodeBatches.createSuccess': 'Create Success',
  'oneTimeCodeBatches.createError': 'Create Error',
  'oneTimeCodeBatches.updateSuccess': 'Update Success',
  'oneTimeCodeBatches.updateError': 'Update Error',
  'oneTimeCodeBatches.changeStatus': 'Change Status',
  'oneTimeCodeBatches.export': 'Export',
  'oneTimeCodeBatches.exportConfirm': 'Export Confirm',
  'oneTimeCodeBatches.exportSuccess': 'Export Success',
  'oneTimeCodeBatches.exportError': 'Export Error',
  'oneTimeCodeBatches.exportPassword': 'Export Password:',
  'oneTimeCodeBatches.numberOfCodesClaimed': 'Number Of Codes Claimed',
  'oneTimeCodeBatches.numberOfCodesCreated': 'Number Of Codes Created',

  'menuBar.oneTimeCodesTitle': 'One Time Code',
  'oneTimeCode.isRedeemed': 'Is Redeemed',
  'oneTimeCode.redeemedBy': 'Redeemed By',
  'oneTimeCode.redeemedByUser': 'Redeemed By User',
  'oneTimeCode.timeRange': 'Create Time Range',
  'oneTimeCode.redeemedAt': 'Redeemed At',
  'oneTimeCode.creditsValidityPeriodInDays': 'Credits Validity Period In Days',
  'oneTimeCode.status': 'Status',

  'shortURLs.createFromWeChatURLLInk': 'Create from WeChat URL Link',
  'shortURLs.createFromWeblink': 'Create from Weblink',
  'shortURLs.wechatURLLink': 'WeChat URL Link',

  'menuBar.miscTitle': 'Misc',
  'navBar.tags': 'Tags',
  'navBar.newTag': 'New Tag',
  'navBar.editTag': 'Edit Tag',
  'navBar.viewTag': 'View Tag',
  'navBar.tagsTitle': 'Tag',
  'menuBar.tagsTitle': 'Tag',
  'tags.name': 'Name',
  'tags.isActive': 'Is Active',
  'tags.taggingsCount': 'Taggings Count',
  'tags.cachedCategoryList': 'Context',
  'tags.categoryAndSubCategory': 'Category & Sub Category',
  'tags.category': 'Category',
  'tags.subCategory': 'Sub Category',
  'tags.translation.name': 'Translation Name',
  'tags.translations': 'Translations',

  'menuBar.lessonDescriptionsTitle': 'Lesson Descriptions',
  'navBar.lessonDescriptionsTitle': 'Lesson Descriptions',
  'navBar.viewLessonDescription': 'View Lesson Description',
  'menuBar.LessonsTitle': 'Lessons',
  'lessonDescription.name': 'Name',
  'lessonDescription.duration': 'Duration(min)',
  'lessonDescription.organizationId': 'Organization ID',
  'lessonDescription.externalProviderId': 'External Provider ID',
  'lessonDescription.isRedirected': 'Is Redirected',
  'lessonDescription.isActive': 'Is Active',
  'lessonDescription.credits': 'Credits',
  'lessonDescription.isAllDay': 'Is All Day',
  'lessonDescription.hasExternalProviderId': 'Has External Provider ID',
  'lessonDescription.bookBefore': 'Book Before',
  'lessonDescription.bookBefore.tips':
    '# of minutes before start_time a class STOPs accepting bookings.',
  'lessonDescription.maxCapacity': 'Max Capacity',
  'lessonDescription.cachedActivityList': 'Cached Activity List',
  'lessonDescription.cachedLabelList': 'Cached Label List',
  'lessonDescription.lateBefore': 'Late Before',
  'lessonDescription.lateBefore.tips': '# of hours before start_time when we charge a late cancel.',
  'lessonDescription.startBookable': 'Start Bookable',
  'lessonDescription.startBookable.tips': 'The # of days before a class is bookable. Default: 10',
  'lessonDescription.isHidden': 'Is Hidden',
  'lessonDescription.isCancelled': 'Is Cancelled',
  'lessonDescription.protip': 'Protip',
  'lessonDescription.maxCapacityCeiling': 'Max Capacity Ceiling',
  'lessonDescription.allowSignInWindowMarking': 'Allow Sign In Window Marking',
  'lessonDescription.ratingScore': 'Rating Score',
  'lessonDescription.ratingVotes': 'Rating Votes',
  'lessonDescription.difficultyLevel': 'Difficulty Level',
  'lessonDescription.lessonDescriptionTranslations': 'Lesson Description Translations',

  'lessonDescriptionTranslations.name': 'Name',
  'lessonDescriptionTranslations.blurb': 'Blurb',
  'lessonDescriptionTranslations.description': 'Description',
  'lessonDescriptionTranslations.specialNoteToUsers': 'Special Note To Users',
  'lessonDescriptionTranslations.highlights': 'Highlights',
  'lessonDescriptionTranslations.lessonPrerequisites': 'Lesson Prerequisites',
  'lessonDescriptionTranslations.equipmentPrerequisites': 'Equipment Prerequisites',
  'lessonDescriptionTranslations.checkInNotes':
    'How many minutes before class starts do you need to arrive',
  'lessonDescriptionTranslations.otherNotes': 'Other Notes',
  'lessonDescriptionTranslations.targetUserGroups': 'Target User Groups',

  'navBar.organizationsTitle': 'Organizations',
  'menuBar.organizationsTitle': 'Organizations',
  'navBar.viewOrganization': 'View Organization',
  'organization.phoneNumber': 'Phone Number',
  'organization.email': 'Email',
  'organization.bookBefore': 'Book Before(min)',
  'organization.cancelBefore': 'Cancel Before(min)',
  'organization.bannerFileName': 'Banner File Name',
  'organization.bannerContentType': ' Banner Content Type',
  'organization.bannerFileSize': 'Banner File Size',
  'organization.bannerUpdatedAt': 'Banner Updated At',
  'organization.profileFileName': 'Profile File Name',
  'organization.profileContentType': 'Profile Content Type',
  'organization.profileFileSize': 'Profile File Size',
  'organization.profileUpdatedAt': 'Profile Updated At',
  'organization.lateBefore': 'Late Before(min)',
  'organization.maxCapacity': 'Max Capacity',
  'organization.startBookable': 'Start Bookable',
  'organization.cityId': 'City ID',
  'organization.liteBookingLimit': 'Lite Booking Limit',
  'organization.attendanceMarkingRequired': 'Attendance Marking Required',
  'organization.maxCapacityCeiling': 'Max Capacity Ceiling',
  'organization.allowSlotAvailabilityNotification': 'Allow Slot Availability Notification',
  'organization.label': 'Label',
  'organization.showClassRatings': 'Show Class Ratings',
  'organization.integratorLessonDescriptionsSync': 'Integrator Lesson Descriptions Sync',
  'organization.integratorQrCodeCheckIn': 'Integrator QR Code Check In',
  'organization.isUserRealNameRequired': 'Is User Real Name Required',
  'organization.isInternalPreviewOnly': 'Is Internal Preview Only',
  'organization.tier': 'Tier',
  'organization.organizationTranslation': 'Organization Translation',
  'organization.picturesAndBanners': 'Pictures And Banners',
  'organization.accountManager': 'Account Manager',
  'organization.accountManager.email': 'Account Manager Email',

  'organizationTranslation.name': 'Name',
  'organizationTranslation.romanizedName': 'Romanized Name',
  'organizationTranslation.blurb': 'Blurb',
  'organizationTranslation.description': 'Description',
  'organizationTranslation.specialNoteToUsers': 'Special Note To Users',

  'navBar.studiosTitle': 'Studios',
  'navBar.viewStudio': 'View Studio',
  'studio.organizationId': 'Organization ID',
  'studio.externalProviderId': 'External Provider ID',
  'studio.zip': 'Zip',
  'studio.phoneNumber': 'Phone Number',
  'studio.email': 'Email',
  'studio.neighborhoodSlug': 'Neighborhood Slug',
  'studio.timeZone': 'Time Zone',
  'studio.bookBefore': 'Book Before(min)',
  'studio.cancelBefore': 'Cancel Before(min)',
  'studio.bannerFileName': 'Banner File Name',
  'studio.bannerContentType': 'Banner Content Type',
  'studio.bannerFileSize': 'Banner File Size',
  'studio.bannerUpdatedAt': 'Banner Updated At',
  'studio.profileFileName': 'Profile File Name',
  'studio.profileContentType': 'Profile Content Type',
  'studio.profileFileSize': 'Profile File Size',
  'studio.profileUpdatedAt': 'Profile Updated At',
  'studio.latitude': 'Latitude',
  'studio.longitude': 'Longitude',
  'studio.latitude.tips': 'Select a location on the map to get the latitude',
  'transitStations.message': 'Please select transit stations',
  'businessDistricts.message': 'Please select business districts',
  'studio.cachedActivityList': 'Cached Activity List',
  'studio.cachedNeighborhoodList': 'Cached Neighborhood List',
  'studio.status': 'Status',
  'studio.neighborhoodId': 'Neighborhood ID',
  'studio.cityId': 'City ID',
  'studio.businessDistrictId': 'Business District ID',
  'studio.isSurgePricingEnabled': 'Is Surge Pricing Enabled',
  'studio.maxVisitTimesThreshold': 'Max Visit Times Threshold',
  'studio.ratingScore': 'Rating Score',
  'studio.ratingVotes': 'Rating Votes',
  'studio.isRequiredQrCodeCheckIn': 'Is Required QR Code Check In',
  'studio.needUploadScheduleReminder': 'Need Upload Schedule Reminder',
  'studio.checkInOpenInMinutes': 'Check In Open In Minutes (min)',
  'studio.checkInDurationInMinutes': 'Check In Duration In Minutes (min)',
  'studio.integratorLessonSync': 'Integrator Lesson Sync',
  'studio.picturesAndBanners': 'Pictures And Banners',
  'studio.studioTranslation': 'Studio Translation',
  'studioTranslation.name': 'Name',
  'studioTranslation.address1': 'Address',
  'studioTranslation.address1.message': 'Please enter address',
  'studioTranslation.address2': 'Address2',
  'studioTranslation.howToFindStudio': 'How To Find Studio',
  'studioTranslation.howToFindStudio.tips': 'For example: right across Hey Tea',
  'studioTranslation.transitToStudioPath': 'Transit To Studio Path',
  'studioTranslation.transitToStudioPath.tips':
    'For example: walk 200m from Exit C, Line 2 Metro station',
  'studioTranslation.country': 'Country',
  'studioTranslation.city': 'City',
  'studioTranslation.area': 'Area',
  'studioTranslation.state': 'State',
  'studioTranslation.blurb': 'Blurb',
  'studioTranslation.highlights': 'Highlights',
  'studioTranslation.description': 'Description',
  'studioTranslation.description.message': 'Please enter description',
  'studioTranslation.specialNoteToUsers': 'Special Note To Users',
  'studioTranslation.announcement': 'Announcement',

  'navBar.editStudioIntegrationConfig': 'Edit Studio Integration Config',
  'navBar.newStudioIntegrationConfig': 'Create New Studio Integration Config',
  'navBar.newStudioIntegrationLessonConfig': 'Create New Lesson Config',
  'navBar.viewStudioIntegrationLessonConfig': 'View Lesson Config',
  'navBar.editStudioIntegrationLessonConfig': 'Edit Lesson Config',
  'navBar.newStudioIntegrationLessonDescriptionConfig': 'Create New Lesson Description Config',
  'navBar.viewPrelaunchLessonDescriptions': 'View Prelaunch Lesson Descriptions',
  'navBar.editPrelaunchLessonDescriptions': 'Edit Prelaunch Lesson Descriptions',
  'navBar.viewStudioIntegrationLessonDescriptionConfig': 'View Lesson Description Config',
  'navBar.editStudioIntegrationLessonDescriptionConfig': 'Edit Lesson Description Config',
  'navBar.viewStudioIntegrationConfig': 'View Studio Integration Config',
  'navBar.studioIntegrationConfig': 'Studio Integration Config',
  'studioIntegrationConfig.integratorType': 'Integrator Type',
  'studioIntegrationConfig.organizationIds': 'Organization IDs',
  'studioIntegrationConfig.createNew': 'Create New Configs',
  'studioIntegrationConfig.detail': 'Studio Integration Config',
  'studioIntegrationConfig.fieldType': 'Field Type',
  'studioIntegrationConfig.fieldType.message': 'Please select field type!',
  'studioIntegrationConfig.fieldValue': 'Field Value',
  'studioIntegrationConfig.fieldValue.message': 'Please enter field value!',
  'studioIntegrationLessonConfig.title': 'Lesson Config',
  'studioIntegrationLessonDescConfig.title': 'Lesson Description Config',
  'studioIntegrationLessonConfig.detail': 'Lesson Config Detail',
  'studioIntegrationConfig.rules': 'Rules',
  'studioIntegrationLessonConfig.timeRanges': 'Time Ranges',
  'studioIntegrationLessonConfig.timeRanges.message': 'Please pick at least a time range!',
  'studioIntegrationConfig.createSuccess': 'Create Config Success',
  'studioIntegrationConfig.createError': 'Create Config Failed',
  'studioIntegrationConfig.editSuccess': 'Edit Config Success',
  'studioIntegrationConfig.editError': 'Edit Config Failed',

  'paymentDetail.companyName': 'Company Name',
  'paymentDetail.companyName.message': 'Please enter company name',
  'paymentDetail.companyAddress': 'Company Address',
  'paymentDetail.companyAddress.message': 'Please enter company address',
  'paymentDetail.bankName': 'Bank Name',
  'paymentDetail.bankName.message': 'Please enter bank name',
  'paymentDetail.bankName.tips': 'Please enter bank message,example: 中信银行上好静安寺支行',
  'paymentDetail.bankAccountNumber': 'Bank Account Number',
  'paymentDetail.bankAccountNumber.message': 'Please enter bank account number',
  'paymentDetail.taxIdentificationNumber': 'Tax Identification Number',
  'paymentDetail.taxIdentificationNumber.message': 'Please enter tax identification number',
  'paymentDetail.taxIdentificationNumber.tips':
    'The serial number in the upper left corner of your business license',
  'studio.area': 'Area',
  'studio.area.message': 'Please enter area',
  'studio.name.message': "Please enter studio's name",
  'studio.latitude.message': 'Please enter latitude',
  'studio.longitude.message': 'Please enter longitude',
  'studio.amenities': 'Amenities',
  'studio.amenities.message': 'Please enter amenities',
  'studio.activities': 'Activities',
  'studio.activities.message': 'Please enter activities',
  'studio.labels': 'Labels',
  'studio.labels.message': 'Please enter labels',
  'studio.primaryCategory': 'Primary category',
  'studio.transitStations': 'Transit Stations',
  'studio.businessDistricts': 'Business Districts',
  'studio.primaryCategory.message': 'Please enter primary category',
  'studio.checkInInfomation': 'Check In Information',
  'studio.taggings': 'Taggings',
  'studio.fxiaokeAccountObjectId': 'Fxiaoke Account Object ID',
  'studio.studioId': 'Studio ID',
  'studio.locationInformation': 'Location Information',
  'studio.baseInformation': 'Base Information',
  'lessonDescription.startBookable.message': 'Please enter start bookable',
  'lessonDescription.maxCapacity.message': 'Please enter max capacity',
  'lessonDescription.bookBefore.message': 'Please enter book before',
  'lessonDescription.lateBefore.message': 'Please enter late before',
  'lessonDescription.difficultLevel': 'Difficult Level',
  'lessonDescription.difficultLevel.message': 'Please enter difficult level',
  'lessonDescription.isAllDay.message': 'Please enter is all day',
  'lessonDescription.description.message': 'Please enter description',
  'studio.phoneNumbers': 'Phone Numbers',
  'studio.phoneNumbers.tips':
    'Phone number should start with 86 or 0, for example, 8618683593821, 028-87820183',
  'studio.checkInOpenInMinutes.message': 'Please enter check in open in minutes',
  'studio.checkInOpenInMinutes.tips':
    "This specify the time when check-in is available before lesson's start time.",
  'studio.checkInDurationInMinutes.message': 'Please enter check in duration in minutes',
  'studio.checkInDurationInMinutes.tips': 'This specify how long before check-in is closed.',
  city: 'City',
  'city.message': 'Please enter city',
  neighborhood: 'Neighborhood',
  'neighborhood.message': 'Please enter neighborhood',
  'lessonDescription.duration.message': 'Please enter duration',
  'lessonDescription.credits.message': 'Please enter credits',

  'prelaunchOrganizationsForm.create': 'Create Prelaunch Organization Form',
  'navBar.viewPrelaunchOrganizationForms': 'View Prelaunch Organization Forms',
  'navBar.prelaunchOrganizationFormsTitle': 'Prelaunch Organization Forms',
  'navBar.prelaunchStudios': 'Prelaunch Studios',
  'navBar.editPrelaunchStudios': 'Edit Prelaunch Studios',
  'navBar.viewPrelaunchStudios': 'View Prelaunch Studios',
  'navBar.prelaunchLessonDescriptions': 'Prelaunch Lesson Descriptions',
  'navBar.prelaunchCompanyPaymentDetail': 'Prelaunch Payment Detail',
  'navBar.editPrelaunchCompanyPaymentDetail': 'Edit Prelaunch Payment Detail',
  'prelaunchOrganizationForms.id': 'ID',
  'prelaunchOrganizationForms.organizationId': 'Organization ID',
  'prelaunchOrganizationForms.uniqueId': 'Unique ID',
  'prelaunchOrganizationForms.uniqueLink': 'Unique Link',
  'prelaunchOrganizationForms.organizationName': 'Organization Name',
  'prelaunchOrganizationForms.expiredAt': 'Expired At',
  'prelaunchOrganizationForms.expiredAtAfter': 'Expired At After',
  'prelaunchOrganizationForms.confirm': 'Confirm & Submit',
  'prelaunchOrganizationForms.confirm.message':
    'By Clicking Ok you will submit and launch {formName}. Please make sure all the information are correct!',
  'prelaunchOrganizationForms.confirmed': 'Confirmed',

  'prelaunchCompanyPaymentDetail.companyName': 'Company Name',
  'prelaunchCompanyPaymentDetail.companyAddress': 'Company Address',
  'prelaunchCompanyPaymentDetail.taxIdentificationNumber': 'Tax Identification Number',
  'prelaunchCompanyPaymentDetail.bankName': 'Bank Name',
  'prelaunchCompanyPaymentDetail.bankAccountNumber': 'Bank Account No.',

  'studio.info': 'Studio Info',
  addStudios: 'Add Studios',
  addLessonDescriptions: 'Add Lesson Descriptions',
  addCompanyPaymentDetail: 'Add Company Payment Detail',
  studio: 'Studio',
  lessonDescription: 'Lesson Description',
  filledPaymentInfo: 'You already filled in the payment info',
  'name.message': 'Please enter name',
  'lessonDescription.status': 'Status',
  'addStudios.success': 'Add Studios Successfully',
  'addStudios.error': 'Add Studios Failed',
  'addLessonDescriptions.success': 'Add Lesson Descriptions Successfully',
  'addLessonDescriptions.error': 'Add Lesson Descriptions Failed',
  'addCompanyPaymentDetail.success': 'Add Company Payment Detail Successfully',
  'addCompanyPaymentDetail.error': 'Add Company Payment Detail Failed',
  'studio.adminUser': 'Admin User',
  'studio.adminUser.email': 'Admin User Email',
  'studio.adminUser.email.message': 'Please enter admin user email',
  'studio.adminUser.email.tips':
    'Your email will be used as the username for the studio backend login, please fill in the correct email',
  addNewPhoneNumber: 'Add New Phone Number',
  'city.placeholder': 'Please select city',
  'neighborhood.placeholder': 'Please select neighborhood',
  'businessDistricts.placeholder': 'Please select business districts',
  'transitStations.placeholder': 'Please select transit stations',
  'amenities.placeholder': 'Please select amenities',
  'activities.placeholder': 'Please select activities',
  'labels.placeholder': 'Please select labels',
  'primaryCategory.placeholder': 'Please select primary category',
  'paymentDetail.companyName.tips':
    'The company name you provide should match the company name on your invoice. If you are a sole proprietor, fill in the personal account name of the operator.',
  'difficultLevelOptions.easy': 'Easy',
  'difficultLevelOptions.medium': 'Medium',
  'difficultLevelOptions.hard': 'Hard',

  firstClassDiscount: 'First Class Discount',
  'firstClassDiscount.discountPercentage': 'Discount Percentage',
  'firstClassDiscount.discountPercentage.message': 'Please enter discount percentage',
  'firstClassDiscount.isDisabled': 'Is Disabled',
  'firstClassDiscount.isFixed': 'Is Fixed',
  'firstClassDiscount.create': 'Create First Class Discount',
  'firstClassDiscount.create.success': 'Create First Class Discount Success',
  'firstClassDiscount.create.error': 'Create First Class Discount Error',
  'firstClassDiscount.update': 'Update First Class Discount',
  'firstClassDiscount.update.success': 'Update First Class Discount Success',
  'firstClassDiscount.update.error': 'Update First Class Discount Error',
  'studio.isFirstClassDiscounted': 'Is First Class Discounted',
  'navBar.editFirstClassDiscountDisabledLesson': 'Edit First Class Discount Disabled Lessons',
  'navBar.newFirstClassDiscountDisabledLesson': 'Create New First Class Discount Disabled Lessons',
  'navBar.firstClassDiscountDisabledLessonsTitle': 'First Class Discount Disabled Lessons',
  'menuBar.firstClassDiscountDisabledLessonsTitle': 'First Class Discount Disabled Lessons',

  firstClassDiscountDisabledLessons: 'First Class Discount Disabled Lessons',
  'firstClassDiscountDisabledLessons.createSuccess':
    'Create First Class Discount Disabled Lessons Success',
  'firstClassDiscountDisabledLessons.createError':
    'Create First Class Discount Disabled Lessons Error',
  'firstClassDiscountDisabledLessons.updateSuccess':
    'Update First Class Discount Disabled Lessons Success',
  'firstClassDiscountDisabledLessons.updateError':
    'Update First Class Discount Disabled Lessons Error',
  'firstClassDiscountDisabledLessons.rangeStartTime': 'Start Time Range',
  'firstClassDiscountDisabledLessons.rangeStartDate': 'Start Date Range',
  'firstClassDiscountDisabledLessons.deleteConfirm': 'Are you sure you want to delete this record?',
  'firstClassDiscountDisabledLessons.deleteSuccess': 'Delete Success',
  'firstClassDiscountDisabledLessons.deleteError': 'Delete Error',
  'studio.primaryCategory.tips': 'Please select a label that matches your studio main business',
  'studio.customerSupportPhoneNumber': 'Customer Support Phone Number',
  'studio.customerSupportPhoneNumber.message': 'Please enter customer support phone number',
  'studio.notificationPhoneNumber': 'Notification Phone Number',
  'studio.notificationPhoneNumber.tips':
    'After the launch, the CP operation team will assist you in opening the background permissions of the mini program and receiving appointment push notifications, so this is not required.',
  'studioTranslation.description.tips': 'Please enter a detailed description of the studio',
  'searchPlaceholder.tips': 'You can search for options after expanding',

  'navBar.lessonCreditAdjustmentsTitle': 'Lesson Credit Adjustments',
  'navBar.newLessonCreditAdjustmentsTitle': 'Create Lesson Credit Adjustments',
  'navBar.editLessonCreditAdjustmentsTitle': 'Edit Lesson Credit Adjustments',
  'navBar.viewLessonCreditAdjustmentsTitle': 'View Lesson Credit Adjustments',
  'lessonCreditAdjustments.selectAdjustmentType': 'Select Adjustment Type',
  'lessonCreditAdjustments.selectAdjustableType': 'Select Adjustable Type',
  'lessonCreditAdjustments.AdjustmentType': 'Adjustment Type',
  'lessonCreditAdjustments.AdjustmentType.message': 'Please select adjustment type',
  'lessonCreditAdjustments.AdjustableType': 'Adjustable Type',
  'lessonCreditAdjustments.AdjustableType.message': 'Please select adjustable type',
  'lessonCreditAdjustments.AdjustableId': 'Adjustable ID',
  'lessonCreditAdjustments.AdjustableId.message': 'Please select {adjustableType} ID',
  'lessonCreditAdjustments.setRule': 'Set Rule',
  'lessonCreditAdjustments.adjustCredits': 'Adjust Credits',
  'lessonCreditAdjustments.adjustCredits.message': 'Please enter Adjust Credits',
  'lessonCreditAdjustments.lessonStartTimeRange': 'Lesson Start Time Range',
  'lessonCreditAdjustments.dayOfWeek': 'Day of Week',
  'lessonCreditAdjustments.lessonDescriptionIds': 'Lesson DescriptionIds',
  'lessonCreditAdjustments.reviewAndSubmit': 'Review and Submit',
  'lessonCreditAdjustments.lessonDescriptionIds.all':
    'All Lesson Description IDs under the organization',
  'lessonCreditAdjustments.dateRange': 'Date Range',
  'lessonCreditAdjustments.daysOfWeek': 'Day of Week',
  'lessonCreditAdjustments.selectLessonDescriptions': 'Select Lesson Descriptions',
  'lessonCreditAdjustments.adjustCredits.edit': 'Edit Adjust Credits',
  'lessonCreditAdjustments.billingItemPricing': 'Billing Item Pricing',
  'lessonCreditAdjustments.billingItemPricingEnabled': 'Add billing item pricing',
  'lessonCreditAdjustments.billingItemPricing.completedClassPriceCents': 'Completed',
  'lessonCreditAdjustments.billingItemPricing.lateCancelledPriceCents': 'Late Cancelled',
  'lessonCreditAdjustments.billingItemPricing.earlyVisitPriceCents': 'Early Visit',
  'lessonCreditAdjustments.billingItemPricing.noShowPriceCents': 'No Show',

  'duplicateLessonDescription.search': 'Duplicated Lesson Descriptions',
  'duplicateLessonDescription.mainLessonDescription.id': 'Main Lesson Decription ID',
  'duplicateLessonDescription.mainLessonDescription.name': 'Main Lesson Decription Name',
  'duplicateLessonDescription.duplicateLessonDescriptions': 'Duplicate Lesson Decriptions',
  'duplicateLessonDescription.mergeSuccess': 'Update Duplicate Lesson Decriptions Success',
  'duplicateLessonDescription.mergeFail': 'Update Duplicate Lesson Decriptions Failed',
  'duplicateLessonDescription.mergeSelected': 'Merge selected items',
  'duplicateLessonDescription.duplicateLessonDescriptions.main': 'Main Lesson Decription',
  'duplicateLessonDescription.mergeDuplicates': 'Merge All Duplicate Lesson Decriptions',
  'duplicateLessonDescription.addDuplicates': 'Add Duplicate Lesson Description',
  'duplicateLessonDescription.addDuplicates.prompt': "Didn't find the duplicates?",
  'duplicateLessonDescription.addDuplicates.success': 'Add Duplicate Lesson Description Success',
  'duplicateLessonDescription.addDuplicates.failure': 'Add Duplicate Lesson Description Failed',
  'duplicateLessonDescription.merged': 'Merged',
  'duplicateLessonDescription.notMerged': 'Pending Review',
  'duplicateLessonDescription.rejected': 'Rejected',
  'duplicateLessonDescription.recover': 'Recover',

  'duplicateLessonDescription.cancelMerge': 'Cancel Merge',
  'duplicateLessonDescription.merge': 'Confirm Merge',
  'duplicateLessonDescription.merge.confirmText':
    'Once the selected lesson descriptions are merged, all upcoming lessons will be reassigned to the main lesson description. This operation is irreversible. Please confirm before proceeding with the merge',
  'duplicateLessonDescription.cancelMerge.confirmText':
    'Canceling the merge of lesson descriptions will only affect the lesson description IDs for lessons generated after the cancellation. Previously modified lesson data will remain unchanged. Please confirm before proceeding with the cancellation.',

  'activate.success': 'Activate Success',
  'deactivate.success': 'Deactivate Success',
  'edit.success': 'Edit Success',

  hasPeakOffPeak: 'Has Peak/Off-Peak Lessons',

  'navBar.billingInvoicesTitle': 'Billing Invoices',
  'menuBar.billingInvoicesTitle': 'Billing Invoices',

  'exchangeRates.exchangeRateChangeMode': 'Exchange Rate Change Mode',
  'exchangeRates.exchangeRate': 'Exchange Rate',
  'exchangeRates.exchangeRate.message': 'Please enter exchange rate',
  'exchangeRates.batchUpdateCredits': 'Batch Update Credits',
  'exchangeRates.batchUpdateCredits.message': 'Please enter batch update credits',
  'navBar.exchangeRatesStudiosTitle': 'Studios',
  'navBar.exchangeRatesLessonDescriptionsTitle': 'Lesson Descriptions',
  earlyVisitRateTips:
    'Early visit as a percentage of completed appointments in the past 30 to 7 days',
  costPerCreditTips:
    'Total user spending on completed courses/total points consumed in courses, in the past 30 to 7 days',
  exchangeRateTips:
    'The exchange rate between the price of the course template and the credits, calculated as: completed_class_price_cents/100/credits',
  fcfPercentageTips:
    'The proportion of the early visit price of the course template, calculated as: 100 - 100 * early_visit_price_cents / completed_class_price_cent',

  cityName: 'City Name',
  organizationName: 'Organization Name',
  studioName: 'Studio Name',
  lessonDescriptionName: 'Lesson Description Name',
  exchangeRate: 'Exchange Rate',
  fcfPercentage: 'FCF Percentage',
  earlyVisitRate: 'Early Visit Rate',
  totalPayout: 'Total Payout(yuan)',
  activities: 'Activities',
  credits: 'Credits',
  studioList: 'Studio List',
  viewStudioList: 'View Studio List',
  viewLessonDescriptionList: 'View Lesson Description List',
  'exchangeRates.update': 'Update Exchange Rates',
  'exchangeRates.updateSuccess': 'Update Exchange Rates Success',
  'exchangeRates.updateFailed': 'Update Exchange Rates Failed',
  'exchangeRates.exchangeRateRange': 'Exchange Rate Range',
  'exchangeRates.fcfPercentageRange': 'FCF Percentage Range',
  'exchangeRates.costPerCreditRange': 'Cost Per Credit Range',
  'exchangeRates.earlyVisitRateRange': 'Early Visit Rate Range',
  'exchangeRates.isIntegrator': 'Is Integrator',
  'exchangeRates.integratorUpdateTitle': 'Integrator Update',
  'exchangeRates.updateContent': 'Please confirm whether to continue to modify the exchange rate?',
  'exchangeRates.adjuestment.tips1':
    'The final score will be rounded up, for example, if the calculated score is 3.33, the final score will be 4 credits',
  'exchangeRates.adjuestment.tips2':
    'This modification will affect all lessons from now on and in the future, and will automatically update the peak/off-peak prices and the credits users see.',
  'exchangeRates.adjuestment.studioTips1':
    'The current studio is from a third party and the lesson description is also used by other studios. Modifying the exchange rate will affect the lesson description prices of other studios.',
  'exchangeRates.adjuestment.studioTips2':
    'The current studio is used by other studios. Modifying the exchange rate will affect the lesson description prices of other studios.',

  'exchangeRates.adjuestment.lessonTips1':
    'The current lesson description is used by other studios. Modifying the exchange rate will affect the lesson description prices of other studios.',
  'exchangeRates.adjuestment.lessonTips2':
    'The current lesson description is from a third party and is also used by other studios. Modifying the exchange rate will affect the lesson description prices of other studios.',

  'exchangeRates.fetchFailed': 'Failed to fetch exchange rates',
  tableActionColumn: 'Action',
  createdAt: 'Created At',
  isExpired: 'Is Expired',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  updatedAt: 'Updated At',
  edit: 'Edit',
  view: 'View',
  cancel: 'Cancel',
  continue: 'Continue',
  yes: 'Yes',
  no: 'No',
  search: 'Search',
  batchDownload: 'Batch Download',
  download: 'Download',
  reset: 'Reset',
  type: 'Type',
  locale: 'Locale',
  clickToUpload: 'Click to Upload',
  addNewTranslation: 'Add new translation',
  addNewTier: 'Add new tier',
  submit: 'Submit',
  networkError: 'Network Error. Please try again later.',
  operationSuccess: 'Operation Success',
  goBack: 'Go Back',
  userId: 'User ID',
  loginError: 'Login Error',
  duplicate: 'Duplicate',
  taggings: 'Taggings',
  editTaggings: 'Edit Taggings',
  isActive: 'Active',
  config: 'Config',
  status: 'Status',
  name: 'Name',
  funFact: 'Fun Fact',
  activityList: 'Activity List',
  label: 'Label',
  companyPaymentDetail: 'Company Payment Detail',
  confirm: 'Confirm',
  success: 'Success',
  error: 'Error',
  add: 'Add Item',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  previous: 'Previous',
  next: 'Next',
  rule: 'Rule',
  activate: 'Activate',
  deactivate: 'Deactivate',
  email: 'Email',
  'email.message': 'Please enter email',
  reject: 'Reject',
  exportSuccess: 'Export Success',
  exportError: 'Export Error',
  apiError: 'API Error',
  orderBy: 'Order By',
  'orderBy.message': 'Please select order by',
  orderDirection: 'Order Direction',
  ascending: 'Ascending',
  descending: 'Descending',
}

export default en
