import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getStudioIntegrationLessonDescriptionConfigDetail } from '../../../api/studioIntegrationConfigsApi'
import { IStudioIntegrationLessonDescriptionConfig } from '../share-types'
import StudioIntegrationLessonConfigDescriptions from '../../../components/studio-integration-configs/studio-integration-lesson-config-descriptions/studio-integration-lesson-config-descriptions'

const StudioIntegrationLessonDescConfig = () => {
  const params = useParams()
  const [lessonDescConfigId] = useState<string | undefined>(params?.lessonDescConfigId)
  const [studioIntegrationLessonDescriptionConfig, setStudioIntegrationLessonDescriptionConfig] =
    useState<IStudioIntegrationLessonDescriptionConfig>()

  useEffect(() => {
    if (lessonDescConfigId) {
      getStudioIntegrationLessonDescriptionConfigDetail(Number(lessonDescConfigId)).then(data => {
        setStudioIntegrationLessonDescriptionConfig(data)
      })
    }
  }, [lessonDescConfigId])

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" wrap size={16}>
        {studioIntegrationLessonDescriptionConfig && (
          <>
            <StudioIntegrationLessonConfigDescriptions
              data={studioIntegrationLessonDescriptionConfig}
            />
          </>
        )}
      </Space>
    </>
  )
}

export default StudioIntegrationLessonDescConfig
