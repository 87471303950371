import { IPaginatedParams, LocaleType, PageInfo } from '../../share-types'
import { ILessonDescription } from '../lesson-description-container/share-type'

export interface IBookingReviewsFilterParams {
  isAnonymous?: boolean
  isDeleted?: boolean
  startDate?: string
  endDate?: string
  stars?: number
  studioId?: number
  isOnlyViewAdminStudio?: boolean
  isOnlyViewTextAndPhotos?: boolean
}

export type IPaginatedBookingReviewsParams = {
  auditStatus: string
} & IPaginatedParams &
  IBookingReviewsFilterParams

export enum AuditStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum BookingReviewRatingEnum {
  STUDIO_ENVIRONMENT = 'STUDIO_ENVIRONMENT',
  LESSON_PROFESSIONAL_DEGREE = 'LESSON_PROFESSIONAL_DEGREE',
  SERVE_ATTITUDE = 'SERVE_ATTITUDE',
  OVERALL_SERVICE_LEVEL = 'OVERALL_SERVICE_LEVEL',
}

export interface IBookingReviewRating {
  id: number
  label: BookingReviewRatingEnum
  stars: number
  labelName: string
}

export interface IUserInfo {
  id: number
  email: string
  firstName: string
  realName: string
  phoneNumber: string
  avatarUrl: string
  uid: string
  isBlocked: boolean
}

export interface IStudioTranslateion {
  transitToStudioPath: string
  howToFindStudio: string
  address1: string
  address2: string
  description: string
  locale: string
  name: string
  specialNoteToUsers: string
  studioId: number
  announcement: string
}

export interface IStudio {
  id: number
  ratingScore: number
  ratingVotes: number
  phoneNumber: string
  createdAt: string
  studioTranslations: IStudioTranslateion[]
}

export interface ILesson {
  id: number
  startTime: string
  lessonDescriptionDto: ILessonDescription
  createdAt: string
  credits: number
}

export interface IBookingReviewImage {
  originalImageUrl: string
}

export interface IBookingReview {
  id: number
  comment: string
  bookingId: number
  auditStatus: AuditStatusEnum
  rejectReason: string | null
  isAnonymous: boolean
  isDeleted: boolean
  deleteReason: string | null
  bookingReviewRatings: IBookingReviewRating[]
  createdAt: string
  reviewedAt: string | null
  images: string[]
  lessonDto: ILesson
  studioDto: IStudio
  userDto: IUserInfo
  bookingReviewImages: IBookingReviewImage[]
}

export type PaginatedBookingReviewResponse = {
  pageInfo: PageInfo
  data: IBookingReview[]
}

export type IApproveBookingReviewParams = {
  status: AuditStatusEnum.APPROVED
}

export type IRejectBookingReviewParams = {
  status: AuditStatusEnum.REJECTED
  rejectReason: string
}

export type IBookingReviewAuditParams = IApproveBookingReviewParams | IRejectBookingReviewParams

export type IRejectReason = {
  id: number
  label: string
  translations: { locale: LocaleType; name: string }[]
}

export type IBookingReviewDeleteParams = {
  deleteReason: string
}
