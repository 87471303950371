import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './feature-flag.module.less'
import { IFeatureFlag } from '../share-type'
import { Outlet } from 'react-router-dom'
import { getFeatureFlagDetail } from '../../../api/featureFlagApi'
import FeatureFlagDescriptions from '../../../components/feature-flags/feature-flag-descriptions/feature-flag-descriptions'
import { keysToCamel } from '../../../helpers/keys-to-case'

const FeatureFlag = () => {
  const params = useParams()
  const [featureFlagId] = useState<string | undefined>(params?.id)
  const [featureFlag, setFeatureFlag] = useState<IFeatureFlag>()
  useEffect(() => {
    if (featureFlagId) {
      getFeatureFlagDetail(Number(featureFlagId)).then(res => {
        setFeatureFlag(keysToCamel(res))
      })
    }
  }, [featureFlagId])

  return (
    <Space className={styles['feature-flag-desc-wrapper']} direction="vertical" wrap size={16}>
      {featureFlag && <FeatureFlagDescriptions featureFlag={featureFlag} />}

      <Outlet />
    </Space>
  )
}

export default FeatureFlag
