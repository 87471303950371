import { Button, Modal, notification } from 'antd'
import { useState } from 'react'
import translate from '../../../i18n'
import { CorporateCreditsRequest, ICorporate } from '../../../pages/corporates-container/share-type'
import { adjustCorporateCredits } from '../../../api/couponApi'
import CreditsNotesForm from '../credits-notes-form/credits-notes-form'

interface IProps {
  adjustmentCorporate: ICorporate
  visiable: boolean
  onCancel: () => void
  onSubmit: () => void
}

const CorporateCreditsModal = (props: IProps) => {
  const { adjustmentCorporate, visiable, onCancel, onSubmit } = props
  const [loading, setLoading] = useState<boolean>(false)

  const onCorporateCreditsAdjustmentFinish = (value: CorporateCreditsRequest) => {
    if (!adjustmentCorporate) {
      return
    }
    setLoading(true)
    adjustCorporateCredits(adjustmentCorporate.id, value)
      .then(() => {
        const message =
          value.credits > 0
            ? translate('corporate.credits.payment')
            : translate('corporate.credits.adjustment')
        const description =
          value.credits > 0
            ? translate('corporate.credits.paymentSuccess')
            : translate('corporate.credits.adjustedSuccess')
        notification.success({
          message: message,
          description: description,
        })
        onSubmit()
      })
      .catch(err => {
        const message =
          value.credits > 0
            ? translate('corporate.credits.payment')
            : translate('corporate.credits.adjustment')
        const description =
          value.credits > 0
            ? translate('corporate.credits.paymentFailed')
            : translate('corporate.credits.adjustedFailed')
        notification.error({
          message: message,
          description: err?.message || description || translate('networkError'),
        })
      })
      .finally(() => {
        setLoading(false)
        onCancel()
      })
  }

  return (
    <Modal
      centered
      open={visiable}
      footer={null}
      width={800}
      forceRender
      title={translate('corporate.credits.adjustment')}
      onCancel={onCancel}
    >
      <CreditsNotesForm
        loading={loading}
        onFinish={onCorporateCreditsAdjustmentFinish}
        footerButton={<Button onClick={onCancel}>取消</Button>}
        originalCredits={adjustmentCorporate?.credits}
        isTrialCorporate={adjustmentCorporate?.isTrial || false}
      />
    </Modal>
  )
}

export default CorporateCreditsModal
