import { Select, Space } from 'antd'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ReduxStore } from '../../../store'
import { useEffect, useState } from 'react'
import styles from './billing-summaries.module.less'

import { IStudio } from '../../../pages/studios-container/share-type'
import BillingSummary from '../../components/billing-summaries/billing-summary/billing-summary'
import BillingSummaryDetails from '../../components/billing-summaries/billing-summary-details/billing-summary-details'

const BillingSummaries = (props: ConnectedProps<typeof withConnect>) => {
  const { studioAdminUserInfo } = props
  const [studiosOptions, setStudiosOptions] = useState([])
  const [selectedStudioIds, setSelectedStudioIds] = useState<number[]>([])

  useEffect(() => {
    if (studioAdminUserInfo && studioAdminUserInfo.studios) {
      const options = studioAdminUserInfo.studios.map((studio: IStudio) => {
        const studioTranslation = studio.studioTranslations.find(
          studioTranslation => studioTranslation.locale === 'zh-CN',
        )

        const studioName = studioTranslation
          ? studioTranslation.name
          : studio.studioTranslations.length > 0
            ? studio.studioTranslations[0].name
            : ''

        return {
          label: `${studio.id}-${studioName}`,
          value: studio.id,
        }
      })

      setStudiosOptions(options)
    }
  }, [studioAdminUserInfo])

  const onClickSelectStudioId = (value: number[]) => {
    setSelectedStudioIds(value)
  }

  return (
    <Space style={{ width: '100%' }} direction="vertical" size={18}>
      <Space size={24}>
        <div className="studios-area-page-title">结算账单</div>
        {studioAdminUserInfo && (
          <Select
            mode="multiple"
            allowClear
            className={styles['billing-summaries-studios-select']}
            options={studiosOptions}
            onChange={onClickSelectStudioId}
            placeholder="请选择工作室"
          />
        )}
      </Space>

      {studioAdminUserInfo && (
        <>
          <BillingSummary studioIds={selectedStudioIds} studioAdminUserInfo={studioAdminUserInfo} />
          <BillingSummaryDetails
            studioIds={selectedStudioIds}
            studioAdminUserInfo={studioAdminUserInfo}
          />
        </>
      )}
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    studioAdminUserInfo: state.studioAdminUserInfo,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(BillingSummaries)
