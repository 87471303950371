import { SET_REFERRAL_CAMPAIGNS } from './constants'
import { ReferralCampaignsAction } from './action'
import update from 'immutability-helper'
import { IReferralCampaign } from '../../pages/referral-campaign-container/share-type'

interface InitialState {
  referralCampaigns: IReferralCampaign[]
}

const initialState: InitialState = {
  referralCampaigns: [],
}

function referralCampaignsReducer(state = initialState, action: ReferralCampaignsAction) {
  switch (action.type) {
    case SET_REFERRAL_CAMPAIGNS:
      return update(state, { $merge: { referralCampaigns: action.payload } })
    default:
      return state
  }
}

export default referralCampaignsReducer
