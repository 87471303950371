import { Button, Form, Modal, Space, Upload, notification } from 'antd'
import translate from '../../../i18n'
import { UploadOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import { FormattedMessage } from '../../intl'
import styles from './batch-deactivate-members-modal.module.less'
import { useState } from 'react'
import { IBatchDeactiveMemberFormValue } from '../../../pages/corporates-container/share-type'
import * as _ from 'lodash'
import { deactivateMembersBulkUploadByCorporateId } from '../../../api/corporateApi'

interface BatchDeactivateMembersModalProps {
  corporateId: number
  open: boolean
  handleCancel: () => void
  handleSubmit: () => void
}

const BatchDeactivateMembersModal = (props: BatchDeactivateMembersModalProps) => {
  const { open, handleCancel, corporateId, handleSubmit } = props
  const [form] = Form.useForm()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  const onFinish = (values: IBatchDeactiveMemberFormValue) => {
    if (corporateId) {
      const data = new FormData()
      if (values.file && values.file.length) {
        data.append('file', values.file[0].originFileObj)
      }
      if (values.phoneNumbers) {
        const phoneNumbers = _.split(values.phoneNumbers, ',')
        data.append('phoneNumbers', JSON.stringify(phoneNumbers))
      }

      data.append('notes', values.notes || '')

      setLoadingButton(true)
      deactivateMembersBulkUploadByCorporateId(corporateId, data)
        .then(res => {
          setLoadingButton(false)
          if (res.isSuccess) {
            notification.success({
              message: translate('corporate.batchDeactivateMembers.success'),
            })
            handleSubmit()
          }
        })
        .catch(err => {
          setLoadingButton(false)
          notification.error({
            message: translate('corporate.batchDeactivateMembers.failed'),
            description: err.message ?? translate('networkError'),
          })
        })
    }
  }

  return (
    <Modal
      title={translate('corporate.batchDeactivateMembers')}
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      width={800}
    >
      <Form
        layout="horizontal"
        name="batch_distribute_credits"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ deactivate: false }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="file"
          label={translate('corporate.phoneNumber.bulkUpload')}
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="phoneNumbers" label={translate('corporate.phoneNumber.specify')}>
          <TextArea placeholder="多个用户phone number，使用,隔开" showCount />
        </Form.Item>

        <Form.Item name="notes" label={translate('corporate.notes')}>
          <TextArea placeholder={translate('corporate.notes.placeholder')} showCount />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Space className={styles['button-group']}>
            <Button loading={loadingButton} type="primary" htmlType="submit">
              <FormattedMessage id="submit" />
            </Button>
            <Button loading={loadingButton} onClick={handleCancel}>
              <FormattedMessage id="cancel" />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BatchDeactivateMembersModal
