import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import translate from '../../i18n'

const LessonCreditAdjustmentsContainer = () => {
  const getNavBarTitle = () => {
    const pathName = location.pathname

    if (pathName.match(/^\/lesson-credit-adjustments\/[0-9]+$/)) {
      return translate('navBar.viewLessonCreditAdjustmentsTitle')
    }

    if (pathName.match(/^\/lesson-credit-adjustments\/new$/)) {
      return translate('navBar.newLessonCreditAdjustmentsTitle')
    }

    return translate('navBar.lessonCreditAdjustmentsTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default LessonCreditAdjustmentsContainer
