import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './tag.module.less'
import { ITag } from '../share-type'
import { Outlet } from 'react-router-dom'
import { getTagDetail } from '../../../api/tagsApi'
import TagDescriptions from '../../../components/tags/tag-descriptions/tag-descriptions'

const Tag = () => {
  const params = useParams()
  const [tagId] = useState<string | undefined>(params?.id)
  const [tag, setTag] = useState<ITag>()
  useEffect(() => {
    if (tagId) {
      getTagDetail(Number(tagId)).then(res => {
        setTag(res)
      })
    }
  }, [tagId])

  return (
    <Space className={styles['tag-desc-wrapper']} direction="vertical" wrap size={16}>
      {tag && <TagDescriptions tag={tag} />}

      <Outlet />
    </Space>
  )
}

export default Tag
