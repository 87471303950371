import React from 'react'
import { Button, Result } from 'antd'
import translate from '../../i18n'
import { useNavigate } from 'react-router-dom'

const NoMatch = () => {
  const navigate = useNavigate()
  return (
    <Result
      status="404"
      title="404"
      subTitle={translate('noMatchPageDesc')}
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          {translate('goBackButton')}
        </Button>
      }
    />
  )
}

export default NoMatch
