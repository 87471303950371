import moment from 'moment'

import config from '../config/index'
import {
  IBillingInvoiceExportParams,
  IBillingInvoiceStatusChangeParams,
  IPaginatedBillingInvoiceFilterParams,
  PaginatedBillingInvoicesResponse,
  PresignedGetUrlResponse,
} from '../pages/billing-invoices-container/share-type'
import { authRequest } from '../request'
import { downloadFile } from '../request/utils'

const { mainApi } = config

export const getBillingInvoices = (params: IPaginatedBillingInvoiceFilterParams) =>
  authRequest.get<MISSING_TYPE, PaginatedBillingInvoicesResponse>(`${mainApi}/billing-invoices`, {
    params,
  })

export const changeBillingInvoiceStatus = (
  billingInvoiceId: number,
  data: IBillingInvoiceStatusChangeParams,
) => authRequest.put(`${mainApi}/billing-invoices/${billingInvoiceId}/change-status`, { ...data })

export const getBillingInvoiceDownloadUrl = (id: number) =>
  authRequest.get<MISSING_TYPE, PresignedGetUrlResponse>(
    `${mainApi}/billing-invoices/${id}/download-url`,
  )

export const exportBillingInvoices = (params: IBillingInvoiceExportParams) => {
  const startTime = moment(params.startTime).format('YYYY-MM-DD')
  const endTime = moment(params.endTime).format('YYYY-MM-DD')
  const organizationId = params.organizationId?.toString() || ''
  let fileName = `billing-invoices-${startTime}-${endTime}`
  if (organizationId) {
    fileName += `-organizationId-${organizationId}`
  }
  fileName += '.zip'
  return downloadFile(`${mainApi}/billing-invoices/export`, params, fileName)
}
