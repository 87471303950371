import { CardActionType, CardConfigTypeEnum } from '../pages/card-stacks-container/share-type'
import {
  AdvertisementCardConfig,
  CouponCardConfig,
  FirstClassDiscountedStudioCardConfig,
  FrequentlyBookedLessonCardConfig,
  IconCardConfig,
  LessonDescriptionCardConfig,
  StudioCardConfig,
  StudioListCardConfig,
  TitleSubtitleIconCardConfig,
  UpcomingLessonCardConfig,
} from './card-config'

const advertisementCardConfig = new AdvertisementCardConfig()
const iconCardConfig = new IconCardConfig()
const lessonDescriptionCardConfig = new LessonDescriptionCardConfig()
const studioCardConfig = new StudioCardConfig()
const studioListCardConfig = new StudioListCardConfig()
const upcomingLessonCardConfig = new UpcomingLessonCardConfig()
const frequentlyBookedLessonCardConfig = new FrequentlyBookedLessonCardConfig()
const titleSubtitleIconCardConfig = new TitleSubtitleIconCardConfig()
const couponCardConfig = new CouponCardConfig()
const firstClassDiscountedStudioCardConfig = new FirstClassDiscountedStudioCardConfig()

export type ICardConfigFormValues = {
  id?: number
  name: string
  cardStackId: number
  cardType: CardConfigTypeEnum
  rank?: number
  bannerUrl?: string
  bannerUrlTranslation?: string
  bannerBiggerUrl?: string
  bannerBiggerUrlTranslation?: string
  contentStrategy?: string
  lessonDescriptionIds: number[]
  max?: number
  studioIds: number[]
  imageUrl?: string
  imageUrlTranslation?: string
  title?: string
  titleTranslation?: string
  cityIds?: number[]
  tagText?: string
  tagTextTranslation?: string
  tagBgColor?: string
  actionUrl?: string
  actionType?: CardActionType
  actionText?: string
  actionTextTranslation?: string
}

export const getCardConfigFormData = (values: MISSING_TYPE) => {
  const {
    cardType,
    rank,
    bannerUrl,
    bannerUrlTranslation,
    bannerBiggerUrl,
    bannerBiggerUrlTranslation,
    contentStrategy,
    lessonDescriptionIds,
    max,
    studioIds,
    imageUrl,
    imageUrlTranslation,
    iconFont,
    title,
    titleTranslation,
    cityIds,
    tagText,
    tagTextTranslation,
    tagBgColor,
    actionUrl,
    actionType,
    actionText,
    actionTextTranslation,
    description,
    descriptionTranslation,
    backgroundColor,
    couponTemplateId,
    ...other
  } = values
  let config: any
  if (cardType === CardConfigTypeEnum.ADVERTISEMENT) {
    advertisementCardConfig.rank = rank
    advertisementCardConfig.content = {
      title: title ? title : '',
      titleTranslation: titleTranslation ? titleTranslation : '',
      description: description ? description : '',
      descriptionTranslation: descriptionTranslation ? descriptionTranslation : '',
      bannerUrl,
      bannerUrlTranslation,
      bannerBiggerUrlTranslation,
      bannerBiggerUrl,
      backgroundColor,
    }

    advertisementCardConfig.action = { actionUrl, actionType, actionText, actionTextTranslation }

    config = advertisementCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.ICON) {
    iconCardConfig.rank = rank
    iconCardConfig.iconFont = iconFont
    iconCardConfig.content = { imageUrl, imageUrlTranslation, title, titleTranslation }
    iconCardConfig.action = { actionUrl, actionType, actionText, actionTextTranslation }
    config = iconCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.TITLE_SUBTITLE_ICON) {
    titleSubtitleIconCardConfig.rank = rank
    titleSubtitleIconCardConfig.content = {
      imageUrl,
      title,
      titleTranslation,
      description,
      descriptionTranslation,
    }
    titleSubtitleIconCardConfig.action = {
      actionUrl,
      actionType,
      actionText,
      actionTextTranslation,
    }

    config = titleSubtitleIconCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.LESSON_DESCRUPTION) {
    lessonDescriptionCardConfig.contentStrategy = { contentStrategy, lessonDescriptionIds }
    config = lessonDescriptionCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.STUDIO) {
    studioCardConfig.contentStrategy = { contentStrategy, studioIds, max }
    config = studioCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.STUDIO_LIST) {
    studioListCardConfig.rank = rank
    studioListCardConfig.cityIds = cityIds
    studioListCardConfig.title = title
    studioListCardConfig.titleTranslation = titleTranslation
    studioListCardConfig.tagBgColor = tagBgColor
    studioListCardConfig.tagText = tagText
    studioListCardConfig.tagTextTranslation = tagTextTranslation
    studioListCardConfig.action = { actionUrl, actionType, actionText, actionTextTranslation }
    studioListCardConfig.contentStrategy = { contentStrategy, studioIds, max }
    config = studioListCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.UPCOMING_LESSONS) {
    config = upcomingLessonCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS) {
    frequentlyBookedLessonCardConfig.max = max
    config = frequentlyBookedLessonCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.COUPON_CARD) {
    couponCardConfig.content = { imageUrl, imageUrlTranslation }
    couponCardConfig.action = { actionUrl, actionType, actionText, actionTextTranslation }
    couponCardConfig.contentStrategy = { couponTemplateId }
    config = couponCardConfig.getParams()
  }

  if (cardType === CardConfigTypeEnum.FIRST_CLASS_DISCOUNTED_STUDIO_CARD) {
    firstClassDiscountedStudioCardConfig.max = max
    config = firstClassDiscountedStudioCardConfig.getParams()
  }

  return {
    config,
    ...other,
  }
}

export const setFieldsCardConfigFormValue = (values: MISSING_TYPE): ICardConfigFormValues => {
  const { config, cardStack, ...other } = values
  let splitConfig
  let cardType: CardConfigTypeEnum
  switch (config['@class']) {
    case advertisementCardConfig.className:
      cardType = CardConfigTypeEnum.ADVERTISEMENT
      splitConfig = advertisementCardConfig.setFieldsValue(config)
      break
    case iconCardConfig.className:
      cardType = CardConfigTypeEnum.ICON
      splitConfig = iconCardConfig.setFieldsValue(config)
      break
    case titleSubtitleIconCardConfig.className:
      cardType = CardConfigTypeEnum.TITLE_SUBTITLE_ICON
      splitConfig = titleSubtitleIconCardConfig.setFieldsValue(config)
      break
    case lessonDescriptionCardConfig.className:
      cardType = CardConfigTypeEnum.LESSON_DESCRUPTION
      splitConfig = lessonDescriptionCardConfig.setFieldsValue(config)
      break
    case studioCardConfig.className:
      cardType = CardConfigTypeEnum.STUDIO
      splitConfig = studioCardConfig.setFieldsValue(config)
      break
    case studioListCardConfig.className:
      cardType = CardConfigTypeEnum.STUDIO_LIST
      splitConfig = studioListCardConfig.setFieldsValue(config)
      break
    case upcomingLessonCardConfig.className:
      cardType = CardConfigTypeEnum.UPCOMING_LESSONS
      splitConfig = upcomingLessonCardConfig.setFieldsValue(config)
      break
    case couponCardConfig.className:
      cardType = CardConfigTypeEnum.COUPON_CARD
      splitConfig = couponCardConfig.setFieldsValue(config)
      break
    case firstClassDiscountedStudioCardConfig.className:
      cardType = CardConfigTypeEnum.FIRST_CLASS_DISCOUNTED_STUDIO_CARD
      splitConfig = firstClassDiscountedStudioCardConfig.setFieldsValue(config)
      break
    case frequentlyBookedLessonCardConfig.className:
    default:
      cardType = CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS
      splitConfig = frequentlyBookedLessonCardConfig.setFieldsValue(config)
      break
  }

  return {
    cardStackId: cardStack.id,
    cardType,
    ...splitConfig,
    ...other,
  }
}
