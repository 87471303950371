import { Button, Form, Select, Input, DatePicker } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import moment from 'moment'
import { IOneTimeCodesFilterParams } from '../../../pages/one-time-codes-container/share-type'

const { Option } = Select
const { RangePicker } = DatePicker
const OneTimeCodesFilter = ({
  onSearch,
  formData,
  isNeedBatchId,
}: {
  onSearch: (value: IOneTimeCodesFilterParams) => void
  formData: IOneTimeCodesFilterParams | undefined
  isNeedBatchId?: boolean
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        timeRange:
          formData.startDate && formData.endDate
            ? [moment(formData.startDate), moment(formData.endDate)]
            : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: any) => {
    const { timeRange, ...other } = values
    onSearch({
      ...other,
      startDate: timeRange ? moment(timeRange[0]).utc().format() : undefined,
      endDate: timeRange ? moment(timeRange[1]).utc().format() : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" style={{ gap: '8px' }} onFinish={onFinish}>
      {isNeedBatchId && (
        <Form.Item name="batchId" label="BatchId">
          <Input />
        </Form.Item>
      )}
      <Form.Item name="code" label="Code">
        <Input />
      </Form.Item>

      <Form.Item name="isRedeemed" label={translate('oneTimeCode.isRedeemed')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="redeemedById" label={translate('oneTimeCode.redeemedBy')}>
        <Input />
      </Form.Item>

      <Form.Item name="timeRange" label={translate('oneTimeCode.timeRange')}>
        <RangePicker
          showTime={{
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {translate('search')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default OneTimeCodesFilter
