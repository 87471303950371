import { Table } from 'antd'
import {
  ISearchTrend,
  LessonDescriptionSuggestionDto,
  SearchTrendRankerDto,
  SearchTrendableTypeEnums,
  StudioSuggestionDto,
} from '../../../pages/search-trends-container/share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'

const SearchTrendRankers = ({ searchTrend }: { searchTrend: ISearchTrend }) => {
  const defaultColumns: ColumnsType<SearchTrendRankerDto> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: translate('searchTrends.rankers.rank'),
      dataIndex: 'rank',
      key: 'rank',
    },
  ]

  const baseColumns: ColumnsType<SearchTrendRankerDto> = [
    {
      title: translate('searchTrends.rankers.clicksCount'),
      dataIndex: 'clicksCount',
      key: 'clicksCount',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
  ]
  const studioColumns: ColumnsType<SearchTrendRankerDto> = defaultColumns.concat(
    [
      {
        title: translate('searchTrends.rankers.studioId'),
        dataIndex: 'studio',
        key: 'studioId',
        render: (studio: StudioSuggestionDto) => studio.id,
      },
      {
        title: translate('searchTrends.rankers.studioName'),
        dataIndex: 'studio',
        key: 'studioName',
        render: (studio: StudioSuggestionDto) => studio.name,
      },
    ],
    baseColumns,
  )

  const lessonDescriptionColumns: ColumnsType<SearchTrendRankerDto> = defaultColumns.concat(
    [
      {
        title: translate('searchTrends.rankers.lessonDescriptionId'),
        dataIndex: 'lessonDescription',
        key: 'lessonDescriptionId',
        render: (lessonDescription: LessonDescriptionSuggestionDto) => lessonDescription.id,
      },
      {
        title: translate('searchTrends.rankers.lessonDescriptionName'),
        dataIndex: 'lessonDescription',
        key: 'lessonDescriptionName',
        render: (lessonDescription: LessonDescriptionSuggestionDto) => lessonDescription.name,
      },
    ],
    baseColumns,
  )

  return (
    <Table
      rowKey={record => record.id}
      columns={
        searchTrend.trendableType === SearchTrendableTypeEnums.STUDIOS
          ? studioColumns
          : lessonDescriptionColumns
      }
      dataSource={searchTrend.ranks}
    />
  )
}

export default SearchTrendRankers
