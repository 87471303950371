import React, { useEffect, useState } from 'react'
import {
  ILessonCreditPricing,
  ILessonCreditPricingFilterParams,
  IPaginatedLessonCreditPricingFilterParams,
  ISimpleLesson,
  LessonCreditPricingAdjustment,
  PaginatedLessonCreditPricingResponse,
} from '../share-type'
import { PageInfo } from '../../../share-types'
import { useSearchParams } from 'react-router-dom'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import { getPaginatedLessonCreditPricings } from '../../../api/lessonCreditPricingApi'
import Table, { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { Button, Drawer, Space } from 'antd'
import styles from './lesson-credit-pricings.module.less'
import LessonCreditPricingFilter from '../../../components/lesson-credit-pricings/lesson-credit-pricing-filter/lesson-credit-pricing-filter'
import config from '../../../config/index'
import LinkButton from '../../../components/link-button/link-button'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const { guavapassUrl } = config

const LessonCreditPricings = () => {
  const [lessonCreditPricings, setLessonCreditPricings] = useState<ILessonCreditPricing[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<ILessonCreditPricingFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [openAdjustmentsDrawer, setOpenAdjustmemtsDrawer] = useState<boolean>(false)
  const [lessonCreditPricing, setLessonCreditPricing] = useState<ILessonCreditPricing>()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'lessonIds', isBoolean: false, isArray: true },
        { key: 'lessonDescriptionId', isBoolean: false },
        { key: 'studioId', isBoolean: false },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedLessonCreditPricingsAction(data)
  }, [])

  const getPaginatedLessonCreditPricingsAction = (
    data: IPaginatedLessonCreditPricingFilterParams,
  ) => {
    getPaginatedLessonCreditPricings(data).then((res: PaginatedLessonCreditPricingResponse) => {
      if (res) {
        setLessonCreditPricings(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const onSearch = (values: ILessonCreditPricingFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo.pageSize,
      ...values,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(values))
    getPaginatedLessonCreditPricingsAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedLessonCreditPricingsAction(data)
  }

  const viewLessonCreditPricig = (record: ILessonCreditPricing) => {
    setLessonCreditPricing(record)
    setOpenAdjustmemtsDrawer(true)
  }

  const closeDrawer = () => {
    setLessonCreditPricing(undefined)
    setOpenAdjustmemtsDrawer(false)
  }

  const columns: ColumnsType<ILessonCreditPricing> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('lessonCreditPricing.lesson'),
      dataIndex: 'lesson',
      key: 'lesson',
      render: (lesson: ISimpleLesson) => (
        <Button type="link" target="_black" href={`${guavapassUrl}/admin/lessons/${lesson.id}`}>
          {lesson.id}
        </Button>
      ),
    },
    {
      title: translate('lessonCreditPricing.lessonDescription'),
      dataIndex: 'lesson',
      key: 'lessonDescriptionId',
      width: 100,
      render: (lesson: ISimpleLesson) => (
        <Button
          type="link"
          target="_black"
          href={`${guavapassUrl}/admin/lesson_descriptions/${lesson.lessonDescriptionId}`}
        >
          {lesson.lessonDescriptionId}
        </Button>
      ),
    },
    {
      title: translate('lessonCreditPricing.studio'),
      dataIndex: 'lesson',
      key: 'studioId',
      render: (lesson: ISimpleLesson) => (
        <Button
          type="link"
          target="_black"
          href={`${guavapassUrl}/admin/studios/${lesson.studioId}`}
        >
          {lesson.studioId}
        </Button>
      ),
    },
    {
      title: translate('lessonCreditPricing.originalCredits'),
      dataIndex: 'lesson',
      key: 'originalCredits',
      render: (lesson: ISimpleLesson) => lesson.credits,
    },
    {
      title: translate('lessonCreditPricing.adjustedCredits'),
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: translate('lessonCreditPricing.lessonStartTime'),
      dataIndex: 'lesson',
      key: 'startTime',
      render: (lesson: ISimpleLesson) => getDateTime(lesson.startTime),
    },
    {
      title: translate('lessonCreditPricing.lessonEndTime'),
      dataIndex: 'lesson',
      key: 'endTime',
      render: (lesson: ISimpleLesson) => {
        const endTime = new Date(lesson.startTime).getTime() + lesson.duration * 60 * 1000
        return getDateTime(endTime)
      },
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: ILessonCreditPricing) => (
        <Button type="link" onClick={() => viewLessonCreditPricig(record)}>
          {translate('lessonCreditPricing.adjustments.view')}
        </Button>
      ),
    },
  ]

  const adjustmentColumns: ColumnsType<LessonCreditPricingAdjustment> = [
    {
      title: 'ID',
      dataIndex: 'lessonCreditAdjustmentId',
      key: 'lessonCreditAdjustmentId',
      width: 100,
      render: (lessonCreditAdjustmentId: number) => (
        <LinkButton to={`/lesson-credit-adjustments/${lessonCreditAdjustmentId}`}>
          {lessonCreditAdjustmentId}
        </LinkButton>
      ),
    },
    {
      title: translate('lessonCreditAdjustments.selectAdjustmentType'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: translate('lessonCreditAdjustments.adjustCredits'),
      dataIndex: 'credits',
      key: 'credits',
    },
  ]

  return (
    <Space className={styles['lesson-credit-pricing-space']} direction="vertical" size={16}>
      <div className={styles['filter-bar']}>
        <LessonCreditPricingFilter onSearch={onSearch} formData={searchValues} />
      </div>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={lessonCreditPricings}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />

      <Drawer
        open={openAdjustmentsDrawer}
        onClose={closeDrawer}
        title={translate('lessonCreditPricing.adjustments.view')}
        placement="right"
        width={1200}
      >
        <Table
          rowKey={record => record.lessonCreditAdjustmentId}
          columns={adjustmentColumns}
          dataSource={lessonCreditPricing?.adjustments}
          pagination={false}
        />
      </Drawer>
    </Space>
  )
}

export default LessonCreditPricings
