import { Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './organization.module.less'
import { getOrganizationDetail } from '../../../api/organizationsApi'
import { IOrganization } from '../share-type'
import OrganizationDescriptions from '../../../components/organizations/organization-descriptions/organization-descriptions'
import BillingInvoicesList from '../../../components/billing-invoices/billing-invoices-list/billing-invoices-list'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'

const { Title } = Typography

const Organization = () => {
  const params = useParams()
  const [organizationId] = useState<string | undefined>(params?.id)
  const [organization, setOrganization] = useState<IOrganization>()
  useEffect(() => {
    loadOrganization()
  }, [organizationId])

  const loadOrganization = () => {
    if (organizationId) {
      getOrganizationDetail(Number(organizationId)).then(res => {
        setOrganization(res)
      })
    }
  }

  return (
    <Space className={styles['organization-desc-wrapper']} direction="vertical" wrap size={16}>
      {organization && <OrganizationDescriptions organization={organization} />}

      <AuthPermission permission={PermissionsEnum.BILLING_INVOICES_READ}>
        {organization?.id && (
          <Space
            className={styles['organization-desc-wrapper']}
            direction="vertical"
            wrap
            size={16}
          >
            <Title level={5}>发票相关</Title>
            <BillingInvoicesList organizationId={organization.id} showOrganizationColumn={false} />
          </Space>
        )}
      </AuthPermission>
    </Space>
  )
}

export default Organization
