import { Button, Descriptions, Modal, Space, Tag, Typography, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'
import translate from '../../../i18n'
import { getDateTime } from '../../../helpers/utils'
import { IOneTimeCodeBatch } from '../../../pages/one-time-code-batches-container/share-type'
import {
  CHAR_CODE_0,
  CHAR_CODE_Z,
  EXPORT_PASSORD_LENGTH,
  OneTimeCodeStatusOptions,
} from '../../../pages/one-time-code-batches-container/constant'
import { exportOneTimeCodes } from '../../../api/oneTimeCodeApi'
import { useEffect, useState } from 'react'
import FormattedMessage from '../../intl/format-message'

const { Paragraph } = Typography

interface Iprops {
  oneTimeCodeBatch: IOneTimeCodeBatch
}
const CorporateDescriptions = (props: Iprops) => {
  const { oneTimeCodeBatch } = props
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const [password, setPassword] = useState<string>('')
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (open) {
      setPassword(generateRandomString(EXPORT_PASSORD_LENGTH))
    }
  }, [open])

  const toEdit = () => {
    navigate(`/one-time-code-batches/${oneTimeCodeBatch?.id}/edit`)
  }

  const generateRandomString = (length: number) => {
    let result = ''
    for (let i = 0; i < length; i++) {
      const charCode = Math.floor(Math.random() * (CHAR_CODE_Z - CHAR_CODE_0 + 1)) + CHAR_CODE_0
      result += String.fromCharCode(charCode)
    }
    return result
  }

  const toExport = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    exportOneTimeCodes(oneTimeCodeBatch.id, password)
      .then(res => {
        if (res) {
          notification.success({
            message: translate('oneTimeCodeBatches.exportSuccess'),
          })
        }
      })
      .catch(err => {
        notification.error({
          message: translate('oneTimeCodeBatches.exportError'),
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        setConfirmLoading(false)
      })
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title={translate('oneTimeCodeBatches.detail')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
          extra={
            <Space>
              <AuthPermission permission={PermissionsEnum.ONE_TIME_CODES_WRITE}>
                <Button onClick={toEdit} type="primary">
                  {translate('edit')}
                </Button>
              </AuthPermission>
              <AuthPermission permission={PermissionsEnum.ONE_TIME_CODES_WRITE}>
                <Button onClick={toExport} type="link">
                  {translate('oneTimeCodeBatches.export')}
                </Button>
              </AuthPermission>
            </Space>
          }
        >
          <Descriptions.Item label="ID">{oneTimeCodeBatch.id}</Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.name')}>
            {oneTimeCodeBatch.name}
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.credits')}>
            {oneTimeCodeBatch.credits}
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.creditsValidityPeriodInDays')}>
            {oneTimeCodeBatch.creditsValidityPeriodInDays}
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.isNewUserOnly')}>
            <YesOrNoTag value={oneTimeCodeBatch.isNewUserOnly} />
          </Descriptions.Item>

          <Descriptions.Item label={translate('oneTimeCodeBatches.timeRange')}>
            {oneTimeCodeBatch.usableWindowStartTime && oneTimeCodeBatch.usableWindowEndTime
              ? `${getDateTime(oneTimeCodeBatch.usableWindowStartTime)} ~ ${getDateTime(
                  oneTimeCodeBatch.usableWindowEndTime,
                )}`
              : '无'}
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.ikeaSegment')}>
            {oneTimeCodeBatch.ikeaSegmentId}
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.status')}>
            <Tag
              color={
                OneTimeCodeStatusOptions.find(item => item.value === oneTimeCodeBatch.status)?.color
              }
            >
              {OneTimeCodeStatusOptions.find(item => item.value === oneTimeCodeBatch.status)?.label}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.numberOfCodesClaimed')}>
            {oneTimeCodeBatch.numberOfCodesClaimed}
          </Descriptions.Item>
          <Descriptions.Item label={translate('oneTimeCodeBatches.numberOfCodesCreated')}>
            {oneTimeCodeBatch.numberOfCodesCreated}
          </Descriptions.Item>
          <Descriptions.Item label={translate('createdAt')}>
            {getDateTime(oneTimeCodeBatch.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label={translate('updatedAt')}>
            {getDateTime(oneTimeCodeBatch.updatedAt)}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <Modal
        title={translate('oneTimeCodeBatches.exportConfirm')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <FormattedMessage id="oneTimeCodeBatches.exportPassword" />
          <Paragraph copyable>{password}</Paragraph>
        </div>
      </Modal>
    </>
  )
}

export default CorporateDescriptions
