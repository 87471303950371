import qs from 'qs'

import config from '../config/index'
import { authRequest } from '../request'
import {
  BillingDetailQueryParams,
  BillingSummaryQueryParams,
  BillingSummaryResponse,
  IPaginatedBillingDetailDto,
} from '../studios-area/pages/billing-summaries/share-type'

const { mainApi } = config

export const getBillingSummary = (params: BillingSummaryQueryParams) => {
  return authRequest.get<MISSING_TYPE, BillingSummaryResponse>(
    `${mainApi}/studios-area/billing-summary`,
    {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )
}

export const getBillingDetails = (params: BillingDetailQueryParams) => {
  return authRequest.get<MISSING_TYPE, IPaginatedBillingDetailDto>(
    `${mainApi}/studios-area/billing-details`,
    {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )
}

export const exportBillingDetails = (params: BillingSummaryQueryParams) => {
  return authRequest.get<MISSING_TYPE, string>(`${mainApi}/studios-area/billing-details/export`, {
    params,
  })
}
