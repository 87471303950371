import { keysToSnake } from './keys-to-case'

class CouponRule {
  name: string
  params: { [key: string]: any }
  constructor(name: string) {
    this.name = name
    this.params = {
      '@class': `com.classpass.api.entities.coupons.${this.name}`,
    }
  }

  getParams() {
    return this.params
  }
}

export class UsageLimitRule extends CouponRule {
  _usageLimit: number | undefined
  constructor(usageLimit?: number) {
    super('UsageLimitRule')
    this.usageLimit = usageLimit
  }

  get usageLimit() {
    return this._usageLimit
  }
  set usageLimit(usageLimit) {
    this._usageLimit = usageLimit
  }

  getParams() {
    return {
      ...this.params,
      ...keysToSnake({
        usageLimit: this.usageLimit,
      }),
    }
  }
}

export class NewUserOnlyRule extends CouponRule {
  constructor() {
    super('NewUserOnlyRule')
  }
}

export class OldUserOnlyRule extends CouponRule {
  constructor() {
    super('OldUserOnlyRule')
  }
}

export class CitySpecificRule extends CouponRule {
  _cityIds: number[] | undefined
  constructor(cityIds?: number[]) {
    super('CitySpecificRule')
    this.cityIds = cityIds
  }

  get cityIds() {
    return this._cityIds
  }

  set cityIds(cityIds) {
    this._cityIds = cityIds
  }

  getParams() {
    return {
      ...this.params,
      ...keysToSnake({
        cityIds: this.cityIds,
      }),
    }
  }
}

export class UserSpecificRule extends CouponRule {
  _userIds: number[] | undefined
  constructor() {
    super('UserSpecificRule')
  }

  get userIds() {
    return this._userIds
  }

  set userIds(userIds) {
    this._userIds = userIds
  }

  getParams() {
    return {
      ...this.params,
      ...keysToSnake({
        userIds: this.userIds,
      }),
    }
  }
}

export class RepeatableRule extends CouponRule {
  _refreshInterval: number | undefined
  constructor() {
    super('RepeatableRule')
  }

  get refreshInterval() {
    return this._refreshInterval
  }

  set refreshInterval(refreshInterval) {
    this._refreshInterval = refreshInterval
  }

  getParams(): { [key: string]: number | undefined } {
    return {
      ...this.params,
      ...keysToSnake({
        refreshInterval: this.refreshInterval,
      }),
    }
  }
}

export class TotalAmountLimitRule extends CouponRule {
  _totalAmount: number | undefined
  constructor() {
    super('TotalAmountLimitRule')
  }
  get totalAmount() {
    return this._totalAmount
  }

  set totalAmount(totalAmount) {
    this._totalAmount = totalAmount
  }
  getParams(): { [key: string]: any } {
    return {
      ...this.params,
      ...keysToSnake({
        totalAmount: this.totalAmount,
      }),
    }
  }
}

export class RepurchaseRule extends CouponRule {
  constructor() {
    super('RepurchaseRule')
  }
}

export class TrialUserRepurchaseRule extends CouponRule {
  constructor() {
    super('TrialUserRepurchaseRule')
  }
}

export class IkeaSegmentRule extends CouponRule {
  _segmentId: number | undefined
  constructor(segmentId?: number) {
    super('IkeaSegmentRule')
    this._segmentId = segmentId
  }

  get segmentId() {
    return this._segmentId
  }

  set segmentId(segmentId) {
    this._segmentId = segmentId
  }

  getParams() {
    return {
      ...this.params,
      ...keysToSnake({
        segmentId: this.segmentId,
      }),
    }
  }
}

export class CorporateSpecificRule extends CouponRule {
  _corporateIds: number[] | undefined

  constructor() {
    super('CorporateSpecificRule')
  }

  get corporateIds() {
    return this._corporateIds
  }

  set corporateIds(corporateIds) {
    this._corporateIds = corporateIds
  }

  getParams() {
    return {
      ...this.params,
      ...keysToSnake({
        corporateIds: this.corporateIds,
      }),
    }
  }
}
