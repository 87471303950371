import { Modal, Steps } from 'antd'
import styles from './billing-invoices-processes-modal.module.less'

interface IProps {
  open: boolean
  handleCancel: () => void
}

const BillingInvoicesProcessesModal = (props: IProps) => {
  const { open, handleCancel } = props
  return (
    <Modal open={open} onCancel={handleCancel} footer={null} width={800} title="结算流程和注意事项">
      <div className={styles['modal-container']}>
        <Steps
          progressDot
          current={3}
          direction="vertical"
          className={styles['modal-steps']}
          items={[
            {
              title: <div className={styles['modal-sub-title']}>1、确认金额</div>,
              description: (
                <>
                  <p className={styles['modal-highlight']}>
                    特别提示：商户需在每个月第三个工作日之前确认上一个月的订单。
                  </p>
                  <p className={styles['modal-content']}>
                    商户需在每个月第三个工作日之前确认上一个月的订单，以确保账单金额准确。确认该月订单无误之后，请在每月12日前将发票开具并确保您的发票在12日之前寄送给ClassPass,我们在收到发票后将于每月18日进行付款。若报告内金额有误，请联系ClassPass运营团队沟通。
                  </p>
                </>
              ),
            },
            {
              title: <div className={styles['modal-sub-title']}>2、商户开票</div>,
              description: (
                <>
                  <p className={styles['modal-highlight']}>
                    特别提示：请在每月12日前将发票开具并确保您的发票在12日之前寄送给ClassPass，若您的发票晚于12日寄送到，将会自动延至下一个月进行支付。若转账金额不超过200人民币，财务会累积到这个金额在下个月给商户进行转账。
                  </p>
                  <p className={styles['modal-content']}>
                    请确保您的开票金额与ClassPass系统账单金额一致，若收到的发票与账单不符，发票将会被退回，若不能在每个月12日之前收到更新的发票，账单金额将被推迟到下月收到正确发票之后进行支付。若转账金额不超过200人民币，财务会累积到这个金额在下个月给商户进行转账。
                  </p>
                </>
              ),
            },
            {
              title: <div className={styles['modal-sub-title']}>3、财务付款</div>,
              description: (
                <>
                  <div className={styles['modal-highlight']}>
                    财务会在每月18日进行付款，若遇节假日则视情况提前或延后。
                  </div>
                  <p className={styles['modal-content']}>
                    当您在准确的时间提交了金额准确且符合要求的发票，财务会在每月18日进行付款，若遇节假日则会顺延至下一个工作日。
                  </p>
                </>
              ),
            },
            {
              title: <div className={styles['modal-sub-title']}>4、完成该月结算</div>,
            },
          ]}
        />
      </div>
    </Modal>
  )
}

export default BillingInvoicesProcessesModal
