import { useNavigate, useParams } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Radio,
  InputNumber,
  Checkbox,
  Select,
  DatePicker,
  Switch,
  Space,
  notification,
  Upload,
} from 'antd'
import { useEffect, useState } from 'react'
import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import styles from './coupon-code-form.module.less'
import {
  CouponDiscountTypeEnums,
  CouponFormMode,
  couponRuleTypeOptions,
  CouponTemplateStatus,
  CouponTemplateTranslation,
  CouponTypeRulesEnums,
  CouponUsageRulesEnums,
  FormatCouponTemplate,
  ForPeopleTypeEnums,
  ValidityTypeEnums,
} from '../../../pages/coupon-templates-container/share-type'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { City } from '../../../store/city/reducer'
import { LocaleType } from '../../../share-types'

import {
  BuyOneGetMoreDiscount,
  BuyOnGetMorePlan,
  CashOffDiscount,
  PercentageOffDiscount,
  PercentageOffPlan,
  StackableCashOffDiscount,
} from '../../../helpers/coupon-discount'
import {
  createCouponTemplate,
  editCouponTemplateDetail,
  getCouponTemplateDetail,
} from '../../../api/couponApi'
import {
  clearCouponTemplateDraftAction,
  setCouponTemplateDraftAction,
} from '../../../store/coupon-template/action'
import { keysToCamel } from '../../../helpers/keys-to-case'
import moment from 'moment'
import {
  CouponTemplateDistributionFrequencyModeEnums,
  CouponTemplateDistributionFrequencyModeOptions,
  couponTypeList,
  CouponUsageRulesOptions,
  ForPeopleOptions,
} from '../../../pages/coupon-templates-container/constant'
import {
  formatCouponTemplate,
  getSplitRules,
  getUserSpecificRule,
  groupRules,
} from '../../../helpers/coupon-template-helper'
import { Plan } from '../../../store/global/reducer'
import InputColor from '../../input-color/input-color'
import { DATE_TIME_FORMAT, LocaleOptions, MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'
import CouponTemplateDiscountOffModal from '../coupon-template-discount-off-modal/coupon-template-discount-off-modal'
import { getDiscountOff, getLowestDiscountOff, getOptions } from '../../../helpers/utils'
import * as _ from 'lodash'
import translate from '../../../i18n'
import { getSegmentSuggestion, getSegmentSuggestionsByIds } from '../../../api/segmentApi'
import { SegmentTypeEnum } from '../../../pages/segments-container/share-type'
import DebounceSelect from '../../debounce-select/debounce-select'
import { getCorporatesSuggestions, getCorporatesSuggestionsByIds } from '../../../api/corporateApi'

const { Option } = Select
const { TextArea } = Input
const { RangePicker } = DatePicker

type PropsFromRedux = ConnectedProps<typeof withConnect>

type ICouponCodeFormProps = PropsFromRedux & {
  onSubmitForm: (res: MISSING_TYPE) => void
  mode: CouponFormMode
}

const inactiveCouponTemplateNotification = () => {
  notification['warning']({
    message: '优惠券模板已失效',
    description: '优惠券模板已失效，暂不能编辑，可重新创建模板',
    style: {
      width: 600,
    },
    placement: 'top',
  })
}

const CouponCodeForm = (props: ICouponCodeFormProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const forPeopleValue = Form.useWatch('forPeople', form)
  const couponTypeValue = Form.useWatch('couponType', form)
  const couponRuleTypeValue = Form.useWatch('couponRuleType', form)
  const showCountDownValue = Form.useWatch('showCountDown', form)
  const couponRuleValue = Form.useWatch('couponRule', form)
  const validityTypeValue = Form.useWatch('validityType', form)
  const percentageOffPlansValue = Form.useWatch('percentageOffPlans', form)
  const [appendUserIdsCount, setAppendUserIdsCount] = useState<number>(0)
  const [currUserIdsCount, setCurrUserIdsCount] = useState<number>(0)
  const [couponTemplateDetail, setCouponTemplateDetail] = useState<FormatCouponTemplate>()
  const [couponFormDisabled, setCouponFormDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showCouponTemplateDiscountOffModal, setShowCouponTemplateDiscountOffModal] =
    useState<boolean>(false)
  const [formData, setFormData] = useState<MISSING_TYPE>()
  const [isUserIdsChanged, setIsUserIdsChanged] = useState(false)
  const [couponId] = useState<string | undefined>(params?.id)
  const [defaultCorporateSuggestionsOptions, setDefaultCorporateSuggestionsOptions] = useState<
    { label: string; value: number }[]
  >([])
  const distributionFrequencyModeValue = Form.useWatch('distributionFrequencyMode', form)
  const [defaultSegmentOption, setDefaultSegmentOption] = useState<
    { label: string; value: number }[]
  >([])

  useEffect(() => {
    if (couponId) {
      getCouponTemplateDetail(Number(couponId)).then((res: any) => {
        setCouponTemplateDetail(res)
        const {
          discount,
          translations,
          countDownBeforeMinutes,
          rules,
          startDate,
          endDate,
          status,
          code,
          ...other
        } = keysToCamel(res)
        const splitRules = getSplitRules(rules)
        const couponTemplateForm = {
          translations,
          ...setDiscount(discount),
          couponType: discount.discountType,
          showCountDown: !!countDownBeforeMinutes,
          countDownBeforeMinutes,
          rangeTime: [moment(startDate), moment(endDate)],
          status:
            props.mode === CouponFormMode.DUPLICATE ? CouponTemplateStatus.INTERNAL_ONLY : status,
          code: props.mode === CouponFormMode.DUPLICATE ? '' : code,
          ...splitRules,
          ...other,
        }
        if (res.status === CouponTemplateStatus.INACTIVE && props.mode === CouponFormMode.EDIT) {
          setCouponFormDisabled(true)
          inactiveCouponTemplateNotification()
        }
        if (splitRules.userIds && splitRules.userIds.length) {
          setCurrUserIdsCount(splitRules.userIds.length)
        }

        if (splitRules.corporateIds && splitRules.corporateIds.length) {
          setCorporateSuggestionsOptions(splitRules.corporateIds)
        }

        if (splitRules.segmentId) {
          asyncGetSegmentSuggestionsByIds([splitRules.segmentId]).then(res => {
            setDefaultSegmentOption(getOptions(res))
          })
        }

        form.setFieldsValue(couponTemplateForm)
      })
    } else if (props.couponTemplateDraft) {
      props.clearCouponTemplateDraftAction()
    }
  }, [couponId, props.couponTemplateDraft])

  useEffect(() => {
    if (couponRuleTypeValue === CouponTypeRulesEnums.TRIAL_USER_REPURCHASE_RULE) {
      form.setFieldsValue({
        forPeople: ForPeopleTypeEnums.ALL_USER,
        showInPopup: true,
        showCountDown: true,
      })
    } else if (couponRuleTypeValue === CouponTypeRulesEnums.REPURCHASE_RULE) {
      form.setFieldsValue({
        forPeople: ForPeopleTypeEnums.OLD_USER_ONLY,
        showInPopup: false,
        showCountDown: false,
      })
    } else {
      form.setFieldsValue({
        forPeople: ForPeopleTypeEnums.ALL_USER,
        showInPopup: false,
        showCountDown: false,
      })
    }
  }, [couponRuleTypeValue])

  const setCorporateSuggestionsOptions = (ids: number[]) => {
    if (ids.length <= MAX_QS_STRINGIFY_LENGTH) {
      getCorporatesSuggestionsByIds(ids).then(res => {
        setDefaultCorporateSuggestionsOptions(getOptions(res.filter(r => r)))
      })
    } else {
      setDefaultCorporateSuggestionsOptions(
        ids.map(id => {
          return { value: id, label: id.toString() }
        }),
      )
    }
  }

  const onFinish = (formData: any) => {
    setShowCouponTemplateDiscountOffModal(true)
    setFormData(formData)
  }

  const continueEditFormData = () => {
    setShowCouponTemplateDiscountOffModal(false)
  }

  async function fetchCorporateList(keyword: string): Promise<{ label: string; value: number }[]> {
    return getCorporatesSuggestions(keyword, true).then(res => {
      return getOptions(res)
    })
  }

  const submitFormData = () => {
    const {
      rangeTime,
      showCountDown,
      countDownBeforeMinutes,
      translations,
      couponRule,
      refreshInterval,
      distributionFrequencyMode,
      cityIds,
      totalAmount,
      forPeople,
      userIds,
      corporateIds,
      couponType,
      percentageOffPlans,
      maxAmount,
      offAmount,
      stackable,
      buyOneGetMorePlans,
      couponRuleType,
      userSpecificRuleFile,
      segmentId,
      usageLimit,
      ...other
    } = formData

    setShowCouponTemplateDiscountOffModal(false)
    if (props.mode === CouponFormMode.EDIT) {
      // edit coupon template
      const userSpecificRule = isUserIdsChanged ? getUserSpecificRule(userIds) : undefined
      const editParams = {
        ...couponTemplateDetail,
        countDownBeforeMinutes,
        translations,
        userSpecificRule,
        ...other,
      }
      setLoading(true)
      editCouponTemplateDetail(Number(couponId), editParams)
        .then(res => {
          const { onSubmitForm } = props
          onSubmitForm(formatCouponTemplate(keysToCamel(res)))
        })
        .catch(error => {
          notification.error({
            message: '提交表单失败',
            description: error.message ?? '',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      const bodyFormData = new FormData()
      if (userSpecificRuleFile && userSpecificRuleFile.length) {
        bodyFormData.append('user_specific_rule_file', userSpecificRuleFile[0].originFileObj)
      }

      const forPeopleValue = userSpecificRuleFile && userSpecificRuleFile.length ? null : forPeople

      const rules = groupRules({
        couponRule,
        refreshInterval,
        distributionFrequencyMode,
        cityIds,
        totalAmount,
        forPeople: forPeopleValue,
        userIds,
        couponRuleType,
        segmentId,
        usageLimit,
        corporateIds,
      })

      const { discount, discountOff: translationDiscountOff } = getDiscountParams({
        couponType,
        percentageOffPlans,
        maxAmount,
        offAmount,
        buyOneGetMorePlans,
        stackable,
      })

      const discountOffTranslations: CouponTemplateTranslation[] = translations.map(
        (translation: CouponTemplateTranslation) => {
          return {
            ...translation,
            discountOff: translationDiscountOff,
          }
        },
      )

      setLoading(true)

      const data = {
        rules,
        discount,
        translations: discountOffTranslations,
        startDate: rangeTime[0],
        endDate: rangeTime[1],
        countDownBeforeMinutes: showCountDown ? countDownBeforeMinutes : null,
        ...other,
      }
      bodyFormData.append('coupon_template_request', JSON.stringify(data))

      createCouponTemplate(bodyFormData)
        .then(res => {
          form.resetFields()
          props.clearCouponTemplateDraftAction()
          const { onSubmitForm } = props
          onSubmitForm(formatCouponTemplate(keysToCamel(res)))
        })
        .catch(error => {
          notification.error({
            message: '提交表单失败',
            description: error.message ?? '',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const getDiscountParams = ({
    couponType,
    percentageOffPlans,
    maxAmount,
    offAmount,
    buyOneGetMorePlans,
    stackable,
  }: {
    couponType: CouponDiscountTypeEnums
    percentageOffPlans: PercentageOffPlan[]
    maxAmount: number | null
    offAmount: number | null
    buyOneGetMorePlans: BuyOnGetMorePlan[]
    stackable: boolean
  }) => {
    if (couponType === CouponDiscountTypeEnums.PERCENTAGE_OFF) {
      const percentageOffplans: PercentageOffPlan[] = percentageOffPlans.map(
        (plan: PercentageOffPlan) => {
          return {
            ...plan,
            price: plan.price * 100,
          }
        },
      )

      const { lowestDiscountOff } = getLowestDiscountOff(props.plans, percentageOffPlans)

      return {
        discount: new PercentageOffDiscount(percentageOffplans).getParams(),
        discountOff: Number(lowestDiscountOff) * 10,
      }
    }

    if (couponType === CouponDiscountTypeEnums.CASH_OFF && maxAmount && offAmount) {
      return {
        discount: new CashOffDiscount(maxAmount * 100, offAmount * 100).getParams(),
        discountOff: offAmount * 100,
      }
    }

    if (couponType === CouponDiscountTypeEnums.BUY_ONE_GET_MORE) {
      const plans: BuyOnGetMorePlan[] = buyOneGetMorePlans
      const buyOneGetMoreDiscountOff = _.sortBy(buyOneGetMorePlans, 'bonusCredits')[0].bonusCredits
      return {
        discount: new BuyOneGetMoreDiscount(plans).getParams(),
        discountOff: buyOneGetMoreDiscountOff,
      }
    }

    if (couponType === CouponDiscountTypeEnums.STACKABLE_CASH_OFF && offAmount) {
      return {
        discount: new StackableCashOffDiscount(stackable, offAmount * 100).getParams(),
        discountOff: offAmount * 100,
      }
    }
    return {
      discount: null,
    }
  }

  const setDiscount = (discount: any) => {
    if (discount.discountType === CouponDiscountTypeEnums.PERCENTAGE_OFF) {
      return {
        percentageOffPlans: discount.plans.map((plan: PercentageOffPlan) => ({
          ...plan,
          price: plan.price / 100,
        })),
      }
    }

    if (discount.discountType === CouponDiscountTypeEnums.CASH_OFF) {
      return {
        offAmount: discount.offAmount / 100,
        maxAmount: discount.maxAmount / 100,
      }
    }

    if (discount.discountType === CouponDiscountTypeEnums.BUY_ONE_GET_MORE) {
      return {
        buyOneGetMorePlans: discount.plans,
      }
    }

    if (discount.discountType === CouponDiscountTypeEnums.STACKABLE_CASH_OFF) {
      return {
        offAmount: discount.offAmount / 100,
        stackable: discount.stackable,
      }
    }

    return null
  }

  const saveCouponTemplate = () => {
    const values = form.getFieldsValue(true)
    props.setCouponTemplateDraftAction(values)
    form.resetFields()
    navigate('/coupon-templates')
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforeUploadUserFile = () => {
    return false
  }

  const rangeConfig = {
    rules: [
      { type: 'array' as const, required: true, message: '请选择优惠券模板开始时间和结束时间!' },
    ],
  }

  async function fetchSegmentSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getSegmentSuggestion(keyword, SegmentTypeEnum.IKEA).then(res => {
      return getOptions(res)
    })
  }

  const asyncGetSegmentSuggestionsByIds = async (queryParams: number[]) => {
    const res = await getSegmentSuggestionsByIds(queryParams)
    return res
  }

  const renderPlanDiscountOff = (key: number) => {
    if (
      percentageOffPlansValue &&
      percentageOffPlansValue[key] &&
      percentageOffPlansValue[key].planId &&
      percentageOffPlansValue[key].price
    ) {
      const discountOff = getDiscountOff(
        props.plans,
        percentageOffPlansValue[key].planId,
        percentageOffPlansValue[key].price,
      )
      return `折扣：${discountOff}折`
    }
    return ''
  }

  return (
    <>
      <Form
        name="coupon_code"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{
          code: '',
          isAuto: false,
          showInPopup: false,
          showCountDown: false,
          forPeople: ForPeopleTypeEnums.ALL_USER,
          status: CouponTemplateStatus.INTERNAL_ONLY,
          validityType: ValidityTypeEnums.NATURAL,
          couponRuleType: CouponTypeRulesEnums.NONE,
          couponRule: CouponUsageRulesEnums.SINGLE_USE,
          distributionFrequencyMode: CouponTemplateDistributionFrequencyModeEnums.ONE_DAY,
          usageLimit: 1,
        }}
        onFinish={onFinish}
        autoComplete="off"
        className={styles['coupon-form']}
        form={form}
        onFieldsChange={changedFields => {
          if (currUserIdsCount && changedFields.length) {
            const userIds = changedFields.filter(field => {
              return field.name.toString() == 'userIds'
            })

            if (userIds.length) {
              const curUserIdsCount = userIds[0].value.split(',').length
              setAppendUserIdsCount(curUserIdsCount - currUserIdsCount)
              setIsUserIdsChanged(true)
            }
          }
        }}
      >
        <Form.List name="translations" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    label="语言"
                    name={[name, 'locale']}
                    rules={[{ required: true, message: '请选择语言！' }]}
                  >
                    <Select disabled={couponFormDisabled}>
                      {LocaleOptions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={translate('couponTemplates.name')}
                    rules={[{ required: true, message: translate('couponTemplates.name.message') }]}
                    {...restField}
                    name={[name, 'name']}
                  >
                    <TextArea
                      disabled={couponFormDisabled}
                      showCount
                      maxLength={100}
                      placeholder={translate('couponTemplates.name')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translate('couponTemplates.discountTitle')}
                    rules={[
                      {
                        required: true,
                        message: translate('couponTemplates.discountTitle.message'),
                      },
                    ]}
                    {...restField}
                    name={[name, 'discountTitle']}
                  >
                    <TextArea
                      disabled={couponFormDisabled}
                      showCount
                      maxLength={100}
                      placeholder={translate('couponTemplates.discountTitle')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translate('couponTemplates.rulesTitle')}
                    rules={[
                      { required: true, message: translate('couponTemplates.rulesTitle.message') },
                    ]}
                    {...restField}
                    name={[name, 'ruleTitle']}
                  >
                    <TextArea
                      disabled={couponFormDisabled}
                      showCount
                      maxLength={100}
                      placeholder={translate('couponTemplates.rulesTitle')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translate('couponTemplates.rulesDescription')}
                    rules={[
                      {
                        required: true,
                        message: translate('couponTemplates.rulesDescription.message'),
                      },
                    ]}
                    {...restField}
                    name={[name, 'ruleDescription']}
                  >
                    <TextArea
                      disabled={couponFormDisabled}
                      showCount
                      placeholder={translate('couponTemplates.rulesDescription')}
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <Form.Item colon={false} label="  ">
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              ))}

              {fields.length < Object.keys(LocaleType).length && (
                <Form.Item colon={false} label="  ">
                  <Button
                    disabled={couponFormDisabled}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add new translation
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>

        <Form.Item label="优惠码 (选填)" name="code">
          <TextArea
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            showCount
            maxLength={100}
          />
        </Form.Item>

        <Form.Item label="应用渠道" name="distributionChannel">
          <TextArea disabled={couponFormDisabled} showCount maxLength={32} />
        </Form.Item>

        <Form.Item
          label={translate('couponTemplates.couponRuleType')}
          name="couponRuleType"
          tooltip={translate('couponTemplates.couponRuleType.desc')}
        >
          <Radio.Group
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            options={couponRuleTypeOptions}
          ></Radio.Group>
        </Form.Item>

        {couponRuleTypeValue === CouponTypeRulesEnums.NONE && (
          <Form.Item label="适用人群" name="forPeople">
            <Radio.Group
              disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
              options={ForPeopleOptions}
            ></Radio.Group>
          </Form.Item>
        )}

        {forPeopleValue === ForPeopleTypeEnums.NEW_USER_ONLY && (
          <>
            <Form.Item
              label="IKEA Segment"
              name="segmentId"
              tooltip={{
                title:
                  '选择 IKEA Segment 以限制使用该优惠券的用户购买后的预约范围, 如果用户有其他已付款订单则不会限制. 如果不选择则不限制',
                icon: <InfoCircleOutlined />,
              }}
            >
              <DebounceSelect
                disabled={couponId != undefined}
                allowClear
                showSearch={true}
                placeholder="可通过id/name搜索用户分组"
                fetchOptions={fetchSegmentSuggestionOptions}
                style={{ width: 240, marginBottom: 0 }}
                defaultOptions={defaultSegmentOption}
              />
            </Form.Item>
          </>
        )}

        {forPeopleValue === ForPeopleTypeEnums.SPECIAL_USER_ONLY && (
          <>
            {!couponId && (
              <Form.Item
                name="userSpecificRuleFile"
                label="上传指定用户列表"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                tooltip={{
                  title: '上传文件或者手动输入用户id，如果上传文件，则手动输入的用户id 无效',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            )}
            <Form.Item name="userIds" label="指定用户id">
              <TextArea placeholder="多个用户id，使用,隔开" showCount />
            </Form.Item>
          </>
        )}

        {forPeopleValue === ForPeopleTypeEnums.CORPORATE_USERS && (
          <Form.Item
            name="corporateIds"
            label={translate('couponTemplates.corporateIds')}
            tooltip={translate('couponTemplates.corporateIds.desc')}
          >
            <DebounceSelect
              mode="multiple"
              style={{ minWidth: 130 }}
              showSearch={true}
              allowClear
              placeholder="请输入ID或关键字搜索企业"
              fetchOptions={fetchCorporateList}
              defaultOptions={defaultCorporateSuggestionsOptions}
              disabled={props.mode === CouponFormMode.EDIT}
            />
          </Form.Item>
        )}

        <Form.Item
          label={translate('couponTemplates.discountType')}
          name="couponType"
          rules={[{ required: true, message: translate('couponTemplates.discountType.message') }]}
        >
          <Radio.Group
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            options={couponTypeList.filter(
              item => item.value !== CouponDiscountTypeEnums.GIFT_CARD,
            )}
          ></Radio.Group>
        </Form.Item>

        {couponTypeValue === CouponDiscountTypeEnums.PERCENTAGE_OFF && (
          <>
            <Form.List name="percentageOffPlans" initialValue={[{}]}>
              {(percentageOffPlanFields, { add, remove }) => (
                <>
                  {percentageOffPlanFields.map(({ key, name, ...restField }) => (
                    <Form.Item
                      label={translate('couponTemplates.plans')}
                      key={key}
                      extra={renderPlanDiscountOff(key)}
                    >
                      <Space align="baseline">
                        <Form.Item
                          {...restField}
                          noStyle
                          name={[name, 'planId']}
                          rules={[
                            { required: true, message: translate('couponTemplates.plans.message') },
                          ]}
                        >
                          <Select
                            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                            style={{ width: 340 }}
                          >
                            {props.plans.map((item: Plan) => {
                              const translation = item.translations.find(
                                t => t.locale === props.locale,
                              )
                              return (
                                <Option key={item.id} value={item.id}>
                                  ID: {item.id} - {translation?.name} - ¥{item.price / 100} -{' '}
                                  {item.credits}点
                                </Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={translate('couponTemplates.plans.price')}
                          name={[name, 'price']}
                          rules={[
                            {
                              required: true,
                              message: translate('couponTemplates.plans.price.message'),
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ minWidth: 140 }}
                            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                            addonAfter="元"
                          />
                        </Form.Item>
                        {percentageOffPlanFields.length > 1 && !couponId && !couponFormDisabled ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </Space>
                    </Form.Item>
                  ))}

                  {percentageOffPlanFields.length < props.plans.length &&
                    !couponId &&
                    !couponFormDisabled && (
                      <Form.Item colon={false} label="  ">
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          添加课包
                        </Button>
                      </Form.Item>
                    )}
                </>
              )}
            </Form.List>
          </>
        )}

        {couponTypeValue === CouponDiscountTypeEnums.CASH_OFF && (
          <Form.Item label="满减券详情">
            <Space>
              <Form.Item
                name="maxAmount"
                noStyle
                rules={[{ required: true, message: '请输入满减价格' }]}
              >
                <InputNumber
                  disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                  placeholder="输入价格"
                  addonBefore="满"
                  addonAfter="元"
                />
              </Form.Item>
              <Form.Item
                name="offAmount"
                noStyle
                rules={[{ required: true, message: '请输入优惠价格' }]}
              >
                <InputNumber
                  disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                  placeholder="输入价格"
                  addonBefore="减"
                  addonAfter="元"
                />
              </Form.Item>
            </Space>
          </Form.Item>
        )}

        {couponTypeValue === CouponDiscountTypeEnums.BUY_ONE_GET_MORE && (
          <>
            <Form.List name="buyOneGetMorePlans" initialValue={[{}]}>
              {(planFields, { add, remove }) => (
                <>
                  {planFields.map(({ key, name, ...restField }) => (
                    <Form.Item label="课包" key={`${key + 'plan'}`}>
                      <Space align="baseline">
                        <Form.Item
                          {...restField}
                          noStyle
                          name={[name, 'planId']}
                          rules={[{ required: true, message: '请选择课包' }]}
                        >
                          <Select
                            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                            style={{ width: 340 }}
                          >
                            {props.plans.map((item: Plan) => {
                              const translation = item.translations.find(
                                t => t.locale === props.locale,
                              )
                              return (
                                <Option key={item.id} value={item.id}>
                                  ID: {item.id} - {translation?.name} - ¥{item.price / 100} -{' '}
                                  {item.credits}点
                                </Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="奖励点数"
                          name={[name, 'bonusCredits']}
                          rules={[{ required: true, message: '请输入奖励点数' }]}
                        >
                          <InputNumber
                            style={{ minWidth: 140 }}
                            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                            addonAfter="点"
                          />
                        </Form.Item>
                        {planFields.length > 1 && !couponId && !couponFormDisabled ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </Space>
                    </Form.Item>
                  ))}

                  {planFields.length < props.plans.length && !couponId && !couponFormDisabled && (
                    <Form.Item colon={false} label="  ">
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        添加课包
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </>
        )}

        {couponTypeValue === CouponDiscountTypeEnums.STACKABLE_CASH_OFF && (
          <>
            <Form.Item
              label="现金券优惠详情"
              name="offAmount"
              rules={[{ required: true, message: '请输入现金优惠价格' }]}
            >
              <InputNumber
                disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                placeholder="输入价格"
                addonBefore="减"
                addonAfter="元"
              />
            </Form.Item>
            <Form.Item
              name="stackable"
              label="是否可叠加"
              valuePropName="checked"
              rules={[{ required: true }]}
              initialValue={true}
            >
              <Switch disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled} />
            </Form.Item>
          </>
        )}

        <Form.Item
          label={translate('couponTemplates.couponRule')}
          name="couponRule"
          rules={[{ required: true, message: translate('couponTemplates.couponRule.message') }]}
        >
          <Radio.Group
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            options={CouponUsageRulesOptions}
          ></Radio.Group>
        </Form.Item>

        {couponRuleValue === CouponUsageRulesEnums.REUSE && (
          <>
            <Form.Item name="distributionFrequencyMode" label="发放时间维度">
              <Radio.Group disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}>
                {CouponTemplateDistributionFrequencyModeOptions.map(item => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="refreshInterval"
              label={translate('couponTemplates.frequency')}
              rules={[{ required: true, message: translate('couponTemplates.frequency.message') }]}
            >
              <InputNumber
                disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
                min={0}
                addonBefore="每"
                addonAfter={`${
                  CouponTemplateDistributionFrequencyModeOptions.find(
                    item => item.value === distributionFrequencyModeValue,
                  )?.label
                }发放一次`}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="usageLimit"
          label={translate('couponTemplates.usageLimit')}
          rules={[{ required: true, message: translate('couponTemplates.usageLimit.message') }]}
        >
          <InputNumber
            min={1}
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            placeholder="输入张数"
            addonAfter="张"
            addonBefore="每人最多领取"
          />
        </Form.Item>

        <Form.Item label={translate('couponTemplates.cities')} name="cityIds">
          <Checkbox.Group disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}>
            {props.activeCities.map((city: City) => {
              const cityTranslations = city.translations.find(item => item.locale === props.locale)
              return (
                <Checkbox key={city.id} value={city.id} style={{ lineHeight: '32px' }}>
                  {cityTranslations?.name}
                </Checkbox>
              )
            })}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label={translate('couponTemplates.expirationDate')}
          name="rangeTime"
          {...rangeConfig}
        >
          <RangePicker
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            showTime
            format={DATE_TIME_FORMAT}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          label={translate('couponTemplates.expirationType')}
          name="validityType"
          tooltip={{
            title: translate('couponTemplates.expirationType'),
            icon: <InfoCircleOutlined />,
          }}
        >
          <Radio.Group disabled={props.mode === CouponFormMode.EDIT}>
            <Radio value={ValidityTypeEnums.FIXED}>fixed</Radio>
            <Radio value={ValidityTypeEnums.NATURAL}>natural</Radio>
          </Radio.Group>
        </Form.Item>

        {validityTypeValue === ValidityTypeEnums.NATURAL && (
          <Form.Item
            label={translate('couponTemplates.validityDays')}
            name="validityDays"
            tooltip={{ title: '默认30天后过期', icon: <InfoCircleOutlined /> }}
          >
            <InputNumber placeholder="输入有效期天数" addonAfter="天" />
          </Form.Item>
        )}

        <Form.Item label="可用数量" name="totalAmount">
          <InputNumber
            disabled={props.mode === CouponFormMode.EDIT || couponFormDisabled}
            placeholder="输入张数"
            addonAfter="张"
          />
        </Form.Item>

        <Form.Item name="showCountDown" label="显示倒计时" valuePropName="checked">
          <Switch disabled={couponFormDisabled} />
        </Form.Item>

        {showCountDownValue && (
          <Form.Item label="倒计时起始时间（分钟）">
            <div style={{ height: '32px', lineHeight: '32px' }} className="ant-form-text">
              从{' '}
            </div>
            <Form.Item name="countDownBeforeMinutes" noStyle>
              <InputNumber disabled={couponFormDisabled} placeholder="输入分钟" addonAfter="分钟" />
            </Form.Item>
            <div
              style={{ height: '32px', lineHeight: '32px', marginLeft: '8px' }}
              className="ant-form-text"
            >
              开始倒数
            </div>
          </Form.Item>
        )}

        <Form.Item
          name="status"
          label="优惠券模板状态"
          rules={[{ required: true, message: '请选择使用人群' }]}
        >
          <Radio.Group disabled={couponFormDisabled}>
            <Radio value={CouponTemplateStatus.ACTIVE}>激活</Radio>
            <Radio value={CouponTemplateStatus.INTERNAL_ONLY}>仅限内部</Radio>
          </Radio.Group>
        </Form.Item>

        {couponRuleTypeValue !== CouponTypeRulesEnums.TRIAL_USER_REPURCHASE_RULE && (
          <Form.Item name="isAuto" label="生成优惠券模板后自动发送" valuePropName="checked">
            <Switch disabled={couponFormDisabled} />
          </Form.Item>
        )}

        <Form.Item name="showInPopup" label="是否在弹窗中显示" valuePropName="checked">
          <Switch disabled={couponFormDisabled} />
        </Form.Item>

        <Form.Item name="marketingHeadingBgColor" label="标记背景颜色">
          <InputColor />
        </Form.Item>
        <Form.Item name="marketingBorderColor" label="标记边框颜色">
          <InputColor />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button disabled={couponFormDisabled} loading={loading} type="primary" htmlType="submit">
            提交
          </Button>
          <Button
            disabled={couponFormDisabled}
            onClick={saveCouponTemplate}
            className={styles['save-button']}
          >
            保存
          </Button>
        </Form.Item>
      </Form>
      {formData && (
        <CouponTemplateDiscountOffModal
          visible={showCouponTemplateDiscountOffModal}
          handleCancel={continueEditFormData}
          handleOk={submitFormData}
          values={formData}
          plans={props.plans}
          appendUserIdsCount={appendUserIdsCount}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
    couponTemplateDraft: state.couponTemplate.couponTemplateDraft,
    plans: state.global.plans,
  }
}

const mapDispatchToProps = {
  setCouponTemplateDraftAction,
  clearCouponTemplateDraftAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(CouponCodeForm)
