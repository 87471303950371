import { Button, Form, Input, Select } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { City, CityTranslation } from '../../../store/city/reducer'
import translate from '../../../i18n'
import { LocaleType } from '../../../share-types'

import { OrganizationMetricFilter } from '../../../pages/exchange-rates-container/share-type'
import { TaggingContextEnums } from '../../../pages/tags-container/constant'
import { ITag, ITagsWithCategory } from '../../../pages/tags-container/share-type'
import { getTagsByContext } from '../../../api/tagsApi'
import * as _ from 'lodash'
import { sortableFields, SortOrderEnums } from '../../../pages/exchange-rates-container/constant'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import FormattedMessage from '../../intl/format-message'
import styles from './exchange-rates-filter.module.less'
import RangeInputFormItem from '../range-input-form-item/range-input-form-item'

const { Option } = Select

type NumberField =
  | 'minEarlyVisitRate'
  | 'maxEarlyVisitRate'
  | 'minCostPerCredit'
  | 'maxCostPerCredit'
  | 'minExchangeRate'
  | 'maxExchangeRate'
  | 'minBlendedFcfPercentage'
  | 'maxBlendedFcfPercentage'

const ExchangeRatesFilter = ({
  onSearch,
  formData,
  activeCities,
  locale,
  isOnlyShowSortableFields = false,
}: {
  onSearch: (value: OrganizationMetricFilter) => void
  formData: OrganizationMetricFilter | undefined
  activeCities: City[]
  locale: LocaleType
  isOnlyShowSortableFields?: boolean
}) => {
  const [form] = Form.useForm()
  const [activitiesTags, setActivitiesTags] = useState<ITag[]>([])
  const orderByValues = Form.useWatch('orderBy', form)
  const orderDirectionValues = Form.useWatch('orderDirection', form)

  useEffect(() => {
    getTagsByContext(TaggingContextEnums.ACTIVITIES).then(res => {
      if (res) {
        setActivitiesTags(flatTagsWithContext(res))
      }
    })
  }, [])

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(parseFormData(formData))
    }
  }, [formData])

  const parseFormData = (data: OrganizationMetricFilter) => {
    const numberFields: NumberField[] = [
      'minEarlyVisitRate',
      'maxEarlyVisitRate',
      'minCostPerCredit',
      'maxCostPerCredit',
      'minExchangeRate',
      'maxExchangeRate',
      'minBlendedFcfPercentage',
      'maxBlendedFcfPercentage',
    ]

    return numberFields.reduce<OrganizationMetricFilter>(
      (acc, field) => {
        const value = data[field]
        return {
          ...acc,
          [field]: value !== undefined ? Number(value) : undefined,
        }
      },
      { ...data },
    )
  }

  const flatTagsWithContext = (res: ITagsWithCategory): ITag[] => {
    return Object.values(res).flat()
  }

  const onFinish = useCallback(
    (values: OrganizationMetricFilter) => {
      const formattedValues = _.pickBy(values, value => value === 0 || Boolean(value))
      onSearch(formattedValues)
    },
    [onSearch],
  )

  const onReset = useCallback(() => {
    form.resetFields()
    onSearch({})
  }, [form, onSearch])

  const getTagsOptions = (tags: ITag[], taggingContex: TaggingContextEnums) => {
    return tags.map(tag => {
      const categoryAndSubCategoryLabel = [tag.category, tag.subCategory]
        .filter(item => item)
        .join('/')
      const tagName = tag.tagTranslations.find(t => t.locale === LocaleType.zh_CN)?.name
      if (taggingContex === TaggingContextEnums.ACTIVITIES) {
        return {
          label: `${tagName} (${categoryAndSubCategoryLabel})`,
          value: tag.id,
        }
      }

      return {
        label: `${tagName}`,
        value: tag.id,
      }
    })
  }

  const toggleSortDirection = () => {
    form.setFieldsValue({
      orderDirection:
        orderDirectionValues === SortOrderEnums.ASC ? SortOrderEnums.DESC : SortOrderEnums.ASC,
    })
  }

  const getSortFormItems = () => {
    return (
      <>
        <Form.Item label={translate('orderBy')} name="orderBy">
          <Select style={{ minWidth: 130 }} allowClear placeholder={translate('orderBy.message')}>
            {sortableFields.map(field => (
              <Option key={field.key} value={field.key}>
                {field.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {orderByValues && (
          <Form.Item label="" style={{ marginLeft: '-20px' }} name="orderDirection">
            <Button
              shape="round"
              onClick={toggleSortDirection}
              icon={orderDirectionValues === 'ASC' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            >
              {orderDirectionValues === 'ASC' ? (
                <FormattedMessage id="ascending"></FormattedMessage>
              ) : (
                <FormattedMessage id="descending"></FormattedMessage>
              )}
            </Button>
          </Form.Item>
        )}
      </>
    )
  }

  const tagsOptions = useMemo(
    () => getTagsOptions(activitiesTags, TaggingContextEnums.ACTIVITIES),
    [activitiesTags],
  )

  return (
    <Form
      form={form}
      layout="inline"
      style={{ gap: '16px' }}
      onFinish={onFinish}
      initialValues={{ orderDirection: SortOrderEnums.DESC }}
    >
      {isOnlyShowSortableFields ? (
        <>{getSortFormItems()}</>
      ) : (
        <>
          <Form.Item label={translate('organizationName')} name="keyword">
            <Input placeholder="请输入品牌名进行搜索" />
          </Form.Item>
          <Form.Item label={translate('organization.cityId')} name="cityId">
            <Select style={{ minWidth: 130 }} allowClear>
              {activeCities.map(city => {
                const translation = city?.translations.find(
                  (translation: CityTranslation) => translation.locale === locale,
                )
                return (
                  <Option key={city.id} value={city.id}>
                    {translation?.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item label={translate('activities')} name="activityIds">
            <Select
              allowClear
              style={{ width: '200px' }}
              placeholder={translate('primaryCategory.placeholder')}
              options={tagsOptions}
              showSearch
              filterOption={(input, option) =>
                ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
              }
              mode="multiple"
            />
          </Form.Item>
          <RangeInputFormItem
            minField="minEarlyVisitRate"
            maxField="maxEarlyVisitRate"
            label={translate('exchangeRates.earlyVisitRateRange')}
            min={0}
          />
          <RangeInputFormItem
            minField="minCostPerCredit"
            maxField="maxCostPerCredit"
            label={translate('exchangeRates.costPerCreditRange')}
            addonAfter="元/点"
            min={0}
          />
          <RangeInputFormItem
            minField="minExchangeRate"
            maxField="maxExchangeRate"
            label={translate('exchangeRates.exchangeRateRange')}
            min={0}
          />
          <RangeInputFormItem
            minField="minBlendedFcfPercentage"
            maxField="maxBlendedFcfPercentage"
            label={translate('exchangeRates.fcfPercentageRange')}
            addonAfter="%"
            min={0}
            max={100}
          />
          {getSortFormItems()}
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {isOnlyShowSortableFields ? (
            <FormattedMessage id="confirm"></FormattedMessage>
          ) : (
            <FormattedMessage id="search"></FormattedMessage>
          )}
        </Button>
        <Button onClick={onReset} className={styles['filter-actions']}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ExchangeRatesFilter
