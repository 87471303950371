import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { Button, Descriptions } from 'antd'
import { getCityLabel } from '../../../helpers/coupon-template-helper'
import { ICardStack } from '../../../pages/card-stacks-container/share-type'
import {
  pageTypeOptions,
  targetUserGroupsOptions,
} from '../../../pages/card-stacks-container/constant'
import AuthPermission from '../../../high-order-components/auth-permission'
import { useNavigate } from 'react-router-dom'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'

interface Iprops extends ConnectedProps<typeof withConnect> {
  cardStack: ICardStack
}

const CardStackDescriptions = (props: Iprops) => {
  const { activeCities, locale, cardStack } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/card-stacks/${cardStack?.id}/edit`)
  }
  return (
    <Descriptions
      style={{ width: 1200 }}
      title="卡片堆详情"
      labelStyle={{ alignItems: 'center', fontWeight: 600 }}
      column={2}
      bordered
      extra={
        <AuthPermission permission={PermissionsEnum.CARD_STACKS_WRITE}>
          <Button onClick={toEdit} type="primary">
            编辑
          </Button>
        </AuthPermission>
      }
    >
      <Descriptions.Item label="页面种类" span={2}>
        {pageTypeOptions.find(item => item.value === cardStack?.pageType)?.label}
      </Descriptions.Item>
      <Descriptions.Item label="ID">{cardStack?.id}</Descriptions.Item>
      <Descriptions.Item label="名称">{cardStack?.name}</Descriptions.Item>
      <Descriptions.Item label="排名">{cardStack?.rank}</Descriptions.Item>
      <Descriptions.Item label="活跃">
        <YesOrNoTag value={cardStack?.isActive} />
      </Descriptions.Item>
      <Descriptions.Item label="城市">
        {cardStack?.cityIds && getCityLabel(cardStack.cityIds, activeCities, locale)}
      </Descriptions.Item>
      <Descriptions.Item label="目标用户">
        {targetUserGroupsOptions.find(item => item.value === cardStack?.targetGroup)?.label}
      </Descriptions.Item>
      <Descriptions.Item label="创建时间">{cardStack?.createdAt}</Descriptions.Item>
      <Descriptions.Item label="更改时间">{cardStack?.updatedAt}</Descriptions.Item>
      <Descriptions.Item label="设置">
        <pre>{JSON.stringify(cardStack?.config, undefined, 2)}</pre>
      </Descriptions.Item>
    </Descriptions>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CardStackDescriptions)
