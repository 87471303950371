import config from '../config/index'
import {
  IPaginatedTaskReward,
  ITaskReward,
  ITaskRewardsFilterParams,
  TaskRewardRequest,
} from '../pages/task-rewards-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedTaskRewards = (data: ITaskRewardsFilterParams) =>
  authRequest.get<MISSING_TYPE, IPaginatedTaskReward>(`${mainApi}/task-rewards`, {
    params: data,
  })

export const getTaskRewardDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, ITaskReward>(`${mainApi}/task-rewards/${id}`)

export const createTaskReward = (data: TaskRewardRequest) =>
  authRequest.post<MISSING_TYPE, string>(`${mainApi}/task-rewards`, data)

export const editTaskReward = (id: number, data: TaskRewardRequest) =>
  authRequest.put<MISSING_TYPE, string>(`${mainApi}/task-rewards/${id}`, data)
