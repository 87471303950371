import React from 'react'
import { Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { getDateTime } from '../../../helpers/utils'
import AuthPermission from '../../../high-order-components/auth-permission'
import translate from '../../../i18n'
import {
  CardConfig,
  ICardConfig,
  ICardStack,
} from '../../../pages/card-stacks-container/share-type'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import LinkButton from '../../link-button/link-button'
import YesOrNoTag from '../../common/YesOrNoTag'
import moment from 'moment'

interface IProps {
  cardConfigs: ICardConfig[]
  pageInfo?: PageInfo
  paginationOnChange: (page: number, pageSize: number) => void
  hasAction?: boolean
}

const CardConfigsTable = (props: IProps) => {
  const { cardConfigs, pageInfo, paginationOnChange, hasAction } = props

  const baseColumns: ColumnsType<ICardConfig> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, row: ICardConfig) => {
        if (!hasAction) {
          return <Link to={`/card-configs/${row.id}`}>{name}</Link>
        }
        return name
      },
    },
    {
      title: '卡片堆',
      dataIndex: 'cardStack',
      key: 'cardStack',
      render: (cardStack: ICardStack) => {
        if (hasAction) {
          return <Link to={`/card-stacks/${cardStack.id}`}>{cardStack.name}</Link>
        }
        return cardStack.name
      },
    },
    {
      title: '排名',
      dataIndex: 'config',
      key: 'config',
      sorter: (a: ICardConfig, b: ICardConfig) => {
        const rankA = a.config?.rank
        const rankB = b.config?.rank
        if (rankA === undefined && rankB === undefined) {
          return 0
        }
        if (rankA === undefined) {
          return 1
        }
        if (rankB === undefined) {
          return -1
        }
        if (rankA < rankB) {
          return -1
        }
        if (rankA > rankB) {
          return 1
        }
        return 0
      },
      render: (config: CardConfig) => {
        return config.rank
      },
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: (a, b) => {
        if (a.isActive < b.isActive) {
          return -1
        }
        if (a.isActive > b.isActive) {
          return 1
        }
        return 0
      },
      render: (isActive: number) => <YesOrNoTag value={isActive} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => {
        if (moment(a.createdAt).isBefore(b.createdAt)) {
          return -1
        }
        if (moment(a.createdAt).isAfter(b.createdAt)) {
          return 1
        }
        return 0
      },
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
      sorter: (a, b) => {
        if (moment(a.createdAt).isBefore(b.createdAt)) {
          return -1
        }
        if (moment(a.createdAt).isAfter(b.createdAt)) {
          return 1
        }
        return 0
      },
    },
  ]

  const actionColumn = {
    title: translate('tableActionColumn'),
    key: 'action',
    render: (text: string, record: any) => (
      <Space>
        <AuthPermission permission={PermissionsEnum.CARD_CONFIGS_WRITE}>
          <LinkButton to={`/card-configs/${record.id}/edit`}>编辑</LinkButton>
        </AuthPermission>

        <LinkButton to={`/card-configs/${record.id}`}>查看</LinkButton>
      </Space>
    ),
  }

  const columns = hasAction ? baseColumns.concat([actionColumn]) : baseColumns

  return (
    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={cardConfigs}
      pagination={{
        current: pageInfo?.currentPage,
        pageSize: pageInfo?.pageSize,
        total: pageInfo?.count,
        onChange: paginationOnChange,
      }}
    />
  )
}

export default CardConfigsTable
