import { Form, Input, Button, Select, notification, Switch, Cascader, Divider } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import styles from './tag-form.module.less'
import { createTag, editTag, getTagDetail } from '../../../api/tagsApi'
import { ITagForm } from '../share-type'
import translate from '../../../i18n'
import { TagCategoryWithSubCategoryOptions } from '../constant'
import type { DefaultOptionType } from 'antd/es/cascader'
import { LocaleOptions } from '../../../helpers/constant'
import { LocaleType } from '../../../share-types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const { Option } = Select
const { TextArea } = Input

const TagForm = () => {
  const params = useParams()
  const navigation = useNavigate()

  const tagId = params?.id
  const [form] = Form.useForm<ITagForm>()

  useEffect(() => {
    if (tagId) {
      getTagDetail(Number(tagId)).then(res => {
        const { category, subCategory, tagTranslations, ...others } = res
        form.setFieldsValue({
          ...others,
          categoryAndSubCategory: res.category
            ? res.subCategory
              ? [category, subCategory]
              : [category]
            : undefined,
          translations: tagTranslations,
        })
      })
    }
  }, [tagId])

  const onFinish = (values: ITagForm) => {
    const { categoryAndSubCategory, ...others } = values
    const data = {
      ...others,
      category: categoryAndSubCategory[0],
      subCategory: categoryAndSubCategory.length > 1 ? categoryAndSubCategory[1] : null,
    }

    if (tagId) {
      editTag(Number(tagId), data)
        .then(() => {
          navigation(`/tags/${tagId}`)
          notification.success({
            message: '编辑成功',
          })
        })
        .catch(err => {
          notification.error({
            message: '编辑失败',
            description: err?.message || translate('networkError'),
          })
        })
    } else {
      createTag(data)
        .then(res => {
          navigation(`/tags/${res.id}`)
          notification.success({
            message: '创建成功',
          })
        })
        .catch(err => {
          notification.error({
            message: '创建失败',
            description: err?.message || translate('networkError'),
          })
        })
    }
  }

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    )

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      form={form}
      name="tag-form"
      className={styles['tag-form']}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
    >
      <Form.Item
        label={translate('tags.name')}
        name="name"
        rules={[{ required: true, message: 'name is required' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={translate('tags.isActive')} name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item
        label={translate('tags.categoryAndSubCategory')}
        name="categoryAndSubCategory"
        rules={[{ required: true, message: 'category is required' }]}
      >
        <Cascader
          options={TagCategoryWithSubCategoryOptions}
          changeOnSelect
          showSearch={{ filter }}
        />
      </Form.Item>

      <Divider style={{ fontSize: '14px' }}>{translate('tags.translations')}</Divider>

      <Form.List name="translations" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Form.Item
                  {...restField}
                  label="语言"
                  name={[name, 'locale']}
                  rules={[{ required: true, message: 'locale is required' }]}
                >
                  <Select>
                    {LocaleOptions.map(item => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={translate('tags.name')}
                  rules={[{ required: true, message: 'name is required' }]}
                  {...restField}
                  name={[name, 'name']}
                >
                  <TextArea showCount maxLength={100} placeholder={translate('tags.name')} />
                </Form.Item>

                {fields.length > 1 ? (
                  <Form.Item colon={false} label="  ">
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  </Form.Item>
                ) : null}
              </div>
            ))}

            {fields.length < Object.keys(LocaleType).length && (
              <Form.Item colon={false} label="  ">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add new translation
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          确定
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TagForm
