import { Button, Col, Row, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { getPaginatedTaskRewards } from '../../../api/taskRewardsApi'
import { ITaskReward, ITaskRewardsFilterParams } from '../share-type'
import { TaskRewardableTypeEnums, TaskRewardableTypeOptions } from '../constant'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const TaskRewards = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [taskRewards, setTaskRewards] = useState<ITaskReward[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    getPaginatedTaskRewardsAction(data)
  }, [])

  const getPaginatedTaskRewardsAction = (data: ITaskRewardsFilterParams) => {
    getPaginatedTaskRewards(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setTaskRewards(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
    }

    setSearchParams(getSearchParams(data))

    getPaginatedTaskRewardsAction(data)
  }

  const columns: ColumnsType<ITaskReward> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('taskRewards.title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: translate('taskRewards.taskRewardableType'),
      dataIndex: 'taskRewardableType',
      key: 'taskRewardableType',
      render: (taskRewardableType: TaskRewardableTypeEnums) => {
        return TaskRewardableTypeOptions.find(item => item.value === taskRewardableType)?.label
      },
    },
    {
      title: translate('taskRewards.taskRewardableId'),
      dataIndex: 'taskRewardableId',
      key: 'taskRewardableId',
      render: (taskRewardableId: string, record: ITaskReward) => {
        if (record.taskRewardableType === TaskRewardableTypeEnums.COUPON_TEMPLATE) {
          return (
            <LinkButton to={`/coupon-templates/${taskRewardableId}`}>{taskRewardableId}</LinkButton>
          )
        }
        return taskRewardableId
      },
    },
    {
      title: translate('taskRewards.beans'),
      dataIndex: 'beans',
      key: 'beans',
    },
    {
      title: translate('taskRewards.isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => {
        return <YesOrNoTag value={isActive} />
      },
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: ITaskReward) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.TASK_REWARDS_WRITE}>
            <LinkButton to={`/task-rewards/${record.id}/edit`}>{translate('edit')}</LinkButton>
          </AuthPermission>

          <LinkButton to={`/task-rewards/${record.id}`}>{translate('view')}</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.taskRewardsTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.TASK_REWARDS_READ}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/task-rewards/new')}
            >
              {translate('navBar.newTaskRewards')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={taskRewards}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default TaskRewards
