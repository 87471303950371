import { Button, Modal, Result } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'

interface IRevokeCouponTemplateModalProps {
  loading: boolean
  visible: boolean
  handleCancel: () => void
  handleOk: () => void
}

const RevokeCouponTemplateModal = (props: IRevokeCouponTemplateModalProps) => {
  const { visible, handleOk, handleCancel, loading } = props

  return (
    <Modal
      centered
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      title={null}
      footer={null}
      width={450}
      bodyStyle={{ padding: '24px 80px' }}
    >
      <Result
        title="确认注销?"
        subTitle="注销后优惠券则不可以再使用"
        icon={<ExclamationCircleFilled style={{ color: '#FF4D4F', fontSize: '40px' }} />}
        extra={[
          <Button type="primary" key="back" loading={loading} onClick={handleOk}>
            确认注销
          </Button>,
          <Button key="submit" onClick={handleCancel}>
            取消注销
          </Button>,
        ]}
      />
    </Modal>
  )
}

export default RevokeCouponTemplateModal
