import { Button, Descriptions, Space, Tag, notification, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './coupon-template.module.less'
import { getCouponTemplateDetail, revokeCouponTemplate } from '../../../api/couponApi'
import {
  CouponTemplateStatus,
  CouponTemplateTranslation,
  ForPeopleTypeEnums,
  FormatCouponTemplate,
  couponRuleTypeOptions,
} from '../share-type'
import {
  CouponTemplateDistributionFrequencyModeOptions,
  couponTypeList,
  CouponUsageRulesOptions,
  ForPeopleOptions,
} from '../constant'
import { keysToCamel } from '../../../helpers/keys-to-case'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { City } from '../../../store/city/reducer'
import { LocaleType, PermissionsEnum } from '../../../share-types'
import {
  formatCitySpecificRuleDetail,
  formatCouponTemplate,
  getDiscountDetail,
} from '../../../helpers/coupon-template-helper'
import RevokeCouponTemplateModal from '../../../components/coupon-templates/revoke-coupon-template-modal/revoke-coupon-template-modal'
import { Plan } from '../../../store/global/reducer'
import * as _ from 'lodash'
import AuthPermission from '../../../high-order-components/auth-permission'
import { getDateTime, getOptions } from '../../../helpers/utils'
import translate from '../../../i18n'
import { getSegmentSuggestionsByIds } from '../../../api/segmentApi'
import LinkButton from '../../../components/link-button/link-button'
import { getCorporatesSuggestionsByIds } from '../../../api/corporateApi'
import { MAX_QS_STRINGIFY_LENGTH } from '../../../helpers/constant'

interface Iprops {
  formatCouponTemplateDetail: any
  activeCities: City[]
  locale: LocaleType
  plans: Plan[]
  segmentOptions: { label: string; value: number }[]
  corporateOptions: { label: string; value: number }[]
}

const CouponTemplateDescriptions = ({
  formatCouponTemplateDetail,
  activeCities,
  locale,
  plans,
  segmentOptions,
  corporateOptions,
}: Iprops) => {
  const {
    discount,
    translations,
    countDownBeforeMinutes,
    rules,
    startDate,
    endDate,
    code,
    showInPopup,
    isAuto,
    forPeople,
    refreshInterval,
    distributionFrequencyMode,
    usageLimit,
    couponUsageRule,
    totalAmount,
    status,
    couponRuleType,
    plans: couponTemplatePlans,
    userIds,
    usedCount,
    issuedCount,
  } = formatCouponTemplateDetail
  const couponNameList = translations.map(
    (translation: CouponTemplateTranslation) => translation.name,
  )
  const title = _.join(couponNameList, '/')
  const discountLabel = couponTypeList.find(item => item.value === discount.discountType)?.label
  const discountDetail = getDiscountDetail(keysToCamel(discount), translations[0].discountOff)
  const zhCNTranslation = translations.find(
    (item: CouponTemplateTranslation) => item.locale === 'zh-CN',
  )
  const enTranslation = translations.find((item: CouponTemplateTranslation) => item.locale === 'en')

  const cityLabel = formatCitySpecificRuleDetail(rules, activeCities, locale)
  const displayPlans = couponTemplatePlans
    .map((item: { planId: number; bonusCredits: number | null; price: number | null }) => {
      const filterPlan = plans.find(plan => plan.id === item.planId)
      if (filterPlan) {
        const name = filterPlan.translations.find(t => t.locale === locale)?.name
        if (item.price && filterPlan.price !== item.price) {
          return `ID：${filterPlan.id}-${name} - 课包价格：¥${filterPlan.price / 100} - 课包点数：${
            filterPlan.credits
          }点 - 折后价: ¥${item.price / 100}`
        }
        if (item.bonusCredits && filterPlan.credits !== item.bonusCredits) {
          return `ID：${filterPlan.id}-${name} - ¥${filterPlan.price / 100} - 课包点数：${
            filterPlan.credits
          }点 - 赠送点数：${item.bonusCredits}点`
        }

        return `ID：${filterPlan.id}-${name} - 课包价格：¥${filterPlan.price / 100} - 课包点数：${
          filterPlan.credits
        }点`
      }
      return ''
    })
    .filter((item: string) => item)

  const renderRepeatableRule = () => {
    const distributionFrequencyLable = CouponTemplateDistributionFrequencyModeOptions.find(
      item => item.value === distributionFrequencyMode,
    )?.label

    if (refreshInterval && usageLimit) {
      distributionFrequencyMode
      return `每${refreshInterval}${distributionFrequencyLable}发放一次，每人最多领取${usageLimit}张`
    }
    if (refreshInterval) {
      return `每${refreshInterval}${distributionFrequencyLable}发放一次`
    }

    if (usageLimit) {
      return `每人最多领取${usageLimit}张`
    }
    return ''
  }

  return (
    <Descriptions
      title={title}
      layout="vertical"
      bordered
      extra={status === CouponTemplateStatus.INACTIVE && <Tag color="red">已失效</Tag>}
      column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      labelStyle={{ fontWeight: 600 }}
    >
      <Descriptions.Item label="优惠码" span={3}>
        <Typography.Text copyable={{ tooltips: false }}>{code}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="优惠劵发劵数量">
        <Typography.Text>{issuedCount}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="优惠劵消耗数量">
        <Typography.Text>{usedCount}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="可用张数">{totalAmount ?? '不限制'}</Descriptions.Item>

      <Descriptions.Item label="有效期" span={3}>
        {getDateTime(startDate)}至{getDateTime(endDate)}
      </Descriptions.Item>

      <Descriptions.Item label="优惠券模板使用规则">
        {CouponUsageRulesOptions.find(item => item.value === couponUsageRule)?.label} -
        {renderRepeatableRule()}
      </Descriptions.Item>

      <Descriptions.Item label="显示倒计时">
        {countDownBeforeMinutes ? `是, 从${countDownBeforeMinutes} 分钟开始倒计时` : '否'}
      </Descriptions.Item>

      <Descriptions.Item label="是否在弹窗中显示">{showInPopup ? '是' : '否'}</Descriptions.Item>

      <Descriptions.Item label="折扣类型">{discountLabel}</Descriptions.Item>
      <Descriptions.Item label="折扣详情" span={2}>
        {discountDetail}
      </Descriptions.Item>

      <Descriptions.Item label="折扣标题 中文" span={3}>
        {zhCNTranslation?.discountTitle ?? '暂无'}
      </Descriptions.Item>
      <Descriptions.Item label="折扣标题 英文" span={3}>
        {enTranslation?.discountTitle ?? '暂无'}
      </Descriptions.Item>

      <Descriptions.Item label="优惠券模板规则标题 中文" span={3}>
        {zhCNTranslation?.ruleTitle ?? '暂无'}
      </Descriptions.Item>

      <Descriptions.Item label="优惠券模板规则标题 英文" span={3}>
        {enTranslation?.ruleTitle ?? '暂无'}
      </Descriptions.Item>

      <Descriptions.Item
        label="优惠券模板规则详情 中文"
        contentStyle={{ whiteSpace: 'break-spaces' }}
        span={3}
      >
        {zhCNTranslation?.ruleDescription ?? '暂无'}
      </Descriptions.Item>

      <Descriptions.Item
        label="优惠券模板规则详情 英文"
        contentStyle={{ whiteSpace: 'break-spaces' }}
        span={3}
      >
        {enTranslation?.ruleDescription ?? '暂无'}
      </Descriptions.Item>

      {displayPlans && displayPlans.length && (
        <Descriptions.Item label="适用课包" span={3}>
          {displayPlans.map((item: string, index: number) => {
            return <div key={index}>{item}</div>
          })}
        </Descriptions.Item>
      )}

      <Descriptions.Item label="自动发送" span={1}>
        {isAuto ? '是' : '否'}
      </Descriptions.Item>
      <Descriptions.Item label="城市" span={2}>
        {cityLabel}
      </Descriptions.Item>

      <Descriptions.Item label="适用人群" span={1}>
        {ForPeopleOptions.find(item => item.value === forPeople)?.label}
      </Descriptions.Item>

      {forPeople === ForPeopleTypeEnums.SPECIAL_USER_ONLY && (
        <Descriptions.Item label="指定用户" span={2}>
          <div className={styles['user-ids-box']}>{userIds.join(',')}</div>
        </Descriptions.Item>
      )}

      {forPeople === ForPeopleTypeEnums.CORPORATE_USERS && (
        <Descriptions.Item label={translate('couponTemplates.corporateIds')} span={2}>
          <Space wrap>
            {corporateOptions.map(option => (
              <LinkButton
                target="_black"
                to={`/corporates/${option.value}`}
                key={option.value}
                style={{ padding: 0 }}
              >
                {option.label}
              </LinkButton>
            ))}
          </Space>
        </Descriptions.Item>
      )}

      {segmentOptions && segmentOptions.length > 0 && (
        <Descriptions.Item label="IKEA Segment" span={2}>
          <Space wrap>
            {segmentOptions.map(option => (
              <LinkButton target="_black" to={`/segments/${option.value}`} key={option.value}>
                {option.label}
              </LinkButton>
            ))}
          </Space>
        </Descriptions.Item>
      )}

      <Descriptions.Item label="优惠券类型">
        {couponRuleTypeOptions.find(option => option.value === couponRuleType)?.label}
      </Descriptions.Item>
    </Descriptions>
  )
}

type PropsFromRedux = ConnectedProps<typeof withConnect>

const CouponTemplate = (props: PropsFromRedux) => {
  const params = useParams()
  const navigate = useNavigate()
  const [couponId] = useState<string | undefined>(params?.id)
  const [visible, setVisible] = useState<boolean>(false)
  const [revokeCouponTemplateLoading, setRevokeCouponTemplateLoading] = useState<boolean>(false)
  const [formatCouponTemplateDetail, setFormatCouponTemplateDetail] =
    useState<FormatCouponTemplate>()

  const [segmentOptions, setSegmentOptions] = useState<{ label: string; value: number }[]>([])
  const [corporateOptions, setCorporateOptions] = useState<{ label: string; value: number }[]>([])

  useEffect(() => {
    if (couponId) {
      getCouponTemplateDetail(Number(couponId)).then((res: any) => {
        const couponTemplateDetail = formatCouponTemplate(keysToCamel(res))
        if (couponTemplateDetail.segmentId) {
          getSegmentSuggestionsByIds([couponTemplateDetail.segmentId]).then(res => {
            setSegmentOptions(getOptions(res))
          })
        }

        if (couponTemplateDetail.corporateIds && couponTemplateDetail.corporateIds.length) {
          if (couponTemplateDetail.corporateIds.length <= MAX_QS_STRINGIFY_LENGTH) {
            asyncGetCorporateSuggestionsByIds(couponTemplateDetail.corporateIds).then(res => {
              setCorporateOptions(getOptions(res))
            })
          } else {
            setCorporateOptions(
              couponTemplateDetail.corporateIds.map(id => ({ label: id.toString(), value: id })),
            )
          }
        }

        setFormatCouponTemplateDetail(couponTemplateDetail)
      })
    }
  }, [couponId])

  const asyncGetCorporateSuggestionsByIds = async (corporateIds: number[]) => {
    const res = await getCorporatesSuggestionsByIds(corporateIds)
    return res
  }

  const toEdit = () => {
    navigate(`/coupon-templates/${couponId}/edit`)
  }

  const toDuplicate = () => {
    navigate(`/coupon-templates/${couponId}/duplicate`)
  }

  const goBack = () => {
    navigate('/coupon-templates')
  }
  const revokeCouponTemplateAction = () => {
    setVisible(true)
  }

  const handleOk = () => {
    if (couponId) {
      setRevokeCouponTemplateLoading(true)
      revokeCouponTemplate(Number(couponId))
        .then(() => {
          notification.success({
            message: '注销优惠券模板成功',
          })
          setVisible(false)
          getCouponTemplateDetail(Number(couponId)).then((res: any) => {
            setFormatCouponTemplateDetail(formatCouponTemplate(keysToCamel(res)))
          })
        })
        .catch(error => {
          notification.error({
            message: '注销优惠券模板失败',
            description: error.message ?? '',
          })
        })
        .finally(() => {
          setRevokeCouponTemplateLoading(false)
        })
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <div className={styles['coupon-template-desc-wrapper']}>
      {formatCouponTemplateDetail && (
        <CouponTemplateDescriptions
          formatCouponTemplateDetail={formatCouponTemplateDetail}
          activeCities={props.activeCities}
          locale={props.locale}
          plans={props.plans}
          segmentOptions={segmentOptions}
          corporateOptions={corporateOptions}
        />
      )}
      <Space className={styles['button-group']}>
        <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
          <Button
            type="primary"
            onClick={toEdit}
            disabled={
              formatCouponTemplateDetail &&
              formatCouponTemplateDetail.status === CouponTemplateStatus.INACTIVE
            }
          >
            {translate('edit')}
          </Button>
        </AuthPermission>

        <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
          <Button
            type="primary"
            ghost
            onClick={toDuplicate}
            disabled={
              formatCouponTemplateDetail &&
              formatCouponTemplateDetail.status === CouponTemplateStatus.INACTIVE
            }
          >
            {translate('duplicate')}
          </Button>
        </AuthPermission>

        <Button onClick={goBack}>返回</Button>

        <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
          <Button
            disabled={
              formatCouponTemplateDetail &&
              formatCouponTemplateDetail.status === CouponTemplateStatus.INACTIVE
            }
            type="primary"
            danger
            onClick={revokeCouponTemplateAction}
          >
            注销
          </Button>
        </AuthPermission>
      </Space>
      <RevokeCouponTemplateModal
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={revokeCouponTemplateLoading}
      ></RevokeCouponTemplateModal>
    </div>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
    plans: state.global.plans,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CouponTemplate)
