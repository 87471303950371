export const SET_LOCALE_KEY = 'SET_LOCALE_KEY'
export type SET_LOCALE_KEY = typeof SET_LOCALE_KEY

export const SET_PLANS_KEY = 'SET_PLANS_KEY'
export type SET_PLANS_KEY = typeof SET_PLANS_KEY

export const SET_PERMISSIONS_KEY = 'SET_PERMISSIONS_KEY'
export type SET_PERMISSIONS_KEY = typeof SET_PERMISSIONS_KEY

export const SET_ROLES_KEY = 'SET_ROLES_KEY'
export type SET_ROLES_KEY = typeof SET_ROLES_KEY

export const SET_REJECT_REASONS_KEY = 'SET_REJECT_REASONS_KEY'
export type SET_REJECT_REASONS_KEY = typeof SET_REJECT_REASONS_KEY

export const SET_CURRENT_MENU_TYPE_KEY = 'SET_CURRENT_MENU_TYPE_KEY'
export type SET_CURRENT_MENU_TYPE_KEY = typeof SET_CURRENT_MENU_TYPE_KEY
