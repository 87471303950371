import { Button, Modal, Input, Form, notification, Space } from 'antd'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { changeAdminUserPassword } from '../../../api/userApi'
import { IAdminUser } from '../../../share-types'
import { ReduxStore } from '../../../store'
import { clearAuthAction } from '../../../store/userAuth/action'
import { clearUserInfoAction } from '../../../store/userInfo/action'

interface IResetUserPasswordModalProps extends ConnectedProps<typeof withConnect> {
  visible: boolean
  handleCancel: () => void
  user: IAdminUser | undefined
}

interface IChangePasswordForm {
  password: string
  confirmPassword: string
}

const ResetUserPasswordModal = (props: IResetUserPasswordModalProps) => {
  const { visible, handleCancel, user } = props
  const [form] = Form.useForm<IChangePasswordForm>()
  const onFinish = (values: IChangePasswordForm) => {
    const { password } = values
    if (user?.id) {
      changeAdminUserPassword(user.id, { password }).then(() => {
        handleCancel()
        notification.success({
          message: '修改密码成功',
        })
        setTimeout(() => {
          if (user.email === props.userInfo.email) {
            notification.info({
              message: '你已经修改密码，需要重新登录',
            })
            props.clearAuthAction()
            props.clearUserInfoAction()
          }
        }, 500)
      })
    }
  }
  useEffect(() => {
    form.resetFields()
  }, [user?.id])

  return (
    <Modal
      centered
      open={visible}
      onCancel={handleCancel}
      title={`修改${user?.email}密码`}
      footer={null}
      width={500}
      bodyStyle={{ padding: '24px 80px' }}
      forceRender
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="change_admin_user_password"
        onFinish={onFinish}
      >
        <Form.Item
          label="账号密码"
          name="password"
          rules={[{ required: true, message: '请输入密码!' }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="确认密码"
          name="confirmPassword"
          hasFeedback
          rules={[
            {
              required: true,
              message: '请确认密码',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('您输入的两个密码不匹配!'))
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
            <Button onClick={handleCancel}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    userInfo: state.userInfo,
  }
}

const mapDispatchToProps = {
  clearAuthAction,
  clearUserInfoAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(ResetUserPasswordModal)
