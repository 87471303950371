import { useNavigate, useParams } from 'react-router-dom'
import { notification, PageHeader, Space } from 'antd'
import { useEffect, useState } from 'react'
import { PrelaunchLessonDescriptionFormValues } from '../../../prelaunch-organization-forms-container/share-types'
import {
  createPrelaunchLessonDescriptions,
  getPrelaunchLessonDescriptionDetail,
} from '../../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../../i18n'
import LessonDescriptionForm from '../../../../components/common/lesson-description-form'
import { convertLessonDescriptionToFormValues } from '../../../../helpers/prelaunch-organization-helper'

const prelaunchLessonDescriptionForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [uniqueId] = useState<string | undefined>(params?.uniqueId)
  const [organizationName] = useState<string | undefined>(params?.organizationName)
  const [lessonDescriptionId] = useState<string | undefined>(params?.lessonDescriptionId)

  const [prelaunchLessonDescriptionFormData, setPrelaunchLessonDescriptionFormData] =
    useState<PrelaunchLessonDescriptionFormValues>()

  useEffect(() => {
    if (lessonDescriptionId) {
      getPrelaunchLessonDescriptionDetail(Number(lessonDescriptionId)).then(data => {
        setPrelaunchLessonDescriptionFormData(convertLessonDescriptionToFormValues(data))
      })
    }
  }, [lessonDescriptionId])
  const onFinish = (values: PrelaunchLessonDescriptionFormValues) => {
    if (!uniqueId) {
      return
    }
    createPrelaunchLessonDescriptions({
      ...values,
      uniqueId: uniqueId,
    })
      .then(response => {
        if (response) {
          notification.success({
            message: translate('addLessonDescriptions.success'),
          })
          navigate(`/public/prelaunch-organization-form/${uniqueId}`)
        }
      })
      .catch(err => {
        notification.error({
          message: translate('addLessonDescriptions.error'),
          description: err?.message || translate('networkError'),
        })
      })
  }

  const onBack = () => {
    navigate(-1)
  }

  if (!uniqueId) {
    return null
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <PageHeader
        className="site-page-header"
        onBack={onBack}
        title={organizationName}
        subTitle={translate('addLessonDescriptions')}
      />
      <LessonDescriptionForm
        isEdit={false}
        organizationUniqueId={uniqueId}
        formData={prelaunchLessonDescriptionFormData}
        onFinish={onFinish}
      />
    </Space>
  )
}

export default prelaunchLessonDescriptionForm
