import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'

const OneTimeCodeBatchesContainer = () => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/one-time-code-batches\/[0-9]+\/edit$/)) {
      return translate('navBar.editOneTimeCodeBatch')
    }

    if (pathName.match(/^\/one-time-code-batches\/new/)) {
      return translate('navBar.newOneTimeCodeBatch')
    }

    if (pathName.match(/^\/one-time-code-batches\/[0-9]+$/)) {
      return translate('navBar.viewOneTimeCodeBatch')
    }

    return translate('navBar.oneTimeCodeBatchesTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default OneTimeCodeBatchesContainer
