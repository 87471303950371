import { ITagsWithCategory } from '../../pages/tags-container/share-type'
import { SET_ALL_CATEGORIES } from './constants'

export interface SetAllCategoriesAction {
  type: SET_ALL_CATEGORIES
  payload: ITagsWithCategory
}

export const setAllCategoriesAction = (payload: ITagsWithCategory): SetAllCategoriesAction => {
  return {
    type: SET_ALL_CATEGORIES,
    payload,
  }
}

export type CategoriesAction = SetAllCategoriesAction
