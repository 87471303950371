import { Button, DatePicker, Form, Modal, notification, Select, Space } from 'antd'
import { FormattedMessage } from '../../intl'
import styles from './batch-download-modal.module.less'
import { BillingInvoiceStatusOptions } from '../../../pages/billing-invoices-container/constant'
import DebounceSelect from '../../debounce-select/debounce-select'
import { getOrganizationsSuggestion } from '../../../api/organizationsApi'
import { ISimpleOrganization } from '../../../share-types'
import { exportBillingInvoices } from '../../../api/billingInvoicesApi'
import { IBillingInvoiceExportParams } from '../../../pages/billing-invoices-container/share-type'
import translate from '../../../i18n'
import { useState } from 'react'
import moment from 'moment'

interface IProps {
  show: boolean
  handleCancel: () => void
}

const BatchDownloadModal = (props: IProps) => {
  const { show, handleCancel } = props
  const [loadingButton, setLoadingButton] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    setLoadingButton(true)
    const formattedValues = {
      ...values,
      startTime: values.startTime
        ? moment(values.startTime).startOf('day').toISOString()
        : undefined,
      endTime: values.endTime ? moment(values.endTime).endOf('day').toISOString() : undefined,
      organizationId: values.organizationId,
    }
    onExportBillingInvoices(formattedValues)
  }

  const handleModalCancel = () => {
    form.resetFields()
    handleCancel()
  }

  const { Option } = Select

  const onExportBillingInvoices = (params: IBillingInvoiceExportParams) => {
    exportBillingInvoices(params)
      .then(res => {
        if (res) {
          notification.success({
            message: translate('exportSuccess'),
          })
        }
      })
      .catch(err => {
        notification.error({
          message: translate('exportError'),
          description: err?.message || translate('networkError'),
        })
      })
      .finally(() => {
        form.resetFields()
        setLoadingButton(false)
      })
  }

  async function fetchOrganizationsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getOrganizationsSuggestion(keyword).then(res => {
      return getOrganizationsSuggestionOptions(res)
    })
  }

  const getOrganizationsSuggestionOptions = (organizationsSuggestions: ISimpleOrganization[]) => {
    return organizationsSuggestions.map(item => {
      const name = item.organizationTranslations[0].name
      return {
        label: `${item.id}-${name}`,
        value: item.id,
      }
    })
  }

  return (
    <Modal title="批量下载" open={show} footer={null} onCancel={handleModalCancel}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{}}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          label="开始时间"
          name="startTime"
          rules={[{ required: true, message: '请选择开始时间' }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          label="结束时间"
          name="endTime"
          rules={[{ required: true, message: '请选择结束时间' }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item label="状态" name="status" rules={[{ required: true, message: '请选择状态' }]}>
          <Select allowClear style={{ width: '100%' }}>
            {BillingInvoiceStatusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Organization" name="organizationId">
          <DebounceSelect
            style={{ minWidth: 130 }}
            showSearch={true}
            allowClear
            placeholder="可以通过id/name搜索organization"
            fetchOptions={fetchOrganizationsSuggestionOptions}
          />
        </Form.Item>
        <Space className={styles['button-group']} direction="horizontal">
          <Button loading={loadingButton} onClick={handleModalCancel}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button loading={loadingButton} type="primary" htmlType="submit">
            <FormattedMessage id="download" />
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}

export default BatchDownloadModal
