import { IPaginatedParams, PageInfo } from '../../share-types'

export enum SearchTrendableTypeEnums {
  STUDIOS = 'STUDIOS',
  LESSON_DESCRIPTIONS = 'LESSON_DESCRIPTIONS',
}

export enum SearchTrendStatusEnums {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type ISearchFilterParams = {
  startDate?: string
  endDate?: string
  status?: SearchTrendStatusEnums
  cityId?: number
  trendableType?: SearchTrendableTypeEnums
}

export type IPaginatedSearchTrendsParams = IPaginatedParams & ISearchFilterParams

export type SearchTrendRankerDto = {
  id: number
  rank: number
  clicksCount: number
  createdAt: string
  updatedAt: string
}

export type StudioSuggestionDto = {
  id: number
  name: string
}

export type StudioSearchTrendRankerDto = {
  studio: StudioSuggestionDto
} & SearchTrendRankerDto

export type LessonDescriptionSuggestionDto = {
  id: number
  name: string
}

export type LessonDescriptionSearchTrendRankerDto = {
  lessonDescription: LessonDescriptionSuggestionDto
} & SearchTrendRankerDto

export type IStudioSearchTrendRanksDto = {
  ranks: StudioSearchTrendRankerDto[]
  trendableType: SearchTrendableTypeEnums.STUDIOS
}

export type ILessonDescriptionSearchTrendRanksDto = {
  ranks: LessonDescriptionSearchTrendRankerDto[]
  trendableType: SearchTrendableTypeEnums.LESSON_DESCRIPTIONS
}

export type ISearchTrend = {
  id: number
  generatedDate: string
  city: {
    id: number
    name: string
  }
  status: SearchTrendStatusEnums
  createdAt: string
  updatedAt: string
} & (IStudioSearchTrendRanksDto | ILessonDescriptionSearchTrendRanksDto)

export type PaginatedSearchTrendsResponse = {
  pageInfo: PageInfo
  data: ISearchTrend[]
}

export type SearchTrendRankerRequest = {
  referenceId: number
  rank: number
}
