import { Button, Descriptions, Modal, notification, Space } from 'antd'
import translate from '../../../i18n'
import {
  CorporateCreditsDistributionScheduleResponse,
  ICorporateCreditsDistributionScheduleFormValue,
} from '../../../pages/corporates-container/share-type'
import { createCorporateCreditsDistributionSchedule } from '../../../api/corporateApi'
import CreditsDistributionScheduleForm from '../credits-distribution-schedule-form/credits-distribution-schedule-form'
import {
  extractPhoneNumberCheckResult,
  formValueToFormData,
} from '../../../helpers/corporate-credits-distribution-schedule-helper'
import { useState } from 'react'
import PhoneNumbersCheckModal from '../phone-numbers-check-modal/phone-numbers-check-modal'
import { FormattedMessage } from 'react-intl'
import YesOrNoTag from '../../common/YesOrNoTag'

interface ICreateCorporateCreditsDistributionScheduleModalProps {
  handleCancel: () => void
  handleSubmit: () => void
  open: boolean
  corporateId: number
  isTrialCorporate: boolean
}

const CreateCorporateCreditsDistributionScheduleModal = (
  props: ICreateCorporateCreditsDistributionScheduleModalProps,
) => {
  const { open, handleCancel, corporateId, handleSubmit, isTrialCorporate } = props
  const [phoneNumbersCheckModalVisible, setPhoneNumbersCheckModalVisible] = useState<boolean>(false)
  const [phoneNumbersCheckModalResult, setPhoneNumbersCheckModalResult] =
    useState<CorporateCreditsDistributionScheduleResponse>()
  const [phoneNumberCount, setPhoneNumberCount] = useState<{
    invalidCount: number
    validCount: number
  }>({ invalidCount: 0, validCount: 0 })
  const [formData, setFormData] = useState<FormData>(new FormData())
  const [showConfirmBeforeSubmit, setShowConfirmBeforeSubmit] = useState<boolean>(false)

  const onFinish = (values: ICorporateCreditsDistributionScheduleFormValue) => {
    if (corporateId) {
      if (!showConfirmBeforeSubmit) {
        const data = formValueToFormData(values)
        setFormData(data)
        setShowConfirmBeforeSubmit(true)
      }
    }
  }

  const onSubmit = () => {
    if (corporateId) {
      formData.set('ignoreInvalidPhoneNumbers', false.toString())
      createCorporateCreditsDistributionScheduleAction(formData)
    }
  }

  const onSkip = () => {
    if (corporateId) {
      formData.set('ignoreInvalidPhoneNumbers', true.toString())
      createCorporateCreditsDistributionScheduleAction(formData)
    }
  }

  const createCorporateCreditsDistributionScheduleAction = (data: FormData) => {
    createCorporateCreditsDistributionSchedule(corporateId, data)
      .then(res => {
        if (res) {
          if (res.isSuccess) {
            notification.success({
              message: translate('corporate.corporateCreditsDistributionSchedule.create.success'),
              description: res.corporateCreditsDistributionSchedule?.isApplyForAllMembers
                ? undefined
                : translate(
                    'corporate.corporateCreditsDistributionSchedule.create.phoneNumberCheck.success',
                    { number: res.successfulPhoneNumbers?.length },
                  ),
            })

            handleSubmit()
            handleCancel()
            setPhoneNumbersCheckModalVisible(false)
          } else {
            handleCancel()

            setPhoneNumberCount(extractPhoneNumberCheckResult(res))
            setPhoneNumbersCheckModalResult(res)
            setPhoneNumbersCheckModalVisible(true)
          }
        }
      })
      .catch(err => {
        notification.error({
          message: translate('corporate.corporateCreditsDistributionSchedule.create.failed'),
          description: err.message ?? translate('networkError'),
        })
      })
  }

  return (
    <>
      <Modal
        title={translate('navbar.corporateCreditsDistributionSchedule.create')}
        open={open}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        width={800}
      >
        {showConfirmBeforeSubmit ? (
          <Space direction="vertical">
            <Descriptions
              title={translate('corporate.corporateCreditsDistributionSchedule.modal.confirm')}
              column={1}
            >
              <Descriptions.Item
                label={translate(
                  'corporate.corporateCreditsDistributionSchedule.modal.isApplyForAllMembers',
                )}
              >
                <YesOrNoTag value={formData.get('isApplyForAllMembers') === 'true'} />
              </Descriptions.Item>

              <Descriptions.Item label={translate('corporate.credits')}>
                {String(formData.get('credits'))}
              </Descriptions.Item>

              <Descriptions.Item label={translate('corporate.bufferPercentage')}>
                {String(formData.get('bufferPercentage'))}%
              </Descriptions.Item>
            </Descriptions>
            <Space align="center">
              <Button type="primary" htmlType="submit" onClick={onSubmit}>
                <FormattedMessage id="submit" />
              </Button>
              <Button
                onClick={() => {
                  setShowConfirmBeforeSubmit(false)
                  handleCancel()
                }}
              >
                <FormattedMessage id="cancel" />
              </Button>
            </Space>
          </Space>
        ) : (
          <CreditsDistributionScheduleForm
            onFinish={onFinish}
            onCancel={handleCancel}
            isEdit={false}
            isTrialCorporate={isTrialCorporate}
            corporateId={corporateId}
          />
        )}
      </Modal>

      {phoneNumbersCheckModalResult && (
        <PhoneNumbersCheckModal
          visibale={phoneNumbersCheckModalVisible}
          result={phoneNumbersCheckModalResult}
          onConfirm={onSkip}
          onCancel={() => setPhoneNumbersCheckModalVisible(false)}
          title={translate(
            'corporate.corporateCreditsDistributionSchedule.modal.phoneNumberCheck.title',
            {
              validCount: phoneNumberCount.validCount,
              invalidCount: phoneNumberCount.invalidCount,
            },
          )}
          okText={translate(
            'corporate.corporateCreditsDistributionSchedule.modal.phoneNumberCheck.okText',
          )}
        />
      )}
    </>
  )
}

export default CreateCorporateCreditsDistributionScheduleModal
