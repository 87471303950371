import { Button, Form, InputNumber, notification, Select, Switch } from 'antd'
import styles from './task-reward-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TaskRewardRequest } from '../share-type'
import { createTaskReward, editTaskReward, getTaskRewardDetail } from '../../../api/taskRewardsApi'
import translate from '../../../i18n'
import { TaskRewardableTypeEnums, TaskRewardableTypeOptions } from '../constant'
import { getCouponTemplatesSuggestions } from '../../../api/couponApi'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { OptionType } from '../../../share-types'
import { getOptions } from '../../../helpers/utils'

const TaskRewardForm = () => {
  const [form] = Form.useForm()

  const params = useParams()
  const navigation = useNavigate()
  const [taskRewardId] = useState<string | undefined>(params?.id)
  const [defaultCouponTemplateOptions, setDefaultCouponTemplateOptions] = useState<OptionType[]>([])

  useEffect(() => {
    if (taskRewardId) {
      getTaskRewardDetail(Number(taskRewardId)).then(data => {
        form.setFieldsValue(data)
        setDefaultCouponTemplateOptions(
          getOptions([{ name: data.title, id: data.taskRewardableId }]),
        )
      })
    }
  }, [taskRewardId])

  useEffect(() => {
    fetchCouponTemplatesSuggestions('').then(options => setDefaultCouponTemplateOptions(options))
  }, [])

  const onFinish = (data: TaskRewardRequest) => {
    if (taskRewardId) {
      editTaskReward(Number(taskRewardId), data)
        .then(() => {
          notification.success({
            message: '编辑任务奖励成功',
          })
          navigation(`/task-rewards/${taskRewardId}`)
        })
        .catch(err => {
          notification.error({
            message: '编辑任务奖励失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    } else {
      createTaskReward(data)
        .then(() => {
          notification.success({
            message: '创建任务奖励成功',
          })
          navigation('/task-rewards')
        })
        .catch(err => {
          notification.error({
            message: '创建任务奖励失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  async function fetchCouponTemplatesSuggestions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getCouponTemplatesSuggestions({
      keyword: keyword ?? '',
      isValid: true,
      isAuto: false,
    }).then(res => {
      return getOptions(res)
    })
  }

  return (
    <Form
      name="taskReward"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{
        isActive: true,
        taskRewardableType: TaskRewardableTypeEnums.COUPON_TEMPLATE,
      }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['task-reward-form']}
    >
      <Form.Item label={translate('taskRewards.taskRewardableType')} name="taskRewardableType">
        <Select options={TaskRewardableTypeOptions} />
      </Form.Item>
      <Form.Item
        label="优惠劵模板"
        name="taskRewardableId"
        rules={[
          {
            required: true,
            message: '请选择优惠劵模板!',
          },
        ]}
      >
        <DebounceSelect
          showSearch
          placeholder="输入ID, 优惠劵名称，优惠码搜索优惠劵模版"
          fetchOptions={fetchCouponTemplatesSuggestions}
          defaultOptions={defaultCouponTemplateOptions}
        />
      </Form.Item>

      <Form.Item label={translate('taskRewards.isActive')} name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        label={translate('taskRewards.beans')}
        name="beans"
        rules={[{ required: true, message: '请输入兑换的活力豆数量！' }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TaskRewardForm
