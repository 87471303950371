import { Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'
import { ICorporateCreditsDistributionScheduleHistory } from '../../../pages/corporates-container/share-type'

const { Title } = Typography

interface CorporateCreditsDistributionScheduleHistoriesTableProps {
  histories: ICorporateCreditsDistributionScheduleHistory[]
}

const CorporateCreditsDistributionScheduleHistoriesTable = ({
  histories,
}: CorporateCreditsDistributionScheduleHistoriesTableProps) => {
  const columns: ColumnsType<ICorporateCreditsDistributionScheduleHistory> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
      width: 100,
    },
    {
      title: translate('corporateCreditsHistories.notes'),
      dataIndex: 'notes',
      key: 'notes',
      width: 100,
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (createdAt: string) => (createdAt ? getDateTime(createdAt) : ''),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Title level={5}>{translate('navbar.corporateCreditsDistributionSchedule.histories')}</Title>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={histories}
        scroll={{ x: 1140 }}
        pagination={false}
      />
    </Space>
  )
}

export default CorporateCreditsDistributionScheduleHistoriesTable
