import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

import Icon from '@ant-design/icons'

const InvoiceSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="4"
      stroke="white"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 10H12.5"
      stroke="white"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 14.5H15.5"
      stroke="white"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const InvoiceIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={InvoiceSvg} {...props} />
)

export default InvoiceIcon
