import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { ISegmentsFilterParams } from '../../../pages/segments-container/share-type'
import { SegmentTypeOptions } from '../../../pages/segments-container/constant'

const { Option } = Select
const SegmentsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: ISegmentsFilterParams) => void
  formData: ISegmentsFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: ISegmentsFilterParams) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="name" label="名字">
        <Input />
      </Form.Item>
      <Form.Item name="type" label="类型">
        <Select options={SegmentTypeOptions} style={{ minWidth: 130 }} allowClear></Select>
      </Form.Item>

      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SegmentsFilter
