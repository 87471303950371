import {
  SET_COUPON_TEMPLATE_DRAFT,
  CLEAR_COUPON_TEMPLATE_DRAFT,
  SET_ALL_COUPON_TEMPLATES,
} from './constants'
import { CouponTemplate } from '../../pages/coupon-templates-container/share-type'

export interface SetCouponTemplateDraftAction {
  type: SET_COUPON_TEMPLATE_DRAFT
  payload: any
}

export const setCouponTemplateDraftAction = (payload: any): SetCouponTemplateDraftAction => {
  return {
    type: SET_COUPON_TEMPLATE_DRAFT,
    payload,
  }
}

export interface ClearCouponTemplateDraftAction {
  type: CLEAR_COUPON_TEMPLATE_DRAFT
}

export const clearCouponTemplateDraftAction = (): ClearCouponTemplateDraftAction => ({
  type: CLEAR_COUPON_TEMPLATE_DRAFT,
})

export interface SetCouponTemplatesAction {
  type: SET_ALL_COUPON_TEMPLATES
  payload: CouponTemplate[]
}

export const setCouponTemplatesAction = (payload: CouponTemplate[]): SetCouponTemplatesAction => ({
  type: SET_ALL_COUPON_TEMPLATES,
  payload,
})

export type CouponTemplateAction =
  | SetCouponTemplateDraftAction
  | ClearCouponTemplateDraftAction
  | SetCouponTemplatesAction
