import {
  Button,
  Col,
  Row,
  Timeline,
  Rate,
  Avatar,
  Comment,
  Descriptions,
  Typography,
  Space,
  Image,
  Empty,
  Divider,
  notification,
  Tag,
} from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  auditBookingReview,
  deleteBookingReview,
  getBookingReviewInfo,
} from '../../../api/bookingReviewApi'
import AuditBookingReviewModal from '../../../components/booking-reviews/audit-booking-review-modal/audit-booking-review-modal'
import DeleteBookingReviewModal from '../../../components/booking-reviews/delete-booking-review-modal/delete-booking-review-modal'
import { LocaleOptions } from '../../../helpers/constant'
import { getDateTime } from '../../../helpers/utils'
import styles from './booking-review.module.less'
import { BookingReviewAuditStatusOptions } from '../constant'
import {
  AuditStatusEnum,
  IBookingReview,
  ILesson,
  IStudio,
  IBookingReviewAuditParams,
  IBookingReviewDeleteParams,
} from '../share-type'
import AuthPermission from '../../../high-order-components/auth-permission'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { PermissionsEnum } from '../../../share-types'

const { Text } = Typography

interface IBookingReviewLessonInfoProps {
  lesson: ILesson
}

const BookingReviewLessonInfo = ({ lesson }: IBookingReviewLessonInfoProps) => {
  const {
    lessonDescriptionDto: { lessonTranslations },
    id,
    startTime,
    createdAt,
    credits,
  } = lesson
  const cloumn = [
    {
      dataIndex: 'name',
      title: 'name',
    },
    {
      dataIndex: 'description',
      title: 'Description',
    },
    {
      dataIndex: 'specialNoteToUsers',
      title: 'Special Note To Users',
    },
  ]
  return (
    <Descriptions
      bordered
      title="课程信息"
      column={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      labelStyle={{ width: 220 }}
      contentStyle={{ width: '50%' }}
    >
      <Descriptions.Item label="ID">{id}</Descriptions.Item>
      <Descriptions.Item label="点数">{credits}点</Descriptions.Item>
      <Descriptions.Item label="创建时间">{getDateTime(createdAt)}</Descriptions.Item>
      <Descriptions.Item label="课程开始时间">{getDateTime(startTime)}</Descriptions.Item>
      {cloumn.map((_, index) => {
        return LocaleOptions.map((localeOption, i) => {
          const title = `${_.title}(${localeOption.label})`
          const item: any = lessonTranslations.find(t => t.locale === localeOption.value)
          return (
            <Descriptions.Item key={`${index}-${i}`} label={title}>
              {item?.[_.dataIndex]}
            </Descriptions.Item>
          )
        })
      })}
    </Descriptions>
  )
}

const BookingReviewStudioInfo = ({ studio }: { studio: IStudio }) => {
  const { studioTranslations, createdAt, id } = studio
  const cloumn = [
    {
      dataIndex: 'name',
      title: 'name',
    },
    {
      dataIndex: 'description',
      title: 'Description',
    },
    {
      dataIndex: 'address1',
      title: 'Address1',
    },
    {
      dataIndex: 'address2',
      title: 'Address2',
    },
    {
      dataIndex: 'howToFindStudio',
      title: 'how To Find Studio',
    },
  ]
  return (
    <Descriptions
      bordered
      title="场馆详情"
      column={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      labelStyle={{ width: 220 }}
      contentStyle={{ width: '50%' }}
    >
      <Descriptions.Item label="ID">{id}</Descriptions.Item>
      {cloumn.map((_, index) => {
        return LocaleOptions.map((localeOption, i) => {
          const title = `${_.title}(${localeOption.label})`
          const item: any = studioTranslations.find(t => t.locale === localeOption.value)
          return (
            <Descriptions.Item key={`${index}-${i}`} label={title}>
              {item?.[_.dataIndex]}
            </Descriptions.Item>
          )
        })
      })}

      <Descriptions.Item label="创建时间">{getDateTime(createdAt)}</Descriptions.Item>
    </Descriptions>
  )
}

interface IProps {
  bookingReview: IBookingReview
}

const BookingReviewDescriptions = (props: IProps) => {
  const { bookingReview } = props
  const {
    userDto: userInfo,
    comment,
    createdAt,
    bookingReviewRatings,
    lessonDto,
    studioDto,
    bookingReviewImages,
    isDeleted,
  } = bookingReview
  return (
    <>
      <Comment
        author={<a>{userInfo.firstName}</a>}
        avatar={<Avatar src={userInfo.avatarUrl} alt={userInfo.firstName} />}
        content={<p>{comment}</p>}
        datetime={
          <Space>
            {getDateTime(createdAt)}
            {isDeleted && <Tag color="red">已删除</Tag>}
          </Space>
        }
      />
      <Descriptions labelStyle={{ alignItems: 'center' }} column={1}>
        {bookingReviewRatings.map(_ => {
          return (
            <Descriptions.Item key={_.id} label={_.labelName}>
              <Rate disabled defaultValue={_.stars} />
            </Descriptions.Item>
          )
        })}
      </Descriptions>

      <Image.PreviewGroup>
        <Space wrap>
          {bookingReviewImages &&
            bookingReviewImages.map((image, index) => (
              <Image width={200} src={image.originalImageUrl} key={index} />
            ))}
        </Space>
      </Image.PreviewGroup>

      <Divider></Divider>
      <BookingReviewLessonInfo lesson={lessonDto} />
      <Divider></Divider>
      <BookingReviewStudioInfo studio={studioDto} />
    </>
  )
}

const BookingReview = (props: ConnectedProps<typeof withConnect>) => {
  const { rejectReasons } = props
  const [bookingReview, setBookingReviews] = useState<IBookingReview>()
  const [visibleAuditModal, setVisibleAuditModal] = useState<boolean>(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteBookingReviewLoading, setDeleteBookingReviewLoading] = useState<boolean>(false)
  const params = useParams()
  const navigate = useNavigate()
  const bookingReviewId = params?.id

  const getBookingReviewInfoAction = () => {
    getBookingReviewInfo(Number(bookingReviewId)).then(res => {
      setBookingReviews(res)
    })
  }

  useEffect(() => {
    getBookingReviewInfoAction()
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  if (!bookingReview) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  const onAudit = () => {
    setVisibleAuditModal(true)
  }

  const onDelete = () => {
    setVisibleDeleteModal(true)
  }

  const handleOk = (value: IBookingReviewAuditParams) => {
    setLoading(true)
    auditBookingReview(bookingReview.id, value)
      .then(() => {
        setLoading(false)
        setVisibleAuditModal(false)
        notification.success({
          message: '审核成功',
        })

        getBookingReviewInfoAction()
      })
      .catch(error => {
        setLoading(false)
        setVisibleAuditModal(false)
        notification.error({
          message: '审核失败',
          description: error.message ?? '',
        })
      })
  }

  const handleCancel = () => {
    setVisibleAuditModal(false)
  }

  const onDeleteHandleOk = (value: IBookingReviewDeleteParams) => {
    setDeleteBookingReviewLoading(true)
    deleteBookingReview(bookingReview.id, value.deleteReason)
      .then(() => {
        setDeleteBookingReviewLoading(false)
        setVisibleDeleteModal(false)
        notification.success({
          message: '删除评论成功',
        })
        getBookingReviewInfoAction()
      })
      .catch(error => {
        setLoading(false)
        setVisibleAuditModal(false)
        notification.error({
          message: '删除评论失败',
          description: error.message ?? '',
        })
      })
  }

  const onDeleteHandleCancel = () => {
    setVisibleDeleteModal(false)
  }

  const auditLabel = BookingReviewAuditStatusOptions.find(
    _ => _.value === bookingReview.auditStatus,
  )

  return (
    <div className={styles['booking-review-desc-wrapper']}>
      <Row>
        <Col span={24} sm={{ span: 18 }}>
          <BookingReviewDescriptions bookingReview={bookingReview} />
        </Col>
        <Col span={24} sm={{ span: 6 }}>
          <Timeline
            pending={
              bookingReview.auditStatus === AuditStatusEnum.PENDING && !bookingReview.isDeleted
                ? '等待审核...'
                : ''
            }
            className={styles['timeline']}
          >
            <Timeline.Item>创建时间: {getDateTime(bookingReview.createdAt)}</Timeline.Item>
            {bookingReview.reviewedAt && (
              <Timeline.Item>用户浏览时间: {getDateTime(bookingReview.reviewedAt)}</Timeline.Item>
            )}
            {bookingReview.auditStatus === AuditStatusEnum.APPROVED && (
              <Timeline.Item>
                <Text type="success">{auditLabel?.label}</Text>
              </Timeline.Item>
            )}

            {bookingReview.auditStatus === AuditStatusEnum.REJECTED && (
              <Timeline.Item>
                <Space direction="vertical">
                  <Text type="danger">{auditLabel?.label}</Text>
                  <Text type="secondary">{bookingReview.rejectReason}</Text>
                </Space>
              </Timeline.Item>
            )}

            {bookingReview.isDeleted && (
              <Timeline.Item>
                <Text type="danger">已删除</Text>
                <Text type="secondary">{bookingReview.deleteReason}</Text>
              </Timeline.Item>
            )}
          </Timeline>
        </Col>
      </Row>

      <div className={styles['button-group']}>
        <Space align="center">
          {bookingReview.auditStatus === AuditStatusEnum.PENDING && (
            <AuthPermission permission={PermissionsEnum.BOOKING_REVIEWS_WRITE}>
              <Button type="primary" onClick={onAudit}>
                审核
              </Button>
            </AuthPermission>
          )}

          <Button onClick={goBack}>返回</Button>

          {!bookingReview.isDeleted && (
            <AuthPermission permission={PermissionsEnum.BOOKING_REVIEWS_WRITE}>
              <Button type="primary" danger onClick={onDelete}>
                删除
              </Button>
            </AuthPermission>
          )}
        </Space>
      </div>

      <AuditBookingReviewModal
        visible={visibleAuditModal}
        loading={loading}
        rejectReasons={rejectReasons}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <DeleteBookingReviewModal
        visible={visibleDeleteModal}
        loading={deleteBookingReviewLoading}
        handleOk={onDeleteHandleOk}
        handleCancel={onDeleteHandleCancel}
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    rejectReasons: state.global.rejectReasons,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(BookingReview)
