export enum ApplicationTypeEnums {
  USER_MINI_PROGRAM = 'USER_MINI_PROGRAM',
  STUDIO_ADMIN_MINI_PROGRAM = 'STUDIO_ADMIN_MINI_PROGRAM',
}

export const ApplicationTypeOptions = [
  {
    label: '用户小程序',
    value: ApplicationTypeEnums.USER_MINI_PROGRAM,
  },
  {
    label: '场馆管理小程序',
    value: ApplicationTypeEnums.STUDIO_ADMIN_MINI_PROGRAM,
  },
]
