import { notification, PageHeader, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { PrelaunchCompanyPaymentDetailFormValues } from '../../../prelaunch-organization-forms-container/share-types'
import { createPrelaunchCompanyPaymentDetails } from '../../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../../i18n'
import PaymentDetailForm from '../../../../components/common/payment-detail-form'

const prelaunchPaymentDetailForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [uniqueId] = useState<string | undefined>(params?.uniqueId)
  const [organizationName] = useState<string | undefined>(params?.organizationName)
  const onFinish = (values: PrelaunchCompanyPaymentDetailFormValues) => {
    if (!uniqueId) {
      return
    }

    createPrelaunchCompanyPaymentDetails({
      ...values,
      uniqueId: uniqueId,
    })
      .then(() => {
        notification.success({
          message: translate('addCompanyPaymentDetail.success'),
        })
        navigate(-1)
      })
      .catch(err => {
        notification.error({
          message: translate('addCompanyPaymentDetail.error'),
          description: err?.message || translate('networkError'),
        })
      })
  }

  const onBack = () => {
    navigate(-1)
  }
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <PageHeader
        className="site-page-header"
        onBack={onBack}
        title={organizationName}
        subTitle={translate('addCompanyPaymentDetail')}
      />
      <PaymentDetailForm onFinish={onFinish} />
    </Space>
  )
}

export default prelaunchPaymentDetailForm
