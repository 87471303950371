import { SearchTrendableTypeEnums, SearchTrendStatusEnums } from './share-type'

export const SearchTrendableTypeOptions = [
  {
    label: '场馆',
    value: SearchTrendableTypeEnums.STUDIOS,
  },
  {
    label: '课程',
    value: SearchTrendableTypeEnums.LESSON_DESCRIPTIONS,
  },
]

export const SearchTrendsStatusOptions = [
  {
    label: SearchTrendStatusEnums.ACTIVE,
    value: SearchTrendStatusEnums.ACTIVE,
  },
  {
    label: SearchTrendStatusEnums.EXPIRED,
    value: SearchTrendStatusEnums.EXPIRED,
  },
  {
    label: SearchTrendStatusEnums.PENDING,
    value: SearchTrendStatusEnums.PENDING,
  },
]
