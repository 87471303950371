import WebAppRouter from './router'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import Loading from './components/loading'
import { IntlProvider } from 'react-intl'
import { messages } from './i18n'
import { ReduxStore } from './store'
import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN'
import { IAdminUser, IStudioAreaUserDto, LocaleType } from './share-types'
import { setUserInfoAction } from './store/userInfo/action'
import { useEffect } from 'react'
import { getAdminUserProfile } from './api/authApi'
import { getStudioAdminUserInfo } from './studios-area-api/userInfo'
import { setStudioAdminUserInfoAction } from './store/studio-admin-user-info/action'
import { useLocation } from 'react-router-dom'

function App(props: ConnectedProps<typeof withConnect>) {
  const {
    spinner,
    global: { locale },
    auth,
  } = props
  const location = useLocation()
  const configLocale: any = {
    [LocaleType.en]: en,
    [LocaleType.zh_CN]: zhCN,
  }

  useEffect(() => {
    if (auth && auth.accessToken) {
      if (location.pathname.includes('/studios-area')) {
        getStudioAdminUserInfo().then((res: IStudioAreaUserDto) => {
          props.setStudioAdminUserInfoAction(res)
        })
      } else {
        getAdminUserProfile().then((res: IAdminUser) => {
          props.setUserInfoAction(res)
        })
      }
    }
  }, [auth.accessToken])

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <ConfigProvider locale={configLocale[locale]}>
        <div>
          <WebAppRouter />
          <Loading loading={spinner.show || spinner.requestCount > 0} />
        </div>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapDispatchToProps = {
  setUserInfoAction,
  setStudioAdminUserInfoAction,
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    spinner: state.spinner,
    global: state.global,
    userInfo: state.userInfo,
    auth: state.auth,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(App)
