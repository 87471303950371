import { Permission, Role } from '../../pages/admin-roles-container/share-type'
import { IRejectReason } from '../../pages/booking-reviews-container/share-type'
import { LocaleType } from '../../share-types'
import {
  SET_LOCALE_KEY,
  SET_PERMISSIONS_KEY,
  SET_PLANS_KEY,
  SET_ROLES_KEY,
  SET_REJECT_REASONS_KEY,
  SET_CURRENT_MENU_TYPE_KEY,
} from './action-keys'
import { Plan } from './reducer'

export interface SetLocaleAction {
  type: SET_LOCALE_KEY
  locale: LocaleType
}

export const setLocaleAction = (locale: LocaleType): SetLocaleAction => ({
  type: SET_LOCALE_KEY,
  locale,
})

export interface SetPlansAction {
  type: SET_PLANS_KEY
  plans: Plan[]
}

export const setPlansAction = (plans: Plan[]): SetPlansAction => ({
  type: SET_PLANS_KEY,
  plans,
})

export interface SetPermissionsAction {
  type: SET_PERMISSIONS_KEY
  permissions: Permission[]
}

export const setPermissionsAction = (permissions: Permission[]): SetPermissionsAction => ({
  type: SET_PERMISSIONS_KEY,
  permissions,
})

export interface SetRolesAction {
  type: SET_ROLES_KEY
  roles: Role[]
}

export const setRolesAction = (roles: Role[]): SetRolesAction => ({
  type: SET_ROLES_KEY,
  roles,
})

export interface SetRejectReasonsAction {
  type: SET_REJECT_REASONS_KEY
  rejectReasons: IRejectReason[]
}

export const setRejectReasonsAction = (rejectReasons: IRejectReason[]): SetRejectReasonsAction => ({
  type: SET_REJECT_REASONS_KEY,
  rejectReasons,
})

export interface SetCurrentMenuTypeAction {
  type: SET_CURRENT_MENU_TYPE_KEY
  currentMenuType: string
}

export const setCurrentMenuTypeAction = (currentMenuType: string): SetCurrentMenuTypeAction => ({
  type: SET_CURRENT_MENU_TYPE_KEY,
  currentMenuType,
})

export type GlocalAction =
  | SetLocaleAction
  | SetPlansAction
  | SetPermissionsAction
  | SetRolesAction
  | SetRejectReasonsAction
  | SetCurrentMenuTypeAction
