import { SET_STUDIO_ADMIN_USER_INFO, CLEAR_STUDIO_ADMIN_USER_INFO } from './constants'
import update from 'immutability-helper'
import { IStudioAreaUserDto } from '../../share-types'
import { StudioAdminUserInfoAction } from './action'

const initialState: IStudioAreaUserDto | null = null
function studioAdminUserInfoReducer(state = initialState, action: StudioAdminUserInfoAction) {
  switch (action.type) {
    case SET_STUDIO_ADMIN_USER_INFO:
      return update(state, { $set: action.payload })
    case CLEAR_STUDIO_ADMIN_USER_INFO:
      return Object.assign({}, initialState)
    default:
      return state
  }
}

export default studioAdminUserInfoReducer
