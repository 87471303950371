import { Button, Form, Select, Input } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { ISegmentRulesFilterParams } from '../../../pages/segment-rules-container/share-type'
import DebounceSelect from '../../debounce-select/debounce-select'
import { getOptions } from '../../../helpers/utils'
import { getSegmentSuggestion } from '../../../api/segmentApi'
import { ReduxStore } from '../../../store'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'

const { Option } = Select

interface IProps extends ConnectedProps<typeof withConnect> {
  onSearch: (value: ISegmentRulesFilterParams) => void
  formData: ISegmentRulesFilterParams | undefined
}

const SegmentRulesFilter = ({ onSearch, formData, firstPageSegments }: IProps) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: ISegmentRulesFilterParams) => {
    onSearch(values)
  }
  async function fetchSegmentsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getSegmentSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }

  return (
    <Form form={form} className="filter-inline-form" layout="inline" onFinish={onFinish}>
      <Form.Item name="name" label="名字">
        <Input />
      </Form.Item>
      <Form.Item name="segmentId" label="用户分组">
        <DebounceSelect
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="搜索用户分组"
          fetchOptions={fetchSegmentsSuggestionOptions}
          defaultOptions={getOptions(firstPageSegments)}
        />
      </Form.Item>

      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    firstPageSegments: state.segments.firstPageSegments,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(SegmentRulesFilter)
