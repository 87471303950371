import translate from '../../i18n'
import { StudioLeaderboardStatusEnum } from './share-type'

export const StudioLeaderboardStatusOptions = [
  {
    text: '待审核',
    label: '待审核',
    value: StudioLeaderboardStatusEnum.PENDING,
  },
  {
    text: '通过',
    label: '通过',
    value: StudioLeaderboardStatusEnum.APPROVED,
  },
  {
    text: '拒绝',
    label: '拒绝',
    value: StudioLeaderboardStatusEnum.REJECTED,
  },
  {
    text: '已过期',
    label: '已过期',
    value: StudioLeaderboardStatusEnum.EXPIRED,
  },
]

export const allCatroeyOption = {
  id: -1,
  name: translate('studioLeaderboards.allCategories'),
}

export const allNeighborhoodOption = {
  value: -1,
  label: translate('studioLeaderboards.allNeighborhoods'),
}
