import { Button, Form, Select } from 'antd'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'
import {
  IStudioIntegrationConfigFilterParams,
  IntegratorTypeEnum,
} from '../../../pages/studio-integration-config-container/share-types'
import { useEffect } from 'react'
const { Option } = Select

const StudioIntegrationConfigFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IStudioIntegrationConfigFilterParams) => void
  formData: IStudioIntegrationConfigFilterParams | undefined
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  const onReset = () => {
    onSearch({})
  }

  return (
    <Form
      form={form}
      layout="inline"
      className="filter-inline-form"
      onFinish={onSearch}
      onReset={onReset}
    >
      <Form.Item name="type" label={translate('studioIntegrationConfig.integratorType')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {Object.values(IntegratorTypeEnum).map(value => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          <FormattedMessage id="search"></FormattedMessage>
        </Button>
      </Form.Item>

      <Form.Item>
        <Button htmlType="reset" type="default" icon={<CloseCircleOutlined />}>
          <FormattedMessage id="reset"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default StudioIntegrationConfigFilter
