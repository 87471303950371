import { notification } from 'antd'
import StudioForm from '../../../components/common/studio-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PrelaunchStudioCreationFormValues } from '../../prelaunch-organization-forms-container/share-types'
import {
  editPrelaunchStudioDetail,
  getPrelaunchStudioDetail,
} from '../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../i18n'
import { convertIPrelaunchStudioToFormValues } from '../../../helpers/prelaunch-organization-helper'

const PrelaunchStudioForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [prelaunchStudioId] = useState<string | undefined>(params?.prelaunchStudioId)
  const [prelaunchOrganizationId] = useState<string | undefined>(params?.id)
  const [prelaunchStudioFormData, setPrelaunchStudioFormData] =
    useState<PrelaunchStudioCreationFormValues>()

  const [organizationUniqueId, setOrganizationUniqueId] = useState<string | undefined>()

  useEffect(() => {
    if (prelaunchStudioId) {
      getPrelaunchStudioDetail(Number(prelaunchStudioId)).then(data => {
        setOrganizationUniqueId(data.prelaunchOrganizationFormUniqueId)
        setPrelaunchStudioFormData(convertIPrelaunchStudioToFormValues(data))
      })
    }
  }, [prelaunchStudioId])

  const onFinish = (value: PrelaunchStudioCreationFormValues) => {
    if (!prelaunchStudioId) {
      return
    }

    editPrelaunchStudioDetail(prelaunchStudioId, value)
      .then(response => {
        if (response) {
          notification.success({
            message: 'Edit Studios Success',
          })
          navigate(
            `/prelaunch-organization-forms/${prelaunchOrganizationId}/studios/${prelaunchStudioId}`,
          )
        }
      })
      .catch(err => {
        notification.error({
          message: 'Edit Studios Failed',
          description: err?.message || translate('networkError'),
        })
      })
  }

  if (!organizationUniqueId) {
    return null
  }

  return (
    <StudioForm
      isEdit={true}
      organizationUniqueId={organizationUniqueId}
      onFinish={onFinish}
      formData={prelaunchStudioFormData}
    />
  )
}

export default PrelaunchStudioForm
