import config from '../config/index'
import {
  IPaginatedStudioLeaderboardsParams,
  IStudioLeaderboard,
  IStudioLeaderboardRankingType,
  PaginatedStudioLeaderboardsResponse,
  StudioLeaderboardRequest,
  StudioLeaderboardStatusEnum,
} from '../pages/studio-leaderboards-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedStudioLeaderboards = (data: IPaginatedStudioLeaderboardsParams) =>
  authRequest.get<MISSING_TYPE, PaginatedStudioLeaderboardsResponse>(
    `${mainApi}/studio-leaderboards`,
    {
      params: data,
    },
  )

export const getStudioLeaderboardDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IStudioLeaderboard>(`${mainApi}/studio-leaderboards/${id}`)

export const createStudioLeaderboard = (batchMode: boolean, data: StudioLeaderboardRequest) =>
  authRequest.post<MISSING_TYPE, IStudioLeaderboard>(
    `${mainApi}/studio-leaderboards?batchMode=${batchMode}`,
    data,
  )

export const updateStudioLeaderboardStatus = (
  id: number,
  data: { status: StudioLeaderboardStatusEnum },
) => authRequest.put(`${mainApi}/studio-leaderboards/${id}`, data)

export const editStudioLeaderboard = (id: number, data: StudioLeaderboardRequest) =>
  authRequest.put<MISSING_TYPE, IStudioLeaderboard>(`${mainApi}/studio-leaderboards/${id}`, data)

export const getStudioLeaderboardRankingTypes = () =>
  authRequest.get<MISSING_TYPE, IStudioLeaderboardRankingType[]>(
    `${mainApi}/studio-leaderboard-ranking-types`,
  )

export const batchApproveStudioLeaderboards = () =>
  authRequest.post<MISSING_TYPE, MISSING_TYPE>(`${mainApi}/studio-leaderboards/batch-approve`)
