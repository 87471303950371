import moment from 'moment'

import {
  ILessonCreditAdjustment,
  LessonCreditAdjustmentCreationFormValues,
  LessonCreditAdjustmentRequest,
  LessonCreditAdjustmentRule,
  LessonCreditAdjustmentRuleTypeEnum,
} from '../pages/lesson-credit-adjustments-container/share-type'
import { keysToCamel, keysToSnake } from './keys-to-case'

export const convertLessonCreditAdjustmentCreationFormValuesToRequest = (
  lessonCreditAdjustment: LessonCreditAdjustmentCreationFormValues,
): LessonCreditAdjustmentRequest => {
  return {
    isActive: lessonCreditAdjustment.isActive,
    adjustmentType: lessonCreditAdjustment.adjustmentType,
    adjustableType: lessonCreditAdjustment.adjustableType,
    adjustableId: lessonCreditAdjustment.adjustableId,
    rule: keysToSnake(convertLessonCreditAdjustmentFormValuesToRule(lessonCreditAdjustment)),
    billingItemPricing: lessonCreditAdjustment.billingItemPricingEnabled
      ? {
          completedClassPriceCents:
            lessonCreditAdjustment.billingItemPricing.completedClassPriceCents,
          lateCancelledPriceCents:
            lessonCreditAdjustment.billingItemPricing.lateCancelledPriceCents,
          earlyVisitPriceCents: lessonCreditAdjustment.billingItemPricing.earlyVisitPriceCents,
          noShowPriceCents: lessonCreditAdjustment.billingItemPricing.noShowPriceCents,
        }
      : null,
  }
}

export const convertLessonCreditAdjustmentFormValuesToRule = (
  lessonCreditAdjustment: LessonCreditAdjustmentCreationFormValues,
): LessonCreditAdjustmentRule => {
  return {
    type: LessonCreditAdjustmentRuleTypeEnum.PEAK_OFF_PEAK,
    adjustCredits: lessonCreditAdjustment.adjustCredits,
    lessonDescriptionIds: lessonCreditAdjustment.lessonDescriptionIds,
    startDate: lessonCreditAdjustment.lessonDateRange
      ? lessonCreditAdjustment.lessonDateRange[0]?.format('YYYY-MM-DD')
      : undefined,
    endDate: lessonCreditAdjustment.lessonDateRange
      ? lessonCreditAdjustment.lessonDateRange[1]?.format('YYYY-MM-DD')
      : undefined,
    lessonStartTimeFrom: lessonCreditAdjustment.lessonStartTimeRange
      ? lessonCreditAdjustment.lessonStartTimeRange[0]?.format('HH:mm:ss')
      : undefined,
    lessonStartTimeTo: lessonCreditAdjustment.lessonStartTimeRange
      ? lessonCreditAdjustment.lessonStartTimeRange[1]?.format('HH:mm:ss')
      : undefined,
    daysOfWeek: lessonCreditAdjustment.daysOfWeek,
  }
}

export const convertLessonCreditAdjustmentToFormValues = (
  lessonCreditAdjustment: ILessonCreditAdjustment,
): LessonCreditAdjustmentCreationFormValues => {
  const data = keysToCamel(lessonCreditAdjustment)

  return {
    adjustmentType: data.adjustmentType,
    adjustableId: data.adjustableId,
    adjustableType: data.adjustableType,
    isActive: data.isActive,
    adjustCredits: data.rule.adjustCredits,
    lessonDescriptionIds: data.rule.lessonDescriptionIds,
    daysOfWeek: data.rule.daysOfWeek,
    lessonDateRange:
      data.rule.startDate && data.rule.endDate
        ? [moment(data.rule.startDate), moment(data.rule.endDate)]
        : undefined,
    lessonStartTimeRange:
      data.rule.lessonStartTimeFrom && data.rule.lessonStartTimeTo
        ? [
            moment(data.rule.lessonStartTimeFrom, 'HH:mm:ss'),
            moment(data.rule.lessonStartTimeTo, 'HH:mm:ss'),
          ]
        : undefined,
    billingItemPricingEnabled: data.billingItemPricing?.isActive ?? false,
    billingItemPricing: data.billingItemPricing || {
      completedClassPriceCents: 0,
      lateCancelledPriceCents: 0,
      earlyVisitPriceCents: 0,
      noShowPriceCents: 0,
    },
  }
}
