import { Button, Modal, notification, Popover, Row, Space, Table, Typography } from 'antd'
import {
  getDateTime,
  getSearchParams,
  getSearchValues,
  renderNullableValue,
} from '../../../helpers/utils'
import { ColumnsType } from 'antd/lib/table'
import {
  CreditsAdjustmentRequest,
  ExchangeRateAdjustmentRequestParams,
  ExchangeRateMetricFilter,
  IPaginatedStudiosCreditsExchangeRatesListParams,
  StudiosCreditsExchangeRatesDto,
} from '../share-type'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import ExchangeRateAdjustmentFormModal from '../../../components/exchange-rates/exchange-rate-adjustment-form-modal/exchange-rate-adjustment-form-modal'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  exchangeRateAdjustment,
  getStudiosCreditsExchangeRates,
} from '../../../api/exchangeRatesApi'
import AuthPermission from '../../../high-order-components/auth-permission'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { InfoCircleOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import { CreditsAdjustmentType } from '../constant'
import LinkButton from '../../../components/link-button/link-button'
import ExchangeRatesFilter from '../../../components/exchange-rates/exchange-rates-filter/exchange-rates-filter'
import { ConnectedProps, connect } from 'react-redux'
import { ReduxStore } from '../../../store'
import { compose } from 'redux'
import { handleApiError } from '../../../helpers/api-helper'

import { PAGINATION_CONFIG, paginationUtils } from '../../../helpers/pagination-helper'

const { DEFAULT } = PAGINATION_CONFIG

const defaultPage = {
  pageSize: DEFAULT.PAGE_SIZE,
  currentPage: DEFAULT.CURRENT_PAGE,
  count: DEFAULT.TOTAL,
}

const { Text } = Typography

interface ModalState {
  open: boolean
  loading: boolean
  studio?: StudiosCreditsExchangeRatesDto
}

const ExchangeRatesStudios = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale } = props
  const [studios, setStudios] = useState<StudiosCreditsExchangeRatesDto[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const params = useParams()
  const [organizationId] = useState<string | undefined>(params?.id)

  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    loading: false,
    studio: undefined,
  })
  const navigate = useNavigate()

  const [searchValues, setSearchValues] = useState<ExchangeRateMetricFilter>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'orderBy', isBoolean: false },
        { key: 'orderDirection', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    getStudiosCreditsExchangeRatesAction(data)
  }, [])

  const getStudiosCreditsExchangeRatesAction = (
    data: IPaginatedStudiosCreditsExchangeRatesListParams,
  ) => {
    if (!organizationId) {
      return
    }

    getStudiosCreditsExchangeRates(Number(organizationId), data).then(res => {
      if (res) {
        setStudios(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: paginationUtils.toFrontendPage(res.pageInfo.currentPage),
        })
      }
    })
  }

  const getConfirmModalTitle = (studio: StudiosCreditsExchangeRatesDto) => {
    if (studio.isIntegrator && studio.hasLessonDescriptionUsedForMultipleStudios) {
      return translate('exchangeRates.adjuestment.studioTips1')
    } else if (studio.isIntegrator) {
      return translate('exchangeRates.integratorUpdateTitle')
    } else if (studio.hasLessonDescriptionUsedForMultipleStudios) {
      return translate('exchangeRates.adjuestment.studioTips2')
    } else {
      return ''
    }
  }

  const onChangeRate = (studio: StudiosCreditsExchangeRatesDto) => {
    const title = getConfirmModalTitle(studio)
    if (title) {
      Modal.confirm({
        title,
        content: translate('exchangeRates.updateContent'),
        cancelText: translate('cancel'),
        okText: translate('continue'),
        onOk() {
          setModalState(prev => ({
            ...prev,
            open: true,
            studio,
          }))
        },
      })
    } else {
      setModalState(prev => ({
        ...prev,
        open: true,
        studio,
      }))
    }
  }

  const buildAdjustmentRequest = (
    values: ExchangeRateAdjustmentRequestParams,
    studio: StudiosCreditsExchangeRatesDto,
  ) => {
    const baseRequest: CreditsAdjustmentRequest = {
      adjustableType: CreditsAdjustmentType.STUDIO,
      adjustableId: studio.studioId,
    }

    return values.rate
      ? {
          ...baseRequest,
          exchangeRateChangeFrom: studio.exchangeRate || 0,
          exchangeRateChangeTo: values.rate,
        }
      : {
          ...baseRequest,
          adjustCredits: values.adjustCredits,
        }
  }

  const onSubmit = useCallback(
    (values: ExchangeRateAdjustmentRequestParams) => {
      if (!organizationId || !modalState.studio) {
        return
      }
      const data = buildAdjustmentRequest(values, modalState.studio)

      setModalState(prev => ({ ...prev, loading: true }))

      exchangeRateAdjustment(data)
        .then(res => {
          if (res) {
            setModalState(prev => ({ ...prev, open: false, studio: undefined }))

            getStudiosCreditsExchangeRatesAction({
              ...searchValues,
              size: defaultPage.pageSize,
              page: defaultPage.currentPage - 1,
            })
            notification.success({
              message: translate('exchangeRates.updateSuccess'),
            })
          }
        })
        .catch(err => {
          handleApiError(err, translate('exchangeRates.updateFailed'), { showNotification: true })
        })
        .finally(() => {
          setModalState(prev => ({ ...prev, loading: false }))
        })
    },
    [organizationId, modalState.studio, searchValues],
  )

  const navigateToLessonDescriptions = (record: StudiosCreditsExchangeRatesDto) => {
    navigate(
      `/exchange-rates/organizations/${organizationId}/studios/${record.studioId}/lesson-descriptions`,
    )
  }

  const columns: ColumnsType<StudiosCreditsExchangeRatesDto> = useMemo(
    () => [
      {
        title: '场馆ID',
        dataIndex: 'studioId',
        key: 'studioId',
        sorter: (a: StudiosCreditsExchangeRatesDto, b: StudiosCreditsExchangeRatesDto) =>
          a.studioId - b.studioId,
        render: (studioId: number) => {
          return (
            <LinkButton to={`/studios/${studioId}`} style={{ padding: 0, textAlign: 'left' }}>
              {studioId}
            </LinkButton>
          )
        },
      },
      {
        title: translate('cityName'),
        dataIndex: 'cityName',
        key: 'cityName',
      },
      {
        title: translate('studioName'),
        dataIndex: 'studioName',
        key: 'studioName',
      },
      {
        title: translate('totalPayout'),
        dataIndex: 'totalPayoutInCents',
        key: 'totalPayoutInCents',
        render: (totalPayoutInCents: number | null) =>
          renderNullableValue(totalPayoutInCents, val => (val / 100).toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('exchangeRateTips')}>
            {translate('exchangeRate')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'exchangeRate',
        key: 'exchangeRate',
        render: (exchangeRate: number | null) =>
          renderNullableValue(exchangeRate, val => val.toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('costPerCreditTips')}>
            {translate('costPerCredit')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'costPerCredit',
        key: 'costPerCredit',
        render: (costPerCredit: number | null) =>
          renderNullableValue(costPerCredit, val => val.toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('fcfPercentageTips')}>
            {translate('fcfPercentage')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'blendedFcfPercentage',
        key: 'blendedFcfPercentage',
        render: (blendedFcfPercentage: number | null) =>
          renderNullableValue(blendedFcfPercentage, val => `${(val * 100).toFixed(2)}%`),
      },
      {
        title: (
          <Popover content={translate('earlyVisitRateTips')}>
            {translate('earlyVisitRate')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'earlyVisitRate',
        key: 'earlyVisitRate',
        render: (earlyVisitRate: number | null) =>
          renderNullableValue(earlyVisitRate, val => `${(val * 100).toFixed(2)}%`),
      },
      {
        title: translate('exchangeRates.isIntegrator'),
        dataIndex: 'isIntegrator',
        key: 'isIntegrator',
        render: (isIntegrator: boolean) => {
          return <YesOrNoTag value={isIntegrator} />
        },
      },
      {
        title: translate('activities'),
        dataIndex: 'activities',
        key: 'activities',
        render: (activities: string[]) => {
          return activities.join(',')
        },
      },
      {
        title: translate('updatedAt'),
        dataIndex: 'updatedTime',
        key: 'updatedTime',
        render: (updatedTime: string) => {
          return getDateTime(updatedTime)
        },
      },
      {
        title: translate('tableActionColumn'),
        key: 'action',
        render: (record: StudiosCreditsExchangeRatesDto) => (
          <Space size="middle">
            <Button type="link" onClick={() => navigateToLessonDescriptions(record)}>
              {translate('viewLessonDescriptionList')}
            </Button>

            <AuthPermission permission={PermissionsEnum.EXCHANGE_RATE_WRITE}>
              <Button type="link" onClick={() => onChangeRate(record)}>
                {translate('exchangeRates.update')}
              </Button>
            </AuthPermission>
          </Space>
        ),
      },
    ],
    [translate, navigateToLessonDescriptions, onChangeRate],
  )

  const paginationOnChange = useCallback(
    (page: number, pageSize: number) => {
      const data = {
        ...searchValues,
        size: paginationUtils.validatePageSize(pageSize),
        page: paginationUtils.toBackendPage(page),
      }
      setSearchParams(getSearchParams(data))
      getStudiosCreditsExchangeRatesAction(data)
    },
    [searchValues],
  )

  const onSearch = (values: ExchangeRateMetricFilter) => {
    const data = {
      ...values,
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(data))
    getStudiosCreditsExchangeRatesAction(data)
  }

  return (
    <>
      <Space className="table-space" direction="vertical" wrap size={16}>
        <Row align="middle">
          <div>{translate('studioList')}</div>
        </Row>

        <Row className="filter-bar">
          <ExchangeRatesFilter
            onSearch={onSearch}
            formData={searchValues}
            activeCities={activeCities}
            locale={locale}
            isOnlyShowSortableFields={true}
          />
        </Row>

        <Table
          rowKey={record => record.studioId.toString()}
          columns={columns}
          dataSource={studios}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
        />
      </Space>

      <ExchangeRateAdjustmentFormModal
        open={modalState.open}
        loading={modalState.loading}
        title={translate('exchangeRates.update')}
        subTitle="对所有被选择的场馆进行批量修改，修后会根据现有支付价除以新汇率以推算出新的点数"
        description={
          <Space direction="vertical">
            <div>
              已选场馆: <Text type="danger">{modalState.studio?.studioName}</Text>, 课程数量:{' '}
              <Text type="danger">{modalState.studio?.lessonDescriptionCount}</Text>
            </div>
            <div>
              当前汇率：<Text type="danger">{modalState.studio?.exchangeRate}</Text>
            </div>
          </Space>
        }
        onCancel={() => setModalState(prev => ({ ...prev, open: false, studio: undefined }))}
        onSubmit={onSubmit}
      />
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ExchangeRatesStudios)
