import { Button, Form, Input, notification, InputNumber, Switch, DatePicker, Radio } from 'antd'
import { OneTimeCodeBatchFormValue, OneTimeCodeStatus } from '../share-type'
import { useEffect, useState } from 'react'
import FormattedMessage from '../../../components/intl/format-message'
import translate from '../../../i18n'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createOneTimeCodeBatch,
  editOneTimeCodeBatch,
  getOneTimeCodeBatchDetail,
} from '../../../api/oneTimeCodeApi'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getSegmentSuggestion, getSegmentSuggestionsByIds } from '../../../api/segmentApi'
import { getOptions } from '../../../helpers/utils'
import { SegmentTypeEnum } from '../../segments-container/share-type'
import moment from 'moment'
import { OneTimeCodeStatusOptions } from '../constant'

const { RangePicker } = DatePicker

const OneTimeCodeBatchForm = () => {
  const [form] = Form.useForm()
  const params = useParams()
  const navigation = useNavigate()
  const [batchId] = useState<string | undefined>(params?.id)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [defaultSegmentOption, setDefaultSegmentOption] = useState<
    { label: string; value: number }[]
  >([])

  useEffect(() => {
    if (batchId) {
      getOneTimeCodeBatchDetail(Number(batchId)).then(res => {
        const { ikeaSegmentId } = res
        if (ikeaSegmentId) {
          asyncGetSegmentSuggestionsByIds([ikeaSegmentId]).then(res => {
            setDefaultSegmentOption(getOptions(res))
          })
        }
        form.setFieldsValue({
          ...res,
          timeRange:
            res.usableWindowStartTime && res.usableWindowEndTime
              ? [moment(res.usableWindowStartTime), moment(res.usableWindowEndTime)]
              : undefined,
        })
      })
    }
  }, [])

  async function fetchSegmentSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getSegmentSuggestion(keyword, SegmentTypeEnum.IKEA).then(res => {
      return getOptions(res)
    })
  }

  const asyncGetSegmentSuggestionsByIds = async (queryParams: number[]) => {
    const res = await getSegmentSuggestionsByIds(queryParams)
    return res
  }

  const onFinish = (values: OneTimeCodeBatchFormValue) => {
    const { timeRange, ...others } = values

    const requestData = {
      ...others,
      usableWindowStartTime: timeRange ? moment(timeRange[0]).utc().format() : null,
      usableWindowEndTime: timeRange ? moment(timeRange[1]).utc().format() : null,
    }

    if (batchId) {
      setLoadingButton(true)

      editOneTimeCodeBatch(Number(batchId), requestData)
        .then(res => {
          if (res) {
            notification.success({
              message: translate('oneTimeCodeBatches.updateSuccess'),
            })
            navigation(`/one-time-code-batches/${res.id}`)
          }
        })
        .catch(err => {
          notification.error({
            message: translate('oneTimeCodeBatches.updateError'),
            description: err?.message || translate('networkError'),
          })
        })
        .finally(() => {
          setLoadingButton(false)
        })
    } else {
      setLoadingButton(true)
      createOneTimeCodeBatch(requestData)
        .then(res => {
          if (res) {
            notification.success({
              message: translate('oneTimeCodeBatches.createSuccess'),
            })
            navigation(`/one-time-code-batches/${res.id}`)
          }
        })
        .catch(err => {
          notification.error({
            message: translate('oneTimeCodeBatches.createError'),
            description: err?.message || translate('networkError'),
          })
        })
        .finally(() => {
          setLoadingButton(false)
        })
    }
  }

  return (
    <Form
      layout="horizontal"
      name="one-time-code-batch-form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ isNewUserOnly: false, status: OneTimeCodeStatus.INTERNAL_ONLY }}
      form={form}
    >
      {!batchId && (
        <Form.Item name="prefix" label={translate('oneTimeCodeBatches.prefix')}>
          <Input style={{ width: 300 }} />
        </Form.Item>
      )}

      <Form.Item
        name="name"
        label={translate('oneTimeCodeBatches.name')}
        rules={[{ required: true, message: 'Please input name!' }]}
      >
        <Input style={{ width: 300 }} />
      </Form.Item>
      {!batchId && (
        <Form.Item
          name="numberOfCodes"
          label={translate('oneTimeCodeBatches.numberOfCodes')}
          rules={[{ required: true, message: 'Please input number of codes!' }]}
        >
          <InputNumber min={1} style={{ width: 300 }} />
        </Form.Item>
      )}
      <Form.Item
        name="credits"
        label={translate('oneTimeCodeBatches.credits')}
        rules={[{ required: true, message: 'Please input credits!' }]}
      >
        <InputNumber min={1} style={{ width: 300 }} />
      </Form.Item>

      <Form.Item
        name="isNewUserOnly"
        label={translate('oneTimeCodeBatches.isNewUserOnly')}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="creditsValidityPeriodInDays"
        label={translate('oneTimeCodeBatches.creditsValidityPeriodInDays')}
        rules={[{ required: true, message: 'Please input Credits Validity Period In Days!' }]}
      >
        <InputNumber min={1} style={{ width: 300 }} />
      </Form.Item>

      <Form.Item name="timeRange" label={translate('oneTimeCodeBatches.timeRange')}>
        <RangePicker
          showTime={{
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }}
        />
      </Form.Item>

      <Form.Item label="IKEA Segment" name="ikeaSegmentId">
        <DebounceSelect
          allowClear
          showSearch={true}
          placeholder="可通过id/name搜索用户分组"
          fetchOptions={fetchSegmentSuggestionOptions}
          style={{ width: 240, marginBottom: 0 }}
          defaultOptions={defaultSegmentOption}
        />
      </Form.Item>

      <Form.Item
        name="status"
        label={translate('oneTimeCodeBatches.status')}
        rules={[{ required: true, message: '请选状态' }]}
      >
        <Radio.Group>
          {OneTimeCodeStatusOptions.map(item => (
            <Radio key={item.value} value={item.value}>
              {item.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button loading={loadingButton} type="primary" htmlType="submit">
          <FormattedMessage id="submit" />
        </Button>
      </Form.Item>
    </Form>
  )
}

export default OneTimeCodeBatchForm
