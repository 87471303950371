import { Descriptions, Space } from 'antd'

import translate from '../../../i18n'
import {
  IStudioIntegrationLessonConfig,
  IStudioIntegrationLessonDescriptionConfig,
  StudioIntegrationConfigFieldsAutoSetRule,
} from '../../../pages/studio-integration-config-container/share-types'
import YesOrNoTag from '../../common/YesOrNoTag'
import { useEffect, useState } from 'react'
import { isStudioIntegrationLessonConfig } from '../../../helpers/studio-integration-config-helper'

interface Iprops {
  data: IStudioIntegrationLessonConfig | IStudioIntegrationLessonDescriptionConfig
}
const StudioIntegrationConfigDescription = (props: Iprops) => {
  const { data } = props
  const [autoSetRules, setAutoSetRules] = useState<StudioIntegrationConfigFieldsAutoSetRule[]>([])

  useEffect(() => {
    if (data) {
      setAutoSetRules(
        isStudioIntegrationLessonConfig(data)
          ? data.studioIntegrationLessonFieldsAutoSetRules
          : data.studioIntegrationLessonDescriptionFieldsAutoSetRules,
      )
    }
  }, [data])

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={16}>
        <Descriptions
          title={translate('studioIntegrationLessonConfig.detail')}
          labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
          contentStyle={{ minWidth: 300 }}
          column={2}
          bordered
        >
          <Descriptions.Item label="ID">{data.id}</Descriptions.Item>
          <Descriptions.Item label={translate('isActive')}>
            <YesOrNoTag value={data.isActive} />
          </Descriptions.Item>

          <Descriptions.Item label={translate('studioIntegrationConfig.fieldType')}>
            {data.fieldType}
          </Descriptions.Item>
          <Descriptions.Item label={translate('studioIntegrationConfig.fieldValue')}>
            {data.fieldValue}
          </Descriptions.Item>
        </Descriptions>

        {autoSetRules.map((item, index) => (
          <Descriptions
            key={index}
            title={translate('studioIntegrationConfig.rules') + (index + 1)}
            labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
            contentStyle={{ minWidth: 300 }}
            column={2}
            bordered
          >
            <Descriptions.Item label={translate('type')} span={2}>
              {item.rule.type}
            </Descriptions.Item>
            <Descriptions.Item label={translate('config')} span={2}>
              <pre>{JSON.stringify(item, undefined, 2)}</pre>
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Space>
    </>
  )
}

export default StudioIntegrationConfigDescription
