import qs from 'qs'

import config from '../config/index'
import {
  IPaginatedLessonCreditPricingFilterParams,
  LessonCreditPricingRequest,
  LessonCreditPricingResponseDto,
  PaginatedLessonCreditPricingResponse,
  UpdateLessonCreditPricingRequest,
} from '../pages/lesson-credit-pricings-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedLessonCreditPricings = (data: IPaginatedLessonCreditPricingFilterParams) =>
  authRequest.get<MISSING_TYPE, PaginatedLessonCreditPricingResponse>(
    `${mainApi}/lesson-credit-pricings`,
    {
      params: data,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const createLessonCreditPricing = (data: LessonCreditPricingRequest) =>
  authRequest.post<MISSING_TYPE, LessonCreditPricingResponseDto>(
    `${mainApi}/lesson-credit-pricings`,
    data,
  )

export const updateLessonCreditPricing = (data: UpdateLessonCreditPricingRequest) =>
  authRequest.put<MISSING_TYPE, LessonCreditPricingResponseDto>(
    `${mainApi}/lesson-credit-pricings`,
    data,
  )

export const bulkUploadLessonCreditPricing = (data: FormData) =>
  authRequest.post<MISSING_TYPE, LessonCreditPricingResponseDto>(
    `${mainApi}/lesson-credit-pricings/bulk-upload`,
    data,
  )
