import { CouponDiscountTypeEnums } from '../pages/coupon-templates-container/share-type'
import { keysToSnake } from './keys-to-case'

class CouponDiscount {
  name: string
  params: { [key: string]: any }
  constructor(name: string) {
    this.name = name
    this.params = {
      '@class': `com.classpass.api.entities.coupons.${this.name}`,
    }
  }

  getParams() {
    return this.params
  }
}

export interface PercentageOffPlan {
  planId: number
  price: number
}

export class PercentageOffDiscount extends CouponDiscount {
  plans: PercentageOffPlan[]
  constructor(plans: PercentageOffPlan[]) {
    super('PercentageOffDiscount')
    this.plans = plans
  }

  getParams(): { [key: string]: any } {
    return {
      ...this.params,
      ...keysToSnake({
        plans: this.plans,
        discountType: CouponDiscountTypeEnums.PERCENTAGE_OFF,
      }),
    }
  }
}

export class StackableCashOffDiscount extends CouponDiscount {
  offAmount: number
  stackable: boolean

  constructor(stackable: boolean, offAmount: number) {
    super('StackableCashOffDiscount')
    this.offAmount = offAmount
    this.stackable = stackable
  }

  getParams(): { [key: string]: any } {
    return {
      ...this.params,
      ...keysToSnake({
        offAmount: this.offAmount,
        stackable: this.stackable,
        discountType: CouponDiscountTypeEnums.STACKABLE_CASH_OFF,
      }),
    }
  }
}

export class CashOffDiscount extends CouponDiscount {
  maxAmount: number
  offAmount: number
  constructor(maxAmount: number, offAmount: number) {
    super('CashOffDiscount')
    ;(this.maxAmount = maxAmount), (this.offAmount = offAmount)
  }

  getParams(): { [key: string]: any } {
    return {
      ...this.params,
      ...keysToSnake({
        maxAmount: this.maxAmount,
        offAmount: this.offAmount,
        discountType: CouponDiscountTypeEnums.CASH_OFF,
      }),
    }
  }
}

export class GiftCardDiscount extends CouponDiscount {
  credits: number
  isRedeemImmediately: boolean
  constructor(credits: number, isRedeemImmediately = true) {
    super('GiftCardDiscount')
    this.credits = credits
    this.isRedeemImmediately = isRedeemImmediately
  }

  getParams(): { [key: string]: any } {
    return {
      ...this.params,
      ...keysToSnake({
        credits: this.credits,
        isRedeemImmediately: this.isRedeemImmediately,
        discountType: CouponDiscountTypeEnums.GIFT_CARD,
      }),
    }
  }
}

export interface BuyOnGetMorePlan {
  planId: number
  bonusCredits: number
}

export class BuyOneGetMoreDiscount extends CouponDiscount {
  plans: BuyOnGetMorePlan[]
  constructor(plans: BuyOnGetMorePlan[]) {
    super('BuyOneGetMoreDiscount')
    this.plans = plans
  }

  getParams(): { [key: string]: any } {
    return {
      ...this.params,
      ...keysToSnake({
        plans: this.plans,
        discountType: CouponDiscountTypeEnums.BUY_ONE_GET_MORE,
      }),
    }
  }
}
