import { Button, Col, Row, Space, Table, Modal, notification } from 'antd'
import { useEffect, useState } from 'react'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import translate from '../../../i18n'
import {
  deleteFirstClassDiscountDisabledLesson,
  getFirstClassDiscountDisabledLessons,
} from '../../../api/firstClassDiscountDisabledLessonsApi'
import {
  IFirstClassDiscountDisabledLesson,
  IFirstClassDiscountDisabledLessonsFilterParams,
  IPaginatedFirstClassDiscountDisabledLessonsFilterParams,
} from '../share-type'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getSearchParams, getSearchValues } from '../../../helpers/utils'
import FirstClassDiscountDisabledLessonsFilter from '../../../components/first-class-discount-disabled-lessons/first-class-discount-disabled-lessons-filter/first-class-discount-disabled-lessons-filter'
import { ColumnsType } from 'antd/lib/table'
import AuthPermission from '../../../high-order-components/auth-permission'
import styles from './first-class-discount-disabled-lessons.module.less'
import { PlusOutlined } from '@ant-design/icons'
import LinkButton from '../../../components/link-button/link-button'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const FirstClassDiscountDisabledLessons = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [firstClassDiscountDisabledLessons, setFirstClassDiscountDisabledLessons] = useState<
    IFirstClassDiscountDisabledLesson[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IFirstClassDiscountDisabledLessonsFilterParams>()
  const navigate = useNavigate()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'lessonDescriptionId', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )
    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getFirstClassDiscountDisabledLessonsAction(data)
  }, [])

  const getFirstClassDiscountDisabledLessonsAction = (
    data: IPaginatedFirstClassDiscountDisabledLessonsFilterParams,
  ) => {
    getFirstClassDiscountDisabledLessons(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setFirstClassDiscountDisabledLessons(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getFirstClassDiscountDisabledLessonsAction(data)
  }

  const onSearch = (value: IFirstClassDiscountDisabledLessonsFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(data))
    getFirstClassDiscountDisabledLessonsAction(data)
  }

  const toCreate = () => {
    navigate('/first-class-discount-disabled-lessons/new')
  }

  const onDelete = (id: number) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: translate('firstClassDiscountDisabledLessons.deleteConfirm'),
      okText: translate('confirm'),
      cancelText: translate('cancel'),
      onOk() {
        deleteFirstClassDiscountDisabledLesson(id)
          .then(() => {
            const data = {
              ...searchValues,
              size: pageInfo.pageSize,
              page: 0,
            }
            getFirstClassDiscountDisabledLessonsAction(data)
          })
          .then(() => {
            notification.success({
              message: translate('success'),
              description: translate('firstClassDiscountDisabledLessons.deleteSuccess'),
            })
          })
          .catch(err => {
            notification.error({
              message: translate('error'),
              description: err.message ?? translate('networkError'),
            })
          })
      },
    })
  }

  const columns: ColumnsType<IFirstClassDiscountDisabledLesson> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Lesson Description ID',
      dataIndex: 'lessonDescriptionId',
      key: 'lessonDescriptionId',
    },
    {
      title: '最小开始时间',
      dataIndex: 'minStartTime',
      key: 'minStartTime',
    },
    {
      title: '最大开始时间',
      dataIndex: 'maxStartTime',
      key: 'maxStartTime',
    },
    {
      title: '最小开始日期',
      dataIndex: 'minStartDate',
      key: 'minStartDate',
    },
    {
      title: '最大开始日期',
      dataIndex: 'maxStartDate',
      key: 'maxStartDate',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IFirstClassDiscountDisabledLesson) => (
        <Space size="middle">
          <AuthPermission permission={PermissionsEnum.FIRST_CLASS_DISCOUNTS_WRITE}>
            <LinkButton to={`/first-class-discount-disabled-lessons/${record.id}/edit`}>
              {translate('edit')}
            </LinkButton>
          </AuthPermission>
          <AuthPermission permission={PermissionsEnum.FIRST_CLASS_DISCOUNTS_WRITE}>
            <Button danger type="text" onClick={() => onDelete(record.id)}>
              {translate('delete')}
            </Button>
          </AuthPermission>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('firstClassDiscountDisabledLessons')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={styles['right-col']}>
          <AuthPermission permission={PermissionsEnum.COUPON_TEMPLATES_WRITE}>
            <Button onClick={toCreate} type="primary">
              <PlusOutlined />
              {translate('navBar.newFirstClassDiscountDisabledLesson')}
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      <Row className="filter-bar">
        <FirstClassDiscountDisabledLessonsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={firstClassDiscountDisabledLessons}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default FirstClassDiscountDisabledLessons
