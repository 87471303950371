import React from 'react'
import { Result, Button } from 'antd'
import translate from '../i18n'

interface Istate {
  hasError: boolean
}

class ErrorBoundary extends React.Component<any, Istate> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="500"
          subTitle={translate('errorPageSubTitle')}
          extra={
            <Button type="primary" href="/">
              {translate('goBackButton')}
            </Button>
          }
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
