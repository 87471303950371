import { Button, Descriptions, Divider, Space, Tag, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import YesOrNoTag from '../../common/YesOrNoTag'
import { PermissionsEnum } from '../../../share-types'
import { ITag } from '../../../pages/tags-container/share-type'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'

interface Iprops {
  tag: ITag
}

const TagDescriptions = (props: Iprops) => {
  const { tag } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/tags/${tag?.id}/edit`)
  }

  return (
    <>
      <Descriptions
        style={{ width: 1200 }}
        title="Tag详情"
        labelStyle={{ alignItems: 'center', fontWeight: 600 }}
        column={2}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.TAGS_WRITE}>
              <Button onClick={toEdit} type="primary">
                {translate('edit')}
              </Button>
            </AuthPermission>
          </Space>
        }
      >
        <Descriptions.Item label="ID">{tag.id}</Descriptions.Item>
        <Descriptions.Item label={translate('tags.name')}>{tag.name}</Descriptions.Item>
        <Descriptions.Item label={translate('tags.isActive')}>
          <YesOrNoTag value={tag.isActive} />
        </Descriptions.Item>

        <Descriptions.Item label={translate('tags.taggingsCount')}>
          {tag.taggingsCount}
        </Descriptions.Item>
        <Descriptions.Item label={translate('tags.cachedCategoryList')}>
          {tag.cachedCategoryList}
        </Descriptions.Item>
        <Descriptions.Item label={translate('tags.category')}>
          <Tag>{tag.category ?? 'N/A'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={translate('tags.subCategory')}>
          <Tag>{tag.subCategory ?? 'N/A'}</Tag>
        </Descriptions.Item>
      </Descriptions>

      <Divider style={{ fontSize: '14px' }}>{translate('tags.translations')}</Divider>

      {
        <Descriptions
          title={
            <Typography.Title level={5} style={{ margin: '0 !important' }}>
              <FormattedMessage id="tags.translation.name" />
            </Typography.Title>
          }
          labelStyle={{ alignItems: 'center', fontWeight: 600 }}
          style={{ width: 1200, marginTop: '25px' }}
          column={2}
          bordered
        >
          {tag.tagTranslations.map(item => {
            return (
              <Descriptions.Item key={item.locale} label={item.locale}>
                {item.name}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      }
    </>
  )
}

export default TagDescriptions
