import { SET_ACTIVE_CITIES } from './constants'
import { CitiesAction } from './action'
import update from 'immutability-helper'

export type CityTranslation = {
  createdAt: string
  id: number
  locale: string
  name: string
  updatedAt: string
}

export type City = {
  id: number
  latitude: number
  longitude: number
  status: number
  translations: CityTranslation[]
}

interface InitialState {
  activeCities: City[]
}

const initialState: InitialState = {
  activeCities: [],
}

function citiesReducer(state = initialState, action: CitiesAction) {
  switch (action.type) {
    case SET_ACTIVE_CITIES:
      return update(state, { $merge: { activeCities: action.payload } })
    default:
      return state
  }
}

export default citiesReducer
