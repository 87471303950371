import config from '../config/index'
import {
  IFeatureFlag,
  IPaginatedFeatureFlagsParams,
  PaginatedFeatureFlagsResponse,
} from '../pages/feature-flag-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getPaginatedFeatureFlags = (data: IPaginatedFeatureFlagsParams) =>
  authRequest.get<MISSING_TYPE, PaginatedFeatureFlagsResponse>(`${mainApi}/feature-flags`, {
    params: data,
  })

export const getFeatureFlagDetail = (id: number) =>
  authRequest.get<MISSING_TYPE, IFeatureFlag>(`${mainApi}/feature-flags/${id}`)

export const editFeatureFlag = (id: number, data: FormData) =>
  authRequest.put<MISSING_TYPE, IFeatureFlag>(`${mainApi}/feature-flags/${id}`, data)

export const changeFeatureFlagStatus = (id: number, isActive: boolean) =>
  authRequest.put<MISSING_TYPE, IFeatureFlag>(`${mainApi}/feature-flags/${id}/change-state`, null, {
    params: { isActive },
  })

export const deleteFeatureFlagById = (id: number) =>
  authRequest.delete<MISSING_TYPE, IFeatureFlag>(`${mainApi}/feature-flags/${id}`)
