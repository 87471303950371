import { Moment } from 'moment'
import { PageInfo } from '../../share-types'

export type IStudioIntegrationConfig = {
  id: number
  integratorType: string
  config: IStudioIntegrationConfigJson
  studioIntegrationLessonConfigs: IStudioIntegrationLessonConfig[]
  studioIntegrationLessonDescriptionConfigs: IStudioIntegrationLessonDescriptionConfig[]
  createdAt: string
  updatedAt: string
}

export type IStudioIntegrationConfigJson = {
  organization_ids: number[]
  allow_use_check_in_code_organization_ids: number[]
  zb_open_gym_lesson_description_ids: number[]
}

export type IStudioIntegrationLessonConfig = {
  id: number
  fieldValue: number
  fieldType: StudioIntegrationLessonConfigFieldTypeEnums
  studioIntegrationLessonFieldsAutoSetRules: StudioIntegrationConfigFieldsAutoSetRule[]
  isActive: boolean
  createdAt: string
  updatedAt: string
}

export type IStudioIntegrationLessonDescriptionConfig = {
  id: number
  fieldValue: number
  fieldType: StudioIntegrationLessonDescriptionFieldTypeEnums
  studioIntegrationLessonDescriptionFieldsAutoSetRules: StudioIntegrationConfigFieldsAutoSetRule[]
  isActive: boolean
  createdAt: Date
  updatedAt: Date
}

export enum StudioIntegrationLessonConfigFieldTypeEnums {
  CREDIT = 'CREDIT',
  CAPACITY = 'CAPACITY',
}

export enum StudioIntegrationLessonDescriptionFieldTypeEnums {
  CREDIT = 'CREDIT',
  COMPLETED_CLASS_PRICE_CENTS = 'COMPLETED_CLASS_PRICE_CENTS',
}

export type StudioIntegrationConfigFieldsAutoSetRule = {
  id: number
  rule: StudioIntegrationFieldsAutoSetRule
  createdAt: string
}

export enum StudioIntegrationFieldsAutoSetRuleEnum {
  TIME_RANGE_RULE = 'TIME_RANGE_RULE',
  SPECIFIC_LESSON_DESCRIPTIONS_RULE = 'SPECIFIC_LESSON_DESCRIPTIONS_RULE',
  SPECIFIC_ORGANIZATIONS_RULE = 'SPECIFIC_ORGANIZATIONS_RULE',
  SPECIFIC_STUDIOS_RULE = 'SPECIFIC_STUDIOS_RULE',
}

export const AutoSetRuleToTypeMap: Record<string, StudioIntegrationFieldsAutoSetRuleEnum> = {
  SpecificLessonDescriptionsRule:
    StudioIntegrationFieldsAutoSetRuleEnum.SPECIFIC_LESSON_DESCRIPTIONS_RULE,
  SpecificOrganizationsRule: StudioIntegrationFieldsAutoSetRuleEnum.SPECIFIC_ORGANIZATIONS_RULE,
  SpecificStudiosRule: StudioIntegrationFieldsAutoSetRuleEnum.SPECIFIC_STUDIOS_RULE,
  TimeRangeRule: StudioIntegrationFieldsAutoSetRuleEnum.TIME_RANGE_RULE,
}

export type IStudioIntegrationConfigFilterParams = {
  type?: string
}

export type IStudioIntegrationLessonConfigFilterParams = {
  studioIntegrationConfigId: string
  isActive?: boolean
}

export type IPaginatedStudioIntegrationConfigFilterParams = {
  page?: number
  size?: number
} & IStudioIntegrationConfigFilterParams

export type PaginatedStudioIntegrationConfigResponse = {
  pageInfo: PageInfo
  data: IStudioIntegrationConfig[]
}

export enum IntegratorTypeEnum {
  KAMAL_YOGA = 'KAMAL_YOGA',
  IWOD = 'IWOD',
  SPACE = 'SPACE',
  JUSTIN_JULIE = 'JUSTIN_JULIE',
  WILLS = 'WILLS',
  FIT_YOGA = 'FIT_YOGA',
  GOOD_FEELING = 'GOOD_FEELING',
  YOGAIN = 'YOGAIN',
  SANTI = 'SANTI',
  CHI_CHENG = 'CHI_CHENG',
  SHU_SHI_BAO = 'SHU_SHI_BAO',
  THE_BODY_LAB = 'THE_BODY_LAB',
  LAJIA = 'LAJIA',
}

export type StudioIntegrationFieldsAutoSetRule = {
  type: StudioIntegrationFieldsAutoSetRuleEnum
  organizationIds?: number[]
  lessonDescriptionIds?: number[]
  studioIds?: number[]
  startTime?: string
  endTime?: string
  timeRanges?: Moment[][]
}

export type StudioIntegrationLessonConfigRequest = {
  studioIntegrationConfigId: number | string
  fieldValue: number
  fieldType: StudioIntegrationLessonConfigFieldTypeEnums
  isActive?: boolean
  rules: StudioIntegrationFieldsAutoSetRule[]
}

export enum ConfigType {
  LESSON,
  LESSON_DESCRIPTION,
}
