import { Button, Form, InputNumber, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ICorporateMemberCreditsHistoryFilterParams } from '../../../pages/corporates-container/share-type'
import translate from '../../../i18n'
import {
  CorporateMemberCreditsHistoryReferenceTypeEnumsOptions,
  CorporateMemberCreditsHistoryTypeEnumsOptions,
} from '../../../pages/corporates-container/constant'

const { Option } = Select
const CorporateMemberCreditsHistoriesFilter = ({
  onSearch,
}: {
  onSearch: (value: ICorporateMemberCreditsHistoryFilterParams) => void
}) => {
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    onSearch(values)
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="type" label={translate('corporateMemberCreditsHistories.type')}>
        <Select style={{ minWidth: 130 }} allowClear>
          {CorporateMemberCreditsHistoryTypeEnumsOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="referenceId"
        label={translate('corporateMemberCreditsHistories.referenceId')}
      >
        <InputNumber style={{ minWidth: 130 }} />
      </Form.Item>

      <Form.Item
        name="referenceType"
        label={translate('corporateMemberCreditsHistories.referenceType')}
      >
        <Select style={{ minWidth: 130 }} allowClear>
          {CorporateMemberCreditsHistoryReferenceTypeEnumsOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CorporateMemberCreditsHistoriesFilter
