import { ReduxStore } from '../../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button, Col, Row, Space } from 'antd'
import { ICardConfig, ICardStack, IPaginatedCardConfigsParams } from '../share-type'
import { useEffect, useState } from 'react'
import CardStackDescriptions from '../../../components/card-stacks/card-stack-descriptions/card-stack-descriptions'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './card-stack.module.less'
import CardConfigsTable from '../../../components/card-configs/card-configs-table/card-configs-table'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PlusOutlined } from '@ant-design/icons'
import { getCardStackDetail } from '../../../api/cardStackApi'
import { getPaginatedCardConfigs } from '../../../api/cardConfigApi'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const CardStack = () => {
  const params = useParams()
  const [cardStackId] = useState<string | undefined>(params?.id)
  const [cardStack, setCardStack] = useState<ICardStack>()
  const [cardConfigs, setCardConfigs] = useState<ICardConfig[]>()
  const [cardConfigsPageInfo, setCardConfigsPageInfo] = useState<PageInfo>(defaultPage)
  const navigate = useNavigate()
  useEffect(() => {
    if (cardStackId) {
      getCardStackDetail(Number(cardStackId)).then(res => {
        setCardStack(res)
      })
      const data = {
        size: cardConfigsPageInfo?.pageSize,
        page: cardConfigsPageInfo?.currentPage - 1,
        cardStackId: Number(cardStackId),
      }
      getPaginatedCardConfigsAction(data)
    }
  }, [cardStackId])
  const goBack = () => {
    navigate(-1)
  }
  const getPaginatedCardConfigsAction = (data: IPaginatedCardConfigsParams) => {
    getPaginatedCardConfigs(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setCardConfigsPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setCardConfigs(res.data)
      }
    })
  }
  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      size: pageSize,
      page: page - 1,
      cardStackId: Number(cardStackId),
    }
    getPaginatedCardConfigsAction(data)
  }
  return (
    <Space className={styles['card-stack-desc-wrapper']} direction="vertical" wrap size={16}>
      {cardStack && <CardStackDescriptions cardStack={cardStack} />}
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <AuthPermission permission={PermissionsEnum.CARD_CONFIGS_WRITE}>
            <div>卡片设置</div>
          </AuthPermission>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <AuthPermission permission={PermissionsEnum.CARD_CONFIGS_WRITE}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate(`/card-configs/new?cardStackId=${cardStackId}`)}
            >
              创建新的卡片设置
            </Button>
          </AuthPermission>
        </Col>
      </Row>
      {cardConfigs && (
        <CardConfigsTable
          hasAction={false}
          cardConfigs={cardConfigs}
          pageInfo={cardConfigsPageInfo}
          paginationOnChange={paginationOnChange}
        />
      )}

      <Button className={styles['button-group']} onClick={goBack}>
        返回
      </Button>
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(CardStack)
