import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { Button, Descriptions, Typography, Space, Image } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
import AuthPermission from '../../../high-order-components/auth-permission'
import {
  IReferralCampaign,
  SenderRewardSchemeType,
} from '../../../pages/referral-campaign-container/share-type'
import moment from 'moment'
import YesOrNoTag from '../../common/YesOrNoTag'
import translate from '../../../i18n'
import { FormattedMessage } from '../../intl'
import { PermissionsEnum } from '../../../share-types'
import { DATE_TIME_FORMAT } from '../../../helpers/constant'
import styles from './referral-campaign-descriptions.module.less'
import React from 'react'

interface Iprops extends ConnectedProps<typeof withConnect> {
  referralCampaign: IReferralCampaign
}

const ReferralCampaignDescriptions = (props: Iprops) => {
  const { referralCampaign } = props
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/referral-campaigns/${referralCampaign?.id}/edit`)
  }

  return (
    <>
      <Descriptions
        style={{ width: 1200 }}
        title={translate('navBar.referralCampaignDetail')}
        labelStyle={{ alignItems: 'center', fontWeight: 600 }}
        column={4}
        bordered
        extra={
          <Space>
            <AuthPermission permission={PermissionsEnum.REFERRAL_CAMPAIGNS_WRITE}>
              <Button onClick={toEdit} type="primary">
                编辑
              </Button>
            </AuthPermission>
          </Space>
        }
      >
        <Descriptions.Item label="ID" span={2}>
          {referralCampaign?.id}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.name')} span={2}>
          {referralCampaign?.name}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.isActive')} span={2}>
          <YesOrNoTag value={referralCampaign?.isActive} />
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.isDefault')} span={2}>
          <YesOrNoTag value={referralCampaign?.isDefault} />
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.startDatetime')} span={2}>
          {moment(referralCampaign?.startDatetime).format(DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.endDatetime')} span={2}>
          {moment(referralCampaign?.endDatetime).format(DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('createdAt')} span={4}>
          {moment(referralCampaign?.createdAt).format(DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.pageBackgroundColor')}>
          {referralCampaign?.pageBackgroundColor && (
            <Space align="center">
              <div
                className="color-preview"
                style={{ background: referralCampaign?.pageBackgroundColor }}
              ></div>
              <span>{referralCampaign?.pageBackgroundColor}</span>
            </Space>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.savePosterButtonColor')}>
          {referralCampaign?.savePosterButtonColor && (
            <Space align="center">
              <div
                className="color-preview"
                style={{ background: referralCampaign?.savePosterButtonColor }}
              ></div>
              <span>{referralCampaign?.savePosterButtonColor}</span>
            </Space>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.sharingButtonColor')}>
          {referralCampaign?.sharingButtonColor && (
            <Space align="center">
              <div
                className="color-preview"
                style={{ background: referralCampaign?.sharingButtonColor }}
              ></div>
              <span>{referralCampaign?.sharingButtonColor}</span>
            </Space>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.generatingPosterButtonColor')}>
          {referralCampaign?.generatingPosterButtonColor && (
            <Space align="center">
              <div
                className="color-preview"
                style={{ background: referralCampaign?.generatingPosterButtonColor }}
              ></div>
              <span>{referralCampaign?.generatingPosterButtonColor}</span>
            </Space>
          )}
        </Descriptions.Item>
        {referralCampaign?.countDownBeforeMinutes && (
          <Descriptions.Item label={translate('referralCampaigns.countDownBeforeMinutes')}>
            {referralCampaign?.countDownBeforeMinutes}
          </Descriptions.Item>
        )}
      </Descriptions>

      {referralCampaign?.senderRewardScheme?.type && (
        <Descriptions
          title={
            <Typography.Title level={5} className={styles['section-title']}>
              <FormattedMessage id="referralCampaigns.senderRewardScheme" />
            </Typography.Title>
          }
          style={{ width: 1200, marginTop: '25px' }}
          labelStyle={{ alignItems: 'center', fontWeight: 600 }}
          column={3}
          bordered
        >
          <Descriptions.Item label={translate('type')} span={3}>
            {referralCampaign?.senderRewardScheme?.type}
          </Descriptions.Item>

          {referralCampaign?.senderRewardScheme?.type ===
            SenderRewardSchemeType.REFERRAL_CREDITS && (
            <Descriptions.Item label={translate('referralCampaigns.credits')} span={3}>
              {referralCampaign?.senderRewardScheme?.credits}
            </Descriptions.Item>
          )}

          {referralCampaign?.senderRewardScheme?.type ===
            SenderRewardSchemeType.RECEIVER_COUNT_BASED && (
            <>
              <Descriptions.Item label={translate('referralCampaigns.receiverCount')} span={2}>
                {referralCampaign?.senderRewardScheme?.receiverCount}
              </Descriptions.Item>
              <Descriptions.Item label={translate('referralCampaigns.couponTemplateId')} span={1}>
                <Link
                  to={`/coupon-templates/${referralCampaign?.senderRewardScheme?.couponTemplateId}`}
                >
                  {referralCampaign?.senderRewardScheme?.couponTemplateId}
                </Link>
              </Descriptions.Item>
            </>
          )}

          {referralCampaign?.senderRewardScheme?.type ===
            SenderRewardSchemeType.RECEIVER_COUNT_BASED_TIERED &&
            referralCampaign?.senderRewardScheme?.rewardTiers?.map((rewardTier, index) => (
              <React.Fragment key={index}>
                <Descriptions.Item label={'TIER'} span={1}>
                  {index + 1}
                </Descriptions.Item>
                <Descriptions.Item label={translate('referralCampaigns.receiverCount')} span={1}>
                  {rewardTier.receiverCount}
                </Descriptions.Item>
                <Descriptions.Item label={translate('referralCampaigns.couponTemplateId')} span={1}>
                  <Link to={`/coupon-templates/${rewardTier.couponTemplateId}`}>
                    {rewardTier.couponTemplateId}
                  </Link>
                </Descriptions.Item>
              </React.Fragment>
            ))}
        </Descriptions>
      )}

      <Descriptions
        title={
          <Typography.Title level={5} className={styles['section-title']}>
            <FormattedMessage id="referralCampaigns.receiverRewardScheme" />
          </Typography.Title>
        }
        style={{ width: 1200, marginTop: '25px' }}
        labelStyle={{ alignItems: 'center', fontWeight: 600 }}
        column={2}
        bordered
      >
        <Descriptions.Item label={translate('type')}>
          {referralCampaign?.receiverRewardScheme.type}
        </Descriptions.Item>
        <Descriptions.Item label={translate('referralCampaigns.couponTemplateId')}>
          <Link to={`/coupon-templates/${referralCampaign?.receiverRewardScheme.couponTemplateId}`}>
            {referralCampaign?.receiverRewardScheme.couponTemplateId}
          </Link>
        </Descriptions.Item>
      </Descriptions>

      <Typography.Title level={5} style={{ marginTop: '25px' }}>
        <FormattedMessage id="referralCampaigns.translations" />
      </Typography.Title>

      {referralCampaign.translations.map((item, i) => (
        <Descriptions
          key={i}
          style={{ width: 1200, marginTop: '20px' }}
          labelStyle={{ alignItems: 'center', fontWeight: 600 }}
          column={2}
          bordered
        >
          <Descriptions.Item label={translate('locale')} span={2}>
            {item.locale}
          </Descriptions.Item>
          {item.title && (
            <Descriptions.Item label={translate('referralCampaigns.translations.title')}>
              {item.title}
            </Descriptions.Item>
          )}
          {item.receiverPageTitle && (
            <Descriptions.Item
              label={translate('referralCampaigns.translations.receiverPageTitle')}
            >
              {item.receiverPageTitle}
            </Descriptions.Item>
          )}
          {item.rewardTitle && (
            <Descriptions.Item
              label={translate('referralCampaigns.translations.rewardTitle')}
              span={2}
            >
              {item.rewardTitle}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={translate('referralCampaigns.translations.sharingDescription')}>
            {item.sharingDescription}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('referralCampaigns.translations.referralPosterModalDescription')}
          >
            <span dangerouslySetInnerHTML={{ __html: item.referralPosterModalDescription }}></span>
          </Descriptions.Item>

          <Descriptions.Item label={translate('referralCampaigns.translations.bannerUrl')}>
            {item.bannerUrl && <Image width={200} src={item.bannerUrl} />}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('referralCampaigns.translations.receiverPageBannerUrl')}
          >
            {item.receiverPageBannerUrl && <Image width={200} src={item.receiverPageBannerUrl} />}
          </Descriptions.Item>
          {item.receiverPageIntroImageUrl && (
            <Descriptions.Item
              label={translate('referralCampaigns.translations.receiverPageIntroImageUrl')}
            >
              {item.receiverPageIntroImageUrl && (
                <Image width={200} src={item.receiverPageIntroImageUrl} />
              )}
            </Descriptions.Item>
          )}
          {item.receiverPagePriceImageUrl && (
            <Descriptions.Item
              label={translate('referralCampaigns.translations.receiverPagePriceImageUrl')}
            >
              {item.receiverPagePriceImageUrl && (
                <Image width={200} src={item.receiverPagePriceImageUrl} />
              )}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={translate('referralCampaigns.translations.shareImageUrl')}>
            {item.shareImageUrl && <Image width={200} src={item.shareImageUrl} />}
          </Descriptions.Item>
          <Descriptions.Item label={translate('referralCampaigns.translations.posterImageUrl')}>
            {item.posterImageUrl && <Image width={200} src={item.posterImageUrl} />}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={translate('referralCampaigns.translations.referralEntranceImageUrl')}
          >
            {item.referralEntranceImageUrl && (
              <Image width={200} src={item.referralEntranceImageUrl} />
            )}
          </Descriptions.Item>
        </Descriptions>
      ))}
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ReferralCampaignDescriptions)
