import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import translate from '../../i18n'
import { setAllCategoriesAction } from '../../store/category/action'
import { getTagsByContext } from '../../api/tagsApi'
import { TaggingContextEnums } from '../tags-container/constant'

const StudioLeaderboardCategoriesContainer = (props: ConnectedProps<typeof withConnect>) => {
  const location = useLocation()

  useEffect(() => {
    getTagsByContext(TaggingContextEnums.ACTIVITIES).then(res => {
      const { setAllCategoriesAction } = props
      setAllCategoriesAction(res)
    })
  }, [])

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/studio-leaderboard-categories\/[0-9]+\/edit$/)) {
      return translate('navBar.editStudioLeaderboardCategories')
    }

    if (pathName.match(/^\/studio-leaderboard-categories\/new/)) {
      return translate('navBar.newStudioLeaderboardCategories')
    }

    if (pathName.match(/^\/studio-leaderboard-categories\/[0-9]+$/)) {
      return translate('navBar.viewStudioLeaderboardCategories')
    }

    return translate('navBar.studioLeaderboardCategoriesTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

const mapDispatchToProps = {
  setAllCategoriesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(StudioLeaderboardCategoriesContainer)
