import { Button, Form, Input } from 'antd'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import translate from '../../i18n'
import { PrelaunchCompanyPaymentDetailFormValues } from '../../pages/prelaunch-organization-forms-container/share-types'

const PaymentDetailForm = ({
  onFinish,
  formData,
}: {
  onFinish: (values: PrelaunchCompanyPaymentDetailFormValues) => void
  formData?: PrelaunchCompanyPaymentDetailFormValues
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  return (
    <Form
      name="payment-detail-form"
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
    >
      <Form.Item
        name="companyName"
        label={translate('paymentDetail.companyName')}
        rules={[{ required: true, message: translate('paymentDetail.companyName.message') }]}
        help={translate('paymentDetail.companyName.tips')}
      >
        <Input />
      </Form.Item>

      <Form.Item name="companyAddress" label={translate('paymentDetail.companyAddress')}>
        <Input />
      </Form.Item>

      <Form.Item
        name="bankName"
        label={translate('paymentDetail.bankName')}
        rules={[{ required: true, message: translate('paymentDetail.bankName.message') }]}
        help={translate('paymentDetail.bankName.tips')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bankAccountNumber"
        label={translate('paymentDetail.bankAccountNumber')}
        rules={[{ required: true, message: translate('paymentDetail.bankAccountNumber.message') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="taxIdentificationNumber"
        label={translate('paymentDetail.taxIdentificationNumber')}
        rules={[
          { required: true, message: translate('paymentDetail.taxIdentificationNumber.message') },
        ]}
        help={translate('paymentDetail.taxIdentificationNumber.tips')}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
        <Button htmlType="submit" type="primary">
          <FormattedMessage id="submit"></FormattedMessage>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PaymentDetailForm
