import { useEffect, useState } from 'react'

import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'

import Table, { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { Button, Col, Row, Space, Tag, Typography } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { getStudios } from '../../../api/studiosApi'
import StudiosFilter from '../../../components/studios/studios-filter/studios-filter'
import { ConnectedProps, connect } from 'react-redux'
import { ReduxStore } from '../../../store'
import { compose } from 'redux'
import {
  IPaginatedStudio,
  IPaginatedStudioFilterParams,
  IStudio,
  IStudioFilterParams,
  IStudioTranslation,
} from '../share-type'
import { StudioStatusEnumOptions, StudioStatusEnums } from '../constants'
import LinkButton from '../../../components/link-button/link-button'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const { Text } = Typography

const Studios = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale } = props
  const navigate = useNavigate()
  const [studios, setStudioss] = useState<IStudio[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IStudioFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'name', isBoolean: false },
        { key: 'cityId', isBoolean: false },
        { key: 'organizationId', isBoolean: false },
        { key: 'status', isBoolean: false },
        { key: 'neighborhoodId', isBoolean: false },
        { key: 'isFirstClassDiscounted', isBoolean: true },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedStudiosAction(data)
  }, [])

  const getPaginatedStudiosAction = (data: IPaginatedStudioFilterParams) => {
    getStudios(data).then((res: IPaginatedStudio) => {
      if (res) {
        setStudioss(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const onSearch = (values: IStudioFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo.pageSize,
      ...values,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(data))
    getPaginatedStudiosAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedStudiosAction(data)
  }

  const columns: ColumnsType<IStudio> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('studioTranslation.name'),
      dataIndex: 'studioTranslations',
      key: 'name',
      render: (studioTranslations: IStudioTranslation[]) => {
        return <Text>{studioTranslations.length ? studioTranslations[0]?.name : ''}</Text>
      },
    },
    {
      title: translate('firstClassDiscount.discountPercentage'),
      dataIndex: 'firstClassDiscount',
      key: 'discountPercentage',
      render: (firstClassDiscount: IStudio['firstClassDiscount']) => {
        if (firstClassDiscount) {
          return <Text>{firstClassDiscount.discountPercentage}%</Text>
        }
        return ''
      },
    },
    {
      title: translate('studio.cityId'),
      dataIndex: 'cityId',
      key: 'cityId',
      width: 100,
    },
    {
      title: translate('studio.organizationId'),
      dataIndex: 'organizationId',
      key: 'organizationId',
      render: (organizationId: number) => {
        return (
          <LinkButton
            to={`/organizations/${organizationId}`}
            style={{ padding: 0, textAlign: 'left' }}
          >
            {organizationId}
          </LinkButton>
        )
      },
    },
    {
      title: translate('studio.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: StudioStatusEnums) => {
        const item = StudioStatusEnumOptions.find(option => option.value === status)
        return <Tag color={item?.color}>{item?.label}</Tag>
      },
    },
    {
      title: translate('studio.neighborhoodId'),
      dataIndex: 'neighborhoodId',
      key: 'neighborhoodId',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: IStudio) => (
        <AuthPermission permission={PermissionsEnum.STUDIOS_READ}>
          <Button type="link" onClick={() => onViewStudios(record)}>
            {translate('view')}
          </Button>
        </AuthPermission>
      ),
    },
  ]

  const onViewStudios = (record: IStudio) => {
    navigate(`/studios/${record.id}`)
  }

  return (
    <Space className="table-space" direction="vertical" size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} className="left-col">
          <div>{translate('navBar.studiosTitle')}</div>
        </Col>
      </Row>

      <div className="filter-bar">
        <StudiosFilter
          onSearch={onSearch}
          formData={searchValues}
          activeCities={activeCities}
          locale={locale}
        />
      </div>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={studios}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(Studios)
