import React, { useEffect, useState } from 'react'
import {
  Table,
  Tag,
  Space,
  Button,
  Row,
  Col,
  Rate,
  Form,
  Select,
  DatePicker,
  Typography,
  Image,
  notification,
  Carousel,
  Tabs,
  Input,
} from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import {
  AuditStatusEnum,
  BookingReviewRatingEnum,
  IBookingReview,
  IBookingReviewAuditParams,
  IBookingReviewImage,
  IBookingReviewRating,
  IBookingReviewsFilterParams,
  IPaginatedBookingReviewsParams,
  IStudioTranslateion,
} from '../share-type'
import translate from '../../../i18n'
import { getDateTime, getOptions, getSearchParams, getSearchValues } from '../../../helpers/utils'
import { auditBookingReview, getPaginatedBookingReviews } from '../../../api/bookingReviewApi'
import { SearchOutlined } from '@ant-design/icons'
import { DATE_TIME_FORMAT } from '../../../helpers/constant'
import { BookingReviewAuditStatusOptions } from '../constant'
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'
import AuthPermission from '../../../high-order-components/auth-permission'
import AuditBookingReviewModal from '../../../components/booking-reviews/audit-booking-review-modal/audit-booking-review-modal'
import { ReduxStore } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { setBookingReviewsAction } from '../../../store/booking-review/action'
import DebounceSelect from '../../../components/debounce-select/debounce-select'
import { getStudiosSuggestion, getStudiosSuggestionsByIds } from '../../../api/studiosApi'

const { RangePicker } = DatePicker
const { Option } = Select

const BookingReviewsFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IBookingReviewsFilterParams) => void
  formData: IBookingReviewsFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  const [defaultStudioSuggestionsOptions, setStudioSuggestionsOptions] = useState<
    { label: string; value: number }[]
  >([])

  useEffect(() => {
    if (formData) {
      const { startDate, endDate, ...other } = formData
      if (formData.studioId) {
        setStudioSuggestionsOptionsAction(formData.studioId)
      }

      form.setFieldsValue({
        ...other,
        rangeTime:
          startDate && endDate ? [moment(Number(startDate)), moment(Number(endDate))] : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: any) => {
    const { rangeTime, stars, accountManagerEmail, ...other } = values
    let time = {}
    if (rangeTime) {
      time = {
        startDate: moment(rangeTime[0]).valueOf(),
        endDate: moment(rangeTime[1]).valueOf(),
      }
    }

    onSearch({
      ...other,
      ...time,
      stars: stars > 0 ? stars : undefined,
      accountManagerEmail: accountManagerEmail?.trim() || undefined,
    })
  }

  async function fetchStudioList(keyword: string): Promise<{ label: string; value: number }[]> {
    return getStudiosSuggestion(keyword).then(res => {
      return getOptions(res)
    })
  }
  const setStudioSuggestionsOptionsAction = (studioId: number) => {
    getStudiosSuggestionsByIds([studioId]).then(res => {
      setStudioSuggestionsOptions(getOptions(res))
    })
  }

  return (
    <Form
      form={form}
      layout="inline"
      style={{ gap: '16px' }}
      onFinish={onFinish}
      initialValues={{ isOnlyViewTextAndPhotos: false }}
    >
      <Form.Item label="星级" name="stars">
        <Rate />
      </Form.Item>

      <Form.Item name="isOnlyViewTextAndPhotos" label="只看有图片/文字评论">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="studioId" label="场馆ID">
        <DebounceSelect
          style={{ minWidth: 130 }}
          showSearch={true}
          allowClear
          placeholder="选择场馆"
          fetchOptions={fetchStudioList}
          defaultOptions={defaultStudioSuggestionsOptions}
        />
      </Form.Item>

      <Form.Item name="isAnonymous" label="匿名评价">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="isDeleted" label="已删除">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={translate('organization.accountManager.email')}
        name="accountManagerEmail"
        rules={[{ type: 'email', message: '输入的电子邮件无效！' }]}
      >
        <Input style={{ minWidth: 250 }} />
      </Form.Item>

      <Form.Item label="评价时间" name="rangeTime">
        <RangePicker showTime format={DATE_TIME_FORMAT} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const BookingReviews = (props: ConnectedProps<typeof withConnect>) => {
  const { rejectReasons, pendingBookingReviews, approvedBookingReviews, rejectedBookingReviews } =
    props
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValues, setSearchValues] = useState<IBookingReviewsFilterParams>()
  const [searchTab, setSearchTab] = useState<string>(AuditStatusEnum.PENDING)
  const [visibleAuditModal, setVisibleAuditModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [auditStatus, setAuditStatus] = useState<
    AuditStatusEnum.APPROVED | AuditStatusEnum.REJECTED
  >()
  const [bookingReviewId, setBookingReviewId] = useState<number>()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'auditStatus', isBoolean: false },
        { key: 'isAnonymous', isBoolean: true },
        { key: 'isDeleted', isBoolean: true },
        { key: 'startDate', isBoolean: false },
        { key: 'endDate', isBoolean: false },
        { key: 'stars', isBoolean: false },
        { key: 'studioId', isBoolean: false },
        { key: 'accountManagerEmail', isBoolean: false },
        { key: 'isOnlyViewTextAndPhotos', isBoolean: true },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
      auditStatus: newSearchValues.auditStatus || searchTab,
    }

    if (newSearchValues.auditStatus && newSearchValues.auditStatus !== searchTab) {
      setSearchTab(newSearchValues.auditStatus)
    }
    setSearchValues(newSearchValues)
    getPaginatedBookingReviewsAction(data)
  }, [])

  const getPaginatedBookingReviewsAction = (data: IPaginatedBookingReviewsParams) => {
    getPaginatedBookingReviews(data).then(res => {
      props.setBookingReviewsAction(
        {
          ...res,
          pageInfo: {
            ...res.pageInfo,
            currentPage: res.pageInfo.currentPage + 1,
          },
        },
        data.auditStatus,
      )
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
      auditStatus: searchTab,
    }

    setSearchParams(getSearchParams(data))
    getPaginatedBookingReviewsAction(data)
  }

  const onSearch = (value: IBookingReviewsFilterParams) => {
    const pageInfo = getPageInfo(searchTab)
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
      auditStatus: searchTab,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams({ ...value, auditStatus: searchTab }))
    getPaginatedBookingReviewsAction(data)
  }

  const onAudit = (
    event: React.MouseEvent<HTMLElement>,
    bookingReviewId: number,
    auditStatus: AuditStatusEnum.APPROVED | AuditStatusEnum.REJECTED,
  ) => {
    event.stopPropagation()
    setBookingReviewId(bookingReviewId)
    setVisibleAuditModal(true)
    setAuditStatus(auditStatus)
  }

  const handleOk = (value: IBookingReviewAuditParams) => {
    if (!bookingReviewId) {
      return
    }
    setLoading(true)
    auditBookingReview(bookingReviewId, value)
      .then(() => {
        setLoading(false)
        setVisibleAuditModal(false)
        notification.success({
          message: '审核成功',
        })
        const pageInfo = getPageInfo(searchTab)
        const data = {
          size: pageInfo.pageSize,
          page: pageInfo.currentPage - 1,
          ...searchValues,
          auditStatus: searchTab,
        }
        getPaginatedBookingReviewsAction(data)
      })
      .catch(error => {
        setLoading(false)
        setVisibleAuditModal(false)
        notification.error({
          message: '审核失败',
          description: error.message ?? '',
        })
      })
  }

  const handleCancel = () => {
    setVisibleAuditModal(false)
  }

  const onChange = (key: string) => {
    const pageInfo = getPageInfo(key)
    const data = {
      size: pageInfo.pageSize,
      page: pageInfo.currentPage - 1,
      ...searchValues,
      auditStatus: key,
    }
    setSearchParams(getSearchParams(data))
    setSearchTab(key)
    getPaginatedBookingReviewsAction(data)
  }

  const getPageInfo = (key: string): PageInfo => {
    switch (key) {
      case AuditStatusEnum.APPROVED:
        return approvedBookingReviews.pageInfo
      case AuditStatusEnum.REJECTED:
        return rejectedBookingReviews.pageInfo
      case AuditStatusEnum.PENDING:
      default:
        return pendingBookingReviews.pageInfo
    }
  }

  const getDataSource = (key: string): IBookingReview[] => {
    switch (key) {
      case AuditStatusEnum.APPROVED:
        return approvedBookingReviews.data
      case AuditStatusEnum.REJECTED:
        return rejectedBookingReviews.data
      case AuditStatusEnum.PENDING:
      default:
        return pendingBookingReviews.data
    }
  }

  const baseColums: ColumnsType<IBookingReview> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '订单ID',
      dataIndex: 'bookingId',
      key: 'bookingId',
      sorter: (a: any, b: any) => a.bookingId - b.bookingId,
    },
    {
      title: '场馆',
      children: [
        {
          title: '场馆ID',
          dataIndex: 'studio',
          key: 'studioId',
          width: 100,
          render: (studio: { id: number }) => {
            return studio?.id
          },
        },
        {
          title: '场馆名称',
          dataIndex: 'studio',
          key: 'studioName',
          width: 200,
          render: (studio: { studioTranslations: IStudioTranslateion[] }) => {
            return studio?.studioTranslations
              .map((studioTranslation: IStudioTranslateion) => studioTranslation.name)
              .join('/')
          },
        },
      ],
    },
    {
      title: '评价时间',
      dataIndex: 'reviewedAt',
      key: 'reviewedAt',
      render: (reviewedAt: string | null) => {
        if (reviewedAt) {
          return <span>{getDateTime(reviewedAt)}</span>
        }
        return ''
      },
    },
    {
      title: '总体评价',
      dataIndex: 'bookingReviewRatings',
      key: 'bookingReviewRatings',
      render: (bookingReviewRatings: IBookingReviewRating[]) => {
        const overall = bookingReviewRatings.filter(
          item => item.label === BookingReviewRatingEnum.OVERALL_SERVICE_LEVEL,
        )
        if (overall && overall.length) {
          return <Rate disabled value={overall[0].stars}></Rate>
        }
        return ''
      },
    },
    {
      title: '文字评价',
      dataIndex: 'comment',
      key: 'comment',
      width: 200,
      render: (comment: string) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              onExpand: event => {
                event.stopPropagation()
              },
            }}
          >
            {comment}
          </Typography.Paragraph>
        )
      },
    },
    {
      title: '图片',
      dataIndex: 'bookingReviewImages',
      key: 'bookingReviewImages',
      width: 200,
      render: (bookingReviewImages: IBookingReviewImage[]) => {
        if (bookingReviewImages && bookingReviewImages.length) {
          return (
            <Image.PreviewGroup>
              <Carousel infinite={false} autoplay style={{ height: 200, width: 200 }}>
                {bookingReviewImages &&
                  bookingReviewImages.map((image, index) => (
                    <Image
                      width={200}
                      height={200}
                      src={image.originalImageUrl}
                      key={index}
                      onClick={event => event.stopPropagation()}
                    />
                  ))}
              </Carousel>
            </Image.PreviewGroup>
          )
        }
        return ''
      },
    },
    {
      title: '已删除',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      render: (isDeleted: boolean) => {
        if (isDeleted) {
          return <Tag color="red">是</Tag>
        }
        return ''
      },
    },
  ]

  const tabsItems = BookingReviewAuditStatusOptions.map(item => {
    const pageInfo = getPageInfo(item.value)
    const dataSource = getDataSource(item.value)
    let columns: ColumnsType<IBookingReview> = []
    if (item.value === AuditStatusEnum.PENDING) {
      columns = baseColums.concat([
        {
          title: translate('tableActionColumn'),
          key: 'action',
          render: (bookingReview: IBookingReview) => (
            <Space>
              {bookingReview.auditStatus === AuditStatusEnum.PENDING && (
                <AuthPermission permission={PermissionsEnum.BOOKING_REVIEWS_WRITE}>
                  <Button
                    type="link"
                    onClick={event => onAudit(event, bookingReview.id, AuditStatusEnum.APPROVED)}
                  >
                    通过
                  </Button>
                </AuthPermission>
              )}
              {bookingReview.auditStatus === AuditStatusEnum.PENDING && (
                <AuthPermission permission={PermissionsEnum.BOOKING_REVIEWS_WRITE}>
                  <Button
                    type="text"
                    danger
                    onClick={event => onAudit(event, bookingReview.id, AuditStatusEnum.REJECTED)}
                  >
                    拒绝
                  </Button>
                </AuthPermission>
              )}
            </Space>
          ),
        },
      ])
    }
    if (item.value === AuditStatusEnum.REJECTED) {
      columns = baseColums.concat([
        {
          title: '拒绝原因',
          key: 'rejectReason',
          dataIndex: 'rejectReason',
        },
      ])
    }

    if (item.value === AuditStatusEnum.APPROVED) {
      columns = baseColums
    }
    return {
      label: item.label,
      key: item.value,
      children: (
        <Table
          onRow={record => {
            return {
              onClick: () => navigate(`/booking-reviews/${record.id}`),
            }
          }}
          rowKey={record => record.id}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
        />
      ),
    }
  })

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} className="left-col">
          <div>用户评价列表</div>
        </Col>
      </Row>
      <Row>
        <BookingReviewsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Tabs activeKey={searchTab} onChange={onChange} items={tabsItems} />

      <AuditBookingReviewModal
        auditStatus={auditStatus}
        visible={visibleAuditModal}
        loading={loading}
        rejectReasons={rejectReasons}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  const { pendingBookingReviews, approvedBookingReviews, rejectedBookingReviews } =
    state.bookingReviews
  return {
    rejectReasons: state.global.rejectReasons,
    pendingBookingReviews,
    approvedBookingReviews,
    rejectedBookingReviews,
  }
}

const mapDispatchToProps = {
  setBookingReviewsAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(BookingReviews)
