import { useEffect, useState } from 'react'

import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getDateTime, getSearchParams, getSearchValues } from '../../../helpers/utils'

import Table, { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { Button, Col, Row, Space, Typography } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import {
  IOrganization,
  IOrganizationFilterParams,
  IOrganizationTranslation,
  IPaginatedOrganization,
  IPaginatedOrganizationFilterParams,
} from '../share-type'
import { getOrganizations } from '../../../api/organizationsApi'
import OrganizationsFilter from '../../../components/organizations/organizations-filter/organizations-filter'
import { ConnectedProps, connect } from 'react-redux'
import { ReduxStore } from '../../../store'
import { compose } from 'redux'
import { IPrelaunchOrganizationForm } from '../../prelaunch-organization-forms-container/share-types'
import PrelaunchOrganizationFormsFormModal from '../../../components/prelaunch-organization-forms/prelaunch-organization-forms-form-modal/prelaunch-organization-forms-form-modal'
import { RetweetOutlined } from '@ant-design/icons'
import CalculateBillingSummariesModal from '../../../components/organizations/calculate-billing-summaries-modal/calculate-billing-summaries-modal'

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const { Text } = Typography

const Organizations = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale } = props
  const navigate = useNavigate()
  const [organizations, setOrganizationss] = useState<IOrganization[]>()
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IOrganizationFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [organizationId, setOrganizationId] = useState<number>()
  const [open, setOpen] = useState<boolean>(false)
  const [openCalculateBillingSummariesModal, setOpenCalculateBillingSummariesModal] =
    useState<boolean>(false)

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'name', isBoolean: false },
        {
          key: 'email',
          isBoolean: false,
        },
        { key: 'cityId', isBoolean: false },
        { key: 'tier', isBoolean: false },

        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedOrganizationsAction(data)
  }, [])

  const getPaginatedOrganizationsAction = (data: IPaginatedOrganizationFilterParams) => {
    getOrganizations(data).then((res: IPaginatedOrganization) => {
      if (res) {
        setOrganizationss(res.data)
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
      }
    })
  }

  const onSearch = (values: IOrganizationFilterParams) => {
    const data = {
      page: 0,
      size: pageInfo.pageSize,
      ...values,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(values))
    getPaginatedOrganizationsAction(data)
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedOrganizationsAction(data)
  }

  const onCreateOrganizationsForm = (record: IOrganization) => {
    setOrganizationId(record.id)
    setOpen(true)
  }

  const columns: ColumnsType<IOrganization> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 50,
    },
    {
      title: translate('organizationTranslation.name'),
      dataIndex: 'organizationTranslations',
      key: 'name',
      render: (organizationTranslations: IOrganizationTranslation[]) => {
        return (
          <Text>{organizationTranslations.length ? organizationTranslations[0]?.name : ''}</Text>
        )
      },
    },
    {
      title: translate('organization.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: translate('organization.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: translate('organization.cityId'),
      dataIndex: 'cityId',
      key: 'cityId',
      width: 100,
    },
    {
      title: translate('organization.tier'),
      dataIndex: 'tier',
      key: 'tier',
      width: 100,
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: IOrganization) => (
        <>
          <Space>
            <AuthPermission permission={PermissionsEnum.STUDIOS_READ}>
              <Button type="link" onClick={() => onViewOrganizations(record)}>
                {translate('view')}
              </Button>
            </AuthPermission>
            <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
              <Button type="link" onClick={() => onCreateOrganizationsForm(record)}>
                {translate('prelaunchOrganizationsForm.create')}
              </Button>
            </AuthPermission>
          </Space>
        </>
      ),
    },
  ]

  const onViewOrganizations = (record: IOrganization) => {
    navigate(`/organizations/${record.id}`)
  }

  const handlePrelaunchOrganizationFormSubmit = (res: IPrelaunchOrganizationForm) => {
    setOpen(false)
    navigate(`/prelaunch-organization-forms/${res.id}`)
  }

  return (
    <Space className="table-space" direction="vertical" size={16}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.organizationsTitle')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Button
            type="primary"
            icon={<RetweetOutlined />}
            onClick={() => setOpenCalculateBillingSummariesModal(true)}
          >
            刷新账单统计
          </Button>
        </Col>
      </Row>

      <div className="filter-bar">
        <OrganizationsFilter
          onSearch={onSearch}
          formData={searchValues}
          activeCities={activeCities}
          locale={locale}
        />
      </div>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={organizations}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />

      {organizationId && (
        <PrelaunchOrganizationFormsFormModal
          open={open}
          handleCancel={() => setOpen(false)}
          organizationId={organizationId}
          handleSubmit={handlePrelaunchOrganizationFormSubmit}
        />
      )}

      <CalculateBillingSummariesModal
        open={openCalculateBillingSummariesModal}
        handleCancel={() => setOpenCalculateBillingSummariesModal(false)}
        handleOk={() => setOpenCalculateBillingSummariesModal(false)}
      />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(Organizations)
