import { Button, Form, Select, Input, InputNumber } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { ICardStacksFilterParams } from '../../../pages/card-stacks-container/share-type'
import { LocaleType } from '../../../share-types'
import { City, CityTranslation } from '../../../store/city/reducer'
import { SearchOutlined } from '@ant-design/icons'
import {
  targetUserGroupsOptions,
  pageTypeOptions,
} from '../../../pages/card-stacks-container/constant'

const { Option } = Select
const CardStacksFilter = ({
  onSearch,
  formData,
  activeCities,
  locale,
}: {
  onSearch: (value: ICardStacksFilterParams) => void
  formData: ICardStacksFilterParams | undefined
  activeCities: City[]
  locale: LocaleType
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])
  const onFinish = (values: any) => {
    const { rangeTime, stars, ...other } = values
    let time = {}
    if (rangeTime) {
      time = {
        startDate: moment(rangeTime[0]).valueOf(),
        endDate: moment(rangeTime[1]).valueOf(),
      }
    }

    onSearch({
      ...other,
      ...time,
      stars: stars > 0 ? stars : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" className="filter-inline-form" onFinish={onFinish}>
      <Form.Item name="name" label="名字">
        <Input />
      </Form.Item>

      <Form.Item name="rank" label="排名">
        <InputNumber min={1} style={{ minWidth: 130 }} />
      </Form.Item>

      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item label="城市" name="cityId">
        <Select style={{ minWidth: 130 }} allowClear>
          {activeCities.map(city => {
            const translation = city?.translations.find(
              (translation: CityTranslation) => translation.locale === locale,
            )
            return (
              <Option key={city.id} value={city.id}>
                {translation?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item label="目标用户" name="targetGroup">
        <Select style={{ minWidth: 130 }} allowClear>
          {targetUserGroupsOptions.map(targetGroup => (
            <Option key={targetGroup.value} value={targetGroup.value}>
              {targetGroup.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="页面种类" name="pageType">
        <Select style={{ minWidth: 130 }} allowClear>
          {pageTypeOptions.map(pageType => (
            <Option key={pageType.value} value={pageType.value}>
              {pageType.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CardStacksFilter
