import { Button, Modal, notification, Popover, Row, Space, Table, Typography } from 'antd'
import {
  getDateTime,
  getSearchParams,
  getSearchValues,
  renderNullableValue,
} from '../../../helpers/utils'
import { ColumnsType } from 'antd/lib/table'
import {
  CreditsAdjustmentRequest,
  ExchangeRateAdjustmentRequestParams,
  ExchangeRateMetricFilter,
  IPaginatedLessonDescriptionsCreditsExchangeRatesListParams,
  LessonDescriptionsCreditsExchangeRatesDto,
} from '../share-type'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useParams, useSearchParams } from 'react-router-dom'
import ExchangeRateAdjustmentFormModal from '../../../components/exchange-rates/exchange-rate-adjustment-form-modal/exchange-rate-adjustment-form-modal'
import {
  exchangeRateAdjustment,
  getLessonDescriptionsCreditsExchangeRates,
} from '../../../api/exchangeRatesApi'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import AuthPermission from '../../../high-order-components/auth-permission'
import { InfoCircleOutlined } from '@ant-design/icons'
import { CreditsAdjustmentType } from '../constant'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import ExchangeRatesFilter from '../../../components/exchange-rates/exchange-rates-filter/exchange-rates-filter'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ReduxStore } from '../../../store'
import { handleApiError } from '../../../helpers/api-helper'
import { PAGINATION_CONFIG, paginationUtils } from '../../../helpers/pagination-helper'

const { DEFAULT } = PAGINATION_CONFIG

const defaultPage = {
  pageSize: DEFAULT.PAGE_SIZE,
  currentPage: DEFAULT.CURRENT_PAGE,
  count: DEFAULT.TOTAL,
}

const { Text } = Typography

interface ModalState {
  open: boolean
  loading: boolean
  lessonDescription?: LessonDescriptionsCreditsExchangeRatesDto
}

const ExchangeRatesLessonDescriptions = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities, locale } = props
  const [lessonDescriptions, setLessonDescriptions] = useState<
    LessonDescriptionsCreditsExchangeRatesDto[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const params = useParams()
  const [organizationId] = useState<string | undefined>(params?.organizationId)
  const [studioId] = useState<string | undefined>(params?.studioId)

  const [searchValues, setSearchValues] = useState<ExchangeRateMetricFilter>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)

  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    loading: false,
    lessonDescription: undefined,
  })

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'orderBy', isBoolean: false },
        { key: 'orderDirection', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }

    getLessonDescriptionsCreditsExchangeRatesAction(data)
  }, [])

  const getLessonDescriptionsCreditsExchangeRatesAction = (
    data: IPaginatedLessonDescriptionsCreditsExchangeRatesListParams,
  ) => {
    if (!studioId || !organizationId) {
      return
    }

    setLoading(true)
    getLessonDescriptionsCreditsExchangeRates(Number(organizationId), Number(studioId), data)
      .then(res => {
        if (res) {
          setLessonDescriptions(res.data)
          setPageInfo({
            ...res.pageInfo,
            currentPage: paginationUtils.toFrontendPage(res.pageInfo.currentPage),
          })
        }
      })
      .catch(error => {
        handleApiError(error, translate('exchangeRates.fetchFailed'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getConfirmModalTitle = (lessonDescription: LessonDescriptionsCreditsExchangeRatesDto) => {
    if (
      lessonDescription.isIntegrator &&
      lessonDescription.hasLessonDescriptionUsedForMultipleStudios
    ) {
      return translate('exchangeRates.adjuestment.lessonTips1')
    } else if (lessonDescription.isIntegrator) {
      return translate('exchangeRates.integratorUpdateTitle')
    } else if (lessonDescription.hasLessonDescriptionUsedForMultipleStudios) {
      return translate('exchangeRates.adjuestment.lessonTips2')
    } else {
      return ''
    }
  }

  const onChangeRate = (lessonDescription: LessonDescriptionsCreditsExchangeRatesDto) => {
    const title = getConfirmModalTitle(lessonDescription)
    if (title) {
      Modal.confirm({
        title,
        content: translate('exchangeRates.updateContent'),
        cancelText: translate('cancel'),
        okText: translate('continue'),
        onOk() {
          setModalState(prev => ({
            ...prev,
            open: true,
            lessonDescription,
          }))
        },
      })
    } else {
      setModalState(prev => ({
        ...prev,
        open: true,
        lessonDescription,
      }))
    }
  }

  const buildAdjustmentRequest = (
    lessonDescription: LessonDescriptionsCreditsExchangeRatesDto,
    values: ExchangeRateAdjustmentRequestParams,
  ) => {
    const baseRequest: CreditsAdjustmentRequest = {
      adjustableType: CreditsAdjustmentType.LESSON_DESCRIPTION,
      adjustableId: lessonDescription.lessonDescriptionId,
    }

    return values.rate
      ? {
          ...baseRequest,
          exchangeRateChangeFrom: lessonDescription.exchangeRate || 0,
          exchangeRateChangeTo: values.rate,
        }
      : {
          ...baseRequest,
          adjustCredits: values.adjustCredits,
        }
  }

  const onSubmit = useCallback(
    (values: ExchangeRateAdjustmentRequestParams) => {
      if (!modalState.lessonDescription) {
        return
      }

      const data: CreditsAdjustmentRequest = buildAdjustmentRequest(
        modalState.lessonDescription,
        values,
      )

      setModalState(prev => ({ ...prev, loading: true }))

      exchangeRateAdjustment(data)
        .then(res => {
          if (res) {
            setModalState(prev => ({ ...prev, open: false, lessonDescription: undefined }))

            getLessonDescriptionsCreditsExchangeRatesAction({
              size: defaultPage.pageSize,
              page: defaultPage.currentPage - 1,
            })
            notification.success({
              message: translate('exchangeRates.updateSuccess'),
            })
          }
        })
        .catch(err => {
          handleApiError(err, translate('exchangeRates.updateFailed'), { showNotification: true })
        })
        .finally(() => {
          setModalState(prev => ({ ...prev, loading: false }))
        })
    },
    [modalState.lessonDescription],
  )

  const columns: ColumnsType<LessonDescriptionsCreditsExchangeRatesDto> = useMemo(
    () => [
      {
        title: '课程 ID',
        dataIndex: 'lessonDescriptionId',
        key: 'lessonDescriptionId',
        sorter: (
          a: LessonDescriptionsCreditsExchangeRatesDto,
          b: LessonDescriptionsCreditsExchangeRatesDto,
        ) => a.lessonDescriptionId - b.lessonDescriptionId,
        render: (lessonDescriptionId: number) => {
          return (
            <LinkButton
              to={`/lesson-descriptions/${lessonDescriptionId}`}
              style={{ padding: 0, textAlign: 'left' }}
            >
              {lessonDescriptionId}
            </LinkButton>
          )
        },
      },
      {
        title: translate('lessonDescriptionName'),
        dataIndex: 'lessonDescriptionName',
        key: 'lessonDescriptionName',
      },
      {
        title: translate('totalPayout'),
        dataIndex: 'totalPayoutInCents',
        key: 'totalPayoutInCents',
        render: (totalPayoutInCents: number | null) =>
          renderNullableValue(totalPayoutInCents, value => (value / 100).toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('exchangeRateTips')}>
            {translate('exchangeRate')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'exchangeRate',
        key: 'exchangeRate',
        render: (exchangeRate: number | null) =>
          renderNullableValue(exchangeRate, value => value.toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('costPerCreditTips')}>
            {translate('costPerCredit')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'costPerCredit',
        key: 'costPerCredit',
        render: (costPerCredit: number | null) =>
          renderNullableValue(costPerCredit, value => value.toFixed(2)),
      },
      {
        title: (
          <Popover content={translate('fcfPercentageTips')}>
            {translate('fcfPercentage')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'blendedFcfPercentage',
        key: 'blendedFcfPercentage',
        render: (blendedFcfPercentage: number | null) =>
          renderNullableValue(blendedFcfPercentage, value => `${(value * 100).toFixed(2)}%`),
      },
      {
        title: (
          <Popover content={translate('earlyVisitRateTips')}>
            {translate('earlyVisitRate')} <InfoCircleOutlined style={{ color: '#faad14' }} />
          </Popover>
        ),
        dataIndex: 'earlyVisitRate',
        key: 'earlyVisitRate',
        render: (earlyVisitRate: number | null) =>
          renderNullableValue(earlyVisitRate, value => `${(value * 100).toFixed(2)}%`),
      },
      {
        title: translate('exchangeRates.isIntegrator'),
        dataIndex: 'isIntegrator',
        key: 'isIntegrator',
        render: (isIntegrator: boolean) => {
          return <YesOrNoTag value={isIntegrator} />
        },
      },
      {
        title: translate('activities'),
        dataIndex: 'activities',
        key: 'activities',
        render: (activities: string[]) => {
          return activities.join(',')
        },
      },
      {
        title: translate('updatedAt'),
        dataIndex: 'updatedTime',
        key: 'updatedTime',
        render: (updatedTime: string) => {
          return getDateTime(updatedTime)
        },
      },
      {
        title: translate('tableActionColumn'),
        key: 'action',
        render: (record: LessonDescriptionsCreditsExchangeRatesDto) => (
          <Space size="middle">
            <AuthPermission permission={PermissionsEnum.EXCHANGE_RATE_WRITE}>
              <Button type="link" onClick={() => onChangeRate(record)}>
                {translate('exchangeRates.update')}
              </Button>
            </AuthPermission>
          </Space>
        ),
      },
    ],
    [translate, onChangeRate],
  )

  const paginationOnChange = useCallback(
    (page: number, pageSize: number) => {
      const data = {
        ...searchValues,
        size: paginationUtils.validatePageSize(pageSize),
        page: paginationUtils.toBackendPage(page),
      }
      setSearchParams(getSearchParams(data))
      getLessonDescriptionsCreditsExchangeRatesAction(data)
    },
    [searchValues],
  )

  const onSearch = (values: ExchangeRateMetricFilter) => {
    const data = {
      ...values,
      size: defaultPage.pageSize,
      page: defaultPage.currentPage - 1,
    }

    setSearchValues(values)
    setSearchParams(getSearchParams(data))
    getLessonDescriptionsCreditsExchangeRatesAction(data)
  }

  return (
    <>
      <Space className="table-space" direction="vertical" wrap size={16}>
        <Row align="middle">
          <div>课程列表</div>
        </Row>
        <Row className="filter-bar">
          <ExchangeRatesFilter
            onSearch={onSearch}
            formData={searchValues}
            activeCities={activeCities}
            locale={locale}
            isOnlyShowSortableFields={true}
          />
        </Row>

        <Table
          rowKey={record => record.lessonDescriptionId.toString()}
          columns={columns}
          dataSource={lessonDescriptions}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
          loading={loading}
        />
      </Space>
      <ExchangeRateAdjustmentFormModal
        open={modalState.open}
        loading={modalState.loading}
        title={translate('exchangeRates.update')}
        subTitle="对所有被选择的场馆进行批量修改，修后会根据现有支付价除以新汇率以推算出新的点数"
        description={
          <Space direction="vertical">
            <div>
              已选课程:{' '}
              <Text type="danger">{modalState.lessonDescription?.lessonDescriptionName}</Text>
            </div>
            <div>
              当前汇率：
              <Text type="danger">{modalState.lessonDescription?.exchangeRate}</Text>
            </div>
          </Space>
        }
        onCancel={() =>
          setModalState(prev => ({ ...prev, open: false, lessonDescription: undefined }))
        }
        onSubmit={onSubmit}
      />
    </>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ExchangeRatesLessonDescriptions)
