import config from '../config/index'
import {
  FirstClassDiscountDisabledLessonRequest,
  IFirstClassDiscountDisabledLesson,
  IPaginatedFirstClassDiscountDisabledLessonsFilterParams,
  PaginatedFirstClassDiscountDisabledLessonsResponse,
  StudioFirstClassDiscountRequest,
} from '../pages/first-class-discount-disabled-lessons-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getFirstClassDiscountDisabledLessons = (
  data: IPaginatedFirstClassDiscountDisabledLessonsFilterParams,
) =>
  authRequest.get<MISSING_TYPE, PaginatedFirstClassDiscountDisabledLessonsResponse>(
    `${mainApi}/first-class-discounts/disabled-lessons`,
    {
      params: data,
    },
  )

export const getFirstClassDiscountDisabledLesson = (id: number) =>
  authRequest.get<MISSING_TYPE, IFirstClassDiscountDisabledLesson>(
    `${mainApi}/first-class-discounts/disabled-lessons/${id}`,
  )

export const createFirstClassDiscountDisabledLesson = (
  data: FirstClassDiscountDisabledLessonRequest,
) => {
  return authRequest.post<MISSING_TYPE, IFirstClassDiscountDisabledLesson>(
    `${mainApi}/first-class-discounts/disabled-lessons`,
    data,
  )
}

export const updateFirstClassDiscountDisabledLesson = (
  id: number,
  data: FirstClassDiscountDisabledLessonRequest,
) => {
  return authRequest.put<MISSING_TYPE, IFirstClassDiscountDisabledLesson>(
    `${mainApi}/first-class-discounts/disabled-lessons/${id}`,
    data,
  )
}

export const deleteFirstClassDiscountDisabledLesson = (id: number) => {
  return authRequest.delete<MISSING_TYPE, void>(
    `${mainApi}/first-class-discounts/disabled-lessons/${id}`,
  )
}
export const createFirstClassDiscountDisabledLessonByStudio = (
  studioId: number,
  data: StudioFirstClassDiscountRequest,
) => {
  return authRequest.put<MISSING_TYPE, IFirstClassDiscountDisabledLesson>(
    `${mainApi}/first-class-discounts/studios/${studioId}`,
    data,
  )
}
