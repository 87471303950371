import config from '../config/index'
import {
  Action,
  CardActionType,
  FirstClassDiscountedStudiosContentStrategyConfigEnum,
  FrequentlyBookedLessonsContentStrategyEnum,
  LessonDescriptionContentStrategyEnum,
  LocaleString,
  StudioContentStrategyEnum,
  StudioListContentStrategyEnum,
  UpcomingLessonContentStrategyEnum,
} from '../pages/card-stacks-container/share-type'
import { UploadFileDirectoryEnum } from '../share-types'
import {
  CouponCardContentStrategyConfig,
  FilterableLessonDescriptionsContentStrategyConfig,
  FirstClassDiscountedStudiosContentStrategyConfig,
  FrequentlyBookedLessonCardContentStrategyConfig,
  NearbyStudiosContentStrategyConfig,
  NewStudioListContentStrategyConfig,
  PersonalizedLessonDescriptionsContentStrategyConfig,
  RecommendedLessonDescriptionsContentStrategyConfig,
  RecommendedStudioListContentStrategyConfig,
  RecommendedStudiosContentStrategyConfig,
  UpcomingLessonCardContentStrategyConfig,
} from './content-strategy'
import { getImageUrl } from './utils'

const { assetUrl } = config

class CardConfig {
  name: string
  className: string
  _action: Action | null
  constructor(name: string) {
    this.name = name
    this.className = `com.classpass.api.entities.${this.name}`
    this._action = null
  }

  public set action({
    actionUrl,
    actionType,
    actionText,
    actionTextTranslation,
  }: {
    actionUrl: string
    actionType: CardActionType
    actionText: string
    actionTextTranslation: string
  }) {
    let obj = {}
    if (actionText) {
      obj = {
        text: {
          locale: 'zh-CN',
          value: actionText,
        },
      }
    }
    if (actionTextTranslation) {
      obj = {
        ...obj,
        textTranslations: [
          {
            locale: 'en',
            value: actionTextTranslation,
          },
        ],
      }
    }

    if (actionUrl) {
      obj = {
        ...obj,
        url: actionUrl,
      }
    }

    if (actionType) {
      obj = {
        ...obj,
        type: actionType,
      }
    }
    if (Object.keys(obj).length) {
      this._action = obj
    } else {
      this._action = null
    }
  }
}

type AdvertisementCardConfigContent = {
  locale: string
  imageUrl: string
  largeImageUrl: string
  title: string
  description: string
  backgroundColor?: string
}

export class AdvertisementCardConfig extends CardConfig {
  _rank = 0
  _content?: AdvertisementCardConfigContent
  _contentTranslations?: AdvertisementCardConfigContent[]
  private imgAssetUrl = `${assetUrl}/${UploadFileDirectoryEnum.HOMEPAGE_ADS}`

  constructor() {
    super('AdvertisementCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    return {
      rank: config.rank,
      bannerUrl: config?.content?.imageUrl
        ? [
            {
              status: 'done',
              url: config?.content?.imageUrl,
              thumbUrl: config?.content?.imageUrl,
            },
          ]
        : [],
      bannerUrlTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].imageUrl
          ? [
              {
                status: 'done',
                url: config?.contentTranslations[0].imageUrl,
                thumbUrl: config?.contentTranslations[0].imageUrl,
              },
            ]
          : [],
      bannerBiggerUrl: config?.content?.largeImageUrl
        ? [
            {
              status: 'done',
              url: config?.content?.largeImageUrl,
              thumbUrl: config?.content?.largeImageUrl,
            },
          ]
        : [],
      bannerBiggerUrlTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].largeImageUrl
          ? [
              {
                status: 'done',
                url: config?.contentTranslations[0].largeImageUrl,
                thumbUrl: config?.contentTranslations[0].largeImageUrl,
              },
            ]
          : [],
      actionUrl: config?.action?.url,
      actionType: config?.action?.type,
      actionText: config?.action?.text?.value,
      actionTextTranslation:
        config?.action?.textTranslations && config?.action?.textTranslations.length
          ? config?.action?.textTranslations[0].value
          : '',
      title: config?.content?.title,
      titleTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].title
          ? config?.contentTranslations[0].title
          : '',
      description: config?.content?.description,
      descriptionTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].description
          ? config?.contentTranslations[0].description
          : '',
      backgroundColor: config?.content?.backgroundColor ?? '',
    }
  }

  public set rank(v: number) {
    this._rank = v
  }

  public set content({
    title,
    description,
    titleTranslation,
    descriptionTranslation,
    bannerUrl,
    bannerUrlTranslation,
    bannerBiggerUrl,
    bannerBiggerUrlTranslation,
    backgroundColor,
  }: {
    title: string
    description: string
    titleTranslation: string
    descriptionTranslation: string
    bannerUrl: MISSING_TYPE
    bannerUrlTranslation: MISSING_TYPE
    bannerBiggerUrl: MISSING_TYPE
    bannerBiggerUrlTranslation: MISSING_TYPE
    backgroundColor?: MISSING_TYPE
  }) {
    this._content = {
      locale: 'zh-CN',
      title: title,
      description: description,
      imageUrl: getImageUrl('banner_url', this.imgAssetUrl, bannerUrl),
      largeImageUrl: getImageUrl('banner_bigger_url', this.imgAssetUrl, bannerBiggerUrl),
      backgroundColor,
    }

    this._contentTranslations = [
      {
        locale: 'en',
        title: titleTranslation,
        description: descriptionTranslation,
        imageUrl: getImageUrl('banner_url_translation', this.imgAssetUrl, bannerUrlTranslation),
        largeImageUrl: getImageUrl(
          'banner_bigger_url_translation',
          this.imgAssetUrl,
          bannerBiggerUrlTranslation,
        ),
        backgroundColor,
      },
    ]
  }

  getParams() {
    return {
      '@class': this.className,
      rank: this._rank,
      content: this._content,
      contentTranslations: this._contentTranslations,
      action: this._action,
    }
  }
}

export type TitleSubtitleIconCardContent = {
  locale: string
  title: string
  description: string
  imageUrl: string
}

export class TitleSubtitleIconCardConfig extends CardConfig {
  _rank?: number
  _content?: TitleSubtitleIconCardContent
  _contentTranslations?: TitleSubtitleIconCardContent[]
  private imgAssetUrl = `${assetUrl}/${UploadFileDirectoryEnum.HOMEPAGE_TITLE_SUBTILE_ICONS}`

  constructor() {
    super('TitleSubtitleIconCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    return {
      rank: config?.rank,
      imageUrl: config?.content?.imageUrl
        ? [
            {
              status: 'done',
              url: config?.content?.imageUrl,
              thumbUrl: config?.content?.imageUrl,
            },
          ]
        : [],
      title: config?.content?.title,
      titleTranslation:
        config?.contentTranslations && config?.contentTranslations.length
          ? config?.contentTranslations[0].title
          : '',
      description: config?.content?.description,
      descriptionTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].description
          ? config?.contentTranslations[0].description
          : '',
      actionUrl: config?.action?.url,
      actionType: config?.action?.type,
      actionText: config?.action?.text?.value,
      actionTextTranslation:
        config?.action?.textTranslations && config?.action?.textTranslations.length
          ? config?.action?.textTranslations[0].value
          : '',
    }
  }

  public set rank(v: number) {
    this._rank = v
  }

  public set content({
    imageUrl,
    title,
    titleTranslation,
    description,
    descriptionTranslation,
  }: {
    imageUrl: MISSING_TYPE
    title: string
    titleTranslation: string
    description: string
    descriptionTranslation: string
  }) {
    const fullImageUrl = getImageUrl('image_url', this.imgAssetUrl, imageUrl)
    this._content = {
      locale: 'zh-CN',
      imageUrl: fullImageUrl,
      title,
      description,
    }

    this._contentTranslations = [
      {
        locale: 'en',
        imageUrl: fullImageUrl,
        title: titleTranslation,
        description: descriptionTranslation,
      },
    ]
  }

  getParams() {
    return {
      '@class': this.className,
      rank: this._rank,
      content: this._content,
      contentTranslations: this._contentTranslations,
      action: this._action,
    }
  }
}

export type IconCardConfigContent = {
  locale: string
  title: string
  imageUrl: string
}

export class IconCardConfig extends CardConfig {
  _rank = 0
  _content?: IconCardConfigContent
  _iconFont?: string
  _contentTranslations?: IconCardConfigContent[]
  private imgAssetUrl = `${assetUrl}/${UploadFileDirectoryEnum.HOMEPAGE_ACTIVITY_ICONS}`

  constructor() {
    super('IconCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    return {
      rank: config?.rank,
      iconFont: config?.iconFont,
      imageUrl: config?.content?.imageUrl
        ? [
            {
              status: 'done',
              url: config?.content?.imageUrl,
              thumbUrl: config?.content?.imageUrl,
            },
          ]
        : [],
      imageUrlTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].imageUrl
          ? [
              {
                status: 'done',
                url: config?.contentTranslations[0].imageUrl,
                thumbUrl: config?.contentTranslations[0].imageUrl,
              },
            ]
          : [],
      title: config?.content?.title,
      titleTranslation:
        config?.contentTranslations && config?.contentTranslations.length
          ? config?.contentTranslations[0].title
          : '',
      actionUrl: config?.action?.url,
      actionType: config?.action?.type,
      actionText: config?.action?.text?.value,
      actionTextTranslation:
        config?.action?.textTranslations && config?.action?.textTranslations.length
          ? config?.action?.textTranslations[0].value
          : '',
    }
  }
  public set rank(v: number) {
    this._rank = v
  }

  public set iconFont(v: string) {
    this._iconFont = v
  }

  public set content({
    imageUrl,
    imageUrlTranslation,
    title,
    titleTranslation,
  }: {
    imageUrl: MISSING_TYPE
    imageUrlTranslation: MISSING_TYPE
    title: string
    titleTranslation: string
  }) {
    this._content = {
      locale: 'zh-CN',
      imageUrl: getImageUrl('image_url', this.imgAssetUrl, imageUrl),
      title,
    }

    this._contentTranslations = [
      {
        locale: 'en',
        imageUrl: getImageUrl('image_url_translation', this.imgAssetUrl, imageUrlTranslation),
        title: titleTranslation,
      },
    ]
  }

  getParams() {
    return {
      '@class': this.className,
      rank: this._rank,
      iconFont: this._iconFont,
      content: this._content,
      contentTranslations: this._contentTranslations,
      action: this._action,
    }
  }
}

type contentStrategy = {
  '@class': string
  lessonDescriptionIds?: number[]
  studioIds?: number[]
  max?: number
  couponTemplateId?: number
}

export class LessonDescriptionCardConfig extends CardConfig {
  _contentStrategy?: contentStrategy
  constructor() {
    super('LessonDescriptionCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    let contentStrategy = ''
    if (
      config.contentStrategy['@class'] ===
      new PersonalizedLessonDescriptionsContentStrategyConfig().className
    ) {
      contentStrategy = LessonDescriptionContentStrategyEnum.PERSONALIZED_LESSON_DESCRIPTIONS
    }
    if (
      config.contentStrategy['@class'] ===
      new RecommendedLessonDescriptionsContentStrategyConfig().className
    ) {
      contentStrategy = LessonDescriptionContentStrategyEnum.RECOMMENDED_LESSON_DESCRIPTIONS
    }
    if (
      config.contentStrategy['@class'] ===
      new FilterableLessonDescriptionsContentStrategyConfig().className
    ) {
      contentStrategy = LessonDescriptionContentStrategyEnum.FILTER
    }
    return {
      rank: config?.rank,
      contentStrategy,
      lessonDescriptionIds: config?.contentStrategy?.lessonDescriptionIds,
    }
  }

  public set contentStrategy({
    contentStrategy,
    lessonDescriptionIds,
  }: {
    contentStrategy: string
    lessonDescriptionIds: number[]
  }) {
    if (contentStrategy === LessonDescriptionContentStrategyEnum.PERSONALIZED_LESSON_DESCRIPTIONS) {
      this._contentStrategy =
        new PersonalizedLessonDescriptionsContentStrategyConfig().getContentStrategy()
    }
    if (contentStrategy === LessonDescriptionContentStrategyEnum.RECOMMENDED_LESSON_DESCRIPTIONS) {
      this._contentStrategy = new RecommendedLessonDescriptionsContentStrategyConfig(
        lessonDescriptionIds,
      ).getContentStrategy()
    }

    if (contentStrategy === LessonDescriptionContentStrategyEnum.FILTER) {
      this._contentStrategy =
        new FilterableLessonDescriptionsContentStrategyConfig().getContentStrategy()
    }
  }

  getParams() {
    return {
      '@class': this.className,
      contentStrategy: this._contentStrategy,
    }
  }
}

export class StudioCardConfig extends CardConfig {
  _contentStrategy?: contentStrategy
  constructor() {
    super('StudioCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    let contentStrategy = ''
    if (config.contentStrategy['@class'] === new NearbyStudiosContentStrategyConfig().className) {
      contentStrategy = StudioContentStrategyEnum.NEARBY_STUDIOS
    }
    if (
      config.contentStrategy['@class'] === new RecommendedStudiosContentStrategyConfig().className
    ) {
      contentStrategy = StudioContentStrategyEnum.RECOMMENDED_STUDIOS
    }
    return {
      rank: config?.rank,
      contentStrategy,
      studioIds: config?.contentStrategy?.studioIds,
      max: config?.contentStrategy?.max,
    }
  }

  public set contentStrategy({
    contentStrategy,
    studioIds,
    max,
  }: {
    contentStrategy: string
    studioIds: number[]
    max: number
  }) {
    if (contentStrategy === StudioContentStrategyEnum.NEARBY_STUDIOS) {
      this._contentStrategy = new NearbyStudiosContentStrategyConfig(max).getContentStrategy()
    }
    if (contentStrategy === StudioContentStrategyEnum.RECOMMENDED_STUDIOS) {
      this._contentStrategy = new RecommendedStudiosContentStrategyConfig(
        studioIds,
      ).getContentStrategy()
    }
  }

  getParams() {
    return {
      '@class': this.className,
      contentStrategy: this._contentStrategy,
    }
  }
}

export class StudioListCardConfig extends CardConfig {
  _rank = 0
  _cityIds?: number[]
  _title?: LocaleString
  _titleTranslations?: LocaleString[]
  _tagBgColor?: string
  _tagText?: LocaleString
  _tagTextTranslations?: LocaleString[]
  _contentStrategy?: contentStrategy
  constructor() {
    super('StudioListCardConfig')
  }

  public set rank(v: number) {
    this._rank = v
  }

  public set cityIds(v: number[]) {
    this._cityIds = v
  }

  public set title(v: string) {
    this._title = {
      locale: 'zh-CN',
      value: v,
    }
  }

  public set titleTranslation(v: string) {
    this._titleTranslations = [
      {
        locale: 'en',
        value: v,
      },
    ]
  }

  public set tagBgColor(v: string) {
    this._tagBgColor = v
  }

  public set tagText(v: string) {
    this._tagText = {
      locale: 'zh-CN',
      value: v,
    }
  }

  public set tagTextTranslation(v: string) {
    this._tagTextTranslations = [
      {
        locale: 'en',
        value: v,
      },
    ]
  }

  public set contentStrategy({
    contentStrategy,
    studioIds,
    max,
  }: {
    contentStrategy: string
    studioIds: number[]
    max: number
  }) {
    if (contentStrategy === StudioListContentStrategyEnum.NEW_STUDIOS) {
      this._contentStrategy = new NewStudioListContentStrategyConfig(max).getContentStrategy()
    }
    if (contentStrategy === StudioListContentStrategyEnum.RECOMMENDED_STUDIOS) {
      this._contentStrategy = new RecommendedStudioListContentStrategyConfig(
        studioIds,
      ).getContentStrategy()
    }
  }

  setFieldsValue(config: MISSING_TYPE) {
    let contentStrategy = ''
    if (config.contentStrategy['@class'] === new NewStudioListContentStrategyConfig().className) {
      contentStrategy = StudioListContentStrategyEnum.NEW_STUDIOS
    }
    if (
      config.contentStrategy['@class'] ===
      new RecommendedStudioListContentStrategyConfig().className
    ) {
      contentStrategy = StudioListContentStrategyEnum.RECOMMENDED_STUDIOS
    }
    return {
      rank: config?.rank,
      cityIds: config?.cityIds || [],
      title: config?.title.value,
      titleTranslation:
        config?.titleTranslations && config?.titleTranslations.length
          ? config?.titleTranslations[0].value
          : '',
      tagBgColor: config?.tagBgColor,
      tagText: config?.tagText.value,
      tagTextTranslation:
        config?.tagTextTranslations && config?.tagTextTranslations.length
          ? config?.tagTextTranslations[0].value
          : '',
      actionUrl: config?.action?.url,
      actionType: config?.action?.type,
      actionText: config?.action?.text?.value,
      actionTextTranslation:
        config?.action?.textTranslations && config?.action?.textTranslations.length
          ? config?.action?.textTranslations[0].value
          : '',
      contentStrategy,
      max: config.contentStrategy?.max,
      studioIds: config.contentStrategy?.studioIds,
    }
  }

  getParams() {
    return {
      '@class': this.className,
      rank: this._rank,
      action: this._action,
      cityIds: this._cityIds,
      title: this._title,
      titleTranslations: this._titleTranslations,
      tagBgColor: this._tagBgColor,
      tagText: this._tagText,
      tagTextTranslations: this._tagTextTranslations,
      contentStrategy: this._contentStrategy,
    }
  }
}

export class UpcomingLessonCardConfig extends CardConfig {
  constructor() {
    super('UpcomingLessonCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    let contentStrategy = ''
    if (
      config.contentStrategy['@class'] === new UpcomingLessonCardContentStrategyConfig().className
    ) {
      contentStrategy = UpcomingLessonContentStrategyEnum.UPCOMING_LESSONS
    }
    return {
      contentStrategy,
    }
  }
  getParams() {
    const contentStrategy = new UpcomingLessonCardContentStrategyConfig().getContentStrategy()
    return {
      '@class': this.className,
      contentStrategy,
    }
  }
}

export class FrequentlyBookedLessonCardConfig extends CardConfig {
  _max?: number
  constructor() {
    super('FrequentlyBookedLessonCardConfig')
  }

  public set max(v: number) {
    this._max = v
  }

  setFieldsValue(config: MISSING_TYPE) {
    let contentStrategy = ''
    if (
      config.contentStrategy['@class'] ===
      new FrequentlyBookedLessonCardContentStrategyConfig().className
    ) {
      contentStrategy = FrequentlyBookedLessonsContentStrategyEnum.FREQUENTLY_BOOKED_LESSONS
    }
    return {
      contentStrategy,
      max: config.contentStrategy?.max,
    }
  }

  getParams() {
    const contentStrategy = new FrequentlyBookedLessonCardContentStrategyConfig(
      this._max,
    ).getContentStrategy()
    return {
      '@class': this.className,
      contentStrategy,
    }
  }
}

type CounponCardConfigContent = {
  locale: string
  imageUrl: string
}

export class CouponCardConfig extends CardConfig {
  _content?: CounponCardConfigContent
  _contentTranslations?: CounponCardConfigContent[]
  _contentStrategy?: contentStrategy

  private imgAssetUrl = `${assetUrl}/${UploadFileDirectoryEnum.STUDIO_LESSON_LIST_BANNERS}`

  constructor() {
    super('CouponCardConfig')
  }

  setFieldsValue(config: MISSING_TYPE) {
    return {
      couponTemplateId: config?.contentStrategy?.couponTemplateId,
      imageUrl: config?.content?.imageUrl
        ? [
            {
              status: 'done',
              url: config?.content?.imageUrl,
              thumbUrl: config?.content?.imageUrl,
            },
          ]
        : [],
      imageUrlTranslation:
        config?.contentTranslations &&
        config?.contentTranslations.length &&
        config?.contentTranslations[0].imageUrl
          ? [
              {
                status: 'done',
                url: config?.contentTranslations[0].imageUrl,
                thumbUrl: config?.contentTranslations[0].imageUrl,
              },
            ]
          : [],
      actionUrl: config?.action?.url,
      actionType: config?.action?.type,
      actionText: config?.action?.text?.value,
      actionTextTranslation:
        config?.action?.textTranslations && config?.action?.textTranslations.length
          ? config?.action?.textTranslations[0].value
          : '',
    }
  }

  public set content({
    imageUrl,
    imageUrlTranslation,
  }: {
    imageUrl: MISSING_TYPE
    imageUrlTranslation: MISSING_TYPE
  }) {
    this._content = {
      locale: 'zh-CN',
      imageUrl: getImageUrl('image_url', this.imgAssetUrl, imageUrl),
    }

    this._contentTranslations = [
      {
        locale: 'en',
        imageUrl: getImageUrl('image_url_translation', this.imgAssetUrl, imageUrlTranslation),
      },
    ]
  }

  public set contentStrategy({ couponTemplateId }: { couponTemplateId: number }) {
    this._contentStrategy = new CouponCardContentStrategyConfig(
      couponTemplateId,
    ).getContentStrategy()
  }

  getParams() {
    return {
      '@class': this.className,
      content: this._content,
      contentTranslations: this._contentTranslations,
      contentStrategy: this._contentStrategy,
      action: this._action,
    }
  }
}

export class FirstClassDiscountedStudioCardConfig extends CardConfig {
  _max?: number
  constructor() {
    super('FirstClassDiscountedStudioCardConfig')
  }

  public set max(v: number) {
    this._max = v
  }

  setFieldsValue(config: MISSING_TYPE) {
    let contentStrategy = ''
    if (
      config.contentStrategy['@class'] ===
      new FirstClassDiscountedStudiosContentStrategyConfig().className
    ) {
      contentStrategy =
        FirstClassDiscountedStudiosContentStrategyConfigEnum.FIRST_CLASS_DISCOUNTED_STUDIOS
    }
    return {
      contentStrategy,
      max: config.contentStrategy?.max,
    }
  }

  getParams() {
    const contentStrategy = new FirstClassDiscountedStudiosContentStrategyConfig(
      this._max,
    ).getContentStrategy()
    return {
      '@class': this.className,
      contentStrategy,
    }
  }
}
