import { Button, Form, Input, notification, Select, Switch } from 'antd'
import styles from './segment-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createSegment, editSegment, getSegmentDetail } from '../../../api/segmentApi'
import { SegmentRequest, SegmentTypeEnum } from '../share-type'

const SegmentForm = () => {
  const [form] = Form.useForm()

  const params = useParams()
  const navigation = useNavigate()
  const [segmentId] = useState<string | undefined>(params?.id)

  useEffect(() => {
    if (segmentId) {
      getSegmentDetail(Number(segmentId)).then(data => {
        form.setFieldsValue(data)
      })
    }
  }, [segmentId])

  const onFinish = (data: SegmentRequest) => {
    if (segmentId) {
      editSegment(Number(segmentId), data)
        .then(res => {
          notification.success({
            message: '编辑用户分组成功',
          })
          navigation(`/segments/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '编辑用户分组失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    } else {
      createSegment(data)
        .then(res => {
          notification.success({
            message: '创建用户分组成功',
          })
          navigation(`/segments/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建用户分组失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  return (
    <Form
      name="segment"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false, type: SegmentTypeEnum.CUSTOM }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['segment-form']}
    >
      <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称！' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="描述" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="类型" name="type">
        <Select
          disabled={segmentId !== undefined}
          options={[
            { value: 'CUSTOM', label: '自定义' },
            { value: 'IKEA', label: 'IKEA' },
          ]}
        />
      </Form.Item>
      <Form.Item label="是否激活" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SegmentForm
