import { Descriptions, Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './prelaunch-organization-form.module.less'
import OrganizationDescriptions from '../../../components/organizations/organization-descriptions/organization-descriptions'
import { getPrelaunchOrganizationForm } from '../../../api/prelaunchOrganizationFormsApi'
import translate from '../../../i18n'
import { IPrelaunchOrganizationForm } from '../share-types'
import { getDateTime } from '../../../helpers/utils'
import PrelaunchStudiosTable from '../../../components/prelaunch-organization-forms/prelaunch-studios-table/prelaunch-studio-table'
import PrelaunchLessonDescriptionsTable from '../../../components/prelaunch-organization-forms/prelaunch-lesson-descriptions-table/prelaunch-lesson-descriptions-table'
import PrelaunchCompanyPaymentDetailDescriptions from '../../../components/prelaunch-organization-forms/prelaunch-payment-detail-descriptions/prelaunch-payment-detail-descriptions'

const { Paragraph } = Typography

const PrelaunchOrganizationForm = () => {
  const params = useParams()
  const [form, setForm] = useState<IPrelaunchOrganizationForm>()
  const [formId] = useState<string | undefined>(params?.id)

  useEffect(() => {
    loadOrganization()
  }, [formId])

  const loadOrganization = () => {
    if (formId) {
      getPrelaunchOrganizationForm(Number(formId)).then(res => {
        setForm(res)
      })
    }
  }

  const getUniqueLink = (uniqueId: string) => {
    const host = window.location.host
    return `${host}/public/prelaunch-organization-form/${uniqueId}`
  }

  return (
    <Space className={styles['organization-desc-wrapper']} direction="vertical" wrap size={16}>
      {form && (
        <>
          <Descriptions
            title={translate('navBar.prelaunchOrganizationFormsTitle')}
            labelStyle={{ alignItems: 'center', fontWeight: 600, width: '200px' }}
            contentStyle={{ minWidth: 300 }}
            column={2}
            bordered
          >
            <Descriptions.Item label="ID">{form.id}</Descriptions.Item>
            <Descriptions.Item label={translate('prelaunchOrganizationForms.uniqueId')}>
              {form.uniqueId}
            </Descriptions.Item>

            <Descriptions.Item label={translate('prelaunchOrganizationForms.uniqueLink')}>
              <Paragraph copyable>{getUniqueLink(form.uniqueId)}</Paragraph>
            </Descriptions.Item>

            <Descriptions.Item label={translate('prelaunchOrganizationForms.expiredAt')}>
              {getDateTime(form.expiredAt)}
            </Descriptions.Item>
            <Descriptions.Item label={translate('createdAt')}>
              {getDateTime(form.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label={translate('updatedAt')}>
              {getDateTime(form.updatedAt)}
            </Descriptions.Item>
            <Descriptions.Item label={translate('status')}>{form.status}</Descriptions.Item>
          </Descriptions>

          <PrelaunchStudiosTable
            prelaunchOrganizationId={formId}
            prelaunchStudios={form.prelaunchStudios}
          />

          <PrelaunchLessonDescriptionsTable
            prelaunchOrganizationId={formId}
            prelaunchLessonDescriptions={form.prelaunchLessonDescription}
          />

          <PrelaunchCompanyPaymentDetailDescriptions
            prelaunchOrganizationId={formId}
            prelaunchCompanyPaymentDetail={form.prelaunchCompanyPaymentDetail}
          />

          <OrganizationDescriptions
            organization={form.organization}
            showCreatePrelaunchOrganizationFormButton={false}
          />
        </>
      )}
    </Space>
  )
}

export default PrelaunchOrganizationForm
