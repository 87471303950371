import { combineReducers } from 'redux'

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createRootReducer(injectedReducers) {
  return combineReducers({
    ...injectedReducers,
  })
}
