import { Button, Form, Input, InputNumber, notification, Radio, Select, Switch } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { ReduxStore } from '../../../store'
import { setBookingReviewsAction } from '../../../store/booking-review/action'
import { City } from '../../../store/city/reducer'
import {
  pageTypeOptions,
  targetUserGroupsOptions,
  cardStackTypeOptionsByCardStackPageType,
} from '../constant'
import { CardActionType, CardStackTypeEnum, CardStackPageTypeEnum } from '../share-type'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './card-stack-form.module.less'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createCardStack, editCardStack, getCardStackDetail } from '../../../api/cardStackApi'
import {
  CarouselCardStack,
  GridCardStack,
  ListableBannerCardStack,
} from '../../../helpers/card-stack-config'

const { Option } = Select
const grid = new GridCardStack()
const carousel = new CarouselCardStack()
const listableBanner = new ListableBannerCardStack()

const CardStackForm = (props: ConnectedProps<typeof withConnect>) => {
  const { activeCities } = props
  const [form] = Form.useForm()
  const cardStackTypeValue = Form.useWatch('cardType', form)
  const numberOfRowValue = Form.useWatch('numberOfRow', form)
  const numberOfColumnValue = Form.useWatch('numberOfColumn', form)
  const pageType = Form.useWatch('pageType', form)

  const params = useParams()
  const navigation = useNavigate()
  const [cardStackId] = useState<string | undefined>(params?.id)
  const [cardStackTypeOptions, setCardStackTypeOptions] = useState<Array<any>>(
    cardStackTypeOptionsByCardStackPageType[CardStackPageTypeEnum.HOME],
  )

  useEffect(() => {
    if (cardStackId) {
      getCardStackDetail(Number(cardStackId)).then(res => {
        const { config, cityIds, ...other } = res
        let cardTypeConfig
        let cardType
        if (config['@class'] === carousel.className) {
          cardTypeConfig = carousel.setFieldsCardConfigFormValue(config)
          cardType = CardStackTypeEnum.CAROUSEL
        } else if (config['@class'] === grid.className) {
          cardTypeConfig = grid.setFieldsCardConfigFormValue(config)
          cardType = CardStackTypeEnum.GRID
        } else {
          cardTypeConfig = listableBanner.setFieldsCardConfigFormValue(config)
          cardType = CardStackTypeEnum.LISTABLE_BANNER
        }

        form.setFieldsValue({
          cardType,
          ...cardTypeConfig,
          ...other,
          cityIds: cityIds ? cityIds.split(',').map(item => Number(item)) : [],
        })
      })
    }
  }, [cardStackId])

  useEffect(() => {
    setCardStackTypeOptions(
      cardStackTypeOptionsByCardStackPageType[
        pageType as keyof typeof cardStackTypeOptionsByCardStackPageType
      ],
    )
    // clear the cardType options when pageType value changes
    form.setFieldValue('cardType', undefined)
  }, [pageType])

  const onFinish = (values: any) => {
    const {
      cardType,
      title,
      actionUrl,
      actionType,
      actionText,
      actionTextTranslation,
      filters,
      titleTranslation,
      numberOfRow,
      numberOfColumn,
      intervalInMs,
      cityIds,
      position,
      ...other
    } = values
    let config

    switch (cardType) {
      case CardStackTypeEnum.GRID:
        grid.title = title
        grid.titleTranslation = titleTranslation
        grid.action = { actionUrl, actionType, actionText, actionTextTranslation }
        grid.filters = filters
        grid.numberOfColumn = numberOfColumn
        grid.numberOfRow = numberOfRow
        config = grid.getConfig()
        break
      case CardStackTypeEnum.CAROUSEL:
        carousel.title = title
        carousel.titleTranslation = titleTranslation
        carousel.action = { actionUrl, actionType, actionText, actionTextTranslation }
        carousel.filters = filters
        carousel.intervalInMs = intervalInMs
        config = carousel.getConfig()
        break
      case CardStackTypeEnum.LISTABLE_BANNER:
        listableBanner.title = title
        listableBanner.titleTranslation = titleTranslation
        listableBanner.action = { actionUrl, actionType, actionText, actionTextTranslation }
        listableBanner.filters = filters
        listableBanner.position = position
        config = listableBanner.getConfig()
        break
      default:
        break
    }

    const cityIdsString = cityIds && cityIds.length ? cityIds.join(',') : null

    const data = { config, cityIds: cityIdsString, ...other }
    if (cardStackId) {
      editCardStack(Number(cardStackId), data)
        .then(res => {
          notification.success({
            message: '编辑卡片堆成功',
          })
          navigation(`/card-stacks/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建卡片堆失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    } else {
      createCardStack(data)
        .then(res => {
          notification.success({
            message: '创建卡片设置成功',
          })
          navigation(`/card-stacks/${res.id}`)
        })
        .catch(err => {
          notification.error({
            message: '创建卡片堆失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  return (
    <Form
      name="card-stack"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ isActive: false }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      className={styles['card-stack-form']}
    >
      <Form.Item
        label="页面种类"
        name="pageType"
        initialValue={CardStackPageTypeEnum.HOME}
        rules={[{ required: true, message: '请选择页面种类' }]}
      >
        <Select>
          {pageTypeOptions.map(pageType => {
            return (
              <Option key={pageType.value} value={pageType.value}>
                {pageType.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称！' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="标题（中文）"
        name="title"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('titleTranslation') && !value) {
                return Promise.reject(new Error('填写标题（英文）后标题（中文）必填'))
              }
              return Promise.resolve()
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="标题（英文）"
        name="titleTranslation"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('title') && !value) {
                return Promise.reject(new Error('填写标题（中文）后标题（英文）必填'))
              }
              return Promise.resolve()
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="顺序" name="rank" rules={[{ required: true, message: '请输入顺序！' }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="卡片类型"
        name="cardType"
        rules={[{ required: true, message: '请选择卡片类型！' }]}
        initialValue={CardStackTypeEnum.CAROUSEL}
      >
        <Radio.Group options={cardStackTypeOptions}></Radio.Group>
      </Form.Item>
      {cardStackTypeValue === CardStackTypeEnum.CAROUSEL && (
        <Form.Item label="动画时间间隔（ms）" name="intervalInMs">
          <InputNumber min={3000} />
        </Form.Item>
      )}

      {cardStackTypeValue === CardStackTypeEnum.LISTABLE_BANNER && (
        <Form.Item label="在列表中的位置" name="position">
          <InputNumber />
        </Form.Item>
      )}

      {cardStackTypeValue === CardStackTypeEnum.GRID && (
        <>
          <Form.Item
            label="行数"
            name="numberOfRow"
            rules={[
              { required: !numberOfRowValue && !numberOfColumnValue, message: '请输入行数！' },
            ]}
            extra="行数和列数必须填写一个"
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            label="列数"
            name="numberOfColumn"
            rules={[
              { required: !numberOfRowValue && !numberOfColumnValue, message: '请输入列数！' },
            ]}
            extra="行数和列数必须填写一个"
          >
            <InputNumber min={1} />
          </Form.Item>
        </>
      )}
      <Form.Item label="是否激活" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="目标城市" name="cityIds">
        <Select mode="multiple">
          {activeCities.map((city: City) => {
            const cityTranslations = city.translations.find(item => item.locale === props.locale)
            return (
              <Option key={city.id} value={city.id}>
                {city.id}-{cityTranslations?.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="目标用户"
        name="targetGroup"
        rules={[{ required: true, message: '请选择目标用户！' }]}
      >
        <Select>
          {targetUserGroupsOptions.map(userGroup => {
            return (
              <Option key={userGroup.value} value={userGroup.value}>
                {userGroup.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label="跳转路径" name="actionUrl">
        <Input />
      </Form.Item>
      <Form.Item
        label="跳转类型"
        name="actionType"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('actionUrl') && !value) {
                return Promise.reject(new Error('填写跳转路径后跳转类型必选'))
              }
              return Promise.resolve()
            },
          }),
        ]}
      >
        <Select allowClear>
          <Option value={CardActionType.DEEP_LINK}>{CardActionType.DEEP_LINK}</Option>
          <Option value={CardActionType.EXTERNAL}>{CardActionType.EXTERNAL}</Option>
        </Select>
      </Form.Item>
      <Form.Item label="跳转文本（中文）" name="actionText">
        <Input />
      </Form.Item>
      <Form.Item label="跳转文本（英文）" name="actionTextTranslation">
        <Input />
      </Form.Item>

      <Form.List name="filters">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Form.Item
                  {...restField}
                  label="过滤器文字（中文）"
                  name={[name, 'text']}
                  rules={[{ required: true, message: '请输入过滤器文字！' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="过滤器文字（英文）"
                  name={[name, 'textTranslation']}
                  rules={[{ required: true, message: '请输入过滤器文字！' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="过滤条件（中文）"
                  name={[name, 'filter']}
                  extra="如果不设置过滤条件，则以过滤文本（中文）作为中文过滤条件"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="过滤条件（英文）"
                  name={[name, 'filterTranslation']}
                  extra="如果不设置过滤条件，则以过滤文本（英文）作为英文过滤条件"
                >
                  <Input />
                </Form.Item>

                <Form.Item label="是否默认" name={[name, 'isDefault']} valuePropName="checked">
                  <Switch />
                </Form.Item>

                <Form.Item colon={false} label="  ">
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(name)}
                  />
                </Form.Item>
              </div>
            ))}
            <Form.Item colon={false} label="  ">
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                新增过滤器
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const mapDispatchToProps = {
  setBookingReviewsAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(CardStackForm)
