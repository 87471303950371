import { StudioPhoneNumberTypeEnums } from './share-types'

export const StudioPhoneNumberTypeOptions = [
  {
    value: StudioPhoneNumberTypeEnums.CUSTOMER_SUPPORT,
    label: '客户联系电话',
  },
  {
    value: StudioPhoneNumberTypeEnums.NOTIFICATION,
    label: '接收预约通知的手机号',
  },
]
