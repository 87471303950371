import { useState } from 'react'
import { changeLanguage } from '../../i18n'
import { LocaleType } from '../../share-types'
import { Button, Radio, Space } from 'antd'
import { ReduxStore } from '../../store'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from '../../components/intl'
import styles from './change-language.module.less'

function ChangeLanguage(props: any) {
  const [locale, setLocale] = useState<LocaleType>(props.locale)
  const onChangeLanguage = () => {
    changeLanguage(locale)
  }

  return (
    <div className={styles['change-language-wrapper']}>
      <Radio.Group
        className={styles['radio-group']}
        onChange={e => setLocale(e.target.value)}
        value={locale}
      >
        <Space size="middle" align="start" direction="vertical">
          <Radio value={LocaleType.zh_CN}>中文</Radio>
          <Radio value={LocaleType.en}>English</Radio>
        </Space>
      </Radio.Group>
      <Button type="primary" onClick={onChangeLanguage}>
        <FormattedMessage id="saveButton"></FormattedMessage>
      </Button>
    </div>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ChangeLanguage)
