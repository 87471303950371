import { ReduxStore } from '../../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './studio-leaderboard-category.module.less'
import { IStudioLeaderboardCategory } from '../share-type'
import { Outlet } from 'react-router-dom'
import { getStudioLeaderboardCategoryDetail } from '../../../api/studioLeaderboardCategoriesApi'
import StudioLeaderboardCategoriesDescriptions from '../../../components/studio-leaderboard-categories/studio-leaderboard-category-descriptions/studio-leaderboard-category-descriptions'

const StudioLeaderboardCategories = () => {
  const params = useParams()
  const [studioLeaderboardCategoryId] = useState<string | undefined>(params?.id)
  const [studioLeaderboardCategory, setStudioLeaderboardCategory] =
    useState<IStudioLeaderboardCategory>()
  useEffect(() => {
    if (studioLeaderboardCategoryId) {
      getStudioLeaderboardCategoryDetail(Number(studioLeaderboardCategoryId)).then(res => {
        setStudioLeaderboardCategory(res)
      })
    }
  }, [studioLeaderboardCategoryId])

  return (
    <Space
      className={styles['studio-leaderboard-categories-desc-wrapper']}
      direction="vertical"
      wrap
      size={16}
    >
      {studioLeaderboardCategory && (
        <StudioLeaderboardCategoriesDescriptions
          studioLeaderboardCategory={studioLeaderboardCategory}
        />
      )}

      <Outlet />
    </Space>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    activeCities: state.cities.activeCities,
    locale: state.global.locale,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(StudioLeaderboardCategories)
