import update from 'immutability-helper'
import { Permission, Role } from '../../pages/admin-roles-container/share-type'
import { IRejectReason } from '../../pages/booking-reviews-container/share-type'
import { LocaleType } from '../../share-types'
import { GlocalAction } from './action'
import {
  SET_LOCALE_KEY,
  SET_PLANS_KEY,
  SET_PERMISSIONS_KEY,
  SET_ROLES_KEY,
  SET_REJECT_REASONS_KEY,
  SET_CURRENT_MENU_TYPE_KEY,
} from './action-keys'

export type PlanTranslation = {
  id: number
  locale: string
  name: string
}

export type Plan = {
  id: number
  price: number
  credits: number
  translations: PlanTranslation[]
}

interface InitialState {
  locale: LocaleType
  plans: Plan[]
  permissions: Permission[]
  roles: Role[]
  rejectReasons: IRejectReason[]
  currentMenuType: string
}

const initialState: InitialState = {
  locale: LocaleType.zh_CN,
  plans: [],
  permissions: [],
  roles: [],
  rejectReasons: [],
  currentMenuType: '',
}

function globalReducer(state = initialState, action: GlocalAction) {
  switch (action.type) {
    case SET_LOCALE_KEY:
      return update(state, { $merge: { locale: action.locale } })
    case SET_PLANS_KEY:
      return update(state, { $merge: { plans: action.plans } })
    case SET_PERMISSIONS_KEY:
      return update(state, { $merge: { permissions: action.permissions } })
    case SET_ROLES_KEY:
      return update(state, { $merge: { roles: action.roles } })
    case SET_REJECT_REASONS_KEY:
      return update(state, { $merge: { rejectReasons: action.rejectReasons } })
    case SET_CURRENT_MENU_TYPE_KEY:
      return update(state, { $merge: { currentMenuType: action.currentMenuType } })
    default:
      return state
  }
}

export default globalReducer
