import { Button, Form, Select, Input, DatePicker } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translate from '../../../i18n'
import moment from 'moment/moment'
import { IOneTimeCodeBatchesFilterParams } from '../../../pages/one-time-code-batches-container/share-type'

const { Option } = Select
const { RangePicker } = DatePicker
const OneTimeCodeBatchesFilter = ({
  onSearch,
  formData,
}: {
  onSearch: (value: IOneTimeCodeBatchesFilterParams) => void
  formData: IOneTimeCodeBatchesFilterParams | undefined
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        timeRange:
          formData.startDate && formData.endDate
            ? [moment(formData.startDate), moment(formData.endDate)]
            : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: any) => {
    const { timeRange, ...other } = values
    onSearch({
      ...other,
      startDate: timeRange ? moment(timeRange[0]).utc().format() : undefined,
      endDate: timeRange ? moment(timeRange[1]).utc().format() : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" style={{ gap: '8px' }} onFinish={onFinish}>
      <Form.Item name="id" label="ID">
        <Input />
      </Form.Item>
      <Form.Item name="name" label={translate('oneTimeCodeBatches.name')}>
        <Input />
      </Form.Item>
      <Form.Item name="isNewUserOnly" label={translate('oneTimeCodeBatches.isNewUserOnly')}>
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>
      <Form.Item name="timeRange" label={translate('createdAt')}>
        <RangePicker
          showTime={{
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {translate('search')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default OneTimeCodeBatchesFilter
