import { Button, Typography, Form, Row, Select, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { ColumnsType } from 'antd/lib/table'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'
import {
  ICorporateCreditsDistributionSchedule,
  ICorporateCreditsDistributionScheduleFilterParams,
} from '../../../pages/corporates-container/share-type'
import LinkButton from '../../link-button/link-button'
import YesOrNoTag from '../../common/YesOrNoTag'
import { SearchOutlined } from '@ant-design/icons'
import { useEffect } from 'react'

const { Option } = Select
const { Title } = Typography

interface CorporateCreditsDistributionScheduleTableProps {
  schedules: ICorporateCreditsDistributionSchedule[]
  corporateId: number
  pageInfo: PageInfo
  paginationOnChange: (page: number, pageSize: number) => void
  onSearch: (data: ICorporateCreditsDistributionScheduleFilterParams) => void
  searchValues: ICorporateCreditsDistributionScheduleFilterParams | undefined
}

const CorporateCreditsDistributionScheduleTable = ({
  schedules,
  corporateId,
  pageInfo,
  paginationOnChange,
  onSearch,
  searchValues,
}: CorporateCreditsDistributionScheduleTableProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (searchValues) {
      form.setFieldsValue({
        searchValues,
      })
    }
  }, [searchValues])

  const onFinish = (values: ICorporateCreditsDistributionScheduleFilterParams) => {
    onSearch(values)
  }

  const columns: ColumnsType<ICorporateCreditsDistributionSchedule> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: translate('isActive'),
      dataIndex: 'isActive',
      key: 'isActive',
      width: 80,
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('corporate.corporateCreditsDistributionSchedule.modal.isApplyForAllMembers'),
      dataIndex: 'isApplyForAllMembers',
      key: 'isApplyForAllMembers',
      width: 100,
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('corporate.corporateCreditsDistributionSchedule.modal.schedule.type'),
      dataIndex: ['schedule', 'type'],
      key: 'type',
      width: 120,
    },
    {
      title: translate('corporate.credits'),
      dataIndex: 'credits',
      key: 'credits',
      width: 100,
    },
    {
      title: translate('corporate.bufferPercentage'),
      dataIndex: 'bufferPercentage',
      key: 'bufferPercentage',
      width: 120,
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (createdAt: string) => (createdAt ? getDateTime(createdAt) : ''),
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text: string, record: ICorporateCreditsDistributionSchedule) => (
        <Space>
          <AuthPermission
            permission={PermissionsEnum.CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_WRITE}
          >
            <LinkButton
              to={`/corporates/${corporateId}/credits-distribution-schedules/${record.id}/edit`}
            >
              {translate('edit')}
            </LinkButton>
          </AuthPermission>
          <AuthPermission
            permission={PermissionsEnum.CORPORATE_CREDITS_DISTRIBUTION_SCHEDULES_READ}
          >
            <LinkButton
              to={`/corporates/${corporateId}/credits-distribution-schedules/${record.id}`}
            >
              {translate('view')}
            </LinkButton>
          </AuthPermission>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Space className="table-space" direction="vertical" wrap size={16}>
        <Title level={5}>{translate('navbar.corporateCreditsDistributionSchedule')}</Title>

        <Row className="filter-bar">
          <Form form={form} layout="inline" onFinish={onFinish}>
            <Form.Item name="isActive" label="活跃">
              <Select style={{ minWidth: 130 }} allowClear>
                <Option value={true}>是</Option>
                <Option value={false}>否</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                搜索
              </Button>
            </Form.Item>
          </Form>
        </Row>

        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={schedules}
          pagination={{
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            total: pageInfo.count,
            onChange: paginationOnChange,
          }}
          scroll={{ x: 1140 }}
        />
      </Space>
    </Space>
  )
}

export default CorporateCreditsDistributionScheduleTable
