import config from '../config/index'
import {
  IOneTimeCodeBatch,
  IPaginatedOneTimeCodeBatchesParams,
  OneTimeCodeBatchRequest,
  OneTimeCodeStatus,
  PaginatedOneTimeCodeBatchesResponse,
} from '../pages/one-time-code-batches-container/share-type'
import {
  IOneTimeCode,
  IPaginatedOneTimeCodesParams,
  PaginatedOneTimeCodesResponse,
} from '../pages/one-time-codes-container/share-type'
import { authRequest } from '../request'
import { downloadFile } from '../request/utils'

const { mainApi } = config

export const getPaginatedOneTimeCodeBatches = (data: IPaginatedOneTimeCodeBatchesParams) =>
  authRequest.get<MISSING_TYPE, PaginatedOneTimeCodeBatchesResponse>(
    `${mainApi}/one-time-code-batches`,
    {
      params: data,
    },
  )

export const getOneTimeCodeBatchDetail = (batchId: number) =>
  authRequest.get<MISSING_TYPE, IOneTimeCodeBatch>(`${mainApi}/one-time-code-batches/${batchId}`)

export const createOneTimeCodeBatch = (data: OneTimeCodeBatchRequest) =>
  authRequest.post<MISSING_TYPE, IOneTimeCodeBatch>(`${mainApi}/one-time-code-batches`, data)

export const editOneTimeCodeBatch = (batchId: number, data: OneTimeCodeBatchRequest) =>
  authRequest.put<MISSING_TYPE, IOneTimeCodeBatch>(
    `${mainApi}/one-time-code-batches/${batchId}`,
    data,
  )

export const getPaginatedOneTimeCodes = (data: IPaginatedOneTimeCodesParams) =>
  authRequest.get<MISSING_TYPE, PaginatedOneTimeCodesResponse>(`${mainApi}/one-time-codes`, {
    params: data,
  })

export const oneTimeCodeBatchChangeStatus = (batchId: number, status: OneTimeCodeStatus) =>
  authRequest.put<MISSING_TYPE, IOneTimeCodeBatch>(
    `${mainApi}/one-time-code-batches/${batchId}/change-status?status=${status}`,
  )

export const oneTimeCodeChangeStatus = (codeId: number, status: OneTimeCodeStatus) =>
  authRequest.put<MISSING_TYPE, IOneTimeCode>(
    `${mainApi}/one-time-codes/${codeId}/change-status?status=${status}`,
  )

export const exportOneTimeCodes = (batchId: number, password: string) => {
  const time = new Date().getTime()
  const fileName = `OTC-Batch-${batchId}-${time}.zip`
  return downloadFile(`${mainApi}/one-time-code-batches/${batchId}/export`, { password }, fileName)
}
