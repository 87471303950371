import { Button, Col, Dropdown, Modal, Row, Space, Table } from 'antd'
import AuthPermission from '../../../high-order-components/auth-permission'
import { useSearchParams } from 'react-router-dom'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import { useEffect, useState } from 'react'
import {
  DownOutlined,
  MinusCircleOutlined,
  StopOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import FeatureFlagsFilter from '../../../components/feature-flags/feature-flags-filter/feature-flags-filter'
import {
  IFeatureFlag,
  IFeatureFlagsFilterParams,
  IPaginatedFeatureFlagsParams,
} from '../share-type'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import LinkButton from '../../../components/link-button/link-button'
import { getDateTime, getSearchParams } from '../../../helpers/utils'
import {
  changeFeatureFlagStatus,
  deleteFeatureFlagById,
  getPaginatedFeatureFlags,
} from '../../../api/featureFlagApi'
import YesOrNoTag from '../../../components/common/YesOrNoTag'
import { FormattedMessage } from 'react-intl'
import styles from './feature-flags.module.less'
const { confirm } = Modal

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const FetureFlags = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlag[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IFeatureFlagsFilterParams>()

  useEffect(() => {
    const searchName = searchParams.get('name')
    const searchIsActive = searchParams.get('isActive')
    const searchPageSize = searchParams.get('size')
    const searchPage = searchParams.get('page')
    const newSearchValues = {
      name: searchName ?? undefined,
      isActive: searchIsActive ? (searchIsActive === 'true' ? true : false) : undefined,
    }
    const data = {
      ...newSearchValues,
      size: searchPageSize === null ? defaultPage.pageSize : Number(searchPageSize),
      page: searchPage === null ? defaultPage.currentPage - 1 : Number(searchPage),
    }
    setSearchValues(newSearchValues)
    getPaginatedFeatureFlagAction(data)
  }, [])

  const getPaginatedFeatureFlagAction = (data: IPaginatedFeatureFlagsParams) => {
    getPaginatedFeatureFlags(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setFeatureFlags(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    setSearchParams(getSearchParams(data))
    getPaginatedFeatureFlagAction(data)
  }
  const onSearch = (value: IFeatureFlagsFilterParams) => {
    const data = {
      ...value,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(data))
    getPaginatedFeatureFlagAction(data)
  }

  const getFirstPageFeatureFlag = () => {
    const data = {
      ...searchValues,
      size: pageInfo.pageSize,
      page: 0,
    }
    setSearchParams(getSearchParams(data))
    getPaginatedFeatureFlagAction(data)
  }

  const onChangeStatus = (key: string, record: IFeatureFlag) => {
    const isActive = key === 'activate' ? true : false
    confirm({
      title: `是否${key} feature flag`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        changeFeatureFlagStatus(record.id, isActive).then(() => {
          getFirstPageFeatureFlag()
        })
      },
    })
  }

  const onDeleteFeatureFlag = (record: IFeatureFlag) => {
    confirm({
      title: `是否删除 feature flag`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteFeatureFlagById(record.id).then(() => {
          getFirstPageFeatureFlag()
        })
      },
    })
  }

  const activeItemActionItems = [
    {
      key: 'delete',
      label: (
        <Button type="link" danger icon={<MinusCircleOutlined />}>
          删除
        </Button>
      ),
    },
    {
      key: 'deactivate',
      label: (
        <Button type="link" danger icon={<StopOutlined />}>
          停用
        </Button>
      ),
    },
  ]

  const inactiveItemActionItems = [
    {
      key: 'delete',
      label: (
        <Button type="link" danger icon={<MinusCircleOutlined />}>
          删除
        </Button>
      ),
    },
    {
      key: 'activate',
      label: (
        <Button type="text" icon={<CheckCircleOutlined />}>
          激活
        </Button>
      ),
    },
  ]

  const handleMenuClick = (e: any, record: MISSING_TYPE) => {
    switch (e.key) {
      case 'deactivate':
      case 'activate':
        onChangeStatus(e.key, record)
        break
      case 'delete':
        onDeleteFeatureFlag(record)
        break
      default:
        break
    }
  }

  const columns: ColumnsType<IFeatureFlag> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: number) => <YesOrNoTag value={isActive} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (text: string, record: IFeatureFlag) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.FEATURE_FLAGS_WRITE}>
            <Dropdown
              menu={{
                items: record.isActive ? activeItemActionItems : inactiveItemActionItems,
                onClick: e => handleMenuClick(e, record),
              }}
            >
              <a className={styles['action-item']}>
                <FormattedMessage id="tableActionColumn" /> <DownOutlined />
              </a>
            </Dropdown>
          </AuthPermission>

          <AuthPermission permission={PermissionsEnum.FEATURE_FLAGS_WRITE}>
            <LinkButton to={`/feature-flags/${record.id}/edit`}>编辑</LinkButton>
          </AuthPermission>

          <LinkButton to={`/feature-flags/${record.id}`}>查看</LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>功能开关列表</div>
        </Col>
      </Row>
      <Row className="filter-bar">
        <FeatureFlagsFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={featureFlags}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default FetureFlags
