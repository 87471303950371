import config from '../config/index'
import {
  IAdminRoleParams,
  Permission,
  RoleResponse,
} from '../pages/admin-roles-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getAdminRoles = () =>
  authRequest.get<MISSING_TYPE, RoleResponse[]>(`${mainApi}/admin-role`)

export const createAdminRole = (data: IAdminRoleParams) =>
  authRequest.post(`${mainApi}/admin-role`, data)

export const editAdminRole = (roleId: string, data: IAdminRoleParams) =>
  authRequest.put(`${mainApi}/admin-role/${roleId}`, data)

export const deleteAdminRole = (roleId: number) =>
  authRequest.delete(`${mainApi}/admin-role/${roleId}`)

export const getAdminRoleById = (roleId: number) =>
  authRequest.get<MISSING_TYPE, RoleResponse>(`${mainApi}/admin-role/${roleId}`)

export const getPermissions = () =>
  authRequest.get<MISSING_TYPE, Permission[]>(`${mainApi}/permissions`)
