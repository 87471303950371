import { Button, Form, Select, Input, DatePicker } from 'antd'
import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { ICorporateMembersFilterParams } from '../../../pages/corporates-container/share-type'
import translate from '../../../i18n'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const CorporateMembersFilter = ({
  onSearch,
  formData,
  corporateId,
}: {
  onSearch: (value: ICorporateMembersFilterParams) => void
  formData: ICorporateMembersFilterParams | undefined,
  corporateId: number
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        expiredTimeRange:
          formData.expiredStartDate && formData.expiredEndDate
            ? [moment(formData.expiredStartDate), moment(formData.expiredEndDate)]
            : undefined,
      })
    }
  }, [formData])
  const onFinish = (values: any) => {
    const { expiredTimeRange, ...other } = values
    onSearch({
      ...other,
      expiredStartDate: expiredTimeRange ? moment(expiredTimeRange[0]).utc().format() : undefined,
      expiredEndDate: expiredTimeRange ? moment(expiredTimeRange[1]).utc().format() : undefined,
    })
  }
  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="phoneNumber" label="电话号码">
        <Input />
      </Form.Item>

      <Form.Item name="isActive" label="活跃">
        <Select style={{ minWidth: 130 }} allowClear>
          <Option value={true}>是</Option>
          <Option value={false}>否</Option>
        </Select>
      </Form.Item>

      <Form.Item name="expiredTimeRange" label={translate('corporateMember.expiredTimeRange')}>
        <RangePicker showTime />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
          搜索
        </Button>
      </Form.Item>

      <Form.Item name="exportCorporateMember">
        <Button href={'https://metabase.guavapass.cn/question/1376-corporate-member-export-query?corporate_id='+corporateId} target='_blank'>{translate('corporateMember.export')}</Button>
      </Form.Item>
    </Form>
  )
}

export default CorporateMembersFilter
