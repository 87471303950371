import config from '../config/index'
import { authRequest, request } from '../request'
import { IAdminUser, INeightborhood } from '../share-types'

const { mainApi, authApi } = config

export interface ILoginParams {
  username: string
  password: string
}

export const loginApi = (data: ILoginParams) => request.post(`${authApi}/oauth/token`, data)

export const getAdminUserProfile = () =>
  authRequest.get<MISSING_TYPE, IAdminUser>(`${mainApi}/admin-user-info`)

export const getCities = () => authRequest.get(`${mainApi}/cities`)

export interface IPlansParams {
  page: number
  size: number
  type: number
}

export const getPlans = (data: IPlansParams) =>
  authRequest.get(`${mainApi}/plans`, { params: data })

export const getNeighborhoodsByCityId = (data: { cityId: number }) =>
  request.get<MISSING_TYPE, INeightborhood[]>(`${mainApi}/neighborhoods`, { params: data })
