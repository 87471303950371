import { Button, Card, Divider, Progress, Space } from 'antd'
import styles from './billing-summary.module.less'
import { IStudioAreaUserDto } from '../../../../share-types'
import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import BillingInvoicesProcessesModal from '../../billing-invoices/billing-invoices-processes-modal/billing-invoices-processes-modal'
import { getBillingSummary } from '../../../../studios-area-api/billingSummariesApi'
import { BillingSummaryResponse } from '../../../pages/billing-summaries/share-type'
import BillingSummaryMonthSelector from '../billing-summary-month-selector/billing-summary-month-selector'

interface IProps {
  studioIds?: number[] | null
  studioAdminUserInfo: IStudioAreaUserDto
}

const BillingSummary = (props: IProps) => {
  const { studioIds, studioAdminUserInfo } = props

  const [selectedMonth, setSelectedMonth] = useState<string | null>(
    moment().startOf('month').toISOString(),
  )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedMonthSummaryData, setSelectedMonthSummaryData] = useState<BillingSummaryResponse>()
  const [selectedLastMonthSummaryData, setSelectedLastMonthSummaryData] =
    useState<BillingSummaryResponse>()

  const [nextBillingDate, setNextBillingDate] = useState<Moment>(moment().date(18))
  const [invoiceDueDate, setInvoiceDueDate] = useState<Moment>(moment().date(12))

  useEffect(() => {
    const currentMoment = moment()

    if (currentMoment.date() > 18) {
      setNextBillingDate(currentMoment.clone().add(1, 'M').date(18))
      setInvoiceDueDate(currentMoment.clone().add(1, 'M').date(12))
    } else {
      setNextBillingDate(currentMoment.clone().date(18))
      setInvoiceDueDate(currentMoment.clone().date(12))
    }

    if (selectedMonth) {
      const startDate = moment(selectedMonth)
      const endDate = moment(selectedMonth).endOf('month')
      getBillingSummaryAction(startDate, endDate)
    }
  }, [selectedMonth, studioIds])
  const getBillingSummaryAction = (startDate: Moment, endDate: Moment) => {
    const params = {
      studioIds: studioIds,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }

    const lastMonthParams = {
      studioIds: studioIds,
      startDate: startDate.subtract(1, 'months').toISOString(),
      endDate: endDate.subtract(1, 'months').toISOString(),
    }

    const selectedMonthSummaryPromises = getBillingSummary(params)
    const selectedLastMonthSummaryPromises = getBillingSummary(lastMonthParams)

    Promise.all([selectedMonthSummaryPromises, selectedLastMonthSummaryPromises]).then(
      ([selectedMonthSummaryData, selectedLastMonthSummaryData]) => {
        setSelectedMonthSummaryData(selectedMonthSummaryData)
        setSelectedLastMonthSummaryData(selectedLastMonthSummaryData)
      },
    )
  }

  const getCompareNumberRender = (currentNumber: number, lastNumber: number) => {
    if (currentNumber === lastNumber) {
      return '0%'
    }

    if (lastNumber === 0 || Number.isNaN(lastNumber)) {
      return <span className={styles['green-word']}>N/A</span>
    }

    const percentage = ((currentNumber - lastNumber) / lastNumber) * 100
    const percentageStr = percentage % 1 === 0 ? `${percentage}%` : percentage.toFixed(2) + '%'

    if (currentNumber > lastNumber) {
      return <span className={styles['green-word']}>+{percentageStr}</span>
    }
    return <span className={styles['red-word']}>{percentageStr}</span>
  }

  const getFirstVisitBookingCountPercentage = (summaryData?: BillingSummaryResponse) => {
    return Math.round(
      ((summaryData?.firstVisitBookingCount ?? 0) / (summaryData?.bookingCount ?? 1)) * 100,
    )
  }

  const getRepeatVisitBookingCountPercentage = (summaryData?: BillingSummaryResponse) => {
    return Math.round(
      ((summaryData?.repeatVisitBookingCount ?? 0) / (summaryData?.bookingCount ?? 1)) * 100,
    )
  }

  const onChangeSelectedMonth = (value: string) => {
    setSelectedMonth(value)
  }

  return (
    <div>
      <Card className="studios-area-card" bordered={false}>
        <div className="display-flex gap-12">
          <div className={`studios-area-card-title`}>结算概览</div>
          <div className={styles['billing-summary-tips']}>
            <div>
              温馨提示：
              {moment(selectedMonth).isSame(moment(), 'month')
                ? '当月订单尚未完成，账单数据可能不准确。'
                : ''}
              下一次结算时间是
              <span className={styles['red-word']}>{nextBillingDate.format('M月D日')}</span>
              ，请确保在
              <span className={styles['red-word']}>{invoiceDueDate.format('M月D日')}</span>
              前寄送金额正确的发票。如有疑问，请联系我们运营团队。
            </div>
            <Button
              type="link"
              className={styles['show-billing-invoice-process-modal-button']}
              onClick={() => setIsModalVisible(true)}
            >
              查看结算流程
            </Button>
          </div>
        </div>

        <div className={styles['billing-summary-header']}>
          <BillingSummaryMonthSelector
            value={selectedMonth}
            onChangeSelectedMonth={onChangeSelectedMonth}
            studioAdminUserInfo={studioAdminUserInfo}
          />
        </div>

        <div className={styles['billing-summary-card-container']}>
          <Space size={16} className={styles['billing-summary-cards']}>
            <Card className={styles['billing-summary-card']}>
              <div>
                <span className={styles['symbol']}>￥</span>
                <span className={styles['number']}>
                  {selectedMonthSummaryData?.totalPriceCents
                    ? selectedMonthSummaryData?.totalPriceCents / 100
                    : 0}
                </span>
              </div>
              <div className={styles['description']}>结算总金额</div>
              <div className={styles['compare-number']}>
                <span>较上月</span>
                {getCompareNumberRender(
                  selectedMonthSummaryData?.totalPriceCents ?? 0,
                  selectedLastMonthSummaryData?.totalPriceCents ?? 0,
                )}
              </div>
            </Card>

            <Card className={styles['billing-summary-card']}>
              <div className={styles['number']}>{selectedMonthSummaryData?.bookingCount ?? 0}</div>
              <div className={styles['description']}>预约数</div>
              <div className={styles['compare-number']}>
                <span>较上月</span>
                {getCompareNumberRender(
                  selectedMonthSummaryData?.bookingCount ?? 0,
                  selectedLastMonthSummaryData?.bookingCount ?? 0,
                )}
              </div>
            </Card>

            <Card className={styles['billing-summary-card']}>
              <Space>
                <div>
                  <div className={styles['number']}>
                    {selectedMonthSummaryData?.checkedInBookingCount ?? 0}
                  </div>
                  <div className={styles['description']}>签到成功</div>
                  <div className={styles['compare-number']}>
                    <span>较上月</span>
                    {getCompareNumberRender(
                      selectedMonthSummaryData?.checkedInBookingCount ?? 0,
                      selectedLastMonthSummaryData?.checkedInBookingCount ?? 0,
                    )}
                  </div>
                </div>
                <Divider type="vertical" className={styles['divider']} />
                <div>
                  <div className="display-flex gap-12">
                    <div className={styles['sub-number-box']}>
                      <span className={styles['sub-number']}>
                        {selectedMonthSummaryData?.firstVisitBookingCount ?? 0}
                      </span>
                      <span>初访</span>
                    </div>
                    <Divider type="vertical" />
                    <div className={styles['compare-number-black-word']}>
                      较上月
                      {getCompareNumberRender(
                        selectedMonthSummaryData?.firstVisitBookingCount ?? 0,
                        selectedLastMonthSummaryData?.firstVisitBookingCount ?? 0,
                      )}
                    </div>
                  </div>
                  <div className="display-flex gap-12">
                    <Progress
                      className={styles['progress-bar']}
                      percent={getFirstVisitBookingCountPercentage(selectedMonthSummaryData)}
                    />
                    <Divider type="vertical" />
                    <div className={styles['compare-number-black-word']}>
                      较上月
                      {getCompareNumberRender(
                        getFirstVisitBookingCountPercentage(selectedMonthSummaryData),
                        getFirstVisitBookingCountPercentage(selectedLastMonthSummaryData),
                      )}
                    </div>
                  </div>
                  <div className="display-flex gap-12">
                    <div className={styles['sub-number-box']}>
                      <span className={styles['sub-number']}>
                        {selectedMonthSummaryData?.repeatVisitBookingCount ?? 0}
                      </span>
                      <span>复购</span>
                    </div>
                    <Divider type="vertical" />
                    <div className={styles['compare-number-black-word']}>
                      较上月
                      {getCompareNumberRender(
                        selectedMonthSummaryData?.repeatVisitBookingCount ?? 0,
                        selectedLastMonthSummaryData?.repeatVisitBookingCount ?? 0,
                      )}
                    </div>
                  </div>
                  <div className="display-flex gap-12">
                    <Progress
                      className={styles['progress-bar']}
                      percent={getRepeatVisitBookingCountPercentage(selectedMonthSummaryData)}
                    />
                    <Divider type="vertical" />
                    <div className={styles['compare-number-black-word']}>
                      较上月
                      {getCompareNumberRender(
                        getRepeatVisitBookingCountPercentage(selectedMonthSummaryData),
                        getRepeatVisitBookingCountPercentage(selectedLastMonthSummaryData),
                      )}
                    </div>
                  </div>
                </div>
              </Space>
            </Card>

            <Card className={styles['billing-summary-card']}>
              <div className={styles['number']}>
                {selectedMonthSummaryData?.canceledBookingCount ?? 0}
              </div>
              <div className={styles['description']}>取消</div>
              <div className={styles['compare-number']}>
                较上月
                {getCompareNumberRender(
                  selectedMonthSummaryData?.canceledBookingCount ?? 0,
                  selectedLastMonthSummaryData?.canceledBookingCount ?? 0,
                )}
              </div>
            </Card>
          </Space>
        </div>
      </Card>

      <BillingInvoicesProcessesModal
        open={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />
    </div>
  )
}

export default BillingSummary
