import { Button, notification, Space } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'

import translate from '../../../i18n'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PermissionsEnum } from '../../../share-types'
import {
  IPrelaunchLessonDescription,
  IPrelaunchLessonDescriptionTranslation,
  PrelaunchConfirmationStatusEnum,
} from '../../../pages/prelaunch-organization-forms-container/share-types'
import { getDateTime } from '../../../helpers/utils'
import LinkButton from '../../link-button/link-button'
import { Typography } from 'antd'
import { useState } from 'react'
import { confirmPrelaunchLessonDescriptions } from '../../../api/prelaunchOrganizationFormsApi'
import PrelaunchConfirmModal from '../prelaunch-confirm-modal/prelaunch-confirm-modal'
const { Title } = Typography

interface Iprops {
  prelaunchOrganizationId?: string
  prelaunchLessonDescriptions: IPrelaunchLessonDescription[]
}
const PrelaunchLessonDescriptionsTable = ({
  prelaunchLessonDescriptions,
  prelaunchOrganizationId,
}: Iprops) => {
  const [open, setOpen] = useState<boolean>(false)
  const [prelaunchLessonDescription, setPrelaunchLessonDescription] =
    useState<IPrelaunchLessonDescription | null>(null)

  const handleConfirmButtonClick = (prelaunchLessonDescription: IPrelaunchLessonDescription) => {
    setOpen(true)
    setPrelaunchLessonDescription(prelaunchLessonDescription)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setPrelaunchLessonDescription(null)
  }

  const formatFormName = () => {
    if (prelaunchLessonDescription) {
      return `【${translate('navBar.prelaunchLessonDescriptions')} ${prelaunchLessonDescription.lessonDescriptionTranslations.map(t => t.name).join(' / ')}】`
    } else {
      return `【${translate('navBar.prelaunchLessonDescriptions')}】`
    }
  }

  const handleConfirmPrelaunchLessonDescription = () => {
    if (prelaunchLessonDescription) {
      confirmPrelaunchLessonDescriptions({
        prelaunchLessonDescriptionIds: [prelaunchLessonDescription.id],
      })
        .then(response => {
          setOpen(false)

          if (response) {
            notification.success({
              message: 'Confirm Success',
            })
            setTimeout(() => window.location.reload(), 1000)
          }
        })

        .catch(err => {
          setOpen(false)

          notification.error({
            message: 'Confirm Failed',
            description: err?.message || translate('networkError'),
          })
        })
    }
  }
  const columns: ColumnsType<IPrelaunchLessonDescription> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: translate('name'),
      dataIndex: 'lessonDescriptionTranslations',
      key: 'lessonDescriptionTranslationName',
      render: (lessonDescriptionTranslations: IPrelaunchLessonDescriptionTranslation[]) => {
        return lessonDescriptionTranslations.map(translation => translation.name).join(', ')
      },
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: translate('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: IPrelaunchLessonDescription) => {
        return (
          <Space>
            <LinkButton
              to={`/prelaunch-organization-forms/${prelaunchOrganizationId}/lesson-descriptions/${record.id}`}
            >
              {translate('view')}
            </LinkButton>

            <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
              <LinkButton
                disabled={record.status == PrelaunchConfirmationStatusEnum.CONFIRMED}
                to={`/prelaunch-organization-forms/${prelaunchOrganizationId}/lesson-descriptions/${record.id}/edit`}
              >
                {translate('edit')}
              </LinkButton>
            </AuthPermission>

            <AuthPermission permission={PermissionsEnum.PRELAUNCH_ORGANIZATION_FORMS_WRITE}>
              {record.status == PrelaunchConfirmationStatusEnum.CONFIRMED ? (
                <Button type="link" disabled={true}>
                  {translate('prelaunchOrganizationForms.confirmed')}
                </Button>
              ) : (
                <Button type="link" onClick={() => handleConfirmButtonClick(record)}>
                  {translate('prelaunchOrganizationForms.confirm')}
                </Button>
              )}
            </AuthPermission>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <Title level={5}>{translate('navBar.prelaunchLessonDescriptions')}</Title>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={prelaunchLessonDescriptions}
        pagination={false}
      />

      <PrelaunchConfirmModal
        open={open}
        formName={formatFormName()}
        handleCancel={handleCloseModal}
        handleConfirm={handleConfirmPrelaunchLessonDescription}
      />
    </>
  )
}

export default PrelaunchLessonDescriptionsTable
