import { Button, Form, Input, notification, Upload, Card, Space } from 'antd'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { userSegmentsBulkUpload } from '../../../api/segmentApi'
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { IUserSegmentsBulkUploadParams } from '../share-type'
import * as _ from 'lodash'
import styles from './user-segments-form.module.less'

const { TextArea } = Input

const UserSegmentsForm = () => {
  const [form] = Form.useForm()
  const params = useParams()
  const navigation = useNavigate()
  const [segmentId] = useState<string | undefined>(params?.id)

  const onFinish = (values: IUserSegmentsBulkUploadParams) => {
    if (segmentId) {
      const data = new FormData()
      if (values.file && values.file.length) {
        data.append('file', values.file[0].originFileObj)
      }
      if (values.userIds) {
        const phoneNumbers = _.split(values.userIds, ',')
        data.append('userIds', JSON.stringify(phoneNumbers))
      }

      userSegmentsBulkUpload(Number(segmentId), data)
        .then(() => {
          notification.success({
            message: '上传用户成功',
          })
          navigation(-1)
        })
        .catch(err => {
          notification.error({
            message: '上传用户失败',
            description: err?.message || '网络请求失败，请稍后重试',
          })
        })
    }
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }
  const beforeUploadUserFile = () => {
    return false
  }

  const goBack = () => {
    navigation(-1)
  }

  return (
    <Card title="新增分组用户" bordered={false}>
      <Form
        layout="horizontal"
        name="corporate_member"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ isActive: false }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="file"
          label="上传指定用户列表"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          tooltip={{
            title: '上传文件或者手动输入用户id，如果上传文件，则手动输入的用户id 无效',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Upload accept=".csv" beforeUpload={beforeUploadUserFile} maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="userIds" label="指定用户id">
          <TextArea placeholder="多个用户id，使用,隔开" showCount />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Space className={styles['button-segment']}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={goBack}>返回</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default UserSegmentsForm
