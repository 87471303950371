import qs from 'qs'

import config from '../config/index'
import {
  IPaginatedStudio,
  IPaginatedStudioFilterParams,
  IStudio,
} from '../pages/studios-container/share-type'
import { authRequest } from '../request'

const { mainApi } = config

export const getStudiosSuggestion = (keyword: string) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string; cityId: number }[]>(
    `${mainApi}/studios/suggestions?keyword=${keyword}`,
  )

export const getStudiosSuggestionsByIds = (ids: number[]) =>
  authRequest.get<MISSING_TYPE, { id: number; name: string }[]>(
    `${mainApi}/studios/suggestions-by-ids`,
    {
      params: {
        ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

export const getStudios = (params: IPaginatedStudioFilterParams) => {
  return authRequest.get<MISSING_TYPE, IPaginatedStudio>(`${mainApi}/studios`, {
    params,
  })
}

export const getStudioDetail = (id: number) => {
  return authRequest.get<MISSING_TYPE, IStudio>(`${mainApi}/studios/${id}`)
}
