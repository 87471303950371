import { SpinnerAction } from './action'
import { SHOW_SPINNER, HIDE_SPINNER, REDUCE_REQUEST_COUNT, ADD_REQUEST_COUNT } from './constants'

const initialState = {
  requestCount: 0,
  show: false,
}

function spinnerReducer(state = initialState, action: SpinnerAction) {
  switch (action.type) {
    case SHOW_SPINNER:
    case HIDE_SPINNER:
      return Object.assign({}, state, {
        ...action.payload,
      })

    case REDUCE_REQUEST_COUNT:
      return Object.assign({}, state, {
        requestCount: --state.requestCount,
      })

    case ADD_REQUEST_COUNT:
      return Object.assign({}, state, {
        requestCount: ++state.requestCount,
      })

    default:
      return state
  }
}

export default spinnerReducer
