import { MenuOutlined } from '@ant-design/icons'
import { CSS } from '@dnd-kit/utilities'
import React, { ReactNode } from 'react'
import { Tag, TagProps } from 'antd'
import { useSortable } from '@dnd-kit/sortable'

interface SortableItemProps extends TagProps {
  id: string
  key: string | number
  label: ReactNode
}

function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.id,
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1, scaleX: 1 }),
    transition,
    marginBottom: '5px',
    userSelect: 'none',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  }

  return (
    <Tag {...props} ref={setNodeRef} style={style} {...attributes}>
      <MenuOutlined {...listeners} style={{ marginRight: '5px', cursor: 'move' }} />
      <span style={{ marginRight: 'auto' }}>{props.label}</span>
    </Tag>
  )
}

export default SortableItem
