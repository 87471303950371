import {
  CardConfigTypeEnum,
  CardStackTypeEnum,
  TargetUserGroupEnum,
  CardStackPageTypeEnum,
} from './share-type'

export const targetUserGroupsOptions = [
  {
    label: '所有用户',
    text: '所有用户',
    value: TargetUserGroupEnum.ALL_USERS,
  },
  {
    label: '老用户',
    text: '老用户',
    value: TargetUserGroupEnum.EXISTING_USERS,
  },
  {
    label: '新用户',
    text: '新用户',
    value: TargetUserGroupEnum.NEW_USERS,
  },
  {
    label: 'IKEA用户',
    text: 'IKEA用户',
    value: TargetUserGroupEnum.TRIAL_USERS,
  },
]

export const pageTypeOptions = [
  {
    label: '首页',
    text: '首页',
    value: CardStackPageTypeEnum.HOME,
  },
  {
    label: '充值页面',
    text: '充值页面',
    value: CardStackPageTypeEnum.PAYMENT,
  },
  {
    label: '场馆列表',
    text: '场馆列表',
    value: CardStackPageTypeEnum.STUDIO_LIST,
  },
  {
    label: '课程列表',
    text: '课程列表',
    value: CardStackPageTypeEnum.LESSON_LIST,
  },
]

export const cardStackTypeOptions = [
  {
    label: CardStackTypeEnum.CAROUSEL,
    value: CardStackTypeEnum.CAROUSEL,
  },
  {
    label: CardStackTypeEnum.GRID,
    value: CardStackTypeEnum.GRID,
  },
]

export const cardConfigTypeOptions = [
  {
    label: CardConfigTypeEnum.ADVERTISEMENT,
    value: CardConfigTypeEnum.ADVERTISEMENT,
  },
  {
    label: CardConfigTypeEnum.ICON,
    value: CardConfigTypeEnum.ICON,
  },
  {
    label: CardConfigTypeEnum.LESSON_DESCRUPTION,
    value: CardConfigTypeEnum.LESSON_DESCRUPTION,
  },
  {
    label: CardConfigTypeEnum.STUDIO,
    value: CardConfigTypeEnum.STUDIO,
  },
  {
    label: CardConfigTypeEnum.STUDIO_LIST,
    value: CardConfigTypeEnum.STUDIO_LIST,
  },
  {
    label: CardConfigTypeEnum.UPCOMING_LESSONS,
    value: CardConfigTypeEnum.UPCOMING_LESSONS,
  },
  {
    label: CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS,
    value: CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS,
  },
  {
    label: CardConfigTypeEnum.TITLE_SUBTITLE_ICON,
    value: CardConfigTypeEnum.TITLE_SUBTITLE_ICON,
  },
]

export const cardStackTypeOptionsByCardStackPageType: {
  [key in CardStackPageTypeEnum]: Array<any>
} = {
  [CardStackPageTypeEnum.HOME]: [
    {
      label: CardStackTypeEnum.CAROUSEL,
      value: CardStackTypeEnum.CAROUSEL,
    },
    {
      label: CardStackTypeEnum.GRID,
      value: CardStackTypeEnum.GRID,
    },
  ],
  [CardStackPageTypeEnum.PAYMENT]: [
    {
      label: CardStackTypeEnum.CAROUSEL,
      value: CardStackTypeEnum.CAROUSEL,
    },
  ],
  [CardStackPageTypeEnum.STUDIO_LIST]: [
    {
      label: CardStackTypeEnum.LISTABLE_BANNER,
      value: CardStackTypeEnum.LISTABLE_BANNER,
    },
  ],
  [CardStackPageTypeEnum.LESSON_LIST]: [
    {
      label: CardStackTypeEnum.LISTABLE_BANNER,
      value: CardStackTypeEnum.LISTABLE_BANNER,
    },
  ],
}

export const cardConfigTypeOptionsByCardStackPageType: {
  [key in CardStackPageTypeEnum]: Array<any>
} = {
  [CardStackPageTypeEnum.HOME]: [
    {
      label: CardConfigTypeEnum.ADVERTISEMENT,
      value: CardConfigTypeEnum.ADVERTISEMENT,
    },
    {
      label: CardConfigTypeEnum.ICON,
      value: CardConfigTypeEnum.ICON,
    },
    {
      label: CardConfigTypeEnum.LESSON_DESCRUPTION,
      value: CardConfigTypeEnum.LESSON_DESCRUPTION,
    },
    {
      label: CardConfigTypeEnum.STUDIO,
      value: CardConfigTypeEnum.STUDIO,
    },
    {
      label: CardConfigTypeEnum.STUDIO_LIST,
      value: CardConfigTypeEnum.STUDIO_LIST,
    },
    {
      label: CardConfigTypeEnum.UPCOMING_LESSONS,
      value: CardConfigTypeEnum.UPCOMING_LESSONS,
    },
    {
      label: CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS,
      value: CardConfigTypeEnum.FREQUENTLY_BOOKED_LESSONS,
    },
    {
      label: CardConfigTypeEnum.TITLE_SUBTITLE_ICON,
      value: CardConfigTypeEnum.TITLE_SUBTITLE_ICON,
    },
    {
      label: CardConfigTypeEnum.FIRST_CLASS_DISCOUNTED_STUDIO_CARD,
      value: CardConfigTypeEnum.FIRST_CLASS_DISCOUNTED_STUDIO_CARD,
    },
  ],
  [CardStackPageTypeEnum.PAYMENT]: [
    {
      label: CardConfigTypeEnum.ADVERTISEMENT,
      value: CardConfigTypeEnum.ADVERTISEMENT,
    },
  ],
  [CardStackPageTypeEnum.LESSON_LIST]: [
    {
      label: CardConfigTypeEnum.COUPON_CARD,
      value: CardConfigTypeEnum.COUPON_CARD,
    },
  ],
  [CardStackPageTypeEnum.STUDIO_LIST]: [
    {
      label: CardConfigTypeEnum.COUPON_CARD,
      value: CardConfigTypeEnum.COUPON_CARD,
    },
  ],
}

export const lessonDescriptionsTargetUserGroupsZhCNOptions = [
  {
    label: '任何人/无经验练习者',
    value: '任何人/无经验练习者',
  },
  {
    label: '刚开始运动的人',
    value: '刚开始运动的人',
  },
  {
    label: '有些许运动经验',
    value: '有些许运动经验',
  },
  {
    label: '经常运动的人',
    value: '经常运动的人',
  },
  {
    label: '专业练习者',
    value: '专业练习者',
  },
]

export const lessonDescriptionsTargetUserGroupsEnOptions = [
  {
    label: 'Anyone/Inexperienced practitioners',
    value: 'Anyone/Inexperienced practitioners',
  },
  {
    label: 'People who just started exercising',
    value: 'People who just started exercising',
  },
  {
    label: 'Some exercise experience',
    value: 'Some exercise experience',
  },
  {
    label: 'People who exercise regularly',
    value: 'People who exercise regularly',
  },
  {
    label: 'Professional practitioners',
    value: 'Professional practitioners',
  },
]
