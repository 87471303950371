import { Divider, Modal } from 'antd'
import ExchangeRateAdjustmentForm from '../exchange-rate-adjustment-form/exchange-rate-adjustment-form'
import React from 'react'
import { Typography } from 'antd'

interface ExchangeRateAdjustmentFormModalProps {
  open: boolean
  loading: boolean
  title: string | React.ReactNode
  subTitle: React.ReactNode
  description: string | React.ReactNode
  onCancel: () => void
  onSubmit: (exchangeRate: any) => void
}

const { Title } = Typography

const ExchangeRateAdjustmentFormModal = (props: ExchangeRateAdjustmentFormModalProps) => {
  const { open, title, subTitle, description, loading, onCancel, onSubmit } = props

  return (
    <Modal width={600} forceRender title={title} open={open} onCancel={onCancel} footer={null}>
      <Title level={5}>{subTitle}</Title>
      <div>{description}</div>
      <Divider />
      <ExchangeRateAdjustmentForm
        loading={loading}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isResetForm={open}
      />
    </Modal>
  )
}

export default ExchangeRateAdjustmentFormModal
