import { Outlet } from 'react-router-dom'
import NavBar from '../../components/nav-bar/nav-bar'
import { Card, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import translate from '../../i18n'

const CorporatesContainer = () => {
  const location = useLocation()

  const getNavBarTitle = () => {
    const pathName = location.pathname
    if (pathName.match(/^\/corporates\/[0-9]+\/edit$/)) {
      return translate('navBar.editCorporate')
    }

    if (pathName.match(/^\/corporates\/new/)) {
      return translate('navBar.newCorporate')
    }

    if (pathName.match(/^\/corporates\/[0-9]+$/)) {
      return translate('navBar.viewCorporate')
    }

    if (pathName.match(/^\/corporates\/[0-9]+\/credits\/histories$/)) {
      return translate('navBar.corporateCreditsHistories')
    }

    if (pathName.match(/^\/corporates\/[0-9]+\/credits-distribution-schedules\/[0-9]+$/)) {
      return translate('navbar.corporateCreditsDistributionSchedule.view')
    }

    if (pathName.match(/^\/corporates\/[0-9]+\/credits-distribution-schedules\/[0-9]+\/edit$/)) {
      return translate('navbar.corporateCreditsDistributionSchedule.edit')
    }

    return translate('navBar.corporatesTitle')
  }

  return (
    <Space className="dashboard-wrapper" direction="vertical">
      <NavBar title={getNavBarTitle()}></NavBar>
      <Card>
        <Outlet />
      </Card>
    </Space>
  )
}

export default CorporatesContainer
