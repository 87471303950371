import React, { useEffect } from 'react'
import { Input, Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { SketchPicker } from 'react-color'

export default function InputColor(props: MISSING_TYPE) {
  const { value, onChange } = props
  const [internalColor, setInternalColor] = React.useState<string>(value)

  useEffect(() => {
    if (value && internalColor !== value) {
      setInternalColor(value)
    }
  }, [value])

  const handleChange = (color: MISSING_TYPE) => {
    setInternalColor(color?.hex)

    if (onChange) {
      onChange(color.hex)
    }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div>
          <SketchPicker
            color={internalColor}
            onChange={handleChange}
            onChangeComplete={handleChange}
          />
        </div>
      ),
    },
  ]

  const inputChange = (color: string) => {
    setInternalColor(color)
    onChange?.(color)
  }

  return (
    <Input
      value={internalColor || ''}
      onChange={e => inputChange(e.target.value)}
      suffix={
        <Dropdown trigger={['click']} menu={{ items }}>
          <Button style={{ background: internalColor }}> </Button>
        </Dropdown>
      }
    />
  )
}
