import { Button, Col, Row, Space, Table, Tooltip } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { PageInfo } from '../../../share-types'
import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from '../../../i18n'
import { getSearchParams, getSearchValues } from '../../../helpers/utils'
import {
  IPaginatedStudioIntegrationConfigFilterParams,
  IStudioIntegrationConfig,
  IStudioIntegrationConfigFilterParams,
  IStudioIntegrationConfigJson,
} from '../share-types'
import { getPaginatedStudioIntegrationConfigs } from '../../../api/studioIntegrationConfigsApi'
import StudioIntegrationConfigFilter from '../../../components/studio-integration-configs/studio-integration-configs-filter/studio-integration-configs-filter'
import LinkButton from '../../../components/link-button/link-button'
import { PlusOutlined } from '@ant-design/icons'
import config from '../../../config'

const { guavapassUrl } = config

const defaultPage = {
  pageSize: 10,
  currentPage: 1,
  count: 0,
}

const StudioIntegrationConfigs = () => {
  const [studioIntegrationConfigs, setStudioIntegrationConfigs] = useState<
    IStudioIntegrationConfig[]
  >([])
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPage)
  const [searchValues, setSearchValues] = useState<IStudioIntegrationConfigFilterParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const newSearchValues = getSearchValues(
      [
        { key: 'type', isBoolean: false },
        { key: 'size', isBoolean: false, default: defaultPage.pageSize },
        { key: 'page', isBoolean: false, default: defaultPage.currentPage - 1 },
      ],
      searchParams,
    )

    const data = {
      ...newSearchValues,
      size: Number(newSearchValues.size),
      page: Number(newSearchValues.page),
    }
    setSearchValues(newSearchValues)
    getPaginatedStudioIntegrationConfigsAction(data)
  }, [])

  const getPaginatedStudioIntegrationConfigsAction = (
    data: IPaginatedStudioIntegrationConfigFilterParams,
  ) => {
    getPaginatedStudioIntegrationConfigs(data).then(res => {
      if (res && res.pageInfo && res.data) {
        setPageInfo({
          ...res.pageInfo,
          currentPage: res.pageInfo.currentPage + 1,
        })
        setStudioIntegrationConfigs(res.data)
      }
    })
  }

  const paginationOnChange = (page: number, pageSize: number) => {
    const data = {
      ...searchValues,
      size: pageSize,
      page: page - 1,
    }

    getPaginatedStudioIntegrationConfigsAction(data)
  }

  const onSearch = (value: IStudioIntegrationConfigFilterParams) => {
    const data = {
      size: pageInfo.pageSize,
      page: 0,
      ...value,
    }
    setSearchValues(value)
    setSearchParams(getSearchParams(value))
    getPaginatedStudioIntegrationConfigsAction(data)
  }

  const columns: ColumnsType<IStudioIntegrationConfig> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: translate('studioIntegrationConfig.integratorType'),
      dataIndex: 'integratorType',
      key: 'integratorType',
    },
    {
      title: translate('studioIntegrationConfig.organizationIds'),
      dataIndex: 'config',
      key: 'config',
      render: (config: IStudioIntegrationConfigJson) =>
        Array.from(config.organization_ids).join(', '),
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      render: (record: IStudioIntegrationConfig) => (
        <Space>
          <LinkButton to={`/studio-integration-config/${record.id}`}>
            {translate('view')}
          </LinkButton>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>{translate('navBar.studioIntegrationConfig')}</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Tooltip title="请移步至GP Admin创建Studio Integration Configs">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              href={`${guavapassUrl}/admin/studio_integration_configs/new`}
            >
              {translate('studioIntegrationConfig.createNew')}
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Row className="filter-bar">
        <StudioIntegrationConfigFilter onSearch={onSearch} formData={searchValues} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={studioIntegrationConfigs}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
      />
    </Space>
  )
}

export default StudioIntegrationConfigs
