import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { getCities } from '../../../api/authApi'
import { setActiveCitiesAction } from '../../../store/city/action'

const PrelaunchOrganizationFormContainer = (props: ConnectedProps<typeof withConnect>) => {
  useEffect(() => {
    getCities().then(res => {
      const { setActiveCitiesAction } = props
      setActiveCitiesAction(res)
    })
  }, [])

  return (
    <div className="public-dashboard-wrapper">
      <Outlet />
    </div>
  )
}

const mapDispatchToProps = {
  setActiveCitiesAction,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(PrelaunchOrganizationFormContainer)
