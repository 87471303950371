import { Moment } from 'moment'
import moment from 'moment'

import config from '../config/index'
import {
  CountBasedRewardTierType,
  IReferralCampaign,
  ReceiverRewardSchemeType,
  ReferralCampaignRequest,
  ReferralCampaignTranslation,
  SenderRewardScheme,
  SenderRewardSchemeType,
} from '../pages/referral-campaign-container/share-type'
import { UploadFileDirectoryEnum } from '../share-types'
import {
  DEFAULT_REFERRAL_GENERATING_POSTER_BUTTON_COLOR,
  DEFAULT_REFERRAL_PAGE_BACKGROUND_COLOR,
  DEFAULT_REFERRAL_POSTER_HIGHLIGHT_COLOR,
  DEFAULT_REFERRAL_SAVE_POSTER_BUTTON_COLOR,
  DEFAULT_REFERRAL_SHARING_BUTTON_COLOR,
} from './constant'
import { getImageUrl } from './utils'

const assetUrl = `${config.assetUrl}/${UploadFileDirectoryEnum.REFERRAL_BANNERS}`

export type IReferralCampaignFormValues = {
  id?: number
  name: string
  countDownBeforeMinutes?: number
  isActive: boolean
  isDefault: boolean
  pageBackgroundColor: string
  savePosterButtonColor: string
  sharingButtonColor: string
  generatingPosterButtonColor: string
  rangeTime: Moment[]
  senderRewardSchemeType?: SenderRewardSchemeType
  senderRewardSchemeReceiverCount?: number
  senderRewardSchemeCouponTemplateId?: number
  senderRewardSchemeCredits?: number
  senderRewardSchemeRewardTiers?: CountBasedRewardTierType[]
  receiverRewardSchemeType: ReceiverRewardSchemeType
  receiverRewardSchemeCouponTemplateId: number
  translations: any[]
}

const imageUrlToUploadFileInput = (url?: string) =>
  url
    ? [
        {
          status: 'done',
          url: url,
          thumbUrl: url,
        },
      ]
    : []

const translationFormValuesToRequest = (translations?: any[]) => {
  const defaultTranslation: ReferralCampaignTranslation = {
    locale: '',
    title: '',
    rewardTitle: '',
    bannerUrl: '',
    receiverPageTitle: '',
    receiverPageBannerUrl: '',
    shareImageUrl: '',
    posterImageUrl: '',
    receiverPageIntroImageUrl: '',
    receiverPagePriceImageUrl: '',
    referralPosterModalDescription: '',
    sharingDescription: '',
    referralEntranceImageUrl: '',
  }

  if (translations && translations.length) {
    return translations.map(t => {
      const { highlightColor, referralPosterModalDescription, ...rest } = t
      return {
        ...defaultTranslation,
        ...rest,
        referralPosterModalDescription: formatReferralPosterModalDescription(
          referralPosterModalDescription,
          highlightColor,
        ),
        bannerUrl: getImageUrl('bannerUrl', assetUrl, t.bannerUrl),
        receiverPageBannerUrl: getImageUrl(
          'receiverPageBannerUrl',
          assetUrl,
          t.receiverPageBannerUrl,
        ),
        shareImageUrl: getImageUrl('shareImageUrl', assetUrl, t.shareImageUrl),
        posterImageUrl: getImageUrl('posterImageUrl', assetUrl, t.posterImageUrl),
        referralEntranceImageUrl: getImageUrl(
          'referralEntranceImageUrl',
          assetUrl,
          t.referralEntranceImageUrl,
        ),
        receiverPageIntroImageUrl: getImageUrl(
          'receiverPageIntroImageUrl',
          assetUrl,
          t.receiverPageIntroImageUrl,
        ),
        receiverPagePriceImageUrl: getImageUrl(
          'receiverPagePriceImageUrl',
          assetUrl,
          t.receiverPagePriceImageUrl,
        ),
      }
    })
  } else {
    return [
      { ...defaultTranslation, locale: 'zh-CN' },
      { ...defaultTranslation, locale: 'en' },
    ]
  }
}

const senderRewardSchemeFormValuesToRequest = (values: {
  senderRewardSchemeType: SenderRewardSchemeType | undefined
  senderRewardSchemeReceiverCount: number | undefined
  senderRewardSchemeCouponTemplateId: number | undefined
  senderRewardSchemeCredits: number | undefined
  senderRewardSchemeRewardTiers?: CountBasedRewardTierType[]
}): SenderRewardScheme | undefined => {
  const {
    senderRewardSchemeType,
    senderRewardSchemeReceiverCount,
    senderRewardSchemeCouponTemplateId,
    senderRewardSchemeCredits,
    senderRewardSchemeRewardTiers,
  } = values

  if (senderRewardSchemeType == SenderRewardSchemeType.RECEIVER_COUNT_BASED) {
    return {
      type: senderRewardSchemeType,
      couponTemplateId: senderRewardSchemeCouponTemplateId,
      receiverCount: senderRewardSchemeReceiverCount,
    }
  } else if (senderRewardSchemeType == SenderRewardSchemeType.REFERRAL_CREDITS) {
    return {
      type: senderRewardSchemeType,
      credits: senderRewardSchemeCredits,
    }
  } else if (senderRewardSchemeType === SenderRewardSchemeType.RECEIVER_COUNT_BASED_TIERED) {
    return {
      type: senderRewardSchemeType,
      rewardTiers: senderRewardSchemeRewardTiers,
    }
  } else {
    return undefined
  }
}

export const referralCampaignFormValuesToRequest = (
  values: IReferralCampaignFormValues,
): ReferralCampaignRequest => {
  const {
    rangeTime,
    senderRewardSchemeType,
    senderRewardSchemeReceiverCount,
    senderRewardSchemeCouponTemplateId,
    senderRewardSchemeCredits,
    senderRewardSchemeRewardTiers,
    receiverRewardSchemeType,
    receiverRewardSchemeCouponTemplateId,
    translations,
    countDownBeforeMinutes,
    pageBackgroundColor,
    savePosterButtonColor,
    sharingButtonColor,
    generatingPosterButtonColor,
    ...other
  } = values

  const senderRewardScheme = senderRewardSchemeFormValuesToRequest({
    senderRewardSchemeType,
    senderRewardSchemeReceiverCount,
    senderRewardSchemeCouponTemplateId,
    senderRewardSchemeCredits,
    senderRewardSchemeRewardTiers,
  })

  const receiverRewardScheme = {
    type: receiverRewardSchemeType,
    couponTemplateId: receiverRewardSchemeCouponTemplateId,
  }

  const startDatetime = rangeTime[0].utc().format()
  const endDatetime = rangeTime[1].utc().format()

  return {
    senderRewardScheme,
    receiverRewardScheme,
    startDatetime,
    endDatetime,
    translations: translationFormValuesToRequest(translations),
    pageBackgroundColor: pageBackgroundColor ?? DEFAULT_REFERRAL_PAGE_BACKGROUND_COLOR,
    savePosterButtonColor: savePosterButtonColor ?? DEFAULT_REFERRAL_SAVE_POSTER_BUTTON_COLOR,
    sharingButtonColor: sharingButtonColor ?? DEFAULT_REFERRAL_SHARING_BUTTON_COLOR,
    generatingPosterButtonColor:
      generatingPosterButtonColor ?? DEFAULT_REFERRAL_GENERATING_POSTER_BUTTON_COLOR,
    countDownBeforeMinutes: countDownBeforeMinutes ?? 0,
    ...other,
  }
}

export const referralCampaignToFormValues = (
  values: IReferralCampaign,
): IReferralCampaignFormValues => {
  const {
    senderRewardScheme,
    receiverRewardScheme,
    startDatetime,
    endDatetime,
    translations,
    ...other
  } = values

  return {
    ...other,
    senderRewardSchemeType: senderRewardScheme?.type ?? undefined,
    senderRewardSchemeReceiverCount: senderRewardScheme?.receiverCount ?? undefined,
    senderRewardSchemeCouponTemplateId: senderRewardScheme?.couponTemplateId ?? undefined,
    senderRewardSchemeCredits: senderRewardScheme?.credits ?? undefined,
    senderRewardSchemeRewardTiers: senderRewardScheme?.rewardTiers ?? [],
    receiverRewardSchemeType: receiverRewardScheme.type,
    receiverRewardSchemeCouponTemplateId: receiverRewardScheme.couponTemplateId,
    rangeTime: [moment(startDatetime), moment(endDatetime)],
    translations: translations.map(t => ({
      ...t,
      bannerUrl: imageUrlToUploadFileInput(t.bannerUrl),
      receiverPageBannerUrl: imageUrlToUploadFileInput(t.receiverPageBannerUrl),
      shareImageUrl: imageUrlToUploadFileInput(t.shareImageUrl),
      posterImageUrl: imageUrlToUploadFileInput(t.posterImageUrl),
      referralEntranceImageUrl: imageUrlToUploadFileInput(t.referralEntranceImageUrl),
      receiverPageIntroImageUrl: imageUrlToUploadFileInput(t.receiverPageIntroImageUrl),
      receiverPagePriceImageUrl: imageUrlToUploadFileInput(t.receiverPagePriceImageUrl),
      highlightColor: extractHighlightColor(t.referralPosterModalDescription),
    })),
  }
}

const formatReferralPosterModalDescription = (
  referralPosterModalDescription: string,
  highlightColor: string,
): string => {
  if (referralPosterModalDescription.includes('style="color:')) {
    const regex = /#[0-9a-fA-F]{6}/g
    return referralPosterModalDescription.replace(regex, highlightColor)
  } else {
    const regex = /<highlight>(.*)<\/highlight>/g
    return referralPosterModalDescription.replace(
      regex,
      `<span style="color: ${highlightColor}">$1</span>`,
    )
  }
}

const extractHighlightColor = (referralPosterModalDescription: string): string => {
  const regex = /#[0-9a-fA-F]{6}/g
  const match = referralPosterModalDescription.match(regex)
  return match ? match[0] : DEFAULT_REFERRAL_POSTER_HIGHLIGHT_COLOR
}
