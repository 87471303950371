import { Form, Input, Button, Image, notification } from 'antd'
import styles from './login.module.less'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { FormattedMessage } from '../../components/intl'
import i18n from '../../i18n'
import logoMobile from '../../assets/images/logo-name.svg'
import { getAdminUserProfile, ILoginParams, loginApi } from '../../api/authApi'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { setAuthAction } from '../../store/userAuth/action'
import { useLocation, useNavigate } from 'react-router-dom'
import { setUserInfoAction } from '../../store/userInfo/action'
import { IAdminUser, IStudioAreaUserDto } from '../../share-types'
import translate from '../../i18n'
import { useEffect, useState } from 'react'
import { ReduxStore } from '../../store'
import { setStudioAdminUserInfoAction } from '../../store/studio-admin-user-info/action'
import { getStudioAdminUserInfo } from '../../studios-area-api/userInfo'

const Login = (props: ConnectedProps<typeof withConnect>) => {
  const { auth } = props
  const navigate = useNavigate()
  const [navigateTo, setNavigateTo] = useState<string>('')
  const [isStudiosArea, setIsStudiosArea] = useState<boolean>(false)

  const location = useLocation()

  useEffect(() => {
    const { accessToken } = auth
    let backUrl = '/'
    const isSudiosAreaPath = location.pathname.includes('/studios-area')
    if (isSudiosAreaPath) {
      backUrl = '/studios-area'
    }

    setIsStudiosArea(isSudiosAreaPath)

    if (accessToken) {
      navigate(backUrl, { replace: true })
    } else {
      setNavigateTo(backUrl)
    }
  }, [location, auth])

  const [form] = Form.useForm()

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  }

  const onFinish = (values: ILoginParams) => {
    let data: ILoginParams & { clientType?: string } = values
    if (isStudiosArea) {
      data = {
        ...values,
        clientType: 'STUDIO_ADMIN',
      }
    }

    loginApi(data)
      .then(res => {
        const { setAuthAction, setUserInfoAction, setStudioAdminUserInfoAction } = props
        setAuthAction({
          accessToken: res,
        })
        if (isStudiosArea) {
          getStudioAdminUserInfo()
            .then((res: IStudioAreaUserDto) => {
              setStudioAdminUserInfoAction(res)
            })
            .finally(() => {
              navigate(navigateTo, { replace: true })
            })
        } else {
          getAdminUserProfile()
            .then((res: IAdminUser) => {
              setUserInfoAction(res)
            })
            .finally(() => {
              navigate(navigateTo, { replace: true })
            })
        }
      })
      .catch(err => {
        notification.error({
          message: translate('loginError'),
          description: err.message || translate('networkError'),
        })
      })
  }

  return (
    <div className={styles['login-layout']}>
      <div className={styles['logo-area']}></div>
      <div className={styles['logo-form-wrapper']}>
        <div className={styles['logo-mobile']}>
          <Image width={100} preview={false} src={logoMobile} />
        </div>
        <div className={styles['form-area']}>
          <div className={styles['form-content']}>
            {isStudiosArea ? (
              <div className={styles['studios-area-login-title']}>Welcome to the Studios Area!</div>
            ) : (
              <div className={styles['login-title']}>Log in</div>
            )}

            <Form
              {...formItemLayout}
              form={form}
              name="normal_login"
              className={styles['login-form']}
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: i18n('loginUsernameTips') }]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={i18n('loginUsernamePlaceholder')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: i18n('loginPasswordTips') }]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={i18n('loginPasswordPlaceholder')}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className={styles['login-form-button']}>
                  <FormattedMessage id="loginButton" />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ReduxStore) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  setAuthAction,
  setUserInfoAction,
  setStudioAdminUserInfoAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Login)
