import config from '../config/index'
import { authRequest } from '../request'
import { SupportedPrelaunchUploadImageTypeEnums } from '../share-types'

const { mainApi } = config

export const prelaunchUploadImage = (
  organizationUniqueId: string,
  filename: string,
  type: SupportedPrelaunchUploadImageTypeEnums,
) =>
  authRequest.post<MISSING_TYPE, string>(
    `${mainApi}/images/prelaunch-pre-signed-url?uniqueId=${organizationUniqueId}&filename=${filename}&type=${type}`,
  )
