import { IPaginatedParams, PageInfo } from '../../share-types'

export interface IFeatureFlag {
  id: number
  name: string
  isActive: boolean
  createdAt: string
  updatedAt: string
  rules: IFeatureFlagrule[]
}

export interface IFeatureFlagrule {
  id: number
  createdAt: string
  updatedAt: string
  rule: FeatureFlagRule
}

export interface PaginatedFeatureFlagsResponse {
  pageInfo: PageInfo
  data: IFeatureFlag[]
}

export interface IFeatureFlagsFilterParams {
  name?: string
  isActive?: boolean
}

export type IPaginatedFeatureFlagsParams = IPaginatedParams & IFeatureFlagsFilterParams

export interface FeatureFlagRequest {
  name: string
  isActive: boolean
  targetAudience?: FeatureFlagTypeOptiosEnums
  rules: FeatureFlagRule[]
  file?: MISSING_TYPE
}

export interface FeatureFlagRule {
  file?: MISSING_TYPE
  type: FeatureFlagTypeRulesEnums
  userIds?: MISSING_TYPE
  segmentIds?: number[]
  corporateIds?: number[]
}

export interface FeatureFlagRuleTypeOption {
  label: string
  text: string
  value: FeatureFlagTypeRulesEnums
}

export enum FeatureFlagTypeOptiosEnums {
  PUBLIC_USER,
  RESTRICTED_USER,
}

export enum FeatureFlagTypeRulesEnums {
  PUBLIC_USER = 'PUBLIC_USER',
  USER_SPECIFIC = 'USER_SPECIFIC',
  INTERNAL_USER_ONLY = 'INTERNAL_USER_ONLY',
  SEGMENT_SPECIFIC = 'SEGMENT_SPECIFIC',
  CORPORATE_USER_ONLY = 'CORPORATE_USER_ONLY',
  CORPORATE_USER_SPECIFIC = 'CORPORATE_USER_SPECIFIC',
}

export const featureFlagRuleTypeMenus = [
  {
    label: '全部用户',
    text: '全部用户',
    value: FeatureFlagTypeOptiosEnums.PUBLIC_USER,
  },
  {
    label: '部分用户',
    text: '部分用户',
    value: FeatureFlagTypeOptiosEnums.RESTRICTED_USER,
  },
]

export const featureFlagRuleTypeOptions: FeatureFlagRuleTypeOption[] = [
  {
    label: 'Internal User',
    text: 'Internal User',
    value: FeatureFlagTypeRulesEnums.INTERNAL_USER_ONLY,
  },
  {
    label: '指定用户',
    text: '指定用户',
    value: FeatureFlagTypeRulesEnums.USER_SPECIFIC,
  },
  {
    label: '指定用户分组',
    text: '指定用户分组',
    value: FeatureFlagTypeRulesEnums.SEGMENT_SPECIFIC,
  },
  {
    label: '所有企业用户',
    text: '所有企业用户',
    value: FeatureFlagTypeRulesEnums.CORPORATE_USER_ONLY,
  },
  {
    label: '指定企业用户',
    text: '指定企业用户',
    value: FeatureFlagTypeRulesEnums.CORPORATE_USER_SPECIFIC,
  },
]
