import type { ReactNode } from 'react'
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message'
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl'
import { IntlMessageKeys } from '../../i18n'

type FormattedMessageProps = ReactIntlFormattedMessageProps<Record<string, ReactNode>> & {
  id?: IntlMessageKeys
}

export default function FormattedMessage({ id, ...reset }: FormattedMessageProps) {
  return <ReactIntlFormattedMessage id={id} {...reset} />
}
