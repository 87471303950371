import { Button, Col, Dropdown, Row, Space, Table, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import AuthPermission from '../../../high-order-components/auth-permission'
import { PageInfo, PermissionsEnum } from '../../../share-types'
import CorporateMembersFilter from '../corporate-members-filter/corporate-members-filter'
import {
  PlusOutlined,
  SyncOutlined,
  DownOutlined,
  StopOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import {
  ICorporateMember,
  ICorporatesFilterParams,
} from '../../../pages/corporates-container/share-type'
import { ColumnsType } from 'antd/lib/table'
import YesOrNoTag from '../../common/YesOrNoTag'
import { getDateTime } from '../../../helpers/utils'
import translate from '../../../i18n'
import config from '../../../config'
import styles from './corporate-members-table.module.less'

interface CorporateMembersTableProps {
  corporateId: number,
  corporateMembers: ICorporateMember[]
  pageInfo: PageInfo
  onBatchReclaimCredits: () => void
  changeStatus: (corporateMember: ICorporateMember) => void
  distributeCredits: (corporateMember: ICorporateMember) => void
  reclaimCredits: (corporateMember: ICorporateMember) => void
  onViewCorprateMemberCreditsHistories: (corporateMember: ICorporateMember) => void
  paginationOnChange: (page: number, pageSize: number) => void
  onSearch: (values: ICorporatesFilterParams) => void
  searchValues?: ICorporatesFilterParams
  onAddNewCorporateMember: () => void
  onBatchDeactivateMembers: () => void
}

const { guavapassUrl } = config
const CorporateMembersTable = (props: CorporateMembersTableProps) => {
  const {
    corporateId,
    corporateMembers,
    pageInfo,
    onBatchReclaimCredits,
    changeStatus,
    distributeCredits,
    reclaimCredits,
    onViewCorprateMemberCreditsHistories,
    paginationOnChange,
    onSearch,
    searchValues,
    onAddNewCorporateMember,
    onBatchDeactivateMembers,
  } = props

  const activeItemActionItems = [
    {
      key: 'reclaim',
      label: (
        <Button type="link" className={styles['reclaim-button']} icon={<MinusCircleOutlined />}>
          <FormattedMessage id="corporate.credits.reclaim" />
        </Button>
      ),
    },
    {
      key: 'stop',
      label: (
        <Button type="link" danger icon={<StopOutlined />}>
          <FormattedMessage id="corporate.member.deactivate" />
        </Button>
      ),
    },
    {
      key: 'distribute',
      label: (
        <Button type="link" icon={<PlusCircleOutlined />}>
          <FormattedMessage id="corporate.credits.distribution" />
        </Button>
      ),
    },
  ]

  const inactiveItemActionItems = [
    {
      key: 'activate',
      label: (
        <Button type="primary" ghost icon={<CheckCircleOutlined />}>
          <FormattedMessage id="corporate.member.activate" />
        </Button>
      ),
    },
  ]

  const handleMenuClick = (e: any, record: ICorporateMember) => {
    switch (e.key) {
      case 'stop':
      case 'activate':
        changeStatus(record)
        break
      case 'distribute':
        distributeCredits(record)
        break
      case 'reclaim':
        reclaimCredits(record)
        break
      default:
        break
    }
  }

  const columns: ColumnsType<ICorporateMember> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      fixed: 'left',
      width: 60,
    },
    {
      title: '电话号码',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 100,
    },
    {
      title: translate('userId'),
      dataIndex: 'userId',
      key: 'userId',
      width: 80,
      render: (userId: string) => {
        if (!userId) {
          return ''
        }
        return (
          <Button type="link" target="_black" href={`${guavapassUrl}/admin/users/${userId}`}>
            {userId}
          </Button>
        )
      },
    },
    {
      title: 'openId',
      dataIndex: 'uid',
      key: 'uid',
      width: 100,
      render: (uid: string) => {
        if (!uid) {
          return ''
        }
        return <Typography.Text copyable>{uid}</Typography.Text>
      },
    },
    {
      title: '昵称',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 80,
    },
    {
      title: '企业点数',
      dataIndex: 'credits',
      key: 'credits',
      width: 80,
    },
    {
      title: '个人点数',
      dataIndex: 'userCredits',
      key: 'userCredits',
      width: 80,
    },
    {
      title: '用户冻结点数',
      dataIndex: 'frozenCredits',
      key: 'frozenCredits',
      width: 80,
    },
    {
      title: '活跃',
      dataIndex: 'isActive',
      key: 'isActive',
      width: 80,
      render: (isActive: boolean) => <YesOrNoTag value={isActive} />,
    },
    {
      title: translate('corporateMember.creditsExpiredAt'),
      dataIndex: 'creditsExpiredAt',
      key: 'creditsExpiredAt',
      width: 100,
      render: (creditsExpiredAt: string | null) => {
        if (creditsExpiredAt) {
          return <span>{getDateTime(creditsExpiredAt)}</span>
        }
        return ''
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (createdAt: string | null) => {
        if (createdAt) {
          return <span>{getDateTime(createdAt)}</span>
        }
        return ''
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      render: (updatedAt: string | null) => {
        if (updatedAt) {
          return <span>{getDateTime(updatedAt)}</span>
        }
        return ''
      },
    },
    {
      title: translate('tableActionColumn'),
      key: 'action',
      fixed: 'right',
      width: 180,
      render: (text: string, record: ICorporateMember) => (
        <Space>
          <AuthPermission permission={PermissionsEnum.CORPORATE_MEMBERS_WRITE}>
            <Dropdown
              menu={{
                items: record.isActive ? activeItemActionItems : inactiveItemActionItems,
                onClick: e => handleMenuClick(e, record),
              }}
            >
              <a className={styles['action-item']}>
                <FormattedMessage id="tableActionColumn" /> <DownOutlined />
              </a>
            </Dropdown>
          </AuthPermission>

          <Button type="link" onClick={() => onViewCorprateMemberCreditsHistories(record)}>
            <FormattedMessage id="corporatemember.credits.viewCreditsHistories" />
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <Space className="table-space" direction="vertical" wrap size={16}>
      <Row align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="left-col">
          <div>企业用户</div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="right-col">
          <Space>
            <AuthPermission permission={PermissionsEnum.CORPORATE_MEMBERS_WRITE}>
              <Button danger icon={<SyncOutlined />} onClick={onBatchReclaimCredits}>
                <FormattedMessage id="corporate.batchReclaimCredits" />
              </Button>
            </AuthPermission>

            <AuthPermission permission={PermissionsEnum.CORPORATE_MEMBERS_WRITE}>
              <Button danger icon={<StopOutlined />} onClick={onBatchDeactivateMembers}>
                <FormattedMessage id="corporate.batchDeactivateMembers" />
              </Button>
            </AuthPermission>

            <AuthPermission permission={PermissionsEnum.CORPORATE_MEMBERS_WRITE}>
              <Button type="primary" icon={<PlusOutlined />} onClick={onAddNewCorporateMember}>
                新增企业用户
              </Button>
            </AuthPermission>
          </Space>
        </Col>
      </Row>
      <Row className="filter-bar">
        <CorporateMembersFilter onSearch={onSearch} formData={searchValues} corporateId={corporateId} />
      </Row>

      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={corporateMembers}
        pagination={{
          current: pageInfo.currentPage,
          pageSize: pageInfo.pageSize,
          total: pageInfo.count,
          onChange: paginationOnChange,
        }}
        scroll={{ x: 1140 }}
      />
    </Space>
  )
}

export default CorporateMembersTable
