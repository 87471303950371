import publicRoutes from './public-routes'
import adminRoutes from './admin-routes'
import StudiosAreaRoutes from './studios-area-routes'
import { RouteObject, useLocation, useRoutes } from 'react-router-dom'
import AuthRoute from '../high-order-components/auth-router'
import NoMatch from '../pages/not-match/no-match'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

const WebAppRouter = (props: ConnectedProps<typeof withConnect>) => {
  const { auth, userInfo } = props
  const isStudiosArea = useLocation().pathname.indexOf('studios-area') > -1

  const tempAdminRoutes: RouteObject[] = adminRoutes.map(item => {
    return AuthRoute({ ...item, ...auth, permissions: userInfo?.permissions, clientType: 'admin' })
  })

  const tempStudiosAreaRoutes: RouteObject[] = StudiosAreaRoutes.map(item => {
    return AuthRoute({ ...item, ...auth, clientType: 'studios-area' })
  })

  const tempPublicRoutes: RouteObject[] = publicRoutes.map(item => {
    return item
  })

  let list: RouteObject[] = tempPublicRoutes
  if (isStudiosArea) {
    list = list.concat(tempStudiosAreaRoutes)
  } else {
    list = list.concat(tempAdminRoutes)
  }

  list.push({
    path: '*',
    element: <NoMatch />,
  })

  return useRoutes(list)
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    userInfo: state.userInfo,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(WebAppRouter)
