import fileDownload from 'js-file-download'

import { authRequest } from '../request'

export const downloadFile = (url: string, data = {}, fileName: string) => {
  return new Promise((resolve, reject) => {
    authRequest({
      method: 'get',
      url: url,
      responseType: 'blob',
      params: data,
    })
      .then(res => {
        fileDownload(res as any, fileName)
        resolve(true)
      })
      .catch(err => {
        reject(err)
      })
  })
}
