import {
  CouponDiscountTypeEnums,
  ForPeopleTypeEnums,
  CouponUsageRulesEnums,
  CouponTemplateStatus,
} from './share-type'

export const couponTypeList = [
  {
    label: '折扣券',
    text: '折扣券',
    value: CouponDiscountTypeEnums.PERCENTAGE_OFF,
  },
  {
    label: '满减券',
    text: '满减券',
    value: CouponDiscountTypeEnums.CASH_OFF,
  },
  {
    label: '返点券',
    text: '返点券',
    value: CouponDiscountTypeEnums.BUY_ONE_GET_MORE,
  },
  {
    label: '礼品券',
    text: '礼品券',
    value: CouponDiscountTypeEnums.GIFT_CARD,
  },
  {
    label: '可叠加现金券',
    text: '可叠加现金券',
    value: CouponDiscountTypeEnums.STACKABLE_CASH_OFF,
  },
]

export const ForPeopleOptions = [
  {
    label: '全部用户',
    value: ForPeopleTypeEnums.ALL_USER,
  },
  {
    label: '老用户',
    value: ForPeopleTypeEnums.OLD_USER_ONLY,
  },
  {
    label: '新用户',
    value: ForPeopleTypeEnums.NEW_USER_ONLY,
  },
  {
    label: '指定用户',
    value: ForPeopleTypeEnums.SPECIAL_USER_ONLY,
  },
  {
    label: '企业用户',
    value: ForPeopleTypeEnums.CORPORATE_USERS,
  },
]

export const CouponUsageRulesOptions = [
  {
    label: '不设置发放周期',
    value: CouponUsageRulesEnums.SINGLE_USE,
  },
  {
    label: '设置重复发放周期',
    value: CouponUsageRulesEnums.REUSE,
  },
]

export const CouponTemplateStatusOptions = [
  {
    text: '激活',
    label: '激活',
    value: CouponTemplateStatus.ACTIVE,
  },
  {
    label: '已失效',
    text: '已失效',
    value: CouponTemplateStatus.INACTIVE,
  },
  {
    label: '仅限内部',
    text: '仅限内部',
    value: CouponTemplateStatus.INTERNAL_ONLY,
  },
]

export const segmentSizeWithoutPage = 9999

export enum CouponTemplateDistributionFrequencyModeEnums {
  ONE_DAY = 'ONE_DAY',
  ONE_MINUTE = 'ONE_MINUTE',
}

export const CouponTemplateDistributionFrequencyModeOptions = [
  {
    label: '天',
    value: CouponTemplateDistributionFrequencyModeEnums.ONE_DAY,
  },
  {
    label: '分钟',
    value: CouponTemplateDistributionFrequencyModeEnums.ONE_MINUTE,
  },
]
