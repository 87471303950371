import { Button, DatePicker, Form, Modal, notification, Space } from 'antd'
import DebounceSelect from '../../debounce-select/debounce-select'
import {
  calculateBillingSummaries,
  getOrganizationsSuggestion,
} from '../../../api/organizationsApi'
import { ISimpleOrganization } from '../../../share-types'
import translate from '../../../i18n'
import { useEffect } from 'react'

interface CalculateBillingSummariesModalProps {
  organizationId?: number
  open: boolean
  handleOk: () => void
  handleCancel: () => void
}

const { RangePicker } = DatePicker

const CalulateBillingSummariesModal = (props: CalculateBillingSummariesModalProps) => {
  const { open, organizationId, handleOk, handleCancel } = props
  const [form] = Form.useForm()

  useEffect(() => {
    if (open) {
      form.resetFields()
    }
  }, [open])
  const onFinish = (values: any) => {
    const startDate = values['timeRange'][0]
    const endDate = values['timeRange'][1]
    const data = {
      organizationId: organizationId ? organizationId : values['organizationId'],
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    }
    calculateBillingSummaries(data)
      .then(() => {
        notification.success({
          message: '刷新账单统计成功',
        })

        handleOk()
      })
      .catch(err => {
        notification.error({
          message: '刷新账单统计失败',
          description: err?.message || translate('networkError'),
        })
      })
  }

  async function fetchOrganizationsSuggestionOptions(
    keyword: string,
  ): Promise<{ label: string; value: number }[]> {
    return getOrganizationsSuggestion(keyword).then(res => {
      return getOrganizationsSuggestionOptions(res)
    })
  }

  const getOrganizationsSuggestionOptions = (organizationsSuggestions: ISimpleOrganization[]) => {
    return organizationsSuggestions.map(item => {
      const name = item.organizationTranslations[0].name
      return {
        label: `${item.id}-${name}`,
        value: item.id,
      }
    })
  }

  return (
    <Modal title="刷新账单统计" open={open} footer={null} onCancel={handleCancel}>
      <Form
        name="calculateBillingSummaries"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        onFinish={onFinish}
        form={form}
      >
        {organizationId ? null : (
          <Form.Item
            label="Organization"
            name="organizationId"
            rules={[{ required: true, message: '请输入Organization！' }]}
          >
            <DebounceSelect
              allowClear
              showSearch={true}
              placeholder="可以通过id/name搜索organization"
              fetchOptions={fetchOrganizationsSuggestionOptions}
              style={{ width: 200 }}
            />
          </Form.Item>
        )}
        <Form.Item
          name="timeRange"
          label="时间范围"
          rules={[{ required: true, message: '请选择时间范围！' }]}
        >
          <RangePicker format={'YYYY-MM-DD'} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
            <Button htmlType="button" onClick={handleCancel}>
              取消
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CalulateBillingSummariesModal
