import React, { Component } from 'react'
import { Card, Space } from 'antd'
import NavBar from '../../components/nav-bar/nav-bar'
import Menus from '../../components/menus/menus'
import translate from '../../i18n'
import { MenuModeEnum } from '../../share-types'

class Home extends Component {
  render(): React.ReactNode {
    return (
      <Space className="dashboard-wrapper" direction="vertical">
        <NavBar title={translate('welcome')}></NavBar>
        <Card>
          <Menus mode={MenuModeEnum.GRID} />
        </Card>
      </Space>
    )
  }
}

export default Home
